/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button.js";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import TableBody from "@material-ui/core/TableBody";
import isEmpty from "lodash/isEmpty";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Modal from "@material-ui/core/Modal";
import Card from "@material-ui/core/Card";
import closeImage from "assets/img/Close_Image.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import noDataImage from "../../assets/img/noDataImage.png";
import { totalOutStanding } from "../../Redux/Actions/TableAction";
import { SimpleDialog } from "../../components/DialogBox/TotalOutStanding";
import { exportToCSV } from "../../Utils/commonUtils";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ChipInput from "material-ui-chip-input";

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

const useStyles = makeStyles(tableStyle);

function createData(
  patientName,
  providerId,
  ehrId,
  Date,
  loanId,
  balance,
  defaultDate,
  status
) {
  return {
    patientName,
    providerId,
    ehrId,
    Date,
    loanId,
    balance,
    defaultDate,
    status
  };
}

const headCells = [
  {
    id: "Patient_Name",
    className: "Patient_Name",
    disablePadding: true,
    label: "Patient"
  },
  {
    id: "Provider_Id",
    className: "Provider_Id",
    disablePadding: true,
    label: "Provider"
  },
  {
    id: "Loan_ID",
    className: "Loan_ID",
    disablePadding: true,
    label: "EHR ID"
  },
  {
    id: "Balance",
    className: "Balance",
    disablePadding: true,
    label: "Balance"
  },
  {
    id: "Status",
    className: "Status",
    disablePadding: true,
    label: "Status"
  }
];

const TotalOutstanding = (props) => {
  const classes = useStyles();
  const sendApiLink = useSelector((state) => state.app.sendClientIdApi);
  const [changeClientIds, setChangeClientIds] = React.useState(sendApiLink);
  const [initialCall, setInitialCall] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const [emptyData, setEmptyDataModel] = React.useState(false);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Patient_Name");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const [pageNo, setPageNo] = React.useState(1);
  const [searchVlaues, setSearchValues] = React.useState({
    ClientId:
      props.appData.sendClientIdApi && props.appData.sendClientIdApi.toString(),
    loginEmail: localStorage.getItem("email"),
    patientName: "",
    balance: "",
    balanceLess: "",
    status: "",
    limit: rowsPerPage,
    sortBy: orderBy,
    orderBy: order,
    pageNo: pageNo
  });

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              className={classes.cellTextHeaderOffer}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell />
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };
  function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      setPageNo(1);

      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      setPageNo(pageNo - 1);

      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      setPageNo(pageNo + 1);

      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      setPageNo(Math.max(0, Math.ceil(count / rowsPerPage)));

      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.rootPagination}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Last</Typography>
          ) : (
            <Typography>First</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Previous</Typography>
          ) : (
            <Typography>Previous</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleFirstPageButtonClick}
          aria-label="first page"
          className={classes.bottonPage}
        >
          <Typography>{page + 1}</Typography>
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <Typography>Next</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? (
            <FirstPageIcon />
          ) : (
            <Typography>Last</Typography>
          )}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const requestData = { ...searchVlaues };
    requestData.sortBy = property;
    requestData.orderBy = isAsc ? "desc" : "asc";
    requestData.ClientId = props.appData.sendClientIdApi.toString();
    setSearchValues(requestData);
  };

  React.useEffect(() => {
    const data = {
      ...searchVlaues,
      pageNo: pageNo,
      limit: rowsPerPage,
      sortBy: orderBy,
      orderBy: order,
      ClientId:
        props.appData.sendClientIdApi &&
        props.appData.sendClientIdApi.toString()
    };
    props.totalOutStanding(data);
  }, [page, rowsPerPage, orderBy, order, props.appData.sendClientIdApi]);

  const rows = [];
  if (props.tableData.totalOutStanding) {
    props.tableData.totalOutStanding.Total_Out_Statnding_Data.forEach(
      (v, i) => {
        const providerId = v.Provider_Id;
        const Date = v.Date;
        const patientName = v.Patient_Name;
        const ehrId = v.Loan_ID;
        const defaultDate = v.Default_Date;
        const loanId = v.Loan_id;
        const balance = v.Balance;
        const status = v.Status;
        rows.push(
          createData(
            patientName,
            providerId,
            ehrId,
            Date,
            loanId,
            balance,
            defaultDate,
            status
          )
        );
      }
    );
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const requestData = { ...searchVlaues };
    requestData.limit = parseInt(event.target.value, 10);
    requestData.pageNo = 1;
    requestData.ClientId = props.appData.sendClientIdApi.toString();
    setSearchValues(requestData);
    setPage(1);
  };

  React.useEffect(() => {
    if (props.tableData.totalOutStanding?.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData]);

  const exportListData = () => {
    const filterValue = "";
    // if (chipValue.length) {
    //   for (const property in chipValue) {
    //     if (chipValue[property] !== "") {
    //       filterValue += `${chipValue[property]}`;
    //     }
    //   }
    // }
    const sheetModuleDetails = [
      ["Module:", "Total Outstanding Details"],
      ["Export On:", moment.utc(new Date()).format()],
      [
        "Exported By:",
        `${props.userDetails.userDetailsById.Users_Data[0].FName} ${props.userDetails.userDetailsById.Users_Data[0].LName} <${props.userDetails.userDetailsById.Users_Data[0].Email}>`
      ],
      ["Filters:", filterValue]
    ];
    if (!isEmpty(rows)) {
      const mappedLogs = rows.map((row) => ({
        Patient: row.patientName,
        Provider: row.providerId,
        "EHR ID": row.ehrId,
        Balance: row.Balance,
        Status: row.Status
      }));
      exportToCSV(
        mappedLogs,
        `Total Outstanding${moment().format("MMDDYYYY")}`,
        sheetModuleDetails
      );
    } else {
      setEmptyDataModel(true);
    }
  };

  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      const chipName = chip.split(":")[0];
      const newChipValues = [...chipValue];
      newChipValues.splice(index, 1);
      setChipValue([...newChipValues]);
      const newSearchValue = { ...searchVlaues };
      if (chipName === "Patient Name") {
        newSearchValue.patientName = "";
      }
      if (chipName === "Amount more than Equal ($)") {
        newSearchValue.balance = "";
      }
      if (chipName === "Amount less than Equal ($)") {
        newSearchValue.balanceLess = "";
      }
      if (chipName === "Status") {
        newSearchValue.status = "";
      }
      setSearchValues({ ...newSearchValue });
      const valuesClientID = { ...newSearchValue };
      // valuesClientID.ClientId = sendApiLink;
      // valuesClientID.LoginEmail = localStorage.getItem("email");
      props.totalOutStanding(valuesClientID);
    }
  };

  const getTotalOutStandingFilterDataHandle = (value) => {
    const finalSearchData = {
      ...value,
      patientName: value.patientName == "" ? "" : value.patientName.trim(),
      balance: value.balance == "" ? "" : value.balance.trim(),
      balanceLess: value.balanceLess == "" ? "" : value.balanceLess.trim(),
      orderBy: order,
      sortBy: orderBy,
      limit: rowsPerPage,
      pageNo: pageNo,
      ClientId:
        props.appData.sendClientIdApi &&
        props.appData.sendClientIdApi.toString(),
      loginEmail: localStorage.getItem("email")
    };
    setSearchValues(finalSearchData);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (property === "patientName") {
          chipArray.push(
            `${"Patient Name"}:
                    ${value[property]}`
          );
        } else if (property === "balance") {
          chipArray.push(
            `${"Amount more than Equal ($)"}:
              ${value[property]}`
          );
        } else if (property === "balanceLess") {
          chipArray.push(
            `${"Amount less than Equal ($)"}:
                    ${value[property]}`
          );
        } else if (property === "status") {
          chipArray.push(
            `${"Status"}:
                ${value[property]}`
          );
        }
      }
    }
    props.totalOutStanding(finalSearchData);
    setChipValue(chipArray);
  };
  return (
    <>
      <ThemeProvider theme={themeFont}>
        <Modal open={emptyData} onClose={() => setEmptyDataModel(false)}>
          <div className={classes.modalDiv}>
            <Card className={classes.modalPayOut}>
              <div
                className={classes.cardDiv1}
                style={{
                  display: "flex",
                  overflow: "hidden"
                }}
              >
                <img src={closeImage} width="75px" />

                <div
                  style={{
                    textAlign: "center",
                    color: "gray",
                    fontSize: "14px",
                    whiteSpace: "normal"
                  }}
                >
                  No Data present
                </div>
                <br />
                <Grid container alignItems="center" justifyContent="center">
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    onClick={() => setEmptyDataModel(false)}
                  >
                    ok
                  </Button>
                </Grid>
              </div>
            </Card>
          </div>
        </Modal>
        <TableContainer
          className={classes.TableContainer}
          component={Paper}
          elevation={0}
        >
          {windowWidth && (
            <Grid container className={classes.searchContainer}>
              <Grid>
                <Typography className={classes.upperMiniHeadings}>
                  REPORTS
                </Typography>
                <Typography className={classes.uppserBoldHeading}>
                  Total Outstanding
                </Typography>
              </Grid>
              <Grid container className={classes.searchContainer}>
                <Grid item className={classes.margin}>
                  <div className={classes.searchWrapper}>
                    <Box style={{ width: "47%", marginLeft: "-10px" }}>
                      Amount Outstanding:{" "}
                      <span style={{ color: "#CF4270" }}>
                        $
                        {props.tableData.totalOutStanding &&
                          props.tableData.totalOutStanding
                            .amountOutstanding}
                      </span>
                    </Box>
                    <ClickAwayListener
                      mouseEvent="onMouseDown"
                      touchEvent="onTouchStart"
                      onClickAway={() => handleClose()}
                    >
                      <Box
                        // className={classes.newSearchDiv}
                        style={{
                          minWidth: "40%",
                          display: "block",
                          backgroundColor: "#ffffff",
                          borderRadius: "20px",
                          marginRight: "10px"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: "5px",
                            marginBottom: "5px"
                          }}
                        >
                          <Box style={{ width: "100%" }}>
                            <ChipInput
                              disableUnderline
                              value={chipValue.length > 0 ? chipValue : []}
                              onClick={() => handleClickOpen()}
                              style={{ display: "flex", marginLeft: "10px" }}
                              classes={{ chipContainer: classes.chipContainer }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.activeInputLableColor,
                                  focused: "focused"
                                }
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.activeInputColor,
                                  input: classes.resize,
                                  focused: "focused"
                                }
                              }}
                              placeholder="Search Report"
                              onDelete={(chip, index) =>
                                handleDeleteChip(chip, index)
                              }
                            />
                          </Box>
                          <Box
                            style={{ paddingTop: "10px", paddingRight: "10px" }}
                          >
                            <SearchIcon />
                          </Box>
                        </div>
                        <Collapse
                          in={open}
                          timeout="auto"
                          unmountOnExit
                          className={classes.overLayBox}
                        >
                          <SimpleDialog
                            close={() => handleClose()}
                            searchValue={searchVlaues}
                            seacrhChipData={(data) =>
                              getTotalOutStandingFilterDataHandle(data)
                            }
                          />
                        </Collapse>
                      </Box>
                    </ClickAwayListener>
                    <Button
                      size="large"
                      type="submit"
                      className={classes.exportButton}
                      onClick={exportListData}
                      variant=""
                      color="#ffffff"
                    >
                      Export Data
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!windowWidth && (
            <Grid container className={classes.searchContainer}>
              <Grid item className={classes.margin} style={{ display: "flex" }}>
                <div className={classes.searchWrapper}>
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => handleClose()}
                  >
                    <Box style={{ width: "85%" }}>
                      <TextField
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused"
                          }
                        }}
                        inputProps={{
                          autoComplete: "off"
                        }}
                        fullWidth
                        label="Search"
                        onClick={() => handleClickOpen()}
                        name="Search"
                        id="Search"
                      />
                      <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className={classes.overLayBox}
                      >
                        <SimpleDialog
                          close={() => handleClose()}
                          searchValue={searchVlaues}
                          seacrhChipData={(data) =>
                            getTotalOutStandingFilterDataHandle(data)
                          }
                        />
                      </Collapse>
                    </Box>
                  </ClickAwayListener>
                  <Button
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    onClick={() => {}}
                  >
                    <Search />
                  </Button>

                  <Grid item className={classes.expirtItemGrid}>
                    <Button
                      size="large"
                      type="submit"
                      className={classes.exportButton}
                      onClick={exportListData}
                      variant=""
                      color="#ffffff"
                      // className={classes.submit}
                    >
                      Export Data
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          )}
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {!isEmpty(rows) ? (
                rows.map((row, index) => (
                  <TableRow
                    style={{
                      backgroundColor:
                        (index + 1) % 2 === 0 ? "#F6F9FF" : "white"
                    }}
                  >
                    <TableCell
                      className={classes.cellTextPatient}
                      align="center"
                    >
                      {row.patientName}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      {row.providerId}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      {row.ehrId}
                    </TableCell>
                    <TableCell
                      className={classes.cellTextNew}
                      align="center"
                      style={{ color: "#CF4270" }}
                    >
                      ${row.balance}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      <Button
                        variant="contain"
                        className={classes.statusButton}
                      >
                        {row.status}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={12}>
                    <img
                      src={noDataImage}
                      alt=""
                      className={classes.noDataImage}
                    />
                  </TableCell>
                </TableRow>
              )}
              {/* {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )} */}
            </TableBody>
            <TableFooter className={classes.tablePaginationRow}>
              <TableRow className={classes.tablePaginationRow}>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value: props.tableData.totalOutStanding?.totalDocs
                    }
                  ]}
                  colSpan={12}
                  count={props.tableData.totalOutStanding?.totalDocs}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </>
  );
};

TotalOutstanding.propTypes = {
  totalOutStanding: PropTypes.func,
  tableData: PropTypes.any,
  userDetails: PropTypes.any
};

const mapStateToProps = (table) => ({
  tableData: table.table,
  userDetails: table.users,
  appData: table.app
});
export default connect(mapStateToProps, {
  totalOutStanding
})(TotalOutstanding);
