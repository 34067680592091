/* eslint-disable radix */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-debugger */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";
import { Grid, Box } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Table from "@material-ui/core/Table";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Button from "components/CustomButtons/Button";
import ButtonOriginal from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import Card from "components/Card/Card";
import Checkbox from "@material-ui/core/Checkbox";
import { Typography, Divider } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import isEmpty from "lodash/isEmpty";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";
import CancelIcon from "@material-ui/icons/Cancel";
import ClearIcon from "@material-ui/icons/Clear";
import ErrorIcon from "@material-ui/icons/Error";
import moment from "moment";
import {
  cptCodeDataCall,
  addProposalCall,
  setDefaultOfferValue
} from "Redux/Actions/genrateOfferPatient";
import styled, { keyframes } from "styled-components";
import ReplayIcon from "@material-ui/icons/Replay";
import AddIcon from "@material-ui/icons/Add";
import noDataImage from "../../assets/img/noDataImage.png";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import tpoLavel0 from "../../assets/img/TPO_Level-0.jpeg";
import tpoLavel1 from "../../assets/img/TPO_Level-1.jpg";
import tpoLavel2 from "../../assets/img/TPO_Level-2.jpg";
import tpoLavel3 from "../../assets/img/TPO_Level-3.jpg";
import tpoLavel4 from "../../assets/img/TPO_Level-4.jpg";
import gif from "../../assets/img/success.gif";
import {
  getAxiosAPI,
  SortData,
  formatter
} from "../../actions/AxiosAPIMethods";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const theme = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      }
    }
  }
});

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      }
    }
  }
});

const useStyles = makeStyles(tableStyle);

function createData(
  insurnaceProvider,
  offerId,
  patientResponsbility,
  cptCode,
  status,
  startDate,
  endDate,
  monthlyPay,
  id,
  offerNumber,
  clientId,
  Service,
  offerGenerated,
  offerExpired,
  OfferCurrentStatus
) {
  return {
    insurnaceProvider,
    offerId,
    patientResponsbility,
    cptCode,
    status,
    startDate,
    endDate,
    monthlyPay,
    id,
    offerNumber,
    clientId,
    Service,
    offerGenerated,
    offerExpired,
    OfferCurrentStatus
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "calories",
    numeric: true,
    disablePadding: true,
    label: "Offer ID"
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Insurance Carrier"
  },
  {
    id: "start",
    numeric: false,
    disablePadding: true,
    label: "Service"
  },
  {
    id: "end",
    numeric: false,
    disablePadding: true,
    label: "Offer Generated On"
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Offer Generated By"
  },
  {
    id: "monthly",
    numeric: true,
    disablePadding: false,
    label: "Offer Expire On"
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Amount Due"
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Status"
  }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            style={{ color: "white" }}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
            disabled={props.disableAction}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={Math.random()}
            className={classes.cellTextHeaderOffer}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  disableAction: PropTypes.bool
};

function ProposalTable(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const sendApiLink = useSelector((state) => state.app.sendClientIdApi);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [preCptCodeData, setPreCptCodeData] = React.useState(
    props.tableData.cptCodeData
  );
  const [patientActivePlans, setPatientActivePlans] = React.useState([]);
  const [insuranceCarrierData, setInsuranceCarrierData] = React.useState([]);
  const [check, setCheck] = React.useState(false);
  const [selectedCount, setSelectedCount] = React.useState([]);
  const [addProposalModal, setaddProposalModal] = React.useState(false);
  const [validationModal, setValidationModal] = React.useState(false);
  const [otherServiceSelect, setOtherServiceSelect] = React.useState(false);
  const [submissionSuccess, setSubmissionSuccess] = React.useState(false);
  const [prePerposal, setPrePerposal] = React.useState(
    props.formData.addProposalData
  );
  localStorage.setItem("selectOfferId", props.offerId);
  const [errorMessage, setErrorMessage] = React.useState(false);
  const filter = createFilterOptions();
  const [values, handleInput] = React.useState({
    responsibilty: "",
    provider: "",
    insuranceProvider: "",
    otherservicename: "",
    service: ""
  });
  const [validationError, setValidationError] = React.useState({
    responsibilty: false,
    provider: false,
    otherservicename: false,
    insuranceProvider: false,
    service: false
  });

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    allState[type] = value;
    error[type] = false;
    handleInput(allState);
    setValidationError(error);
  };

  const handleResetValues = () => {
    setValidationModal(false);
    const allState = { ...values };
    allState.responsibilty = "";
    allState.provider = "";
    allState.insuranceProvider = "";
    handleInput(allState);
    setSubmissionSuccess(false);
    const data = {
      commonText: "",
      ClientId: props.app.sendClientIdApi.toString()
    };
    props.patientSearch(data);
    setFieldValue("service", "");
    setFieldValue("otherservicename", "");
    handleInput({
      responsibilty: "",
      provider: "",
      insuranceProvider: "",
      otherservicename: "",
      service: ""
    });
  };

  const setFieldValue = (key, value) => {
    if (key === "service") {
      const allValidation = { ...validationError };
      // allValidation.service = false;
      setValidationError(allValidation);
      handleInput((pre) => {
        pre.service = value;
        return pre;
      });
    }
    if (key === "otherservicename") {
      const data = {};
      setSubmissionSuccess(data);
      const allValidation = { ...validationError };
      // allValidation.service = false;
      setValidationError(allValidation);
      setOtherServiceSelect(true);
      handleInput((pre) => {
        pre.otherservicename = value;
        return pre;
      });
    }
  };

  const checkValidation = () => {
    let checkValue = true;
    const value = { ...values };
    const allValidation = { ...validationError };
    Object.keys(validationError).forEach((v) => {
      if (value[v] === "") {
        checkValue = false;
        allValidation[v] = true;
      }
    });
    setValidationError(allValidation);
    return checkValue;
  };

  // function handleInputChange(event, value) {
  //   const allState = { ...values };
  //   const allValidation = { ...validationError };
  //   allState.service = value;
  //   allValidation.service = false;
  //   handleInput(allState);
  //   setValidationError(allValidation);
  // }

  const onFormSubmit = () => {
    // if (checkValidation()) {
    const patientIdValue = props.showOfferDEtails.data.Patient_id;

    let data = {
      patientId: props.showOfferDEtails.data.Patient_id,
      responsibilty: values.responsibilty,
      provider: values.provider,
      insuranceProvider: values.insuranceProvider,
      otherservicename: values.service,
      service: values.service
    };

    if (patientIdValue === "") {
      data = {
        patientId: props.showOfferDEtails.data.Patient_id,
        patientName: props.showOfferDEtails.data.Patient_Name,
        ssn: props.showOfferDEtails.data.SSNFull,
        email: props.showOfferDEtails.data.Email,
        mobile: props.showOfferDEtails.data.Mobile_Number,
        dob: moment(props.showOfferDEtails.data.DOB).format("YYYY-MM-DD"),
        address1: props.showOfferDEtails.data.Address,
        address2: props.showOfferDEtails.data.Address2,
        city: props.showOfferDEtails.data.City,
        zip: props.showOfferDEtails.data.ZipCode,
        responsibilty: values.responsibilty,
        provider: values.provider,
        insuranceProvider: values.insuranceProvider,
        otherservicename: ""
      };
    }
    // if (values.service === "") {
    //   data.service = "other";
    //   data.otherservicename = values.otherservicename;
    // } else {
    //   data.service = props.tableData.cptCodeData.State_Data.filter(
    //     (key) => key.cptDescription === values.service
    //   )[0].cptCode;
    //   data.otherservicename = "";
    // }
    // if (values.otherservicename !== "") {
    //   data.service = values.otherservicename;
    //   data.otherservicename = values.otherservicename;
    // } else {
    //   data.service = values.otherservicename;
    //   data.otherservicename = "";
    // }

    props.addProposalCall(data);
    setaddProposalModal(false);
    handleResetValues(false);
    setValidationModal(true);
    setSubmissionSuccess(true);
    getNewDues();
    // } else {
    // }
  };

  function blinkingEffect() {
    return keyframes`
      50% {
        opacity: 0;
      }
    `;
  }

  const AnimatedComponent = styled.div`
    animation: ${blinkingEffect} 2s linear infinite;
  `;

  React.useEffect(() => {
    setValidationModal(false);
    if (prePerposal !== props.formData.addProposalData) {
      setValidationModal(false);
      setPrePerposal(props.formData.addProposalData);
      if (
        props.formData.addProposalData &&
        props.formData.addProposalData.status
      ) {
        setaddProposalModal(false);
        enqueueSnackbar(`${props.formData.addProposalData.message}`, {
          variant: "success"
        });
        handleCloseModel();
      } else if (
        props.formData.addProposalData &&
        !props.formData.addProposalData.status &&
        props.formData.addProposalData.errors
      ) {
        setErrorMessage(props.formData.addProposalData.errors);
        setSubmissionSuccess(false);
        setValidationModal(true);
      }
    }
    const path = window.location.href.split("/", 5).pop();
    if (path === "addpatient") {
      getInsuranceCarrierList();
    }
  }, [props]);

  React.useEffect(() => {
    getNewDues();
  }, []);

  const selectedPatient = JSON.parse(
    localStorage.getItem("selectedPatient") || "{}"
  );

  const getInsuranceCarrierList = async () => {
    const url = "/insurance-carrier";
    await getAxiosAPI(url).then((res) => {
      if (res.error) {
      } else {
        const sortedData = res.data.State_Data.sort((a, b) =>
          SortData(a.name.toLowerCase(), b.name.toLowerCase())
        );
        setInsuranceCarrierData(sortedData);
      }
    });
  };

  const getNewDues = async () => {
    const ID = selectedPatient?.patientId;
    const url = `/patients/dues-list?patientId=${ID}&ClientId=${props.app.sendClientIdApi.toString()}`;
    await getAxiosAPI(url).then((res) => {
      if (res.error) {
      } else {
        setPatientActivePlans(res.data.data);
      }
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (offerId, pram) => {
    let value = selected;
    if (selected.length === 0) {
      value.push(offerId);
      setSelected(value);
      setCheck(pram);
    } else if (selected.length) {
      const checkAlreadyPresent = selected.filter((key) => key === offerId);
      if (checkAlreadyPresent.length) {
        value = selected.filter((key) => key !== offerId);
        setSelected(value);
        setCheck(pram);
      } else {
        value.push(offerId);
        setSelected(value);
        setCheck(pram);
      }
    }
  };

  const rows = [];
  patientActivePlans.forEach((key) => {
    const insurnaceProvider = key.Insurance_Provider;
    const offerId = key.Offer_ID;
    const patientResponsbility = key.Patient_Responsibility;
    const startDate = key.start_Date;
    const endDate = key.end_Date;
    const monthlyPay = key.monthlyPay;
    const cptCode = key.CPT_Code;
    const status = key.Current_Status;
    const id = key._id;
    const offerNumber = key.offerNumber;
    const clientId = key.Client_ID;
    const Service = key.Service;
    const offerGenerated = key.offer_generated;
    const offerExpired = key.offer_expired;
    const OfferCurrentStatus = key.Offer_Current_Status;
    rows.push(
      createData(
        insurnaceProvider,
        offerId,
        patientResponsbility,
        cptCode,
        status,
        startDate,
        endDate,
        monthlyPay,
        id,
        offerNumber,
        clientId,
        Service,
        offerGenerated,
        offerExpired,
        OfferCurrentStatus
      )
    );
  });

  let totalAmount = 0;
  let countSelect = parseInt(0);
  if (selected.length > 0) {
    const value = [];
    rows.forEach((v) => {
      if (selected.filter((key) => key === v.offerId).length) {
        value.push(v.patientResponsbility);
        countSelect += parseInt(1);
      } else if (selected.filter((key) => key === v.id).length) {
        value.push(v.patientResponsbility);
        countSelect += parseInt(1);
      }
    });
    totalAmount = value.reduce((acc, val) => acc + val, 0).toFixed(2);
    localStorage.setItem("duesId", JSON.stringify(selected));
    localStorage.setItem("totalAmount", totalAmount);
  }

  // selected.length > 0 ? rows.filter(key => key.offerId === offerId);selected.reduce((acc, val) => acc + val, 0).toFixed(2) : 0; a.findIndex(t => (t.label === v.label && t.value === v.value)) === i)

  const handleGenrateOffer = (value) => {
    props.setOfferId(false);
    let totalAmountIn = 0;
    const valuePreposalId = [];
    const valueIn = [];
    rows.forEach((v) => {
      if (value.filter((key) => key === v.offerId).length) {
        valuePreposalId.push(v.id);
        localStorage.setItem("tempClientId", v.clientId);
        valueIn.push(v.patientResponsbility);
      } else if (value.filter((key) => key === v.id).length) {
        valuePreposalId.push(v.id);
        localStorage.setItem("tempClientId", v.clientId);
        valueIn.push(v.patientResponsbility);
      }
    });
    totalAmountIn = valueIn.reduce((acc, val) => acc + val, 0).toFixed(2);
    props.setPropsalData(valuePreposalId);
    // if (totalAmountIn > 0) {
    const data = {
      dueId: selected,
      offerId: ""
    };
    const data2 = {
      amount: totalAmountIn,
      terms: 24
    };
    // props.setLoadingFalse(true);
    // setTimeout(() => {
    //   props.setLoadingFalse(false);
    // }, 2000);
    // props.getGenrateOfferData(data);
    // props.setDefaultOfferValue(data2);
    // }
    localStorage.setItem("genrateOfferData", JSON.stringify(data));
    props.history.push("/admin/acceptOffer");
  };

  React.useEffect(() => {
    if (props.tableData.paymentDetailsData && props.tableData.createOffer) {
      const filterData = props.showOfferDEtails.data.proposalList.filter(
        (key) => key.Offer_ID === props.tableData.createOffer.data.OfferId
      );
      if (filterData.length > 0) {
        const backRow = {
          insurnaceProvider: filterData[0].Insurance_Provider,
          offerId: filterData[0].Offer_ID,
          patientResponsbility: filterData[0].Patient_Responsibility,
          startDate: filterData[0].start_Date,
          endDate: filterData[0].end_Date,
          monthlyPay: filterData[0].monthlyPay,
          cptCode: filterData[0].CPT_Code,
          status: filterData[0].Current_Status,
          id: filterData[0]._id
        };
        handleClick(backRow.id, !check, true);
      }
      props.setOfferId(props.tableData.createOffer.data.OfferId);
    }
  }, []);

  React.useEffect(() => {
    if (preCptCodeData !== props.tableData.cptCodeData) {
      setPreCptCodeData(props.tableData.cptCodeData);
      if (props.tableData.cptCodeData) {
        setaddProposalModal(true);
      }
    }
  }, [props]);

  const handleGenrateOfferHyperLink = (value, offerId) => {
    props.setOfferId(offerId);
    const valuePreposalId = [];
    let totalAmountIn = 0;
    const valueIn = [];
    rows.forEach((v) => {
      if (value.filter((key) => key === v.offerId).length) {
        valuePreposalId.push(v.id);
        valueIn.push(v.patientResponsbility);
      } else if (value.filter((key) => key === v.id).length) {
        valuePreposalId.push(v.id);
        valueIn.push(v.patientResponsbility);
      }
    });
    totalAmountIn = valueIn.reduce((acc, val) => acc + val, 0).toFixed(2);
    if (totalAmountIn > 0) {
      const data = {
        dueId: null,
        offerId: [offerId]
      };
      const data2 = {
        amount: totalAmountIn,
        terms: 24,
        offerId
      };
      props.genrateOfferHyperLink(data);
      props.setDefaultOfferValue(data2);
      props.setClickLink();
    }
  };

  const handleReset = () => {
    setSelected([]);
    props.handlePrePosalReset();
  };

  const generateOffers = (item) => {
    const offerDate = moment(item.Offer_EndDate).format("YYYY-MM-DD");
    const currentDate = moment().format("YYYY-MM-DD");

    if (offerDate < currentDate) {
      enqueueSnackbar("This offer has expired. Please generate a new offer.", {
        variant: "error"
      });
    } else {
      localStorage.setItem("tempClientId", item.Client_ID);
      props.setOfferId(item.Offer_ID);
      const checkBoxActive = [];
      const filterOfferId = rows.filter((key) => key.offerId === item.Offer_ID);
      filterOfferId.forEach((key) => {
        checkBoxActive.push(key.id);
      });
      const valuePreposalId = [];
      rows.forEach((v) => {
        if (checkBoxActive.filter((key) => key === v.offerId).length) {
          valuePreposalId.push(v.id);
        } else if (checkBoxActive.filter((key) => key === v.id).length) {
          valuePreposalId.push(v.id);
        }
      });
      props.setPropsalData(valuePreposalId);
      setSelected(checkBoxActive);
      // props.history.push("/admin/offerpayment");
      handleGenrateOfferHyperLink(checkBoxActive, item.Offer_ID);
    }
  };

  const handleAddNew = () => {
    const data = {};
    setSubmissionSuccess(data);
    setOtherServiceSelect(true);
  };

  const handleaddProposalModal = () => {
    setSubmissionSuccess(false);
    setValidationModal(false);
    props.cptCodeDataCall();
    getInsuranceCarrierList();
  };

  const handleCloseModel = () => {
    setValidationModal(false);
    setaddProposalModal(false);
    handleResetValues(false);
    const allState = { ...values };
    allState.responsibilty = "";
    allState.provider = "";
    allState.insuranceProvider = "";
    handleInput(allState);
    setSubmissionSuccess(false);
    const data = {
      commonText: "",
      ClientId: props.app.sendClientIdApi.toString()
    };
    props.patientSearch(data);
    setFieldValue("service", "");
    setFieldValue("otherservicename", "");
  };

  const defaultProps = {
    options: props.tableData.cptCodeData.State_Data
    // getOptionLabel: option => option.cptDescription,
  };

  return (
    <>
      <Grid
        container
        // spacing={5}
        style={{
          marginTop: "0px",
          marginBottom: "0px"
        }}
      >
        <Grid
          container
          xs={12}
          lg={4}
          // className={classes.offerDetailsTopHeading}
          style={{
            position: "relative",
            display: "inline-flex",
            justifyContent: "left",
            alignItems: "center",
            paddingLeft: "0px",
            marginTop: "20px",
            marginBottom: "20px"
          }}
        >
          <Typography
            style={{
              marginLeft: "22px"
            }}
          >
            Selected Amount :&nbsp;- &nbsp;&nbsp;
          </Typography>
          <Typography
            variant="h5"
            color="primary"
            style={{
              BackgroundColer: "#334E90",
              fontWeight: "20px"
            }}
          >
            ${totalAmount} ({countSelect})
          </Typography>
        </Grid>

        <Grid
          container
          xs={12}
          lg={8}
          // onClick={() => tpoScoreLevelModel()}
          style={{
            position: "relative",
            display: "inline-flex",
            justifyContent: "left",
            alignItems: "center",
            paddingLeft: "10px",
            marginTop: "20px",
            marginBottom: "30px"
          }}
        >
          <Grid
            item
            xs={4}
            lg={2}
            // onClick={() => tpoScoreLevelModel()}
            style={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "left",
              alignItems: "center"
            }}
          >
            {totalAmount > 0 ? (
              <Button
                disabled={props.disableAction}
                className={classes.offerGenerateButton}
                variant="contained"
                color="#03A430"
                style={{
                  backgroundColor: "#03A430",
                  textTransform: "none",
                  // borderRadius: "25px",
                  height: "40px"
                }}
                onClick={() => handleGenrateOffer(selected)}
              >
                Generate Offer
              </Button>
            ) : (
              <Button
                disabled={props.disableAction}
                className={classes.offerGenerateButton}
                variant="contained"
                color="#03A430"
                style={{
                  backgroundColor: "#868686",
                  textTransform: "none",
                  // borderRadius: "25px",
                  height: "40px"
                }}
              >
                Generate Offer
              </Button>
            )}
          </Grid>
          <Grid
            item
            xs={4}
            lg={8}
            style={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "20px",
              fontWeight: "bold"
            }}
          >
            <ButtonOriginal
              variant="outlined"
              style={{
                textTransform: "none",
                border: "aliceblue",
                BackgroundColer: "#334E90 !important"
              }}
              color="primary"
              onClick={() => handleReset()}
            >
              <ReplayIcon /> &nbsp; Reset
            </ButtonOriginal>
          </Grid>
          <Grid
            item
            xs={4}
            lg={2}
            style={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "right",
              alignItems: "center",
              fontSize: "20px",
              fontWeight: "bold",
              paddingRight: "20px"
            }}
          >
            <ButtonOriginal
              disabled={props.disableAction}
              variant="contained"
              style={{
                marginLeft: "3%",
                textTransform: "none",
                backgroundColor: "#03A430"
              }}
              // color="primary"
              onClick={() => handleaddProposalModal()}
            >
              + Add
            </ButtonOriginal>
          </Grid>
        </Grid>
        <Card
          style={{
            marginTop: "0px"
            // borderTop: "2px solid #0d1619",
          }}
        >
          <TableContainer style={{ maxHeight: "34rem" }}>
            <Table
              className={classes.patientTable}
              aria-labelledby="tableTitle"
              aria-label="enhanced table"
            >
              <ThemeProvider theme={themeFont}>
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                  disableAction={props.disableAction}
                />
              </ThemeProvider>
              <TableBody>
                {!isEmpty(rows) ? (
                  patientActivePlans?.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow hover role="checkbox" key={Math.random()}>
                        <ThemeProvider theme={themeFont}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              onClick={() =>
                                props.disableAction
                                  ? {}
                                  : handleClick(row._id, !check)
                              }
                              checked={
                                selected.filter((key) => key === row._id)
                                  .length > 0
                              }
                              inputProps={{ "aria-labelledby": labelId }}
                              disabled={props.disableAction}
                            />
                          </TableCell>
                          <TableCell
                            className={classes.patientNameColor}
                            style={{ cursor: "pointer" }}
                            align="left"
                            onClick={() =>
                              row?.Offer_ID ? generateOffers(row) : {}
                            }
                          >
                            {row?.Offer_Number}
                          </TableCell>
                          <TableCell className={classes.cellText} align="left">
                            {row.Insurance_Provider}
                          </TableCell>
                          <TableCell className={classes.cellText} align="left">
                            {row.Service}
                          </TableCell>
                          <TableCell className={classes.cellText} align="left">
                            {row?.offerGenerated}
                          </TableCell>
                          <TableCell className={classes.cellText} align="left">
                            {row.Client_ID}
                          </TableCell>
                          <TableCell className={classes.cellText} align="left">
                            {row.Offer_EndDate}
                          </TableCell>
                          <TableCell className={classes.cellText} align="left">
                            {/* {`$${row.Patient_Responsibility ? row.Patient_Responsibility : "0.00"}`} */}
                            {formatter.format(row.Patient_Responsibility)}
                          </TableCell>
                          <TableCell className={classes.cellText} align="left">
                            {row.Status_Name ? row.Status_Name : "New"}
                          </TableCell>
                        </ThemeProvider>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell
                      className="no-data"
                      colSpan={headCells.length + 1}
                    >
                      <img
                        src={noDataImage}
                        alt=""
                        className={classes.noDataImage}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        <Modal open={addProposalModal} onClose={() => handleCloseModel(false)}>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              width: "92%",
              marginRight: "10%",
              marginBottom: "20%"
            }}
          >
            <Card style={{ width: "70%", marginTop: "5%" }}>
              <Grid
                container
                className={classes.margin}
                style={{ margin: "2%" }}
              >
                <Grid item xs={11}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      margin: "1%",
                      fontSize: "20px",
                      whiteSpace: "nowrap",
                      fontFamily: "Source Sans Pro"
                    }}
                  >
                    Dues for:
                    <div
                      style={{
                        fontSize: "18px",
                        color: "#144b8e",
                        fontWeight: "bold",
                        marginTop: "1px"
                      }}
                    >
                      &nbsp;&nbsp; {props.showOfferDEtails.data.Patient_Name}{" "}
                    </div>
                    <div
                      style={{
                        marginTop: "-1%",
                        marginLeft: "3%"
                      }}
                    >
                      {(props.showOfferDEtails.data.tpoLevel === "Level-0" && (
                        <img src={tpoLavel0} alt="" />
                      )) ||
                        (props.showOfferDEtails.data.tpoLevel === "Level-1" && (
                          <img src={tpoLavel1} alt="" />
                        )) ||
                        (props.showOfferDEtails.data.tpoLevel === "Level-2" && (
                          <img src={tpoLavel2} alt="" />
                        )) ||
                        (props.showOfferDEtails.data.tpoLevel === "Level-3" && (
                          <img src={tpoLavel3} alt="" />
                        )) ||
                        (props.showOfferDEtails.data.tpoLevel >= "Level-4" && (
                          <img src={tpoLavel4} alt="" />
                        ))}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1} align="left">
                  <ClearIcon
                    align="right"
                    style={{ cursor: "pointer" }}
                    onClick={() => handleCloseModel(false)}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Grid
                container
                className={classes.margin}
                style={{ margin: "1% 2%" }}
              >
                <Grid item xs>
                  <TextField
                    error={validationError.responsibilty}
                    required
                    style={{ width: "90%", fontFamily: "Source Sans Pro" }}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Responsibilty ($)"
                    value={values.responsibilty}
                    onChange={(e) => onChange(e.target.value, "responsibilty")}
                    name="responsibilty"
                    id="responsibilty"
                  />
                </Grid>
                <Grid item xs>
                  <TextField
                    error={validationError.provider}
                    className={classes.root}
                    style={{ width: "90%", fontFamily: "Source Sans Pro" }}
                    required
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    select
                    label="Provider"
                    value={values.provider}
                    onChange={(e) => onChange(e.target.value, "provider")}
                    name="provider"
                    id="provider"
                  >
                    {props.userProvider.userProvider &&
                      props.userProvider.userProvider.data.map((key) => (
                        <MenuItem
                          style={{ fontFamily: "Source Sans Pro" }}
                          value={key.Porvider_Id}
                        >
                          {key.ProviderData}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
              </Grid>

              <Grid
                container
                className={classes.margin}
                style={{ margin: "1% 2%" }}
              >
                <Grid item xs>
                  <ThemeProvider theme={theme}>
                    <Autocomplete
                      id="highlights-demo"
                      style={{ fontFamily: "Source Sans Pro" }}
                      options={insuranceCarrierData}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          style={{
                            width: "90%",
                            fontFamily: "Source Sans Pro"
                          }}
                          {...params}
                          label="Insurance Carrier"
                          margin="normal"
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          onChange("insuranceCarrier", newValue.code);
                          // onChange("otherservicename", newValue.code);
                        } else if (newValue && newValue.code) {
                          onChange(newValue.name, "insuranceProvider");
                          // onChange("otherservicename", "");
                        } else {
                          onChange("insuranceCarrier", newValue.code);
                          // onChange("otherservicename", newValue.code);
                        }
                      }}
                      renderOption={(props, option) => {
                        const matches = match(props.name, option.inputValue);
                        const parts = parse(props.name, matches);
                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    background: part.highlight
                                      ? "#f3ff0070"
                                      : "",
                                    fontWeight: part.highlight ? 400 : "",
                                    fontSize: "14px",
                                    fontFamily: "Source Sans Pro"
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        );
                      }}
                    />
                  </ThemeProvider>
                </Grid>

                <Grid item xs>
                  <ThemeProvider theme={theme}>
                    <Autocomplete
                      id="highlights-demo"
                      style={{ width: "90%", fontFamily: "none" }}
                      options={defaultProps.options}
                      getOptionLabel={(option) => option.cptDescription}
                      renderInput={(params) => (
                        <TextField
                          style={{
                            fontFamily: "Source Sans Pro"
                          }}
                          {...params}
                          label="Service Description"
                          margin="normal"
                        />
                      )}
                      onChange={(event, newValue) => {
                        if (typeof newValue === "string") {
                          // onChange("service", newValue.cptCode);
                          // onChange("otherservicename", newValue.cptCode);
                        } else if (newValue && newValue.cptCode) {
                          onChange(newValue.cptCode, "otherservicename");
                          onChange(newValue.cptCode, "service");
                        } else {
                          onChange(newValue.cptCode, "service");
                          // onChange("otherservicename", newValue.cptCode);
                        }
                      }}
                      renderOption={(props, option) => {
                        const matches = match(
                          props.cptDescription,
                          option.inputValue
                        );
                        const parts = parse(props.cptDescription, matches);
                        return (
                          <li {...props}>
                            <div>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    background: part.highlight
                                      ? "#f3ff0070"
                                      : "",
                                    fontWeight: part.highlight ? 400 : "",
                                    fontSize: "14px",
                                    fontFamily: "Source Sans Pro"
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </div>
                          </li>
                        );
                      }}
                    />
                  </ThemeProvider>
                </Grid>

                {/* <Grid item xs>
                {!otherServiceSelect && (
                  <Autocomplete
                    {...defaultProps}
                    onInputChange={handleInputChange}
                    noOptionsText={
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        not available
                        <Button
                          color="secondary"
                          onClick={() => handleAddNew()}
                        >
                          Add New
                        </Button>
                      </Box>
                    }
                    onClose={(e, reason) => {
                      if (reason === "blur") {
                        handleAddNew();
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        className={classes.root}
                        style={{ width: "90%" }}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused",
                          },
                        }}
                        {...params}
                        label="Service Description"
                      />
                    )}
                  />
                )}
                {otherServiceSelect && (
                  <TextField
                    style={{ width: "90%" }}
                    error={validationError.insuranceProvider}
                    required
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      endAdornment: (
                        <CancelIcon
                          onClick={() => setOtherServiceSelect(false)}
                        />
                      ),
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused",
                      },
                    }}
                    label="Add New Service"
                    value={values.otherservicename}
                    onChange={(e) =>
                      onChange(e.target.value, "otherservicename")
                    }
                    name="otherservicename"
                    id="otherservicename"
                  />
                )}
              </Grid> */}
              </Grid>
              <Grid
                container
                className={classes.margin}
                style={{ marginTop: "2%", marginBottom: "3%" }}
              >
                <Grid item xs align="center">
                  <Box className={classes.buttonBox}>
                    <ButtonOriginal
                      variant="outlined"
                      style={{
                        height: "30px",
                        marginRight: "1%",
                        textTransform: "none"
                      }}
                      // className={classes.clearButton}
                      size="large"
                      onClick={() => handleResetValues(false)}
                    >
                      Reset
                    </ButtonOriginal>
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      style={{ backgroundColor: "#03A430" }}
                      size="large"
                      onClick={() => onFormSubmit()}
                    >
                      Create
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </div>
        </Modal>
        <Modal open={validationModal} onClose={() => setValidationModal(false)}>
          <div className={classes.modalDiv}>
            <Card className={classes.modalCard}>
              <div className={classes.cardDiv} align="center">
                {!props.formData.addProposalData.status ? (
                  <ErrorIcon style={{ fontSize: "75px", color: "#FF0000" }} />
                ) : (
                  <img alt="" src={gif} width="50%" />
                )}
                <div className={classes.successText}>
                  {props.formData.addProposalData.message}
                </div>
                <br />
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  size="sm"
                  onClick={() => handleCloseModel()}
                >
                  Ok
                </Button>
              </div>
            </Card>
          </div>
        </Modal>
      </Grid>
    </>
  );
}

ProposalTable.propTypes = {
  showOfferDEtails: PropTypes.any,
  getGenrateOfferData: PropTypes.func,
  setDefaultOfferValue: PropTypes.func,
  setPropsalData: PropTypes.func,
  disableAction: PropTypes.bool,
  handlePrePosalReset: PropTypes.func,
  genrateOfferHyperLink: PropTypes.func,
  tableData: PropTypes.object,
  setOfferId: PropTypes.func,
  cptCodeDataCall: PropTypes.func,
  addProposalCall: PropTypes.func,
  patientSearch: PropTypes.func,
  // offerId: PropTypes.object,
  userProvider: PropTypes.object,
  formData: PropTypes.object,
  setLoadingFalse: PropTypes.func,
  setClickLink: PropTypes.func,
  app: PropTypes.object
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  formData: app.genrateOfferPatient,
  app: app.app
});

export default connect(mapStateToProps, {
  cptCodeDataCall,
  addProposalCall,
  setDefaultOfferValue
})(ProposalTable);
