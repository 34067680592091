/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import isEmpty from "lodash/isEmpty";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  printOfferCall,
  emptyPatientSelect,
  patientPaymentDetailsCall
} from "Redux/Actions/genrateOfferPatient";
import { getOfferDetailsPatient } from "Redux/Actions/PatientDashboard";

import fullOrigin from "../../assets/img/imgpsh_fullsize_anim.png";

const useStyles = makeStyles(styles);
const PaymentConfirmation = (props) => {
  const classes = useStyles();
  const [logoPath, setLogoPath] = React.useState("");
  const handleBackToSearch = () => {
    localStorage.removeItem("payType");
    if (!props.patientDashboard.offerAccountStatusData) {
      if (props.formData.emailState) {
        props.history.push("/");
      } else if (props.formData.genrateOfferData.data[0].payType === 1) {
        // props.state();
        props.emptyPatientSelect();
        props.history.push("/admin/Offers");
      } else {
        const printRequest = {
          patientId: props.formData.paymentDetailsData.data.patientId,
          offerId: props.formData.paymentDetailsData.data.offerId
        };
        props.printOfferCall(printRequest);
        props.history.push("/admin/printoffer");
        // props.state();
      }
    } else {
      props.history.push("/patient/Dashboard");
      // props.state();
    }
  };

  let string = "";
  if (props.values.cardNumber !== "") {
    string = `${
      props.values.paymentType === "cc" ? "credit" : "debit"
    } card ending with ${
      props.values.cardNumber.split(" ")[
        props.values.cardNumber.split(" ").length - 1
      ]
    }`;
  } else {
    string = `Account ending with ${props.values.bankAccountNumber.substr(-4)}`;
  }

  const handleMonthlyPayment = () => {
    localStorage.removeItem("payType");
    const data = {
      patientId: props.tableData.genrateOfferData.data[0].patientId,
      offerId: props.tableData.genrateOfferData.data[0].offerId,
      amount: monthlyPayment,
      paymentId: "",
      monthlyPayment: monthlyPayment,
      payType: 3
    };
    const changesetupdata = {
      offerId: props.tableData.genrateOfferData.data[0].offerId
    };
    props.getOfferDetailsPatient(changesetupdata);
    props.patientPaymentDetailsCall(data);
    props.setConfirmation();
    props.setShowPaymentDetails();
    props.setPaymentStatus();
    props.history.push("/patient/patientPaymentDetails");
  };

  let monthlyPayment =
    !props.patientDashboard.offerAccountStatusData &&
    (props.formData.emailState
      ? props.formData.showPatientDetailsViaMail.data[0].Loan_Details
          .Monthly_Payment
      : props.formData.genrateOfferData.data[0].Recourse[0].offers[0]
          .monthlyPayment);

  if (props.patientDashboard.offerAccountStatusData) {
    monthlyPayment =
      props.patientDashboard.offerAccountStatusData.data[0].monthlyPayment;
  }
  // let monthlyPayment = props.tableData.paymentDetailsData.data.monthlyPayment;
  if (isEmpty(logoPath)) {
    const logo_path = new Image();
    if (props.app.portalConfig) {
      const x = props.app.portalConfig.data[0]?.Logo_Image;
      logo_path.src = `${x}?" + ${new Date().getTime()}`;
      logo_path.onload = function () {
        setLogoPath(logo_path.src);
      };
      logo_path.onerror = function () {
        setLogoPath(fullOrigin);
      };
    }
  }
console.log("PaymentPorps2",props)
const payTypeValue = localStorage.getItem("payType");
  return (
    <>
      <Grid container style={{ margin: "1% 0% 0% 0%" }}>
        <Grid iten xs={2} />
        <Grid iten xs={8} align="center">
          <img src={logoPath} alt="" style={{ width: "150px" }} />
        </Grid>
        <Grid iten xs={2} />
      </Grid>
      <Grid container style={{ margin: "1% 0% 0% 0%" }}>
        <Grid iten xs={2} />
        <Grid iten xs={8} align="center">
          <CheckCircleIcon style={{ fontSize: "75px", color: "#3aae3a" }} />
        </Grid>
        <Grid iten xs={2} />
      </Grid>
      <Grid container>
        <Grid iten xs={1} />
        <Grid
          item
          xs={10}
          style={{
            fontSize: "16px",
            color: "#3a57a7",
            fontFamily: "Source Sans Pro"
          }}
        >
          <div align="left">
            <div align="left">{`Thank you ${props.values.firstName}`}</div>
            <br />
            {(props.formData.emailState ||
              (!props.formData.setUpMonthltFromOffer &&
                props.tableData.paymentDetailsData &&
                payTypeValue ==
                  process.env.REACT_APP_MONTHLY_OFFER_TYPE)) && (
              <div>
                {`Your ${
                  props.paymentMode === "Downpay"
                    ? "Down payment of"
                    : "monthly payment"
                }`}{" "}
                <b style={{ fontSize: "22px" }}>
                  {props.paymentMode === "Downpay"
                    ? props.formData.paymentDetailsData.data.amount
                    : `${monthlyPayment}`}
                </b>{" "}
                against extended payment no.{" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentDetailsData.data.offerNumber}
                </b>{" "}
                {`has been ${
                  props.paymentMode === "Downpay" ? "processed" : "setup"
                } successfully on`}{" "}
                {string}. Your Payment reference number is{" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentMakeDetails.data.Confirmation}
                </b>
                .
                <br />
                <br />
                {props.paymentMode === "Downpay" &&
                  !props.formData.setUpMonthltFromOffer &&
                  !props.formData.emailState && (
                    <>
                      Monthly payment of{" "}
                      <b style={{ fontSize: "22px" }}>{`${monthlyPayment}`}</b>{" "}
                      is also setup on this{" "}
                      {props.values.cardNumber !== "" ? "card" : "account"}. If
                      you want to set that up differently click on button
                      "Change Monthly Setup". You can also do it anytime later
                      by logging on to your TPO Portal.
                    </>
                  )}
              </div>
            )}
            {props.patientDashboard.offerAccountStatusData && (
              <div>
                {`Your ${
                  props.paymentMode === "Downpay"
                    ? "Down payment of"
                    : "monthly payment"
                }`}{" "}
                <b style={{ fontSize: "22px" }}>{`${monthlyPayment}`}</b>{" "}
                against extended payment no.{" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentDetailsData.data.offerNumber}
                </b>{" "}
                {`has been ${
                  props.paymentMode === "Downpay" ? "processed" : "setup"
                } successfully on`}{" "}
                {string}. Your Payment reference number is{" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentMakeDetails.data.Confirmation}
                </b>
                .
                <br />
                <br />
                {props.paymentMode === "Downpay" &&
                  !props.formData.setUpMonthltFromOffer &&
                  !props.formData.emailState && (
                    <>
                      <b style={{ fontSize: "22px" }}>
                        Monthly payment of {`${monthlyPayment}`} is also setup
                        on this{" "}
                        {props.values.cardNumber !== "" ? "card" : "account"}.
                        If you want to set that up differently click on button
                        "Change Monthly Setup". You can also do it anytime later
                        by logging on to your TPO Portal.
                      </b>
                    </>
                  )}
              </div>
            )}
            {props.formData.setUpMonthltFromOffer && (
              <div>
                {`Your ${
                  props.paymentMode === "Downpay"
                    ? "Down payment of"
                    : "monthly payment"
                }`}{" "}
                <b style={{ fontSize: "22px" }}>
                  {props.paymentMode === "Downpay"
                    ? props.formData.paymentDetailsData.data.amount
                    : `${monthlyPayment}`}
                </b>{" "}
                against extended payment no.{" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentDetailsData.data.offerNumber}
                </b>{" "}
                {`has been ${
                  props.paymentMode === "Downpay" ? "processed" : "setup"
                }processed successfully on`}{" "}
                {string}. Your Payment reference number is{" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentMakeDetails.data.Confirmation}
                </b>
                .
              </div>
            )}
            {!props.formData.emailState &&
              props.formData.genrateOfferData &&
              props.tableData.paymentDetailsData.data.payType == "3" && (
                <div>
                  {"Your monthly payment of"}{" "}
                  <b style={{ fontSize: "22px" }}>
                    {/* {props.paymentMode === "Downpay"
                      ? props.formData.paymentDetailsData.data.amount
                      : monthlyPayment} */}
                    {monthlyPayment}
                  </b>{" "}
                  against extended payment no.{" "}
                  <b style={{ fontSize: "22px" }}>
                    {props.formData.paymentDetailsData.data.offerNumber}
                  </b>{" "}
                  has been setup successfully on card ending with XXXX-
                  {props.tableData.paymentDetailsData &&
                    props.tableData.paymentDetailsData.data.Last4CardNumber}
                  . If you want to change this monthly payment method, You can
                  do it anytime later by logging on to your TPO Portal.
                </div>
              )}
            {!props.formData.emailState &&
              props.formData.genrateOfferData &&
              props.formData.genrateOfferData.data[0].payType ==
                process.env.REACT_APP_DISCOUNT_OFFER_TYPE && (
                <div>
                  {"Your full payment after discount of"}{" "}
                  <b style={{ fontSize: "22px" }}>{props.values.amount}</b>{" "}
                  against extended payment no.{" "}
                  <b style={{ fontSize: "22px" }}>
                    {props.formData.paymentDetailsData.data.offerNumber}
                  </b>{" "}
                  has been processed successfully . Your Payment reference
                  number is{" "}
                  <b style={{ fontSize: "22px" }}>
                    {props.formData.paymentMakeDetails.data.Confirmation}
                  </b>
                  .
                </div>
              )}
          </div>
        </Grid>
        <Grid iten xs={1} />
      </Grid>
      <Grid container style={{ marginTop: "3%", marginBottom: "10%" }}>
        <Grid iten xs={3} />
        <Grid
          item
          xs={6}
          style={{
            display: "flex",
            justifyContent: `${
              !props.patientDashboard.offerAccountStatusData &&
              (props.formData.emailState ||
                props.formData.genrateOfferData.data[0].payType === 1 ||
                props.paymentMode !== "Downpay")
                ? "space-around"
                : "space-around"
            }`
          }}
        >
          <Button
            variant="contained"
            onClick={() => handleBackToSearch()}
            className={classes.clearButton}
            size="large"
            align="center"
          >
            {`${!props.formData.emailState ? "Next" : "Close"}`}
          </Button>
          {!props.patientDashboard.offerAccountStatusData &&
            !props.formData.emailState &&
            props.formData.genrateOfferData.data[0].payType !== 1 && (
              <Button
                variant="contained"
                onClick={() => handleMonthlyPayment()}
                size="large"
              >
                Change Monthly Setup
              </Button>
            )}
        </Grid>
      </Grid>
    </>
  );
};

PaymentConfirmation.propTypes = {
  formData: PropTypes.object,
  history: PropTypes.object,
  state: PropTypes.func,
  values: PropTypes.object,
  setConfirmation: PropTypes.func,
  setShowPaymentDetails: PropTypes.func,
  setPaymentStatus: PropTypes.func,
  paymentMode: PropTypes.any,
  printOfferCall: PropTypes.func,
  emptyPatientSelect: PropTypes.func,
  patientDashboard: PropTypes.object,
  app: PropTypes.object,
  patientPaymentDetailsCall: PropTypes.func,
  getOfferDetailsPatient: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  userProvider: app.users,
  payDetails: app.patientDashboard
});

export default connect(mapStateToProps, {
  printOfferCall,
  emptyPatientSelect,
  patientPaymentDetailsCall,
  getOfferDetailsPatient
})(PaymentConfirmation);

// export default PaymentConfirmation;
