import "../../assets/css/CountryCode.scss";

import "date-fns";
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@material-ui/core";
// import Card from "components/Card/Card";
import { useSnackbar } from "notistack";
import CustomButton from "components/CustomButtons/Button";
import ButtonOriginal from "@material-ui/core/Button";
import CardIcon from "components/Card/CardIcon";
import Avatar from "@material-ui/core/Avatar";
import CardHeader from "components/Card/CardHeader";
import { ErrorMessage, Field, Form, Formik } from "formik";
import isEmpty from "lodash";
import Divider from "@material-ui/core/Divider";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
// import CancelIcon from "@material-ui/icons/Cancel";

import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import InputAdornment from "@material-ui/core/InputAdornment";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { addNewPatientCall } from "../../actions/Patient.action";
import PatientStyle from "./PatientStyle";
import { getCountryList } from "../../actions/login.action";
import { getCountryTypeList } from "../../Redux/Actions/UserRegistration";
import { locationCity } from "../../constant/locationConstant";
import Validation from "../../Utils/Validations";
import { getAxiosAPI } from "../../actions/AxiosAPIMethods";
import { cptCodeDataCall } from "Redux/Actions/genrateOfferPatient";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import moment from "moment";

const useStyles = makeStyles(PatientStyle);
const AddPatientForm = (props) => {
  const formikRef = React.useRef(null);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [countryList, setCountryList] = React.useState([]);
  const [initialSubmit, setInitialSubmit] = React.useState(false);
  const [insuranceCarrierData, setInsuranceCarrierData] = React.useState([]);
  const [initialValues, setInitialValues] = React.useState({
    firstName: "",
    lastName: "",
    ssn: "",
    mobile: "",
    confirmSsn: "",
    email: "",
    dob: null,
    address1: "",
    address2: "",
    country: "US",
    state: "",
    city: "",
    zip: "",
    responsibilty: "",
    provider: "",
    insuranceProvider: "",
    insuranceCarrier: "",
    otherservicename: "",
    service: "",
    countryCode: 1,
  });

  const checkValidation = (error) => {
    const tempData = {};
    error.map((err) => {
      tempData[err.param] = err.msg;
    });
    formikRef.current.setErrors(tempData);
  };

  const onFormSubmit = (values, { setFieldError }) => {
    const data = {
      firstName: values.firstName,
      lastName: values.lastName,
      ssn: values.ssn,
      mobile: values.mobile.replace(/-/g, ""),
      email: values.email,
      dob: moment(values.dob).format("MM/DD/YYYY"),
      address1: values.address1,
      address2: values.address2,
      country: values.country,
      state: values.state,
      city: values.city,
      zip: values.zip,
      responsibilty: values.responsibilty,
      provider: values.provider,
      insuranceProvider: values.insuranceCarrier,
      insuranceCarrier: values.insuranceCarrier,
      otherservicename: "",
      service: "",
      countryCode: values.countryCode,
    };
    if (values.otherservicename !== "") {
      data.service = "other";
      data.otherservicename = values.otherservicename;
    } else {
      data.service = values.service;
      data.otherservicename = "";
    }
    props.addNewPatientCall(
      data,
      (response) => {
        if (response.status) {
          enqueueSnackbar("Patient registered successfully.", {
            variant: "success",
          });
          props.history.push("/admin/Offers");
        }
      },
      (errors) => {
        if (errors.message === "Patient form validation error.") {
          errors.errors.map((x) => {
            if (x.location === "body") {
              setFieldError(x.param, x.msg);
            }
          });
        } else {
          enqueueSnackbar(errors.message, {
            variant: "error",
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
          });
        }
      }
    );
  };

  function formatSocialSecurity(v) {
    let r = v.replace(/\D/g, "");
    if (r.length > 9) {
      r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    } if (r.length > 4) {
      r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  function formatPhoneNumber(v) {
    let r = v.replace(/\D/g, "");
    if (r.length > 11) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    }
    if (r.length > 6) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 3) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  const getInsuranceCarrierList = async () => {
    const url = "/insurance-carrier";
    await getAxiosAPI(url).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setInsuranceCarrierData(res.data.State_Data);
      }
    });
  };

  const handleChange = (prop) => (event) => {
    setInitialValues({ ...initialValues, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setInitialValues({
      ...initialValues,
      showPassword: !initialValues.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  React.useEffect(() => {
    if (!props.formData.countryTypeList) {
      props.getCountryTypeList();
    }
    if (isEmpty(countryList)) {
      props.getCountryList((data) => {
        setCountryList(data.Country_Data);
      });
    }
    getInsuranceCarrierList();
    props.cptCodeDataCall();
  }, []);

  const defaultProps = {
    options: props.formData.cptCodeData.State_Data,
  };
  const handleSSNFocus = (e) => {
    e.preventDefault();
    e.target.select();
  };
  return (
    <>
      <CardHeader className={classes.headerCardIcon}>
        <CardIcon className={classes.avatarIcon}>
          <Avatar alt="Remy Sharp" />
        </CardIcon>
      </CardHeader>
      <Paper className={classes.loginFormPaper}>
        <Grid
          container
          sm={12}
          xs={12}
          alignItems="center"
          justify="flex-start"
          className={classes.addPatientBackButton}
        >
          <Grid item>
            <Button
              variant="contained"
              size="small"
              style={{ textTransform: "none" }}
              className={classes.clearButton}
              onClick={() => props.history.goBack()}
            >
              <Typography component="p">Back</Typography>
            </Button>
          </Grid>
        </Grid>
        <Formik
          innerRef={(p) => {
            formikRef.current = p;
          }}
          onSubmit={onFormSubmit}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Validation.addPatient}
        >
          {({
            errors,
            setFieldValue,
            values,
            resetForm,
            touched,
            handleSubmit,
          }) => (
            <Form>
              <Grid className={classes.addPatientForm} spacing={1} container>
                <Grid item xs={12} sm={3} className={classes.cardMargin}>
                  <Typography
                    style={{
                      marginBottom:"5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    First Name <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="First Name"
                    error={
                      initialSubmit && errors.firstName && touched.firstName
                    }
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                      },
                    }}
                    // label="First Name"
                    name="firstName"
                    id="firstName"
                  />
                  <ErrorMessage name="firstName">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={3} className={classes.cardMargin}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Last Name <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Last Name"
                    error={initialSubmit && errors.lastName && touched.lastName}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                      },
                    }}
                    // label="Last Name"
                    name="lastName"
                    id="lastName"
                  />
                  <ErrorMessage name="lastName">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.cardMargin}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    SSN<p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  {/* <MenuBookRoundedIcon
                      className={classes.inputBox}
                      fontSize="small"
                    /> */}
                  {/* <TextField
                      // disabled={props.disabled}
                      size="small"
                      fullWidth
                      variant="outlined"
                      placeholder="SSN"
                      required
                      className={classes.root}
                      error={initialSubmit && errors.ssn && touched.ssn}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      inputProps={{
                        autoComplete: "off",
                        maxLength: 11,
                      }}
                      // label="Social Security Number (SSN)"
                      // helperText="Enter last four digits of SSN"
                      value={props.data.Owner_Last4_SSN}
                      onChange={(e) =>
                        props.handleOwnerChange(
                          e.target.value,
                          "Owner_Last4_SSN",
                          e
                        )
                      }
                      helperText={
                        props.validationError.Owner_Last4_SSN &&
                        "Owner SSN is Required."
                      }
                      onFocus={(e) => handleSSNFocus(e)}
                      name="Owner_Last4_SSN"
                      id="Owner_Last4_SSN"
                      type="text"
                    /> */}

                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="SSN"
                    error={initialSubmit && errors.ssn && touched.ssn}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                      },
                    }}
                    onChange={event => {
                      if (
                        event.nativeEvent.inputType === "deleteContentBackward"
                        || event.nativeEvent.inputType === "deleteContentForward"
                      ) {
                        setFieldValue("ssn", "");
                      } else {
                        setFieldValue("ssn", formatSocialSecurity(event.target.value));
                      }
                    }}
                    name="ssn"
                    id="ssn"
                  />
                  <ErrorMessage name="ssn">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} sm={3} className={classes.cardMargin}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Confirm SSN <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  {/* <FormControl
                    placeholder="SSN"
                    size="small"
                    variant="outlined"
                    error={initialSubmit && errors.ssn && touched.ssn}
                    name="ssn"
                    id="ssn"
                    
                  >
                    <OutlinedInput
                      // label="SSN"
                      size="small"
                      type={initialValues.showPassword ? "snn" : "password"}
                      value={initialValues.password}
                      onChange={handleChange("password")}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            // aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {initialValues.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      // label="Password"
                    
                    />
                  </FormControl> */}
                  {/* <ErrorMessage name="ssn">
                      {(msg) => (
                        <Typography
                          component="span"
                          color="error"
                          variant="body2"
                        >
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage> */}
                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Confirm SSN"
                    onChange={event => {
                      if (
                        event.nativeEvent.inputType === "deleteContentBackward"
                        || event.nativeEvent.inputType === "deleteContentForward"
                      ) {
                        setFieldValue("confirmSsn", "");
                      } else {
                        setFieldValue("confirmSsn", formatSocialSecurity(event.target.value));
                      }
                    }}
                    error={
                      initialSubmit && errors.confirmSsn && touched.confirmSsn
                    }
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                      },
                    }}
                    name="confirmSsn"
                    id="confirmSsn"
                  />
                  <ErrorMessage name="confirmSsn">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={3} className={classes.cardMargin}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Mobile <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>

                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Mobile"
                    error={initialSubmit && errors.mobile && touched.mobile}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    onChange={(e) => {
                      setFieldValue(
                        "mobile",
                        formatPhoneNumber(e.target.value)
                      );
                    }}
                    // label="Mobile"
                    name="mobile"
                    id="mobile"
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Select
                            id="country-menu"
                            value={values.countryCode}
                            onChange={(event) => {
                              setFieldValue("countryCode", event.target.value);
                            }}
                            classes={{ root: "country-list" }}
                            className="no-underline"
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                          >
                            {countryList.map((country) => (
                              <MenuItem
                                value={country.Dial_Code}
                                option={countryList}
                                key={country.Country_Code}
                                className="country"
                              >
                                <span
                                  className={`country-flag ${country.Country_Code.toLowerCase().substring(
                                    0,
                                    2
                                  )}`}
                                />
                                &nbsp;
                                <span className="dial-code">{`+${country.Dial_Code}`}</span>
                                &nbsp;
                                <span className="country-name">
                                  ({country.Country_Name})
                                </span>
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage name="mobile">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.cardMargin}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Email <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Email"
                    error={initialSubmit && errors.email && touched.email}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                      },
                    }}
                    // label="Email"
                    type="email"
                    name="email"
                    id="email"
                  />
                  <ErrorMessage name="email">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={3} className={classes.cardMargin}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Date Of Birth <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  <MuiPickersUtilsProvider
                    variant="outlined"
                    utils={DateFnsUtils}
                  >
                    <KeyboardDatePicker
                      as={TextField}
                      autoOk
                      size="small"
                      fullWidth
                      maxDate={new Date()}
                      placeholder="Date Of Birth"
                      inputVariant="outlined"
                      value={values.dob}
                      onChange={(value) => setFieldValue("dob", value)}
                      views={["year", "month", "date"]}
                      // label="Date Of Birth"
                      format="MM/dd/yyyy"
                      name="dob"
                      id="dob"
                    />
                  </MuiPickersUtilsProvider>
                  <ErrorMessage name="dob">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.cardMargin}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Address1 <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Address1"
                    error={initialSubmit && errors.address1 && touched.address1}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused",
                      },
                    }}
                    name="address1"
                    id="address1"
                  />
                  <ErrorMessage name="address1">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.cardMargin}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                    }}
                  >
                    Address2
                  </Typography>
                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Address2"
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused",
                      },
                    }}
                    // label="Address2"
                    name="address2"
                    id="address2"
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Country <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>

                  <Autocomplete
                    fullWidth
                    size="small"
                    error={initialSubmit && errors.country && touched.country}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      color: "secondary",
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused",
                      },
                    }}
                    // label="Country"
                    name="country"
                    placeholder="Country"
                    id="country"
                    value={
                      countryList.find(
                        (option) => option.Country_Code === values.country
                      ) || {}
                    }
                    onChange={(_, value) => {
                      value && setFieldValue("country", value.Country_Code);
                    }}
                    options={countryList}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.Country_Name}
                    renderOption={(option) => (
                      <>
                        <span
                          className={`country-flag ${option.Country_Code.toLowerCase().substring(
                            0,
                            2
                          )}`}
                        />
                        &nbsp;
                        <span className="country-name">
                          {option.Country_Name}
                        </span>
                        &nbsp;
                        <span className="dial-code">{`(${option.Country_Code})`}</span>
                      </>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Choose a country"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />

                  <ErrorMessage name="country">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={6} sm={3}>
                  {/* <Autocomplete
                    // disablePortal
                    id="state"
                    options={
                      props.userProvider.userProvider &&
                      props.userProvider.userProvider.data.map((key) => (
                        <MenuItem value={key.Porvider_Id}>
                          {key.ProviderData}
                        </MenuItem>
                      ))
                    }
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="State"
                        label="State"
                      />
                    )}
                  /> */}
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    State <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Date Of Birth"
                    select={values.country === "US"}
                    error={initialSubmit && errors.state && touched.state}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    // label="State"
                    name="state"
                    id="state"
                  >
                    {locationCity.map((key) => (
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value={key.value}
                      >
                        {key.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorMessage name="state">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    City <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="City"
                    error={initialSubmit && errors.city && touched.city}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused",
                      },
                    }}
                    // label="City"
                    name="city"
                    id="city"
                  />
                  <ErrorMessage name="city">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={6} sm={3}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Zip <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Zip"
                    error={initialSubmit && errors.zip && touched.zip}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused",
                      },
                    }}
                    // label="Zip"
                    name="zip"
                    id="zip"
                  />
                  <ErrorMessage name="zip">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <Divider />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.cardMargin}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Responsibilty ($) <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Responsibilty ($)"
                    error={
                      initialSubmit &&
                      errors.responsibilty &&
                      touched.responsibilty
                    }
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused",
                      },
                    }}
                    // label="Responsibilty ($)"
                    name="responsibilty"
                    id="responsibilty"
                  />
                  <ErrorMessage name="responsibilty">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.cardMargin}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Provider <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  {/* <Autocomplete
                    id="highlights-demo"
                    sx={{ width: 300 }}
                    options={
                      props.userProvider.userProvider &&
                      props.userProvider.userProvider.data
                    }
                    getOptionLabel={(option) => option.ProviderData}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Insurance Carrier"
                        // margin="normal"
                        variant="outlined"
                        size="small"
                        placeholder="Provider"
                        
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setFieldValue("provider", newValue.ProviderData);
                        // setFieldValue("otherservicename", newValue);
                      } else if (newValue && newValue.Porvider_Id) {
                        setFieldValue("provider", newValue.ProviderData);
                        // setFieldValue("otherservicename", "");
                      } else {
                        setFieldValue("provider", newValue.ProviderData);
                        // setFieldValue("otherservicename", newValue);
                      }
                    }}
                    renderOption={(props, option) => {
                      const matches = match(
                        props.ProviderData,
                        option.inputValue
                      );
                      const parts = parse(props.ProviderData, matches);
                      return (
                        <li {...props}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                value={part.Porvider_Id}
                                // style={{
                                //   background: part.highlight ? "#f3ff0070" : "",
                                //   fontWeight: part.highlight ? 400 : "",
                                //   fontSize: "14px",
                                // }}
                              >
                                {part.ProviderData}
                              </span>
                            ))}
                          </div>
                        </li>
                      );
                    }}
                  /> */}
                  <Field
                    as={TextField}
                    size="small"
                    fullWidth
                    variant="outlined"
                    placeholder="Provider"
                    error={initialSubmit && errors.provider && touched.provider}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,

                        focused: "focused",
                      },
                    }}
                    select
                    // label="Provider"
                    name="provider"
                    id="provider"
                  >
                    {props.userProvider.userProvider &&
                      props.userProvider.userProvider.data
                        .sort((a, b) =>
                          a.ProviderData.toString().localeCompare(
                            b.ProviderData.toString()
                          )
                        )
                        .map((key) => (
                          <MenuItem value={key.Porvider_Id}>
                            {key.ProviderData}
                          </MenuItem>
                        ))}
                  </Field>
                  <ErrorMessage name="provider">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Insurance Carrier <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  <Autocomplete
                    id="highlights-demo"
                    sx={{ width: 300 }}
                    options={insuranceCarrierData.sort((a, b) =>
                      a.name.toString().localeCompare(b.name.toString())
                    )}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Insurance Carrier"
                        // margin="normal"
                        variant="outlined"
                        size="small"
                        placeholder="Insurance Carrier"
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setFieldValue("insuranceCarrier", newValue.name);
                        // setFieldValue("otherservicename", newValue);
                      } else if (newValue && newValue.cptCode) {
                        setFieldValue("insuranceCarrier", newValue.name);
                        // setFieldValue("otherservicename", "");
                      } else {
                        setFieldValue("insuranceCarrier", newValue.name);
                        // setFieldValue("otherservicename", newValue);
                      }
                    }}
                    renderOption={(props, option) => {
                      const matches = match(props.name, option.inputValue);
                      const parts = parse(props.name, matches);
                      return (
                        <li {...props}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  background: part.highlight ? "#f3ff0070" : "",
                                  fontWeight: part.highlight ? 400 : "",
                                  fontSize: "14px",
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </li>
                      );
                    }}
                  />
                  <ErrorMessage name="insuranceCarrier">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography
                    style={{
                      marginBottom: "5px",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    Service Description <p style={{ color: "red",marginBottom:"0px",marginTop:"0px" }}> * </p>
                  </Typography>
                  <Autocomplete
                    id="highlights-demo"
                    sx={{ width: 300 }}
                    options={
                      defaultProps.options &&
                      defaultProps.options.sort((a, b) =>
                        a.cptDescription
                          .toString()
                          .localeCompare(b.cptDescription.toString())
                      )
                    }
                    getOptionLabel={(option) => option.cptDescription}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // label="Service Description"
                        // margin="normal"
                        variant="outlined"
                        size="small"
                        placeholder="Service Description"
                      />
                    )}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setFieldValue("service", newValue);
                        setFieldValue("otherservicename", newValue);
                      } else if (newValue && newValue.cptDescription) {
                        setFieldValue("service", newValue.cptDescription);
                        setFieldValue(
                          "otherservicename",
                          newValue.cptDescription
                        );
                      } else {
                        setFieldValue("service", newValue);
                        setFieldValue("otherservicename", newValue);
                      }
                    }}
                    renderOption={(props, option) => {
                      const matches = match(
                        props.cptDescription,
                        option.inputValue
                      );
                      const parts = parse(props.cptDescription, matches);
                      return (
                        <li {...props}>
                          <div>
                            {parts.map((part, index) => (
                              <span
                                key={index}
                                style={{
                                  background: part.highlight ? "#f3ff0070" : "",
                                  fontWeight: part.highlight ? 400 : "",
                                  fontSize: "14px",
                                }}
                              >
                                {part.text}
                              </span>
                            ))}
                          </div>
                        </li>
                      );
                    }}
                  />
                  <ErrorMessage name="service">
                    {(msg) => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
              </Grid>
              <Box className={classes.buttonBox}>
                {/* <CustomButton
                  variant="outlined"
                  className={classes.clearButton}
                  size="large"
                  onClick={() => props.history.goBack()}
                >
                  Back
                </CustomButton> */}
                <ButtonOriginal
                  variant="outlined"
                  size="large"
                  style={{
                    height: "2.1em",
                    marginTop: "5px",
                    textTransform: "none",
                  }}
                  onClick={() => {
                    resetForm();
                    // props.history.goBack();
                  }}
                >
                  Reset
                </ButtonOriginal>
                <CustomButton
                  variant="contained"
                  className={classes.buttonStyle}
                  style={{ backgroundColor: "#03A430 !important" }}
                  type="submit"
                  size="large"
                >
                  Register
                </CustomButton>
              </Box>
              <Grid item xs={12} sm={12} />
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
};

AddPatientForm.propTypes = {
  formData: PropTypes.object,
  history: PropTypes.object,
  setPatiemtModal: PropTypes.func,
  userProvider: PropTypes.object,
  getCountryTypeList: PropTypes.func,
  addNewPatientCall: PropTypes.func,
  getCountryList: PropTypes.func,
  cptCodeDataCall: PropTypes.func,
};

const mapStateToProps = (app) => ({
  formData: app.genrateOfferPatient,
  userProvider: app.users,
});

export default connect(mapStateToProps, {
  getCountryTypeList,
  addNewPatientCall,
  getCountryList,
  cptCodeDataCall,
})(AddPatientForm);
