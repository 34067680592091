import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {
  Typography,
  TextField,
  InputAdornment,
  Button,
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import SearchIcon from "@material-ui/icons/Search";
import Style from "./style";
import "./products.css";
import StarImage from "../../assets/img/star.png";
import VivoBookPCImage from "../../assets/tempImages/vivobook.jpg";
import HeroBookProImage from "../../assets/tempImages/herobook-pro.jpg";
import LenovoTabletImage from "../../assets/tempImages/lenovo-tablet.jpg";

import GooglePixelImage from "../../assets/tempImages/google-pixel.jpg";
import WillfulSmartWatchImage from "../../assets/tempImages/willful-smartwatch.jpg";
import AmazfitSmartWatchImage from "../../assets/tempImages/amazfit-smartwatch.jpg";

const useStyles = makeStyles(Style);
const dummyProducts = [
  {
    qa: 5,
    totalRating: 24533,
    id: "123450",
    image: VivoBookPCImage,
    title:
      "ASUS VivoBook 15 Thin and Light Laptop- 15.6” Full HD, Intel i5-1035G1 CPU, 8GB RAM, 512GB SSD, Backlit KeyBoard, Fingerprint, Windows 10- F512JA-AS54, Slate Gray",
    rating: 5,
    price: 599.99,
    productURL: "#",
    shippingLocation: "United States of America",
  },
  {
    qa: 10,
    totalRating: 67932,
    id: "123452",
    image: WillfulSmartWatchImage,
    title:
      "Willful Smart Watch for Android Phones and iOS Phones Compatible iPhone Samsung, IP68 Swimming Waterproof Smartwatch Fitness Tracker Fitness Watch Heart Rate Monitor Smart Watches for Men Women Black",
    rating: 4,
    price: 35.99,
    productURL: "#",
    shippingLocation: "United States of America",
  },
  {
    qa: 15,
    totalRating: 24533,
    id: "123454",
    image: GooglePixelImage,
    title:
      "Google Pixel 4a - New Unlocked Android Smartphone - 128 GB of Storage - Up to 24 Hour Battery - Just Black",
    rating: 4,
    price: 53.0,
    productURL: "#",
    shippingLocation: "United States of America",
  },
  {
    qa: 8,
    totalRating: 1500,
    id: "123455",
    image: HeroBookProImage,
    title:
      "CHUWI HeroBook Pro 14.1 inch Windows 10 Laptop Computer, 8G RAM / 256GB SSD with Intel Gmini Lake N4000 Notebook, Thin and Light",
    rating: 4,
    price: 349.0,
    productURL: "#",
    shippingLocation: "United States of America",
  },
  {
    qa: 12,
    totalRating: 200,
    id: "123456",
    image: LenovoTabletImage,
    title:
      'Lenovo Tab M8 Tablet, 8" HD Android Tablet, Quad-Core Processor, 2GHz, 16GB Storage, Full Metal Cover, Long Battery Life, Android 9 Pie, ZA5G0102US, Slate Black',
    rating: 4,
    price: 89.99,
    productURL: "#",
    shippingLocation: "United States of America",
  },
  {
    qa: 29,
    totalRating: 57563,
    id: "123457",
    image: AmazfitSmartWatchImage,
    title:
      'Amazfit Bip U Pro Smart Watch with Built-in GPS, 9-Day Battery Life, Fitness Tracker, Blood Oxygen, Heart Rate, Sleep, Stress Monitor, 60+ Sports Modes, 1.43" Large HD Display',
    rating: 4,
    price: 69.99,
    productURL: "#",
    shippingLocation: "United States of America",
  },
];

export default function Products() {
  const classes = useStyles();

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        <div
          style={{
            padding: "2%",
            display: "flex",
            flexDirection: "column",
            flexWrap: "nowrap",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <Box style={{ width: "35%", marginLeft: "60%" }}>
              <TextField
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                  autoComplete: "off",
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                }}
                fullWidth
                label="Search"
                name="Search"
                id="Search"
              />
            </Box>
          </div>
          <Grid conatiner>
            {dummyProducts.map((item) => (
              <div key={item.id} className="product">
                <div className="product-link" to={item.productURL}>
                  <img
                    src={item.image}
                    style={{
                      maxHeight: "250px",
                      maxWidth: "250px",
                      backgroundSize: "contain",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                    alt=""
                  />
                </div>
                <div className="product-info">
                  <p className="product-title">
                    <Link to={item.productURL}>{item.title}</Link>
                  </p>

                  <div className="product-rating">
                    {Array(item.rating)
                      .fill()
                      .map((_, i) => (
                        <img key={i} src={StarImage} alt="" />
                      ))}
                    <a style={{ fontSize: "12px" }} herf="#">
                      &nbsp;&nbsp;{item.totalRating} ratings | ${item.qa}{" "}
                      answered questions
                    </a>
                  </div>
                  <div className="product-price">
                    <small>&#36;</small>
                    <strong>{item.price}</strong>
                  </div>
                  <div className="shipping-location">
                    Ships to {item.shippingLocation}
                  </div>
                  <div className="product-btn-wrap">
                    {/* <button
                      type="button"
                      className="btn-primary add-cart-btn"
                      // onClick={handleAddToCart}
                    >
                      Add to Cart
                    </button> */}
                    {/* <button
                      type="button"
                      className="btn-primary buy-cart-btn"
                      // onClick={handleBuyNow}
                    >
                      Buy Now
                    </button> */}
                    <Button className="add-cart-btn" variant="contained">
                      Add to Cart
                    </Button>
                    <Button
                      color="primary"
                      style={{
                        width: "192px",
                        height: "30px",
                        flexBasis: "150px",
                      }}
                      variant="contained"
                    >
                      Buy Now
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </Grid>
        </div>
      </TableContainer>
    </>
  );
}
