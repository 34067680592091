import paymentOfferStyle from "../views/PaymentOffer";

const tableStyle = (theme) => ({
  labelFont: {
    fontFamily: "Source Sans Pro"
  },
  table: {
    minWidth: 650,
    borderRadius: "1em",
    overflow: "hidden"
  },
  modalDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "stretch"
  },
  modalPayOut: {
    width: "25%",
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  cardDiv1: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10%",
    marginTop: "10%",
    overflow: "auto",
    textAlign: "center"
  },
  TableContainer: {
    borderRadius: "1em",
    backgroundColor: "#EFF4FF"
    // background: "transparent"
  },
  uppserBoldHeading: {
    fontWeight: "400",
    fontSize: "30px",
    lineHeight: "30px",
    color: "#333333"
  },
  activeCount: {
    fontSize: "16px",
    lineHeight: "20px",
    padding: "5px"
  },
  exportButton: {
    backgroundColor: "#03A430",
    textTransform: "none",
    borderRadius: "25px",
    height: "48px",
    color: "#ffffff",
    fontSize: "18px",
    fontWeight: "400"
  },
  newSearchInput: {
    border: "none",
    padding: "15px 20px",
    background: "transparent"
  },
  upperMiniHeadings: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#27337D"
  },
  ...paymentOfferStyle,
  patientTable: {
    minWidth: 0,
    borderRadius: "0.3em",
    overflow: "hidden"
  },
  newSearchDiv: {
    borderRadius: "24px",
    backgroundColor: "white",
    marginLeft: "20px",
    marginRight: "20px",
    marginTop: "5px",
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: "400",
    paddingRight: "10px",
    display: "flex",
    height: "48px"
  },
  expirtItemGrid: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "5px",
    width: "inherit"
  },
  clearButton: {
    textTransform: "none",
    backgroundColor: theme.palette.primary.dark,
    height: "35px",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark
    }
  },
  statusModalHeader: {
    display: "flex",
    height: "50px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#4856c1",
    padding: "1%"
  },
  statusModalPadding: {
    padding: "1%"
  },
  statusHeadingText: {
    textAlign: "center",
    width: "90%",
    color: "#fff",
    fontSize: "16px"
  },
  statusRoot: {
    maxWidth: "69%"
  },
  marginBackButton: {
    marginTop: "2%"
  },
  cellTextHeader: {
    // fontSize: "80%",
    fontSize: "13px",
    fontFamily: "Source Sans Pro",
    color: theme.palette.primary.dark,
    fontWeight: "bold"
  },
  cellTextHeaderOffer: {
    // fontSize: "80%",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "20px",
    fontFamily: "Source Sans Pro",
    display: "table-cell",
    color: theme.palette.tableColor?.tableHeaderTextColor,
    "&:hover": {
      color: theme.palette.tableColor?.tableHeaderTextColor
    }
  },
  cellText: {
    fontSize: "12.5px",
    fontStyle: "Source Sans Pro",
    color: "#000"
  },
  cellTextNew: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "Source Sans Pro",
    color: "#333333"
  },
  cellTextStatus: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "Source Sans Pro",
    color: "#333333"
  },
  statusButton: {
    borderRadius: "20px",
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "Source Sans Pro",
    backgroundColor: "#008768"
  },
  cellTextPatient: {
    fontSize: "16px",
    fontWeight: "400",
    fontStyle: "Source Sans Pro",
    color: "#27337D"
    // textDecoration: "underline"
  },
  cellTextReport: {
    // fontSize: "80%",

    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "20px",
    fontFamily: "Source Sans Pro",
    color: "#000"
  },
  cellTextStatus: {
    padding: "15px 30px",
    // backgroundColor: "#008768",
    color: "#0000",
    fontSize: "12px",
    margin: "20px 50px",
    fontWeight: "600",
    lineHeight: "16px",
    fontFamily: "Source Sans Pro",
    textTransform: "uppercase"
  },
  cellHeadSign: {
    cursor: "pointer",
    "&:hover": {
      background: "#EFF4FF"
    }
  },
  modalRoot: {
    width: "400",
    height: "auto",
    maxHeight: "400px",
    maxWidth: "450px"
  },
  modalRootAcc: {
    width: "300px",
    height: "auto",
    maxHeight: "400px",
    maxWidth: "450px"
  },
  modalRootXs: {
    width: "80%"
  },
  modalRootUpload: {
    width: "40%",
    marginTop: "10%",
    height: "auto",
    padding: "30px",
    paddingTop: "10px",
    alignItems: "center",
    justifyContent: "center"
  },
  selectDropdown: { fontSize: "14px" },
  menuItem: {
    fontSize: "14px"
  },
  cellTextDiscription: {
    fontSize: "80%",
    fontFamily: "Source Sans Pro",
    color: theme.palette.primary.main
  },
  successText: {
    color: "gray",
    fontSize: "large"
  },
  patientNameColor: {
    // fontSize: "80%",
    fontSize: "12.5px",
    fontStyle: "Source Sans Pro",
    color: theme.palette.tableColor?.clickable
  },
  margin: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-around"
  },
  marginTop: {
    margin: "1%"
  },
  modalDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "stretch"
  },
  modalCard: {
    width: "30%",
    marginTop: "10%",
    height: "auto",
    padding: "30px",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center"
  },
  searchContainer: {
    display: "flex",
    paddingLeft: "10px",
    flexDirection: "row",
    padding: "1%"
    // borderBottom: "1px solid #E3EAF6"
  },
  searchContainerSetup: {
    display: "flex",
    paddingLeft: "10px",
    flexDirection: "row",
    padding: "1%",
    justifyContent: "end",
    borderBottom: "1px solid #E3EAF6"
  },
  buttonStyle: {
    backgroundColor: "#03A430",
    height: "30px",
    color: theme.palette.primary.contrastText,
    "&:focus": {
      backgroundColor: "#03A430"
    }
  },
  ExportButtonStyle: {
    backgroundColor: "#03A430",
    margin: "0.5%",
    color: theme.palette.primary.contrastText,
    "&:focus": {
      backgroundColor: "#03A430"
    }
  },
  activeInputColor: {
    // "& .MuiSelect-nativeInput": {
    //   position: "inherit",
    //   width: "auto",
    // },
  },
  resize: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro",
    color: theme.palette.primary.dark
  },
  dialogBoxDropDown: {
    width: "auto",
    color: theme.palette.primary.dark,
    overflow: "hidden",
    marginRight: "2%",
    marginLeft: "2%",
    fontSize: ".7rem",
    boxSizing: "border-box",
    fontFamily: "Source Sans Pro",
    fontWeight: "200",
    lineHeight: "1.5",
    "&:hover": {
      backgroundColor: "#00aab4",
      color: "#fff"
    }
  },
  searchWrapper: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end"
  },
  root: {
    width: "30%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro"
    }
  },
  rootOverLay: {
    width: "30%",
    padding: "1%",
    position: "absolute",
    zIndex: 5000,
    cursor: "pointer",
    backgroundColor: theme.palette.primary.contrastText
    // boxShadow: "1px 1px 2px white, 0 0 25px #d6d0d0, 0 0 5px #737373",
  },
  rootPagination: {
    flexShrink: 0
  },
  tableHeader: {
    backgroundColor: theme.palette.tableColor?.header,
    borderTop: `2px solid ${theme.palette.tableColor?.borderTop}`
  },
  tablePaginationRow: {
    // backgroundColor: "pink",
    marginTop: "20px",
    "&.MuiTablePagination-toolbar": {
      marginTop: "20px"
      // backgroundColor: "red"
    }
  },
  overLayBox: {
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      width: "91%"
    },
    [theme.breakpoints.up("sm")]: {
      width: "35%"
    },
    padding: "1.5%",
    zIndex: 999,
    cursor: "pointer",
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: "1px 1px 1px white, 0 0 10px #d6d0d0, 0 0 2px #737373"
  },
  bottonPage: {
    borderRadius: "100%",
    backgroundColor: `#334E90`,
    fontSize: "14px",
    color: "#fff",
    height: "34px",
    "&:hover": {
      backgroundColor: `#334E90`
    }
  },
  inputBox: {
    margin: "10px",
    marginTop: "20px",
    color: "#bfbdbd"
  },
  fullInput: {
    width: "93.2%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro"
    }
  },
  halfInput: {
    width: "87%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro"
    }
  },
  halfInputSingle: {
    width: "87%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro"
    }
  },
  withoutOption: {
    width: "26.8%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro"
    }
  },
  quaterInput: {
    width: "73%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro"
    }
  },
  activeInputLableColor: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro",
    fontStyle: "Source Sans Pro",
    "&.focused": {
      color: theme.palette.primary.main
    }
  },
  chipContainer: {
    maxHeight: "10px"
  },
  activeInputLableBestOffer: {
    fontSize: "130%",
    "&.focused": {
      color: theme.palette.primary.main
    }
  },
  FName: {
    width: "10%"
  },
  ClientID: {
    width: "10%"
  },
  Email: {
    width: "10%"
  },
  Phone: {
    width: "10%"
  },
  Role: {
    width: "10%"
  },
  Created_date: {
    width: "10%"
  },
  status: {
    width: "10%"
  },
  DBA_Name: {
    width: "20%"
  },
  E_mail: {
    width: "10%"
  },
  OwnershipType: {
    width: "15%"
  },
  Contact_Name: {
    width: "15%"
  },
  Phone: {
    width: "10%"
  },
  Creation_Date: {
    width: "10%"
  },
  Update_Date: {
    width: "10%"
  },
  activityDBAName: {
    width: "10%"
  },
  activityEMail: {
    width: "10%"
  },
  activityOwnershipType: {
    width: "10%"
  },
  activityContactName: {
    width: "10%"
  },
  activityPhone: {
    width: "10%"
  },
  activityCreationDate: {
    width: "10%"
  },
  activityUpdateDate: {
    width: "10%"
  },
  noDataImage: {
    height: "200px"
  },
  buttonBlink: {
    from: { opacity: 0 },
    to: { opacity: 1 }
  },
  offerGenerateButton: {
    background: "#70B754",
    borderRadius: "5px",
    textTransform: "none",
    width: "160px",
    height: "38px",
    marginLeft: "5%",
    "&:hover": {
      backgroundColor: "#70B754"
    }
  },
  offerAddButton: {
    background: "#70B754",
    borderRadius: "5px",
    textTransform: "none",
    width: "90px",
    height: "38px",
    marginLeft: "10%",
    "&:hover": {
      backgroundColor: "#70B754"
    }
  }
});

export default tableStyle;
