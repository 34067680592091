/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { string } from "yup";

import {
  Typography,
  Grid,
  TextField,
  MenuItem,
  InputAdornment,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  Form, Field, ErrorMessage, Formik,
} from "formik";
import * as yup from "yup";
import Autocomplete from "@material-ui/lab/Autocomplete";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import moment from "moment";
import {
  postAxiosAPI,
  getAxiosAPI,
  formatter,
} from "../../actions/AxiosAPIMethods";
import { locationCity } from "../../constant/locationConstant";

const validation_schema = yup.object({
  address: yup.string().required("address is required"),
  address2: yup.string().required("address2 is required"),
  city: yup.string().required("city is required"),
  zip: yup.string().required("zip is required"),
  ssn: string("Please enter a valid SSN")
    .required("SSN is required.")
    .test(
      "ssn",
      "Please enter a valid 9-digit SSN",
      value => !value || value.length === 11
    )
    .trim(),
  email: yup.string().required("email is required"),
  phone: yup.string().required("phone is required"),
  dob: yup.string().required("dob is required"),
  householdIncome: yup.string().required("household income is required"),
});

const EditPatientInfo = props => {
  const classes = useStyles();
  const selectedPatient = JSON.parse(
    localStorage.getItem("selectedPatient") || "{}"
  );
  const [initialSubmit, setInitialSubmit] = React.useState(false);
  const [editDetails, setEditDetails] = useState(true);
  const [countries, setCountries] = useState([]);
  const [patientInfo, setPatientInfo] = useState({});

  const initialValues = {
    address: patientInfo?.demographicInfo?.address
      ? patientInfo?.demographicInfo?.address
      : "",
    address2: patientInfo?.demographicInfo?.address2
      ? patientInfo?.demographicInfo?.address2
      : "",
    city: patientInfo?.demographicInfo?.city
      ? patientInfo?.demographicInfo?.city
      : "",
    zip: patientInfo?.demographicInfo?.zip
      ? patientInfo?.demographicInfo?.zip
      : "",
    ssn: patientInfo?.demographicInfo?.SSN
      ? patientInfo?.demographicInfo?.SSN
      : "",
    email: patientInfo?.demographicInfo?.email
      ? patientInfo?.demographicInfo?.email
      : "",
    phone: patientInfo?.demographicInfo?.phone
      ? patientInfo?.demographicInfo?.phone
      : "",
    dob: patientInfo?.demographicInfo?.DoB
      ? moment(patientInfo?.demographicInfo?.DoB).format("MM/DD/YYYY")
      : null,
    country: "US",
    state: patientInfo?.demographicInfo?.state
      ? patientInfo?.demographicInfo?.state
      : "",
    countryCode: "1",
    householdIncome: patientInfo?.financialInfo?.householdIncome
      ? patientInfo?.financialInfo?.householdIncome
      : "",
  };

  function formatSocialSecurity(v) {
    let r = v.replace(/\D/g, "");
    if (r.length > 9) {
      r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    } if (r.length > 4) {
      r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  const getPatientDetails = async () => {
    const patientId = selectedPatient?.patientId;
    await getAxiosAPI(`/patients/patient-info?patientId=${patientId}`).then(
      res => {
        if (res.error) {
          console.log(res.error);
        } else {
          setPatientInfo(res.data);
        }
      }
    );
  };

  const countryList = async () => {
    await getAxiosAPI("/country").then(res => {
      if (res.error) {
        console.log(res.error);
      } else {
        setCountries(res.data.Country_Data);
      }
    });
  };

  useEffect(() => {
    window.scroll(0, 0);
    getPatientDetails();
    countryList();
  }, []);

  const handleSSNFocus = e => {
    e.preventDefault();
    e.target.select();
  };

  const onFormSubmit = async values => {
    const patientId = selectedPatient?.patientId;
    const dataToSend = {
      patientId,
      address: values.address,
      address2: values.address2,
      city: values.city,
      zip: values.zip,
      state: values.state,
      country: values.country,
      SSN: values.ssn,
      email: values.email,
      phone: values.phone,
      DoB: moment(values.dob).format("MM/DD/YYYY"),
      householdIncome: values.householdIncome,
    };
    await postAxiosAPI("/patients/update-patient-info", dataToSend).then(
      res => {
        if (res.error) {
          console.log(res.error);
        } else {
          setEditDetails(true);
          getPatientDetails();
        }
      }
    );
  };

  return (
    <Grid>
      {editDetails ? (
        <Grid container>
          <Grid xs={12} sm={6} md={4} className={classes.addressBox}>
            <Grid className={classes.innerInputGrid}>
              <Typography className={classes.Ptext}>Address: </Typography>
              <Typography className={classes.Atext}>
                {patientInfo?.demographicInfo?.address}
                {" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} md={4} className={classes.addressBox}>
            <Grid className={classes.innerInputGrid}>
              <Typography className={classes.Ptext}>Address2: </Typography>
              <Typography className={classes.Atext}>
                {patientInfo?.demographicInfo?.address2}
                {" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} md={4} className={classes.addressBox}>
            <Grid className={classes.innerInputGrid}>
              <Typography className={classes.Ptext}>Country: </Typography>
              <Typography className={classes.Atext}>
                {patientInfo?.demographicInfo?.country}
                {" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} md={4} className={classes.addressBox}>
            <Grid className={classes.innerInputGrid}>
              <Typography className={classes.Ptext}>state: </Typography>
              <Typography className={classes.Atext}>
                {patientInfo?.demographicInfo?.state}
                {" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} md={4} className={classes.addressBox}>
            <Grid className={classes.innerInputGrid}>
              <Typography className={classes.Ptext}>City: </Typography>
              <Typography className={classes.Atext}>
                {patientInfo?.demographicInfo?.city}
                {" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} md={4} className={classes.addressBox}>
            <Grid className={classes.innerInputGrid}>
              <Typography className={classes.Ptext}>Zip: </Typography>
              <Typography className={classes.Atext}>
                {patientInfo?.demographicInfo?.zip}
                {" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} md={4} className={classes.addressBox}>
            <Grid className={classes.innerInputGrid}>
              <Typography className={classes.Ptext}>SSN: </Typography>
              <Typography className={classes.Atext}>
                {patientInfo?.demographicInfo?.SSN}
                {" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} md={4} className={classes.addressBox}>
            <Grid className={classes.innerInputGrid}>
              <Typography className={classes.Ptext}>Email ID: </Typography>
              <Typography className={classes.Atext}>
                {patientInfo?.demographicInfo?.email}
                {" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} md={4} className={classes.addressBox}>
            <Grid className={classes.innerInputGrid}>
              <Typography className={classes.Ptext}>Phone Number: </Typography>
              <Typography className={classes.Atext}>
                {patientInfo?.demographicInfo?.phone}
                {" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} md={4} className={classes.addressBox}>
            <Grid className={classes.innerInputGrid}>
              <Typography className={classes.Ptext}>Date of Birth: </Typography>
              <Typography className={classes.Atext}>
                {patientInfo?.demographicInfo?.DoB}
                {" "}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
            }}
            container
            sx={12}
          >
            <Typography className={classes.info}>
              Financial Information
            </Typography>
            <Grid>
              <Grid className={classes.innerInputGrid}>
                <Typography className={classes.Ptext}>
                  Household Income:
                  {" "}
                </Typography>
                <Typography className={classes.Atext}>
                  {patientInfo?.financialInfo?.householdIncome
                    ? formatter.format(
                        patientInfo?.financialInfo?.householdIncome
                    )
                    : null}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => setEditDetails(false)}
              variant="contained"
              className={classes.buttons}
            >
              Edit Patient Details
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Formik
          onSubmit={onFormSubmit}
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validation_schema}
        >
          {({
            errors,
            setFieldValue,
            values,
            resetForm,
            touched,
            handleSubmit,
          }) => (
            <Form>
              <Grid container>
                <Grid sx={12} sm={6} md={4} className={classes.editFields}>
                  <Field
                    as={TextField}
                    error={errors.address && touched.address}
                    fullWidth
                    size="small"
                    label="Address"
                    name="address"
                    id="address"
                    style={{ width: "80%" }}
                  />
                  <ErrorMessage name="address">
                    {msg => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid sx={12} sm={6} md={4} className={classes.editFields}>
                  <Field
                    as={TextField}
                    error={errors.address2 && touched.address2}
                    fullWidth
                    size="small"
                    label="Address2"
                    name="address2"
                    id="address2"
                    style={{ width: "80%" }}
                  />
                  <ErrorMessage name="address2">
                    {msg => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid sx={12} sm={6} md={4} className={classes.editFields}>
                  <Autocomplete
                    fullWidth
                    size="small"
                    error={errors.country && touched.country}
                    label="Country"
                    name="country"
                    id="country"
                    value={
                      countries.find(
                        option => option.Country_Code === values.country
                      ) || {}
                    }
                    onChange={(_, value) => {
                      value && setFieldValue("country", value.Country_Code);
                    }}
                    options={countries}
                    classes={{
                      option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={option => option.Country_Name}
                    renderOption={option => (
                      <>
                        <span
                          className={`country-flag ${option.Country_Code.toLowerCase().substring(
                            0,
                            2
                          )}`}
                        />
                        &nbsp;
                        <span className="country-name">
                          {option.Country_Name}
                        </span>
                        &nbsp;
                        <span className="dial-code">{`(${option.Country_Code})`}</span>
                      </>
                    )}
                    renderInput={params => (
                      <TextField
                        {...params}
                        style={{ width: "80%" }}
                        label="Choose a country"
                      />
                    )}
                  />

                  <ErrorMessage name="country">
                    {msg => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid sx={12} sm={6} md={4} className={classes.editFields}>
                  <Field
                    as={TextField}
                    fullWidth
                    size="small"
                    select={values.country === "US"}
                    error={errors.state && touched.state}
                    label="State"
                    name="state"
                    id="state"
                    style={{ width: "80%" }}
                  >
                    {locationCity.map(key => (
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value={key.value}
                      >
                        {key.name}
                      </MenuItem>
                    ))}
                  </Field>
                  <ErrorMessage name="state">
                    {msg => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid sx={12} sm={6} md={4} className={classes.editFields}>
                  <Field
                    as={TextField}
                    error={errors.city && touched.city}
                    fullWidth
                    size="small"
                    label="City"
                    name="city"
                    id="city"
                    style={{ width: "80%" }}
                  />
                  <ErrorMessage name="city">
                    {msg => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid sx={12} sm={6} md={4} className={classes.editFields}>
                  <Field
                    as={TextField}
                    error={errors.zip && touched.zip}
                    fullWidth
                    size="small"
                    label="Zip"
                    name="zip"
                    id="zip"
                    style={{ width: "80%" }}
                  />
                  <ErrorMessage name="zip">
                    {msg => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid sx={12} sm={6} md={4} className={classes.editFields}>
                  <Field
                    as={TextField}
                    error={initialSubmit && errors.ssn && touched.ssn}
                    fullWidth
                    size="small"
                    label="SSN"
                    name="ssn"
                    onChange={event => {
                      if (
                        event.nativeEvent.inputType === "deleteContentBackward"
                        || event.nativeEvent.inputType === "deleteContentForward"
                      ) {
                        setFieldValue("ssn", "");
                      } else {
                        setFieldValue("ssn", formatSocialSecurity(event.target.value));
                      }
                    }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused",
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                      },
                    }}
                    id="ssn"
                    onFocus={e => handleSSNFocus(e)}
                    style={{ width: "80%" }}
                  />
                  <ErrorMessage name="ssn">
                    {msg => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid sx={12} sm={6} md={4} className={classes.editFields}>
                  <Field
                    as={TextField}
                    error={errors.email && touched.email}
                    fullWidth
                    size="small"
                    label="Email"
                    name="email"
                    id="email"
                    style={{ width: "80%" }}
                  />
                  <ErrorMessage name="email">
                    {msg => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid sx={12} sm={6} md={4} className={classes.editFields}>
                  <Field
                    as={TextField}
                    error={errors.phone && touched.phone}
                    fullWidth
                    size="small"
                    value={values.phone}
                    style={{ width: "80%" }}
                    label="Mobile"
                    name="phone"
                    id="phone"
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <Select
                            id="country-menu"
                            value={values.countryCode}
                            onChange={event => {
                              setFieldValue("countryCode", event.target.value);
                            }}
                            classes={{ root: "country-list" }}
                            className="no-underline"
                            inputProps={{
                              "aria-label": "Without label",
                            }}
                          >
                            {countries.map(country => (
                              <MenuItem
                                value={country.Dial_Code}
                                option={countries}
                                key={country.Country_Code}
                                className="country"
                              >
                                <span
                                  className={`country-flag ${country.Country_Code.toLowerCase().substring(
                                    0,
                                    2
                                  )}`}
                                />
                                &nbsp;
                                <span className="dial-code">{`+${country.Dial_Code}`}</span>
                                &nbsp;
                                <span className="country-name">
                                  (
                                  {country.Country_Name}
                                  )
                                </span>
                              </MenuItem>
                            ))}
                          </Select>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ErrorMessage name="phone">
                    {msg => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>
                <Grid sx={12} sm={6} md={4} className={classes.editFields}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      as={TextField}
                      autoOk
                      fullWidth
                      value={values.dob}
                      maxDate={new Date()}
                      onChange={value => setFieldValue("dob", value)}
                      views={["year", "month", "date"]}
                      label="Date Of Birth"
                      format="MM/dd/yyyy"
                      name="dob"
                      id="dob"
                      style={{ width: "80%" }}
                    />
                  </MuiPickersUtilsProvider>
                  <ErrorMessage name="dob">
                    {msg => (
                      <Typography
                        component="span"
                        color="error"
                        variant="body2"
                      >
                        {msg}
                      </Typography>
                    )}
                  </ErrorMessage>
                </Grid>

                <Grid
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                  }}
                  container
                  sx={12}
                >
                  <Typography className={classes.incomeInfo}>
                    Financial Information
                  </Typography>
                  <Grid sm={6} md={4}>
                    <Field
                      as={TextField}
                      error={errors.householdIncome && touched.householdIncome}
                      fullWidth
                      size="small"
                      label="Household Income"
                      name="householdIncome"
                      id="householdIncome"
                      style={{ width: "80%" }}
                    />
                    <ErrorMessage name="householdIncome">
                      {msg => (
                        <Typography
                          component="span"
                          color="error"
                          variant="body2"
                        >
                          {msg}
                        </Typography>
                      )}
                    </ErrorMessage>
                  </Grid>
                </Grid>
                <Grid className={classes.editFields}>
                  <Button
                    variant="contained"
                    className={classes.buttons}
                    type="submit"
                  >
                    Save Details
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      )}
    </Grid>
  );
};

export default EditPatientInfo;

const useStyles = makeStyles({
  addressBox: {
    display: "flex",
    flexDirection: "column",
  },
  cardDiv1: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10%",
    marginTop: "10%",
    overflow: "auto",
    textAlign: "center",
  },
  modalDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "stretch",
  },
  modalCard: {
    width: "100%",
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    display: "initial",
  },
  TNCtext: {
    padding: "10px 0",
    fontSize: "16px",
    color: "#144b8e",
  },
  bottomRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  editFields: {
    // margin: "0 2px"
    display: "flex",
    flexDirection: "column",
  },
  activeInputLableColor: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro",
    "&.focused": {
      color: "#144b8e",
    },
  },
  H1: {
    color: "#144b8e",
    fontSize: "16px",
    fontWeight: "600",
    padding: "5px",
  },
  H2: {
    fontSize: "16px",
    fontWeight: "400",
    padding: "0 20px",
  },
  innerInputGrid: {
    display: "flex",
    flexDirection: "row",
  },
  Cbalance: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "end",
  },
  inputFileds: {
    border: "none",
    margin: "0 20px",
    outline: "none",
  },
  Ptext: {
    color: "#144b8e",
    fontSize: "18px",
    fontWeight: "400",
    padding: "5px 10px",
  },
  mainH1: {
    color: "#144b8e",
    fontSize: "20px",
    fontWeight: "600",
    padding: "10px 0",
  },
  Atext: {
    // color: "#144b8e",
    padding: "5px 10px",
    fontSize: "18px",
    fontWeight: "400",
  },
  buttons: {
    textTransform: "none",
    fontWeight: "16px",
    padding: "8px 20px",
    color: "white",
    backgroundColor: "#03A430",
    borderRadius: "8px",
    margin: "20px",
  },
  info: {
    color: "#144b8e",
    fontSize: "20px",
    margin: "0 10px",
    fontWeight: "600",
  },
  incomeInfo: {
    color: "#144b8e",
    fontSize: "20px",
    margin: "10px",
    fontWeight: "600",
  },
  balance: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "22px",
    color: "#27337D",
    padding: "0 15px",
  },
  balanceDigit: {
    fontSize: "48px",
    fontWeight: "600",
    lineHeight: "52px",
    color: "#27337D",
  },
  nameBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  patientNameBox: {
    display: "flex",
    flexDirection: "row",
  },
  patientDetailsCard: {
    borderRadius: "15px",
    margin: "20px 0",
    padding: "20px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    backgroundColor: "#FFFFFF",
  },
  upperInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
});
