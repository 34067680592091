import "./style.scss";
import "../../../assets/css/CountryCode.scss";

import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  TextField,
  Typography,
  MenuItem,
  Card,
  CardContent,
  Select
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import OTPInput from "otp-input-react";
import { connect } from "react-redux";
// import isNumber from "lodash/isNumber";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTheme } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import jwt from "jsonwebtoken";
import { useSnackbar } from "notistack";
import React from "react";
import isEmpty from "lodash/isEmpty";
import {
  loginRequest,
  setUserRole,
  setAppBarName,
  setLayoutName,
  getUserLeftMenuCall,
  clientThemeCall,
  clientPortalConfig
} from "../../../Redux/Actions/LoginActions";
import { getUserDetailsById } from "../../../Redux/Actions/UserRegistration";
import { getOtpAction, getCountryList } from "../../../actions/login.action";
import { checkLayoutName } from "../../../Uintls/gloables";

import Validation from "../../../Utils/Validations";
import brandLogo from "../../../assets/img/Animated_Logo.gif";
import useStyles from "./Style";

const adminInitialValues = {
  clientId: "",
  id: "",
  password: "",
  countryCode: 1
};
const patientInitialValues = {
  clientId: "",
  id: "",
  password: "",
  userType: "PT",
  countryCode: 1
};

function Login(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [onClickOtp, setOnClickOtp] = React.useState(false);
  const [action, setAction] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [loginType, setloginType] = React.useState("admin");
  const [initialValues, setInitialValues] = React.useState({});
  const [countryCode, setCountryCode] = React.useState([]);

  const handleOtp = (value) => {
    setOtp(value);
  };

  const onOtpSend = function (values) {
    setAction(true);
    localStorage.setItem("email", values.id.toLowerCase());
    const updatedValues = {
      email: values.id,
      clientId: values.clientId || ""
    };
    if (/^\d+$/.test(values.id)) {
      updatedValues.countryCode = values.countryCode;
    }
    setInitialValues(values);
    props.getOtpAction(
      updatedValues,
      (data) => {
        if (data.status) {
          enqueueSnackbar(`One time code is sent to ${values.id}`, {
            variant: "success"
          });
          setOnClickOtp(!onClickOtp);
          setAction(false);
        } else {
          setAction(false);
          enqueueSnackbar(data.message, {
            variant: "error"
          });
        }
      },
      () => {
        enqueueSnackbar("Failed to send One time code", {
          variant: "error"
        });
      }
    );
  };

  const passwordLogin = function (values) {
    setAction(true);
    localStorage.setItem("email", values.id.toLowerCase());
    const updatedValues = {
      Email: values.id,
      clientId: values.clientId || "",
      loginBy: "",
      password: values.password
    };
    if (/^\d+$/.test(values.id)) {
      updatedValues.countryCode = values.countryCode;
    }
    props.loginRequest(updatedValues);
  };

  const otpLogin = function () {
    setAction(true);
    const updatedValues = {
      Email: initialValues.id,
      clientId: initialValues.clientId || "",
      loginBy: "OTP",
      password: otp
    };
    if (/^\d+$/.test(initialValues.id)) {
      updatedValues.countryCode = initialValues.countryCode;
    }
    props.loginRequest(updatedValues);
  };

  React.useEffect(() => {
    if (isEmpty(countryCode)) {
      props.getCountryList((data) => {
        setCountryCode(data.Country_Data);
      });
    }
  }, []);

  React.useEffect(() => {
    if (props.userData !== false) {
      if (props.userData && props.userData.status) {
        setAction(false);
        localStorage.setItem("token", `bearer ${props.userData.token}`);
        jwt.verify(
          props.userData.token,
          "Support@Vaminfosyshserus",
          (err, decoded) => {
            if (decoded !== undefined) {
              // const idealPath = JSON.parse(localStorage.getItem("idealPath"));
              props.clientThemeCall(decoded.ClientID);
              props.clientPortalConfig(decoded.ClientID);
              const idealPath = "";
              let LandingPage = "/";
              let MainCat = "";
              if (idealPath) {
                LandingPage = idealPath.landingPageName;
              } else {
                LandingPage = decoded.Role_Permission_Data.LandingPage;
                MainCat =
                  decoded.Role_Permission_Data.Main_Category_LandingPage;
              }
              props.setAppBarName(
                MainCat ? `${MainCat} / ${LandingPage}` : LandingPage
              );
              const layoutName = checkLayoutName(
                decoded.Role_Permission_Data.Profile_Code
              );
              props.setLayoutName(layoutName);
              if (idealPath) {
                props.history.push(idealPath.path);
              } else {
                let userType = "";
                let profileType = "";
                if (decoded) {
                  if (decoded.Role_Permission_Data.Profile_Code === "PR") {
                    userType = "External";
                  } else {
                    userType = "Internal";
                  }
                }
                if (decoded) {
                  if (decoded.Role_Permission_Data.Profile_Code === "SA") {
                    profileType = "Admin";
                  } else {
                    profileType = "Others";
                  }
                }
                if (!isEmpty(userType) || !isEmpty(profileType)) {
                  localStorage.setItem("userType", userType);
                  localStorage.setItem("profileType", profileType);
                }
                localStorage.setItem("menu", JSON.stringify(decoded));
                localStorage.setItem("clientId", decoded.ClientID);
                localStorage.setItem("userId", decoded.UserID);
                props.getUserDetailsById(decoded.UserID);
                props.setUserRole(decoded);
                props.getUserLeftMenuCall();
                props.history.push(
                  `/${layoutName}/${LandingPage.replace(/\s/g, "")}`
                );
              }
            }
          }
        );
      } else {
        setAction(false);
        enqueueSnackbar(props.userData.message, {
          variant: "error"
        });
      }
    }
  }, [props]);

  React.useEffect(() => {
    if (loginType === "admin") {
      setInitialValues(adminInitialValues);
    }
    if (loginType === "patient") {
      setInitialValues(patientInitialValues);
    }
  }, [loginType]);

  React.useEffect(() => {
    setOtp("");
  }, [onClickOtp]);

  return (
    <Grid
      container
      style={{
        height: "100%"
      }}
    >
      <Grid
        item
        xs={12}
        md={6}
        lg={6}
        style={{
          backgroundColor: "#EFF4FF",
          minHeight: "100vh",
          position: "relative"
        }}
      >
        <Grid item xs={12} lg={12}>
          <div
            style={{
              // paddingTop: "10px",
              paddingLeft: "45px"
              // paddingBottom: "10px",
            }}
          >
            <img
              style={{
                width: "80%"
              }}
              src={"/images/tpo-logo.png"}
              alt="appName"
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          lg={12}
          style={{
            // color: "#ffffff",
            display: "flex",
            paddingLeft: "60px"
            // paddingTop: "20px"
          }}
        >
          <Button
            alignItems="flex-start"
            style={{
              textTransform: "none",
              color: loginType === "admin" ? "#3E4E9B" : "#ffffff",
              backgroundColor: loginType === "admin" ? "#ffffff" : "#3E4E9B",
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              borderTopLeftRadius: "10px",
              paddingRight: "25px",
              paddingLeft: "25px",
              paddingBottom: "10px",
              paddingTop: "10px",
              fontSize: "18px",
              fontWidth: "600"
            }}
            onClick={() => setloginType("patient")}
            flexDirection="column"
            className={`${classes.tilesCard} tiles-capsule`}
          >
            Patient Login
          </Button>
          <Button
            style={{
              textTransform: "none",
              color: loginType === "admin" ? "#ffffff" : "#3E4E9B",
              backgroundColor: loginType === "admin" ? "#3E4E9B" : "#ffffff",
              borderBottomRightRadius: "0px",
              borderBottomLeftRadius: "0px",
              paddingRight: "25px",
              paddingLeft: "25px",
              paddingBottom: "10px",
              paddingTop: "10px",
              borderTopRightRadius: "10px",
              fontSize: "18px",
              fontWidth: "600"
            }}
            alignItems="flex-start"
            flexDirection="column"
            onClick={() => setloginType("admin")}
            className={`${classes.tilesCard} tiles-capsule`}
          >
            Admin Login
          </Button>
        </Grid>
        <Grid
          style={{
            paddingRight: "60px",
            paddingLeft: "60px"
          }}
        >
          <Card
            style={{
              borderTopLeftRadius: "0px",
              paddingRight: "10px",
              paddingLeft: "10px",
              borderTopRightRadius: "10px",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px"
            }}
          >
            <CardContent>
              <Grid
                height="100%"
                display="flex"
                alignItems="center"

                // className={
                //   !windowWidth ? ` auth-right-panelM` : ` auth-right-panel`
                // }
              >
                {!onClickOtp ? (
                  <>
                    <div>
                      <Formik
                        validationSchema={
                          loginType === "admin"
                            ? Validation.loginAdmin
                            : Validation.loginPatient
                        }
                        enableReinitialize
                        // eslint-disable-next-line react/jsx-no-bind
                        onSubmit={passwordLogin}
                        initialValues={initialValues}
                      >
                        {({
                          values,
                          errors,
                          setFieldValue,
                          dirty,
                          handleSubmit
                        }) => (
                          <>
                            <Grid container spacing={3}>
                              <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                  component="p"
                                  variant="subtitle2"
                                  style={{
                                    color: "#27337D",
                                    textAlign: "start",
                                    fontSize: "1.5rem",
                                    fontWeight: "600"
                                    // marginTop: "10px",
                                    // marginBottom: "10px",
                                  }}
                                >
                                  Sign into the The Patient Offer
                                </Typography>
                              </Grid>
                            </Grid>
                            <Form>
                              <Grid container spacing={3}>
                                {loginType === "admin" && (
                                  <>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                      <Typography
                                        style={{
                                          marginBottom: "5px",
                                          fontWeight: "600",
                                          textAlign: "start",
                                          fontSize: "16px"
                                        }}
                                      >
                                        Client ID
                                      </Typography>
                                      <Field
                                        as={TextField}
                                        size="large"
                                        fullWidth
                                        variant="outlined"
                                        id="clientId"
                                        // label={"Enter Client ID" + "*"}
                                        name="clientId"
                                        autoComplete="clientId"
                                        autoFocus={loginType === "admin"}
                                        inputProps={{ readOnly: false }}
                                      />
                                      <ErrorMessage name="clientId">
                                        {(msg) => (
                                          <Typography
                                            component="span"
                                            className="error"
                                            variant="body2"
                                          >
                                            {msg}
                                          </Typography>
                                        )}
                                      </ErrorMessage>
                                    </Grid>
                                  </>
                                )}

                                <Grid
                                  item
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                  style={{
                                    fontSize: "18px"
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        marginBottom: "5px",
                                        fontWeight: "600",
                                        textAlign: "start",
                                        fontSize: "16px",
                                        width: "30%",
                                        display: "flex",
                                        alignItems: "center"
                                      }}
                                    >
                                      Email Address{" "}
                                      <p
                                        style={{
                                          color: "red",
                                          marginBottom: "0px",
                                          marginTop: "0px"
                                        }}
                                      >
                                        {" "}
                                        *{" "}
                                      </p>
                                    </Typography>
                                    <ErrorMessage name="id">
                                      {(msg) => (
                                        <Typography
                                          component="span"
                                          className="error"
                                          variant="body2"
                                        >
                                          {msg}
                                        </Typography>
                                      )}
                                    </ErrorMessage>
                                    <ErrorMessage name="countryCode">
                                      {(msg) => (
                                        <Typography
                                          component="span"
                                          className="error"
                                          variant="body2"
                                        >
                                          {msg}
                                        </Typography>
                                      )}
                                    </ErrorMessage>
                                  </Box>

                                  <Field
                                    as={TextField}
                                    size="large"
                                    style={{
                                      fontSize: "18px"
                                    }}
                                    fullWidth
                                    variant="outlined"
                                    id="id"
                                    fontSize="18px"
                                    required
                                    InputLabelProps={{
                                      style: { fontSize: "18px" }
                                    }}
                                    placeholder="Enter your email address"
                                    autoComplete="new-password"
                                    // error={errors.id}
                                    // label="Enter Email"
                                    name="id"
                                    autoFocus={loginType !== "admin"}
                                    InputProps={{
                                      style: { fontSize: "18px" }
                                      // startAdornment: (
                                      // <InputAdornment position="start">
                                      //  <Select
                                      // id="country-menu"
                                      // value={values.countryCode}
                                      // onChange={(event) => {
                                      //   setFieldValue(
                                      //     "countryCode",
                                      //     event.target.value
                                      //   );
                                      // }}
                                      // classes={{
                                      //   root: "country-list",
                                      // }}
                                      // // className="no-underline"
                                      // inputProps={{
                                      //   "aria-label": "Without label",
                                      // }}
                                      // >
                                      //   {countryCode.map((country) => (
                                      //     <MenuItem
                                      //       value={country.Dial_Code}
                                      //       option={countryCode}
                                      //       key={country.Country_Code}
                                      //   className="country"
                                      // >
                                      //   <span
                                      //     className={`country-flag ${country.Country_Code.toLowerCase().substring(
                                      //       0,
                                      //       2
                                      //     )}`}
                                      //   />
                                      //   &nbsp;
                                      //       <span className="dial-code">{`+${country.Dial_Code}`}</span>
                                      //       &nbsp;
                                      //       <span className="country-name">
                                      //         ({country.Country_Name})
                                      //       </span>
                                      //     </MenuItem>
                                      //   ))}
                                      // </Select> */}
                                      /* </InputAdornment>
                                        ), */
                                    }}
                                  />
                                </Grid>

                                <Grid item lg={12} md={12} sm={12} xs={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center"
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        marginBottom: "5px",
                                        fontWeight: "600",
                                        textAlign: "start",
                                        fontSize: "16px",
                                        width: "20%",
                                        display: "flex",
                                        alignItems: "center"
                                      }}
                                    >
                                      Password{" "}
                                      <p
                                        style={{
                                          color: "red",
                                          marginBottom: "0px",
                                          marginTop: "0px"
                                        }}
                                      >
                                        {" "}
                                        *{" "}
                                      </p>
                                    </Typography>
                                    <ErrorMessage name="password">
                                      {(msg) => (
                                        <Typography
                                          component="span"
                                          className="error"
                                          variant="body2"
                                          fontSize="large"
                                        >
                                          {msg}
                                        </Typography>
                                      )}
                                    </ErrorMessage>
                                  </Box>

                                  <Field
                                    as={TextField}
                                    size="large"
                                    fullWidth
                                    placeholder="Enter your password"
                                    variant="outlined"
                                    name="password"
                                    // error={errors.password}
                                    // label={"Password" + "*"}
                                    type="password"
                                    id="password"
                                    autoComplete="new-password"
                                    InputProps={{
                                      style: { fontSize: "18px" },
                                      endAdornment: (
                                        <InputAdornment position="end">
                                          <Button
                                            className={classes.forgotButton}
                                            color="primary"
                                            onClick={() => {
                                              props.history.push(
                                                "resetPassword"
                                              );
                                            }}
                                          >
                                            Forgot?
                                          </Button>
                                        </InputAdornment>
                                      )
                                    }}
                                  />
                                </Grid>
                              </Grid>
                              <Grid
                                container
                                alignItems="center"
                                justify="center"
                              >
                                <Grid sm={12} xs={12} item>
                                  <Button
                                    size="large"
                                    fullWidth
                                    type="submit"
                                    style={{
                                      backgroundColor: "#03A430",
                                      textTransform: "none",
                                      borderRadius: "25px",
                                      height: "56px",
                                      color: "#ffffff",
                                      marginTop: "27px",
                                      marginBottom: "3px",
                                      fontSize: "18px"
                                    }}
                                    onClick={handleSubmit}
                                    // disabled={
                                    //   !isEmpty(errors) ||
                                    //   isEmpty(values.id) ||
                                    //   (!dirty && isEmpty(values.id)) ||
                                    //   action
                                    // }
                                    variant=""
                                    color="#ffffff"
                                    // className={classes.submit}
                                  >
                                    Sign into TPO
                                  </Button>
                                </Grid>
                              </Grid>

                              {/* <Grid
                                container
                                alignItems="center"
                                justify="center"
                                className={classes.loginBackButton}
                              > */}
                              {/* <Grid sm={12} xs={6} item>
                                    <Typography
                                      style={{
                                        marginBottom: "10px",
                                      }}
                                    >
                                      -----------------------------------------------or-----------------------------------------------
                                    </Typography>
                                  </Grid> */}
                              {/* 
                                  <Grid sm={12} xs={6} item>
                                    <Button
                                      size="large"
                                      fullWidth
                                      type="submit"
                                      variant=""
                                      // color="#03A430"
                                      style={{
                                        textTransform: "none",
                                        borderRadius: "25px",
                                        border: "1px solid"
                                      }}
                                    >
                                      Request One-Time Code
                                    </Button>
                                  </Grid> */}
                              {/* </Grid> */}
                            </Form>
                          </>
                        )}
                      </Formik>
                    </div>
                  </>
                ) : (
                  <div className={classes.authInnerForm}>
                    <Typography component="p" variant="body1" gutterBottom>
                      Please enter the one time code sent to
                    </Typography>
                    <Typography component="p" variant="body1">
                      {initialValues.id}
                    </Typography>
                    <Link onClick={() => setOnClickOtp(false)} color="primary">
                      <b>Change</b>
                    </Link>
                    <div className={classes.form}>
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        className={classes.spaceTop}
                      >
                        <OTPInput
                          value={otp}
                          onChange={handleOtp}
                          OTPLength={6}
                          inputStyles={{
                            border: 0,
                            width: "14px",
                            borderBottom: "1px solid #cbcbcb"
                          }}
                          otpType="number"
                          disabled={false}
                        />
                      </Grid>

                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        style={{ marginTop: "20px" }}
                        className={classes.spaceTop}
                      >
                        <Grid sm={10} xs={10} item>
                          <Button
                            size="large"
                            fullWidth
                            variant="contained"
                            color="primary"
                            disabled={otp.toString().length < 6}
                            className={classes.submit}
                            // eslint-disable-next-line react/jsx-no-bind
                            onClick={otpLogin}
                          >
                            Verify
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        className={classes.spaceTop}
                      >
                        <Grid item>
                          <Typography component="p" variant="subtitle2">
                            Not received your code?
                            <Button color="primary">
                              <b>Resend code</b>
                            </Button>
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                )}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Grid item xs={12} md={6} lg={6} className={classes.backgroundImg}>
        <Box
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)"
          }}
        >
          <Typography
            // component="h6"
            // align="center"
            style={{
              color: "#ffffff",
              fontSize: "2rem"
              // paddingLeft: "210px",
              // paddingTop: "250px",
            }}
          >
            Delivering Timely
            <br /> and <br /> Tailored Offers
          </Typography>
          <Box
            style={{
              color: "#ffffff",
              // fontSize: '1.5rem',
              // paddingLeft: "210px",
              // paddingBottom: "218px",
              paddingTop: "20px"
            }}
          >
            <Button
              style={{
                backgroundColor: "#03A430",
                borderRadius: "25px",
                textTransform: "none",
                height: "56px",
                color: "#ffffff",
                fontSize: "18px",
                width: "240px"
              }}
              size="large"
              variant="contained"
              color="#ffffff"
              type="submit"

              // disabled={!isEmpty(formik.errors) || !formik.dirty}
              // className={classes.submit}
            >
              Sign Up Now
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
  // }
}

Login.propTypes = {
  history: PropTypes.object,
  loginRequest: PropTypes.func,
  userData: PropTypes.object,
  setUserRole: PropTypes.func,
  setAppBarName: PropTypes.func,
  setLayoutName: PropTypes.func,
  getOtpAction: PropTypes.func,
  getCountryList: PropTypes.func,
  clientThemeCall: PropTypes.func,
  clientPortalConfig: PropTypes.func,
  getUserLeftMenuCall: PropTypes.func,
  app: PropTypes.object,
  getUserDetailsById: PropTypes.func
};

const mapStateToProps = ({ app }) => ({
  userData: app.userDetails,
  app
});

export default connect(mapStateToProps, {
  loginRequest,
  setUserRole,
  setAppBarName,
  clientPortalConfig,
  setLayoutName,
  getOtpAction,
  getCountryList,
  clientThemeCall,
  getUserDetailsById,
  getUserLeftMenuCall
})(Login);
