/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Card from "components/Card/Card";
import "../Payment/card.css";
import { Grid, Typography } from "@material-ui/core";
import "react-credit-cards/es/styles-compiled.css";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import {
  getAllActivePlanList,
  getOpenFundingAmount,
  getPendingOfferAmount,
  getPaymentStatus,
  getOfferAccountStatus,
  getOriginalLoanTerm,
  getRemainingFundingGraph,
  resetPaymentList,
  getPaymentDetailsList,
} from "Redux/Actions/PatientDashboard";
import { getGenrateOfferData } from "Redux/Actions/genrateOfferPatient";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import isEmpty from "lodash/isEmpty";
import { patientPaymentDetailsCall } from "Redux/Actions/genrateOfferPatient";
import Ripple from "components/RippleEffect/Ripple";
import Button from "components/CustomButtons/Button";
import styles from "./Dashboard.style";
import visaCardImg from "../../assets/img/visa.png";
import masterCardImg from "../../assets/img/masterCard.png";
import { getAxiosAPI } from "../../actions/AxiosAPIMethods";

const useStyles = makeStyles(styles);

const PatientPaymentDashboard = (props) => {
  const getPatientId = JSON.parse(localStorage.getItem("selectedPatient"));
  const newGenerateOffer = props.patientDetails.genrateOfferData;
  const classes = useStyles();
  const [prePaymentDetails, setPrePaymentsDetails] = React.useState(
    props.patientDetails.paymentDetailsData
  );
  const [paymentFlow, setPaymentFlow] = React.useState(false);
  const [cardFlow, setCardFlow] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState(
    localStorage.getItem("payType")
  );
  const duesId = JSON.parse(localStorage.getItem("duesId") || "[]");
  const selectedPatient = JSON.parse(
    localStorage.getItem("selectedPatient") || "{}"
  );
  const theme = useTheme();
  const windowWidthMd = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthLg = useMediaQuery(theme.breakpoints.up("lg"));

  React.useEffect(() => {
    const paymentListPatient = {
      patientId: props.patientDetails.selectPatient
        ? props.patientDetails.selectPatient.data.Patient_id
        : getPatientId.patientId,
      payType: paymentType,
    };
    if (!props.tableData.paymentList) {
      props.getPaymentDetailsList(paymentListPatient);
    }
  }, []);

  React.useEffect(() => {
    if (
      prePaymentDetails !== props.patientDetails.paymentDetailsData &&
      !props.patientDetails.printOfferData
    ) {
      setPrePaymentsDetails(props.patientDetails.paymentDetailsData);
      if (
        props.patientDetails.paymentDetailsData &&
        props.patientDetails.paymentDetailsData.data.payType != 3
      ) {
        // props.state();
        if (paymentFlow == "New_Method") {
          setPaymentFlow(false);
          props.history.push("/tpo/payments");
        } else if (paymentFlow == "Selected_Card") {
          setPaymentFlow(false);
          props.history.push("/tpo/otp-confirmation");
        }
        props.resetPaymentList();
      }
    }
  }, [props]);

  React.useEffect(() => {
    if (
      props.patientDetails.paymentDetailsData &&
      props.patientDetails.paymentDetailsData.data.payType == 3
    ) {
      if (paymentFlow == "New_Method" && cardFlow == "Setup") {
        setPaymentFlow(false);
        setCardFlow(false);
        props.history.push("/tpo/payments");
        props.resetPaymentList();
      } else if (paymentFlow == "Selected_Card" && cardFlow == "Setup") {
        setPaymentFlow(false);
        setCardFlow(false);
        props.history.push("/tpo/otp-confirmation");
        props.resetPaymentList();
      }
    }
  }, [props]);

  const monthlyAmount =
    props.patientDetails.genrateOfferData &&
    props.patientDetails.genrateOfferData.data[0].Recourse[0].offers &&
    props.patientDetails.genrateOfferData.data[0].Recourse[0].offers.filter(
      (i) => i?.financerNo == localStorage.getItem("finNo")
    );

  const handleBackToDashboard = () => {
    props.resetPaymentList();
    if (props.patientDetails.genrateOfferData) {
      props.history.push("/admin/offerpayment");
    } else {
      props.history.push("/patient/Dashboard");
    }
  };

  const paymentTypeId = localStorage.getItem("payType");
  const handlePayNowNewMethod = (type) => {
    let data;

    setPaymentFlow("New_Method");
    setCardFlow(type);
    if (props.patientDetails.genrateOfferData) {
      const amount =
        parseInt(paymentTypeId) === 1
          ? Number(
              props.patientDetails.genrateOfferData.data[0].Discounted[0].finalPayAmount
                .toString()
                .replace("$", "")
            )
          : type === "New"
          ? Number(
              props.patientDetails.genrateOfferData.data[0].Recourse[0].offers[0].downPayment
                .toString()
                .replace("$", "")
            )
          : monthlyAmount &&
            monthlyAmount[0]?.monthlyPayment.toString().replace("$", "");

      data = {
        patientId: props.patientDetails.selectPatient
          ? props.patientDetails.selectPatient.data.Patient_id
          : getPatientId.patientId,
        offerId: props.patientDetails.genrateOfferData.data[0].offerId,
        amount,
        paymentId: "",
        cardType: "",
        monthlyPayment: monthlyAmount
          ? monthlyAmount[0]?.monthlyPayment.toString().replace("$", "")
          : 0,
        payType: !props.patientDetails.paymentDetailsData
          ? props.patientDetails.genrateOfferData.data[0].payType
          : props.patientDetails.paymentDetailsData.data.payType,
      };
    }

    props.patientPaymentDetailsCall(data);
  };

  const handlePayNow = (paymentId, cardType) => {
    setPaymentFlow("Selected_Card");
    if (
      props.tableData.offetDetailsPatient &&
      props.tableData.offetDetailsPatient.data.length > 0
    ) {
      setCardFlow("Setup");
    }
    let data;
    if (props.patientDetails.genrateOfferData) {
      const amount =
        parseInt(paymentTypeId) === 1
          ? Number(
              props.patientDetails.genrateOfferData.data[0].Discounted[0].finalPayAmount
                .toString()
                .replace("$", "")
            )
          : props.tableData.offetDetailsPatient &&
            props.tableData.offetDetailsPatient.data.length > 0
          ? monthlyAmount &&
            monthlyAmount[0]?.monthlyPayment.toString().replace("$", "")
          : Number(
              props.patientDetails.genrateOfferData.data[0].Recourse[0].offers[0].downPayment
                .toString()
                .replace("$", "")
            );
      data = {
        patientId: props.patientDetails.selectPatient
          ? props.patientDetails.selectPatient.data.Patient_id
          : getPatientId.patientId,
        offerId: props.patientDetails.genrateOfferData.data[0].offerId,
        amount,
        paymentId: paymentId ? paymentId : "",
        cardType: cardType ? cardType : "",
        monthlyPayment: monthlyAmount
          ? monthlyAmount[0]?.monthlyPayment.toString().replace("$", "")
          : 0,
        payType: !props.patientDetails.paymentDetailsData
          ? props.patientDetails.genrateOfferData.data[0].payType
          : props.patientDetails.paymentDetailsData.data.payType,
      };
    } else {
      const amount = 0;
      data = {
        patientId: props.patientDetails.selectPatient
          ? props.patientDetails.selectPatient.data.Patient_id
          : getPatientId.patientId,
        offerId:
          props.patientDetails.genrateOfferData &&
          props.patientDetails.genrateOfferData?.data[0].offerId,
        amount,
        paymentId: paymentId ? paymentId : "",
        cardType: cardType ? cardType : "",
        monthlyPayment: monthlyAmount
          ? monthlyAmount[0]?.monthlyPayment.toString().replace("$", "")
          : 0,
        payType: !props.patientDetails.paymentDetailsData
          ? props.patientDetails.genrateOfferData.data[0].payType
          : props.patientDetails.paymentDetailsData.data.payType,
      };
    }
    props.patientPaymentDetailsCall(data);
  };

  const offerIdData = localStorage.getItem("offerId")
    ? localStorage.getItem("offerId")
    : localStorage.getItem("selectOfferId");
  useEffect(() => {
    if (!props.patientDetails.genrateOfferData || paymentType) {
      const reqData = {
        offerId: offerIdData,
        payType: parseInt(paymentType),
      };
      props.getGenrateOfferData(reqData);
    }
  }, []);

  const addCardClassName = (key) => {
    setTimeout(() => {
      console.log(document.getElementsByClassName("rccs__card"));
    }, 6000);
  };

  const fullAddress =
    props.tableData.offetDetailsPatient &&
    props.tableData.offetDetailsPatient.data.length > 0
      ? `${props.tableData.offetDetailsPatient.data[0].address1}, ${props.tableData.offetDetailsPatient.data[0].address2}, ${props.tableData.offetDetailsPatient.data[0].city}, ${props.tableData.offetDetailsPatient.data[0].state}, ${props.tableData.offetDetailsPatient.data[0].zipCode}`
      : props.patientDetails.selectPatient &&
        `${props.patientDetails.selectPatient.Address}, ${props.patientDetails.selectPatient.Address2}, ${props.patientDetails.selectPatient.City}, ${props.patientDetails.ZipCode}`;

  return (
    <>
      <Card style={{ overflow: "hidden" }}>
        <Grid
          container
          spacing={1}
          style={{
            margin: "5% 2%",
            marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid
            item
            lg={7}
            md={6}
            sm={12}
            xs={12}
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: windowWidthMd || windowWidthLg ? "0px" : "10px",
              alignItems:
                windowWidthMd || windowWidthLg ? "flex-start" : "center",
            }}
          >
            <Typography style={{ fontSize: "20px" }}>
              Your Payment Options
            </Typography>
            <Typography style={{ fontSize: "15px" }}>
              An overview of your payment methods and settings sdf
            </Typography>
          </Grid>
          {(!props.tableData.offetDetailsPatient ||
            (props.tableData.offetDetailsPatient &&
              props.tableData.offetDetailsPatient.data.length < 1)) && (
            <Grid item container lg={4} md={5} sm={12} xs={12}>
              <Grid
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                item
                lg={8}
                md={6}
                sm={12}
                xs={12}
              >
                {/* {props.patientDetails.data && ( */}
                <Button
                  style={{
                    backgroundColor: "rgb(3, 164, 48)",
                    borderRadius: "5px",
                    padding: "5px 10px",
                    textTransform: "none",
                    color: "#fff",
                    // fontWeight: "400",
                    fontSize: "0.875rem",
                    // lineHeight: "24px"
                  }}
                  onClick={() => handlePayNowNewMethod("New")}
                >
                  Pay Now By New Method
                </Button>
                {/* )} */}
              </Grid>

              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                lg={4}
                md={6}
                sm={12}
                xs={12}
              >
                <Button
                  variant="contained"
                  className={classes.clearButton}
                  onClick={() => handleBackToDashboard()}
                >
                  Back
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* change monthly setup */}
        {props.tableData.offetDetailsPatient &&
          props.tableData.offetDetailsPatient.data.length > 0 && (
            <Card style={{ margin: "2%", padding: "10px", width: "94%" }}>
              <Grid container style={{ margin: "1% 0px" }}>
                <Grid
                  item
                  xs
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "8px",
                  }}
                >
                  <Typography style={{ fontSize: "16px" }}>
                    Your current monthly payment setup of loan:
                  </Typography>
                  <Typography
                    style={{
                      fontSize: "18px",
                      fontStyle: "Source Sans Pro",
                      color: "#3a57a6",
                    }}
                  >
                    {props.tableData.offetDetailsPatient.data &&
                    props.tableData.offetDetailsPatient.data.length > 0
                      ? props.tableData.offetDetailsPatient.data[0].offerId
                      : props.patientDetails.genrateOfferData.data[0].offerId}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                }}
              >
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Typography style={{ fontSize: "14px" }}>
                    <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                      ADDRESS
                    </div>
                    {"\n"}
                    {fullAddress}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ fontSize: "14px", width: "100%" }}>
                    <div
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginBottom: "1%",
                      }}
                    >
                      Payment Method
                    </div>
                    {"\n"}
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {props.tableData.offetDetailsPatient &&
                      props.tableData.offetDetailsPatient.data.length > 0 &&
                      (props.tableData.offetDetailsPatient.data[0].cardType ===
                        "VISA" ||
                        props.tableData.offetDetailsPatient.data[0].cardType ===
                          "visa") ? (
                        <img
                          src={visaCardImg}
                          alt=""
                          style={{ width: "32px", marginRight: "7px" }}
                        />
                      ) : (
                        <img
                          src={masterCardImg}
                          alt=""
                          style={{ width: "32px", marginRight: "7px" }}
                        />
                      )}
                      <span>
                        {`${
                          props.tableData.offetDetailsPatient &&
                          props.tableData.offetDetailsPatient.data.length > 0
                            ? props.tableData.offetDetailsPatient.data[0]
                                .cardType
                            : ""
                        } ending in ${
                          props.tableData.offetDetailsPatient &&
                          props.tableData.offetDetailsPatient.data.length > 0
                            ? props.tableData.offetDetailsPatient.data[0]
                                .last4CardNumber
                            : ""
                        }`}
                      </span>
                    </div>
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <Typography style={{ fontSize: "14px" }}>
                    <div style={{ fontSize: "14px", fontWeight: "bold" }}>
                      Nickname
                    </div>
                    {"\n"}
                    {props.tableData.offetDetailsPatient &&
                      props.tableData.offetDetailsPatient.data.length > 0 &&
                      props.tableData.offetDetailsPatient.data[0].nickName}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          )}
        {props.tableData.offetDetailsPatient &&
          props.tableData.offetDetailsPatient.data.length > 0 && (
            <Grid
              container
              style={{
                display: "flex",
                justifyContent: "end",
              }}
            >
              <Button
                style={{ textTransform: "none", marginRight: "2rem" }}
                onClick={() => handlePayNowNewMethod("Setup")}
              >
                Setup new Payment Method
              </Button>
            </Grid>
          )}
        {/* change monthly setup */}
        {props.tableData.paymentList &&
          !isEmpty(props.tableData.paymentList.data[0].card) && (
            <Card style={{ margin: "2%", padding: "10px", width: "94%" }}>
              <Grid container>
                <Grid item>
                  <Typography style={{ fontSize: "20px", color: "#3a56a6" }}>
                    Your saved card details:
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} style={{ padding: "1%" }}>
                  {props.tableData.paymentList &&
                    props.tableData.paymentList?.data[0]?.card.map((key) => (
                      <Accordion style={{ margin: "1%" }}>
                        <AccordionSummary
                          style={{
                            minHeight: "0px",
                            paddingTop: "0px",
                            backgroundColor: "#f3f3f3",
                            margin: "0px",
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          onClick={() => addCardClassName(key)}
                          id="panel1a-header"
                        >
                          <Grid item xs={5} style={{ display: "flex" }}>
                            {key.cardType === "VISA" ||
                            key.cardType === "visa" ? (
                              <img
                                src={visaCardImg}
                                alt=""
                                style={{ width: "32px", marginRight: "7px" }}
                              />
                            ) : (
                              <img
                                src={masterCardImg}
                                alt=""
                                style={{ width: "32px", marginRight: "7px" }}
                              />
                            )}
                            <Typography className={classes.heading}>
                              {`${key.cardType} ending in ${key.last4CardNumber}`}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={4}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "16px", color: "#3a56a6" }}
                            >
                              Setup Date:{" "}
                            </Typography>
                            <Typography className={classes.heading}>
                              {`${key.setupDate}`}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} style={{ display: "flex" }}>
                            {key.default == 1 ? "Default" : ""}
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{ flexDirection: "column" }}>
                          <Grid container style={{ margin: "1%" }}>
                            <Grid
                              item
                              xs={4}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    color: "#3a56a6",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Name on card
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{ fontSize: "14px" }}
                                >
                                  {key.firstName}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  style={{ fontSize: "20px", color: "#3a56a6" }}
                                >
                                  Billing Details
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{ fontSize: "14px" }}
                                >
                                  {key.firstName}
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{ fontSize: "14px" }}
                                >
                                  {key.address1}
                                  {", "}
                                  {key.address2}
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{ fontSize: "14px" }}
                                >
                                  {key.city}
                                  {", "}
                                  {key.state}
                                  {", "}
                                  {key.zipCode}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                onClick={() =>
                                  handlePayNow(key.paymentId, key.cardType)
                                }
                              >
                                {props.tableData.offetDetailsPatient &&
                                props.tableData.offetDetailsPatient.data
                                  .length > 0
                                  ? "Set as default"
                                  : "Pay Now"}
                              </Button>
                            </Grid>
                          </Grid>
                          {/* <Grid container style={{ margin: "1%" }}>
                      <Grid item xs style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <Button onClick={() => handlePayNow(key.paymentId)}>{`${props.patientDetails.createOffer ? "Pay Now" : "Schedule"}`}</Button>
                      </Grid>
                    </Grid> */}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </Grid>
              </Grid>
            </Card>
          )}
        {props.tableData.paymentList &&
          !isEmpty(props.tableData.paymentList.data[0].bank) && (
            <Card style={{ margin: "2%", padding: "10px", width: "94%" }}>
              <Grid container>
                <Grid
                  item
                  xs={5}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography style={{ fontSize: "20px", color: "#3a56a6" }}>
                    Your saved bank details:
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} style={{ padding: "1%" }}>
                  {props.tableData.paymentList &&
                    props.tableData.paymentList.data[0].bank.map((key) => (
                      <Accordion style={{ margin: "1%" }}>
                        <AccordionSummary
                          style={{
                            minHeight: "0px",
                            paddingTop: "0px",
                            backgroundColor: "#f3f3f3",
                            margin: "0px",
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          onClick={() => addCardClassName(key)}
                          id="panel1a-header"
                        >
                          <Grid item xs={6} style={{ display: "flex" }}>
                            {/* {key.cardType === "visa" || key.cardType === "VISA" ? (
                          <img
                            src={visaCardImg}
                            alt=""
                            style={{ width: "32px", marginRight: "7px" }}
                          />
                        ) : (
                          <img
                            src={masterCardImg}
                            alt=""
                            style={{ width: "32px", marginRight: "7px" }}
                          />
                        )} */}
                            <AccountBalanceIcon
                              style={{ width: "32px", marginRight: "7px" }}
                            />
                            <Typography className={classes.heading}>
                              {`Account number ending in ${key.last4CardNumber}`}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            xs={5}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{ fontSize: "16px", color: "#3a56a6" }}
                            >
                              Setup Date:{" "}
                            </Typography>
                            <Typography className={classes.heading}>
                              {`${key.setupDate}`}
                            </Typography>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{ flexDirection: "column" }}>
                          <Grid container style={{ margin: "1%" }}>
                            <Grid
                              item
                              xs={4}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "15px",
                                    color: "#3a56a6",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Name on card
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{ fontSize: "14px" }}
                                >
                                  {key.firstName}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Typography
                                  style={{ fontSize: "20px", color: "#3a56a6" }}
                                >
                                  Billing Details
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{ fontSize: "14px" }}
                                >
                                  {key.firstName}
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{ fontSize: "14px" }}
                                >
                                  {key.address1}
                                  {", "}
                                  {key.address2}
                                </Typography>
                                <Typography
                                  align="left"
                                  style={{ fontSize: "14px" }}
                                >
                                  {key.city}
                                  {", "}
                                  {key.state}
                                  {", "}
                                  {key.zipCode}
                                </Typography>
                              </div>
                            </Grid>
                            <Grid item xs={2}>
                              <Button
                                style={{ height: "35%", marginTop: "35%" }}
                                onClick={() => handlePayNow(key.paymentId)}
                              >
                                {`${newGenerateOffer ? "Pay Now" : "Schedule"}`}
                              </Button>
                            </Grid>
                          </Grid>
                          {/* <Grid container style={{ margin: "1%" }}>
                      <Grid item xs style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
                        <Button onClick={() => handlePayNow(key.paymentId)}>{`${props.patientDetails.createOffer ? "Pay Now" : "Schedule"}`}</Button>
                      </Grid>
                    </Grid> */}
                        </AccordionDetails>
                      </Accordion>
                    ))}
                </Grid>
              </Grid>
            </Card>
          )}
      </Card>
    </>
  );
};

PatientPaymentDashboard.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.object,
  patientDetails: PropTypes.object,
  patientPaymentDetailsCall: PropTypes.func,
  state: PropTypes.func,
  resetPaymentList: PropTypes.func,
  getGenrateOfferData: PropTypes.func,
  getPaymentDetailsList: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.patientDashboard,
  patientDetails: app.genrateOfferPatient,
  userDetails: app.userRole,
});

export default connect(mapStateToProps, {
  getAllActivePlanList,
  getOpenFundingAmount,
  getPendingOfferAmount,
  getPaymentStatus,
  getOfferAccountStatus,
  getOriginalLoanTerm,
  getRemainingFundingGraph,
  patientPaymentDetailsCall,
  resetPaymentList,
  getGenrateOfferData,
  getPaymentDetailsList,
})(PatientPaymentDashboard);
