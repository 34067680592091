/* eslint-disable camelcase */
import React, { useEffect, useRef, useLayoutEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import PropTypes from "prop-types";
import { daily_deposit_colors_graph } from "../../assets/GloableColor";
import { Divider, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardFooter from "components/Card/CardFooter";
import { Button } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import currentBalance from "assets/jss/material-dashboard-react/views/currentBalance";
import { makeStyles } from "@material-ui/core/styles";
import { styled, alpha } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import ArchiveIcon from "@material-ui/icons/Archive";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const options = ["None", "Atria", "Callisto", "Dione"];

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(currentBalance);

let rows = [];

function data(count, value, weekdays) {
  return {
    count,
    value,
    weekdays,
  };
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

function Daliydeposits(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  useEffect(() => {
    let root = am5.Root.new("chartdiv");

    // Create root element
    // https://www.amcharts.com/docs/v5/getting-started/#Root_elemen

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: "none",
        wheelY: "none",
      })
    );

    // We don't want zoom-out button to appear while animating, so we hide it
    chart.zoomOutButton.set("forceHidden", true);

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
    });
    xRenderer.labels.template.setAll({
      rotation: 0,
      centerY: 0,
      centerX: 0,
      paddingLeft: -15,
    });
    xRenderer.grid.template.set("visible", false);

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        maxDeviation: 0.3,
        categoryField: "weekdays",
        renderer: xRenderer,
      })
    );

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        maxDeviation: 0.3,
        min: 0,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    var series = chart.series.push(
      am5xy.ColumnSeries.new(root, {
        name: "Series 1",
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "weekdays",
        //tooltipText: "asddadsadasdasda"
      })
    );

    series.columns.template.setAll({
      tooltipText: "{name}, {categoryX}: {valueY}",
      width: am5.percent(90),
      tooltipY: 0,
    });

    // Rounded corners for columns
    series.columns.template.setAll({
      cornerRadiusTL: 15,
      cornerRadiusTR: 15,
      maxWidth: 30,
      strokeOpacity: 0,
      tooltipX: am5.percent(50),
      fill: am5.color(0xf8f8f8),
      tooltipHTML:
        "<div style='width:100px;'><center><strong>${valueY}</center></strong><hr/><center>Count: {count}</center></div>",
      tooltipText: "{name}, {categoryX}: {valueY}",
    });

    // Make each column to be of a different color
    /* series.columns.template.adapters.add("fill", function (fill, target) {
  return chart.get("colors").getIndex(series.columns.indexOf(target ));
  return am5.color(0x15A672);
}); */

    series.columns.template.set(
      "fillGradient",
      am5.LinearGradient.new(root, {
        stops: [
          {
            color: am5.color(0x15a672),
          },
          {
            color: am5.color(0x77c2d7),
          },
        ],
        rotation: 60,
      })
    );

    series.columns.template.adapters.add("stroke", function (stroke, target) {
      return chart.get("colors").getIndex(series.columns.indexOf(target));
    });

    // Add Label bullet
    series.bullets.push(function () {
      return am5.Bullet.new(root, {
        locationY: 1,
        sprite: am5.Label.new(root, {
          text: "",
          fill: root.interfaceColors.get("alternativeText"),
          centerY: 0,
          centerX: am5.p50,
          populateText: true,
        }),
      });
    });

    {
      props.dashboardData.dailyDepositeData &&
        props.dashboardData.dailyDepositeData.data.slice(0, 1).map((row) => (
          <>
            <Typography className={classes.typographyAmmount}>
              Total Deposits {row.count}
            </Typography>
            <Typography className={classes.typographyCountGraph}>
              ${row.totalAmount}
            </Typography>
          </>
        ));
    }
    var data =
      props.dashboardData.dailyDepositeData &&
      props.dashboardData.dailyDepositeData.data;

    xAxis.data.setAll(data);
    series.data.setAll(data);

    // Get series item by category
    function getSeriesItem(category) {
      for (var i = 0; i < series.dataItems.length; i++) {
        var dataItem = series.dataItems[i];
        if (dataItem.get("categoryX") == category) {
          return dataItem;
        }
      }
    }

    // Axis sorting
    function sortCategoryAxis() {
      // Sort by value
      series.dataItems.sort(function (x, y) {
        return y.get("valueY") - x.get("valueY"); // descending
        //return y.get("valueY") - x.get("valueY"); // ascending
      });

      //   // Go through each axis item
      //   am5.array.each(xAxis.dataItems, function (dataItem) {
      //     // get corresponding series item
      //     var seriesDataItem = getSeriesItem(dataItem.get("category"));

      //     if (seriesDataItem) {
      //       // get index of series data item
      //       var index = series.dataItems.indexOf(seriesDataItem);
      //       // calculate delta position
      //       var deltaPosition = (index - dataItem.get("index", 0)) / series.dataItems.length;
      //       // set index to be the same as series data item index
      //       dataItem.set("index", index);
      //       // set deltaPosition instanlty
      //       dataItem.set("deltaPosition", -deltaPosition);
      //       // animate delta position to 0
      //       dataItem.animate({
      //         key: "deltaPosition",
      //         to: 0,
      //         duration: 1000,
      //         easing: am5.ease.out(am5.ease.cubic)
      //       })
      //     }
      //   });

      // Sort axis items by index.
      // This changes the order instantly, but as deltaPosition is set,
      // they keep in the same places and then animate to true positions.
      xAxis.dataItems.sort(function (x, y) {
        return x.get("index") - y.get("index");
      });
    }

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    series.appear(1000);
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [props.dashboardData.dailyDepositeData.data]);

  //   useEffect(() => {
  //     if (document.getElementById("daily") !== null) {
  //       const chart = am4core.create("daily", am4charts.XYChart);
  //       chart.logo.disabled = true;
  //       chart.colors.step = 2;
  //       chart.maskBullets = false;

  //       const title = chart.titles.create();
  //       title.text = "Daily Deposit";
  //       title.fontSize = 13;
  //       title.fontFamily = "Source Sans Pro";
  //       title.paddingBottom = 10;
  //       title.align = "left";
  //       title.fontWeight = "bold";
  //       title.color = am4core.color("#fff");
  //       // title.text.color=am4core.color("#2b2d30");

  //       if (
  //         props.dashboardData.dailyDepositeData &&
  //         props.dashboardData.dailyDepositeData.data
  //       ) {
  //         // Add data
  //         chart.data = props.dashboardData.dailyDepositeData.data;
  //       }

  //       const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
  //       // dateAxis.renderer.labels.template.location = 20;
  //       dateAxis.renderer.minGridDistance = 0;
  //       dateAxis.renderer.grid.template.disabled = true;
  //       dateAxis.renderer.fullWidthTooltip = false;
  //       dateAxis.renderer.labels.template.rotation = 315;
  //       dateAxis.renderer.labels.template.verticalCenter = "right";
  //       dateAxis.renderer.labels.template.fontSize = am4core.percent(65);

  //       const distanceAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //       distanceAxis.title.text = "Volume ($)";
  //       distanceAxis.title.fontSize = am4core.percent(70);
  //       distanceAxis.renderer.grid.template.disabled = true;
  //       distanceAxis.renderer.labels.template.fontSize = am4core.percent(65);

  //       const durationAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //       durationAxis.title.text = "Count";
  //       durationAxis.title.fontSize = am4core.percent(70);
  //       durationAxis.baseUnit = "count";
  //       durationAxis.renderer.labels.template.fontSize = am4core.percent(65);
  //       durationAxis.renderer.grid.template.disabled = false;
  //       durationAxis.renderer.opposite = true;
  //       durationAxis.syncWithAxis = distanceAxis;

  //       // durationAxis.durationFormatter.durationFormat = "hh'h' mm'min'";

  //       // Create series
  //       const distanceSeries = chart.series.push(new am4charts.ColumnSeries());
  //       distanceSeries.dataFields.valueY = "totalAmount";
  //       distanceSeries.dataFields.dateX = "monthYear";
  //       distanceSeries.yAxis = distanceAxis;

  //       //    distanceSeries.tooltipText = "{valueY}";
  //       distanceSeries.name = "Volume($)";
  //       distanceSeries.fontSize = am4core.percent(70);
  //       distanceSeries.columns.template.width = am4core.percent(50);
  //       distanceSeries.fill = daily_deposit_colors_graph.volume;
  //       distanceSeries.stroke = daily_deposit_colors_graph.volume;
  //       distanceSeries.columns.template.fillOpacity = 8;
  //       distanceSeries.columns.template.propertyFields.strokeDasharray =
  //         "dashLength";
  //       distanceSeries.columns.template.propertyFields.fillOpacity = "alpha";
  //       distanceSeries.showOnInit = true;

  //       const distanceState =
  //         distanceSeries.columns.template.states.create("hover");
  //       distanceState.properties.fillOpacity = 0.9;

  //       const durationSeries = chart.series.push(new am4charts.LineSeries());
  //       durationSeries.dataFields.valueY = "count";
  //       durationSeries.dataFields.dateX = "monthYear";
  //       durationSeries.name = "Count";
  //       durationSeries.yAxis = durationAxis;
  //       durationSeries.strokeWidth = 1;
  //       durationSeries.propertyFields.strokeDasharray = "dashLength";

  //       //    durationSeries.tooltipText = "{valueY}";

  //       durationSeries.tooltip.dy = -8;
  //       // durationSeries.tooltip.dx=0;

  //       durationSeries.tooltipHTML = `<table style="font-size:10px">
  // <tr>
  // <center style="font-size:10px"><strong>{monthYear}</strong></center>
  // <hr />
  // </tr>

  // <tr>
  // <th align="left">Count:</th>
  // <td>{count}</td>
  // </tr>
  // <tr>
  // <th align="left">Volume($):</th>
  // <td>{totalAmount}</td>
  // </tr>

  // </table>`;
  //       durationSeries.showSystemTooltip = true;
  //       durationSeries.showOnInit = true;
  //       durationSeries.stroke = daily_deposit_colors_graph.line;
  //       durationSeries.fill = daily_deposit_colors_graph.line;
  //       durationSeries.cursorTooltipEnabled = true;

  //       const durationBullet = durationSeries.bullets.push(
  //         new am4charts.Bullet()
  //       );
  //       durationBullet.horizontalCenter = "middle";
  //       durationBullet.verticalCenter = "middle";
  //       const circleBullet = durationSeries.bullets.push(
  //         new am4charts.CircleBullet()
  //       );
  //       circleBullet.circle.radius = 2;

  //       const durationState = durationBullet.states.create("hover");
  //       durationState.properties.scale = 1.2;

  //       // Add legend
  //       chart.legend = new am4charts.Legend();
  //       chart.legend.position = "top";
  //       chart.legend.fontSize = 10;
  //       chart.legend.useDefaultMarker = true;
  //       const marker = chart.legend.markers.template.children.getIndex(0);
  //       marker.cornerRadius(12, 12, 12, 12);
  //       marker.strokeWidth = 0;
  //       marker.strokeOpacity = 1;

  //       chart.tooltip.label.fill = am4core.color("#f00");

  //       const markerTemplate = chart.legend.markers.template;
  //       markerTemplate.width = 10;
  //       markerTemplate.height = 10;

  //       chart.tooltip.getFillFromObject = true;
  //       chart.tooltip.background.fill = am4core.color("#67b7dc");

  //       // Add cursor
  //       chart.cursor = new am4charts.XYCursor();
  //       chart.cursor.fullWidthLineX = true;
  //       chart.cursor.lineY.disabled = true;
  //       chart.cursor.xAxis = dateAxis;

  //       chart.cursor.lineX.strokeOpacity = 0;
  //       chart.cursor.lineX.fill = daily_deposit_colors_graph.volume;
  //       chart.cursor.lineX.fillOpacity = 0.1;

  //       const hoverState = distanceSeries.columns.template.states.create("hover");
  //       hoverState.properties.fill = am4core.color(
  //         daily_deposit_colors_graph.columnHover
  //       );
  //       hoverState.properties.fillOpacity = 0.5;
  //       chart.cursor.lineX.fillOpacity = 0;

  //       const buttonContainer = chart.plotContainer.createChild(
  //         am4core.Container
  //       );
  //       buttonContainer.shouldClone = false;
  //       buttonContainer.align = "right";
  //       buttonContainer.zIndex = Number.MAX_SAFE_INTEGER;
  //       buttonContainer.layout = "horizontal";
  //     }
  //   }, [props.dashboardData.dailyDepositeDataps]);
  return (
    <>
      <Grid
        item
        // className={classes.cardGrid}
      >
        <CardHeader
          // className={classes.duesGrid}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "0px",
            paddingTop: "15px",
          }}
        >
          <Typography noWrap className={classes.cardTitle}>
            <Button
              style={{
                color: "#333333",
                border: "1px solid",
                borderRadius: "25px",
                textTransform: "none",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant=""
              // onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Daily Deposits
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              // open={open}
              // onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Daily Deposits</MenuItem>
              {/* <MenuItem onClick={handleClose}>Last Week</MenuItem>
              <MenuItem onClick={handleClose}>This Month</MenuItem>
              <MenuItem onClick={handleClose}>Last Month</MenuItem> */}
            </StyledMenu>
          </Typography>
          <Typography noWrap className={classes.cardTitle}>
            <Button
              style={{
                color: "#27337D",
                border: "1px solid",
                borderRadius: "25px",
                textTransform: "none",
                fontWeight: "600",
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant=""
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              This Week
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                style={{
                  color: "#27337D",
                  border: "1px solid",
                  borderRadius: "25px",
                  textTransform: "none",
                  fontWeight: "600",
                }}
                onClick={handleClose}
              >
                This Week
              </MenuItem>
              <MenuItem onClick={handleClose}>Last Week</MenuItem>
              {/* <MenuItem onClick={handleClose}>This Month</MenuItem>
              <MenuItem onClick={handleClose}>Last Month</MenuItem> */}
            </StyledMenu>
          </Typography>
        </CardHeader>
        <CardBody>
          {props.dashboardData.dailyDepositeCount &&
            props.dashboardData.dailyDepositeCount.data.map((row) => (
              <>
                <Typography className={classes.typographyAmmount}>
                  Total Deposits {row.monthYear}
                </Typography>
                <Typography className={classes.typographyCountGraph}>
                  ${row.totalAmount}
                </Typography>
              </>
            ))}
        </CardBody>
      </Grid>
      <div id="chartdiv" style={{ width: "100%", height: "500px" }} />
    </>
  );
}

Daliydeposits.propTypes = {
  dashboardData: PropTypes.object,
  dailyDepositeData: PropTypes.any,
};

export default Daliydeposits;
