export const cptCodeData = {
    "CPT_Code_Data": [
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7a",
            "Date_offered": "11/23/2020",
            "Total_funded": 3500,
            "CPT_Code": "M84.58XS",
            "CPT_Description": "Pathological fracture in neoplastic disease, other specified site, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc8a",
            "Date_offered": "11/07/2020",
            "Total_funded": 5100,
            "CPT_Code": "M45.9",
            "CPT_Description": "Ankylosing spondylitis of unspecified sites in spine"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc8f",
            "Date_offered": "11/02/2020",
            "Total_funded": 5600,
            "CPT_Code": "H44.139",
            "CPT_Description": "Sympathetic uveitis, unspecified eye"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7c",
            "Date_offered": "11/21/2020",
            "Total_funded": 3700,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7c",
            "Date_offered": "11/21/2020",
            "Total_funded": 3700,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7c",
            "Date_offered": "11/21/2020",
            "Total_funded": 3700,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7c",
            "Date_offered": "11/21/2020",
            "Total_funded": 3700,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7c",
            "Date_offered": "11/21/2020",
            "Total_funded": 3700,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7c",
            "Date_offered": "11/21/2020",
            "Total_funded": 3700,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7c",
            "Date_offered": "11/21/2020",
            "Total_funded": 3700,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7c",
            "Date_offered": "11/21/2020",
            "Total_funded": 3700,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc77",
            "Date_offered": "11/26/2020",
            "Total_funded": 3200,
            "CPT_Code": "C46.4",
            "CPT_Description": "Kaposi's sarcoma of gastrointestinal sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc77",
            "Date_offered": "11/26/2020",
            "Total_funded": 3200,
            "CPT_Code": "C46.4",
            "CPT_Description": "Kaposi's sarcoma of gastrointestinal sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc77",
            "Date_offered": "11/26/2020",
            "Total_funded": 3200,
            "CPT_Code": "C46.4",
            "CPT_Description": "Kaposi's sarcoma of gastrointestinal sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc77",
            "Date_offered": "11/26/2020",
            "Total_funded": 3200,
            "CPT_Code": "C46.4",
            "CPT_Description": "Kaposi's sarcoma of gastrointestinal sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc78",
            "Date_offered": "11/25/2020",
            "Total_funded": 3300,
            "CPT_Code": "C50.929",
            "CPT_Description": "Malignant neoplasm of unspecified site of unspecified male breast"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc78",
            "Date_offered": "11/25/2020",
            "Total_funded": 3300,
            "CPT_Code": "C50.929",
            "CPT_Description": "Malignant neoplasm of unspecified site of unspecified male breast"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc78",
            "Date_offered": "11/25/2020",
            "Total_funded": 3300,
            "CPT_Code": "C50.929",
            "CPT_Description": "Malignant neoplasm of unspecified site of unspecified male breast"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc76",
            "Date_offered": "11/27/2020",
            "Total_funded": 3100,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc76",
            "Date_offered": "11/27/2020",
            "Total_funded": 3100,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc76",
            "Date_offered": "11/27/2020",
            "Total_funded": 3100,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc76",
            "Date_offered": "11/27/2020",
            "Total_funded": 3100,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc76",
            "Date_offered": "11/27/2020",
            "Total_funded": 3100,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc76",
            "Date_offered": "11/27/2020",
            "Total_funded": 3100,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc76",
            "Date_offered": "11/27/2020",
            "Total_funded": 3100,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc76",
            "Date_offered": "11/27/2020",
            "Total_funded": 3100,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc76",
            "Date_offered": "11/27/2020",
            "Total_funded": 3100,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc76",
            "Date_offered": "11/27/2020",
            "Total_funded": 3100,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7b",
            "Date_offered": "11/22/2020",
            "Total_funded": 3600,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7b",
            "Date_offered": "11/22/2020",
            "Total_funded": 3600,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7b",
            "Date_offered": "11/22/2020",
            "Total_funded": 3600,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7b",
            "Date_offered": "11/22/2020",
            "Total_funded": 3600,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7b",
            "Date_offered": "11/22/2020",
            "Total_funded": 3600,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7b",
            "Date_offered": "11/22/2020",
            "Total_funded": 3600,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7b",
            "Date_offered": "11/22/2020",
            "Total_funded": 3600,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7b",
            "Date_offered": "11/22/2020",
            "Total_funded": 3600,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc86",
            "Date_offered": "11/11/2020",
            "Total_funded": 4700,
            "CPT_Code": "M06.39",
            "CPT_Description": "Rheumatoid nodule, multiple sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc86",
            "Date_offered": "11/11/2020",
            "Total_funded": 4700,
            "CPT_Code": "M06.39",
            "CPT_Description": "Rheumatoid nodule, multiple sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc8b",
            "Date_offered": "11/06/2020",
            "Total_funded": 5200,
            "CPT_Code": "M48.9",
            "CPT_Description": "Spondylopathy, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc93",
            "Date_offered": "10/29/2020",
            "Total_funded": 6000,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc93",
            "Date_offered": "10/29/2020",
            "Total_funded": 6000,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc93",
            "Date_offered": "10/29/2020",
            "Total_funded": 6000,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc93",
            "Date_offered": "10/29/2020",
            "Total_funded": 6000,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc93",
            "Date_offered": "10/29/2020",
            "Total_funded": 6000,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc93",
            "Date_offered": "10/29/2020",
            "Total_funded": 6000,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc93",
            "Date_offered": "10/29/2020",
            "Total_funded": 6000,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc93",
            "Date_offered": "10/29/2020",
            "Total_funded": 6000,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc93",
            "Date_offered": "10/29/2020",
            "Total_funded": 6000,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc93",
            "Date_offered": "10/29/2020",
            "Total_funded": 6000,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9d",
            "Date_offered": "10/19/2020",
            "Total_funded": 7000,
            "CPT_Code": "T47.7X5S",
            "CPT_Description": "Adverse effect of emetics, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9d",
            "Date_offered": "10/19/2020",
            "Total_funded": 7000,
            "CPT_Code": "T47.7X5S",
            "CPT_Description": "Adverse effect of emetics, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcaa",
            "Date_offered": "10/06/2020",
            "Total_funded": 8300,
            "CPT_Code": "O09.893",
            "CPT_Description": "Supervision of other high risk pregnancies, third trimester"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcad",
            "Date_offered": "10/03/2020",
            "Total_funded": 8600,
            "CPT_Code": "M05.89",
            "CPT_Description": "Other rheumatoid arthritis with rheumatoid factor of multiple sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc92",
            "Date_offered": "10/30/2020",
            "Total_funded": 5900,
            "CPT_Code": "M33.99",
            "CPT_Description": "Dermatopolymyositis, unspecified with other organ involvement"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9a",
            "Date_offered": "10/22/2020",
            "Total_funded": 6700,
            "CPT_Code": "D03.9",
            "CPT_Description": "Melanoma in situ, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9a",
            "Date_offered": "10/22/2020",
            "Total_funded": 6700,
            "CPT_Code": "D03.9",
            "CPT_Description": "Melanoma in situ, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9a",
            "Date_offered": "10/22/2020",
            "Total_funded": 6700,
            "CPT_Code": "D03.9",
            "CPT_Description": "Melanoma in situ, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9a",
            "Date_offered": "10/22/2020",
            "Total_funded": 6700,
            "CPT_Code": "D03.9",
            "CPT_Description": "Melanoma in situ, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca1",
            "Date_offered": "10/15/2020",
            "Total_funded": 7400,
            "CPT_Code": "C44.1292",
            "CPT_Description": "Squamous cell carcinoma of skin of left lower eyelid, including canthus"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca1",
            "Date_offered": "10/15/2020",
            "Total_funded": 7400,
            "CPT_Code": "C44.1292",
            "CPT_Description": "Squamous cell carcinoma of skin of left lower eyelid, including canthus"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca1",
            "Date_offered": "10/15/2020",
            "Total_funded": 7400,
            "CPT_Code": "C44.1292",
            "CPT_Description": "Squamous cell carcinoma of skin of left lower eyelid, including canthus"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca5",
            "Date_offered": "10/11/2020",
            "Total_funded": 7800,
            "CPT_Code": "C50.929",
            "CPT_Description": "Malignant neoplasm of unspecified site of unspecified male breast"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca5",
            "Date_offered": "10/11/2020",
            "Total_funded": 7800,
            "CPT_Code": "C50.929",
            "CPT_Description": "Malignant neoplasm of unspecified site of unspecified male breast"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca5",
            "Date_offered": "10/11/2020",
            "Total_funded": 7800,
            "CPT_Code": "C50.929",
            "CPT_Description": "Malignant neoplasm of unspecified site of unspecified male breast"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca9",
            "Date_offered": "10/07/2020",
            "Total_funded": 8200,
            "CPT_Code": "D03.9",
            "CPT_Description": "Melanoma in situ, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca9",
            "Date_offered": "10/07/2020",
            "Total_funded": 8200,
            "CPT_Code": "D03.9",
            "CPT_Description": "Melanoma in situ, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca9",
            "Date_offered": "10/07/2020",
            "Total_funded": 8200,
            "CPT_Code": "D03.9",
            "CPT_Description": "Melanoma in situ, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca9",
            "Date_offered": "10/07/2020",
            "Total_funded": 8200,
            "CPT_Code": "D03.9",
            "CPT_Description": "Melanoma in situ, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcac",
            "Date_offered": "10/04/2020",
            "Total_funded": 8500,
            "CPT_Code": "M05.79",
            "CPT_Description": "Rheumatoid arthritis with rheumatoid factor of multiple sites without organ or systems involvement"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc95",
            "Date_offered": "10/27/2020",
            "Total_funded": 6200,
            "CPT_Code": "C46.4",
            "CPT_Description": "Kaposi's sarcoma of gastrointestinal sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc95",
            "Date_offered": "10/27/2020",
            "Total_funded": 6200,
            "CPT_Code": "C46.4",
            "CPT_Description": "Kaposi's sarcoma of gastrointestinal sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc95",
            "Date_offered": "10/27/2020",
            "Total_funded": 6200,
            "CPT_Code": "C46.4",
            "CPT_Description": "Kaposi's sarcoma of gastrointestinal sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc95",
            "Date_offered": "10/27/2020",
            "Total_funded": 6200,
            "CPT_Code": "C46.4",
            "CPT_Description": "Kaposi's sarcoma of gastrointestinal sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb1",
            "Date_offered": "09/29/2020",
            "Total_funded": 9000,
            "CPT_Code": "C82.09",
            "CPT_Description": "Follicular lymphoma grade I, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb1",
            "Date_offered": "09/29/2020",
            "Total_funded": 9000,
            "CPT_Code": "C82.09",
            "CPT_Description": "Follicular lymphoma grade I, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb1",
            "Date_offered": "09/29/2020",
            "Total_funded": 9000,
            "CPT_Code": "C82.09",
            "CPT_Description": "Follicular lymphoma grade I, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb1",
            "Date_offered": "09/29/2020",
            "Total_funded": 9000,
            "CPT_Code": "C82.09",
            "CPT_Description": "Follicular lymphoma grade I, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb1",
            "Date_offered": "09/29/2020",
            "Total_funded": 9000,
            "CPT_Code": "C82.09",
            "CPT_Description": "Follicular lymphoma grade I, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc8d",
            "Date_offered": "11/04/2020",
            "Total_funded": 5400,
            "CPT_Code": "H20.9",
            "CPT_Description": "Unspecified iridocyclitis"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca0",
            "Date_offered": "10/16/2020",
            "Total_funded": 7300,
            "CPT_Code": "C43.9",
            "CPT_Description": "Malignant melanoma of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca0",
            "Date_offered": "10/16/2020",
            "Total_funded": 7300,
            "CPT_Code": "C43.9",
            "CPT_Description": "Malignant melanoma of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca0",
            "Date_offered": "10/16/2020",
            "Total_funded": 7300,
            "CPT_Code": "C43.9",
            "CPT_Description": "Malignant melanoma of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca0",
            "Date_offered": "10/16/2020",
            "Total_funded": 7300,
            "CPT_Code": "C43.9",
            "CPT_Description": "Malignant melanoma of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca0",
            "Date_offered": "10/16/2020",
            "Total_funded": 7300,
            "CPT_Code": "C43.9",
            "CPT_Description": "Malignant melanoma of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb5",
            "Date_offered": "09/25/2020",
            "Total_funded": 9400,
            "CPT_Code": "C82.49",
            "CPT_Description": "Follicular lymphoma grade IIIb, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb5",
            "Date_offered": "09/25/2020",
            "Total_funded": 9400,
            "CPT_Code": "C82.49",
            "CPT_Description": "Follicular lymphoma grade IIIb, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb5",
            "Date_offered": "09/25/2020",
            "Total_funded": 9400,
            "CPT_Code": "C82.49",
            "CPT_Description": "Follicular lymphoma grade IIIb, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb5",
            "Date_offered": "09/25/2020",
            "Total_funded": 9400,
            "CPT_Code": "C82.49",
            "CPT_Description": "Follicular lymphoma grade IIIb, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb5",
            "Date_offered": "09/25/2020",
            "Total_funded": 9400,
            "CPT_Code": "C82.49",
            "CPT_Description": "Follicular lymphoma grade IIIb, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc82",
            "Date_offered": "11/15/2020",
            "Total_funded": 4300,
            "CPT_Code": "K50.919",
            "CPT_Description": "Crohn's disease, unspecified, with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc82",
            "Date_offered": "11/15/2020",
            "Total_funded": 4300,
            "CPT_Code": "K50.919",
            "CPT_Description": "Crohn's disease, unspecified, with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc82",
            "Date_offered": "11/15/2020",
            "Total_funded": 4300,
            "CPT_Code": "K50.919",
            "CPT_Description": "Crohn's disease, unspecified, with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc82",
            "Date_offered": "11/15/2020",
            "Total_funded": 4300,
            "CPT_Code": "K50.919",
            "CPT_Description": "Crohn's disease, unspecified, with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc8e",
            "Date_offered": "11/03/2020",
            "Total_funded": 5500,
            "CPT_Code": "H44.119",
            "CPT_Description": "Panuveitis, unspecified eye"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc99",
            "Date_offered": "10/23/2020",
            "Total_funded": 6600,
            "CPT_Code": "C67.9",
            "CPT_Description": "Malignant neoplasm of bladder, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc99",
            "Date_offered": "10/23/2020",
            "Total_funded": 6600,
            "CPT_Code": "C67.9",
            "CPT_Description": "Malignant neoplasm of bladder, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc99",
            "Date_offered": "10/23/2020",
            "Total_funded": 6600,
            "CPT_Code": "C67.9",
            "CPT_Description": "Malignant neoplasm of bladder, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc99",
            "Date_offered": "10/23/2020",
            "Total_funded": 6600,
            "CPT_Code": "C67.9",
            "CPT_Description": "Malignant neoplasm of bladder, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc99",
            "Date_offered": "10/23/2020",
            "Total_funded": 6600,
            "CPT_Code": "C67.9",
            "CPT_Description": "Malignant neoplasm of bladder, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca7",
            "Date_offered": "10/09/2020",
            "Total_funded": 8000,
            "CPT_Code": "C62.92",
            "CPT_Description": "Malignant neoplasm of left testis, unspecified whether descended or undescended"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca7",
            "Date_offered": "10/09/2020",
            "Total_funded": 8000,
            "CPT_Code": "C62.92",
            "CPT_Description": "Malignant neoplasm of left testis, unspecified whether descended or undescended"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca7",
            "Date_offered": "10/09/2020",
            "Total_funded": 8000,
            "CPT_Code": "C62.92",
            "CPT_Description": "Malignant neoplasm of left testis, unspecified whether descended or undescended"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc84",
            "Date_offered": "11/13/2020",
            "Total_funded": 4500,
            "CPT_Code": "L40.9",
            "CPT_Description": "Psoriasis, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc84",
            "Date_offered": "11/13/2020",
            "Total_funded": 4500,
            "CPT_Code": "L40.9",
            "CPT_Description": "Psoriasis, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc94",
            "Date_offered": "10/28/2020",
            "Total_funded": 6100,
            "CPT_Code": "C43.9",
            "CPT_Description": "Malignant melanoma of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc94",
            "Date_offered": "10/28/2020",
            "Total_funded": 6100,
            "CPT_Code": "C43.9",
            "CPT_Description": "Malignant melanoma of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc94",
            "Date_offered": "10/28/2020",
            "Total_funded": 6100,
            "CPT_Code": "C43.9",
            "CPT_Description": "Malignant melanoma of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc94",
            "Date_offered": "10/28/2020",
            "Total_funded": 6100,
            "CPT_Code": "C43.9",
            "CPT_Description": "Malignant melanoma of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc94",
            "Date_offered": "10/28/2020",
            "Total_funded": 6100,
            "CPT_Code": "C43.9",
            "CPT_Description": "Malignant melanoma of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc83",
            "Date_offered": "11/14/2020",
            "Total_funded": 4400,
            "CPT_Code": "K51.919",
            "CPT_Description": "Ulcerative colitis, unspecified with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc83",
            "Date_offered": "11/14/2020",
            "Total_funded": 4400,
            "CPT_Code": "K51.919",
            "CPT_Description": "Ulcerative colitis, unspecified with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc97",
            "Date_offered": "10/25/2020",
            "Total_funded": 6400,
            "CPT_Code": "C54.3",
            "CPT_Description": "Malignant neoplasm of fundus uteri"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc97",
            "Date_offered": "10/25/2020",
            "Total_funded": 6400,
            "CPT_Code": "C54.3",
            "CPT_Description": "Malignant neoplasm of fundus uteri"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc80",
            "Date_offered": "11/17/2020",
            "Total_funded": 4100,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc80",
            "Date_offered": "11/17/2020",
            "Total_funded": 4100,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc80",
            "Date_offered": "11/17/2020",
            "Total_funded": 4100,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc80",
            "Date_offered": "11/17/2020",
            "Total_funded": 4100,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc80",
            "Date_offered": "11/17/2020",
            "Total_funded": 4100,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc80",
            "Date_offered": "11/17/2020",
            "Total_funded": 4100,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc80",
            "Date_offered": "11/17/2020",
            "Total_funded": 4100,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc80",
            "Date_offered": "11/17/2020",
            "Total_funded": 4100,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7e",
            "Date_offered": "11/19/2020",
            "Total_funded": 3900,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7e",
            "Date_offered": "11/19/2020",
            "Total_funded": 3900,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7e",
            "Date_offered": "11/19/2020",
            "Total_funded": 3900,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7e",
            "Date_offered": "11/19/2020",
            "Total_funded": 3900,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7e",
            "Date_offered": "11/19/2020",
            "Total_funded": 3900,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7e",
            "Date_offered": "11/19/2020",
            "Total_funded": 3900,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7e",
            "Date_offered": "11/19/2020",
            "Total_funded": 3900,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7e",
            "Date_offered": "11/19/2020",
            "Total_funded": 3900,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcab",
            "Date_offered": "10/05/2020",
            "Total_funded": 8400,
            "CPT_Code": "O09.93",
            "CPT_Description": "Supervision of high risk pregnancy, unspecified, third trimester"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc79",
            "Date_offered": "11/24/2020",
            "Total_funded": 3400,
            "CPT_Code": "C57.22",
            "CPT_Description": "Malignant neoplasm of left round ligament"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc79",
            "Date_offered": "11/24/2020",
            "Total_funded": 3400,
            "CPT_Code": "C57.22",
            "CPT_Description": "Malignant neoplasm of left round ligament"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc79",
            "Date_offered": "11/24/2020",
            "Total_funded": 3400,
            "CPT_Code": "C57.22",
            "CPT_Description": "Malignant neoplasm of left round ligament"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9e",
            "Date_offered": "10/18/2020",
            "Total_funded": 7100,
            "CPT_Code": "T50.995S",
            "CPT_Description": "Adverse effect of other drugs, medicaments and biological substances, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9e",
            "Date_offered": "10/18/2020",
            "Total_funded": 7100,
            "CPT_Code": "T50.995S",
            "CPT_Description": "Adverse effect of other drugs, medicaments and biological substances, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9e",
            "Date_offered": "10/18/2020",
            "Total_funded": 7100,
            "CPT_Code": "T50.995S",
            "CPT_Description": "Adverse effect of other drugs, medicaments and biological substances, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9e",
            "Date_offered": "10/18/2020",
            "Total_funded": 7100,
            "CPT_Code": "T50.995S",
            "CPT_Description": "Adverse effect of other drugs, medicaments and biological substances, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7d",
            "Date_offered": "11/20/2020",
            "Total_funded": 3800,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7d",
            "Date_offered": "11/20/2020",
            "Total_funded": 3800,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7d",
            "Date_offered": "11/20/2020",
            "Total_funded": 3800,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7d",
            "Date_offered": "11/20/2020",
            "Total_funded": 3800,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7d",
            "Date_offered": "11/20/2020",
            "Total_funded": 3800,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7d",
            "Date_offered": "11/20/2020",
            "Total_funded": 3800,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7d",
            "Date_offered": "11/20/2020",
            "Total_funded": 3800,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7d",
            "Date_offered": "11/20/2020",
            "Total_funded": 3800,
            "CPT_Code": "M80.88XS",
            "CPT_Description": "Other osteoporosis with current pathological fracture, vertebra(e), sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc81",
            "Date_offered": "11/16/2020",
            "Total_funded": 4200,
            "CPT_Code": "T50.995S",
            "CPT_Description": "Adverse effect of other drugs, medicaments and biological substances, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc81",
            "Date_offered": "11/16/2020",
            "Total_funded": 4200,
            "CPT_Code": "T50.995S",
            "CPT_Description": "Adverse effect of other drugs, medicaments and biological substances, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc81",
            "Date_offered": "11/16/2020",
            "Total_funded": 4200,
            "CPT_Code": "T50.995S",
            "CPT_Description": "Adverse effect of other drugs, medicaments and biological substances, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc81",
            "Date_offered": "11/16/2020",
            "Total_funded": 4200,
            "CPT_Code": "T50.995S",
            "CPT_Description": "Adverse effect of other drugs, medicaments and biological substances, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc96",
            "Date_offered": "10/26/2020",
            "Total_funded": 6300,
            "CPT_Code": "C50.929",
            "CPT_Description": "Malignant neoplasm of unspecified site of unspecified male breast"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc96",
            "Date_offered": "10/26/2020",
            "Total_funded": 6300,
            "CPT_Code": "C50.929",
            "CPT_Description": "Malignant neoplasm of unspecified site of unspecified male breast"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc96",
            "Date_offered": "10/26/2020",
            "Total_funded": 6300,
            "CPT_Code": "C50.929",
            "CPT_Description": "Malignant neoplasm of unspecified site of unspecified male breast"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcaf",
            "Date_offered": "10/01/2020",
            "Total_funded": 8800,
            "CPT_Code": "M06.39",
            "CPT_Description": "Rheumatoid nodule, multiple sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcaf",
            "Date_offered": "10/01/2020",
            "Total_funded": 8800,
            "CPT_Code": "M06.39",
            "CPT_Description": "Rheumatoid nodule, multiple sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc7f",
            "Date_offered": "11/18/2020",
            "Total_funded": 4000,
            "CPT_Code": "M88.9",
            "CPT_Description": "Osteitis deformans of unspecified bone"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc87",
            "Date_offered": "11/10/2020",
            "Total_funded": 4800,
            "CPT_Code": "M06.9",
            "CPT_Description": "Rheumatoid arthritis, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc87",
            "Date_offered": "11/10/2020",
            "Total_funded": 4800,
            "CPT_Code": "M06.9",
            "CPT_Description": "Rheumatoid arthritis, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc89",
            "Date_offered": "11/08/2020",
            "Total_funded": 5000,
            "CPT_Code": "M08.99",
            "CPT_Description": "Juvenile arthritis, unspecified, multiple sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb0",
            "Date_offered": "09/30/2020",
            "Total_funded": 8900,
            "CPT_Code": "M06.89",
            "CPT_Description": "Other specified rheumatoid arthritis, multiple sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc85",
            "Date_offered": "11/12/2020",
            "Total_funded": 4600,
            "CPT_Code": "M05.9",
            "CPT_Description": "Rheumatoid arthritis with rheumatoid factor, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9c",
            "Date_offered": "10/20/2020",
            "Total_funded": 6900,
            "CPT_Code": "T45.1X5S",
            "CPT_Description": "Adverse effect of antineoplastic and immunosuppressive drugs, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9c",
            "Date_offered": "10/20/2020",
            "Total_funded": 6900,
            "CPT_Code": "T45.1X5S",
            "CPT_Description": "Adverse effect of antineoplastic and immunosuppressive drugs, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca2",
            "Date_offered": "10/14/2020",
            "Total_funded": 7500,
            "CPT_Code": "C44.99",
            "CPT_Description": "Other specified malignant neoplasm of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca2",
            "Date_offered": "10/14/2020",
            "Total_funded": 7500,
            "CPT_Code": "C44.99",
            "CPT_Description": "Other specified malignant neoplasm of skin, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca6",
            "Date_offered": "10/10/2020",
            "Total_funded": 7900,
            "CPT_Code": "C51.2",
            "CPT_Description": "Malignant neoplasm of clitoris"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca6",
            "Date_offered": "10/10/2020",
            "Total_funded": 7900,
            "CPT_Code": "C51.2",
            "CPT_Description": "Malignant neoplasm of clitoris"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca6",
            "Date_offered": "10/10/2020",
            "Total_funded": 7900,
            "CPT_Code": "C51.2",
            "CPT_Description": "Malignant neoplasm of clitoris"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca6",
            "Date_offered": "10/10/2020",
            "Total_funded": 7900,
            "CPT_Code": "C51.2",
            "CPT_Description": "Malignant neoplasm of clitoris"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca8",
            "Date_offered": "10/08/2020",
            "Total_funded": 8100,
            "CPT_Code": "C85.99",
            "CPT_Description": "Non-Hodgkin lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca8",
            "Date_offered": "10/08/2020",
            "Total_funded": 8100,
            "CPT_Code": "C85.99",
            "CPT_Description": "Non-Hodgkin lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca8",
            "Date_offered": "10/08/2020",
            "Total_funded": 8100,
            "CPT_Code": "C85.99",
            "CPT_Description": "Non-Hodgkin lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca8",
            "Date_offered": "10/08/2020",
            "Total_funded": 8100,
            "CPT_Code": "C85.99",
            "CPT_Description": "Non-Hodgkin lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca8",
            "Date_offered": "10/08/2020",
            "Total_funded": 8100,
            "CPT_Code": "C85.99",
            "CPT_Description": "Non-Hodgkin lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca8",
            "Date_offered": "10/08/2020",
            "Total_funded": 8100,
            "CPT_Code": "C85.99",
            "CPT_Description": "Non-Hodgkin lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb4",
            "Date_offered": "09/26/2020",
            "Total_funded": 9300,
            "CPT_Code": "C82.39",
            "CPT_Description": "Follicular lymphoma grade IIIa, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb4",
            "Date_offered": "09/26/2020",
            "Total_funded": 9300,
            "CPT_Code": "C82.39",
            "CPT_Description": "Follicular lymphoma grade IIIa, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb4",
            "Date_offered": "09/26/2020",
            "Total_funded": 9300,
            "CPT_Code": "C82.39",
            "CPT_Description": "Follicular lymphoma grade IIIa, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb4",
            "Date_offered": "09/26/2020",
            "Total_funded": 9300,
            "CPT_Code": "C82.39",
            "CPT_Description": "Follicular lymphoma grade IIIa, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb4",
            "Date_offered": "09/26/2020",
            "Total_funded": 9300,
            "CPT_Code": "C82.39",
            "CPT_Description": "Follicular lymphoma grade IIIa, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc90",
            "Date_offered": "11/01/2020",
            "Total_funded": 5700,
            "CPT_Code": "K50.919",
            "CPT_Description": "Crohn's disease, unspecified, with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc90",
            "Date_offered": "11/01/2020",
            "Total_funded": 5700,
            "CPT_Code": "K50.919",
            "CPT_Description": "Crohn's disease, unspecified, with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc90",
            "Date_offered": "11/01/2020",
            "Total_funded": 5700,
            "CPT_Code": "K50.919",
            "CPT_Description": "Crohn's disease, unspecified, with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc90",
            "Date_offered": "11/01/2020",
            "Total_funded": 5700,
            "CPT_Code": "K50.919",
            "CPT_Description": "Crohn's disease, unspecified, with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcae",
            "Date_offered": "10/02/2020",
            "Total_funded": 8700,
            "CPT_Code": "M06.09",
            "CPT_Description": "Rheumatoid arthritis without rheumatoid factor, multiple sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc91",
            "Date_offered": "10/31/2020",
            "Total_funded": 5800,
            "CPT_Code": "K51.919",
            "CPT_Description": "Ulcerative colitis, unspecified with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc91",
            "Date_offered": "10/31/2020",
            "Total_funded": 5800,
            "CPT_Code": "K51.919",
            "CPT_Description": "Ulcerative colitis, unspecified with unspecified complications"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc98",
            "Date_offered": "10/24/2020",
            "Total_funded": 6500,
            "CPT_Code": "C57.22",
            "CPT_Description": "Malignant neoplasm of left round ligament"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc98",
            "Date_offered": "10/24/2020",
            "Total_funded": 6500,
            "CPT_Code": "C57.22",
            "CPT_Description": "Malignant neoplasm of left round ligament"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc98",
            "Date_offered": "10/24/2020",
            "Total_funded": 6500,
            "CPT_Code": "C57.22",
            "CPT_Description": "Malignant neoplasm of left round ligament"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca4",
            "Date_offered": "10/12/2020",
            "Total_funded": 7700,
            "CPT_Code": "C49.9",
            "CPT_Description": "Malignant neoplasm of connective and soft tissue, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb3",
            "Date_offered": "09/27/2020",
            "Total_funded": 9200,
            "CPT_Code": "C82.29",
            "CPT_Description": "Follicular lymphoma grade III, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb3",
            "Date_offered": "09/27/2020",
            "Total_funded": 9200,
            "CPT_Code": "C82.29",
            "CPT_Description": "Follicular lymphoma grade III, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb3",
            "Date_offered": "09/27/2020",
            "Total_funded": 9200,
            "CPT_Code": "C82.29",
            "CPT_Description": "Follicular lymphoma grade III, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb3",
            "Date_offered": "09/27/2020",
            "Total_funded": 9200,
            "CPT_Code": "C82.29",
            "CPT_Description": "Follicular lymphoma grade III, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb3",
            "Date_offered": "09/27/2020",
            "Total_funded": 9200,
            "CPT_Code": "C82.29",
            "CPT_Description": "Follicular lymphoma grade III, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc88",
            "Date_offered": "11/09/2020",
            "Total_funded": 4900,
            "CPT_Code": "M08.29",
            "CPT_Description": "Juvenile rheumatoid arthritis with systemic onset, multiple sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc8c",
            "Date_offered": "11/05/2020",
            "Total_funded": 5300,
            "CPT_Code": "D86.9",
            "CPT_Description": "Sarcoidosis, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9b",
            "Date_offered": "10/21/2020",
            "Total_funded": 6800,
            "CPT_Code": "T45.0X5S",
            "CPT_Description": "Adverse effect of antiallergic and antiemetic drugs, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9b",
            "Date_offered": "10/21/2020",
            "Total_funded": 6800,
            "CPT_Code": "T45.0X5S",
            "CPT_Description": "Adverse effect of antiallergic and antiemetic drugs, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9f",
            "Date_offered": "10/17/2020",
            "Total_funded": 7200,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9f",
            "Date_offered": "10/17/2020",
            "Total_funded": 7200,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9f",
            "Date_offered": "10/17/2020",
            "Total_funded": 7200,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9f",
            "Date_offered": "10/17/2020",
            "Total_funded": 7200,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9f",
            "Date_offered": "10/17/2020",
            "Total_funded": 7200,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9f",
            "Date_offered": "10/17/2020",
            "Total_funded": 7200,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9f",
            "Date_offered": "10/17/2020",
            "Total_funded": 7200,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9f",
            "Date_offered": "10/17/2020",
            "Total_funded": 7200,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9f",
            "Date_offered": "10/17/2020",
            "Total_funded": 7200,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dc9f",
            "Date_offered": "10/17/2020",
            "Total_funded": 7200,
            "CPT_Code": "C34.92",
            "CPT_Description": "Malignant neoplasm of unspecified part of left bronchus or lung"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dca3",
            "Date_offered": "10/13/2020",
            "Total_funded": 7600,
            "CPT_Code": "C47.9",
            "CPT_Description": "Malignant neoplasm of peripheral nerves and autonomic nervous system, unspecified"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb2",
            "Date_offered": "09/28/2020",
            "Total_funded": 9100,
            "CPT_Code": "C82.19",
            "CPT_Description": "Follicular lymphoma grade II, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb2",
            "Date_offered": "09/28/2020",
            "Total_funded": 9100,
            "CPT_Code": "C82.19",
            "CPT_Description": "Follicular lymphoma grade II, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb2",
            "Date_offered": "09/28/2020",
            "Total_funded": 9100,
            "CPT_Code": "C82.19",
            "CPT_Description": "Follicular lymphoma grade II, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb2",
            "Date_offered": "09/28/2020",
            "Total_funded": 9100,
            "CPT_Code": "C82.19",
            "CPT_Description": "Follicular lymphoma grade II, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb2",
            "Date_offered": "09/28/2020",
            "Total_funded": 9100,
            "CPT_Code": "C82.19",
            "CPT_Description": "Follicular lymphoma grade II, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd23",
            "Date_offered": "06/07/2020",
            "Total_funded": 20400,
            "CPT_Code": "S82.155S",
            "CPT_Description": "Nondisplaced fracture of left tibial tuberosity, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd23",
            "Date_offered": "06/07/2020",
            "Total_funded": 20400,
            "CPT_Code": "S82.155S",
            "CPT_Description": "Nondisplaced fracture of left tibial tuberosity, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd23",
            "Date_offered": "06/07/2020",
            "Total_funded": 20400,
            "CPT_Code": "S82.155S",
            "CPT_Description": "Nondisplaced fracture of left tibial tuberosity, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc2",
            "Date_offered": "09/12/2020",
            "Total_funded": 10700,
            "CPT_Code": "C57.02",
            "CPT_Description": "Malignant neoplasm of left fallopian tube"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbd",
            "Date_offered": "09/17/2020",
            "Total_funded": 10200,
            "CPT_Code": "C83.79",
            "CPT_Description": "Burkitt lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbd",
            "Date_offered": "09/17/2020",
            "Total_funded": 10200,
            "CPT_Code": "C83.79",
            "CPT_Description": "Burkitt lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbd",
            "Date_offered": "09/17/2020",
            "Total_funded": 10200,
            "CPT_Code": "C83.79",
            "CPT_Description": "Burkitt lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbd",
            "Date_offered": "09/17/2020",
            "Total_funded": 10200,
            "CPT_Code": "C83.79",
            "CPT_Description": "Burkitt lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbd",
            "Date_offered": "09/17/2020",
            "Total_funded": 10200,
            "CPT_Code": "C83.79",
            "CPT_Description": "Burkitt lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb6",
            "Date_offered": "09/24/2020",
            "Total_funded": 9500,
            "CPT_Code": "C82.59",
            "CPT_Description": "Diffuse follicle center lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb6",
            "Date_offered": "09/24/2020",
            "Total_funded": 9500,
            "CPT_Code": "C82.59",
            "CPT_Description": "Diffuse follicle center lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb6",
            "Date_offered": "09/24/2020",
            "Total_funded": 9500,
            "CPT_Code": "C82.59",
            "CPT_Description": "Diffuse follicle center lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb6",
            "Date_offered": "09/24/2020",
            "Total_funded": 9500,
            "CPT_Code": "C82.59",
            "CPT_Description": "Diffuse follicle center lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb6",
            "Date_offered": "09/24/2020",
            "Total_funded": 9500,
            "CPT_Code": "C82.59",
            "CPT_Description": "Diffuse follicle center lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd26",
            "Date_offered": "06/04/2020",
            "Total_funded": 20700,
            "CPT_Code": "S82.202S",
            "CPT_Description": "Unspecified fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd26",
            "Date_offered": "06/04/2020",
            "Total_funded": 20700,
            "CPT_Code": "S82.202S",
            "CPT_Description": "Unspecified fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3e",
            "Date_offered": "05/11/2020",
            "Total_funded": 23100,
            "CPT_Code": "S82.462S",
            "CPT_Description": "Displaced segmental fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3e",
            "Date_offered": "05/11/2020",
            "Total_funded": 23100,
            "CPT_Code": "S82.462S",
            "CPT_Description": "Displaced segmental fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3e",
            "Date_offered": "05/11/2020",
            "Total_funded": 23100,
            "CPT_Code": "S82.462S",
            "CPT_Description": "Displaced segmental fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5f",
            "Date_offered": "04/08/2020",
            "Total_funded": 26400,
            "CPT_Code": "S83.135S",
            "CPT_Description": "Medial dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5f",
            "Date_offered": "04/08/2020",
            "Total_funded": 26400,
            "CPT_Code": "S83.135S",
            "CPT_Description": "Medial dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5f",
            "Date_offered": "04/08/2020",
            "Total_funded": 26400,
            "CPT_Code": "S83.135S",
            "CPT_Description": "Medial dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd66",
            "Date_offered": "04/01/2020",
            "Total_funded": 27100,
            "CPT_Code": "S83.207S",
            "CPT_Description": "Unspecified tear of unspecified meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd66",
            "Date_offered": "04/01/2020",
            "Total_funded": 27100,
            "CPT_Code": "S83.207S",
            "CPT_Description": "Unspecified tear of unspecified meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd82",
            "Date_offered": "03/04/2020",
            "Total_funded": 29900,
            "CPT_Code": "S89.112S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd82",
            "Date_offered": "03/04/2020",
            "Total_funded": 29900,
            "CPT_Code": "S89.112S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd82",
            "Date_offered": "03/04/2020",
            "Total_funded": 29900,
            "CPT_Code": "S89.112S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd96",
            "Date_offered": "02/13/2020",
            "Total_funded": 31900,
            "CPT_Code": "T84.127S",
            "CPT_Description": "Displacement of internal fixation device of bone of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd96",
            "Date_offered": "02/13/2020",
            "Total_funded": 31900,
            "CPT_Code": "T84.127S",
            "CPT_Description": "Displacement of internal fixation device of bone of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb6",
            "Date_offered": "01/12/2020",
            "Total_funded": 35100,
            "CPT_Code": "S72.065S",
            "CPT_Description": "Nondisplaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb6",
            "Date_offered": "01/12/2020",
            "Total_funded": 35100,
            "CPT_Code": "S72.065S",
            "CPT_Description": "Nondisplaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb6",
            "Date_offered": "01/12/2020",
            "Total_funded": 35100,
            "CPT_Code": "S72.065S",
            "CPT_Description": "Nondisplaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbc",
            "Date_offered": "01/06/2020",
            "Total_funded": 35700,
            "CPT_Code": "S72.125S",
            "CPT_Description": "Nondisplaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbc",
            "Date_offered": "01/06/2020",
            "Total_funded": 35700,
            "CPT_Code": "S72.125S",
            "CPT_Description": "Nondisplaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbc",
            "Date_offered": "01/06/2020",
            "Total_funded": 35700,
            "CPT_Code": "S72.125S",
            "CPT_Description": "Nondisplaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbc",
            "Date_offered": "01/06/2020",
            "Total_funded": 35700,
            "CPT_Code": "S72.125S",
            "CPT_Description": "Nondisplaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc5",
            "Date_offered": "09/09/2020",
            "Total_funded": 11000,
            "CPT_Code": "C57.8",
            "CPT_Description": "Malignant neoplasm of overlapping sites of female genital organs"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce2",
            "Date_offered": "08/11/2020",
            "Total_funded": 13900,
            "CPT_Code": "S72.125S",
            "CPT_Description": "Nondisplaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce2",
            "Date_offered": "08/11/2020",
            "Total_funded": 13900,
            "CPT_Code": "S72.125S",
            "CPT_Description": "Nondisplaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce2",
            "Date_offered": "08/11/2020",
            "Total_funded": 13900,
            "CPT_Code": "S72.125S",
            "CPT_Description": "Nondisplaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce2",
            "Date_offered": "08/11/2020",
            "Total_funded": 13900,
            "CPT_Code": "S72.125S",
            "CPT_Description": "Nondisplaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcea",
            "Date_offered": "08/03/2020",
            "Total_funded": 14700,
            "CPT_Code": "S72.322S",
            "CPT_Description": "Displaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcea",
            "Date_offered": "08/03/2020",
            "Total_funded": 14700,
            "CPT_Code": "S72.322S",
            "CPT_Description": "Displaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcea",
            "Date_offered": "08/03/2020",
            "Total_funded": 14700,
            "CPT_Code": "S72.322S",
            "CPT_Description": "Displaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf9",
            "Date_offered": "07/19/2020",
            "Total_funded": 16200,
            "CPT_Code": "S72.425S",
            "CPT_Description": "Nondisplaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf9",
            "Date_offered": "07/19/2020",
            "Total_funded": 16200,
            "CPT_Code": "S72.425S",
            "CPT_Description": "Nondisplaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf9",
            "Date_offered": "07/19/2020",
            "Total_funded": 16200,
            "CPT_Code": "S72.425S",
            "CPT_Description": "Nondisplaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd05",
            "Date_offered": "07/07/2020",
            "Total_funded": 17400,
            "CPT_Code": "S72.92XS",
            "CPT_Description": "Unspecified fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd05",
            "Date_offered": "07/07/2020",
            "Total_funded": 17400,
            "CPT_Code": "S72.92XS",
            "CPT_Description": "Unspecified fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd06",
            "Date_offered": "07/06/2020",
            "Total_funded": 17500,
            "CPT_Code": "S79.002S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd06",
            "Date_offered": "07/06/2020",
            "Total_funded": 17500,
            "CPT_Code": "S79.002S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd06",
            "Date_offered": "07/06/2020",
            "Total_funded": 17500,
            "CPT_Code": "S79.002S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0a",
            "Date_offered": "07/02/2020",
            "Total_funded": 17900,
            "CPT_Code": "S79.112S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0a",
            "Date_offered": "07/02/2020",
            "Total_funded": 17900,
            "CPT_Code": "S79.112S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0a",
            "Date_offered": "07/02/2020",
            "Total_funded": 17900,
            "CPT_Code": "S79.112S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd19",
            "Date_offered": "06/17/2020",
            "Total_funded": 19400,
            "CPT_Code": "S82.102S",
            "CPT_Description": "Unspecified fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd19",
            "Date_offered": "06/17/2020",
            "Total_funded": 19400,
            "CPT_Code": "S82.102S",
            "CPT_Description": "Unspecified fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd22",
            "Date_offered": "06/08/2020",
            "Total_funded": 20300,
            "CPT_Code": "S82.152S",
            "CPT_Description": "Displaced fracture of left tibial tuberosity, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd22",
            "Date_offered": "06/08/2020",
            "Total_funded": 20300,
            "CPT_Code": "S82.152S",
            "CPT_Description": "Displaced fracture of left tibial tuberosity, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd22",
            "Date_offered": "06/08/2020",
            "Total_funded": 20300,
            "CPT_Code": "S82.152S",
            "CPT_Description": "Displaced fracture of left tibial tuberosity, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd38",
            "Date_offered": "05/17/2020",
            "Total_funded": 22500,
            "CPT_Code": "S82.432S",
            "CPT_Description": "Displaced oblique fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd38",
            "Date_offered": "05/17/2020",
            "Total_funded": 22500,
            "CPT_Code": "S82.432S",
            "CPT_Description": "Displaced oblique fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd38",
            "Date_offered": "05/17/2020",
            "Total_funded": 22500,
            "CPT_Code": "S82.432S",
            "CPT_Description": "Displaced oblique fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3a",
            "Date_offered": "05/15/2020",
            "Total_funded": 22700,
            "CPT_Code": "S82.442S",
            "CPT_Description": "Displaced spiral fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3a",
            "Date_offered": "05/15/2020",
            "Total_funded": 22700,
            "CPT_Code": "S82.442S",
            "CPT_Description": "Displaced spiral fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3a",
            "Date_offered": "05/15/2020",
            "Total_funded": 22700,
            "CPT_Code": "S82.442S",
            "CPT_Description": "Displaced spiral fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4e",
            "Date_offered": "04/25/2020",
            "Total_funded": 24700,
            "CPT_Code": "S82.872S",
            "CPT_Description": "Displaced pilon fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4e",
            "Date_offered": "04/25/2020",
            "Total_funded": 24700,
            "CPT_Code": "S82.872S",
            "CPT_Description": "Displaced pilon fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4e",
            "Date_offered": "04/25/2020",
            "Total_funded": 24700,
            "CPT_Code": "S82.872S",
            "CPT_Description": "Displaced pilon fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd56",
            "Date_offered": "04/17/2020",
            "Total_funded": 25500,
            "CPT_Code": "S83.092S",
            "CPT_Description": "Other subluxation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd56",
            "Date_offered": "04/17/2020",
            "Total_funded": 25500,
            "CPT_Code": "S83.092S",
            "CPT_Description": "Other subluxation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd56",
            "Date_offered": "04/17/2020",
            "Total_funded": 25500,
            "CPT_Code": "S83.092S",
            "CPT_Description": "Other subluxation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd65",
            "Date_offered": "04/02/2020",
            "Total_funded": 27000,
            "CPT_Code": "S83.204S",
            "CPT_Description": "Other tear of unspecified meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd65",
            "Date_offered": "04/02/2020",
            "Total_funded": 27000,
            "CPT_Code": "S83.204S",
            "CPT_Description": "Other tear of unspecified meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6e",
            "Date_offered": "03/24/2020",
            "Total_funded": 27900,
            "CPT_Code": "S83.282S",
            "CPT_Description": "Other tear of lateral meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6e",
            "Date_offered": "03/24/2020",
            "Total_funded": 27900,
            "CPT_Code": "S83.282S",
            "CPT_Description": "Other tear of lateral meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7f",
            "Date_offered": "03/07/2020",
            "Total_funded": 29600,
            "CPT_Code": "S89.042S",
            "CPT_Description": "Salter-Harris Type IV physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7f",
            "Date_offered": "03/07/2020",
            "Total_funded": 29600,
            "CPT_Code": "S89.042S",
            "CPT_Description": "Salter-Harris Type IV physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7f",
            "Date_offered": "03/07/2020",
            "Total_funded": 29600,
            "CPT_Code": "S89.042S",
            "CPT_Description": "Salter-Harris Type IV physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9c",
            "Date_offered": "02/07/2020",
            "Total_funded": 32500,
            "CPT_Code": "T84.89XS",
            "CPT_Description": "Other specified complication of internal orthopedic prosthetic devices, implants and grafts, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9c",
            "Date_offered": "02/07/2020",
            "Total_funded": 32500,
            "CPT_Code": "T84.89XS",
            "CPT_Description": "Other specified complication of internal orthopedic prosthetic devices, implants and grafts, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb0",
            "Date_offered": "01/18/2020",
            "Total_funded": 34500,
            "CPT_Code": "S72.032S",
            "CPT_Description": "Displaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb0",
            "Date_offered": "01/18/2020",
            "Total_funded": 34500,
            "CPT_Code": "S72.032S",
            "CPT_Description": "Displaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb0",
            "Date_offered": "01/18/2020",
            "Total_funded": 34500,
            "CPT_Code": "S72.032S",
            "CPT_Description": "Displaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc3",
            "Date_offered": "12/30/2019",
            "Total_funded": 36400,
            "CPT_Code": "S72.302S",
            "CPT_Description": "Unspecified fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc3",
            "Date_offered": "12/30/2019",
            "Total_funded": 36400,
            "CPT_Code": "S72.302S",
            "CPT_Description": "Unspecified fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc7",
            "Date_offered": "12/26/2019",
            "Total_funded": 36800,
            "CPT_Code": "S72.335S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc7",
            "Date_offered": "12/26/2019",
            "Total_funded": 36800,
            "CPT_Code": "S72.335S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc7",
            "Date_offered": "12/26/2019",
            "Total_funded": 36800,
            "CPT_Code": "S72.335S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dddb",
            "Date_offered": "12/06/2019",
            "Total_funded": 38800,
            "CPT_Code": "S72.465S",
            "CPT_Description": "Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dddb",
            "Date_offered": "12/06/2019",
            "Total_funded": 38800,
            "CPT_Code": "S72.465S",
            "CPT_Description": "Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dddb",
            "Date_offered": "12/06/2019",
            "Total_funded": 38800,
            "CPT_Code": "S72.465S",
            "CPT_Description": "Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf3",
            "Date_offered": "07/25/2020",
            "Total_funded": 15600,
            "CPT_Code": "S72.365S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf3",
            "Date_offered": "07/25/2020",
            "Total_funded": 15600,
            "CPT_Code": "S72.365S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf3",
            "Date_offered": "07/25/2020",
            "Total_funded": 15600,
            "CPT_Code": "S72.365S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd07",
            "Date_offered": "07/05/2020",
            "Total_funded": 17600,
            "CPT_Code": "S79.012S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd07",
            "Date_offered": "07/05/2020",
            "Total_funded": 17600,
            "CPT_Code": "S79.012S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd07",
            "Date_offered": "07/05/2020",
            "Total_funded": 17600,
            "CPT_Code": "S79.012S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd07",
            "Date_offered": "07/05/2020",
            "Total_funded": 17600,
            "CPT_Code": "S79.012S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd08",
            "Date_offered": "07/04/2020",
            "Total_funded": 17700,
            "CPT_Code": "S79.092S",
            "CPT_Description": "Other physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd08",
            "Date_offered": "07/04/2020",
            "Total_funded": 17700,
            "CPT_Code": "S79.092S",
            "CPT_Description": "Other physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0f",
            "Date_offered": "06/27/2020",
            "Total_funded": 18400,
            "CPT_Code": "S82.002S",
            "CPT_Description": "Unspecified fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0f",
            "Date_offered": "06/27/2020",
            "Total_funded": 18400,
            "CPT_Code": "S82.002S",
            "CPT_Description": "Unspecified fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd12",
            "Date_offered": "06/24/2020",
            "Total_funded": 18700,
            "CPT_Code": "S82.022S",
            "CPT_Description": "Displaced longitudinal fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd12",
            "Date_offered": "06/24/2020",
            "Total_funded": 18700,
            "CPT_Code": "S82.022S",
            "CPT_Description": "Displaced longitudinal fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd12",
            "Date_offered": "06/24/2020",
            "Total_funded": 18700,
            "CPT_Code": "S82.022S",
            "CPT_Description": "Displaced longitudinal fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd18",
            "Date_offered": "06/18/2020",
            "Total_funded": 19300,
            "CPT_Code": "S82.092S",
            "CPT_Description": "Other fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd18",
            "Date_offered": "06/18/2020",
            "Total_funded": 19300,
            "CPT_Code": "S82.092S",
            "CPT_Description": "Other fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd40",
            "Date_offered": "05/09/2020",
            "Total_funded": 23300,
            "CPT_Code": "S82.492S",
            "CPT_Description": "Other fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd40",
            "Date_offered": "05/09/2020",
            "Total_funded": 23300,
            "CPT_Code": "S82.492S",
            "CPT_Description": "Other fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd45",
            "Date_offered": "05/04/2020",
            "Total_funded": 23800,
            "CPT_Code": "S82.812S",
            "CPT_Description": "Torus fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd45",
            "Date_offered": "05/04/2020",
            "Total_funded": 23800,
            "CPT_Code": "S82.812S",
            "CPT_Description": "Torus fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd45",
            "Date_offered": "05/04/2020",
            "Total_funded": 23800,
            "CPT_Code": "S82.812S",
            "CPT_Description": "Torus fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5e",
            "Date_offered": "04/09/2020",
            "Total_funded": 26300,
            "CPT_Code": "S83.132S",
            "CPT_Description": "Medial subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5e",
            "Date_offered": "04/09/2020",
            "Total_funded": 26300,
            "CPT_Code": "S83.132S",
            "CPT_Description": "Medial subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5e",
            "Date_offered": "04/09/2020",
            "Total_funded": 26300,
            "CPT_Code": "S83.132S",
            "CPT_Description": "Medial subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd75",
            "Date_offered": "03/17/2020",
            "Total_funded": 28600,
            "CPT_Code": "S83.522S",
            "CPT_Description": "Sprain of posterior cruciate ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd75",
            "Date_offered": "03/17/2020",
            "Total_funded": 28600,
            "CPT_Code": "S83.522S",
            "CPT_Description": "Sprain of posterior cruciate ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd75",
            "Date_offered": "03/17/2020",
            "Total_funded": 28600,
            "CPT_Code": "S83.522S",
            "CPT_Description": "Sprain of posterior cruciate ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8c",
            "Date_offered": "02/23/2020",
            "Total_funded": 30900,
            "CPT_Code": "S89.312S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8c",
            "Date_offered": "02/23/2020",
            "Total_funded": 30900,
            "CPT_Code": "S89.312S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8c",
            "Date_offered": "02/23/2020",
            "Total_funded": 30900,
            "CPT_Code": "S89.312S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd90",
            "Date_offered": "02/19/2020",
            "Total_funded": 31300,
            "CPT_Code": "T84.023S",
            "CPT_Description": "Instability of internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd90",
            "Date_offered": "02/19/2020",
            "Total_funded": 31300,
            "CPT_Code": "T84.023S",
            "CPT_Description": "Instability of internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd90",
            "Date_offered": "02/19/2020",
            "Total_funded": 31300,
            "CPT_Code": "T84.023S",
            "CPT_Description": "Instability of internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda5",
            "Date_offered": "01/29/2020",
            "Total_funded": 33400,
            "CPT_Code": "M84.364S",
            "CPT_Description": "Stress fracture, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda5",
            "Date_offered": "01/29/2020",
            "Total_funded": 33400,
            "CPT_Code": "M84.364S",
            "CPT_Description": "Stress fracture, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddaf",
            "Date_offered": "01/19/2020",
            "Total_funded": 34400,
            "CPT_Code": "S72.025S",
            "CPT_Description": "Nondisplaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddaf",
            "Date_offered": "01/19/2020",
            "Total_funded": 34400,
            "CPT_Code": "S72.025S",
            "CPT_Description": "Nondisplaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddaf",
            "Date_offered": "01/19/2020",
            "Total_funded": 34400,
            "CPT_Code": "S72.025S",
            "CPT_Description": "Nondisplaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddba",
            "Date_offered": "01/08/2020",
            "Total_funded": 35500,
            "CPT_Code": "S72.115S",
            "CPT_Description": "Nondisplaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddba",
            "Date_offered": "01/08/2020",
            "Total_funded": 35500,
            "CPT_Code": "S72.115S",
            "CPT_Description": "Nondisplaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddba",
            "Date_offered": "01/08/2020",
            "Total_funded": 35500,
            "CPT_Code": "S72.115S",
            "CPT_Description": "Nondisplaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddba",
            "Date_offered": "01/08/2020",
            "Total_funded": 35500,
            "CPT_Code": "S72.115S",
            "CPT_Description": "Nondisplaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc4",
            "Date_offered": "12/29/2019",
            "Total_funded": 36500,
            "CPT_Code": "S72.322S",
            "CPT_Description": "Displaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc4",
            "Date_offered": "12/29/2019",
            "Total_funded": 36500,
            "CPT_Code": "S72.322S",
            "CPT_Description": "Displaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc4",
            "Date_offered": "12/29/2019",
            "Total_funded": 36500,
            "CPT_Code": "S72.322S",
            "CPT_Description": "Displaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcba",
            "Date_offered": "09/20/2020",
            "Total_funded": 9900,
            "CPT_Code": "C83.09",
            "CPT_Description": "Small cell B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcba",
            "Date_offered": "09/20/2020",
            "Total_funded": 9900,
            "CPT_Code": "C83.09",
            "CPT_Description": "Small cell B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcba",
            "Date_offered": "09/20/2020",
            "Total_funded": 9900,
            "CPT_Code": "C83.09",
            "CPT_Description": "Small cell B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcba",
            "Date_offered": "09/20/2020",
            "Total_funded": 9900,
            "CPT_Code": "C83.09",
            "CPT_Description": "Small cell B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcba",
            "Date_offered": "09/20/2020",
            "Total_funded": 9900,
            "CPT_Code": "C83.09",
            "CPT_Description": "Small cell B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcba",
            "Date_offered": "09/20/2020",
            "Total_funded": 9900,
            "CPT_Code": "C83.09",
            "CPT_Description": "Small cell B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbf",
            "Date_offered": "09/15/2020",
            "Total_funded": 10400,
            "CPT_Code": "C83.99",
            "CPT_Description": "Non-follicular (diffuse) lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbf",
            "Date_offered": "09/15/2020",
            "Total_funded": 10400,
            "CPT_Code": "C83.99",
            "CPT_Description": "Non-follicular (diffuse) lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbf",
            "Date_offered": "09/15/2020",
            "Total_funded": 10400,
            "CPT_Code": "C83.99",
            "CPT_Description": "Non-follicular (diffuse) lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbf",
            "Date_offered": "09/15/2020",
            "Total_funded": 10400,
            "CPT_Code": "C83.99",
            "CPT_Description": "Non-follicular (diffuse) lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbf",
            "Date_offered": "09/15/2020",
            "Total_funded": 10400,
            "CPT_Code": "C83.99",
            "CPT_Description": "Non-follicular (diffuse) lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbf",
            "Date_offered": "09/15/2020",
            "Total_funded": 10400,
            "CPT_Code": "C83.99",
            "CPT_Description": "Non-follicular (diffuse) lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd2",
            "Date_offered": "08/27/2020",
            "Total_funded": 12300,
            "CPT_Code": "S72.002S",
            "CPT_Description": "Fracture of unspecified part of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd2",
            "Date_offered": "08/27/2020",
            "Total_funded": 12300,
            "CPT_Code": "S72.002S",
            "CPT_Description": "Fracture of unspecified part of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd8",
            "Date_offered": "08/21/2020",
            "Total_funded": 12900,
            "CPT_Code": "S72.042S",
            "CPT_Description": "Displaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd8",
            "Date_offered": "08/21/2020",
            "Total_funded": 12900,
            "CPT_Code": "S72.042S",
            "CPT_Description": "Displaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd8",
            "Date_offered": "08/21/2020",
            "Total_funded": 12900,
            "CPT_Code": "S72.042S",
            "CPT_Description": "Displaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce3",
            "Date_offered": "08/10/2020",
            "Total_funded": 14000,
            "CPT_Code": "S72.132S",
            "CPT_Description": "Displaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce3",
            "Date_offered": "08/10/2020",
            "Total_funded": 14000,
            "CPT_Code": "S72.132S",
            "CPT_Description": "Displaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce3",
            "Date_offered": "08/10/2020",
            "Total_funded": 14000,
            "CPT_Code": "S72.132S",
            "CPT_Description": "Displaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce3",
            "Date_offered": "08/10/2020",
            "Total_funded": 14000,
            "CPT_Code": "S72.132S",
            "CPT_Description": "Displaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce8",
            "Date_offered": "08/05/2020",
            "Total_funded": 14500,
            "CPT_Code": "S72.25XS",
            "CPT_Description": "Nondisplaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce8",
            "Date_offered": "08/05/2020",
            "Total_funded": 14500,
            "CPT_Code": "S72.25XS",
            "CPT_Description": "Nondisplaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce8",
            "Date_offered": "08/05/2020",
            "Total_funded": 14500,
            "CPT_Code": "S72.25XS",
            "CPT_Description": "Nondisplaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce8",
            "Date_offered": "08/05/2020",
            "Total_funded": 14500,
            "CPT_Code": "S72.25XS",
            "CPT_Description": "Nondisplaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce9",
            "Date_offered": "08/04/2020",
            "Total_funded": 14600,
            "CPT_Code": "S72.302S",
            "CPT_Description": "Unspecified fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce9",
            "Date_offered": "08/04/2020",
            "Total_funded": 14600,
            "CPT_Code": "S72.302S",
            "CPT_Description": "Unspecified fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1d",
            "Date_offered": "06/13/2020",
            "Total_funded": 19800,
            "CPT_Code": "S82.125S",
            "CPT_Description": "Nondisplaced fracture of lateral condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1d",
            "Date_offered": "06/13/2020",
            "Total_funded": 19800,
            "CPT_Code": "S82.125S",
            "CPT_Description": "Nondisplaced fracture of lateral condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1d",
            "Date_offered": "06/13/2020",
            "Total_funded": 19800,
            "CPT_Code": "S82.125S",
            "CPT_Description": "Nondisplaced fracture of lateral condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd25",
            "Date_offered": "06/05/2020",
            "Total_funded": 20600,
            "CPT_Code": "S82.192S",
            "CPT_Description": "Other fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd25",
            "Date_offered": "06/05/2020",
            "Total_funded": 20600,
            "CPT_Code": "S82.192S",
            "CPT_Description": "Other fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd33",
            "Date_offered": "05/22/2020",
            "Total_funded": 22000,
            "CPT_Code": "S82.312S",
            "CPT_Description": "Torus fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd33",
            "Date_offered": "05/22/2020",
            "Total_funded": 22000,
            "CPT_Code": "S82.312S",
            "CPT_Description": "Torus fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd33",
            "Date_offered": "05/22/2020",
            "Total_funded": 22000,
            "CPT_Code": "S82.312S",
            "CPT_Description": "Torus fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3b",
            "Date_offered": "05/14/2020",
            "Total_funded": 22800,
            "CPT_Code": "S82.445S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3b",
            "Date_offered": "05/14/2020",
            "Total_funded": 22800,
            "CPT_Code": "S82.445S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3b",
            "Date_offered": "05/14/2020",
            "Total_funded": 22800,
            "CPT_Code": "S82.445S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd42",
            "Date_offered": "05/07/2020",
            "Total_funded": 23500,
            "CPT_Code": "S82.55XS",
            "CPT_Description": "Nondisplaced fracture of medial malleolus of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd42",
            "Date_offered": "05/07/2020",
            "Total_funded": 23500,
            "CPT_Code": "S82.55XS",
            "CPT_Description": "Nondisplaced fracture of medial malleolus of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd42",
            "Date_offered": "05/07/2020",
            "Total_funded": 23500,
            "CPT_Code": "S82.55XS",
            "CPT_Description": "Nondisplaced fracture of medial malleolus of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd46",
            "Date_offered": "05/03/2020",
            "Total_funded": 23900,
            "CPT_Code": "S82.822S",
            "CPT_Description": "Torus fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd46",
            "Date_offered": "05/03/2020",
            "Total_funded": 23900,
            "CPT_Code": "S82.822S",
            "CPT_Description": "Torus fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd46",
            "Date_offered": "05/03/2020",
            "Total_funded": 23900,
            "CPT_Code": "S82.822S",
            "CPT_Description": "Torus fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd57",
            "Date_offered": "04/16/2020",
            "Total_funded": 25600,
            "CPT_Code": "S83.095S",
            "CPT_Description": "Other dislocation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd57",
            "Date_offered": "04/16/2020",
            "Total_funded": 25600,
            "CPT_Code": "S83.095S",
            "CPT_Description": "Other dislocation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd57",
            "Date_offered": "04/16/2020",
            "Total_funded": 25600,
            "CPT_Code": "S83.095S",
            "CPT_Description": "Other dislocation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd67",
            "Date_offered": "03/31/2020",
            "Total_funded": 27200,
            "CPT_Code": "S83.212S",
            "CPT_Description": "Bucket-handle tear of medial meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd67",
            "Date_offered": "03/31/2020",
            "Total_funded": 27200,
            "CPT_Code": "S83.212S",
            "CPT_Description": "Bucket-handle tear of medial meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd67",
            "Date_offered": "03/31/2020",
            "Total_funded": 27200,
            "CPT_Code": "S83.212S",
            "CPT_Description": "Bucket-handle tear of medial meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd76",
            "Date_offered": "03/16/2020",
            "Total_funded": 28700,
            "CPT_Code": "S83.8X2S",
            "CPT_Description": "Sprain of other specified parts of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd76",
            "Date_offered": "03/16/2020",
            "Total_funded": 28700,
            "CPT_Code": "S83.8X2S",
            "CPT_Description": "Sprain of other specified parts of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd76",
            "Date_offered": "03/16/2020",
            "Total_funded": 28700,
            "CPT_Code": "S83.8X2S",
            "CPT_Description": "Sprain of other specified parts of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd76",
            "Date_offered": "03/16/2020",
            "Total_funded": 28700,
            "CPT_Code": "S83.8X2S",
            "CPT_Description": "Sprain of other specified parts of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7d",
            "Date_offered": "03/09/2020",
            "Total_funded": 29400,
            "CPT_Code": "S89.022S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7d",
            "Date_offered": "03/09/2020",
            "Total_funded": 29400,
            "CPT_Code": "S89.022S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7d",
            "Date_offered": "03/09/2020",
            "Total_funded": 29400,
            "CPT_Code": "S89.022S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd86",
            "Date_offered": "02/29/2020",
            "Total_funded": 30300,
            "CPT_Code": "S89.192S",
            "CPT_Description": "Other physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd86",
            "Date_offered": "02/29/2020",
            "Total_funded": 30300,
            "CPT_Code": "S89.192S",
            "CPT_Description": "Other physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd88",
            "Date_offered": "02/27/2020",
            "Total_funded": 30500,
            "CPT_Code": "S89.212S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd88",
            "Date_offered": "02/27/2020",
            "Total_funded": 30500,
            "CPT_Code": "S89.212S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd88",
            "Date_offered": "02/27/2020",
            "Total_funded": 30500,
            "CPT_Code": "S89.212S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd89",
            "Date_offered": "02/26/2020",
            "Total_funded": 30600,
            "CPT_Code": "S89.222S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd89",
            "Date_offered": "02/26/2020",
            "Total_funded": 30600,
            "CPT_Code": "S89.222S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd89",
            "Date_offered": "02/26/2020",
            "Total_funded": 30600,
            "CPT_Code": "S89.222S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8b",
            "Date_offered": "02/24/2020",
            "Total_funded": 30800,
            "CPT_Code": "S89.302S",
            "CPT_Description": "Unspecified physeal fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8b",
            "Date_offered": "02/24/2020",
            "Total_funded": 30800,
            "CPT_Code": "S89.302S",
            "CPT_Description": "Unspecified physeal fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd93",
            "Date_offered": "02/16/2020",
            "Total_funded": 31600,
            "CPT_Code": "T84.063S",
            "CPT_Description": "Wear of articular bearing surface of internal prosthetic left knee joint, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd93",
            "Date_offered": "02/16/2020",
            "Total_funded": 31600,
            "CPT_Code": "T84.063S",
            "CPT_Description": "Wear of articular bearing surface of internal prosthetic left knee joint, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd93",
            "Date_offered": "02/16/2020",
            "Total_funded": 31600,
            "CPT_Code": "T84.063S",
            "CPT_Description": "Wear of articular bearing surface of internal prosthetic left knee joint, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd98",
            "Date_offered": "02/11/2020",
            "Total_funded": 32100,
            "CPT_Code": "T84.410S",
            "CPT_Description": "Breakdown (mechanical) of muscle and tendon graft, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd98",
            "Date_offered": "02/11/2020",
            "Total_funded": 32100,
            "CPT_Code": "T84.410S",
            "CPT_Description": "Breakdown (mechanical) of muscle and tendon graft, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda1",
            "Date_offered": "02/02/2020",
            "Total_funded": 33000,
            "CPT_Code": "M80.062S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda1",
            "Date_offered": "02/02/2020",
            "Total_funded": 33000,
            "CPT_Code": "M80.062S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda1",
            "Date_offered": "02/02/2020",
            "Total_funded": 33000,
            "CPT_Code": "M80.062S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda1",
            "Date_offered": "02/02/2020",
            "Total_funded": 33000,
            "CPT_Code": "M80.062S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb5",
            "Date_offered": "01/13/2020",
            "Total_funded": 35000,
            "CPT_Code": "S72.062S",
            "CPT_Description": "Displaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb5",
            "Date_offered": "01/13/2020",
            "Total_funded": 35000,
            "CPT_Code": "S72.062S",
            "CPT_Description": "Displaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb5",
            "Date_offered": "01/13/2020",
            "Total_funded": 35000,
            "CPT_Code": "S72.062S",
            "CPT_Description": "Displaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbb",
            "Date_offered": "01/07/2020",
            "Total_funded": 35600,
            "CPT_Code": "S72.122S",
            "CPT_Description": "Displaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbb",
            "Date_offered": "01/07/2020",
            "Total_funded": 35600,
            "CPT_Code": "S72.122S",
            "CPT_Description": "Displaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbb",
            "Date_offered": "01/07/2020",
            "Total_funded": 35600,
            "CPT_Code": "S72.122S",
            "CPT_Description": "Displaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbb",
            "Date_offered": "01/07/2020",
            "Total_funded": 35600,
            "CPT_Code": "S72.122S",
            "CPT_Description": "Displaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbb",
            "Date_offered": "09/19/2020",
            "Total_funded": 10000,
            "CPT_Code": "C83.19",
            "CPT_Description": "Mantle cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbb",
            "Date_offered": "09/19/2020",
            "Total_funded": 10000,
            "CPT_Code": "C83.19",
            "CPT_Description": "Mantle cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbb",
            "Date_offered": "09/19/2020",
            "Total_funded": 10000,
            "CPT_Code": "C83.19",
            "CPT_Description": "Mantle cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbb",
            "Date_offered": "09/19/2020",
            "Total_funded": 10000,
            "CPT_Code": "C83.19",
            "CPT_Description": "Mantle cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbb",
            "Date_offered": "09/19/2020",
            "Total_funded": 10000,
            "CPT_Code": "C83.19",
            "CPT_Description": "Mantle cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd5",
            "Date_offered": "08/24/2020",
            "Total_funded": 12600,
            "CPT_Code": "S72.025S",
            "CPT_Description": "Nondisplaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd5",
            "Date_offered": "08/24/2020",
            "Total_funded": 12600,
            "CPT_Code": "S72.025S",
            "CPT_Description": "Nondisplaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd5",
            "Date_offered": "08/24/2020",
            "Total_funded": 12600,
            "CPT_Code": "S72.025S",
            "CPT_Description": "Nondisplaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdc",
            "Date_offered": "08/17/2020",
            "Total_funded": 13300,
            "CPT_Code": "S72.065S",
            "CPT_Description": "Nondisplaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdc",
            "Date_offered": "08/17/2020",
            "Total_funded": 13300,
            "CPT_Code": "S72.065S",
            "CPT_Description": "Nondisplaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdc",
            "Date_offered": "08/17/2020",
            "Total_funded": 13300,
            "CPT_Code": "S72.065S",
            "CPT_Description": "Nondisplaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcee",
            "Date_offered": "07/30/2020",
            "Total_funded": 15100,
            "CPT_Code": "S72.342S",
            "CPT_Description": "Displaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcee",
            "Date_offered": "07/30/2020",
            "Total_funded": 15100,
            "CPT_Code": "S72.342S",
            "CPT_Description": "Displaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcee",
            "Date_offered": "07/30/2020",
            "Total_funded": 15100,
            "CPT_Code": "S72.342S",
            "CPT_Description": "Displaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2f",
            "Date_offered": "05/26/2020",
            "Total_funded": 21600,
            "CPT_Code": "S82.262S",
            "CPT_Description": "Displaced segmental fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2f",
            "Date_offered": "05/26/2020",
            "Total_funded": 21600,
            "CPT_Code": "S82.262S",
            "CPT_Description": "Displaced segmental fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2f",
            "Date_offered": "05/26/2020",
            "Total_funded": 21600,
            "CPT_Code": "S82.262S",
            "CPT_Description": "Displaced segmental fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd60",
            "Date_offered": "04/07/2020",
            "Total_funded": 26500,
            "CPT_Code": "S83.142S",
            "CPT_Description": "Lateral subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd60",
            "Date_offered": "04/07/2020",
            "Total_funded": 26500,
            "CPT_Code": "S83.142S",
            "CPT_Description": "Lateral subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd60",
            "Date_offered": "04/07/2020",
            "Total_funded": 26500,
            "CPT_Code": "S83.142S",
            "CPT_Description": "Lateral subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd63",
            "Date_offered": "04/04/2020",
            "Total_funded": 26800,
            "CPT_Code": "S83.195S",
            "CPT_Description": "Other dislocation of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd63",
            "Date_offered": "04/04/2020",
            "Total_funded": 26800,
            "CPT_Code": "S83.195S",
            "CPT_Description": "Other dislocation of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd63",
            "Date_offered": "04/04/2020",
            "Total_funded": 26800,
            "CPT_Code": "S83.195S",
            "CPT_Description": "Other dislocation of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd77",
            "Date_offered": "03/15/2020",
            "Total_funded": 28800,
            "CPT_Code": "S86.112S",
            "CPT_Description": "Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd77",
            "Date_offered": "03/15/2020",
            "Total_funded": 28800,
            "CPT_Code": "S86.112S",
            "CPT_Description": "Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd77",
            "Date_offered": "03/15/2020",
            "Total_funded": 28800,
            "CPT_Code": "S86.112S",
            "CPT_Description": "Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd77",
            "Date_offered": "03/15/2020",
            "Total_funded": 28800,
            "CPT_Code": "S86.112S",
            "CPT_Description": "Strain of other muscle(s) and tendon(s) of posterior muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd78",
            "Date_offered": "03/14/2020",
            "Total_funded": 28900,
            "CPT_Code": "S86.212S",
            "CPT_Description": "Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd78",
            "Date_offered": "03/14/2020",
            "Total_funded": 28900,
            "CPT_Code": "S86.212S",
            "CPT_Description": "Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd78",
            "Date_offered": "03/14/2020",
            "Total_funded": 28900,
            "CPT_Code": "S86.212S",
            "CPT_Description": "Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd78",
            "Date_offered": "03/14/2020",
            "Total_funded": 28900,
            "CPT_Code": "S86.212S",
            "CPT_Description": "Strain of muscle(s) and tendon(s) of anterior muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7e",
            "Date_offered": "03/08/2020",
            "Total_funded": 29500,
            "CPT_Code": "S89.032S",
            "CPT_Description": "Salter-Harris Type III physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7e",
            "Date_offered": "03/08/2020",
            "Total_funded": 29500,
            "CPT_Code": "S89.032S",
            "CPT_Description": "Salter-Harris Type III physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7e",
            "Date_offered": "03/08/2020",
            "Total_funded": 29500,
            "CPT_Code": "S89.032S",
            "CPT_Description": "Salter-Harris Type III physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd83",
            "Date_offered": "03/03/2020",
            "Total_funded": 30000,
            "CPT_Code": "S89.122S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd83",
            "Date_offered": "03/03/2020",
            "Total_funded": 30000,
            "CPT_Code": "S89.122S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd83",
            "Date_offered": "03/03/2020",
            "Total_funded": 30000,
            "CPT_Code": "S89.122S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd94",
            "Date_offered": "02/15/2020",
            "Total_funded": 31700,
            "CPT_Code": "T84.093S",
            "CPT_Description": "Other mechanical complication of internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd94",
            "Date_offered": "02/15/2020",
            "Total_funded": 31700,
            "CPT_Code": "T84.093S",
            "CPT_Description": "Other mechanical complication of internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd94",
            "Date_offered": "02/15/2020",
            "Total_funded": 31700,
            "CPT_Code": "T84.093S",
            "CPT_Description": "Other mechanical complication of internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9a",
            "Date_offered": "02/09/2020",
            "Total_funded": 32300,
            "CPT_Code": "T84.498S",
            "CPT_Description": "Other mechanical complication of other internal orthopedic devices, implants and grafts, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9a",
            "Date_offered": "02/09/2020",
            "Total_funded": 32300,
            "CPT_Code": "T84.498S",
            "CPT_Description": "Other mechanical complication of other internal orthopedic devices, implants and grafts, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9a",
            "Date_offered": "02/09/2020",
            "Total_funded": 32300,
            "CPT_Code": "T84.498S",
            "CPT_Description": "Other mechanical complication of other internal orthopedic devices, implants and grafts, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9a",
            "Date_offered": "02/09/2020",
            "Total_funded": 32300,
            "CPT_Code": "T84.498S",
            "CPT_Description": "Other mechanical complication of other internal orthopedic devices, implants and grafts, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc1",
            "Date_offered": "09/13/2020",
            "Total_funded": 10600,
            "CPT_Code": "C85.89",
            "CPT_Description": "Other specified types of non-Hodgkin lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc1",
            "Date_offered": "09/13/2020",
            "Total_funded": 10600,
            "CPT_Code": "C85.89",
            "CPT_Description": "Other specified types of non-Hodgkin lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc1",
            "Date_offered": "09/13/2020",
            "Total_funded": 10600,
            "CPT_Code": "C85.89",
            "CPT_Description": "Other specified types of non-Hodgkin lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc1",
            "Date_offered": "09/13/2020",
            "Total_funded": 10600,
            "CPT_Code": "C85.89",
            "CPT_Description": "Other specified types of non-Hodgkin lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc1",
            "Date_offered": "09/13/2020",
            "Total_funded": 10600,
            "CPT_Code": "C85.89",
            "CPT_Description": "Other specified types of non-Hodgkin lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc1",
            "Date_offered": "09/13/2020",
            "Total_funded": 10600,
            "CPT_Code": "C85.89",
            "CPT_Description": "Other specified types of non-Hodgkin lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcca",
            "Date_offered": "09/04/2020",
            "Total_funded": 11500,
            "CPT_Code": "M84.352S",
            "CPT_Description": "Stress fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcca",
            "Date_offered": "09/04/2020",
            "Total_funded": 11500,
            "CPT_Code": "M84.352S",
            "CPT_Description": "Stress fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcca",
            "Date_offered": "09/04/2020",
            "Total_funded": 11500,
            "CPT_Code": "M84.352S",
            "CPT_Description": "Stress fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dccb",
            "Date_offered": "09/03/2020",
            "Total_funded": 11600,
            "CPT_Code": "M84.364S",
            "CPT_Description": "Stress fracture, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dccb",
            "Date_offered": "09/03/2020",
            "Total_funded": 11600,
            "CPT_Code": "M84.364S",
            "CPT_Description": "Stress fracture, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd0",
            "Date_offered": "08/29/2020",
            "Total_funded": 12100,
            "CPT_Code": "M84.652S",
            "CPT_Description": "Pathological fracture in other disease, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd0",
            "Date_offered": "08/29/2020",
            "Total_funded": 12100,
            "CPT_Code": "M84.652S",
            "CPT_Description": "Pathological fracture in other disease, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd02",
            "Date_offered": "07/10/2020",
            "Total_funded": 17100,
            "CPT_Code": "S72.472S",
            "CPT_Description": "Torus fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd02",
            "Date_offered": "07/10/2020",
            "Total_funded": 17100,
            "CPT_Code": "S72.472S",
            "CPT_Description": "Torus fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd02",
            "Date_offered": "07/10/2020",
            "Total_funded": 17100,
            "CPT_Code": "S72.472S",
            "CPT_Description": "Torus fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd04",
            "Date_offered": "07/08/2020",
            "Total_funded": 17300,
            "CPT_Code": "S72.8X2S",
            "CPT_Description": "Other fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd04",
            "Date_offered": "07/08/2020",
            "Total_funded": 17300,
            "CPT_Code": "S72.8X2S",
            "CPT_Description": "Other fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd10",
            "Date_offered": "06/26/2020",
            "Total_funded": 18500,
            "CPT_Code": "S82.012S",
            "CPT_Description": "Displaced osteochondral fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd10",
            "Date_offered": "06/26/2020",
            "Total_funded": 18500,
            "CPT_Code": "S82.012S",
            "CPT_Description": "Displaced osteochondral fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd10",
            "Date_offered": "06/26/2020",
            "Total_funded": 18500,
            "CPT_Code": "S82.012S",
            "CPT_Description": "Displaced osteochondral fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd16",
            "Date_offered": "06/20/2020",
            "Total_funded": 19100,
            "CPT_Code": "S82.042S",
            "CPT_Description": "Displaced comminuted fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd16",
            "Date_offered": "06/20/2020",
            "Total_funded": 19100,
            "CPT_Code": "S82.042S",
            "CPT_Description": "Displaced comminuted fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd16",
            "Date_offered": "06/20/2020",
            "Total_funded": 19100,
            "CPT_Code": "S82.042S",
            "CPT_Description": "Displaced comminuted fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1f",
            "Date_offered": "06/11/2020",
            "Total_funded": 20000,
            "CPT_Code": "S82.135S",
            "CPT_Description": "Nondisplaced fracture of medial condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1f",
            "Date_offered": "06/11/2020",
            "Total_funded": 20000,
            "CPT_Code": "S82.135S",
            "CPT_Description": "Nondisplaced fracture of medial condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1f",
            "Date_offered": "06/11/2020",
            "Total_funded": 20000,
            "CPT_Code": "S82.135S",
            "CPT_Description": "Nondisplaced fracture of medial condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6b",
            "Date_offered": "03/27/2020",
            "Total_funded": 27600,
            "CPT_Code": "S83.252S",
            "CPT_Description": "Bucket-handle tear of lateral meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6b",
            "Date_offered": "03/27/2020",
            "Total_funded": 27600,
            "CPT_Code": "S83.252S",
            "CPT_Description": "Bucket-handle tear of lateral meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6b",
            "Date_offered": "03/27/2020",
            "Total_funded": 27600,
            "CPT_Code": "S83.252S",
            "CPT_Description": "Bucket-handle tear of lateral meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7a",
            "Date_offered": "03/12/2020",
            "Total_funded": 29100,
            "CPT_Code": "S86.812S",
            "CPT_Description": "Strain of other muscle(s) and tendon(s) at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7a",
            "Date_offered": "03/12/2020",
            "Total_funded": 29100,
            "CPT_Code": "S86.812S",
            "CPT_Description": "Strain of other muscle(s) and tendon(s) at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7a",
            "Date_offered": "03/12/2020",
            "Total_funded": 29100,
            "CPT_Code": "S86.812S",
            "CPT_Description": "Strain of other muscle(s) and tendon(s) at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7a",
            "Date_offered": "03/12/2020",
            "Total_funded": 29100,
            "CPT_Code": "S86.812S",
            "CPT_Description": "Strain of other muscle(s) and tendon(s) at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd95",
            "Date_offered": "02/14/2020",
            "Total_funded": 31800,
            "CPT_Code": "T84.117S",
            "CPT_Description": "Breakdown (mechanical) of internal fixation device of bone of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd95",
            "Date_offered": "02/14/2020",
            "Total_funded": 31800,
            "CPT_Code": "T84.117S",
            "CPT_Description": "Breakdown (mechanical) of internal fixation device of bone of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddac",
            "Date_offered": "01/22/2020",
            "Total_funded": 34100,
            "CPT_Code": "S72.002S",
            "CPT_Description": "Fracture of unspecified part of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddac",
            "Date_offered": "01/22/2020",
            "Total_funded": 34100,
            "CPT_Code": "S72.002S",
            "CPT_Description": "Fracture of unspecified part of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb8",
            "Date_offered": "01/10/2020",
            "Total_funded": 35300,
            "CPT_Code": "S72.102S",
            "CPT_Description": "Unspecified trochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb8",
            "Date_offered": "01/10/2020",
            "Total_funded": 35300,
            "CPT_Code": "S72.102S",
            "CPT_Description": "Unspecified trochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb8",
            "Date_offered": "01/10/2020",
            "Total_funded": 35300,
            "CPT_Code": "S72.102S",
            "CPT_Description": "Unspecified trochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc5",
            "Date_offered": "12/28/2019",
            "Total_funded": 36600,
            "CPT_Code": "S72.325S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc5",
            "Date_offered": "12/28/2019",
            "Total_funded": 36600,
            "CPT_Code": "S72.325S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc5",
            "Date_offered": "12/28/2019",
            "Total_funded": 36600,
            "CPT_Code": "S72.325S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc6",
            "Date_offered": "12/27/2019",
            "Total_funded": 36700,
            "CPT_Code": "S72.332S",
            "CPT_Description": "Displaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc6",
            "Date_offered": "12/27/2019",
            "Total_funded": 36700,
            "CPT_Code": "S72.332S",
            "CPT_Description": "Displaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc6",
            "Date_offered": "12/27/2019",
            "Total_funded": 36700,
            "CPT_Code": "S72.332S",
            "CPT_Description": "Displaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddcd",
            "Date_offered": "12/20/2019",
            "Total_funded": 37400,
            "CPT_Code": "S72.365S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddcd",
            "Date_offered": "12/20/2019",
            "Total_funded": 37400,
            "CPT_Code": "S72.365S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddcd",
            "Date_offered": "12/20/2019",
            "Total_funded": 37400,
            "CPT_Code": "S72.365S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd8",
            "Date_offered": "12/09/2019",
            "Total_funded": 38500,
            "CPT_Code": "S72.452S",
            "CPT_Description": "Displaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd8",
            "Date_offered": "12/09/2019",
            "Total_funded": 38500,
            "CPT_Code": "S72.452S",
            "CPT_Description": "Displaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd8",
            "Date_offered": "12/09/2019",
            "Total_funded": 38500,
            "CPT_Code": "S72.452S",
            "CPT_Description": "Displaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd9",
            "Date_offered": "08/20/2020",
            "Total_funded": 13000,
            "CPT_Code": "S72.045S",
            "CPT_Description": "Nondisplaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd9",
            "Date_offered": "08/20/2020",
            "Total_funded": 13000,
            "CPT_Code": "S72.045S",
            "CPT_Description": "Nondisplaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd9",
            "Date_offered": "08/20/2020",
            "Total_funded": 13000,
            "CPT_Code": "S72.045S",
            "CPT_Description": "Nondisplaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce4",
            "Date_offered": "08/09/2020",
            "Total_funded": 14100,
            "CPT_Code": "S72.135S",
            "CPT_Description": "Nondisplaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce4",
            "Date_offered": "08/09/2020",
            "Total_funded": 14100,
            "CPT_Code": "S72.135S",
            "CPT_Description": "Nondisplaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce4",
            "Date_offered": "08/09/2020",
            "Total_funded": 14100,
            "CPT_Code": "S72.135S",
            "CPT_Description": "Nondisplaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce4",
            "Date_offered": "08/09/2020",
            "Total_funded": 14100,
            "CPT_Code": "S72.135S",
            "CPT_Description": "Nondisplaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf0",
            "Date_offered": "07/28/2020",
            "Total_funded": 15300,
            "CPT_Code": "S72.352S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf0",
            "Date_offered": "07/28/2020",
            "Total_funded": 15300,
            "CPT_Code": "S72.352S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf0",
            "Date_offered": "07/28/2020",
            "Total_funded": 15300,
            "CPT_Code": "S72.352S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfb",
            "Date_offered": "07/17/2020",
            "Total_funded": 16400,
            "CPT_Code": "S72.435S",
            "CPT_Description": "Nondisplaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfb",
            "Date_offered": "07/17/2020",
            "Total_funded": 16400,
            "CPT_Code": "S72.435S",
            "CPT_Description": "Nondisplaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfb",
            "Date_offered": "07/17/2020",
            "Total_funded": 16400,
            "CPT_Code": "S72.435S",
            "CPT_Description": "Nondisplaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0c",
            "Date_offered": "06/30/2020",
            "Total_funded": 18100,
            "CPT_Code": "S79.132S",
            "CPT_Description": "Salter-Harris Type III physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0c",
            "Date_offered": "06/30/2020",
            "Total_funded": 18100,
            "CPT_Code": "S79.132S",
            "CPT_Description": "Salter-Harris Type III physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0c",
            "Date_offered": "06/30/2020",
            "Total_funded": 18100,
            "CPT_Code": "S79.132S",
            "CPT_Description": "Salter-Harris Type III physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd11",
            "Date_offered": "06/25/2020",
            "Total_funded": 18600,
            "CPT_Code": "S82.015S",
            "CPT_Description": "Nondisplaced osteochondral fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd11",
            "Date_offered": "06/25/2020",
            "Total_funded": 18600,
            "CPT_Code": "S82.015S",
            "CPT_Description": "Nondisplaced osteochondral fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd11",
            "Date_offered": "06/25/2020",
            "Total_funded": 18600,
            "CPT_Code": "S82.015S",
            "CPT_Description": "Nondisplaced osteochondral fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd15",
            "Date_offered": "06/21/2020",
            "Total_funded": 19000,
            "CPT_Code": "S82.035S",
            "CPT_Description": "Nondisplaced transverse fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd15",
            "Date_offered": "06/21/2020",
            "Total_funded": 19000,
            "CPT_Code": "S82.035S",
            "CPT_Description": "Nondisplaced transverse fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd15",
            "Date_offered": "06/21/2020",
            "Total_funded": 19000,
            "CPT_Code": "S82.035S",
            "CPT_Description": "Nondisplaced transverse fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd36",
            "Date_offered": "05/19/2020",
            "Total_funded": 22300,
            "CPT_Code": "S82.422S",
            "CPT_Description": "Displaced transverse fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd36",
            "Date_offered": "05/19/2020",
            "Total_funded": 22300,
            "CPT_Code": "S82.422S",
            "CPT_Description": "Displaced transverse fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd36",
            "Date_offered": "05/19/2020",
            "Total_funded": 22300,
            "CPT_Code": "S82.422S",
            "CPT_Description": "Displaced transverse fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd47",
            "Date_offered": "05/02/2020",
            "Total_funded": 24000,
            "CPT_Code": "S82.832S",
            "CPT_Description": "Other fracture of upper and lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd47",
            "Date_offered": "05/02/2020",
            "Total_funded": 24000,
            "CPT_Code": "S82.832S",
            "CPT_Description": "Other fracture of upper and lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4c",
            "Date_offered": "04/27/2020",
            "Total_funded": 24500,
            "CPT_Code": "S82.862S",
            "CPT_Description": "Displaced Maisonneuve's fracture of left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4c",
            "Date_offered": "04/27/2020",
            "Total_funded": 24500,
            "CPT_Code": "S82.862S",
            "CPT_Description": "Displaced Maisonneuve's fracture of left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4c",
            "Date_offered": "04/27/2020",
            "Total_funded": 24500,
            "CPT_Code": "S82.862S",
            "CPT_Description": "Displaced Maisonneuve's fracture of left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5c",
            "Date_offered": "04/11/2020",
            "Total_funded": 26100,
            "CPT_Code": "S83.122S",
            "CPT_Description": "Posterior subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5c",
            "Date_offered": "04/11/2020",
            "Total_funded": 26100,
            "CPT_Code": "S83.122S",
            "CPT_Description": "Posterior subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5c",
            "Date_offered": "04/11/2020",
            "Total_funded": 26100,
            "CPT_Code": "S83.122S",
            "CPT_Description": "Posterior subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd62",
            "Date_offered": "04/05/2020",
            "Total_funded": 26700,
            "CPT_Code": "S83.192S",
            "CPT_Description": "Other subluxation of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd62",
            "Date_offered": "04/05/2020",
            "Total_funded": 26700,
            "CPT_Code": "S83.192S",
            "CPT_Description": "Other subluxation of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd62",
            "Date_offered": "04/05/2020",
            "Total_funded": 26700,
            "CPT_Code": "S83.192S",
            "CPT_Description": "Other subluxation of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6f",
            "Date_offered": "03/23/2020",
            "Total_funded": 28000,
            "CPT_Code": "S83.32XS",
            "CPT_Description": "Tear of articular cartilage of left knee, current, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6f",
            "Date_offered": "03/23/2020",
            "Total_funded": 28000,
            "CPT_Code": "S83.32XS",
            "CPT_Description": "Tear of articular cartilage of left knee, current, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6f",
            "Date_offered": "03/23/2020",
            "Total_funded": 28000,
            "CPT_Code": "S83.32XS",
            "CPT_Description": "Tear of articular cartilage of left knee, current, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd79",
            "Date_offered": "03/13/2020",
            "Total_funded": 29000,
            "CPT_Code": "S86.312S",
            "CPT_Description": "Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd79",
            "Date_offered": "03/13/2020",
            "Total_funded": 29000,
            "CPT_Code": "S86.312S",
            "CPT_Description": "Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd79",
            "Date_offered": "03/13/2020",
            "Total_funded": 29000,
            "CPT_Code": "S86.312S",
            "CPT_Description": "Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd79",
            "Date_offered": "03/13/2020",
            "Total_funded": 29000,
            "CPT_Code": "S86.312S",
            "CPT_Description": "Strain of muscle(s) and tendon(s) of peroneal muscle group at lower leg level, left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddaa",
            "Date_offered": "01/24/2020",
            "Total_funded": 33900,
            "CPT_Code": "M84.652S",
            "CPT_Description": "Pathological fracture in other disease, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddaa",
            "Date_offered": "01/24/2020",
            "Total_funded": 33900,
            "CPT_Code": "M84.652S",
            "CPT_Description": "Pathological fracture in other disease, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb9",
            "Date_offered": "01/09/2020",
            "Total_funded": 35400,
            "CPT_Code": "S72.112S",
            "CPT_Description": "Displaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb9",
            "Date_offered": "01/09/2020",
            "Total_funded": 35400,
            "CPT_Code": "S72.112S",
            "CPT_Description": "Displaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb9",
            "Date_offered": "01/09/2020",
            "Total_funded": 35400,
            "CPT_Code": "S72.112S",
            "CPT_Description": "Displaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb9",
            "Date_offered": "01/09/2020",
            "Total_funded": 35400,
            "CPT_Code": "S72.112S",
            "CPT_Description": "Displaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dddc",
            "Date_offered": "12/05/2019",
            "Total_funded": 38900,
            "CPT_Code": "S72.472S",
            "CPT_Description": "Torus fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dddc",
            "Date_offered": "12/05/2019",
            "Total_funded": 38900,
            "CPT_Code": "S72.472S",
            "CPT_Description": "Torus fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dddc",
            "Date_offered": "12/05/2019",
            "Total_funded": 38900,
            "CPT_Code": "S72.472S",
            "CPT_Description": "Torus fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dddd",
            "Date_offered": "12/04/2019",
            "Total_funded": 39000,
            "CPT_Code": "S72.492S",
            "CPT_Description": "Other fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dddd",
            "Date_offered": "12/04/2019",
            "Total_funded": 39000,
            "CPT_Code": "S72.492S",
            "CPT_Description": "Other fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb8",
            "Date_offered": "09/22/2020",
            "Total_funded": 9700,
            "CPT_Code": "C82.89",
            "CPT_Description": "Other types of follicular lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb8",
            "Date_offered": "09/22/2020",
            "Total_funded": 9700,
            "CPT_Code": "C82.89",
            "CPT_Description": "Other types of follicular lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb8",
            "Date_offered": "09/22/2020",
            "Total_funded": 9700,
            "CPT_Code": "C82.89",
            "CPT_Description": "Other types of follicular lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb8",
            "Date_offered": "09/22/2020",
            "Total_funded": 9700,
            "CPT_Code": "C82.89",
            "CPT_Description": "Other types of follicular lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb8",
            "Date_offered": "09/22/2020",
            "Total_funded": 9700,
            "CPT_Code": "C82.89",
            "CPT_Description": "Other types of follicular lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdb",
            "Date_offered": "08/18/2020",
            "Total_funded": 13200,
            "CPT_Code": "S72.062S",
            "CPT_Description": "Displaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdb",
            "Date_offered": "08/18/2020",
            "Total_funded": 13200,
            "CPT_Code": "S72.062S",
            "CPT_Description": "Displaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdb",
            "Date_offered": "08/18/2020",
            "Total_funded": 13200,
            "CPT_Code": "S72.062S",
            "CPT_Description": "Displaced articular fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbe",
            "Date_offered": "09/16/2020",
            "Total_funded": 10300,
            "CPT_Code": "C83.89",
            "CPT_Description": "Other non-follicular lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbe",
            "Date_offered": "09/16/2020",
            "Total_funded": 10300,
            "CPT_Code": "C83.89",
            "CPT_Description": "Other non-follicular lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbe",
            "Date_offered": "09/16/2020",
            "Total_funded": 10300,
            "CPT_Code": "C83.89",
            "CPT_Description": "Other non-follicular lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbe",
            "Date_offered": "09/16/2020",
            "Total_funded": 10300,
            "CPT_Code": "C83.89",
            "CPT_Description": "Other non-follicular lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbe",
            "Date_offered": "09/16/2020",
            "Total_funded": 10300,
            "CPT_Code": "C83.89",
            "CPT_Description": "Other non-follicular lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb7",
            "Date_offered": "09/23/2020",
            "Total_funded": 9600,
            "CPT_Code": "C82.69",
            "CPT_Description": "Cutaneous follicle center lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb7",
            "Date_offered": "09/23/2020",
            "Total_funded": 9600,
            "CPT_Code": "C82.69",
            "CPT_Description": "Cutaneous follicle center lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb7",
            "Date_offered": "09/23/2020",
            "Total_funded": 9600,
            "CPT_Code": "C82.69",
            "CPT_Description": "Cutaneous follicle center lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb7",
            "Date_offered": "09/23/2020",
            "Total_funded": 9600,
            "CPT_Code": "C82.69",
            "CPT_Description": "Cutaneous follicle center lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb7",
            "Date_offered": "09/23/2020",
            "Total_funded": 9600,
            "CPT_Code": "C82.69",
            "CPT_Description": "Cutaneous follicle center lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce5",
            "Date_offered": "08/08/2020",
            "Total_funded": 14200,
            "CPT_Code": "S72.142S",
            "CPT_Description": "Displaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce5",
            "Date_offered": "08/08/2020",
            "Total_funded": 14200,
            "CPT_Code": "S72.142S",
            "CPT_Description": "Displaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce5",
            "Date_offered": "08/08/2020",
            "Total_funded": 14200,
            "CPT_Code": "S72.142S",
            "CPT_Description": "Displaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce5",
            "Date_offered": "08/08/2020",
            "Total_funded": 14200,
            "CPT_Code": "S72.142S",
            "CPT_Description": "Displaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce7",
            "Date_offered": "08/06/2020",
            "Total_funded": 14400,
            "CPT_Code": "S72.22XS",
            "CPT_Description": "Displaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce7",
            "Date_offered": "08/06/2020",
            "Total_funded": 14400,
            "CPT_Code": "S72.22XS",
            "CPT_Description": "Displaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce7",
            "Date_offered": "08/06/2020",
            "Total_funded": 14400,
            "CPT_Code": "S72.22XS",
            "CPT_Description": "Displaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce7",
            "Date_offered": "08/06/2020",
            "Total_funded": 14400,
            "CPT_Code": "S72.22XS",
            "CPT_Description": "Displaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd01",
            "Date_offered": "07/11/2020",
            "Total_funded": 17000,
            "CPT_Code": "S72.465S",
            "CPT_Description": "Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd01",
            "Date_offered": "07/11/2020",
            "Total_funded": 17000,
            "CPT_Code": "S72.465S",
            "CPT_Description": "Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd01",
            "Date_offered": "07/11/2020",
            "Total_funded": 17000,
            "CPT_Code": "S72.465S",
            "CPT_Description": "Nondisplaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd13",
            "Date_offered": "06/23/2020",
            "Total_funded": 18800,
            "CPT_Code": "S82.025S",
            "CPT_Description": "Nondisplaced longitudinal fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd13",
            "Date_offered": "06/23/2020",
            "Total_funded": 18800,
            "CPT_Code": "S82.025S",
            "CPT_Description": "Nondisplaced longitudinal fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd13",
            "Date_offered": "06/23/2020",
            "Total_funded": 18800,
            "CPT_Code": "S82.025S",
            "CPT_Description": "Nondisplaced longitudinal fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd28",
            "Date_offered": "06/02/2020",
            "Total_funded": 20900,
            "CPT_Code": "S82.225S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd28",
            "Date_offered": "06/02/2020",
            "Total_funded": 20900,
            "CPT_Code": "S82.225S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd28",
            "Date_offered": "06/02/2020",
            "Total_funded": 20900,
            "CPT_Code": "S82.225S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd30",
            "Date_offered": "05/25/2020",
            "Total_funded": 21700,
            "CPT_Code": "S82.265S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd30",
            "Date_offered": "05/25/2020",
            "Total_funded": 21700,
            "CPT_Code": "S82.265S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd30",
            "Date_offered": "05/25/2020",
            "Total_funded": 21700,
            "CPT_Code": "S82.265S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd39",
            "Date_offered": "05/16/2020",
            "Total_funded": 22600,
            "CPT_Code": "S82.435S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd39",
            "Date_offered": "05/16/2020",
            "Total_funded": 22600,
            "CPT_Code": "S82.435S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd39",
            "Date_offered": "05/16/2020",
            "Total_funded": 22600,
            "CPT_Code": "S82.435S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd43",
            "Date_offered": "05/06/2020",
            "Total_funded": 23600,
            "CPT_Code": "S82.62XS",
            "CPT_Description": "Displaced fracture of lateral malleolus of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd43",
            "Date_offered": "05/06/2020",
            "Total_funded": 23600,
            "CPT_Code": "S82.62XS",
            "CPT_Description": "Displaced fracture of lateral malleolus of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd43",
            "Date_offered": "05/06/2020",
            "Total_funded": 23600,
            "CPT_Code": "S82.62XS",
            "CPT_Description": "Displaced fracture of lateral malleolus of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd50",
            "Date_offered": "04/23/2020",
            "Total_funded": 24900,
            "CPT_Code": "S82.892S",
            "CPT_Description": "Other fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd50",
            "Date_offered": "04/23/2020",
            "Total_funded": 24900,
            "CPT_Code": "S82.892S",
            "CPT_Description": "Other fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd52",
            "Date_offered": "04/21/2020",
            "Total_funded": 25100,
            "CPT_Code": "S83.002S",
            "CPT_Description": "Unspecified subluxation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd52",
            "Date_offered": "04/21/2020",
            "Total_funded": 25100,
            "CPT_Code": "S83.002S",
            "CPT_Description": "Unspecified subluxation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd58",
            "Date_offered": "04/15/2020",
            "Total_funded": 25700,
            "CPT_Code": "S83.102S",
            "CPT_Description": "Unspecified subluxation of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd58",
            "Date_offered": "04/15/2020",
            "Total_funded": 25700,
            "CPT_Code": "S83.102S",
            "CPT_Description": "Unspecified subluxation of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6a",
            "Date_offered": "03/28/2020",
            "Total_funded": 27500,
            "CPT_Code": "S83.242S",
            "CPT_Description": "Other tear of medial meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6a",
            "Date_offered": "03/28/2020",
            "Total_funded": 27500,
            "CPT_Code": "S83.242S",
            "CPT_Description": "Other tear of medial meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6a",
            "Date_offered": "03/28/2020",
            "Total_funded": 27500,
            "CPT_Code": "S83.242S",
            "CPT_Description": "Other tear of medial meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd72",
            "Date_offered": "03/20/2020",
            "Total_funded": 28300,
            "CPT_Code": "S83.422S",
            "CPT_Description": "Sprain of lateral collateral ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd72",
            "Date_offered": "03/20/2020",
            "Total_funded": 28300,
            "CPT_Code": "S83.422S",
            "CPT_Description": "Sprain of lateral collateral ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd72",
            "Date_offered": "03/20/2020",
            "Total_funded": 28300,
            "CPT_Code": "S83.422S",
            "CPT_Description": "Sprain of lateral collateral ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd72",
            "Date_offered": "03/20/2020",
            "Total_funded": 28300,
            "CPT_Code": "S83.422S",
            "CPT_Description": "Sprain of lateral collateral ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7c",
            "Date_offered": "03/10/2020",
            "Total_funded": 29300,
            "CPT_Code": "S89.012S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7c",
            "Date_offered": "03/10/2020",
            "Total_funded": 29300,
            "CPT_Code": "S89.012S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7c",
            "Date_offered": "03/10/2020",
            "Total_funded": 29300,
            "CPT_Code": "S89.012S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd85",
            "Date_offered": "03/01/2020",
            "Total_funded": 30200,
            "CPT_Code": "S89.142S",
            "CPT_Description": "Salter-Harris Type IV physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd85",
            "Date_offered": "03/01/2020",
            "Total_funded": 30200,
            "CPT_Code": "S89.142S",
            "CPT_Description": "Salter-Harris Type IV physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd85",
            "Date_offered": "03/01/2020",
            "Total_funded": 30200,
            "CPT_Code": "S89.142S",
            "CPT_Description": "Salter-Harris Type IV physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd92",
            "Date_offered": "02/17/2020",
            "Total_funded": 31500,
            "CPT_Code": "T84.053S",
            "CPT_Description": "Periprosthetic osteolysis of internal prosthetic left knee joint, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd92",
            "Date_offered": "02/17/2020",
            "Total_funded": 31500,
            "CPT_Code": "T84.053S",
            "CPT_Description": "Periprosthetic osteolysis of internal prosthetic left knee joint, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd92",
            "Date_offered": "02/17/2020",
            "Total_funded": 31500,
            "CPT_Code": "T84.053S",
            "CPT_Description": "Periprosthetic osteolysis of internal prosthetic left knee joint, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda0",
            "Date_offered": "02/03/2020",
            "Total_funded": 32900,
            "CPT_Code": "M80.052S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda0",
            "Date_offered": "02/03/2020",
            "Total_funded": 32900,
            "CPT_Code": "M80.052S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda0",
            "Date_offered": "02/03/2020",
            "Total_funded": 32900,
            "CPT_Code": "M80.052S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda0",
            "Date_offered": "02/03/2020",
            "Total_funded": 32900,
            "CPT_Code": "M80.052S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb4",
            "Date_offered": "01/14/2020",
            "Total_funded": 34900,
            "CPT_Code": "S72.052S",
            "CPT_Description": "Unspecified fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb4",
            "Date_offered": "01/14/2020",
            "Total_funded": 34900,
            "CPT_Code": "S72.052S",
            "CPT_Description": "Unspecified fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc9",
            "Date_offered": "12/24/2019",
            "Total_funded": 37000,
            "CPT_Code": "S72.345S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc9",
            "Date_offered": "12/24/2019",
            "Total_funded": 37000,
            "CPT_Code": "S72.345S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc9",
            "Date_offered": "12/24/2019",
            "Total_funded": 37000,
            "CPT_Code": "S72.345S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd0",
            "Date_offered": "12/17/2019",
            "Total_funded": 37700,
            "CPT_Code": "S72.412S",
            "CPT_Description": "Displaced unspecified condyle fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd0",
            "Date_offered": "12/17/2019",
            "Total_funded": 37700,
            "CPT_Code": "S72.412S",
            "CPT_Description": "Displaced unspecified condyle fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd1",
            "Date_offered": "12/16/2019",
            "Total_funded": 37800,
            "CPT_Code": "S72.415S",
            "CPT_Description": "Nondisplaced unspecified condyle fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd1",
            "Date_offered": "12/16/2019",
            "Total_funded": 37800,
            "CPT_Code": "S72.415S",
            "CPT_Description": "Nondisplaced unspecified condyle fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc4",
            "Date_offered": "09/10/2020",
            "Total_funded": 10900,
            "CPT_Code": "C57.22",
            "CPT_Description": "Malignant neoplasm of left round ligament"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc4",
            "Date_offered": "09/10/2020",
            "Total_funded": 10900,
            "CPT_Code": "C57.22",
            "CPT_Description": "Malignant neoplasm of left round ligament"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc4",
            "Date_offered": "09/10/2020",
            "Total_funded": 10900,
            "CPT_Code": "C57.22",
            "CPT_Description": "Malignant neoplasm of left round ligament"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc8",
            "Date_offered": "09/06/2020",
            "Total_funded": 11300,
            "CPT_Code": "M80.852S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc8",
            "Date_offered": "09/06/2020",
            "Total_funded": 11300,
            "CPT_Code": "M80.852S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc8",
            "Date_offered": "09/06/2020",
            "Total_funded": 11300,
            "CPT_Code": "M80.852S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc8",
            "Date_offered": "09/06/2020",
            "Total_funded": 11300,
            "CPT_Code": "M80.852S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dccc",
            "Date_offered": "09/02/2020",
            "Total_funded": 11700,
            "CPT_Code": "M84.452S",
            "CPT_Description": "Pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dccc",
            "Date_offered": "09/02/2020",
            "Total_funded": 11700,
            "CPT_Code": "M84.452S",
            "CPT_Description": "Pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dccc",
            "Date_offered": "09/02/2020",
            "Total_funded": 11700,
            "CPT_Code": "M84.452S",
            "CPT_Description": "Pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dccd",
            "Date_offered": "09/01/2020",
            "Total_funded": 11800,
            "CPT_Code": "M84.464S",
            "CPT_Description": "Pathological fracture, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dccd",
            "Date_offered": "09/01/2020",
            "Total_funded": 11800,
            "CPT_Code": "M84.464S",
            "CPT_Description": "Pathological fracture, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dccf",
            "Date_offered": "08/30/2020",
            "Total_funded": 12000,
            "CPT_Code": "M84.564S",
            "CPT_Description": "Pathological fracture in neoplastic disease, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dccf",
            "Date_offered": "08/30/2020",
            "Total_funded": 12000,
            "CPT_Code": "M84.564S",
            "CPT_Description": "Pathological fracture in neoplastic disease, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf5",
            "Date_offered": "07/23/2020",
            "Total_funded": 15800,
            "CPT_Code": "S72.402S",
            "CPT_Description": "Unspecified fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf5",
            "Date_offered": "07/23/2020",
            "Total_funded": 15800,
            "CPT_Code": "S72.402S",
            "CPT_Description": "Unspecified fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd21",
            "Date_offered": "06/09/2020",
            "Total_funded": 20200,
            "CPT_Code": "S82.145S",
            "CPT_Description": "Nondisplaced bicondylar fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd21",
            "Date_offered": "06/09/2020",
            "Total_funded": 20200,
            "CPT_Code": "S82.145S",
            "CPT_Description": "Nondisplaced bicondylar fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd21",
            "Date_offered": "06/09/2020",
            "Total_funded": 20200,
            "CPT_Code": "S82.145S",
            "CPT_Description": "Nondisplaced bicondylar fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd32",
            "Date_offered": "05/23/2020",
            "Total_funded": 21900,
            "CPT_Code": "S82.302S",
            "CPT_Description": "Unspecified fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd32",
            "Date_offered": "05/23/2020",
            "Total_funded": 21900,
            "CPT_Code": "S82.302S",
            "CPT_Description": "Unspecified fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4a",
            "Date_offered": "04/29/2020",
            "Total_funded": 24300,
            "CPT_Code": "S82.852S",
            "CPT_Description": "Displaced trimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4a",
            "Date_offered": "04/29/2020",
            "Total_funded": 24300,
            "CPT_Code": "S82.852S",
            "CPT_Description": "Displaced trimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4a",
            "Date_offered": "04/29/2020",
            "Total_funded": 24300,
            "CPT_Code": "S82.852S",
            "CPT_Description": "Displaced trimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd81",
            "Date_offered": "03/05/2020",
            "Total_funded": 29800,
            "CPT_Code": "S89.102S",
            "CPT_Description": "Unspecified physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd81",
            "Date_offered": "03/05/2020",
            "Total_funded": 29800,
            "CPT_Code": "S89.102S",
            "CPT_Description": "Unspecified physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd87",
            "Date_offered": "02/28/2020",
            "Total_funded": 30400,
            "CPT_Code": "S89.202S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd87",
            "Date_offered": "02/28/2020",
            "Total_funded": 30400,
            "CPT_Code": "S89.202S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8a",
            "Date_offered": "02/25/2020",
            "Total_funded": 30700,
            "CPT_Code": "S89.292S",
            "CPT_Description": "Other physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8a",
            "Date_offered": "02/25/2020",
            "Total_funded": 30700,
            "CPT_Code": "S89.292S",
            "CPT_Description": "Other physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda6",
            "Date_offered": "01/28/2020",
            "Total_funded": 33500,
            "CPT_Code": "M84.452S",
            "CPT_Description": "Pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda6",
            "Date_offered": "01/28/2020",
            "Total_funded": 33500,
            "CPT_Code": "M84.452S",
            "CPT_Description": "Pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda6",
            "Date_offered": "01/28/2020",
            "Total_funded": 33500,
            "CPT_Code": "M84.452S",
            "CPT_Description": "Pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddab",
            "Date_offered": "01/23/2020",
            "Total_funded": 34000,
            "CPT_Code": "M84.664S",
            "CPT_Description": "Pathological fracture in other disease, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddab",
            "Date_offered": "01/23/2020",
            "Total_funded": 34000,
            "CPT_Code": "M84.664S",
            "CPT_Description": "Pathological fracture in other disease, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd4",
            "Date_offered": "12/13/2019",
            "Total_funded": 38100,
            "CPT_Code": "S72.432S",
            "CPT_Description": "Displaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd4",
            "Date_offered": "12/13/2019",
            "Total_funded": 38100,
            "CPT_Code": "S72.432S",
            "CPT_Description": "Displaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd4",
            "Date_offered": "12/13/2019",
            "Total_funded": 38100,
            "CPT_Code": "S72.432S",
            "CPT_Description": "Displaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc7",
            "Date_offered": "09/07/2020",
            "Total_funded": 11200,
            "CPT_Code": "M80.062S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc7",
            "Date_offered": "09/07/2020",
            "Total_funded": 11200,
            "CPT_Code": "M80.062S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc7",
            "Date_offered": "09/07/2020",
            "Total_funded": 11200,
            "CPT_Code": "M80.062S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc7",
            "Date_offered": "09/07/2020",
            "Total_funded": 11200,
            "CPT_Code": "M80.062S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc3",
            "Date_offered": "09/11/2020",
            "Total_funded": 10800,
            "CPT_Code": "C57.12",
            "CPT_Description": "Malignant neoplasm of left broad ligament"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce1",
            "Date_offered": "08/12/2020",
            "Total_funded": 13800,
            "CPT_Code": "S72.122S",
            "CPT_Description": "Displaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce1",
            "Date_offered": "08/12/2020",
            "Total_funded": 13800,
            "CPT_Code": "S72.122S",
            "CPT_Description": "Displaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce1",
            "Date_offered": "08/12/2020",
            "Total_funded": 13800,
            "CPT_Code": "S72.122S",
            "CPT_Description": "Displaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce1",
            "Date_offered": "08/12/2020",
            "Total_funded": 13800,
            "CPT_Code": "S72.122S",
            "CPT_Description": "Displaced fracture of lesser trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcef",
            "Date_offered": "07/29/2020",
            "Total_funded": 15200,
            "CPT_Code": "S72.345S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcef",
            "Date_offered": "07/29/2020",
            "Total_funded": 15200,
            "CPT_Code": "S72.345S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcef",
            "Date_offered": "07/29/2020",
            "Total_funded": 15200,
            "CPT_Code": "S72.345S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd09",
            "Date_offered": "07/03/2020",
            "Total_funded": 17800,
            "CPT_Code": "S79.102S",
            "CPT_Description": "Unspecified physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd09",
            "Date_offered": "07/03/2020",
            "Total_funded": 17800,
            "CPT_Code": "S79.102S",
            "CPT_Description": "Unspecified physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd41",
            "Date_offered": "05/08/2020",
            "Total_funded": 23400,
            "CPT_Code": "S82.52XS",
            "CPT_Description": "Displaced fracture of medial malleolus of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd41",
            "Date_offered": "05/08/2020",
            "Total_funded": 23400,
            "CPT_Code": "S82.52XS",
            "CPT_Description": "Displaced fracture of medial malleolus of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd41",
            "Date_offered": "05/08/2020",
            "Total_funded": 23400,
            "CPT_Code": "S82.52XS",
            "CPT_Description": "Displaced fracture of medial malleolus of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd49",
            "Date_offered": "04/30/2020",
            "Total_funded": 24200,
            "CPT_Code": "S82.845S",
            "CPT_Description": "Nondisplaced bimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd49",
            "Date_offered": "04/30/2020",
            "Total_funded": 24200,
            "CPT_Code": "S82.845S",
            "CPT_Description": "Nondisplaced bimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd49",
            "Date_offered": "04/30/2020",
            "Total_funded": 24200,
            "CPT_Code": "S82.845S",
            "CPT_Description": "Nondisplaced bimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4b",
            "Date_offered": "04/28/2020",
            "Total_funded": 24400,
            "CPT_Code": "S82.855S",
            "CPT_Description": "Nondisplaced trimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4b",
            "Date_offered": "04/28/2020",
            "Total_funded": 24400,
            "CPT_Code": "S82.855S",
            "CPT_Description": "Nondisplaced trimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4b",
            "Date_offered": "04/28/2020",
            "Total_funded": 24400,
            "CPT_Code": "S82.855S",
            "CPT_Description": "Nondisplaced trimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd70",
            "Date_offered": "03/22/2020",
            "Total_funded": 28100,
            "CPT_Code": "S83.402S",
            "CPT_Description": "Sprain of unspecified collateral ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd70",
            "Date_offered": "03/22/2020",
            "Total_funded": 28100,
            "CPT_Code": "S83.402S",
            "CPT_Description": "Sprain of unspecified collateral ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd97",
            "Date_offered": "02/12/2020",
            "Total_funded": 32000,
            "CPT_Code": "T84.197S",
            "CPT_Description": "Other mechanical complication of internal fixation device of bone of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd97",
            "Date_offered": "02/12/2020",
            "Total_funded": 32000,
            "CPT_Code": "T84.197S",
            "CPT_Description": "Other mechanical complication of internal fixation device of bone of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9f",
            "Date_offered": "02/04/2020",
            "Total_funded": 32800,
            "CPT_Code": "G82.22",
            "CPT_Description": "Paraplegia, incomplete"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbd",
            "Date_offered": "01/05/2020",
            "Total_funded": 35800,
            "CPT_Code": "S72.132S",
            "CPT_Description": "Displaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbd",
            "Date_offered": "01/05/2020",
            "Total_funded": 35800,
            "CPT_Code": "S72.132S",
            "CPT_Description": "Displaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbd",
            "Date_offered": "01/05/2020",
            "Total_funded": 35800,
            "CPT_Code": "S72.132S",
            "CPT_Description": "Displaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbd",
            "Date_offered": "01/05/2020",
            "Total_funded": 35800,
            "CPT_Code": "S72.132S",
            "CPT_Description": "Displaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbf",
            "Date_offered": "01/03/2020",
            "Total_funded": 36000,
            "CPT_Code": "S72.142S",
            "CPT_Description": "Displaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbf",
            "Date_offered": "01/03/2020",
            "Total_funded": 36000,
            "CPT_Code": "S72.142S",
            "CPT_Description": "Displaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbf",
            "Date_offered": "01/03/2020",
            "Total_funded": 36000,
            "CPT_Code": "S72.142S",
            "CPT_Description": "Displaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbf",
            "Date_offered": "01/03/2020",
            "Total_funded": 36000,
            "CPT_Code": "S72.142S",
            "CPT_Description": "Displaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc8",
            "Date_offered": "12/25/2019",
            "Total_funded": 36900,
            "CPT_Code": "S72.342S",
            "CPT_Description": "Displaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc8",
            "Date_offered": "12/25/2019",
            "Total_funded": 36900,
            "CPT_Code": "S72.342S",
            "CPT_Description": "Displaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc8",
            "Date_offered": "12/25/2019",
            "Total_funded": 36900,
            "CPT_Code": "S72.342S",
            "CPT_Description": "Displaced spiral fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddca",
            "Date_offered": "12/23/2019",
            "Total_funded": 37100,
            "CPT_Code": "S72.352S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddca",
            "Date_offered": "12/23/2019",
            "Total_funded": 37100,
            "CPT_Code": "S72.352S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddca",
            "Date_offered": "12/23/2019",
            "Total_funded": 37100,
            "CPT_Code": "S72.352S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddcb",
            "Date_offered": "12/22/2019",
            "Total_funded": 37200,
            "CPT_Code": "S72.355S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddcb",
            "Date_offered": "12/22/2019",
            "Total_funded": 37200,
            "CPT_Code": "S72.355S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddcb",
            "Date_offered": "12/22/2019",
            "Total_funded": 37200,
            "CPT_Code": "S72.355S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddcc",
            "Date_offered": "12/21/2019",
            "Total_funded": 37300,
            "CPT_Code": "S72.362S",
            "CPT_Description": "Displaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddcc",
            "Date_offered": "12/21/2019",
            "Total_funded": 37300,
            "CPT_Code": "S72.362S",
            "CPT_Description": "Displaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddcc",
            "Date_offered": "12/21/2019",
            "Total_funded": 37300,
            "CPT_Code": "S72.362S",
            "CPT_Description": "Displaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dddf",
            "Date_offered": "12/02/2019",
            "Total_funded": 39200,
            "CPT_Code": "S72.92XS",
            "CPT_Description": "Unspecified fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dddf",
            "Date_offered": "12/02/2019",
            "Total_funded": 39200,
            "CPT_Code": "S72.92XS",
            "CPT_Description": "Unspecified fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dced",
            "Date_offered": "07/31/2020",
            "Total_funded": 15000,
            "CPT_Code": "S72.335S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dced",
            "Date_offered": "07/31/2020",
            "Total_funded": 15000,
            "CPT_Code": "S72.335S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dced",
            "Date_offered": "07/31/2020",
            "Total_funded": 15000,
            "CPT_Code": "S72.335S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf1",
            "Date_offered": "07/27/2020",
            "Total_funded": 15400,
            "CPT_Code": "S72.355S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf1",
            "Date_offered": "07/27/2020",
            "Total_funded": 15400,
            "CPT_Code": "S72.355S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf1",
            "Date_offered": "07/27/2020",
            "Total_funded": 15400,
            "CPT_Code": "S72.355S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf4",
            "Date_offered": "07/24/2020",
            "Total_funded": 15700,
            "CPT_Code": "S72.392S",
            "CPT_Description": "Other fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf4",
            "Date_offered": "07/24/2020",
            "Total_funded": 15700,
            "CPT_Code": "S72.392S",
            "CPT_Description": "Other fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfa",
            "Date_offered": "07/18/2020",
            "Total_funded": 16300,
            "CPT_Code": "S72.432S",
            "CPT_Description": "Displaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfa",
            "Date_offered": "07/18/2020",
            "Total_funded": 16300,
            "CPT_Code": "S72.432S",
            "CPT_Description": "Displaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfa",
            "Date_offered": "07/18/2020",
            "Total_funded": 16300,
            "CPT_Code": "S72.432S",
            "CPT_Description": "Displaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfd",
            "Date_offered": "07/15/2020",
            "Total_funded": 16600,
            "CPT_Code": "S72.445S",
            "CPT_Description": "Nondisplaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfd",
            "Date_offered": "07/15/2020",
            "Total_funded": 16600,
            "CPT_Code": "S72.445S",
            "CPT_Description": "Nondisplaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfd",
            "Date_offered": "07/15/2020",
            "Total_funded": 16600,
            "CPT_Code": "S72.445S",
            "CPT_Description": "Nondisplaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0b",
            "Date_offered": "07/01/2020",
            "Total_funded": 18000,
            "CPT_Code": "S79.122S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0b",
            "Date_offered": "07/01/2020",
            "Total_funded": 18000,
            "CPT_Code": "S79.122S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0b",
            "Date_offered": "07/01/2020",
            "Total_funded": 18000,
            "CPT_Code": "S79.122S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0d",
            "Date_offered": "06/29/2020",
            "Total_funded": 18200,
            "CPT_Code": "S79.142S",
            "CPT_Description": "Salter-Harris Type IV physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0d",
            "Date_offered": "06/29/2020",
            "Total_funded": 18200,
            "CPT_Code": "S79.142S",
            "CPT_Description": "Salter-Harris Type IV physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0d",
            "Date_offered": "06/29/2020",
            "Total_funded": 18200,
            "CPT_Code": "S79.142S",
            "CPT_Description": "Salter-Harris Type IV physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd14",
            "Date_offered": "06/22/2020",
            "Total_funded": 18900,
            "CPT_Code": "S82.032S",
            "CPT_Description": "Displaced transverse fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd14",
            "Date_offered": "06/22/2020",
            "Total_funded": 18900,
            "CPT_Code": "S82.032S",
            "CPT_Description": "Displaced transverse fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd14",
            "Date_offered": "06/22/2020",
            "Total_funded": 18900,
            "CPT_Code": "S82.032S",
            "CPT_Description": "Displaced transverse fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2b",
            "Date_offered": "05/30/2020",
            "Total_funded": 21200,
            "CPT_Code": "S82.242S",
            "CPT_Description": "Displaced spiral fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2b",
            "Date_offered": "05/30/2020",
            "Total_funded": 21200,
            "CPT_Code": "S82.242S",
            "CPT_Description": "Displaced spiral fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2b",
            "Date_offered": "05/30/2020",
            "Total_funded": 21200,
            "CPT_Code": "S82.242S",
            "CPT_Description": "Displaced spiral fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd35",
            "Date_offered": "05/20/2020",
            "Total_funded": 22200,
            "CPT_Code": "S82.402S",
            "CPT_Description": "Unspecified fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd35",
            "Date_offered": "05/20/2020",
            "Total_funded": 22200,
            "CPT_Code": "S82.402S",
            "CPT_Description": "Unspecified fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3c",
            "Date_offered": "05/13/2020",
            "Total_funded": 22900,
            "CPT_Code": "S82.452S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3c",
            "Date_offered": "05/13/2020",
            "Total_funded": 22900,
            "CPT_Code": "S82.452S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3c",
            "Date_offered": "05/13/2020",
            "Total_funded": 22900,
            "CPT_Code": "S82.452S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd53",
            "Date_offered": "04/20/2020",
            "Total_funded": 25200,
            "CPT_Code": "S83.005S",
            "CPT_Description": "Unspecified dislocation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd53",
            "Date_offered": "04/20/2020",
            "Total_funded": 25200,
            "CPT_Code": "S83.005S",
            "CPT_Description": "Unspecified dislocation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd54",
            "Date_offered": "04/19/2020",
            "Total_funded": 25300,
            "CPT_Code": "S83.012S",
            "CPT_Description": "Lateral subluxation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd54",
            "Date_offered": "04/19/2020",
            "Total_funded": 25300,
            "CPT_Code": "S83.012S",
            "CPT_Description": "Lateral subluxation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd54",
            "Date_offered": "04/19/2020",
            "Total_funded": 25300,
            "CPT_Code": "S83.012S",
            "CPT_Description": "Lateral subluxation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd55",
            "Date_offered": "04/18/2020",
            "Total_funded": 25400,
            "CPT_Code": "S83.015S",
            "CPT_Description": "Lateral dislocation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd55",
            "Date_offered": "04/18/2020",
            "Total_funded": 25400,
            "CPT_Code": "S83.015S",
            "CPT_Description": "Lateral dislocation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd55",
            "Date_offered": "04/18/2020",
            "Total_funded": 25400,
            "CPT_Code": "S83.015S",
            "CPT_Description": "Lateral dislocation of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd59",
            "Date_offered": "04/14/2020",
            "Total_funded": 25800,
            "CPT_Code": "S83.105S",
            "CPT_Description": "Unspecified dislocation of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd59",
            "Date_offered": "04/14/2020",
            "Total_funded": 25800,
            "CPT_Code": "S83.105S",
            "CPT_Description": "Unspecified dislocation of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6d",
            "Date_offered": "03/25/2020",
            "Total_funded": 27800,
            "CPT_Code": "S83.272S",
            "CPT_Description": "Complex tear of lateral meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6d",
            "Date_offered": "03/25/2020",
            "Total_funded": 27800,
            "CPT_Code": "S83.272S",
            "CPT_Description": "Complex tear of lateral meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6d",
            "Date_offered": "03/25/2020",
            "Total_funded": 27800,
            "CPT_Code": "S83.272S",
            "CPT_Description": "Complex tear of lateral meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd73",
            "Date_offered": "03/19/2020",
            "Total_funded": 28400,
            "CPT_Code": "S83.502S",
            "CPT_Description": "Sprain of unspecified cruciate ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd73",
            "Date_offered": "03/19/2020",
            "Total_funded": 28400,
            "CPT_Code": "S83.502S",
            "CPT_Description": "Sprain of unspecified cruciate ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7b",
            "Date_offered": "03/11/2020",
            "Total_funded": 29200,
            "CPT_Code": "S89.002S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd7b",
            "Date_offered": "03/11/2020",
            "Total_funded": 29200,
            "CPT_Code": "S89.002S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd84",
            "Date_offered": "03/02/2020",
            "Total_funded": 30100,
            "CPT_Code": "S89.132S",
            "CPT_Description": "Salter-Harris Type III physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd84",
            "Date_offered": "03/02/2020",
            "Total_funded": 30100,
            "CPT_Code": "S89.132S",
            "CPT_Description": "Salter-Harris Type III physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd84",
            "Date_offered": "03/02/2020",
            "Total_funded": 30100,
            "CPT_Code": "S89.132S",
            "CPT_Description": "Salter-Harris Type III physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda9",
            "Date_offered": "01/25/2020",
            "Total_funded": 33800,
            "CPT_Code": "M84.564S",
            "CPT_Description": "Pathological fracture in neoplastic disease, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda9",
            "Date_offered": "01/25/2020",
            "Total_funded": 33800,
            "CPT_Code": "M84.564S",
            "CPT_Description": "Pathological fracture in neoplastic disease, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb3",
            "Date_offered": "01/15/2020",
            "Total_funded": 34800,
            "CPT_Code": "S72.045S",
            "CPT_Description": "Nondisplaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb3",
            "Date_offered": "01/15/2020",
            "Total_funded": 34800,
            "CPT_Code": "S72.045S",
            "CPT_Description": "Nondisplaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb3",
            "Date_offered": "01/15/2020",
            "Total_funded": 34800,
            "CPT_Code": "S72.045S",
            "CPT_Description": "Nondisplaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc2",
            "Date_offered": "12/31/2019",
            "Total_funded": 36300,
            "CPT_Code": "S72.25XS",
            "CPT_Description": "Nondisplaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc2",
            "Date_offered": "12/31/2019",
            "Total_funded": 36300,
            "CPT_Code": "S72.25XS",
            "CPT_Description": "Nondisplaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc2",
            "Date_offered": "12/31/2019",
            "Total_funded": 36300,
            "CPT_Code": "S72.25XS",
            "CPT_Description": "Nondisplaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc2",
            "Date_offered": "12/31/2019",
            "Total_funded": 36300,
            "CPT_Code": "S72.25XS",
            "CPT_Description": "Nondisplaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddce",
            "Date_offered": "12/19/2019",
            "Total_funded": 37500,
            "CPT_Code": "S72.392S",
            "CPT_Description": "Other fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddce",
            "Date_offered": "12/19/2019",
            "Total_funded": 37500,
            "CPT_Code": "S72.392S",
            "CPT_Description": "Other fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc6",
            "Date_offered": "09/08/2020",
            "Total_funded": 11100,
            "CPT_Code": "M80.052S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc6",
            "Date_offered": "09/08/2020",
            "Total_funded": 11100,
            "CPT_Code": "M80.052S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc6",
            "Date_offered": "09/08/2020",
            "Total_funded": 11100,
            "CPT_Code": "M80.052S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc6",
            "Date_offered": "09/08/2020",
            "Total_funded": 11100,
            "CPT_Code": "M80.052S",
            "CPT_Description": "Age-related osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbc",
            "Date_offered": "09/18/2020",
            "Total_funded": 10100,
            "CPT_Code": "C83.39",
            "CPT_Description": "Diffuse large B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbc",
            "Date_offered": "09/18/2020",
            "Total_funded": 10100,
            "CPT_Code": "C83.39",
            "CPT_Description": "Diffuse large B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbc",
            "Date_offered": "09/18/2020",
            "Total_funded": 10100,
            "CPT_Code": "C83.39",
            "CPT_Description": "Diffuse large B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbc",
            "Date_offered": "09/18/2020",
            "Total_funded": 10100,
            "CPT_Code": "C83.39",
            "CPT_Description": "Diffuse large B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcbc",
            "Date_offered": "09/18/2020",
            "Total_funded": 10100,
            "CPT_Code": "C83.39",
            "CPT_Description": "Diffuse large B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd3",
            "Date_offered": "08/26/2020",
            "Total_funded": 12400,
            "CPT_Code": "S72.012S",
            "CPT_Description": "Unspecified intracapsular fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd3",
            "Date_offered": "08/26/2020",
            "Total_funded": 12400,
            "CPT_Code": "S72.012S",
            "CPT_Description": "Unspecified intracapsular fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf8",
            "Date_offered": "07/20/2020",
            "Total_funded": 16100,
            "CPT_Code": "S72.422S",
            "CPT_Description": "Displaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf8",
            "Date_offered": "07/20/2020",
            "Total_funded": 16100,
            "CPT_Code": "S72.422S",
            "CPT_Description": "Displaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf8",
            "Date_offered": "07/20/2020",
            "Total_funded": 16100,
            "CPT_Code": "S72.422S",
            "CPT_Description": "Displaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfe",
            "Date_offered": "07/14/2020",
            "Total_funded": 16700,
            "CPT_Code": "S72.452S",
            "CPT_Description": "Displaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfe",
            "Date_offered": "07/14/2020",
            "Total_funded": 16700,
            "CPT_Code": "S72.452S",
            "CPT_Description": "Displaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfe",
            "Date_offered": "07/14/2020",
            "Total_funded": 16700,
            "CPT_Code": "S72.452S",
            "CPT_Description": "Displaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd00",
            "Date_offered": "07/12/2020",
            "Total_funded": 16900,
            "CPT_Code": "S72.462S",
            "CPT_Description": "Displaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd00",
            "Date_offered": "07/12/2020",
            "Total_funded": 16900,
            "CPT_Code": "S72.462S",
            "CPT_Description": "Displaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd00",
            "Date_offered": "07/12/2020",
            "Total_funded": 16900,
            "CPT_Code": "S72.462S",
            "CPT_Description": "Displaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd24",
            "Date_offered": "06/06/2020",
            "Total_funded": 20500,
            "CPT_Code": "S82.162S",
            "CPT_Description": "Torus fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd24",
            "Date_offered": "06/06/2020",
            "Total_funded": 20500,
            "CPT_Code": "S82.162S",
            "CPT_Description": "Torus fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd24",
            "Date_offered": "06/06/2020",
            "Total_funded": 20500,
            "CPT_Code": "S82.162S",
            "CPT_Description": "Torus fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2d",
            "Date_offered": "05/28/2020",
            "Total_funded": 21400,
            "CPT_Code": "S82.252S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2d",
            "Date_offered": "05/28/2020",
            "Total_funded": 21400,
            "CPT_Code": "S82.252S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2d",
            "Date_offered": "05/28/2020",
            "Total_funded": 21400,
            "CPT_Code": "S82.252S",
            "CPT_Description": "Displaced comminuted fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd34",
            "Date_offered": "05/21/2020",
            "Total_funded": 22100,
            "CPT_Code": "S82.392S",
            "CPT_Description": "Other fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd34",
            "Date_offered": "05/21/2020",
            "Total_funded": 22100,
            "CPT_Code": "S82.392S",
            "CPT_Description": "Other fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd37",
            "Date_offered": "05/18/2020",
            "Total_funded": 22400,
            "CPT_Code": "S82.425S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd37",
            "Date_offered": "05/18/2020",
            "Total_funded": 22400,
            "CPT_Code": "S82.425S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd37",
            "Date_offered": "05/18/2020",
            "Total_funded": 22400,
            "CPT_Code": "S82.425S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3f",
            "Date_offered": "05/10/2020",
            "Total_funded": 23200,
            "CPT_Code": "S82.465S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3f",
            "Date_offered": "05/10/2020",
            "Total_funded": 23200,
            "CPT_Code": "S82.465S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3f",
            "Date_offered": "05/10/2020",
            "Total_funded": 23200,
            "CPT_Code": "S82.465S",
            "CPT_Description": "Nondisplaced segmental fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd44",
            "Date_offered": "05/05/2020",
            "Total_funded": 23700,
            "CPT_Code": "S82.65XS",
            "CPT_Description": "Nondisplaced fracture of lateral malleolus of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd44",
            "Date_offered": "05/05/2020",
            "Total_funded": 23700,
            "CPT_Code": "S82.65XS",
            "CPT_Description": "Nondisplaced fracture of lateral malleolus of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd44",
            "Date_offered": "05/05/2020",
            "Total_funded": 23700,
            "CPT_Code": "S82.65XS",
            "CPT_Description": "Nondisplaced fracture of lateral malleolus of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd64",
            "Date_offered": "04/03/2020",
            "Total_funded": 26900,
            "CPT_Code": "S83.201S",
            "CPT_Description": "Bucket-handle tear of unspecified meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd64",
            "Date_offered": "04/03/2020",
            "Total_funded": 26900,
            "CPT_Code": "S83.201S",
            "CPT_Description": "Bucket-handle tear of unspecified meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd64",
            "Date_offered": "04/03/2020",
            "Total_funded": 26900,
            "CPT_Code": "S83.201S",
            "CPT_Description": "Bucket-handle tear of unspecified meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd91",
            "Date_offered": "02/18/2020",
            "Total_funded": 31400,
            "CPT_Code": "T84.033S",
            "CPT_Description": "Mechanical loosening of internal left knee prosthetic joint, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd91",
            "Date_offered": "02/18/2020",
            "Total_funded": 31400,
            "CPT_Code": "T84.033S",
            "CPT_Description": "Mechanical loosening of internal left knee prosthetic joint, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd91",
            "Date_offered": "02/18/2020",
            "Total_funded": 31400,
            "CPT_Code": "T84.033S",
            "CPT_Description": "Mechanical loosening of internal left knee prosthetic joint, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9d",
            "Date_offered": "02/06/2020",
            "Total_funded": 32600,
            "CPT_Code": "Z96.653",
            "CPT_Description": "Presence of artificial knee joint, bilateral"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9d",
            "Date_offered": "02/06/2020",
            "Total_funded": 32600,
            "CPT_Code": "Z96.653",
            "CPT_Description": "Presence of artificial knee joint, bilateral"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda2",
            "Date_offered": "02/01/2020",
            "Total_funded": 33100,
            "CPT_Code": "M80.852S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda2",
            "Date_offered": "02/01/2020",
            "Total_funded": 33100,
            "CPT_Code": "M80.852S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda2",
            "Date_offered": "02/01/2020",
            "Total_funded": 33100,
            "CPT_Code": "M80.852S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda2",
            "Date_offered": "02/01/2020",
            "Total_funded": 33100,
            "CPT_Code": "M80.852S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda3",
            "Date_offered": "01/31/2020",
            "Total_funded": 33200,
            "CPT_Code": "M80.862S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda3",
            "Date_offered": "01/31/2020",
            "Total_funded": 33200,
            "CPT_Code": "M80.862S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda3",
            "Date_offered": "01/31/2020",
            "Total_funded": 33200,
            "CPT_Code": "M80.862S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda3",
            "Date_offered": "01/31/2020",
            "Total_funded": 33200,
            "CPT_Code": "M80.862S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda7",
            "Date_offered": "01/27/2020",
            "Total_funded": 33600,
            "CPT_Code": "M84.464S",
            "CPT_Description": "Pathological fracture, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda7",
            "Date_offered": "01/27/2020",
            "Total_funded": 33600,
            "CPT_Code": "M84.464S",
            "CPT_Description": "Pathological fracture, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddae",
            "Date_offered": "01/20/2020",
            "Total_funded": 34300,
            "CPT_Code": "S72.022S",
            "CPT_Description": "Displaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddae",
            "Date_offered": "01/20/2020",
            "Total_funded": 34300,
            "CPT_Code": "S72.022S",
            "CPT_Description": "Displaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddae",
            "Date_offered": "01/20/2020",
            "Total_funded": 34300,
            "CPT_Code": "S72.022S",
            "CPT_Description": "Displaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc1",
            "Date_offered": "01/01/2020",
            "Total_funded": 36200,
            "CPT_Code": "S72.22XS",
            "CPT_Description": "Displaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc1",
            "Date_offered": "01/01/2020",
            "Total_funded": 36200,
            "CPT_Code": "S72.22XS",
            "CPT_Description": "Displaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc1",
            "Date_offered": "01/01/2020",
            "Total_funded": 36200,
            "CPT_Code": "S72.22XS",
            "CPT_Description": "Displaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc1",
            "Date_offered": "01/01/2020",
            "Total_funded": 36200,
            "CPT_Code": "S72.22XS",
            "CPT_Description": "Displaced subtrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddde",
            "Date_offered": "12/03/2019",
            "Total_funded": 39100,
            "CPT_Code": "S72.8X2S",
            "CPT_Description": "Other fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddde",
            "Date_offered": "12/03/2019",
            "Total_funded": 39100,
            "CPT_Code": "S72.8X2S",
            "CPT_Description": "Other fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb9",
            "Date_offered": "09/21/2020",
            "Total_funded": 9800,
            "CPT_Code": "C82.99",
            "CPT_Description": "Follicular lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb9",
            "Date_offered": "09/21/2020",
            "Total_funded": 9800,
            "CPT_Code": "C82.99",
            "CPT_Description": "Follicular lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb9",
            "Date_offered": "09/21/2020",
            "Total_funded": 9800,
            "CPT_Code": "C82.99",
            "CPT_Description": "Follicular lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb9",
            "Date_offered": "09/21/2020",
            "Total_funded": 9800,
            "CPT_Code": "C82.99",
            "CPT_Description": "Follicular lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb9",
            "Date_offered": "09/21/2020",
            "Total_funded": 9800,
            "CPT_Code": "C82.99",
            "CPT_Description": "Follicular lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcb9",
            "Date_offered": "09/21/2020",
            "Total_funded": 9800,
            "CPT_Code": "C82.99",
            "CPT_Description": "Follicular lymphoma, unspecified, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcce",
            "Date_offered": "08/31/2020",
            "Total_funded": 11900,
            "CPT_Code": "M84.552S",
            "CPT_Description": "Pathological fracture in neoplastic disease, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcce",
            "Date_offered": "08/31/2020",
            "Total_funded": 11900,
            "CPT_Code": "M84.552S",
            "CPT_Description": "Pathological fracture in neoplastic disease, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcce",
            "Date_offered": "08/31/2020",
            "Total_funded": 11900,
            "CPT_Code": "M84.552S",
            "CPT_Description": "Pathological fracture in neoplastic disease, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd4",
            "Date_offered": "08/25/2020",
            "Total_funded": 12500,
            "CPT_Code": "S72.022S",
            "CPT_Description": "Displaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd4",
            "Date_offered": "08/25/2020",
            "Total_funded": 12500,
            "CPT_Code": "S72.022S",
            "CPT_Description": "Displaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd4",
            "Date_offered": "08/25/2020",
            "Total_funded": 12500,
            "CPT_Code": "S72.022S",
            "CPT_Description": "Displaced fracture of epiphysis (separation) (upper) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcda",
            "Date_offered": "08/19/2020",
            "Total_funded": 13100,
            "CPT_Code": "S72.052S",
            "CPT_Description": "Unspecified fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcda",
            "Date_offered": "08/19/2020",
            "Total_funded": 13100,
            "CPT_Code": "S72.052S",
            "CPT_Description": "Unspecified fracture of head of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdd",
            "Date_offered": "08/16/2020",
            "Total_funded": 13400,
            "CPT_Code": "S72.092S",
            "CPT_Description": "Other fracture of head and neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdd",
            "Date_offered": "08/16/2020",
            "Total_funded": 13400,
            "CPT_Code": "S72.092S",
            "CPT_Description": "Other fracture of head and neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdd",
            "Date_offered": "08/16/2020",
            "Total_funded": 13400,
            "CPT_Code": "S72.092S",
            "CPT_Description": "Other fracture of head and neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdf",
            "Date_offered": "08/14/2020",
            "Total_funded": 13600,
            "CPT_Code": "S72.112S",
            "CPT_Description": "Displaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdf",
            "Date_offered": "08/14/2020",
            "Total_funded": 13600,
            "CPT_Code": "S72.112S",
            "CPT_Description": "Displaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdf",
            "Date_offered": "08/14/2020",
            "Total_funded": 13600,
            "CPT_Code": "S72.112S",
            "CPT_Description": "Displaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcdf",
            "Date_offered": "08/14/2020",
            "Total_funded": 13600,
            "CPT_Code": "S72.112S",
            "CPT_Description": "Displaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf2",
            "Date_offered": "07/26/2020",
            "Total_funded": 15500,
            "CPT_Code": "S72.362S",
            "CPT_Description": "Displaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf2",
            "Date_offered": "07/26/2020",
            "Total_funded": 15500,
            "CPT_Code": "S72.362S",
            "CPT_Description": "Displaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf2",
            "Date_offered": "07/26/2020",
            "Total_funded": 15500,
            "CPT_Code": "S72.362S",
            "CPT_Description": "Displaced segmental fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd03",
            "Date_offered": "07/09/2020",
            "Total_funded": 17200,
            "CPT_Code": "S72.492S",
            "CPT_Description": "Other fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd03",
            "Date_offered": "07/09/2020",
            "Total_funded": 17200,
            "CPT_Code": "S72.492S",
            "CPT_Description": "Other fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0e",
            "Date_offered": "06/28/2020",
            "Total_funded": 18300,
            "CPT_Code": "S79.192S",
            "CPT_Description": "Other physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd0e",
            "Date_offered": "06/28/2020",
            "Total_funded": 18300,
            "CPT_Code": "S79.192S",
            "CPT_Description": "Other physeal fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1e",
            "Date_offered": "06/12/2020",
            "Total_funded": 19900,
            "CPT_Code": "S82.132S",
            "CPT_Description": "Displaced fracture of medial condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1e",
            "Date_offered": "06/12/2020",
            "Total_funded": 19900,
            "CPT_Code": "S82.132S",
            "CPT_Description": "Displaced fracture of medial condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1e",
            "Date_offered": "06/12/2020",
            "Total_funded": 19900,
            "CPT_Code": "S82.132S",
            "CPT_Description": "Displaced fracture of medial condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2e",
            "Date_offered": "05/27/2020",
            "Total_funded": 21500,
            "CPT_Code": "S82.255S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2e",
            "Date_offered": "05/27/2020",
            "Total_funded": 21500,
            "CPT_Code": "S82.255S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2e",
            "Date_offered": "05/27/2020",
            "Total_funded": 21500,
            "CPT_Code": "S82.255S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5b",
            "Date_offered": "04/12/2020",
            "Total_funded": 26000,
            "CPT_Code": "S83.115S",
            "CPT_Description": "Anterior dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5b",
            "Date_offered": "04/12/2020",
            "Total_funded": 26000,
            "CPT_Code": "S83.115S",
            "CPT_Description": "Anterior dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5b",
            "Date_offered": "04/12/2020",
            "Total_funded": 26000,
            "CPT_Code": "S83.115S",
            "CPT_Description": "Anterior dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8d",
            "Date_offered": "02/22/2020",
            "Total_funded": 31000,
            "CPT_Code": "S89.322S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8d",
            "Date_offered": "02/22/2020",
            "Total_funded": 31000,
            "CPT_Code": "S89.322S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8d",
            "Date_offered": "02/22/2020",
            "Total_funded": 31000,
            "CPT_Code": "S89.322S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddcf",
            "Date_offered": "12/18/2019",
            "Total_funded": 37600,
            "CPT_Code": "S72.402S",
            "CPT_Description": "Unspecified fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddcf",
            "Date_offered": "12/18/2019",
            "Total_funded": 37600,
            "CPT_Code": "S72.402S",
            "CPT_Description": "Unspecified fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd6",
            "Date_offered": "12/11/2019",
            "Total_funded": 38300,
            "CPT_Code": "S72.442S",
            "CPT_Description": "Displaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd6",
            "Date_offered": "12/11/2019",
            "Total_funded": 38300,
            "CPT_Code": "S72.442S",
            "CPT_Description": "Displaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd6",
            "Date_offered": "12/11/2019",
            "Total_funded": 38300,
            "CPT_Code": "S72.442S",
            "CPT_Description": "Displaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd7",
            "Date_offered": "08/22/2020",
            "Total_funded": 12800,
            "CPT_Code": "S72.035S",
            "CPT_Description": "Nondisplaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd7",
            "Date_offered": "08/22/2020",
            "Total_funded": 12800,
            "CPT_Code": "S72.035S",
            "CPT_Description": "Nondisplaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd7",
            "Date_offered": "08/22/2020",
            "Total_funded": 12800,
            "CPT_Code": "S72.035S",
            "CPT_Description": "Nondisplaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcde",
            "Date_offered": "08/15/2020",
            "Total_funded": 13500,
            "CPT_Code": "S72.102S",
            "CPT_Description": "Unspecified trochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcde",
            "Date_offered": "08/15/2020",
            "Total_funded": 13500,
            "CPT_Code": "S72.102S",
            "CPT_Description": "Unspecified trochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcde",
            "Date_offered": "08/15/2020",
            "Total_funded": 13500,
            "CPT_Code": "S72.102S",
            "CPT_Description": "Unspecified trochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcec",
            "Date_offered": "08/01/2020",
            "Total_funded": 14900,
            "CPT_Code": "S72.332S",
            "CPT_Description": "Displaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcec",
            "Date_offered": "08/01/2020",
            "Total_funded": 14900,
            "CPT_Code": "S72.332S",
            "CPT_Description": "Displaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcec",
            "Date_offered": "08/01/2020",
            "Total_funded": 14900,
            "CPT_Code": "S72.332S",
            "CPT_Description": "Displaced oblique fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf6",
            "Date_offered": "07/22/2020",
            "Total_funded": 15900,
            "CPT_Code": "S72.412S",
            "CPT_Description": "Displaced unspecified condyle fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf6",
            "Date_offered": "07/22/2020",
            "Total_funded": 15900,
            "CPT_Code": "S72.412S",
            "CPT_Description": "Displaced unspecified condyle fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf7",
            "Date_offered": "07/21/2020",
            "Total_funded": 16000,
            "CPT_Code": "S72.415S",
            "CPT_Description": "Nondisplaced unspecified condyle fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcf7",
            "Date_offered": "07/21/2020",
            "Total_funded": 16000,
            "CPT_Code": "S72.415S",
            "CPT_Description": "Nondisplaced unspecified condyle fracture of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfc",
            "Date_offered": "07/16/2020",
            "Total_funded": 16500,
            "CPT_Code": "S72.442S",
            "CPT_Description": "Displaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfc",
            "Date_offered": "07/16/2020",
            "Total_funded": 16500,
            "CPT_Code": "S72.442S",
            "CPT_Description": "Displaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcfc",
            "Date_offered": "07/16/2020",
            "Total_funded": 16500,
            "CPT_Code": "S72.442S",
            "CPT_Description": "Displaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1c",
            "Date_offered": "06/14/2020",
            "Total_funded": 19700,
            "CPT_Code": "S82.122S",
            "CPT_Description": "Displaced fracture of lateral condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1c",
            "Date_offered": "06/14/2020",
            "Total_funded": 19700,
            "CPT_Code": "S82.122S",
            "CPT_Description": "Displaced fracture of lateral condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1c",
            "Date_offered": "06/14/2020",
            "Total_funded": 19700,
            "CPT_Code": "S82.122S",
            "CPT_Description": "Displaced fracture of lateral condyle of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd51",
            "Date_offered": "04/22/2020",
            "Total_funded": 25000,
            "CPT_Code": "S82.92XS",
            "CPT_Description": "Unspecified fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd51",
            "Date_offered": "04/22/2020",
            "Total_funded": 25000,
            "CPT_Code": "S82.92XS",
            "CPT_Description": "Unspecified fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5a",
            "Date_offered": "04/13/2020",
            "Total_funded": 25900,
            "CPT_Code": "S83.112S",
            "CPT_Description": "Anterior subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5a",
            "Date_offered": "04/13/2020",
            "Total_funded": 25900,
            "CPT_Code": "S83.112S",
            "CPT_Description": "Anterior subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5a",
            "Date_offered": "04/13/2020",
            "Total_funded": 25900,
            "CPT_Code": "S83.112S",
            "CPT_Description": "Anterior subluxation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd68",
            "Date_offered": "03/30/2020",
            "Total_funded": 27300,
            "CPT_Code": "S83.222A",
            "CPT_Description": "Peripheral tear of medial meniscus, current injury, left knee, initial encounter"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd68",
            "Date_offered": "03/30/2020",
            "Total_funded": 27300,
            "CPT_Code": "S83.222A",
            "CPT_Description": "Peripheral tear of medial meniscus, current injury, left knee, initial encounter"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd80",
            "Date_offered": "03/06/2020",
            "Total_funded": 29700,
            "CPT_Code": "S89.092S",
            "CPT_Description": "Other physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd80",
            "Date_offered": "03/06/2020",
            "Total_funded": 29700,
            "CPT_Code": "S89.092S",
            "CPT_Description": "Other physeal fracture of upper end of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda8",
            "Date_offered": "01/26/2020",
            "Total_funded": 33700,
            "CPT_Code": "M84.552S",
            "CPT_Description": "Pathological fracture in neoplastic disease, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda8",
            "Date_offered": "01/26/2020",
            "Total_funded": 33700,
            "CPT_Code": "M84.552S",
            "CPT_Description": "Pathological fracture in neoplastic disease, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda8",
            "Date_offered": "01/26/2020",
            "Total_funded": 33700,
            "CPT_Code": "M84.552S",
            "CPT_Description": "Pathological fracture in neoplastic disease, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddad",
            "Date_offered": "01/21/2020",
            "Total_funded": 34200,
            "CPT_Code": "S72.012S",
            "CPT_Description": "Unspecified intracapsular fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddad",
            "Date_offered": "01/21/2020",
            "Total_funded": 34200,
            "CPT_Code": "S72.012S",
            "CPT_Description": "Unspecified intracapsular fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb1",
            "Date_offered": "01/17/2020",
            "Total_funded": 34600,
            "CPT_Code": "S72.035S",
            "CPT_Description": "Nondisplaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb1",
            "Date_offered": "01/17/2020",
            "Total_funded": 34600,
            "CPT_Code": "S72.035S",
            "CPT_Description": "Nondisplaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb1",
            "Date_offered": "01/17/2020",
            "Total_funded": 34600,
            "CPT_Code": "S72.035S",
            "CPT_Description": "Nondisplaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd2",
            "Date_offered": "12/15/2019",
            "Total_funded": 37900,
            "CPT_Code": "S72.422S",
            "CPT_Description": "Displaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd2",
            "Date_offered": "12/15/2019",
            "Total_funded": 37900,
            "CPT_Code": "S72.422S",
            "CPT_Description": "Displaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd2",
            "Date_offered": "12/15/2019",
            "Total_funded": 37900,
            "CPT_Code": "S72.422S",
            "CPT_Description": "Displaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dde0",
            "Date_offered": "12/01/2019",
            "Total_funded": 39300,
            "CPT_Code": "S79.002S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dde0",
            "Date_offered": "12/01/2019",
            "Total_funded": 39300,
            "CPT_Code": "S79.002S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dde0",
            "Date_offered": "12/01/2019",
            "Total_funded": 39300,
            "CPT_Code": "S79.002S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc0",
            "Date_offered": "09/14/2020",
            "Total_funded": 10500,
            "CPT_Code": "C85.29",
            "CPT_Description": "Mediastinal (thymic) large B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc0",
            "Date_offered": "09/14/2020",
            "Total_funded": 10500,
            "CPT_Code": "C85.29",
            "CPT_Description": "Mediastinal (thymic) large B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc0",
            "Date_offered": "09/14/2020",
            "Total_funded": 10500,
            "CPT_Code": "C85.29",
            "CPT_Description": "Mediastinal (thymic) large B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc0",
            "Date_offered": "09/14/2020",
            "Total_funded": 10500,
            "CPT_Code": "C85.29",
            "CPT_Description": "Mediastinal (thymic) large B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc0",
            "Date_offered": "09/14/2020",
            "Total_funded": 10500,
            "CPT_Code": "C85.29",
            "CPT_Description": "Mediastinal (thymic) large B-cell lymphoma, extranodal and solid organ sites"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd1",
            "Date_offered": "08/28/2020",
            "Total_funded": 12200,
            "CPT_Code": "M84.664S",
            "CPT_Description": "Pathological fracture in other disease, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd1",
            "Date_offered": "08/28/2020",
            "Total_funded": 12200,
            "CPT_Code": "M84.664S",
            "CPT_Description": "Pathological fracture in other disease, left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce0",
            "Date_offered": "08/13/2020",
            "Total_funded": 13700,
            "CPT_Code": "S72.115S",
            "CPT_Description": "Nondisplaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce0",
            "Date_offered": "08/13/2020",
            "Total_funded": 13700,
            "CPT_Code": "S72.115S",
            "CPT_Description": "Nondisplaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce0",
            "Date_offered": "08/13/2020",
            "Total_funded": 13700,
            "CPT_Code": "S72.115S",
            "CPT_Description": "Nondisplaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce0",
            "Date_offered": "08/13/2020",
            "Total_funded": 13700,
            "CPT_Code": "S72.115S",
            "CPT_Description": "Nondisplaced fracture of greater trochanter of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce6",
            "Date_offered": "08/07/2020",
            "Total_funded": 14300,
            "CPT_Code": "S72.145S",
            "CPT_Description": "Nondisplaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce6",
            "Date_offered": "08/07/2020",
            "Total_funded": 14300,
            "CPT_Code": "S72.145S",
            "CPT_Description": "Nondisplaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce6",
            "Date_offered": "08/07/2020",
            "Total_funded": 14300,
            "CPT_Code": "S72.145S",
            "CPT_Description": "Nondisplaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dce6",
            "Date_offered": "08/07/2020",
            "Total_funded": 14300,
            "CPT_Code": "S72.145S",
            "CPT_Description": "Nondisplaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dceb",
            "Date_offered": "08/02/2020",
            "Total_funded": 14800,
            "CPT_Code": "S72.325S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dceb",
            "Date_offered": "08/02/2020",
            "Total_funded": 14800,
            "CPT_Code": "S72.325S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dceb",
            "Date_offered": "08/02/2020",
            "Total_funded": 14800,
            "CPT_Code": "S72.325S",
            "CPT_Description": "Nondisplaced transverse fracture of shaft of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcff",
            "Date_offered": "07/13/2020",
            "Total_funded": 16800,
            "CPT_Code": "S72.455S",
            "CPT_Description": "Nondisplaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcff",
            "Date_offered": "07/13/2020",
            "Total_funded": 16800,
            "CPT_Code": "S72.455S",
            "CPT_Description": "Nondisplaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcff",
            "Date_offered": "07/13/2020",
            "Total_funded": 16800,
            "CPT_Code": "S72.455S",
            "CPT_Description": "Nondisplaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd17",
            "Date_offered": "06/19/2020",
            "Total_funded": 19200,
            "CPT_Code": "S82.045S",
            "CPT_Description": "Nondisplaced comminuted fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd17",
            "Date_offered": "06/19/2020",
            "Total_funded": 19200,
            "CPT_Code": "S82.045S",
            "CPT_Description": "Nondisplaced comminuted fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd17",
            "Date_offered": "06/19/2020",
            "Total_funded": 19200,
            "CPT_Code": "S82.045S",
            "CPT_Description": "Nondisplaced comminuted fracture of left patella, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1a",
            "Date_offered": "06/16/2020",
            "Total_funded": 19500,
            "CPT_Code": "S82.112S",
            "CPT_Description": "Displaced fracture of left tibial spine, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1a",
            "Date_offered": "06/16/2020",
            "Total_funded": 19500,
            "CPT_Code": "S82.112S",
            "CPT_Description": "Displaced fracture of left tibial spine, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1a",
            "Date_offered": "06/16/2020",
            "Total_funded": 19500,
            "CPT_Code": "S82.112S",
            "CPT_Description": "Displaced fracture of left tibial spine, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1b",
            "Date_offered": "06/15/2020",
            "Total_funded": 19600,
            "CPT_Code": "S82.115S",
            "CPT_Description": "Nondisplaced fracture of left tibial spine, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1b",
            "Date_offered": "06/15/2020",
            "Total_funded": 19600,
            "CPT_Code": "S82.115S",
            "CPT_Description": "Nondisplaced fracture of left tibial spine, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd1b",
            "Date_offered": "06/15/2020",
            "Total_funded": 19600,
            "CPT_Code": "S82.115S",
            "CPT_Description": "Nondisplaced fracture of left tibial spine, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd27",
            "Date_offered": "06/03/2020",
            "Total_funded": 20800,
            "CPT_Code": "S82.222S",
            "CPT_Description": "Displaced transverse fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd27",
            "Date_offered": "06/03/2020",
            "Total_funded": 20800,
            "CPT_Code": "S82.222S",
            "CPT_Description": "Displaced transverse fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd27",
            "Date_offered": "06/03/2020",
            "Total_funded": 20800,
            "CPT_Code": "S82.222S",
            "CPT_Description": "Displaced transverse fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2a",
            "Date_offered": "05/31/2020",
            "Total_funded": 21100,
            "CPT_Code": "S82.235S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2a",
            "Date_offered": "05/31/2020",
            "Total_funded": 21100,
            "CPT_Code": "S82.235S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2a",
            "Date_offered": "05/31/2020",
            "Total_funded": 21100,
            "CPT_Code": "S82.235S",
            "CPT_Description": "Nondisplaced oblique fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2c",
            "Date_offered": "05/29/2020",
            "Total_funded": 21300,
            "CPT_Code": "S82.245S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2c",
            "Date_offered": "05/29/2020",
            "Total_funded": 21300,
            "CPT_Code": "S82.245S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd2c",
            "Date_offered": "05/29/2020",
            "Total_funded": 21300,
            "CPT_Code": "S82.245S",
            "CPT_Description": "Nondisplaced spiral fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd31",
            "Date_offered": "05/24/2020",
            "Total_funded": 21800,
            "CPT_Code": "S82.292S",
            "CPT_Description": "Other fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd31",
            "Date_offered": "05/24/2020",
            "Total_funded": 21800,
            "CPT_Code": "S82.292S",
            "CPT_Description": "Other fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3d",
            "Date_offered": "05/12/2020",
            "Total_funded": 23000,
            "CPT_Code": "S82.455S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3d",
            "Date_offered": "05/12/2020",
            "Total_funded": 23000,
            "CPT_Code": "S82.455S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd3d",
            "Date_offered": "05/12/2020",
            "Total_funded": 23000,
            "CPT_Code": "S82.455S",
            "CPT_Description": "Nondisplaced comminuted fracture of shaft of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd48",
            "Date_offered": "05/01/2020",
            "Total_funded": 24100,
            "CPT_Code": "S82.842S",
            "CPT_Description": "Displaced bimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd48",
            "Date_offered": "05/01/2020",
            "Total_funded": 24100,
            "CPT_Code": "S82.842S",
            "CPT_Description": "Displaced bimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd48",
            "Date_offered": "05/01/2020",
            "Total_funded": 24100,
            "CPT_Code": "S82.842S",
            "CPT_Description": "Displaced bimalleolar fracture of left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5d",
            "Date_offered": "04/10/2020",
            "Total_funded": 26200,
            "CPT_Code": "S83.125S",
            "CPT_Description": "Posterior dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5d",
            "Date_offered": "04/10/2020",
            "Total_funded": 26200,
            "CPT_Code": "S83.125S",
            "CPT_Description": "Posterior dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd5d",
            "Date_offered": "04/10/2020",
            "Total_funded": 26200,
            "CPT_Code": "S83.125S",
            "CPT_Description": "Posterior dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd61",
            "Date_offered": "04/06/2020",
            "Total_funded": 26600,
            "CPT_Code": "S83.145S",
            "CPT_Description": "Lateral dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd61",
            "Date_offered": "04/06/2020",
            "Total_funded": 26600,
            "CPT_Code": "S83.145S",
            "CPT_Description": "Lateral dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd61",
            "Date_offered": "04/06/2020",
            "Total_funded": 26600,
            "CPT_Code": "S83.145S",
            "CPT_Description": "Lateral dislocation of proximal end of tibia, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6c",
            "Date_offered": "03/26/2020",
            "Total_funded": 27700,
            "CPT_Code": "S83.262S",
            "CPT_Description": "Peripheral tear of lateral meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6c",
            "Date_offered": "03/26/2020",
            "Total_funded": 27700,
            "CPT_Code": "S83.262S",
            "CPT_Description": "Peripheral tear of lateral meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd6c",
            "Date_offered": "03/26/2020",
            "Total_funded": 27700,
            "CPT_Code": "S83.262S",
            "CPT_Description": "Peripheral tear of lateral meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8e",
            "Date_offered": "02/21/2020",
            "Total_funded": 31100,
            "CPT_Code": "S89.392S",
            "CPT_Description": "Other physeal fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8e",
            "Date_offered": "02/21/2020",
            "Total_funded": 31100,
            "CPT_Code": "S89.392S",
            "CPT_Description": "Other physeal fracture of lower end of left fibula, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd99",
            "Date_offered": "02/10/2020",
            "Total_funded": 32200,
            "CPT_Code": "T84.420S",
            "CPT_Description": "Displacement of muscle and tendon graft, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd99",
            "Date_offered": "02/10/2020",
            "Total_funded": 32200,
            "CPT_Code": "T84.420S",
            "CPT_Description": "Displacement of muscle and tendon graft, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9b",
            "Date_offered": "02/08/2020",
            "Total_funded": 32400,
            "CPT_Code": "T84.54XS",
            "CPT_Description": "Infection and inflammatory reaction due to internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9b",
            "Date_offered": "02/08/2020",
            "Total_funded": 32400,
            "CPT_Code": "T84.54XS",
            "CPT_Description": "Infection and inflammatory reaction due to internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9b",
            "Date_offered": "02/08/2020",
            "Total_funded": 32400,
            "CPT_Code": "T84.54XS",
            "CPT_Description": "Infection and inflammatory reaction due to internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9b",
            "Date_offered": "02/08/2020",
            "Total_funded": 32400,
            "CPT_Code": "T84.54XS",
            "CPT_Description": "Infection and inflammatory reaction due to internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9e",
            "Date_offered": "02/05/2020",
            "Total_funded": 32700,
            "CPT_Code": "G81.94",
            "CPT_Description": "Hemiplegia, unspecified affecting left nondominant side"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9e",
            "Date_offered": "02/05/2020",
            "Total_funded": 32700,
            "CPT_Code": "G81.94",
            "CPT_Description": "Hemiplegia, unspecified affecting left nondominant side"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd9e",
            "Date_offered": "02/05/2020",
            "Total_funded": 32700,
            "CPT_Code": "G81.94",
            "CPT_Description": "Hemiplegia, unspecified affecting left nondominant side"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda4",
            "Date_offered": "01/30/2020",
            "Total_funded": 33300,
            "CPT_Code": "M84.352S",
            "CPT_Description": "Stress fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda4",
            "Date_offered": "01/30/2020",
            "Total_funded": 33300,
            "CPT_Code": "M84.352S",
            "CPT_Description": "Stress fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dda4",
            "Date_offered": "01/30/2020",
            "Total_funded": 33300,
            "CPT_Code": "M84.352S",
            "CPT_Description": "Stress fracture, left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb2",
            "Date_offered": "01/16/2020",
            "Total_funded": 34700,
            "CPT_Code": "S72.042S",
            "CPT_Description": "Displaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb2",
            "Date_offered": "01/16/2020",
            "Total_funded": 34700,
            "CPT_Code": "S72.042S",
            "CPT_Description": "Displaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb2",
            "Date_offered": "01/16/2020",
            "Total_funded": 34700,
            "CPT_Code": "S72.042S",
            "CPT_Description": "Displaced fracture of base of neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb7",
            "Date_offered": "01/11/2020",
            "Total_funded": 35200,
            "CPT_Code": "S72.092S",
            "CPT_Description": "Other fracture of head and neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb7",
            "Date_offered": "01/11/2020",
            "Total_funded": 35200,
            "CPT_Code": "S72.092S",
            "CPT_Description": "Other fracture of head and neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddb7",
            "Date_offered": "01/11/2020",
            "Total_funded": 35200,
            "CPT_Code": "S72.092S",
            "CPT_Description": "Other fracture of head and neck of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbe",
            "Date_offered": "01/04/2020",
            "Total_funded": 35900,
            "CPT_Code": "S72.135S",
            "CPT_Description": "Nondisplaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbe",
            "Date_offered": "01/04/2020",
            "Total_funded": 35900,
            "CPT_Code": "S72.135S",
            "CPT_Description": "Nondisplaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbe",
            "Date_offered": "01/04/2020",
            "Total_funded": 35900,
            "CPT_Code": "S72.135S",
            "CPT_Description": "Nondisplaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddbe",
            "Date_offered": "01/04/2020",
            "Total_funded": 35900,
            "CPT_Code": "S72.135S",
            "CPT_Description": "Nondisplaced apophyseal fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc0",
            "Date_offered": "01/02/2020",
            "Total_funded": 36100,
            "CPT_Code": "S72.145S",
            "CPT_Description": "Nondisplaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc0",
            "Date_offered": "01/02/2020",
            "Total_funded": 36100,
            "CPT_Code": "S72.145S",
            "CPT_Description": "Nondisplaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc0",
            "Date_offered": "01/02/2020",
            "Total_funded": 36100,
            "CPT_Code": "S72.145S",
            "CPT_Description": "Nondisplaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddc0",
            "Date_offered": "01/02/2020",
            "Total_funded": 36100,
            "CPT_Code": "S72.145S",
            "CPT_Description": "Nondisplaced intertrochanteric fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd3",
            "Date_offered": "12/14/2019",
            "Total_funded": 38000,
            "CPT_Code": "S72.425S",
            "CPT_Description": "Nondisplaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd3",
            "Date_offered": "12/14/2019",
            "Total_funded": 38000,
            "CPT_Code": "S72.425S",
            "CPT_Description": "Nondisplaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd3",
            "Date_offered": "12/14/2019",
            "Total_funded": 38000,
            "CPT_Code": "S72.425S",
            "CPT_Description": "Nondisplaced fracture of lateral condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd9",
            "Date_offered": "12/08/2019",
            "Total_funded": 38600,
            "CPT_Code": "S72.455S",
            "CPT_Description": "Nondisplaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd9",
            "Date_offered": "12/08/2019",
            "Total_funded": 38600,
            "CPT_Code": "S72.455S",
            "CPT_Description": "Nondisplaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd9",
            "Date_offered": "12/08/2019",
            "Total_funded": 38600,
            "CPT_Code": "S72.455S",
            "CPT_Description": "Nondisplaced supracondylar fracture without intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc9",
            "Date_offered": "09/05/2020",
            "Total_funded": 11400,
            "CPT_Code": "M80.862S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc9",
            "Date_offered": "09/05/2020",
            "Total_funded": 11400,
            "CPT_Code": "M80.862S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc9",
            "Date_offered": "09/05/2020",
            "Total_funded": 11400,
            "CPT_Code": "M80.862S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcc9",
            "Date_offered": "09/05/2020",
            "Total_funded": 11400,
            "CPT_Code": "M80.862S",
            "CPT_Description": "Other osteoporosis with current pathological fracture, left lower leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd6",
            "Date_offered": "08/23/2020",
            "Total_funded": 12700,
            "CPT_Code": "S72.032S",
            "CPT_Description": "Displaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd6",
            "Date_offered": "08/23/2020",
            "Total_funded": 12700,
            "CPT_Code": "S72.032S",
            "CPT_Description": "Displaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dcd6",
            "Date_offered": "08/23/2020",
            "Total_funded": 12700,
            "CPT_Code": "S72.032S",
            "CPT_Description": "Displaced midcervical fracture of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd20",
            "Date_offered": "06/10/2020",
            "Total_funded": 20100,
            "CPT_Code": "S82.142S",
            "CPT_Description": "Displaced bicondylar fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd20",
            "Date_offered": "06/10/2020",
            "Total_funded": 20100,
            "CPT_Code": "S82.142S",
            "CPT_Description": "Displaced bicondylar fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd20",
            "Date_offered": "06/10/2020",
            "Total_funded": 20100,
            "CPT_Code": "S82.142S",
            "CPT_Description": "Displaced bicondylar fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd29",
            "Date_offered": "06/01/2020",
            "Total_funded": 21000,
            "CPT_Code": "S82.232S",
            "CPT_Description": "Displaced oblique fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd29",
            "Date_offered": "06/01/2020",
            "Total_funded": 21000,
            "CPT_Code": "S82.232S",
            "CPT_Description": "Displaced oblique fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd29",
            "Date_offered": "06/01/2020",
            "Total_funded": 21000,
            "CPT_Code": "S82.232S",
            "CPT_Description": "Displaced oblique fracture of shaft of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4d",
            "Date_offered": "04/26/2020",
            "Total_funded": 24600,
            "CPT_Code": "S82.865S",
            "CPT_Description": "Nondisplaced Maisonneuve's fracture of left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4d",
            "Date_offered": "04/26/2020",
            "Total_funded": 24600,
            "CPT_Code": "S82.865S",
            "CPT_Description": "Nondisplaced Maisonneuve's fracture of left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4d",
            "Date_offered": "04/26/2020",
            "Total_funded": 24600,
            "CPT_Code": "S82.865S",
            "CPT_Description": "Nondisplaced Maisonneuve's fracture of left leg, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4f",
            "Date_offered": "04/24/2020",
            "Total_funded": 24800,
            "CPT_Code": "S82.875S",
            "CPT_Description": "Nondisplaced pilon fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4f",
            "Date_offered": "04/24/2020",
            "Total_funded": 24800,
            "CPT_Code": "S82.875S",
            "CPT_Description": "Nondisplaced pilon fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd4f",
            "Date_offered": "04/24/2020",
            "Total_funded": 24800,
            "CPT_Code": "S82.875S",
            "CPT_Description": "Nondisplaced pilon fracture of left tibia, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd69",
            "Date_offered": "03/29/2020",
            "Total_funded": 27400,
            "CPT_Code": "S83.232S",
            "CPT_Description": "Complex tear of medial meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd69",
            "Date_offered": "03/29/2020",
            "Total_funded": 27400,
            "CPT_Code": "S83.232S",
            "CPT_Description": "Complex tear of medial meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd69",
            "Date_offered": "03/29/2020",
            "Total_funded": 27400,
            "CPT_Code": "S83.232S",
            "CPT_Description": "Complex tear of medial meniscus, current injury, left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd71",
            "Date_offered": "03/21/2020",
            "Total_funded": 28200,
            "CPT_Code": "S83.412S",
            "CPT_Description": "Sprain of medial collateral ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd71",
            "Date_offered": "03/21/2020",
            "Total_funded": 28200,
            "CPT_Code": "S83.412S",
            "CPT_Description": "Sprain of medial collateral ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd71",
            "Date_offered": "03/21/2020",
            "Total_funded": 28200,
            "CPT_Code": "S83.412S",
            "CPT_Description": "Sprain of medial collateral ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd71",
            "Date_offered": "03/21/2020",
            "Total_funded": 28200,
            "CPT_Code": "S83.412S",
            "CPT_Description": "Sprain of medial collateral ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd74",
            "Date_offered": "03/18/2020",
            "Total_funded": 28500,
            "CPT_Code": "S83.512S",
            "CPT_Description": "Sprain of anterior cruciate ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd74",
            "Date_offered": "03/18/2020",
            "Total_funded": 28500,
            "CPT_Code": "S83.512S",
            "CPT_Description": "Sprain of anterior cruciate ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd74",
            "Date_offered": "03/18/2020",
            "Total_funded": 28500,
            "CPT_Code": "S83.512S",
            "CPT_Description": "Sprain of anterior cruciate ligament of left knee, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8f",
            "Date_offered": "02/20/2020",
            "Total_funded": 31200,
            "CPT_Code": "T84.013S",
            "CPT_Description": "Broken internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8f",
            "Date_offered": "02/20/2020",
            "Total_funded": 31200,
            "CPT_Code": "T84.013S",
            "CPT_Description": "Broken internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9dd8f",
            "Date_offered": "02/20/2020",
            "Total_funded": 31200,
            "CPT_Code": "T84.013S",
            "CPT_Description": "Broken internal left knee prosthesis, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd5",
            "Date_offered": "12/12/2019",
            "Total_funded": 38200,
            "CPT_Code": "S72.435S",
            "CPT_Description": "Nondisplaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd5",
            "Date_offered": "12/12/2019",
            "Total_funded": 38200,
            "CPT_Code": "S72.435S",
            "CPT_Description": "Nondisplaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd5",
            "Date_offered": "12/12/2019",
            "Total_funded": 38200,
            "CPT_Code": "S72.435S",
            "CPT_Description": "Nondisplaced fracture of medial condyle of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd7",
            "Date_offered": "12/10/2019",
            "Total_funded": 38400,
            "CPT_Code": "S72.445S",
            "CPT_Description": "Nondisplaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd7",
            "Date_offered": "12/10/2019",
            "Total_funded": 38400,
            "CPT_Code": "S72.445S",
            "CPT_Description": "Nondisplaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddd7",
            "Date_offered": "12/10/2019",
            "Total_funded": 38400,
            "CPT_Code": "S72.445S",
            "CPT_Description": "Nondisplaced fracture of lower epiphysis (separation) of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddda",
            "Date_offered": "12/07/2019",
            "Total_funded": 38700,
            "CPT_Code": "S72.462S",
            "CPT_Description": "Displaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddda",
            "Date_offered": "12/07/2019",
            "Total_funded": 38700,
            "CPT_Code": "S72.462S",
            "CPT_Description": "Displaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fc374ce6a29c91c88b9ddda",
            "Date_offered": "12/07/2019",
            "Total_funded": 38700,
            "CPT_Code": "S72.462S",
            "CPT_Description": "Displaced supracondylar fracture with intracondylar extension of lower end of left femur, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b2800550c",
            "Date_offered": "10/16/2020",
            "Total_funded": 8600,
            "CPT_Code": "S89.192S",
            "CPT_Description": "Other physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b2800550c",
            "Date_offered": "10/16/2020",
            "Total_funded": 8600,
            "CPT_Code": "S89.192S",
            "CPT_Description": "Other physeal fracture of lower end of left tibia, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b2800550d",
            "Date_offered": "08/27/2020",
            "Total_funded": 8632,
            "CPT_Code": "S89.202S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b2800550d",
            "Date_offered": "08/27/2020",
            "Total_funded": 8632,
            "CPT_Code": "S89.202S",
            "CPT_Description": "Unspecified physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b2800550e",
            "Date_offered": "07/08/2020",
            "Total_funded": 8664,
            "CPT_Code": "S89.212S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b2800550e",
            "Date_offered": "07/08/2020",
            "Total_funded": 8664,
            "CPT_Code": "S89.212S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b2800550e",
            "Date_offered": "07/08/2020",
            "Total_funded": 8664,
            "CPT_Code": "S89.212S",
            "CPT_Description": "Salter-Harris Type I physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b2800550f",
            "Date_offered": "05/19/2020",
            "Total_funded": 8696,
            "CPT_Code": "S89.222S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b2800550f",
            "Date_offered": "05/19/2020",
            "Total_funded": 8696,
            "CPT_Code": "S89.222S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b2800550f",
            "Date_offered": "05/19/2020",
            "Total_funded": 8696,
            "CPT_Code": "S89.222S",
            "CPT_Description": "Salter-Harris Type II physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b28005510",
            "Date_offered": "03/30/2020",
            "Total_funded": 8728,
            "CPT_Code": "S89.292S",
            "CPT_Description": "Other physeal fracture of upper end of left fibula, sequela"
        },
        {
            "Loan_id": "5fccedf52ca4412b28005510",
            "Date_offered": "03/30/2020",
            "Total_funded": 8728,
            "CPT_Code": "S89.292S",
            "CPT_Description": "Other physeal fracture of upper end of left fibula, sequela"
        }
    ]
};