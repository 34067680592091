import { get } from "./httpClient";
import {
  ONBORDING_PROVIDER_URL,
  GET_GROUP_LIST_URL,
  GET_GROUP_TYPE_LIST_URL,
} from "../Uintls/Urls";

export const userListApi =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(ONBORDING_PROVIDER_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const groupsListSearchApi =
  (payload, successCallback, failureCallback) => async () => {
    const updatedPayload = { ...payload };
    if (payload.groupType === "") {
      updatedPayload.groupType = "All";
    }
    if (payload.groupStatus === "") {
      updatedPayload.groupStatus = "All";
    }
    try {
      // const url = `${GET_GROUP_LIST_URL}?groupName=${payload.groupName}&groupType=${payload.groupType}&groupStatus=${payload.groupStatus}&apiType=${payload.apiType}`;
      const data = await get(GET_GROUP_LIST_URL, updatedPayload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const groupsListApi = (successCallback, failureCallback) => async () => {
  try {
    const data = await get(GET_GROUP_LIST_URL);

    if (typeof successCallback === "function") {
      successCallback(data);
    }
  } catch (err) {
    if (typeof failureCallback === "function") {
      failureCallback(err);
    }
  }
};

export const groupsTypeListApi =
  (successCallback, failureCallback) => async () => {
    try {
      const data = await get(GET_GROUP_TYPE_LIST_URL);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
