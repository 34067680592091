export const recent_patient_data = {
    "Patient_List": [
        {
            "Patient_Name": "Scott styris",
			"Profile_Image": "marc.jpg"
        },
        {
            "Patient_Name": "Adam Zampa",
			"Profile_Image": "marc.jpg"
        },
        {
            "Patient_Name": "Aaron Finch",
			"Profile_Image": "marc.jpg"
        },
        {
            "Patient_Name": "Steve Smith",
			"Profile_Image": "marc.jpg"
        },
        {
            "Patient_Name": "Glenn Maxwell",
			"Profile_Image": "marc.jpg"
        },
        {
            "Patient_Name": "David warner",
			"Profile_Image": "marc.jpg"
        },
        {
            "Patient_Name": "garry kustern",
			"Profile_Image": "marc.jpg"
        },
        {
            "Patient_Name": "Ian botham",
			"Profile_Image": "marc.jpg"
        },
        {
            "Patient_Name": "Allen border",
			"Profile_Image": "marc.jpg"
        },
        {
            "Patient_Name": "Ricky Ponting",
			"Profile_Image": "marc.jpg"
        }
    ]
}