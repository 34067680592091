import {
  Box,
  Grid,
  Paper,
  ThemeProvider,
  Typography,
  makeStyles,
  useTheme,
  Stepper,
  Step,
  Button,
  createTheme,
  Modal,
  StepLabel,
  Backdrop,
  TextField,
  FormControl,
  InputLabel
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { ReactComponent as PaymentConfirmIcon } from "./paymentConfirm.svg";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { userDetailsFunction } from "../../actions/ayloPay.actions";
import moment from "moment";
import { Field, Form, Formik, ErrorMessage } from "formik";
import Validations from "./validations";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  next: {
    fontFamily: "Poppins",
    background: `#1A4155`,
    borderRadius: "0px 20px 20px 20px",
    padding: "12px 45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    justifyContent: "end",
    display: "flex",
    marginLeft: "auto",
    "&:hover": {
      background: `#1A4155`,
      color: "white"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 20px",
      fontSize: "12px"
    }
  },
  previous: {
    fontFamily: "Poppins",
    borderRadius: "20px",
    padding: "12px 45px",
    background: `white`,
    color: "black",
    border: "2px solid",
    fontWeight: 700,
    fontSize: "16px",
    "&:hover": {
      background: `white`,
      color: "black",
      border: "2px solid"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 20px",
      fontSize: "12px"
    }
  },
  payNow: {
    fontFamily: "Poppins",
    background: `#F26628`,
    borderRadius: "0px 20px 20px 20px",
    padding: "12px 45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    justifyContent: "end",
    display: "flex",
    marginLeft: "auto",
    "&:hover": {
      background: `#F26628`,
      color: "white"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 20px",
      fontSize: "12px"
    }
  },
  mainHeading: {
    fontFamily: "Lora",
    fontWeight: "400",
    fontSize: "50px",
    color: "#1a4155",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px"
    }
  },
  mainText: {
    fontFamily: "Poppins",
    fontWeight: "400",
    fontSize: "18px",
    color: "#1a4155",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px"
    }
  },
  payText: {
    fontFamily: "Poppins",
    fontWeight: "700",
    fontSize: "16px",
    color: "#1a4155",
    textTransform: "uppercase",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  },
  linkText: {
    textDecoration: "underline",
    cursor: "pointer"
  },
  customBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "8px"
  },
  reviewCustomBox: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "1rem"
  },
  reviewDueBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "1.5rem"
  },
  customBoxRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "8px"
  },
  stepLabel: {
    fontFamily: "Poppins",
    background: `#1A4155`,
    borderRadius: "50%",
    padding: "1rem",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    letterSpacing: "0.15em"
  },
  reviewBox: {
    background: `#F5F5F5`,
    padding: "1rem",
    borderRadius: "10px"
  }
}));

const theme = createTheme({
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: `rgba(242, 102, 40, 0.6)`
      }
    },
    MuiIconButton: {
      root: {
        background: `white`,
        "&:hover": {
          backgroundColor: `white`
        }
      }
    },
    MuiButton: {
      label: {
        letterSpacing: "0.15em !important"
      },
      root: {
        "&.Mui-disabled": {
          backgroundColor: `#C0C0C0`
        }
      }
    },
    MuiIcon: {
      root: {
        color: `#1A4155`
      }
    },
    MuiStepIcon: {
      active: {
        color: `#1A4155 !important`,
        width: "1.5rem",
        height: "1.5rem"
      },
      completed: {
        color: `#1A4155 !important`,
        width: "1.5rem",
        height: "1.5rem"
      }
    },
    MuiInputBase: {
      input: {
        padding: "12px !important",
        borderRadius: "10px !important"
      }
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: "unset !important"
      },
      root: {
        border: "2px solid #1A4155 !important",
        borderRadius: "10px"
      }
    },
    MuiInput: {
      root: {
        border: "2px solid #1A4155 !important",
        borderRadius: "10px"
      },
      underline: {
        "&:before": {
          borderBottom: "unset !important"
        },
        "&:after": {
          borderBottom: "unset !important"
        }
      }
    },
    MuiPaper: {
      root: {
        "&::-webkit-scrollbar": {
          display: "none"
        }
      }
    },
    MuiFormHelperText: {
      root: {
        color: "red"
      }
    }
  }
});

const amountTheme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        padding: "unset",
        borderRadius: "10px",
        fontSize: "28px",
        fontFamily: "Poppins",
        textAlign: "center",
        cursor: "pointer"
      }
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "unset !important"
        },
        "&:after": {
          borderBottom: "unset !important"
        }
      }
    },
    MuiFormHelperText: {
      root: {
        textAlign: "center !important",
        color: "red"
      }
    }
  }
});

const infoTheme = createTheme({
  overrides: {
    MuiInputBase: {
      input: {
        padding: "unset",
        borderRadius: "10px",
        fontSize: "18px",
        fontFamily: "Poppins",
        textAlign: "end",
        cursor: "pointer",
        ["@media (max-width:959px)"]: {
          fontSize: "14px"
        }
      },
      multiline: {
        padding: "unset"
      }
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "unset !important"
        },
        "&:after": {
          borderBottom: "unset !important"
        }
      }
    },
    MuiFormControl: {
      root: {
        width: "70%"
      }
    }
  }
});

function getSteps() {
  return ["Step 1", "Step 2", "Step 3"];
}

const PaymentModalData = (props) => {
  const classes = useStyles();
  const formikRef = React.useRef(null);

  const [activeStep, setActiveStep] = React.useState(0);
  const [moreInfoOpen, setMoreInfoOpen] = React.useState(false);
  const [isInfoError, setIsInfoError] = React.useState(false);
  const [isPaymentError, setIsPaymentError] = React.useState(false);
  const [isPaymentSuccess, setIsPaymentSuccess] = React.useState(false);
  const [userData, setUserData] = React.useState();
  const [values, setValues] = React.useState({
    accountNumber: "",
    dob: null
  });
  const [cardDetails, setCardDetails] = React.useState({
    cardNumber: "",
    cvv: "",
    expiry: null,
    zip: ""
  });
  const [newUserData, setNewUserData] = React.useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    zip: "",
    dob: values.dob
  });

  const handleClose = () => {
    setActiveStep(0);
    setIsPaymentSuccess(false);
    setIsPaymentError(false);
    setMoreInfoOpen(false);
    setIsInfoError(false);
    props.closePaymentModal();
    setNewUserData({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      zip: "",
      dob: null
    });
    setCardDetails({
      cardNumber: "",
      cvv: "",
      expiry: null,
      zip: ""
    });
    setValues({
      accountNumber: "",
      dob: null
    });
  };
  const steps = getSteps();

  const preventCopyPaste = (e) => {
    e.preventDefault();
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const formatCardNumber = (value) => {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
    const onlyNumbers = value.replace(/[^\d]/g, "");

    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter((group) => !!group).join("-")
    );
  };

  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return !isInfoError && moreInfoOpen ? (
          <MoreInformation />
        ) : isInfoError && !moreInfoOpen ? (
          <UserNotFoundError />
        ) : (
          <MakePayment />
        );
      case 1:
        return <PaymentDue />;
      case 2:
        return !isPaymentError && isPaymentSuccess ? (
          <PaymentSucess />
        ) : isPaymentError && !isPaymentSuccess ? (
          <PaymentError />
        ) : (
          <PaymentReview />
        );
    }
  }

  const MakePayment = () => {
    const submitUserAccountDetails = (values) => {
      let payload = {
        accountNumber: values.accountNumber,
        dob: moment(values.dob).format("yyyy-MM-DD")
      };
      setValues({ ...payload, dob: values.dob });
      props.userDetailsFunction(
        payload,
        (res) => {
          if (res.status == true) {
            setUserData(res.data[0]);
            handleNext();
            setMoreInfoOpen(false);
          } else if (res.status == false) {
            setMoreInfoOpen(true);
          }
        },
        (err) => {}
      );
    };
    return (
      <>
        <Box>
          <Typography className={classes.mainHeading}>
            Make a payment.
          </Typography>
          <Typography className={classes.mainText}>
            Whether you prefer to set up monthly payments or make a one-time
            payment, we take your security seriously. All you need is your
            account number, date of birth and credit or debit card.
          </Typography>
        </Box>
        <Box style={{ width: "100%" }}>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
            <Formik
              innerRef={(r) => {
                formikRef.current = r;
              }}
              enableReinitialize
              onSubmit={submitUserAccountDetails}
              initialValues={{ ...values }}
              validationSchema={Validations.checkUserDetails}
            >
              {({ values, setFieldValue, resetForm, errors, touched }) => (
                <>
                  <Form>
                    <Grid style={{ marginBottom: "20px" }}>
                      <InputLabel
                        htmlFor="accountNumber"
                        className={classes.mainText}
                        style={{ marginBottom: "10px" }}
                      >
                        Account Number*
                      </InputLabel>
                      <FormControl fullWidth>
                        <Field
                          as={TextField}
                          id="accountNumber"
                          variant="outlined"
                          fullWidth
                          required
                          name="accountNumber"
                          onChange={(e) =>
                            (/^[0-9]\d*$/g.test(e.target.value) ||
                              e.target.value == "") &&
                            setFieldValue("accountNumber", e.target.value)
                          }
                          error={errors.accountNumber && touched.accountNumber}
                          helperText={
                            touched.accountNumber && errors.accountNumber
                              ? errors.accountNumber
                              : ""
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid style={{ marginBottom: "20px" }}>
                      <InputLabel
                        htmlFor="dob"
                        className={classes.mainText}
                        style={{ marginBottom: "10px" }}
                      >
                        Date of Birth*
                      </InputLabel>
                      <FormControl fullWidth>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Field
                            as={KeyboardDatePicker}
                            required
                            id="dob"
                            format="MM/dd/yyyy"
                            variant="outlined"
                            placeholder="MM/DD/YYYY"
                            fullWidth
                            name="dob"
                            onChange={(value) => {
                              setFieldValue("dob", value);
                            }}
                            KeyboardButtonProps={{
                              size: "small"
                            }}
                            maxDate={new Date()}
                            error={errors.dob && touched.dob}
                            helperText={
                              touched.dob && errors.dob ? errors.dob : ""
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    </Grid>
                    <Grid style={{ marginTop: "1rem" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItmes: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.previous}
                          style={{
                            display: activeStep === 0 ? "none" : "block"
                          }}
                        >
                          previous
                        </Button>
                        <Button
                          type="submit"
                          variant="h5"
                          className={classes.next}
                          disabled={
                            values.accountNumber == "" || values.dob == null
                          }
                        >
                          Next
                        </Button>
                      </div>
                    </Grid>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </Box>
      </>
    );
  };

  const MoreInformation = () => {
    const updateNewUserDetails = (values) => {
      let data = {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone,
        email: values.email,
        zip: values.zip,
        dob: values.dob
      };
      setNewUserData({ ...data });
      if (userData) {
        handleNext();
      } else {
        setIsInfoError(true);
      }
    };

    return (
      <>
        <Box>
          <Typography className={classes.mainHeading}>
            It looks like we need some more information.
          </Typography>
          <Typography className={classes.mainText}>
            We were unable to find your account with the information provided.
            Please continue filling out the form to look up your account
            details.
          </Typography>
        </Box>
        <Box style={{ width: "100%" }}>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
            <Formik
              innerRef={(r) => {
                formikRef.current = r;
              }}
              enableReinitialize
              onSubmit={updateNewUserDetails}
              initialValues={{ ...newUserData }}
              validationSchema={Validations.updateUserDetails}
            >
              {({ values, setFieldValue, resetForm, errors, touched }) => (
                <>
                  <Form>
                    <Grid
                      style={{
                        marginBottom: "20px",
                        justifyContent: "space-between"
                      }}
                      container
                    >
                      <Grid style={{ width: "48%" }}>
                        <InputLabel
                          htmlFor="firstName"
                          className={classes.mainText}
                          style={{ marginBottom: "10px" }}
                        >
                          First Name*
                        </InputLabel>
                        <FormControl fullWidth>
                          <TextField
                            id="firstName"
                            variant="outlined"
                            fullWidth
                            required
                            name="firstName"
                            onChange={(e) =>
                              setFieldValue("firstName", e.target.value)
                            }
                            error={errors.firstName && touched.firstName}
                            helperText={
                              touched.firstName && errors.firstName
                                ? errors.firstName
                                : ""
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid style={{ width: "48%" }}>
                        <InputLabel
                          htmlFor="lastName"
                          className={classes.mainText}
                          style={{ marginBottom: "10px" }}
                        >
                          Last Name*
                        </InputLabel>
                        <FormControl fullWidth>
                          <TextField
                            id="lastName"
                            variant="outlined"
                            fullWidth
                            required
                            name="lastName"
                            onChange={(e) =>
                              setFieldValue("lastName", e.target.value)
                            }
                            error={errors.lastName && touched.lastName}
                            helperText={
                              touched.lastName && errors.lastName
                                ? errors.lastName
                                : ""
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginBottom: "20px" }}>
                      <InputLabel
                        htmlFor="phoneNumber"
                        className={classes.mainText}
                        style={{ marginBottom: "10px" }}
                      >
                        Phone Number*
                      </InputLabel>
                      <FormControl fullWidth>
                        <TextField
                          id="phoneNumber"
                          variant="outlined"
                          fullWidth
                          required
                          name="phoneNumber"
                          onChange={(e) =>
                            (/^[0-9]\d*$/g.test(e.target.value) ||
                              e.target.value == "") &&
                            setFieldValue("phoneNumber", e.target.value)
                          }
                          error={errors.phoneNumber && touched.phoneNumber}
                          helperText={
                            touched.phoneNumber && errors.phoneNumber
                              ? errors.phoneNumber
                              : ""
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid style={{ marginBottom: "20px" }}>
                      <InputLabel
                        htmlFor="email"
                        className={classes.mainText}
                        style={{ marginBottom: "10px" }}
                      >
                        Email*
                      </InputLabel>
                      <FormControl fullWidth>
                        <TextField
                          id="email"
                          variant="outlined"
                          fullWidth
                          required
                          name="email"
                          type="email"
                          onChange={(e) =>
                            setFieldValue("email", e.target.value)
                          }
                          error={errors.email && touched.email}
                          helperText={
                            touched.email && errors.email ? errors.email : ""
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid style={{ marginBottom: "20px" }}>
                      <InputLabel
                        htmlFor="dob"
                        className={classes.mainText}
                        style={{ marginBottom: "10px" }}
                      >
                        Date of Birth
                      </InputLabel>
                      <FormControl fullWidth>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <Field
                            as={KeyboardDatePicker}
                            id="dob"
                            format="MM/dd/yyyy"
                            variant="outlined"
                            placeholder="MM/DD/YYYY"
                            fullWidth
                            name="dob"
                            onChange={(value) => {
                              setFieldValue("dob", value);
                            }}
                            KeyboardButtonProps={{
                              size: "small"
                            }}
                            maxDate={new Date()}
                            error={errors.dob && touched.dob}
                            helperText={
                              touched.dob && errors.dob ? errors.dob : ""
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </FormControl>
                    </Grid>
                    <Grid style={{ marginBottom: "20px" }}>
                      <InputLabel
                        htmlFor="zip"
                        className={classes.mainText}
                        style={{ marginBottom: "10px" }}
                      >
                        ZIP Code
                      </InputLabel>
                      <FormControl fullWidth>
                        <TextField
                          id="zip"
                          variant="outlined"
                          fullWidth
                          name="zip"
                          onChange={(e) =>
                            (/^[0-9]\d*$/g.test(e.target.value) ||
                              e.target.value == "") &&
                            setFieldValue("zip", e.target.value)
                          }
                          error={errors.zip && touched.zip}
                          helperText={
                            touched.zip && errors.zip ? errors.zip : ""
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid style={{ marginTop: "1rem" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItmes: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <Button
                          onClick={() => setMoreInfoOpen(false)}
                          className={classes.previous}
                        >
                          Previous
                        </Button>
                        <Button
                          type="submit"
                          variant="h5"
                          className={classes.next}
                        >
                          Next
                        </Button>
                      </div>
                    </Grid>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </Box>
      </>
    );
  };

  const UserNotFoundError = () => {
    return (
      <Box className={classes.customBox}>
        <Typography className={classes.mainHeading}>
          We were unable to find your information in our system.
        </Typography>
        <Typography className={classes.mainText}>
          Please call our customer care team to complete your payment.
        </Typography>
        <Button
          className={classes.next}
          style={{ marginLeft: "unset", marginTop: "1rem" }}
        >
          Call 123435243
        </Button>
      </Box>
    );
  };

  const PaymentDue = () => {
    const handleCardDetails = (values) => {
      let payload = {
        cardNumber: values.cardNumber,
        cvv: values.cvv,
        expiry: moment(values.expiry).format("MM/YY"),
        zip: values.zip
      };
      setCardDetails({
        ...payload,
        expiry: values.expiry
      });
      handleNext();
      // props.cardDetails(
      //   payload,
      //   (res) => {
      //     if (res.status == true) {
      //       setUserData(res.data[0]);
      //       handleNext();
      //       setMoreInfoOpen(false);
      //     } else if (res.status == false) {
      //       setMoreInfoOpen(true);
      //     }
      //   },
      //   (err) => {
      //   }
      // );
    };
    return (
      <>
        <Box>
          <Typography className={classes.mainHeading}>
            ${userData.amount} payment due
          </Typography>
          <Typography className={classes.payText}>
            next payment due{" "}
            {moment(userData.nextPyamentMonth).format("MM/DD/yyyy")}
          </Typography>
        </Box>
        <Box style={{ width: "100%" }}>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
            <Formik
              innerRef={(r) => {
                formikRef.current = r;
              }}
              enableReinitialize
              onSubmit={handleCardDetails}
              initialValues={{ ...cardDetails }}
              validationSchema={Validations.updateCardDetails}
            >
              {({ values, setFieldValue, resetForm, errors, touched }) => (
                <>
                  <Form>
                    <Grid style={{ marginBottom: "20px" }}>
                      <InputLabel
                        htmlFor="cardNumber"
                        className={classes.mainText}
                        style={{ marginBottom: "10px" }}
                      >
                        Card Number*
                      </InputLabel>
                      <FormControl fullWidth>
                        <TextField
                          id="cardNumber"
                          variant="outlined"
                          fullWidth
                          required
                          name="cardNumber"
                          onCut={preventCopyPaste}
                          onCopy={preventCopyPaste}
                          onPaste={preventCopyPaste}
                          value={formatCardNumber(values.cardNumber)}
                          onChange={(e) =>
                            setFieldValue("cardNumber", e.target.value)
                          }
                          inputProps={{ maxLength: 19, minLength: 19 }}
                          error={errors.cardNumber && touched.cardNumber}
                          helperText={
                            touched.cardNumber && errors.cardNumber
                              ? errors.cardNumber
                              : ""
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid
                      style={{
                        marginBottom: "20px",
                        justifyContent: "space-between"
                      }}
                      container
                    >
                      <Grid style={{ width: "32%" }}>
                        <InputLabel
                          htmlFor="expiryDate"
                          className={classes.mainText}
                          style={{ marginBottom: "10px" }}
                        >
                          MM / YY*
                        </InputLabel>
                        <FormControl fullWidth>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Field
                              as={KeyboardDatePicker}
                              required
                              id="expiry"
                              views={["month", "year"]}
                              format="MM/yy"
                              variant="outlined"
                              placeholder="MM/YY"
                              fullWidth
                              name="expiry"
                              onChange={(value) =>
                                setFieldValue("expiry", value)
                              }
                              KeyboardButtonProps={{
                                size: "small"
                              }}
                              error={errors.expiry && touched.expiry}
                              helperText={
                                touched.expiry && errors.expiry
                                  ? errors.expiry
                                  : ""
                              }
                            />
                          </MuiPickersUtilsProvider>
                        </FormControl>
                      </Grid>
                      <Grid style={{ width: "32%" }}>
                        <InputLabel
                          htmlFor="cvv"
                          className={classes.mainText}
                          style={{ marginBottom: "10px" }}
                        >
                          CVV*
                        </InputLabel>
                        <FormControl fullWidth>
                          <TextField
                            id="cvv"
                            variant="outlined"
                            fullWidth
                            required
                            name="cvv"
                            type="password"
                            value={values.cvv}
                            onCut={preventCopyPaste}
                            onCopy={preventCopyPaste}
                            onPaste={preventCopyPaste}
                            onChange={(e) =>
                              (/^[0-9]\d*$/g.test(e.target.value) ||
                                e.target.value == "") &&
                              setFieldValue("cvv", e.target.value)
                            }
                            inputProps={{ maxLength: 3, minLength: 3 }}
                            error={errors.cvv && touched.cvv}
                            helperText={
                              touched.cvv && errors.cvv ? errors.cvv : ""
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid style={{ width: "32%" }}>
                        <InputLabel
                          htmlFor="zip"
                          className={classes.mainText}
                          style={{ marginBottom: "10px" }}
                        >
                          Zip Code*
                        </InputLabel>
                        <FormControl fullWidth>
                          <TextField
                            id="zip"
                            variant="outlined"
                            fullWidth
                            required
                            name="zip"
                            value={values.zip}
                            inputProps={{ maxLength: 7, minLength: 5 }}
                            onChange={(e) =>
                              (/^[0-9]\d*$/g.test(e.target.value) ||
                                e.target.value == "") &&
                              setFieldValue("zip", e.target.value)
                            }
                            error={errors.zip && touched.zip}
                            helperText={
                              touched.zip && errors.zip ? errors.zip : ""
                            }
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Grid style={{ marginTop: "1rem" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItmes: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <Button
                          onClick={handleBack}
                          className={classes.previous}
                        >
                          previous
                        </Button>
                        <Button
                          type="submit"
                          variant="h5"
                          className={classes.next}
                        >
                          Next
                        </Button>
                      </div>
                    </Grid>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </Box>
      </>
    );
  };

  const PaymentReview = () => {
    const textInput = React.useRef();
    const [editableData, setEditableData] = React.useState({
      name: userData.name,
      address: userData.address,
      amount: userData.amount
    });
    const [isEdit, setIsEdit] = React.useState(false);
    const [amountError, setAmountError] = React.useState(false);

    const handlePayment = (values) => {
      const data = {
        name: values.name,
        address: values.address,
        amount: values.amount
      };
      setIsPaymentSuccess(true);
    };
    const last4CardNo = cardDetails.cardNumber.substring(
      cardDetails.cardNumber.length - 4
    );

    const handleEdit = () => {
      setIsEdit(true);
      setTimeout(() => {
        textInput.current.focus();
      }, 100);
    };

    return (
      <>
        <Box>
          <Typography className={classes.mainHeading}>Review</Typography>
          <Typography className={classes.mainText}>
            By clicking submit payment, you agree to Aylo Health’s{" "}
            <span className={classes.linkText}> Terms of Service </span> and{" "}
            <span className={classes.linkText}> Privacy Policy. </span>
          </Typography>
        </Box>
        <Box style={{ width: "100%" }}>
          <div className={classes.root}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel />
                </Step>
              ))}
            </Stepper>
            <Formik
              innerRef={(r) => {
                formikRef.current = r;
              }}
              enableReinitialize
              onSubmit={handlePayment}
              initialValues={{ ...editableData }}
              validationSchema={Validations.payReview}
            >
              {({ values, setFieldValue, resetForm, errors, touched }) => (
                <>
                  <Form>
                    <Box className={classes.reviewBox}>
                      <Box className={classes.reviewCustomBox}>
                        <Box className={classes.customBoxRow}>
                          <Typography
                            className={classes.payText}
                            style={{ color: "black" }}
                          >
                            Payment
                          </Typography>
                          <Typography
                            className={classes.payText}
                            style={{ color: "black", cursor: "pointer" }}
                            onClick={() => handleBack()}
                          >
                            Edit
                          </Typography>
                        </Box>
                        <Box className={classes.customBoxRow}>
                          <Typography
                            className={classes.mainText}
                            style={{ color: "black" }}
                          >
                            XXXX-{last4CardNo}
                          </Typography>
                          <Typography
                            className={classes.mainText}
                            style={{ color: "black" }}
                          >
                            {moment(cardDetails.expiry).format("MM/YY")}
                          </Typography>
                        </Box>
                      </Box>
                      <Box className={classes.reviewCustomBox}>
                        <ThemeProvider ThemeProvider theme={infoTheme}>
                          <Box className={classes.customBoxRow}>
                            <Typography
                              className={classes.payText}
                              style={{ color: "black" }}
                            >
                              Billing Address
                            </Typography>
                            {!isEdit && (
                              <Typography
                                className={classes.payText}
                                style={{ color: "black", cursor: "pointer" }}
                                onClick={() => handleEdit()}
                              >
                                Edit
                              </Typography>
                            )}
                            {isEdit && (
                              <Typography
                                className={classes.payText}
                                style={{ color: "black" }}
                                onClick={() => setIsEdit(false)}
                              >
                                Cancel
                              </Typography>
                            )}
                          </Box>
                          <Box
                            className={classes.customBoxRow}
                            style={{ alignItems: "unset" }}
                          >
                            <Typography
                              className={classes.mainText}
                              style={{ color: "black" }}
                            >
                              Name
                            </Typography>
                            {!isEdit && (
                              <Typography
                                className={classes.mainText}
                                style={{ color: "black", textAlign: "end" }}
                              >
                                {userData.name}
                              </Typography>
                            )}
                            {isEdit && (
                              <TextField
                                id="name"
                                name="name"
                                value={values.name}
                                onChange={(e) =>
                                  setFieldValue("name", e.target.value)
                                }
                                multiline
                                inputRef={textInput}
                                required
                                error={errors.name && touched.name}
                                helperText={
                                  touched.name && errors.name ? errors.name : ""
                                }
                              />
                            )}
                          </Box>
                          <Box
                            className={classes.customBoxRow}
                            style={{ alignItems: "unset" }}
                          >
                            <Typography
                              className={classes.mainText}
                              style={{ color: "black" }}
                            >
                              Address
                            </Typography>
                            {!isEdit && (
                              <Typography
                                className={classes.mainText}
                                style={{ color: "black", textAlign: "end" }}
                              >
                                {userData.address}
                              </Typography>
                            )}
                            {isEdit && (
                              <TextField
                                id="address"
                                name="address"
                                value={values.address}
                                onChange={(e) =>
                                  setFieldValue("address", e.target.value)
                                }
                                multiline
                                required
                                error={errors.address && touched.address}
                                helperText={
                                  touched.address && errors.address
                                    ? errors.address
                                    : ""
                                }
                              />
                            )}
                          </Box>
                        </ThemeProvider>
                      </Box>

                      <Box className={classes.reviewDueBox}>
                        <ThemeProvider ThemeProvider theme={amountTheme}>
                          <Box
                            className={classes.reviewCustomBox}
                            style={{
                              alignItems: "center"
                            }}
                          >
                            <Typography
                              className={classes.payText}
                              style={{ color: "black" }}
                            >
                              Total Due today
                            </Typography>
                            <TextField
                              id="amount"
                              name="amount"
                              value={values.amount}
                              onChange={(e) => {
                                if (
                                  /^\d+(\.\d{0,2})?$/g.test(e.target.value) ||
                                  e.target.value == ""
                                ) {
                                  setAmountError(false);
                                  if (e.target.value > userData.amount) {
                                    setAmountError(true);
                                    setFieldValue("amount", e.target.value);
                                  } else if (e.target.value > 0) {
                                    setFieldValue("amount", e.target.value);
                                  } else {
                                    setAmountError(true);
                                    setFieldValue("amount", e.target.value);
                                  }
                                }
                              }}
                              required
                              error={
                                (errors.amount && touched.amount) || amountError
                              }
                              helperText={
                                touched.amount && errors.amount
                                  ? errors.amount
                                  : amountError &&
                                    values.amount > userData.amount
                                  ? "Amount cannot be greater than due amount"
                                  : amountError
                                  ? "Amount should be greater than zero"
                                  : ""
                              }
                            />
                          </Box>
                        </ThemeProvider>
                        <Box
                          className={classes.reviewCustomBox}
                          style={{ alignItems: "center" }}
                        >
                          <Typography
                            className={classes.payText}
                            style={{ color: "black" }}
                          >
                            Next Payment Due
                          </Typography>
                          <Typography
                            className={classes.mainText}
                            style={{ fontSize: "24px", color: "black" }}
                          >
                            {moment(userData.nextPyamentMonth).format(
                              "MM/DD/yyyy"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Grid style={{ marginTop: "1rem" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItmes: "center",
                          justifyContent: "space-between"
                        }}
                      >
                        <Button
                          onClick={handleBack}
                          className={classes.previous}
                        >
                          previous
                        </Button>
                        <Button
                          type="submit"
                          variant="h5"
                          className={classes.payNow}
                          disabled={amountError}
                        >
                          Pay Now
                        </Button>
                      </div>
                    </Grid>
                  </Form>
                </>
              )}
            </Formik>
          </div>
        </Box>
      </>
    );
  };

  const PaymentSucess = () => {
    return (
      <Grid container justifyContent="center" style={{ margin: "2rem 0rem" }}>
        <Box className={classes.customBox}>
          <PaymentConfirmIcon />
          <Typography className={classes.payText} style={{ marginTop: "1rem" }}>
            confirmation #123456790
          </Typography>
          <Typography className={classes.mainHeading}>
            Payment Received
          </Typography>
          <Typography className={classes.payText} style={{ marginTop: "1rem" }}>
            next payment due
          </Typography>
          <Typography className={classes.mainText} style={{ fontSize: "24px" }}>
            {moment(userData.nextPyamentMonth).format("MM/DD/yyyy")}
          </Typography>
        </Box>
      </Grid>
    );
  };

  const PaymentError = () => {
    return (
      <Box className={classes.customBox}>
        <Typography className={classes.mainHeading}>
          We were unable to complete your payment.
        </Typography>
        <Typography className={classes.mainText}>
          Please call our customer care team to complete your payment.
        </Typography>
        <Button
          className={classes.next}
          style={{ marginLeft: "unset", marginTop: "1rem" }}
        >
          Call 123435243
        </Button>
      </Box>
    );
  };

  return (
    <Modal
      open={props.isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        gap: "1.5rem"
      }}
    >
      <ThemeProvider theme={theme}>
        <Backdrop open={props.isOpen} />
        <Paper
          style={{
            maxWidth: "700px",
            width: "85%",
            padding: "1.5rem",
            borderRadius: "20px 0px",
            overflowY: "scroll"
          }}
        >
          <Grid container>
            <Box style={{ width: "100%" }}>
              <Typography>{getStepContent(activeStep)}</Typography>
            </Box>
          </Grid>
        </Paper>
        <IconButton onClick={handleClose}>
          <CloseIcon style={{ color: "#1A4155" }} />
        </IconButton>
      </ThemeProvider>
    </Modal>
  );
};

PaymentModalData.propTypes = {
  userDetailsFunction: PropTypes.func,
  closePaymentModal: PropTypes.func,
  isOpen: PropTypes.bool
};

const mapStateToProps = (app) => ({
  data: app
});
export default connect(mapStateToProps, { userDetailsFunction })(
  PaymentModalData
);
