import React from "react";
import jsPDF from "jspdf";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  margin: {
    margin: "10px",
  },
  mainText: {
    marginBottom: "20px",
    marginLeft: "200px",
    color: "#0C97A1",
  },
  subText: {
    fontSize: "10px",
    whiteSpace: "wrap",
    marginTop: "-10px",
  },
  text: {
    fontSize: "10px",
    whiteSpace: "wrap",
    marginTop: "-10px",
  },
  priceText: {
    color: "#66686D",
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
  },
  focusText: {
    color: "#00B050",
    fontFamily: "Source Sans Pro",
    fontSize: "12px",
    marginTop: "-10px",
  },
  subMainText: {
    fontSize: "13px",
    whiteSpace: "wrap",
    color: "#0C97A1",
  },
});
function PdfComponent(props) {
  const classes = useStyles();
  const options = {
    orientation: "l",
    unit: "px",
    compressPdf: true,
  };

  const download = () => {
    const doc = new jsPDF(options);
    doc.html(document.getElementById("pdf"), {
      callback() {
        doc.save("myDocument.pdf");
        window.open(doc.output("bloburl")); // To debug.
      },
    });
  };

  return (
    <div style={{ backgroundColor: "#fff", padding: "10px" }}>
      <div style={{ display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}>
        <Button onClick={() => download()}>Download pdf</Button>
      </div>
      <div className={classes.margin} id="pdf">
        <div className={classes.mainText}>WELCOME TO  XXXXX HOSPITAL!</div>
        <div className={classes.subText}>Rajil</div>
        <div className={classes.subText}>Congratulations! We're excited to let you know that our loan department has  approved your application and we are</div>
        <div className={classes.subText}>ready to fund your account balance with terms defined below:</div>
        <div className={classes.subMainText}>Loan Details</div>
        <div className={classes.subText}>
          Account Number:
          <span className={classes.priceText}> 12345678</span>
        </div>
        <div className={classes.subText}>
          Loan Proceeds:
          <span className={classes.priceText}> $10,000.00</span>
        </div>
        <div className={classes.subText}>
          Autopayment Amount:
          <span className={classes.priceText}> $346.59</span>
        </div>
        <div className={classes.subText}>
          Autopayment Due Date:
          <span className={classes.priceText}> 11/2/2020</span>
        </div>
        <div className={classes.subMainText}>Account Status</div>
        <div className={classes.subText}>
          There are two ways to view your current account information. Visit
          <span className={classes.focusText}>XXX Hospital.com</span>
          <span className={classes.subText}>, click the Login button and</span>
          <div className={classes.subText}>click Quick View to see details like your account number, next payment and your loan balance. Or, you can visit </div>
          <div className={classes.subText}>
            <span className={classes.focusText}>ThePatientoffer.com</span>
            {" "}
            <span className={classes.subText}>to see more details and make payments.</span>
          </div>
        </div>
        <div className={classes.subText}>
          <a
            style={{
              fontSize: "10px", color: "blue", fontSize: "10px", textDecoration: "underline",
            }}
            href="http://click.email.bestegg.com/?qs=3b7531317b5dce327e5c271d0ae582caf95172375915644d9fa9a2c977ea29254a2496e31f0af420081b78fb290d02ef8971bc86c36cc235"
            title="">
            If this is the first time you've visited AccountInfo.com, you must register. Keep this email handy, you'll need your account number.
          </a>
        </div>
        <div className={classes.subMainText}>Payments</div>
        <div className={classes.subText}>
          You're currently enrolled in autopay and your payment will
          <span className={classes.subText}>automatically</span>
          <span className={classes.subText}> charged to your credit card or be withdrawn </span>
          <div className={classes.subText}>from your account, depending on the option chosen by you. Your payment will post on-time to your loan on your payment</div>
          <div className={classes.subText}> due date, however, it could take 2-3 business days for your payment to show at your bank. You will receive a payment reminder</div>
          <div className={classes.subText}> email ten days before your payment due date.</div>
        </div>
        <div className={classes.subText}>Your first payment will be deducted automatically on 11/2/2020 and then monthly on the same date thereafter.</div>
        <div className={classes.subMainText}>Questions</div>
        <div className={classes.subText}><a style={{ fontSize: "10px", color: "blue", textDecoration: "underline" }} href="http://click.email.bestegg.com/?qs=3b7531317b5dce32b1205b9b79c3975bcde0b525d96e81ed56068ec7bbdc69e6fe268916aa8e6ad5d86893b74c6b6c1afa4a94e7e7f13663" title="">Still have questions? Visit our Help Center, or give us a call at 855-555-5555 to speak with an Agent.</a></div>
        <div className={classes.subText}>Sincerely</div>
        <div className={classes.subText}>The XXXX Hospital</div>
      </div>
    </div>
  );
}

PdfComponent.propTypes = {};

export default PdfComponent;
