/* eslint-disable no-param-reassign */
import { combineReducers } from "redux";
import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";
import app from "./LoginReducer";
import table from "./Table";
import provideRegistration from "./ProviderRegistration";
import roles from "./RoleReducer";
import userRole from "./AppReducer";
import users from "./UserReducer";
import genrateOfferPatient from "./genrateOfferPatient";
import patientDashboard from "./PatientDashboard";
import dashboard from "./Dashboard";
import onBoarding from "./OnBoarding";
import paymentReducer from "./PaymentReducer";

const appReducer = combineReducers({
  userRole,
  app,
  table,
  provideRegistration,
  genrateOfferPatient,
  roles,
  onBoarding,
  users,
  patientDashboard,
  dashboard,
  paymentReducer
});

const rootReducer = (state, action) => {
  if (action.type === types.REST_REDUCER_STATE) {
    const common = state.app.defaultTheme;
    state = initialState;
    state.app.defaultTheme = common;
  }
  return appReducer(state, action);
};

export default rootReducer;
