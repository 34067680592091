/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-useless-path-segments */
import * as types from "./ActionTypes";
import { get } from "../Helper/index";
import * as urls from "../../Uintls/Urls";

export const loginRequest = (data) => (dispatch) => {
  dispatch({ type: types.LOGIN_API });
  get({
    url: `${urls.LOGIN_URL}`,
    success: types.SET_LOGIN,
    failure: types.SET_ERROR,
    dispatch,
    body: data
  });
};

export const getUserList = (data) => (dispatch) => {
  dispatch({ type: types.USER_LIST_CALL });
  get({
    url: urls.USER_LIST_URL,
    success: types.USER_LIST_SUCCESS,
    failure: types.USER_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getOtpCall = (data) => (dispatch) => {
  dispatch({ type: types.GET_OTP_CALL });
  get({
    url: `${urls.LOGIN_OTP_URL}`,
    success: types.GET_OTP_SUCCESS,
    failure: types.GET_OTP_ERROR,
    dispatch,
    body: data
  });
};

export const clientThemeCall = () => (dispatch) => {
  dispatch({ type: types.CLIENT_THEME_CALL });
  get({
    url: `${urls.PROVIDERS_PORTAL_THEME_URL}`,
    success: types.SET_CLIENT_THEME,
    failure: types.CLIENT_THEME_ERROR,
    dispatch,
    body: false
  });
};

export const setUserRole = (data) => ({
  type: types.SET_USER_ROLE,
  data
});

export const logout = () => ({
  type: types.SET_LOUGOUT
});

export const setAppBarName = (data) => ({
  type: types.SET_NAME_APPBAR,
  data
});

export const setLayoutName = (data) => ({
  type: types.SET_LAYOUT_IN_APP,
  data
});

export const setSendClientIdInApi = (data) => ({
  type: types.SET_CLIENT_API_LIST,
  data
});

export const clientPortalConfig = () => (dispatch) => {
  dispatch({ type: types.CLIENT_PORTAL_CONFIG_CALL });
  get({
    url: `${urls.PROVIDER_PANEL_DATA_URL}`,
    success: types.CLIENT_PORTAL_CONFIG_SUCCESS,
    failure: types.CLIENT_PORTAL_CONFIG_ERROR,
    dispatch,
    body: false
  });
};

export const getUserLeftMenuCall = () => (dispatch) => {
  dispatch({ type: types.USER_LEFT_MENU_CALL });
  get({
    url: `${urls.USER_LEFT_MENU_URL}`,
    success: types.USER_LEFT_MENU_SUCCESS,
    failure: types.USER_LEFT_MENU_ERROR,
    dispatch,
    body: false
  });
};
