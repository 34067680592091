/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Card from "@material-ui/core/Card";
// import Card from "components/Card/Card";
import Store from "@material-ui/icons/Store";
import { Box, Grid, Typography } from "@material-ui/core";
import CardHeader from "components/Card/CardHeader";
import CardIcon from "components/Card/CardIcon";
import CardFooter from "components/Card/CardFooter";
import CardBody from "components/Card/CardBody";
import Select from "@material-ui/core/Select";
import Accessibility from "@material-ui/icons/Accessibility";
import RippleLoading from "components/RippleCard/Index";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  getAllActivePlanList,
  getOpenFundingAmount,
  getPendingOfferAmount,
  getPaymentStatus,
  getOfferAccountStatus,
  getOriginalLoanTerm,
  getRemainingFundingGraph,
  getPaymentDetailsList,
  getOfferDetailsPatient
} from "Redux/Actions/PatientDashboard";
import Ripple from "components/RippleEffect/Ripple";
import Button from "components/CustomButtons/Button";
import { Link } from "react-router-dom";
import styles from "./Dashboard.style";
import RemainingFund from "../../components/Graph/remainingFund";
import VisaImage from "../../assets/img/visa.png";
import MasterImage from "../../assets/img/masterCard.png";
import DailyDeposits from "./DailyDeposits";
import patientDashboard from "assets/jss/material-dashboard-react/views/patientDashboard";
import { IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { styled, alpha } from "@material-ui/core/styles";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5)
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        )
      }
    }
  }
}));

const options = ["None", "Atria", "Callisto", "Dione"];

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(patientDashboard);

const Patients = (props) => {
  const classes = useStyles();
  const [allActiveOOffer, setAllActiveOOffer] = React.useState(
    props.tableData.activePlanPatientListlse &&
      props.tableData.activePlanPatientListlse.data[0].patientId
  );
  const [initailCall, setInitailCall] = React.useState(false);
  const [callOfferIdAfter, setCallOfferIdAfter] = React.useState(false);
  const [remainingFundData, setRemainingFundData] = React.useState(
    props.tableData.remainingFundingData
  );
  const [prePaymentList, setPrePaymentList] = React.useState(
    props.tableData.paymentList
  );
  const [preOfferDetails, setPreOfferDetails] = React.useState(
    props.tableData.offetDetailsPatient
  );
  const [values, setValues] = React.useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!initailCall) {
    const request = {
      userId: localStorage.getItem("userId")
        ? localStorage.getItem("userId")
        : props.userDetails.UserID,
      ClientId: localStorage.getItem("sendApiClientId")
        ? JSON.stringify(localStorage.getItem("sendApiClientId"))
        : JSON.stringify(localStorage.getItem("ClientId"))
      // props.userDetails.Role_Permission_Data.ProviderId,
    };
    props.getAllActivePlanList(request);
    props.getOpenFundingAmount(request);
    props.getPendingOfferAmount(request);
    props.getRemainingFundingGraph(request);
    setInitailCall(true);
  }
  if (
    !callOfferIdAfter &&
    props.tableData.activePlanPatientList &&
    props.tableData.activePlanPatientList.status &&
    props.tableData.activePlanPatientList.data[0].offerId
  ) {
    const offerIdValueData = {
      offerId: props.tableData.activePlanPatientList.data[0].offerId
    };
    setValues(props.tableData.activePlanPatientList.data[0].offerId);
    props.getPaymentStatus(offerIdValueData);
    props.getOriginalLoanTerm(offerIdValueData);
    props.getOfferAccountStatus(offerIdValueData);
    setCallOfferIdAfter(true);
  }
  React.useEffect(() => {
    if (remainingFundData !== props.tableData.remainingFundingData) {
      setRemainingFundData(props.tableData.remainingFundingData);
    }
    if (prePaymentList !== props.tableData.paymentList) {
      setPrePaymentList(props.tableData.paymentList);
      if (
        props.tableData.paymentList &&
        props.tableData.paymentList.status &&
        props.tableData.offetDetailsPatient &&
        props.tableData.offetDetailsPatient.status
      ) {
        props.history.push("/patient/patientPaymentDetails");
      }
    }
    if (preOfferDetails !== props.tableData.offetDetailsPatient) {
      setPreOfferDetails(props.tableData.offetDetailsPatient);
      if (
        props.tableData.paymentList &&
        props.tableData.paymentList.status &&
        props.tableData.offetDetailsPatient &&
        props.tableData.offetDetailsPatient.status
      ) {
        props.history.push("/patient/patientPaymentDetails");
      }
    }
  }, [props]);

  const handlePaymentChange = () => {
    const paymentListPatient = {
      patientId: props.tableData.activePlanPatientList.data[0].patientId
    };
    const offerDetailsdata = {
      offerId: values
    };
    props.getPaymentDetailsList(paymentListPatient);
    props.getOfferDetailsPatient(offerDetailsdata);
  };

  const onChange = (value) => {
    const offerIdValue = {
      offerId: value
    };
    props.getPaymentStatus(offerIdValue);
    props.getOriginalLoanTerm(offerIdValue);
    props.getOfferAccountStatus(offerIdValue);
    setValues(value);
  };

  const hexToRgb = (input) => {
    input += "";
    input = input.replace("#", "");
    const hexRegex = /[0-9A-Fa-f]/g;
    if (!hexRegex.test(input) || (input.length !== 3 && input.length !== 6)) {
      throw new Error("input is not a valid hex color.");
    }
    if (input.length === 3) {
      const first = input[0];
      const second = input[1];
      const last = input[2];
      input = first + first + second + second + last + last;
    }
    input = input.toUpperCase();
    const first = input[0] + input[1];
    const second = input[2] + input[3];
    const last = input[4] + input[5];
    return `${parseInt(first, 16)}, ${parseInt(second, 16)}, ${parseInt(
      last,
      16
    )}`;
  };

  // const fundAmount = props.tableData && props.tableData.openFundingData.map(({ name }) => name);

  // const renderListOfUserNames = (names) => {
  //   return names.map((name) => <li>{name}</li>);
  // };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={8} md={8} xs={12}>
          <Grid
            item
            xs
            className={classes.margin}
            style={{
              paddingBottom: "0px"
            }}
          >
            <Card
              style={{
                borderRadius: "10px"
              }}
            >
              {/* {props.dashboardData.loading ? (
            <RippleLoading />
          ) : ( */}
              <DailyDeposits
              // {...props}
              />
              {/* )} */}
            </Card>
          </Grid>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Grid
            style={{
              marginBottom: "25px"
            }}
          >
            <Card
              style={{
                borderRadius: "10px"
              }}
            >
              <CardHeader
                className={classes.duesGrid}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: "12px",
                  paddingTop: "12px"
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold"
                  }}
                  noWrap
                  className={classes.cardTitle}
                >
                  Current Balance
                </Typography>
              </CardHeader>

              <CardBody>
                <Typography className={classes.typographyAmmount}>
                  Open Funding Accounts
                </Typography>
                <Typography className={classes.typographyCount}>
                  {props.tableData.openFundingData.totalFundAmount}
                </Typography>
              </CardBody>

              <hr
                style={{
                  opacity: 0.3
                }}
              ></hr>
              <CardFooter>
                <div
                  className={classes.viewDetail}
                  align="left"
                  // onClick={(event) => props.handleChange(event, 1)}
                >
                  View Balance Details
                  {/* <ArrowForwardIosIcon /> */}
                </div>
              </CardFooter>
            </Card>
          </Grid>
          <Grid>
            <Card
              style={{
                borderRadius: "10px"
              }}
            >
              <CardHeader
                className={classes.duesGrid}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingBottom: "12px",
                  paddingTop: "12px"
                }}
              >
                <Typography
                  style={{
                    fontWeight: "bold"
                  }}
                  noWrap
                  className={classes.cardTitle}
                >
                  Pending Offers
                </Typography>
              </CardHeader>
              <CardBody>
                <Typography className={classes.typographyAmmount}>
                  Multiple Offers Available
                </Typography>
                <Typography className={classes.typographyCount}>
                  {props.tableData.patientOfferAmountData.pendingAmount}
                </Typography>
              </CardBody>

              {/* {renderListOfUserNames(fundAmount)} */}
              <hr
                style={{
                  opacity: 0.3
                }}
              ></hr>
              <CardFooter>
                <div
                  className={classes.viewDetail}
                  align="left"
                  // onClick={(event) => props.handleChange(event, 1)}
                >
                  View Offer Details
                  {/* <ArrowForwardIosIcon /> */}
                </div>
              </CardFooter>
            </Card>
          </Grid>

          {/* <div style={{ padding: "1%", width: "60%" }}> */}
          {/* {props.tableData.openFundingData ? (
            <Card>
              <CardHeader color="primary" stats icon>
                <CardIcon color="primary">
                  <Store />
                </CardIcon>
                <h3 className={classes.cardTitle}>
                  {`${props.tableData.openFundingData.totalCount}`}
                </h3>
              </CardHeader>
              <CardBody>
                <h3 className={classes.cardTitle}>
                  {`${props.tableData.openFundingData.totalFundAmount}`}
                </h3>
                <Typography
                  style={{ color: "#c3cbc3", fontSize: "16px" }}
                  align="center"
                >
                  Open Funding Accounts
                </Typography>
              </CardBody>
              <CardFooter stats>
                <Link to="/patient/OpenFunding?redirected=Dashboard">
                  View Details
                </Link>
              </CardFooter>
            </Card>
          ) : (
            <RippleLoading />
          )} */}
          {/* </div> */}
        </Grid>

        <Grid item lg={12} md={12} xs={12}>
          <Card
            style={{
              borderRadius: "10px"
            }}
          >
            <CardHeader
              // className={classes.duesGrid}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingBottom: "0px",
                paddingTop: "15px",
                paddingLeft: "40px",
                paddingRight: "40px"
              }}
            >
              <Typography noWrap className={classes.cardTitle}>
                <div
                  style={{
                    color: "#333333",
                    textTransform: "none",
                    fontWeight: "bold",
                    fontSize: "25px"
                  }}
                >
                  Loan Details
                </div>
              </Typography>
              <Typography noWrap className={classes.cardTitle}>
                <div
                  style={{
                    color: "#CF4270",
                    textTransform: "none",
                    display: "flex",
                    fontSize: "16px"
                  }}
                  id="demo-customized-button"
                  aria-controls={open ? "demo-customized-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  variant=""
                >
                  <ErrorOutlineIcon
                    style={{
                      paddingRight: "5px",
                      fontSize: "18px"
                    }}
                  />{" "}
                  You currently have 2 loans
                </div>
              </Typography>
            </CardHeader>
            <Grid
              style={{
                padding: "35px"
              }}
            >
              <CardBody
                style={{
                  border: "1px solid lightgray",
                  padding: "0px",
                  borderRadius: "10px",
                  borderTopLeftRadius: "30px"
                }}
              >
                {/* <Typography noWrap className={classes.cardTitle}>
                  <Button
                    style={{
                      color: "#333333",
                      border: "1px solid",
                      borderRadius: "25px",
                      textTransform: "none",
                      fontWeight: "bold",
                      fontSize: "20px",
                    }}
                    id="offerId"
                    aria-controls={open ? "demo-customized-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    variant=""
                    disableElevation
                    onClick={handleClick}
                    onChange={(e) => onChange(e.target.value)}
                    value={values}
                    name="offerId"
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Daily Deposits
                  </Button>
                  <StyledMenu
                    id="offerId"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                  >
                    {props.tableData.activePlanPatientList &&
                      props.tableData.activePlanPatientList.data.map((key) => (
                        <MenuItem
                          onClick={handleClose}
                          disableRipple
                          value={key.offerId}
                          style={{
                            display: "flex",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {key.offerNumber} &nbsp;&nbsp;&nbsp;&nbsp;
                          </Typography>

                          <Typography
                            style={{
                              color: "#008768",
                              fontSize: "18px",
                              fontWeight: "bold",
                            }}
                          >
                            {key.amountfunded}
                          </Typography>
                        </MenuItem>
                      ))}
                  </StyledMenu>
                </Typography> */}
                <Grid
                  style={{
                    marginTop: "-12px"
                  }}
                >
                  <Box>
                    <Select
                      defaultValue={JSON.stringify(allActiveOOffer)}
                      id="offerId"
                      disableUnderline
                      style={{
                        fontWeight: "bold",
                        border: "1px solid lightgray",
                        // borderRight: "1px solid",
                        // borderBottomRightRadius: "30px",
                        marginBottom: "40px",
                        marginTop: "-20px",
                        overflow: "hidden",
                        zIndex: 50,
                        marginLeft: "-1px",
                        backgroundColor: "#ffffff",
                        borderTopLeftRadius: "30px",
                        borderTopRightRadius: "30px",
                        borderBottomRightRadius: "30px",
                        borderBottomLeftRadius: "0px"
                      }}
                      value={values}
                      name="offerId"
                      onChange={(e) => onChange(e.target.value)}
                    >
                      {props.tableData.activePlanPatientList &&
                        props.tableData.activePlanPatientList.data.map(
                          (key) => (
                            <MenuItem
                              value={key.offerId}
                              style={{
                                display: "flex"
                              }}
                            >
                              <CardBody
                                style={{
                                  display: "flex",
                                  marginBottom: "0px",
                                  paddingLeft: "110px",
                                  paddingRight: "110px",
                                  paddingTop: "10px",
                                  paddingBottom: "10px"
                                }}
                              >
                                <Typography
                                  style={{
                                    fontSize: "18px",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {key.offerNumber} &nbsp;&nbsp;&nbsp;&nbsp;
                                </Typography>

                                <Typography
                                  style={{
                                    color: "#008768",
                                    fontSize: "18px",
                                    fontWeight: "bold"
                                  }}
                                >
                                  {key.amountfunded}
                                </Typography>
                              </CardBody>
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </Box>
                </Grid>
                <Grid
                  container
                  spacing={3}
                  style={{
                    paddingLeft: "30px",
                    paddingRight: "30px",
                    marginBottom: "10px"
                  }}
                >
                  <Grid item lg={4} md={4} xs={12}>
                    {props.tableData.offerAccountStatusData ? (
                      <Card
                        style={{
                          color: "#ffffff",
                          backgroundColor: "#0079BD"
                        }}
                      >
                        <CardBody>
                          <Typography className={classes.typographyAmmount}>
                            Monthly Payment
                          </Typography>
                          <Typography className={classes.typographyCount}>
                            {`$${
                              props.tableData.offerAccountStatusData &&
                              props.tableData.offerAccountStatusData.data[0]
                                .monthlyPayment
                            }`}
                          </Typography>
                        </CardBody>
                      </Card>
                    ) : (
                      <RippleLoading />
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    {props.tableData.paymentStatusData ? (
                      <Card
                        style={{
                          color: "#ffffff",
                          backgroundColor: "#008768"
                        }}
                      >
                        <CardBody>
                          <Typography className={classes.typographyAmmount}>
                            Payoff Amount
                          </Typography>
                          <Typography className={classes.typographyCount}>
                            {`$${
                              props.tableData.offerAccountStatusData &&
                              props.tableData.offerAccountStatusData.data[0]
                                .payOff
                            }`}
                          </Typography>
                        </CardBody>
                      </Card>
                    ) : (
                      <RippleLoading />
                    )}
                  </Grid>
                  <Grid item lg={4} md={4} xs={12}>
                    {props.tableData.originalLoanTermData ? (
                      <Card
                        style={{
                          color: "#ffffff",
                          backgroundColor: "#3E4E9B"
                        }}
                      >
                        <CardBody>
                          <Typography className={classes.typographyAmmount}>
                            Amount Financed
                          </Typography>
                          <Typography className={classes.typographyCount}>
                            {`$${
                              props.tableData.originalLoanTermData &&
                              props.tableData.originalLoanTermData.data[0]
                                .amountFinanced
                            }`}
                          </Typography>
                        </CardBody>
                      </Card>
                    ) : (
                      <RippleLoading />
                    )}
                  </Grid>
                </Grid>
                {props.tableData.paymentStatusData ? (
                  <Grid container className={classes.noBackgroundColor}>
                    <Grid
                      item
                      xs={12}
                      align="center"
                      style={{
                        fontSize: "18px",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <EventOutlinedIcon
                        style={{
                          paddingRight: "5px"
                        }}
                      />{" "}
                      <div>
                        Next payment due&nbsp;
                        {props.tableData.paymentStatusData.data[0].nextDueDate}
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <Ripple duration={3000} />
                )}
                <CardBody
                  style={{
                    color: "#008768",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  {props.tableData.offerAccountStatusData ? (
                    <Typography
                      className={classes.typographyAmmount}
                      style={{
                        display: "flex",
                        fontWeight: "600"
                      }}
                    >
                      <CheckCircleOutlineIcon
                        style={{
                          paddingRight: "5px"
                        }}
                      />{" "}
                      You paid on&nbsp;
                      {props.tableData.paymentStatusData &&
                        props.tableData.paymentStatusData.data[0]
                          .monthlyPayment}
                    </Typography>
                  ) : (
                    <RippleLoading />
                  )}
                  {props.tableData.paymentStatusData ? (
                    <Typography
                      className={classes.typographyAmmount}
                      style={{
                        color: "#008768",
                        fontWeight: "600"
                      }}
                    >
                      {props.tableData.offerAccountStatusData &&
                        props.tableData.offerAccountStatusData.data[0]
                          .lastPaymentDate}
                    </Typography>
                  ) : (
                    <RippleLoading />
                  )}
                </CardBody>
                <hr
                  style={{
                    marginBottom: "25px",
                    marginTop: "10px",
                    opacity: 0.3
                  }}
                />
                <Grid container spacing={3}>
                  <Grid item lg={6} md={6} xs={12}>
                    <Typography
                      style={{
                        paddingLeft: "25px"
                      }}
                      className={classes.typographyAmmount}
                    >
                      Preferred Payment Method
                    </Typography>
                    <Grid item>
                      <Typography
                        style={{
                          paddingLeft: "25px",
                          display: "flex",
                          fontWeight: "600",
                          fontSize: "18px",
                          marginTop: "15px",
                          marginBottom: "-15px"
                        }}
                      >
                        <img
                          src={
                            props.tableData.paymentStatusData &&
                            (props.tableData.paymentStatusData.data[0]
                              .cardMode === "visa" ||
                              props.tableData.paymentStatusData.data[0]
                                .cardMode === "VISA")
                              ? VisaImage
                              : MasterImage
                          }
                          style={{ width: "15%", marginRight: "20px" }}
                        />{" "}
                        {props.tableData.paymentStatusData &&
                          props.tableData.paymentStatusData.data[0]
                            .lastCardNumber}
                        Citi® Diamond
                        <br /> Preferred Visa Card
                      </Typography>
                      <div
                        style={{
                          fontWeight: "400",
                          paddingLeft: "125px",
                          fontSize: "18px",
                          paddingTop: "8px"
                        }}
                      >
                        Credit • • • 8923
                      </div>
                    </Grid>
                    <Grid item xs align="start">
                      <Typography
                        style={{
                          paddingLeft: "25px",
                          textAlign: "start"
                        }}
                      >
                        <Button
                          style={{
                            color: "#27337D",
                            border: "1px solid",
                            borderRadius: "25px",
                            textTransform: "none",
                            backgroundColor: "#ffffff",
                            marginTop: "15px",
                            marginBottom: "25px",
                            paddingTop: "12px"
                          }}
                          id="demo-customized-button"
                          aria-controls={
                            open ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          variant=""
                          onClick={() => handlePaymentChange()}
                        >
                          Change Payment Method
                        </Button>
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                    style={{
                      paddingRight: "40px"
                    }}
                  >
                    <Grid container className={classes.backgroundColorDiv}>
                      <Grid
                        item
                        xs={6}
                        align="left"
                        className={classes.typographyAmmount}
                      >
                        Down Payment
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        align="right"
                        className={classes.typographyAmmount}
                      >
                        {`$${
                          props.tableData.originalLoanTermData &&
                          props.tableData.originalLoanTermData.data[0]
                            .downPayment
                        }`}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{
                        padding: "10px"
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        align="left"
                        className={classes.typographyAmmount}
                      >
                        Loan Terms
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        align="right"
                        className={classes.typographyAmmount}
                      >
                        {`${
                          props.tableData.originalLoanTermData &&
                          props.tableData.originalLoanTermData.data[0].terms
                        }`}
                      </Grid>
                    </Grid>
                    <Grid container className={classes.backgroundColorDiv}>
                      <Grid
                        item
                        xs={6}
                        align="left"
                        className={classes.typographyAmmount}
                      >
                        Terms Remaining
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        align="right"
                        className={classes.typographyAmmount}
                      >
                        {`${
                          props.tableData.offerAccountStatusData &&
                          props.tableData.offerAccountStatusData.data[0]
                            .balanceTerms
                        }`}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      style={{
                        padding: "10px"
                      }}
                    >
                      <Grid
                        item
                        xs={6}
                        align="left"
                        className={classes.typographyAmmount}
                      >
                        Principal Balance
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        align="right"
                        className={classes.typographyAmmount}
                      >
                        {`$${
                          props.tableData.offerAccountStatusData &&
                          props.tableData.offerAccountStatusData.data[0]
                            .principalBalance
                        }`}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </CardBody>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      {/* <Grid container>
        <Grid item xs={12} sm={6} md={4} style={{ padding: "15px" }}>
          {props.tableData.paymentStatusData ? (
            <Card>
              <CardHeader
                color="info"
                stats
                icon
                style={{ margin: "0px 20px" }}
              >
                <CardIcon
                  color="info"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    float: "none",
                    padding: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Accessibility />
                  <Typography
                    noWrap
                    style={{ color: "#fff" }}
                    className={classes.cardTitle}
                  >
                    Payment Status
                  </Typography>
                </CardIcon>
              </CardHeader>
              <CardBody style={{ height: "240px", padding: "0px" }}>
                <Typography
                  style={{
                    color: "#0B9600",
                    fontSize: "21px",
                    padding: "1rem",
                  }}
                  align="center"
                >
                  {`Dues: $${
                    props.tableData.paymentStatusData &&
                    props.tableData.paymentStatusData.data[0].dues
                  }`}
                </Typography>
                {props.tableData.paymentStatusData ? (
                  <Grid container className={classes.noBackgroundColor}>
                    <Grid item xs={1} />
                    <Grid item xs={6} align="left">
                      Next Due Date&nbsp;
                    </Grid>
                    <Grid item xs={4} align="right">
                      {props.tableData.paymentStatusData.data[0].nextDueDate}
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                ) : (
                  <Ripple duration={3000} />
                )}
                {props.tableData.paymentStatusData && (
                  <Grid container className={classes.backgroundColorDiv}>
                    <Grid item xs={1} />
                    <Grid item xs={6} align="left">
                      Payment Mode&nbsp;
                    </Grid>
                    <Grid item xs={4} align="right">
                      {`${
                        props.tableData.paymentStatusData &&
                        props.tableData.paymentStatusData.data[0].paymentMode
                      }`}
                    </Grid>
                    <Grid item xs={1} />
                  </Grid>
                )}
                <Grid container style={{ paddingTop: "10%" }}>
                  <Grid item xs>
                    <Typography
                      align="center"
                      style={{ color: "#3f51b5", fontWeight: "bold" }}
                    >
                      <img
                        src={
                          props.tableData.paymentStatusData &&
                          props.tableData.paymentStatusData.data[0].cardMode ===
                            "visa"
                            ? VisaImage
                            : MasterImage
                        }
                        style={{ width: "15%", marginBottom: "-3%" }}
                      />{" "}
                      {props.tableData.paymentStatusData &&
                        props.tableData.paymentStatusData.data[0]
                          .lastCardNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs align="center">
                    <Button onClick={() => handlePaymentChange()}>
                      change
                    </Button>
                  </Grid>
                </Grid>
              </CardBody>
              <CardFooter stats style={{ marginTop: "0px" }}>
                <div className={classes.stats} />
                <div className={classes.stats} align="right">
                  View your recently scheduled payments
                </div>
              </CardFooter>
            </Card>
          ) : (
            <RippleLoading />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ padding: "15px" }}>
          {props.tableData.offerAccountStatusData ? (
            <Card>
              <CardHeader
                color="info"
                stats
                icon
                style={{ margin: "0px 20px" }}
              >
                <CardIcon
                  color="info"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    float: "none",
                    padding: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Accessibility />
                  <Typography
                    noWrap
                    style={{ color: "#fff" }}
                    className={classes.cardTitle}
                  >
                    Account Status
                  </Typography>
                </CardIcon>
              </CardHeader>
              <CardBody style={{ height: "240px", padding: "0px" }}>
                <Typography
                  style={{
                    color: "#0B9600",
                    fontSize: "21px",
                    padding: "1rem",
                  }}
                  align="center"
                >
                  {`PayOff: $${
                    props.tableData.offerAccountStatusData &&
                    props.tableData.offerAccountStatusData.data[0].payOff
                  }`}
                </Typography>
                <Grid container className={classes.noBackgroundColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Monthly Payment
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`$${
                      props.tableData.offerAccountStatusData &&
                      props.tableData.offerAccountStatusData.data[0]
                        .monthlyPayment
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.backgroundColorDiv}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Last Payment On
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`${
                      props.tableData.offerAccountStatusData &&
                      props.tableData.offerAccountStatusData.data[0]
                        .lastPaymentDate
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.noBackgroundColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Balance term left
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`${
                      props.tableData.offerAccountStatusData &&
                      props.tableData.offerAccountStatusData.data[0]
                        .balanceTerms
                    }`}
                  </Grid>
                </Grid>
                <Grid container className={classes.backgroundColorDiv}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Principal Balance
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`$${
                      props.tableData.offerAccountStatusData &&
                      props.tableData.offerAccountStatusData.data[0]
                        .principalBalance
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </CardBody>
              <CardFooter stats style={{ marginTop: "0px" }}>
                <div className={classes.stats} />
                <div className={classes.stats} align="right">
                  View Details
                </div>
              </CardFooter>
            </Card>
          ) : (
            <RippleLoading />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={4} style={{ padding: "15px" }}>
          {props.tableData.originalLoanTermData ? (
            <Card>
              <CardHeader
                color="info"
                stats
                icon
                style={{ margin: "0px 20px" }}
              >
                <CardIcon
                  color="info"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-around",
                    float: "none",
                    padding: "0px",
                    marginRight: "0px",
                  }}
                >
                  <Accessibility />
                  <Typography
                    noWrap
                    style={{ color: "#fff" }}
                    className={classes.cardTitle}
                  >
                    Original Loan Term
                  </Typography>
                </CardIcon>
              </CardHeader>
              <CardBody style={{ height: "240px", padding: "0px" }}>
                <Typography
                  style={{
                    color: "#0B9600",
                    fontSize: "21px",
                    padding: "1rem",
                  }}
                  align="center"
                >
                  {`Amount Financed: $${
                    props.tableData.originalLoanTermData &&
                    props.tableData.originalLoanTermData.data[0].amountFinanced
                  }`}
                </Typography>
                <Grid container className={classes.noBackgroundColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Funding date
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`${
                      props.tableData.originalLoanTermData &&
                      props.tableData.originalLoanTermData.data[0].fundingDate
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.backgroundColorDiv}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Down Payment
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`$${
                      props.tableData.originalLoanTermData &&
                      props.tableData.originalLoanTermData.data[0].downPayment
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.noBackgroundColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Monthly Payment
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`$${
                      props.tableData.originalLoanTermData &&
                      props.tableData.originalLoanTermData.data[0]
                        .monthlyPayment
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.backgroundColorDiv}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Terms (Monthly)
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`${
                      props.tableData.originalLoanTermData &&
                      props.tableData.originalLoanTermData.data[0].terms
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.noBackgroundColor}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    APR
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`${
                      props.tableData.originalLoanTermData &&
                      props.tableData.originalLoanTermData.data[0].apr
                    }%`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
                <Grid container className={classes.backgroundColorDiv}>
                  <Grid item xs={1} />
                  <Grid item xs={6} align="left">
                    Daily Interest
                  </Grid>
                  <Grid item xs={4} align="right">
                    {`$${
                      props.tableData.originalLoanTermData &&
                      props.tableData.originalLoanTermData.data[0].dailyInterest
                    }`}
                  </Grid>
                  <Grid item xs={1} />
                </Grid>
              </CardBody>
              <CardFooter stats style={{ marginTop: "0px" }}>
                <div className={classes.stats} />
                <div className={classes.stats} align="right">
                  View Details
                </div>
              </CardFooter>
            </Card>
          ) : (
            <RippleLoading />
          )}
        </Grid>
      </Grid> */}
      {/* <Grid container xs={4} spacing={3}>
            <Grid item xs={12}>
              {props.tableData.remainingFundingData ? (
                <Card>
                  <RemainingFund remainingFundData={remainingFundData} />
                </Card>
              ) : (
                <RippleLoading />
              )}
            </Grid>
          </Grid> */}
    </div>
  );
};

Patients.propTypes = {
  getAllActivePlanList: PropTypes.func,
  getOpenFundingAmount: PropTypes.func,
  getPendingOfferAmount: PropTypes.func,
  getPaymentStatus: PropTypes.func,
  getOfferAccountStatus: PropTypes.func,
  getOriginalLoanTerm: PropTypes.func,
  getRemainingFundingGraph: PropTypes.func,
  tableData: PropTypes.object,
  userDetails: PropTypes.object,
  history: PropTypes.object,
  getPaymentDetailsList: PropTypes.func,
  getOfferDetailsPatient: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.patientDashboard,
  userDetails: app.userRole
});

// Dashboard.propTypes = {
//   dashboardData: PropTypes.object,

// };
export default connect(mapStateToProps, {
  getAllActivePlanList,
  getOpenFundingAmount,
  getPendingOfferAmount,
  getPaymentStatus,
  getOfferAccountStatus,
  getOriginalLoanTerm,
  getRemainingFundingGraph,
  getPaymentDetailsList,
  getOfferDetailsPatient
})(Patients);
