import React, { useState, useEffect } from "react";
import { Container, Typography, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { postAxiosAPI, getAxiosAPI } from "../../actions/AxiosAPIMethods";
import CardBody from "components/Card/CardBody";
import CardFooter from "components/Card/CardFooter";

const PatientPendingOffers = (props) => {
  const classes = useStyles();
  const [showAllOffers, setShowAllOffers] = React.useState(false);
  const [loader, setLoader] = useState(true);
  const [offersDataNew, setOffersDataNew] = useState({});

  const loginUser = async () => {
    const url =
      "/user/login?Email=suresh%40vaminfosys.com&clientId=&loginBy=&password=Temp%401234";
    await getAxiosAPI(url).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        localStorage.setItem("token", `bearer ${res.data.token}`);
        getAllOffersApiCall();
      }
    });
  };
  const userOfferPath = window.location.pathname
    .split("/")
    .splice(1, 2)
    .join("/");
  const duesId = "";
  // window.location.pathname?.split("/").slice(3)[0].split(",")

  useEffect(() => {
    loginUser();
  }, []);
  const selectedPatient = JSON.parse(
    localStorage.getItem("selectedPatient") || "{}"
  );

  const handlePaymentOffer = () => {};

  const getAllOffersApiCall = async (token) => {
    setLoader(true);
    const url = "/patients/get-patient-offers";
    const reqData = {
      dueId: duesId,
    };
    await postAxiosAPI(url, reqData).then((res) => {
      if (res.error) {
        console.log(res.error);
        setLoader(false);
      } else {
        setOffersDataNew(res.data);
        setLoader(false);
      }
    });
  };
 

  const generateOffers = async () => {
    setShowAllOffers(true);
  };

  return (
    <Container>
      {/* {!loader ? ( */}
      <>
        {/* <Typography style={{ fontSize: "18px", margin: "0 20px" }}>
            Patient Name:- <b>{selectedPatient?.patientName}</b>
          </Typography> */}
        <Grid lg={12} container className={classes.cardMain}>
          <Grid xs={12} sm={6} className={classes.leftItem}>
            <Box className={classes.toplevelBox}>
              <Typography className={classes.tpoLevel}>
                3{/* {Math.round(selectedPatient?.tpoLevel)} */}
              </Typography>
            </Box>

            <Grid className={classes.leftText}>
              <Typography className={classes.mainHeading}>TPO Level</Typography>
              <Typography className={classes.bottomPara}>
                Level is calculated using The Patient <br /> Offer Algorithm 2.0
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} className={classes.rightItem}>
            <Typography className={classes.balance}>Current Balance</Typography>
            <Typography className={classes.balanceDigit}>
              $ {/* {offersDataNew?.data[0]?.Discounted[0]?.amountFinanced} */}
            </Typography>
          </Grid>
        </Grid>
        <Typography
          style={{
            margin: "20px",
            fontSize: "22px",
            fontWight: 600,
            lineHeight: "28px",
          }}
        >
          The following payment options are available to you:
        </Typography>
        {showAllOffers ? (
          <>
            {
              // offersDataNew?.data[0]?.Recourse[0].offers.length && offersDataNew?.data[0]?.Recourse[0].offers.map(loan => (
              <Grid>
                <div style={{ marginLeft: "20px" }}>*Values are estimated</div>

                <Grid container className={classes.offersCard}>
                  <Grid xs={6} sm={4} lg={2}>
                    <img
                      className={classes.financerLogo}
                      // key={loan.financerLogo}
                      // src={loan.financerLogo}
                      alt="Current Logo"
                      loading="eager"
                    />
                  </Grid>
                  <Grid xs={6} sm={4} lg={2}>
                    <Typography className={classes.offersPara}>APR*</Typography>
                    <Typography className={classes.offersHeading}>
                      {/* {loan.APR} */}%
                    </Typography>
                  </Grid>
                  <Grid xs={6} sm={4} lg={2}>
                    <Typography className={classes.offersPara}>
                      Down Payment
                    </Typography>
                    <Typography className={classes.offersHeading}>
                      ${/* {loan.monthlyPayment} */}
                    </Typography>
                  </Grid>
                  <Grid xs={6} sm={4} lg={2}>
                    <Typography className={classes.offersPara}>
                      Term*
                    </Typography>

                    <Typography className={classes.offersHeading}>
                      {/* {loan.termsMonth} */} mos.
                    </Typography>
                  </Grid>
                  <Grid xs={6} sm={4} lg={2}>
                    {/* <Typography className={classes.offersPara}>
                      Monthly Payment*
                    </Typography> */}
                    <Typography className={classes.offersHeading}>
                      {/* {loan.downPayment} */}
                    </Typography>
                  </Grid>
                  <Grid xs={6} sm={4} lg={2}>
                    <Button
                      onClick={() => handlePaymentOffer(2)}
                      style={{
                        backgroundColor: "#03A430",
                        borderRadius: "25px",
                        textTransform: "none",
                        height: "45px",
                        color: "#ffffff",
                        width: "190px",
                      }}
                      size="large"
                      variant="contained"
                      color="#ffffff"
                      type="submit"

                      // disabled={!isEmpty(formik.errors) || !formik.dirty}
                      // className={classes.submit}
                    >
                      Accept Offer
                    </Button>

                    <Typography
                      style={{ padding: "5px 18px", }}
                      className={classes.offersPara}
                    >
                      Offer details, rates & fees
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              // ))
            }

            <Grid style={{ textAlign: "center", margin: "20px" }}>
              <Button
              style={{
                borderRadius: "25px",
                textTransform: "none",
                height: "45px",
              }}
              size="large"
              variant="contained"
              color="#ffffff"
              type="submit"
                onClick={() => setShowAllOffers(false)}
                className={classes.paymentBtn}
              >
                Return to Payment Options
              </Button>
            </Grid>
          </>
        ) : (
          <Grid container className={classes.screen1Cards}>
            <Grid xs={12} md={12} lg={12} style={{ paddingBottom: "40px" }}>
              <Grid
                style={{ position: "relative" }}
                className={classes.primaryCards}
              >
                <Grid className={classes.discountFlex}>
                  <Typography className={classes.cardH7}>
                    {/* {offersDataNew?.data[0].Discounted[0].discount} */}%
                  </Typography>
                  <Typography className={classes.cardH8}>Discount</Typography>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography
                      className={classes.cardH1}
                      style={{ paddingLeft: "0px", paddingTop: "10px" }}
                    >
                      Single Payment
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography className={classes.typographyAmmount}>
                      Single Pay Discount
                    </Typography>
                    <Typography className={classes.cardH1}>
                      $
                      {/* {offersDataNew?.data[0].Discounted[0].discountAmount} */}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography className={classes.typographyAmmount}>
                      Total Payment
                    </Typography>
                    <Typography
                      className={classes.cardH1}
                      style={{ color: "#03A430" }}
                    >
                      ${" "}
                      {/* {offersDataNew.data[0].Discounted[0].finalPayAmount} */}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Button
                      style={{
                        backgroundColor: "#03A430",
                        borderRadius: "25px",
                        textTransform: "none",
                        height: "45px",
                        color: "#ffffff",
                        width: "190px",
                      }}
                      size="large"
                      variant="contained"
                      color="#ffffff"
                      type="submit"

                      // disabled={!isEmpty(formik.errors) || !formik.dirty}
                      // className={classes.submit}
                    >
                      Make Payment
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={12} lg={12} style={{ paddingBottom: "40px" }}>
              <Grid
                style={{ position: "relative" }}
                className={classes.primaryCards}
              >
                <Grid
                  className={classes.discountFlex}
                  style={{ backgroundColor: "#3E4E9B", color: "#ffffff" }}
                >
                  <Typography className={classes.cardH8}>
                    TPO Sponsored
                  </Typography>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography
                      className={classes.cardH1}
                      style={{ paddingLeft: "0px", paddingTop: "10px" }}
                    >
                      Payment Plans
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography className={classes.typographyAmmount}>
                      Lowest APR
                    </Typography>
                    <Typography className={classes.cardH1}>
                      $
                      {/* {offersDataNew?.data[0].Discounted[0].discountAmount} */}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography className={classes.typographyAmmount}>
                      Lowest Monthly Payment
                    </Typography>
                    <Typography className={classes.cardH1}>
                      ${" "}
                      {/* {offersDataNew.data[0].Discounted[0].finalPayAmount} */}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Button
                      onClick={() => generateOffers()}
                      style={{
                        backgroundColor: "#3E4E9B",
                        borderRadius: "25px",
                        textTransform: "none",
                        height: "45px",
                        color: "#ffffff",
                        width: "190px",
                      }}
                      size="large"
                      variant="contained"
                      color="#ffffff"
                      type="submit"

                      // disabled={!isEmpty(formik.errors) || !formik.dirty}
                      // className={classes.submit}
                    >
                      View Plans
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} md={12} lg={12} style={{ paddingBottom: "40px" }}>
              <Grid
                style={{ position: "relative" }}
                className={classes.primaryCards}
              >
                <Grid
                  className={classes.discountFlex}
                  style={{ backgroundColor: "#0079BD", color: "#ffffff" }}
                >
                  <Typography className={classes.cardH8}>
                    Third-Party Plan
                  </Typography>
                </Grid>
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography
                      className={classes.cardH1}
                      style={{ paddingLeft: "0px", paddingTop: "10px" }}
                    >
                      Payment Plans
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography className={classes.typographyAmmount}>
                      Lowest APR
                    </Typography>
                    <Typography className={classes.cardH1}>
                      $
                      {/* {offersDataNew?.data[0].Discounted[0].discountAmount} */}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Typography className={classes.typographyAmmount}>
                      Lowest Monthly Payment
                    </Typography>
                    <Typography className={classes.cardH1}>
                      ${" "}
                      {/* {offersDataNew.data[0].Discounted[0].finalPayAmount} */}
                    </Typography>
                  </Grid>
                  <Grid item lg={3} md={3} xs={12}>
                    <Button
                      onClick={() => generateOffers()}
                      style={{
                        backgroundColor: "#0079BD",
                        borderRadius: "25px",
                        textTransform: "none",
                        height: "45px",
                        color: "#ffffff",
                        width: "190px",
                      }}
                      size="large"
                      variant="contained"
                      color="#ffffff"
                      type="submit"

                      // disabled={!isEmpty(formik.errors) || !formik.dirty}
                      // className={classes.submit}
                    >
                      View Plans
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {/* <Typography className={classes.cardH1}>Single Payment</Typography>
              <Grid style={{ margin: "20px 0" }}>
                <Typography className={classes.cardH2}>
                  Sub-total ${" "} */}
            {/* {offersDataNew?.data[0]?.Discounted[0]?.amountFinanced} */}
            {/* </Typography>
                <Typography className={classes.cardH2}>
                  Discount: -${" "} */}
            {/* {offersDataNew?.data[0].Discounted[0].discountAmount} */}{" "}
            {/* </Typography>
              </Grid> */}
            {/* <Typography className={classes.cardH3}>TOTAL AMOUNT</Typography>
              <Divider className={classes.dividerCss} />
              <Typography className={classes.cardH4}> */}
            {/* ${offersDataNew.data[0].Discounted[0].finalPayAmount} */}
            {/* </Typography> */}
            {/* <Button variant="contained" className={classes.paymentBtnPrimary}>
                Make Single Payment
              </Button> */}
            {/* <Grid xs={12} md={12} lg={12}>
                <Grid className={classes.primaryCards}>
                  <Typography className={classes.cardH1}>
                    Payment Plan
                  </Typography>
                  <Grid style={{ margin: "20px 0" }}>
                    <Typography className={classes.cardH2}>
                      Pay in equal monthly installments.
                    </Typography>
                  </Grid>
                  <Grid className={classes.card2middle}>
                    <Typography className={classes.cardH3}>
                      Lowest Rate
                    </Typography>
                    <Typography className={classes.cardH3}>
                      View details, rates & fees
                    </Typography>
                  </Grid>
                  <Divider className={classes.dividerCss} />
                  <Grid className={classes.card2middle}>
                    <Grid> */}
            {/* <Typography className={classes.cardH5}> */}
            {/* {offersDataNew?.data[0]?.Recourse[0].offers[0]?.monthlyPayment} */}
            {/* /mo.
                      </Typography>
                      <Typography className={classes.cardH6}>
                        Estimated Payment
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography className={classes.cardH5}> */}{" "}
            {/* {offersDataNew?.data[0]?.Recourse[0].offers[0]?.termsMonth} */}{" "}
            {/* mos.
                      </Typography>
                      <Typography className={classes.cardH6}>
                        Payment Length
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    onClick={() => generateOffers()}
                    variant="contained"
                    className={classes.paymentBtnPrimary2}
                  >
                    See All Payment Plans
                  </Button>
                </Grid>
              </Grid> */}
          </Grid>
        )}
      </>
      {/* // ) : ( */}
      {/* //   <>
      //     <Typography className={classes.cardH4}>Loading...</Typography>
      //   </> */}
      {/* // )} */}
    </Container>
  );
};

export default PatientPendingOffers;

const useStyles = makeStyles({
  financerLogo: { objectFit: "contain", height: "80px", width: "100px" },
  root: {
    padding: "2px 15px",
    display: "flex",
    alignItems: "center",
    width: 300,
    borderRadius: "24px",
    color: "#555555",
    backgroundColor: "#EFF4FF",
  },
  discountFlex: {
    position: "absolute",
    top: "0px",
    backgroundColor: "#FFD568",
    padding: "10px 15px",
    display: "flex",
    alignItems: "center",
    // borderRadius: "0px 0px 15px 15px",
  },
  card2middle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardH1: {
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "35px",
  },
  cardH2: {
    fontWeight: 400,
    padding: "2px 0",
    fontSize: "18px",
    lineHeight: "24px",
  },
  cardH7: {
    fontWeight: 600,
    color: "#333333",
    fontSize: "14px",
    lineHeight: "10px",
    paddingRight: "5px",
    textTransform: "none",
  },
  cardH8: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "10px",
    textTransform: "none",
  },
  cardH4: {
    fontWeight: 600,
    padding: "2px 0",
    fontSize: "48px",
    paddingBottom: "30px",
    paddingTop: "5px",
    lineHeight: "52px",
  },
  cardH5: {
    fontWeight: 600,
    padding: "5px 0",
    fontSize: "32px",
    lineHeight: "34px",
  },
  cardH6: {
    fontWeight: 400,
    paddingTop: "5px",
    paddingBottom: "45px",
    fontSize: "16px",
    lineHeight: "18px",
  },
  cardH3: {
    fontWeight: 600,
    color: "#27337D",
    padding: "5px 0",
    fontSize: "18px",
    lineHeight: "22px",
  },
  primaryCards: {
    backgroundColor: "white !important",
    padding: "30px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    borderRadius: "15px",
    height: "50px",
  },
  cardMain: {
    borderRadius: "15px",
    margin: "40px 20px 20px 20px",
    padding: "20px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    backgroundColor: "#FFFFFF",
  },
  screen1Cards: {
    margin: "20px",
  },
  dividerCss: {
    height: "2px !important",
    color: "#DBE3F3 !important",
  },
  input: {
    // marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  iconButtonBell: {
    padding: "10px",
    margin: "0 10px",
    backgroundColor: "#3E4E9B",
  },
  divider: {
    height: 28,
    margin: 4,
  },

  offersCard: {
    borderRadius: "15px",
    margin: "20px",
    padding: "24px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    backgroundColor: "#FFFFFF",
  },
  tpoLevel: {
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "56px",
  },
  toplevelBox: {
    backgroundColor: "#FFD568",
    paddingTop: "3px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "55px",
    height: "55px",
    borderRadius: "10px",
  },
  leftItem: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: 'space-between',
    margin: "auto 0px",
  },
  rightItem: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: 'right',
    alignItems: "flex-end",
    margin: "auto 0px",
  },
  leftText: {
    margin: "auto 20px",
  },
  mainHeading: {
    fontSize: "26px",
    fontWeight: "600",
    lineHeight: "30px",
  },
  bottomPara: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "22px",
  },
  balance: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "22px",
    color: "#27337D",
    padding: "0 15px",
  },
  balanceDigit: {
    fontSize: "48px",
    fontWeight: "600",
    lineHeight: "52px",
    // color: '#27337D'
  },
  offersHeading: {
    fontSize: "28px",
    fontWeight: "600",
    lineHeight: "30px",
  },
  offersPara: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    color: "#777777",
    paddingTop: "5px",
  },
  offersPara2: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    color: "#777777",
    paddingTop: "5px",
    textAlign: "center",
    margin: "10px 0px",
  },
  acceptButton: {
    backgroundColor: "#008768",
    borderRadius: "5px",
    // width: "auto",
    textTransform: "none",
    color: "#fff",
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: "400",
    margin: "0 10px",
    padding: "10px 30px !important",
  },
  paymentBtn: {
    backgroundColor: "#0079BD",
    borderRadius: "5px",
    padding: "10px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
  },
  paymentBtnPrimary: {
    backgroundColor: "#008768",
    width: "100%",
    borderRadius: "5px",
    padding: "25px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    marginTop: "30px",
  },
  paymentBtnPrimary2: {
    backgroundColor: "#0079BD",
    width: "100%",
    borderRadius: "5px",
    padding: "25px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    marginTop: "30px",
  },
  drawerBtns: {
    backgroundColor: "#008768",
    borderRadius: "5px",
    padding: "12px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    margin: "10px 0px",
  },
  drawerBtns2: {
    // backgroundColor: '#008768',
    borderRadius: "5px",
    padding: "12px 50px",
    textTransform: "none",
    // color: '#fff',
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    margin: "10px 0px",
  },
  sendOffersBtn: {
    wordBreak: "break-all",
    textTransform: "none",
    width: "0.6em !important",
    height: "auto",
    minWidth: "40px",
    backgroundColor: "#0079BD",
    borderRadius: "5px",
    padding: "10px",
    color: "#fff",
    fontWeight: "400",
    fontSize: "22px",
    lineHeight: "24px",
    position: "absolute",
    right: "0px",
    top: "242px",
  },
  drawerInput: {
    width: "350px",
    margin: "10px 0px",
    // padding: '10px 0px',
    height: "40px",
  },
  backBtn: {
    color: "#27337D",
    marginBottom: "50px",
    cursor: "pointer",
  },
  btnPairs: {
    margin: "80px 0px",
    display: "flex",
    flexDirection: "column",
  },
  topHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "20px",
  },
  headerContent: {
    display: "flex",
    flexDirection: "row",
  },
  useName: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    padding: "0 20px",
  },
});
