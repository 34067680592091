import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  getProviderParameterById,
  changeProviderParameterFormMode
} from "../../Redux/Actions/ProvideRegistration";

const useStyles = makeStyles(styles);

function LongMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type) => {
    if (type === "view") {
      const data = props.details;
      props.getProviderParameterById(data);
      props.changeProviderParameterFormMode("View");
      props.setShowSetUp("View");
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          className={classes.dialogBoxDropDown}
          onClick={() => handleClose("view")}
        >
          View
        </MenuItem>
      </Menu>
    </div>
  );
}

LongMenu.propTypes = {
  details: PropTypes.string,
  getProviderParameterById: PropTypes.func,
  setShowSetUp: PropTypes.func,
  changeProviderParameterFormMode: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration
});

export default connect(mapStateToProps, {
  getProviderParameterById,
  changeProviderParameterFormMode
})(LongMenu);
