/* eslint-disable import/extensions */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import SignatureCanvas from "react-signature-canvas";
import { Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button.js";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);
const TncAccept = props => {
  const classes = useStyles();
  const date = moment().format("MM/DD/YYYY");
  const handleConfirmAndPay = () => {
    props.history.push("/frontDesk/pdf");
  };
  return (<div>
    <Box className={classes.margin}>
      <Grid container className={classes.margin}>
        <Grid item xs>
          <div>
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {`I ${props.preValues.patientName} do here by agree to pay ${props.preValues.downPayment} Down payment ${props.preValues.perEmiAmt} monthly for the next ${props.preValues.totalEmi} months`}
            {" "}
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.margin}>
        <Grid item xs className={classes.displayFlex}>
          <Typography className={classes.datetnc}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date:
          </Typography>
          <div>
            {" "}
            {date}
            {" "}
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.margin}>
        <Grid item xs className={classes.displayFlex}>
          <Typography className={classes.datetnc}>
            E-Signature: &nbsp;
          </Typography>
          <SignatureCanvas
            penColor="black"
            canvasProps={{ width: 250, height: 70, className: classes.canvasBackgourd }} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Typography className={classes.thanksColor}>Thanks for the Payment</Typography>
        </Grid>
        <Grid item xs />
        <Grid item xs>
          <br />
          <Button variant="contained" className={classes.buttonStyle} onClick={() => handleConfirmAndPay()} size="md">
            Confirm & Pay Now
          </Button>
&nbsp;&nbsp;&nbsp;
          <Button variant="contained" className={classes.buttonStyle} size="md">
            Print
          </Button>
        </Grid>
      </Grid>
    </Box>
  </div>);
};

TncAccept.propTypes = {
  history: PropTypes.any,
  preValues: PropTypes.any,
};

export default TncAccept;
