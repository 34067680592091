/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/extensions */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import TextField from "@material-ui/core/TextField";
import Card from "components/Card/Card.js";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import closeImage from "assets/img/Close_Image.png";
import EmailIcon from "@material-ui/icons/Email";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import EventNoteIcon from "@material-ui/icons/EventNote";
import {
  ErrorMessage, Field, Form, Formik,
} from "formik";
import styles from "assets/jss/material-dashboard-react/components/businessForm.js";
import Modal from "@material-ui/core/Modal";
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import { locationCity } from "../../constant/locationConstant";
import gif from "../../assets/img/success.gif";
import { callOnBoarding } from "../../Redux/Actions/OnBordingActions";
import Validations from "../../Utils/Validations";
import { sendMailAgain } from "../../actions/MerchantSignUp";

const useStyles = makeStyles(styles);

function OnBording(props) {
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const windowWidth = useMediaQuery(theme.breakpoints.up("sm"));
  const [preOnBoradData, setPreOnBoradData] = React.useState(
    props.mainData.onBoarding.data
  );
  const initialValues = {
    Profile_Code: "Merchant",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Email: "",
    Password: "Temp@1234",
    Role_ID: "618b841da564b0bd91d52a55",
    Address1: "Testst",
    Address2: "",
    City: "AL",
    Mobile: "9874586321",
    Phone_No: "",
    Country_Code: "US",
    State_Code: "AR",
    Zip: "00124",
    Time_Zone_Code: "AKST",
    Accessible_Provider: ["H001"],
    ClientID: "H001",
    CreateByClientId: "H001",
    ClientId: "H001",
    legalName: "",
    dbaName: "",
    formType: "MerchantSetup",
    Created_By: localStorage.getItem("userId"),
  };

  const [successModal, setSuccessModal] = React.useState(false);
  const classes = useStyles();
  const [tempValues, setTempValues] = React.useState({});

  const handleSubmit = values => {
    setTempValues(values);
    const addCreatedBy = { ...values };
    addCreatedBy.Created_By = localStorage.getItem("userId");
    props.callOnBoarding({ ...addCreatedBy });
  };

  const handleModal = () => {
    setSuccessModal(false);
  };

  const resendMailApiCall = () => {
    props.sendMailAgain(
      { email: tempValues.Email },
      data => {
        if (data.status) {
          enqueueSnackbar(`${data.message}`, {
            variant: "success",
          });
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error",
          });
        }
        handleModal();
      },
      err => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
        handleModal();
      }
    );
  };

  React.useEffect(() => {
    if (props.mainData.onBoarding.data !== preOnBoradData) {
      if (props.mainData.onBoarding.data) {
        setSuccessModal(true);
      }
      setPreOnBoradData(props.mainData.onBoarding.data);
    }
  }, [props]);

  return (
    <div>
      <Modal open={successModal} onClose={() => handleModal(false)}>
        <div className={classes.modalDiv}>
          <Card
            className={
              windowWidth ? classes.modalCard : classes.mobileModalCard
            }
          >
            <div
              style={{ display: "inline-table" }}
              className={classes.cardDiv1}
            >
              <img
                src={
                  props.mainData.onBoarding.data
                  && props.mainData.onBoarding.data.status
                    ? gif
                    : closeImage
                }
                width="75px"
              />

              <div
                style={{
                  textAlign: "center",
                  padding: "4%",
                  color: "gray",
                  fontSize: "14px",
                  whiteSpace: "normal",
                }}
              >
                {props.mainData.onBoarding.data
                && props.mainData.onBoarding.data.status
                  ? props.mainData.onBoarding.data.message
                  : props.mainData.onBoarding.data.message}
              </div>
              <br />
              {props.mainData.onBoarding.data.status === false
              && props.mainData.onBoarding.data.message
                === "Signup invitation has been already sent to this email id. Do you want to send invitation again?" ? (
                  <Grid justifyContent="center" spacing={2} container>
                    <Grid xs={4} item>
                      <Button
                        variant="contained"
                        className={classes.buttonStyle}
                        color="#696969"
                        size="sm"
                        onClick={resendMailApiCall}
                      >
                        Yes
                      </Button>
                    </Grid>
                    <Grid xs={4} item>
                      <Button
                        variant="contained"
                        className={classes.clearButton}
                        color="#696969"
                        size="sm"
                        onClick={() => handleModal()}
                      >
                        No
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    size="sm"
                    onClick={() => handleModal()}
                  >
                    Ok
                  </Button>
                )}
            </div>
          </Card>
        </div>
      </Modal>
      <Card
        style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
      >
        <Box
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            alignItems: "center",
          }}
          className={classes.tittleColor}
          height={40}
        >
          <Typography className={classes.tittleTextColor}>
            User Personal Information
          </Typography>
        </Box>
        <Formik
          validationSchema={Validations.merchantSignUp}
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={initialValues}
        >
          {({ errors, touched }) => (
            <Form>
              <Box style={{ paddingTop: "20px" }} className={classes.marginBox}>
                <Grid container>
                  <Grid item lg={4} sm={4} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      required
                      className={classes.root}
                      helperText={
                        errors.First_Name && touched.First_Name
                          ? errors.First_Name
                          : ""
                      }
                      error={errors.First_Name && touched.First_Name}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="First Name"
                      name="First_Name"
                      id="First_Name"
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <PersonIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                        required: true,
                      }}
                      label="Last Name"
                      name="Last_Name"
                      helperText={
                        errors.Last_Name && touched.Last_Name
                          ? errors.Last_Name
                          : ""
                      }
                      error={errors.Last_Name && touched.Last_Name}
                      id="Last_Name"
                    />
                  </Grid>
                  <Grid item lg={4} sm={4} xs={12}>
                    <EmailIcon className={classes.inputBox} fontSize="small" />
                    <Field
                      as={TextField}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Email"
                      name="Email"
                      helperText={
                        errors.Email && touched.Email ? errors.Email : ""
                      }
                      error={errors.Email && touched.Email}
                      id="Email"
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <EventNoteIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <Field
                      as={TextField}
                      required
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="Legal Name"
                      name="legalName"
                      id="legalName"
                      helperText={
                        errors.legalName && touched.legalName
                          ? errors.legalName
                          : ""
                      }
                      error={errors.legalName && touched.legalName}
                    />
                  </Grid>
                  <Grid item lg={6} sm={6} xs={12}>
                    <EventNoteIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <Field
                      as={TextField}
                      required
                      fullWidth
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused",
                        },
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused",
                        },
                      }}
                      label="DBA Name"
                      name="dbaName"
                      id="dbaName"
                      helperText={
                        errors.dbaName && touched.dbaName ? errors.dbaName : ""
                      }
                      error={errors.dbaName && touched.dbaName}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  style={{
                    textAlign: "center",
                    justifyContent: "space-evenly",
                    marginTop: "30px",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item sm={3} xs={8}>
                    <Button
                      className={classes.buttonStyle}
                      variant="contained"
                      fullWidth
                      disabled={!isEmpty(errors)}
                      color="#696969"
                      type="submit"
                    >
                      Submit and Send
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )}
        </Formik>
      </Card>
    </div>
  );
}

OnBording.propTypes = {
  mainData: PropTypes.object,
  // history: PropTypes.object,
  callOnBoarding: PropTypes.func,
  sendMailAgain: PropTypes.func,
};

const mapStateToProps = app => ({
  mainData: app,
});
export default connect(mapStateToProps, {
  callOnBoarding,
  sendMailAgain,
})(OnBording);
