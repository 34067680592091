/* eslint-disable radix */
import React from "react";
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Search from "@material-ui/icons/Search";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";
import IdleTimer from "react-idle-timer/dist/modern";
import Button from "components/CustomButtons/Button";
import { Box, Typography } from "@material-ui/core";
import { connect } from "react-redux";
// @material-ui/icons
import Person from "@material-ui/icons/Person";
// core components
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Link } from "react-router-dom";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle";
import MultipleSelect from "../MultiSelect/Index";
import { getProviderRoleList } from "../../Redux/Actions/UserRegistration";

const useStyles = makeStyles(styles);

function AdminNavbarLinks(props) {
  const classes = useStyles();
  const [openProfile, setOpenProfile] = React.useState(null);
  const [filterDataRole, setFilterDataRole] = React.useState(false);
  const [dataRole, setDataRole] = React.useState(false);
  const [userList, setUserList] = React.useState([]);

  const MenuData = JSON.parse(localStorage.getItem("menu") || "{}");

  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const idleTimer = React.useRef();
  const idleTime = parseInt(process.env.REACT_APP_IDEAL_TIME_MINUTES);
  const timeOutLimit = 10000 * 60 * idleTime;

  const onIdle = () => {
    if (window.location.pathname === "/admin/user") {
      document.getElementById("saveDraftBusinessForm").click();
    }
    props.setState();
    localStorage.setItem(
      "idealPath",
      JSON.stringify({
        path: window.location.pathname,
        landingPageName: props.app
      })
    );
    props.logout();
    localStorage.clear();
    props.setOroviderListEmpty();
    props.resetReducer();
    props.history.push("/sessionOut");
  };

  const profileTypeCheck =
    props.userRole.userList &&
    props.userRole.userList.Role_Permission_Data &&
    props.userRole.userList.Role_Permission_Data.Profile_Code;
  React.useEffect(() => {
    if (!props.userProvider.userProvider && !props.userProvider.loading) {
      props.getUserListProvider();
    } else if (
      props.userProvider.userProvider &&
      !props.userProvider.loading &&
      !props.appData.sendClientIdApi
    ) {
      const value = [];
      props.userProvider.userProvider.data.forEach((v) => {
        value.push(v.Porvider_Id);
      });
      localStorage.setItem("sendApiClientId", JSON.stringify(value));
      props.setSendClientIdInApi(value);
    }
  }, [props]);
  const handleCloseProfile = () => {
    props.setState();
    props.logout();
    localStorage.clear();
    localStorage.clear();
    props.setCallRolePermission();
    props.setOroviderListEmpty();
    props.resetReducer();
    props.history.push("/");
  };
  // const callUserListApi = () => {
  //   props.userListApi(
  //     {
  //       ClientId: localStorage.getItem("clientId"),
  //       LoginEmail: localStorage.getItem("email"),
  //       formType: "reportsTo",
  //     },
  //     (data) => {
  //       setUserList(data.Users_Data);
  //     }
  //   );
  // };
  // React.useEffect(() => {
  //   callUserListApi();
  //   if (!props.formData.countryTypeList) {
  //     props.getCountryTypeList();
  //   }
  //   if (!props.formData.profileTypeList) {
  //     props.getProfileTypeList();
  //   }
  //   if (!props.formData.roleList) {
  //     props.getRoleList();
  //   }
  //   if (!props.formData.timeZoneList) {
  //     props.getTimeZoneList();
  //   }
  //   if (!props.formData.providerroleList) {
  //     props.getProviderRoleList();
  //   }
  //   if (!props.formData.userProvider) {
  //     const data = {
  //       clientId: props.userRole.userList.ClientID,
  //     };
  //     props.getUserListProvider(data);
  //   }
  // }, []);

  // if (
  //   !dataRole &&
  //   props.roles.providerroleList &&
  //   props.roles.providerroleList.Roles_Data &&
  //   props.formData.userDetailsById
  // ) {
  //   const preData = props.formData.userDetailsById.Users_Data[0];
  //   const valueRole = props.roles.providerroleList.Roles_Data.filter(
  //     (v) => v.Profile_Code === preData.Profile_Code
  //   );

  //   const updatedData = {
  //     Profile_Code: preData.Profile_Code,
  //     User_Profile_Image: preData.User_Profile_Image,
  //   };

  //   setInitialValues({ ...updatedData });
  //   setFilterDataRole(valueRole);
  //   setDataRole(true);
  // }

  const initialValues =
    props.formData.users.userDetailsById &&
    props.formData.users.userDetailsById.Users_Data[0] &&
    props.formData.users.userDetailsById.Users_Data[0];

  const clientIdSelect = () => {
    window.location.reload();
  };
  return (
    <Box
      style={{
        display: `${profileTypeCheck === "Patient" ? "" : "flex"}`,
        width: "100%",
        justifyContent: "flex-end",
        alignItems: "center"
      }}
    >
      {/* <div className={classes.hideSearch}>
        {profileTypeCheck !== "Patient" && props.userProvider.userProvider && (
          <MultipleSelect
            className={classes.hideSearch}
            lable
            clientId={props.menuData.userList.ClientID}
            values={props.appData.sendClientIdApi}
            data={
              props.userProvider.userProvider
              && props.userProvider.userProvider.data
            }
            onChangeSelect={item => props.setSendClientIdInApi(item)}
          />
        )}
        {profileTypeCheck !== "Patient" && (
          <Button
            color="white"
            aria-label="edit"
            justIcon
            round
            onClick={() => clientIdSelect()}
          >
            <Search />
          </Button>
        )}
      </div> */}
      <div className={classes.manager}>
        <Box
          onClick={handleClickProfile}
          style={{
            cursor: "pointer",
            height: "50px",
            display: "flex",
            borderRadius: "24px",
            backgroundColor: "#EFF4FF",
            margin: "5px 20px"
          }}
        >
          <Box>
            <Typography
              style={{
                padding: "15px 0 15px 25px",
                fontSize: "16px",
                fontWight: "600",
                lineHeight: "22px",
                color: "#333333"
              }}
            >
              {MenuData?.Role_Permission_Data?.UserName}{" "}
            </Typography>
          </Box>
          <Box style={{ padding: "15px 10px" }}>
            <KeyboardArrowDownIcon />
          </Box>
          <Box>
            {profileTypeCheck === "SA" && (
              <img
                alt="UserImage"
                style={{ borderRadius: "50%", height: "49px", width: "49px" }}
                src={`${
                  JSON.parse(localStorage.getItem("menu")).userProfileImage
                }`}
              />
            )}
          </Box>
        </Box>
        <Poppers
          open={Boolean(openProfile)}
          anchorEl={openProfile}
          transition
          disablePortal
          placement="bottom-end"
          className={`${classNames({ [classes.popperClose]: !openProfile })} ${
            classes.popperNav
          }`}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="profile-menu-list-grow"
              style={{
                transformOrigin: "right"
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={() => {
                    setOpenProfile(null);
                  }}
                >
                  <MenuList role="menu">
                    <MenuItem className={classes.dialogBoxDropDown}>
                      {profileTypeCheck === "Patient" ? (
                        <Link
                          style={{
                            color: "#333333"
                          }}
                          to="/patient/Profile"
                        >
                          Profile
                        </Link>
                      ) : (
                        <Link
                          style={{
                            color: "#333333"
                          }}
                          to="/admin/Profile"
                        >
                          Profile
                        </Link>
                      )}
                    </MenuItem>
                    {/* <MenuItem className={classes.dialogBoxDropDown}>
                      Settings
                    </MenuItem> */}
                    <Divider light />
                    <MenuItem
                      onClick={handleCloseProfile}
                      className={classes.dialogBoxDropDown}
                    >
                      Logout
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Poppers>
        <IdleTimer
          ref={idleTimer}
          element={document}
          onIdle={() => onIdle()}
          onAction={() => {
            idleTimer.current.reset();
          }}
          debounce={250}
          timeout={timeOutLimit}
        />
      </div>
    </Box>
  );
}

AdminNavbarLinks.propTypes = {
  userProvider: PropTypes.object,
  history: PropTypes.object,
  getUserListProvider: PropTypes.func,
  userRole: PropTypes.object,
  setState: PropTypes.func,
  logout: PropTypes.func,
  setOroviderListEmpty: PropTypes.func,
  resetReducer: PropTypes.func,
  menuData: PropTypes.object,
  app: PropTypes.any,
  appData: PropTypes.object,
  setCallRolePermission: PropTypes.func,
  setSendClientIdInApi: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app
});
export default connect(mapStateToProps, {})(AdminNavbarLinks);
