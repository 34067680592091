const partner = process.env.REACT_APP_PARTNER;
const stage = process.env.REACT_APP_STAGE;

export const handleResponse = (response, onError = false) => {
  if (response.status !== 200) {
    return response
      .json()
      .then(error => Promise.reject(onError || error))
      .catch(err => {
        if (response.status === 401 || response.status === 403) {
          // window.location = '/unauthorized'
          return Promise.reject(
            `unauthorized access attempt:${response.status}`
          );
        }

        return Promise.reject(onError || err);
      });
  }

  try {
    return response.json();
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getBarcodeDetails = barcode => fetch(`https://${partner}.barcode.service.${stage}.com/${partner}/barcode?partition=${barcode}`, {
  mode: "cors",
  method: "GET",
});

export const postPayment = body => fetch(`https://${partner}.cash.api.${stage}.com/${partner}/payment`, {
  mode: "no-cors",
  method: "POST",
  headers: {
    "Content-Type": "application/json",
  },
  body: JSON.stringify(body),
});
