import "./style.scss";
import "../../../assets/css/CountryCode.scss";

import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Card,
  CardContent,
  TextField,
  Typography,
  Select,
  MenuItem,
} from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// import Email from "@material-ui/icons/Email";
import { connect } from "react-redux";
import { useFormik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { useTheme } from "@material-ui/core/styles";
import { ErrorMessage, Field, Form, Formik } from "formik";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import OTPInput from "otp-input-react";
import { useSnackbar } from "notistack";
import React from "react";
import isEmpty from "lodash/isEmpty";
import { getCountryList } from "../../../actions/login.action";
import { confirmForgetPassword } from "../../../actions/Operations";
import useStyles from "./Style";

function ResetPassword(props) {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const emailRegEx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const [countryCode, setCountryCode] = React.useState([]);
  const validationSchema = yup.object({
    // email: yup
    //   .string("Enter your email")
    //   .email("Enter a valid email")
    //   .required("Email is required"),
    email: yup
      .string("Please enter a valid Email.")
      .required("Email/Mobile number is required.")
      .test(
        "id",
        "Please enter a valid Email/Mobile number.",
        (value) =>
          !value ||
          emailRegEx.test(value) ||
          /^(\+\d{1,3}[- ]?)?\d{10}$/.test(parseInt(value))
      )
      .trim(),
  });

  const onSubmit = (values) => {
    const payload = { email: values.email };
    if (/^\d+$/.test(values.email)) {
      payload.countryCode = values.countryCode;
    }
    props.confirmForgetPassword(
      payload,
      (data) => {
        if (data.status) {
          enqueueSnackbar(data.message, {
            variant: "success",
          });
        } else {
          data.errors.map((x) => {
            if (x.location === "body") {
              formik.setFieldError(x.param, x.msg);
            } else {
              enqueueSnackbar(x.msg, {
                variant: "error",
              });
            }
          });
        }
      },
      (err) => {
        // console.log(err.response);
        // err.map((x) => {
        //   if (x.location === "body") {
        //     formik.setFieldError(x.param, x.msg);
        //   } else {
        //     enqueueSnackbar(x.msg, {
        //       variant: "error",
        //     });
        //   }
        // });
        enqueueSnackbar("Request could not be completed", {
          variant: "error",
        });
      }
    );
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      countryCode: 1,
    },
    validationSchema,
    onSubmit,
  });

  React.useEffect(() => {
    if (isEmpty(countryCode)) {
      props.getCountryList((data) => {
        setCountryCode(data.Country_Data);
      });
    }
  }, []);

  return (
    <>
      <Grid
        container
        style={{
          height: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={6}
          style={{
            backgroundColor: "#EFF4FF",
            minHeight: "100vh",
            position: "relative",
          }}
        >
          <Grid item xs={12} lg={12}>
            <div
              style={{
                // paddingTop: "10px",
                paddingLeft: "45px",
                // paddingBottom: "10px",
              }}
            >
              <img
                style={{
                  width: "80%",
                }}
                src={"/images/tpo-logo.png"}
                alt="appName"
              />
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            lg={12}
            style={{
              // color: "#ffffff",
              display: "flex",
              paddingLeft: "60px",
              // paddingTop: "20px"
            }}
          >
            {/* <Button
              alignItems="flex-start"
              style={{
                textTransform: "none",
                color: loginType === "admin" ? "#ffffff" : "#3E4E9B",
                backgroundColor: loginType === "admin" ? "#3E4E9B" : "#ffffff",
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                borderTopLeftRadius: "10px",
                paddingRight: "25px",
                paddingLeft: "25px",
                paddingBottom: "10px",
                paddingTop: "10px",
                fontSize: "18px",
                fontWidth: "600",
              }}
              onClick={() => setloginType("patient")}
              flexDirection="column"
              className={`${classes.tilesCard} tiles-capsule`}
            >
              Patient Login
            </Button>
            <Button
              style={{
                textTransform: "none",
                color: loginType === "admin" ? "#3E4E9B" : "#ffffff",
                backgroundColor: loginType === "admin" ? "#ffffff" : "#3E4E9B",
                borderBottomRightRadius: "0px",
                borderBottomLeftRadius: "0px",
                paddingRight: "25px",
                paddingLeft: "25px",
                paddingBottom: "10px",
                paddingTop: "10px",
                borderTopRightRadius: "10px",
                fontSize: "18px",
                fontWidth: "600",
              }}
              alignItems="flex-start"
              flexDirection="column"
              onClick={() => setloginType("admin")}
              className={`${classes.tilesCard} tiles-capsule`}
            >
              Admin Login
            </Button> */}
          </Grid>
          <Grid
            style={{
              paddingRight: "60px",
              paddingLeft: "60px",
            }}
          >
            {/* <CssBaseline /> */}
            <Paper
              className={!windowWidth ? classes.paperNewM : classes.paperNew}
              elevation={5}
            >
              <Box
                height="100%"
                pt={4}
                pb={4}
                pl={{
                  xs: 4,
                  sm: 6,
                  md: 2,
                  xl: 8,
                }}
                pr={{
                  xs: 4,
                  sm: 6,
                  md: 2,
                  xl: 8,
                }}
                display="flex"
                alignItems="center"
              >
                <div className={classes.authInnerForm}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Typography
                      component="p"
                      variant="subtitle2"
                      style={{
                        color: "#27337D",
                        textAlign: "start",
                        fontSize: "1.5rem",
                        fontWeight: "600",
                        // marginTop: "10px",
                        // marginBottom: "10px",
                      }}
                    >
                      Forgot Password
                    </Typography>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    {/* <Typography
                      style={{
                        marginBottom: "5px",
                        fontWeight: "600",
                        textAlign: "start",
                        fontSize: "16px",
                      }}
                    >
                      Client ID
                    </Typography> */}
                    {/* <Field */}
                    {/* as={TextField}
                      size="large"
                      fullWidth
                      variant="outlined"
                      // id="clientId"
                      // label={"Enter Client ID" + "*"}
                      name="clientId"
                      // autoComplete="clientId"
                      // autoFocus={loginType === "admin"}
                      // inputProps={{ readOnly: true }}
                    // /> */}
                  </Grid>

                  <Typography
                    style={{
                      marginTop: "30px",
                      color: "#4856c1",
                    }}
                    component="p"
                    variant="subtitle2"
                  >
                    <b>Client ID</b>
                    &nbsp; H001
                  </Typography>
                  <Typography
                    style={{
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                    component="p"
                    variant="caption"
                    gutterBottom
                  >
                    Enter your email address to receive a password reset link.
                  </Typography>
                  <form onSubmit={formik.handleSubmit}>
                    <div className={classes.form}>
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        className={classes.spaceTop}
                      >
                        <Box
                          style={{
                            display: "flex",
                            width: "100%",
                          }}
                        >
                          <Typography
                            style={{
                              marginBottom: "5px",
                              fontWeight: "600",
                              textAlign: "start",
                              fontSize: "16px",
                              width: "29%",
                            }}
                          >
                            Enter Email *
                          </Typography>
                          <Typography
                            component="span"
                            className="error"
                            variant="body2"
                          >
                            {formik.errors.email}
                          </Typography>
                        </Box>
                        <TextField
                          as={TextField}
                          labelText="Email..."
                          id="email"
                          name="email"
                          variant="outlined"
                          required
                          placeholder="Enter Email"
                          fullWidth
                          // style={{ maxWidth: "550px" }}
                          // label="Enter Email/Mobile Number"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          // error={Boolean(formik.errors.email)}
                          // helperText={formik.touched.email && formik.errors.email}
                          formControlProps={{
                            fullWidth: true,
                          }}
                          InputProps={
                            /^\d+$/.test(formik.values.email) && {
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Select
                                    id="country-menu"
                                    value={formik.values.countryCode}
                                    onChange={(event) => {
                                      formik.setFieldValue(
                                        "countryCode",
                                        event.target.value
                                      );
                                    }}
                                    classes={{
                                      root: "country-list",
                                    }}
                                    className="no-underline"
                                    inputProps={{
                                      "aria-label": "Without label",
                                    }}
                                  >
                                    {countryCode.map((country) => (
                                      <MenuItem
                                        value={country.Dial_Code}
                                        option={countryCode}
                                        key={country.Country_Code}
                                        className="country"
                                      >
                                        <span
                                          className={`country-flag ${country.Country_Code.toLowerCase().substring(
                                            0,
                                            2
                                          )}`}
                                        />
                                        &nbsp;
                                        <span className="dial-code">{`+${country.Dial_Code}`}</span>
                                        &nbsp;
                                        <span className="country-name">
                                          ({country.Country_Name})
                                        </span>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </InputAdornment>
                              ),
                            }
                          }
                        />
                      </Grid>

                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        style={{ marginTop: "20px" }}
                        className={classes.spaceTop}
                      >
                        <Grid container alignItems="center" justify="center">
                          <Grid sm={12} xs={12} item>
                            <Button
                              size="large"
                              fullWidth
                              type="submit"
                              style={{
                                backgroundColor: "#03A430",
                                textTransform: "none",
                                borderRadius: "25px",
                                height: "56px",
                                color: "#ffffff",
                                marginTop: "5px",
                                marginBottom: "3px",
                                fontSize: "18px",
                              }}
                              // onClick={handleSubmit}
                              // disabled={
                              //   !isEmpty(errors) ||
                              //   isEmpty(values.id) ||
                              //   (!dirty && isEmpty(values.id)) ||
                              //   action
                              // }
                              variant=""
                              color="#ffffff"
                              className={classes.submit}
                            >
                              Send
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        className={classes.spaceTop}
                      >
                        <Grid item>
                          <Button
                            onClick={() => history.push("/")}
                            color="primary"
                          >
                            <b>Return To Login</b>
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  </form>
                </div>
              </Box>
            </Paper>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={6} className={classes.backgroundImg}>
          <Box
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            <Typography
              // component="h6"
              // align="center"
              style={{
                color: "#ffffff",
                fontSize: "2rem",
                // paddingLeft: "210px",
                // paddingTop: "250px",
              }}
            >
              Delivering Timely
              <br /> and <br /> Tailored Offers
            </Typography>
            <Box
              style={{
                color: "#ffffff",
                // fontSize: '1.5rem',
                // paddingLeft: "210px",
                // paddingBottom: "218px",
                paddingTop: "20px",
              }}
            >
              {/* <Button
                style={{
                  backgroundColor: "#03A430",
                  borderRadius: "25px",
                  textTransform: "none",
                  height: "56px",
                  color: "#ffffff",
                  fontSize: "18px",
                  width: "240px",
                }}
                size="large"
                variant="contained"
                color="#ffffff"
                type="submit"

                // disabled={!isEmpty(formik.errors) || !formik.dirty}
                // className={classes.submit}
              >
                Sign Up Now
              </Button> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
  // }
}

ResetPassword.propTypes = {
  confirmForgetPassword: PropTypes.func,
  getCountryList: PropTypes.func,
  app: PropTypes.object,
};

const mapStateToProps = ({ app }) => ({
  userData: app.userDetails,
  app,
});

export default connect(mapStateToProps, {
  confirmForgetPassword,
  getCountryList,
})(ResetPassword);
