/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Payment from "payment";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import AppLoader from "components/AppLoader/Index";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Cards from "react-credit-cards";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import card4 from "assets/img/card4.jpeg";
import accountIcon from "assets/img/bank-account-icon.jpg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import paymentCard from "assets/img/payment-card.jpg";
import "react-credit-cards/es/styles-compiled.css";
import isEmpty from "lodash/isEmpty";
import "./card.css";
import {
  patientMakePayment,
  emptyGraphData,
  setUpMonthlyOffer,
  beforePaymentSendOTP,
  paymentTokenCheckout,
} from "../../Redux/Actions/genrateOfferPatient";
import fullOrigin from "../../assets/img/imgpsh_fullsize_anim.png";
import PaymentConfirmation from "./PaymentConfirmation";
import PaymentErrorMessage from "./PaymentErrorMessage";
import PaymentOtp from "./PaymentOtp";

// eslint-disable-next-line react/prop-types
const RenderTime = ({ remainingTime, handleBackToSearch }) => {
  const currentTime = React.useRef(remainingTime);
  const prevTime = React.useRef(null);
  const isNewTimeFirstTick = React.useRef(false);
  const [, setOneLastRerender] = React.useState(0);

  if (currentTime.current !== remainingTime) {
    isNewTimeFirstTick.current = true;
    prevTime.current = currentTime.current;
    currentTime.current = remainingTime;
  } else {
    isNewTimeFirstTick.current = false;
  }

  // force one last re-render when the time is over to tirgger the last animation
  if (remainingTime === 0) {
    setTimeout(() => {
      setOneLastRerender((val) => val + 1);
    }, 20);
  }

  if (currentTime.current === 0) {
    handleBackToSearch();
  }

  const isTimeUp = isNewTimeFirstTick.current;
  // eslint-disable-next-line no-param-reassign
  function fmtMSS(s) {
    return (s - (s %= 60)) / 60 + (s > 9 ? ":" : ":0") + s;
  }

  return (
    <div className="time-wrapper">
      {prevTime.current !== null && (
        <div
          key={prevTime.current}
          className={`time ${!isTimeUp ? "down" : ""}`}
        >
          {fmtMSS(prevTime.current)}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(styles);
const PaymentOffer = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const financeNo = localStorage.getItem("finNo");
  const [logoPath, setLogoPath] = React.useState("");
  const [minutes, setMinutes] = React.useState(0);
  const [seconds, setSeconds] = React.useState(0);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const [submissionSuccess, setSubmissionSuccess] = React.useState(
    props.formData.data
  );
  const [preCheckoutData, setPreCheckoutData] = React.useState(
    props.formData.paymentCheckoutData
  );
  const [paymentMethod, setPaymentMethod] = React.useState("cc");
  const [handleConfirmation, setConfirmation] = React.useState(false);
  const [handleFail, setHandleFail] = React.useState(false);
  const [prePaymentDetails, setPrePaymentDetails] = React.useState(
    props.formData.paymentMakeDetails
  );
  const [patientDetails, setPatientDetails] = React.useState(
    props.formData.paymentDetailsData
  );
  const [showPaymentDetails, setShowPaymentDetails] = React.useState(true);
  const [cardType, setCardType] = React.useState("");
  const [paymentMode, setPaymentMode] = React.useState(
    !props.formData.setUpMonthltFromOffer &&
      !props.patientDashboard.offerAccountStatusData
      ? "Downpay"
      : "Setup"
  );

  useEffect(() => {
    if (
      !props.patientDashboard.offerAccountStatusData &&
      !props.formData.paymentDetailsData
      // !props.formData.emailState
    ) {
      props.history.push("/admin/offerpayment");
    }
  }, []);
  const [values, handleInput] = React.useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    paymentType: paymentMethod,
    amount: props.patientDashboard.offerAccountStatusData
      ? props.patientDashboard.offerAccountStatusData &&
        props.patientDashboard.offerAccountStatusData.data[0].monthlyPayment
      : props.formData.paymentDetailsData &&
        props.formData.paymentDetailsData.data.amount,
    firstName:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.firstName,
    lastName:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.lastName,
    email:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.email,
    address1:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.address1,
    address2:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.address2,
    city:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.city,
    state:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.state,
    zipCode:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.zip,
    bankRouting: "",
    bankAccountNumber: "",
    bankAccountType: "savings",
    cardType: "VISA"
  });
  const [billingValidationError, setBillingValidationError] =
    React.useState(true);

  const [validationError, setValidationError] = React.useState({
    cardNumber: false,
    expiryDate: false,
    cvv: false,
    firstName: false,
    lastName: false,
    email: false,
    address1: false,
    city: false,
    state: false,
    bankRouting: false,
    bankAccountNumber: false,
    zipCode: false,
  });
  const [sendOtpDetails, setsendOtpDetails] = React.useState(
    props.formData.sentOTPData
  );
  const [handleOtpConfirmation, setOtpConfirmation] = React.useState(false);

  function clearNumber(value = "") {
    return value.replace(/\D+/g, "");
  }

  function formatExpirationDate(value) {
    const clearValue = clearNumber(value);
    if (clearValue.length >= 3) {
      let monthValue = clearValue.slice(0, 2);
      if (clearValue.slice(0, 2) >= 12) {
        monthValue = 12;
      }
      return `${monthValue}/${clearValue.slice(2, 4)}`;
    }

    return clearValue;
  }

  function formatCVC(value, prevValue, allValues = {}) {
    const clearValue = clearNumber(value);
    let maxLength = 4;

    if (allValues.number) {
      const issuer = Payment.fns.cardType(allValues.number);
      maxLength = issuer === "amex" ? 4 : 3;
    }

    return clearValue.slice(0, maxLength);
  }

  function formatCreditCardNumber(value) {
    if (!value) {
      return value;
    }

    const issuer = Payment.fns.cardType(value);
    const clearValue = clearNumber(value);
    let nextValue;

    switch (issuer) {
      case "amex":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 15)}`;
        break;
      case "dinersclub":
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          10
        )} ${clearValue.slice(10, 14)}`;
        break;
      default:
        nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
          4,
          8
        )} ${clearValue.slice(8, 12)} ${clearValue.slice(12, 19)}`;
        break;
    }

    return nextValue.trim();
  }

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    if (type === "cardNumber") {
      error[type] = false;
      allState[type] = formatCreditCardNumber(value);
    } else if (type === "cvv") {
      error[type] = false;
      allState[type] = formatCVC(value);
    } else if (type === "expiryDate") {
      error[type] = false;
      allState[type] = formatExpirationDate(value);
    } else {
      error[type] = false;
      allState[type] = value;
    }
    handleInput(allState);
    setBillingValidationError(true);
    setValidationError(error);
  };

  React.useEffect(() => {
    if (submissionSuccess !== props.formData.data) {
      if (props.formData.data && props.formData.data.status) {
        setSubmissionSuccess(props.formData.data);
        setOpen(true);
      } else if (
        props.formData.data &&
        !props.formData.data.status &&
        props.formData.data.errors
      ) {
        setSubmissionSuccess(props.formData.data);
      }
    }
    if (prePaymentDetails !== props.formData.paymentMakeDetails) {
      setPrePaymentDetails(props.formData.paymentMakeDetails);
      if (props.formData.paymentMakeDetails.status) {
        setHandleFail(false);
        setConfirmation(true);
        setShowPaymentDetails(false);
        setOtpConfirmation(false);
      }
      if (!props.formData.paymentMakeDetails.status) {
        setHandleFail(true);
        setConfirmation(false);
        setShowPaymentDetails(false);
        setOtpConfirmation(false);
      }
    }
    if (sendOtpDetails !== props.formData.sentOTPData) {
      setsendOtpDetails(props.formData.sentOTPData);
      if (props.formData.sentOTPData.status) {
        setHandleFail(false);
        setConfirmation(false);
        setShowPaymentDetails(false);
        setOtpConfirmation(true);
      }
    }
    if (preCheckoutData !== props.formData.paymentCheckoutData) {
      onFormSubmit();
      setPreCheckoutData(props.formData.paymentCheckoutData);
    }
  }, [submissionSuccess, props]);

  const sendOTPtoConfim = () => {
    if (
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.paymentId !== ""
    ) {
      setHandleFail(false);
      setConfirmation(false);
      setShowPaymentDetails(false);
      setOtpConfirmation(true);
      if (
        patientDetails === props.formData.paymentDetailsData &&
        props.formData.paymentDetailsData.status === true
      ) {
        setPatientDetails(props.formData.paymentDetailsData);
        const data = {
          patientId: props.formData.paymentDetailsData.data.patientId,
          offerId: props.formData.paymentDetailsData.data.offerId,
          countryCode: "91",
          mobile: props.formData.paymentDetailsData.data.mobile,
        };
        props.beforePaymentSendOTP(data);
      }
    }
  };

  React.useEffect(() => {
    sendOTPtoConfim();
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 500);
    return () => {
      clearInterval(myInterval);
    };
  }, []);

  const checkValidation = () => {
    let value = true;
    const validationValues = { ...validationError };
    Object.keys(validationError).forEach((v) => {
      if (
        paymentMethod !== "ach" &&
        v !== "bankRouting" &&
        v !== "bankAccountNumber"
      ) {
        if (values[v] !== "" && v === "cvv" && values[v].length < 3) {
          value = false;
          validationValues[v] = true;
        } else if (!values[v] || values[v] === "") {
          value = false;
          validationValues[v] = true;
        }
      } else if (
        paymentMethod === "ach" &&
        v !== "cardNumber" &&
        v !== "expiryDate" &&
        v !== "cvv"
      ) {
        if (!values[v] || values[v] === "") {
          value = false;
          validationValues[v] = true;
        }
      }
    });
    setValidationError(validationValues);
    setBillingValidationError(value);
    return value;
  };

  const onFormSubmit = () => {
    let payType = 2;
    if (!props.formData.emailState && props.formData.paymentDetailsData) {
      payType = props.formData.paymentDetailsData.data.payType;
    }
    if (paymentMode === "Setup") {
      payType = 3;
    }
    // if (checkValidation()) {
    const data = {
      patientId: props.formData.paymentDetailsData.data.patientId,
      offerId: props.formData.paymentDetailsData.data.offerId,
      generateToken: props.formData.paymentCheckoutData.Confirmation,
      amount:
        paymentMode === "Setup"
          ? props.formData.paymentDetailsData.data.monthlyPayment
          : Number(
              props.formData.paymentDetailsData.data.amount
                .toString()
                .replace("$", "")
            ),
      paymentType: paymentMethod,
      cardLast4Digit:
        paymentMethod !== "ach"
          ? props.formData.paymentDetailsData.data.Last4CardNumber
              .replaceAll(" ", "")
              .slice(values.cardNumber.replaceAll(" ", "").length - 5)
          : values.bankAccountNumber.slice(values.bankAccountNumber.length - 4),
      email: values.email,
      bankRouting: values.bankRouting,
      firstName: values.firstName,
      lastName: values.lastName,
      address1: values.address1,
      address2: values.address2,
      cardType: values.cardType,
      state: values.state,
      city: values.city,
      payType: payType,
      zipCode: values.zipCode,
      payStatus: paymentMode,
      paymentId:"",
      // paymentId: props.formData.paymentDetailsData.data.paymentId,
      financeNo: financeNo || 1,
    };
    props.patientMakePayment(data);
    // }
  };

  const sendOtpBeforePayment = () => {
    if (checkValidation()) {
      const data = {
        MerchantKey: "AEAE82F9-5A34-47C3-A61E-1E8EE37BE3AD",
        PaymentType: paymentMethod,
        EmailAddress: values.email,
        CardNumber:
          values.cardNumber !== "" ? values.cardNumber.replaceAll(" ", "") : "",
        ExpDate:
          values.expiryDate !== ""
            ? values.expiryDate.replaceAll(" ", "/")
            : "",
        CVV: values.cvv,
        BankRouting: values.bankRouting,
        BankAccountNumber: values.bankAccountNumber,
        BankAccountType: values.bankAccountType,
      };
      props.paymentTokenCheckout(data);
    }
  };

  const setPaymentStatus = () => {
    const allState = { ...values };
    allState.amount = props.formData.emailState
      ? props.formData.showPatientDetailsViaMail?.data[0]?.Loan_Details
          ?.Monthly_Payment
      : props.formData.paymentDetailsData.data.amount;
    allState.cardNumber = "";
    allState.cvv = "";
    allState.expiryDate = "";
    allState.bankRouting = "";
    allState.bankAccountNumber = "";
    allState.bankAccountType = "savings";
    handleInput(allState);
    setBillingValidationError(true);
    setPaymentMode("Setup");
  };
  const setRetryPayment = () => {
    const allState = { ...values };
    allState.cardNumber = "";
    allState.cvv = "";
    allState.expiryDate = "";
    allState.bankRouting = "";
    allState.bankAccountNumber = "";
    allState.bankAccountType = "savings";
    handleInput(allState);
    setBillingValidationError(true);
  };

  if (isEmpty(logoPath)) {
    const logo_path = new Image();
    if (props.app.portalConfig) {
      const x = props.app.portalConfig.data[0]?.Logo_Image;
      logo_path.src = `${x}?" + ${new Date().getTime()}`;
      logo_path.onload = function () {
        setLogoPath(logo_path.src);
      };
      logo_path.onerror = function () {
        setLogoPath(fullOrigin);
      };
    }
  }

  const handleBackToSearch = () => {
    if (!props.patientDashboard.offerAccountStatusData) {
      if (props.formData.emailState) {
        props.history.push("/admin/email/offer");
      } else {
        setOpenCancelModal(false);
        props.history.push("/admin/offerpayment");
        // props.state();
      }
    } else {
      props.history.push("/patient/Dashboard");
      // props.state();
    }
  };

  return (
    <div style={{ margin: windowWidth ? "1% 15% 1% 15%" : "1%" }}>
      <AppLoader loader={props.formData.loading} />
      <Modal open={openCancelModal} onClose={() => setOpenCancelModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "60%",
                height: "150px",
                paddingBottom: "5%",
              }}
            >
              <Grid container className={classes.margin}>
                <Grid
                  item
                  xs
                  style={{
                    margin: "10%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "-webkit-center",
                  }}
                >
                  Do You want to cancel the process of making payment?
                </Grid>
              </Grid>
              <Grid container className={classes.margin}>
                <Grid item xs={2} />
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    style={{ height: "30px" }}
                    color="#696969"
                    size="sm"
                    onClick={() => handleBackToSearch()}
                  >
                    Ok
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    className={classes.clearButton}
                    color="#696969"
                    size="sm"
                    onClick={() => setOpenCancelModal(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </Modal>
      {!windowWidth ? (
        <Grid container>
          <Grid item xs={windowWidth ? 4 : 12} align="center">
            <img
              src={
                props.app.defaultTheme &&
                props.app.defaultTheme.data[0].Logo_Image
              }
              width="30%"
              height="auto"
              alt=""
            />
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <Card>
        <div style={{ margin: "15px" }}>
          {showPaymentDetails && (
            <>
              <Grid container style={{ margin: "1%" }}>
                {windowWidth ? (
                  <>
                    <Grid item xs={windowWidth ? 4 : 12}>
                      <img src={logoPath} width="30%" height="auto" alt="" />
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      align="center"
                      // style={{
                      //   fontSize: "30px", display: "flex", justifyContent: "center", alignItems: "center",
                      // }}
                      style={{
                        fontSize: "30px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {values.amount}
                      <br />
                      {!props.formData.emailState &&
                        props.formData.createOffer &&
                        props.formData.createOffer.data.payType === 2 && (
                          <>
                            <div
                              style={{
                                fontSize: "14px",
                                marginTop: "3%",
                                color: "#3a57a7",
                              }}
                            >
                              {`(Next due on : ${props.formData.paymentDetailsData.data.nextDueDate})`}
                            </div>
                            <div style={{ fontSize: "14px", color: "#3a57a7" }}>
                              Total due months :
                              {props.formData.paymentDetailsData.data.dueEMI}
                            </div>
                          </>
                        )}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <CountdownCircleTimer
                        isPlaying
                        duration={300}
                        size={80}
                        colors={[
                          ["#3a57a7", 0.33],
                          ["#64bc5f", 0.33],
                          ["#A30000"],
                        ]}
                      >
                        <RenderTime
                          handleBackToSearch={() => handleBackToSearch()}
                          {...props}
                        />
                      </CountdownCircleTimer>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      align="right"
                      style={{ paddingRight: "3%" }}
                    >
                      <Button
                        className={classes.clearButton}
                        onClick={() => setOpenCancelModal(true)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
              {!windowWidth ? (
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <CountdownCircleTimer
                      isPlaying
                      duration={300}
                      size={80}
                      colors={[
                        ["#3a57a7", 0.33],
                        ["#64bc5f", 0.33],
                        ["#A30000"],
                      ]}
                    >
                      <RenderTime
                        handleBackToSearch={() => handleBackToSearch()}
                        {...props}
                      />
                    </CountdownCircleTimer>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    align="right"
                    style={{ paddingRight: "3%" }}
                  >
                    <Button
                      className={classes.clearButton}
                      onClick={() => setOpenCancelModal(true)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    align="center"
                    // style={{
                    //   fontSize: "30px", display: "flex", justifyContent: "center", alignItems: "center",
                    // }}
                    style={{
                      fontSize: "30px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {values.amount}
                    <br />
                    {!props.formData.emailState &&
                      props.formData.createOffer &&
                      props.formData.createOffer.data.payType === 2 && (
                        <>
                          <div
                            style={{
                              fontSize: "14px",
                              marginTop: "3%",
                              color: "#3a57a7",
                            }}
                          >
                            {`(Next due on : ${props.formData.paymentDetailsData.data.nextDueDate})`}
                          </div>
                          <div style={{ fontSize: "14px", color: "#3a57a7" }}>
                            Total due months :
                            {props.formData.paymentDetailsData.data.dueEMI}
                          </div>
                        </>
                      )}
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <Grid container>
                <Grid item xs>
                  <Divider
                    variant="middle"
                    style={{ marginTop: "2%", marginBottom: "2%" }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={windowWidth ? 4 : 12}>
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => setPaymentMethod("cc")}
                    className={classes.margin}
                    style={{
                      width: "75%",
                      color: `${paymentMethod === "cc" ? "black" : "#A7C2B5"}`,
                      background: `${
                        paymentMethod === "cc" ? "#EFF4FF" : "#fff"
                      }`,
                    }}
                  >
                    <img src={card4} style={{ width: "30%" }} alt="" />
                    &nbsp;&nbsp; Credit Card
                  </Button>
                  <Grid item style={{ marginTop: "3%" }} />
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => setPaymentMethod("db")}
                    className={classes.margin}
                    style={{
                      width: "75%",
                      color: `${paymentMethod === "db" ? "black" : "#A7C2B5"}`,
                      background: `${
                        paymentMethod === "db" ? "#EFF4FF" : "#fff"
                      }`,
                    }}
                  >
                    <img src={card4} style={{ width: "30%" }} alt="" />
                    &nbsp;&nbsp; Debit Card
                  </Button>
                  <Grid item style={{ marginTop: "3%" }} />
                  <Button
                    variant="outlined"
                    size="large"
                    onClick={() => setPaymentMethod("ach")}
                    className={classes.margin}
                    style={{
                      width: "75%",
                      color: `${paymentMethod === "ach" ? "black" : "#A7C2B5"}`,
                      background: `${
                        paymentMethod === "ach" ? "#EFF4FF" : "#fff"
                      }`,
                    }}
                  >
                    <img src={accountIcon} style={{ width: "25%" }} alt="" />
                    &nbsp;&nbsp; Bank Account
                  </Button>
                </Grid>
                <Grid item xs={windowWidth ? 8 : 12}>
                  {paymentMethod !== "ach" ? (
                    <>
                      <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={4}>
                          <img
                            src={paymentCard}
                            style={{ width: "70%" }}
                            alt=""
                          />
                        </Grid>
                        <Grid item xs={3} />
                      </Grid>
                      <Grid container>
                        <Grid item xs={windowWidth ? 6 : 12}>
                          <TextField
                            required
                            error={validationError.cardNumber}
                            className={classes.halfInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label={`${
                              paymentMethod === "cc"
                                ? "Credit Card Number"
                                : "Debit Card Number"
                            }`}
                            value={values.cardNumber}
                            onChange={(e) =>
                              onChange(e.target.value, "cardNumber")
                            }
                            name="cardNumber"
                            id="cardNumber"
                            helperText={`${
                              validationError.cardNumber
                                ? "Enter Card Number"
                                : ""
                            }`}
                          />
                          <TextField
                            error={validationError.expiryDate}
                            className={classes.halfInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label="Expiry Date"
                            value={values.expiryDate}
                            onChange={(e) =>
                              onChange(e.target.value, "expiryDate")
                            }
                            name="expiryDate"
                            id="expiryDate"
                            helperText={`${
                              validationError.cardNumber
                                ? "Enter Expiry Date"
                                : ""
                            }`}
                          />
                          <TextField
                            error={validationError.cvv}
                            className={classes.halfInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            // eslint-disable-next-line react/jsx-no-duplicate-props
                            inputProps={{ maxLength: 3 }}
                            type="password"
                            label="CVV"
                            value={values.cvv}
                            onChange={(e) => onChange(e.target.value, "cvv")}
                            name="cvv"
                            id="cvv"
                            helperText={`${
                              validationError.cvv ? "cvv should be 3 digit" : ""
                            }`}
                          />
                        </Grid>
                        <Grid item xs={windowWidth ? 6 : 12}>
                          <Cards
                            cvc={values.cvv}
                            callback={(data) => setCardType(data.issuer)}
                            expiry={values.expiryDate}
                            name={`${values.firstName} ${values.lastName}`}
                            number={values.cardNumber}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          <TextField
                            error={validationError.bankAccountNumber}
                            className={classes.halfInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label="Account Number"
                            value={values.bankAccountNumber}
                            onChange={(e) =>
                              onChange(e.target.value, "bankAccountNumber")
                            }
                            name="bankAccountNumber"
                            id="bankAccountNumber"
                            helperText={`${
                              validationError.bankAccountNumber
                                ? "Enter Account Number"
                                : ""
                            }`}
                          />
                        </Grid>
                        <Grid item xs={2} />
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          <TextField
                            error={validationError.bankRouting}
                            className={classes.halfInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label="Rounting Number"
                            value={values.bankRouting}
                            onChange={(e) =>
                              onChange(e.target.value, "bankRouting")
                            }
                            name="bankRouting"
                            id="bankRouting"
                            helperText={`${
                              validationError.bankRouting
                                ? "Enter Rounting Number"
                                : ""
                            }`}
                          />
                        </Grid>
                        <Grid item xs={2} />
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} />
                        <Grid item xs={8}>
                          <FormControlLabel
                            value="Checking"
                            control={
                              <Checkbox
                                onClick={() =>
                                  onChange(
                                    values.bankAccountType === "Checking"
                                      ? ""
                                      : "Checking",
                                    "bankAccountType"
                                  )
                                }
                                checked={values.bankAccountType === "Checking"}
                                color="secondary"
                              />
                            }
                            label="Checking"
                            labelPlacement="Checking"
                          />
                          <FormControlLabel
                            value="Savings"
                            control={
                              <Checkbox
                                onClick={() =>
                                  onChange(
                                    values.bankAccountType === "savings"
                                      ? ""
                                      : "savings",
                                    "bankAccountType"
                                  )
                                }
                                checked={values.bankAccountType === "savings"}
                                color="secondary"
                              />
                            }
                            label="Savings"
                            labelPlacement="Savings"
                          />
                        </Grid>
                        <Grid item xs={2} />
                      </Grid>
                    </>
                  )}
                  <Grid container>
                    <Grid item xs>
                      <Divider
                        variant="middle"
                        style={{ marginTop: "2%", marginBottom: "2%" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs>
                      <div
                        style={{
                          margin: "1%",
                          fontSize: "18px",
                          color: "#00aab4",
                          fontFamily: "Source Sans Pro",
                        }}
                      >
                        Billing Information:
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <TextField
                        required
                        error={validationError.firstName}
                        className={classes.halfInput}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          color: "secondary",
                          classes: {
                            root: classes.activeInputColor,
                            focused: "focused",
                            input: classes.resize,
                          },
                          required: true,
                        }}
                        label="First Name"
                        value={values.firstName}
                        onChange={(e) => onChange(e.target.value, "firstName")}
                        name="firstName"
                        id="firstName"
                        helperText={`${
                          validationError.firstName
                            ? "Please Enter First Name"
                            : ""
                        }`}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        error={validationError.lastName}
                        className={classes.halfInput}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          color: "secondary",
                          classes: {
                            root: classes.activeInputColor,
                            focused: "focused",
                            input: classes.resize,
                          },
                          required: true,
                        }}
                        label="Last Name"
                        value={values.lastName}
                        onChange={(e) => onChange(e.target.value, "lastName")}
                        name="lastName"
                        id="lastName"
                        helperText={`${
                          validationError.lastName
                            ? "Please Enter Last Name"
                            : ""
                        }`}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        required
                        error={validationError.email}
                        className={classes.fullInput}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          color: "secondary",
                          classes: {
                            root: classes.activeInputColor,
                            focused: "focused",
                            input: classes.resize,
                          },
                          required: true,
                        }}
                        label="Email"
                        value={values.email}
                        onChange={(e) => onChange(e.target.value, "email")}
                        name="email"
                        id="email"
                        helperText={`${
                          validationError.email ? "Please Enter Email" : ""
                        }`}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        required
                        error={validationError.address1}
                        className={classes.fullInput}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          color: "secondary",
                          classes: {
                            root: classes.activeInputColor,
                            focused: "focused",
                            input: classes.resize,
                          },
                          required: true,
                        }}
                        label="Address-Line 1"
                        value={values.address1}
                        onChange={(e) => onChange(e.target.value, "address1")}
                        name="address1"
                        id="address1"
                        helperText={`${
                          validationError.address1
                            ? "Please Enter Address-Line 1"
                            : ""
                        }`}
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextField
                        className={classes.fullInput}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          color: "secondary",
                          classes: {
                            root: classes.activeInputColor,
                            focused: "focused",
                            input: classes.resize,
                          },
                          required: true,
                        }}
                        label="Address-Line 2"
                        value={values.address2}
                        onChange={(e) => onChange(e.target.value, "address2")}
                        name="address2"
                        id="address2"
                      />
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <TextField
                        required
                        error={validationError.city}
                        className={classes.halfInput}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          color: "secondary",
                          classes: {
                            root: classes.activeInputColor,
                            focused: "focused",
                            input: classes.resize,
                          },
                          required: true,
                        }}
                        label="City"
                        value={values.city}
                        onChange={(e) => onChange(e.target.value, "city")}
                        name="City"
                        id="city"
                        helperText={`${
                          validationError.city ? "Please Enter City" : ""
                        }`}
                      />
                    </Grid>
                    <Grid item xs={6} />
                  </Grid>
                  <Grid container>
                    <Grid item xs={6}>
                      <TextField
                        required
                        error={validationError.state}
                        className={classes.halfInput}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          color: "secondary",
                          classes: {
                            root: classes.activeInputColor,
                            focused: "focused",
                            input: classes.resize,
                          },
                          required: true,
                        }}
                        label="State Code"
                        value={values.state}
                        onChange={(e) => onChange(e.target.value, "state")}
                        name="state"
                        id="state"
                        helperText={`${
                          validationError.state ? "Please Enter State" : ""
                        }`}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        required
                        error={validationError.zipCode}
                        className={classes.halfInput}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused",
                          },
                        }}
                        InputProps={{
                          color: "secondary",
                          classes: {
                            root: classes.activeInputColor,
                            focused: "focused",
                            input: classes.resize,
                          },
                          required: true,
                        }}
                        label="Zip Code"
                        value={values.zipCode}
                        onChange={(e) => onChange(e.target.value, "zipCode")}
                        name="zipCode"
                        id="zipCode"
                        helperText={`${
                          validationError.zipCode ? "Please Enter Zip Code" : ""
                        }`}
                      />
                    </Grid>
                  </Grid>
                  <Grid container style={{ marginTop: "5%" }}>
                    <Grid iten xs={3} />
                    <Grid item xs={9} textAlign="center">
                      <Button
                        style={{ fontSize: "18px" }}
                        variant="contained"
                        disabled={!billingValidationError}
                        className={classes.buttonStyle}
                        // style={{
                        //   backgroundColor: "rgb(3, 164, 48)",
                        //   borderRadius: "5px",
                        //   padding: "5px 10px",
                        //   textTransform: "none",
                        //   color: "#fff",
                        //   fontSize: "0.875rem",
                        //   lineHeight: "24px",
                        // }}
                        onClick={() => sendOtpBeforePayment()}
                      >
                        {`${
                          paymentMode === "Downpay"
                            ? "Pay Now"
                            : "Setup Monthly"
                        }`}{" "}
                        {`${
                          paymentMode === "Downpay"
                            ? props.formData.paymentDetailsData &&
                              props.formData.paymentDetailsData.data.amount
                            : values.amount
                        }`}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {handleConfirmation && (
            <PaymentConfirmation
              paymentMode={paymentMode}
              values={values}
              setPaymentStatus={() => setPaymentStatus()}
              setShowPaymentDetails={() => setShowPaymentDetails(true)}
              setConfirmation={() => setConfirmation(false)}
              {...props}
            />
          )}
          {handleFail && (
            <PaymentErrorMessage
              values={values}
              setRetryPayment={() => setRetryPayment()}
              setShowPaymentDetails={() => setShowPaymentDetails(true)}
              setHandleFail={() => setHandleFail(false)}
              {...props}
            />
          )}
          {handleOtpConfirmation && (
            <PaymentOtp
              values={values}
              onFormSubmit={() => onFormSubmit(true)}
              setOtpConfirmation={() => setOtpConfirmation(false)}
              resendOTP={() => sendOTPtoConfim()}
              {...props}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

PaymentOffer.propTypes = {
  patientMakePayment: PropTypes.func,
  beforePaymentSendOTP: PropTypes.func,
  formData: PropTypes.object,
  history: PropTypes.object,
  state: PropTypes.func,
  emptyGraphData: PropTypes.func,
  setUpMonthlyOffer: PropTypes.func,
  patientDashboard: PropTypes.object,
  paymentTokenCheckout: PropTypes.func,
  app: PropTypes.object,
  tableData: PropTypes.object,
};

const mapStateToProps = (app) => ({
  formData: app.genrateOfferPatient,
  patientDashboard: app.patientDashboard,
  app: app.app,
  tableData: app.patientDashboard,
});

export default connect(mapStateToProps, {
  patientMakePayment,
  beforePaymentSendOTP,
  emptyGraphData,
  setUpMonthlyOffer,
  paymentTokenCheckout,
})(PaymentOffer);
