/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-duplicate-props */
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Card from "components/Card/Card";
import isEmpty from "lodash/isEmpty";
import Button from "components/CustomButtons/Button";
import React, { useState } from "react";
import { Container, Paper, Typography, Grid, Box } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import TextField from "@material-ui/core/TextField";
import {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  getPatientOfferData,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  getPersonalOfferData
} from "Redux/Actions/genrateOfferPatient";
import {
  getPaymentDetailsList,
  getOfferDetailsPatient,
  errorReset
} from "Redux/Actions/PatientDashboard";
import { useSnackbar } from "notistack";
import ButtonOriginal from "@material-ui/core/Button";
import { financeOfferListFetchCall } from "../../actions/Patient.action";
import { getAxiosAPI } from "../../actions/AxiosAPIMethods";

// const useStyles = makeStyles(styles);
const useStyles = makeStyles({
  financerLogo: { objectFit: "contain", height: "80px", width: "100px" },
  offersText: {
    fontWeight: 400,
    fontSize: "22px",
    padding: "20px",
    lineHeight: "28px"
  },
  offersText2: {
    fontWeight: 400,
    fontSize: "14px",
    padding: "0 20px",
    lineHeight: "28px"
  },
  cardMain: {
    borderRadius: "15px",
    margin: "20px",
    padding: "20px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    backgroundColor: "#FFFFFF"
  },
  offersCard: {
    borderRadius: "15px",
    margin: "20px",
    padding: "24px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    backgroundColor: "#FFFFFF"
  },
  tpoLevel: {
    fontSize: "32px !important",
    fontWeight: "600",
    lineHeight: "56px"
  },
  toplevelBox: {
    backgroundColor: "#FFD568",
    paddingTop: "3px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "55px",
    height: "55px",
    borderRadius: "10px"
  },
  leftItem: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: 'space-between',
    margin: "auto 0px"
  },
  rightItem: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: 'right',
    alignItems: "flex-end",
    margin: "auto 0px"
  },
  leftText: {
    margin: "auto 20px"
  },
  mainHeading: {
    fontSize: "26px !important",
    fontWeight: "600",
    lineHeight: "30px"
  },
  bottomPara: {
    fontSize: "16px !important",
    fontWeight: "400",
    lineHeight: "22px"
  },
  balance: {
    fontSize: "18px !important",
    fontWeight: "600",
    lineHeight: "22px",
    color: "#27337D",
    padding: "0 15px"
  },
  balanceDigit: {
    fontSize: "48px !important",
    fontWeight: "600",
    lineHeight: "52px"
    // color: '#27337D'
  },
  offersHeading: {
    fontSize: "28px !important",
    fontWeight: "600",
    lineHeight: "30px"
  },
  offersPara: {
    fontSize: "14px !important",
    fontWeight: "400",
    lineHeight: "18px",
    color: "#777777",
    paddingTop: "5px"
  },
  offersPara2: {
    fontSize: "14px !important",
    fontWeight: "400",
    lineHeight: "18px",
    color: "#777777",
    paddingTop: "5px",
    textAlign: "center",
    margin: "10px 0px"
  },
  acceptButton: {
    backgroundColor: "#008768",
    borderRadius: "5px",
    width: "180px",
    textTransform: "none",
    color: "#fff",
    fontSize: "18px !important",
    lineHeight: "22px",
    fontWeight: "400",
    margin: "0 20px"
  },
  showoffersBtn: {
    backgroundColor: "#008768",
    borderRadius: "5px",
    width: "180px",
    textTransform: "none",
    color: "#fff",
    fontSize: "18px !important",
    lineHeight: "22px",
    fontWeight: "400",
    margin: "20px 20px",
    padding: "15px 10px"
  },
  paymentBtn: {
    backgroundColor: "#0079BD",
    borderRadius: "5px",
    padding: "10px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "400",
    fontSize: "20px !important",
    lineHeight: "24px"
  },
  drawerBtns: {
    backgroundColor: "#008768",
    borderRadius: "5px",
    padding: "12px 50px",
    textTransform: "none",
    color: "#fff",
    padding: "20px 0",
    fontWeight: "400",
    fontSize: "20px !important",
    lineHeight: "24px",
    margin: "10px 0px"
  },
  drawerBtns2: {
    // backgroundColor: '#008768',
    borderRadius: "5px",
    padding: "12px 50px",
    textTransform: "none",
    padding: "20px 0",
    // color: '#fff',
    fontWeight: "400",
    fontSize: "20px !important",
    lineHeight: "24px",
    margin: "10px 0px"
  },
  sendOffersBtn: {
    backgroundColor: "#0079BD",
    borderRadius: "5px",
    padding: "10px 30px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "400",
    fontSize: "20px !important",
    lineHeight: "24px",
    transform: "rotate(-90deg)",
    position: "fixed",
    // top: '10px',
    // left: '200px',
    right: "-40px",
    bottom: "300px"
  },
  drawerInput: {
    width: "350px",
    margin: "10px 0px",
    // padding: '10px 0px',
    height: "40px"
  },
  backBtn: {
    color: "#27337D",
    marginBottom: "50px",
    cursor: "pointer"
  },
  btnPairs: {
    margin: "80px 0px",
    display: "flex",
    flexDirection: "column"
  }
});

const NewOfferPayment = (props) => {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const sendApiLink = useSelector((state) => state.app.sendClientIdApi);
  const [preSendEmailResult, setPreSendEmailResult] = React.useState(
    props.tableData.createOffer
  );
  const [prePaymentDetails, setPrePaymentsDetails] = React.useState(
    props.tableData.paymentDetailsData
  );
  const [preError, setPreError] = React.useState(props.patientDashboard.error);
  const [preSetUpMonthlyPayment, setPreSetUpMonthlyPayment] = React.useState(
    props.tableData.setUpMonthlyPayment
  );
  const [showOfferDEtails, setShowOfferDEtails] = React.useState({});
  const [preGenrateOffer, setPreGenrateOffer] = React.useState();

  const [preEmailSend, setPreEmailSend] = React.useState(
    props.tableData.sendEmailOffer
  );
  const [prePaymentList, setPrePaymentList] = React.useState(
    props.patientDashboard.paymentList
  );
  const [loanOffers, setLoanOffers] = React.useState({});
  const [preOfferDetails, setPreOfferDetails] = React.useState(
    props.patientDashboard.offetDetailsPatient
  );
  const [emailType, setEmailType] = React.useState(false);
  const [setUpMonthlyModal, setSetUpMonthlyModal] = React.useState(false);
  const [prePrintOfferData, setPrePrintOfferData] = React.useState(
    props.tableData.printOfferData
  );
  const [pageTypePrint, setPageTypePrint] = React.useState(false);
  const [defaultData, setDefaultData] = React.useState(false);

  const getLoanOfferList = () => {
    const oId = {
      offerId: props.tableData.genrateOfferData.data[0].Monthly[0].offerId,
      patientId: props.tableData.selectPatient.patientId,
      amount:
        props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced,
      clientId: props.tableData.selectPatient.data.Provider_Id
    };
    props.financeOfferListFetchCall(oId, (data) => {
      setLoanOffers(data);
    });
  };

  const [values, setValues] = React.useState({
    downPayment: ""
    //   props?.tableData?.generateOfferData.data[0]?.Monthly[0]?.downPayment,
    // monthlyPayment:
    //   props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment,
    // terms: props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth,
    // startDate: props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
    // maturityDate:
    //   props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate,
    // apr: props.tableData.genrateOfferData.data[0].Monthly[0].APR,
    // dailyIntrest:
    //   props.tableData.genrateOfferData.data[0].Monthly[0].dailyInterestCharge,
  });

  const action = (key) => (
    <>
      <Button
        onClick={() => {
          sendEmailSuccess();
          closeSnackbar(key);
        }}
      >
        'Dismiss'
      </Button>
    </>
  );

  React.useEffect(() => {
    if (preGenrateOffer !== props.tableData.genrateOfferData) {
      setPreGenrateOffer(props.tableData.genrateOfferData);
      if (
        props.tableData.genrateOfferData &&
        props.tableData.genrateOfferData.data
      ) {
      }
    }
    if (
      prePaymentDetails !== props.tableData.paymentDetailsData &&
      !props.tableData.printOfferData
    ) {
      setPrePaymentsDetails(props.tableData.paymentDetailsData);
      if (props.tableData.paymentDetailsData) {
        props.state();
        props.errorReset();
        props.history.push("/tpo/payments");
      }
    }
    if (prePaymentList !== props.patientDashboard.paymentList) {
      setPrePaymentList(props.patientDashboard.paymentList);
      if (
        props.patientDashboard.paymentList &&
        props.patientDashboard.paymentList.status &&
        props.patientDashboard.offetDetailsPatient &&
        props.patientDashboard.offetDetailsPatient.status
      ) {
        props.history.push("/patient/patientPaymentDetails");
      }
    }
    if (preError !== props.patientDashboard.error) {
      setPreError(props.patientDashboard.error);
      const amount =
        props.tableData.createOffer.data.payType === 1
          ? Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].finalPayAmount
                .toString()
                .replace("$", "")
            )
          : Number(
              props.tableData.genrateOfferData.data[0].Monthly[0].downPayment
                .toString()
                .replace("$", "")
            );
      const data = {
        patientId: showOfferDEtails.data.Patient_id,
        offerId: props.tableData.createOffer.data.OfferId,
        amount,
        paymentId: ""
      };
      props.patientPaymentDetailsCall(data);
    }
    if (preOfferDetails !== props.patientDashboard.offetDetailsPatient) {
      setPreOfferDetails(props.patientDashboard.offetDetailsPatient);
      if (
        props.patientDashboard.paymentList &&
        props.patientDashboard.paymentList.status &&
        props.patientDashboard.offetDetailsPatient &&
        props.patientDashboard.offetDetailsPatient.status
      ) {
        props.history.push("/patient/patientPaymentDetails");
      }
    }
    if (preSendEmailResult !== props.tableData.createOffer) {
      setPreSendEmailResult(props.tableData.createOffer);
      if (props.tableData.createOffer.status) {
        if (emailType) {
          const printRequest = {
            offerId: props.tableData.createOffer.data.OfferId
          };
          props.sendEmailApiCall(printRequest);
          setEmailType(false);
        }
        if (
          props.tableData.createOffer.data.payType === 1 ||
          props.tableData.createOffer.data.payType === 2
        ) {
          if (!pageTypePrint && !emailType) {
            const paymentListPatient = {
              patientId: showOfferDEtails.data.Patient_id
            };
            const offerDetailsdata = {
              offerId: props.tableData.createOffer.data.OfferId
            };
            props.getPaymentDetailsList(paymentListPatient);
            props.getOfferDetailsPatient(offerDetailsdata);
          } else if (!emailType) {
            const printRequest = {
              patientId: showOfferDEtails.data.Patient_id,
              offerId: props.tableData.createOffer.data.OfferId
            };
            props.printOfferCall(printRequest);
          }
        }
      }
    }
    if (preEmailSend !== props.tableData.sendEmailOffer) {
      setPreEmailSend(props.tableData.sendEmailOffer);
      if (props.tableData.sendEmailOffer.status) {
        // setSendEmailModal(true);
        enqueueSnackbar(
          "Offer Details are sent successfully to registered email id.",
          {
            variant: "success",
            persist: true,
            action
          }
        );
      }
    }
    if (prePrintOfferData !== props.tableData.printOfferData) {
      if (props.tableData.printOfferData.status) {
        props.state();
        props.history.push("/admin/printoffer");
      }
      setPrePrintOfferData(props.tableData.printOfferData);
    }
    if (
      prePaymentDetails !== props.tableData.paymentDetailsData &&
      !props.tableData.printOfferData
    ) {
      setPrePaymentsDetails(props.tableData.paymentDetailsData);
      if (props.tableData.paymentDetailsData) {
        props.state();
        props.history.push("/tpo/payments");
      }
    }
    if (
      prePaymentDetails !== props.tableData.paymentDetailsData &&
      props.tableData.printOfferData &&
      !emailType
    ) {
      setPrePaymentsDetails(props.tableData.paymentDetailsData);
      if (props.tableData.paymentDetailsData) {
        props.state();
        props.history.push("/tpo/payments");
      }
    }
    if (preSetUpMonthlyPayment !== props.tableData.setUpMonthlyPayment) {
      setPreSetUpMonthlyPayment(props.tableData.setUpMonthlyPayment);
      setSetUpMonthlyModal(true);
    }
  }, [props]);

  const handleSetupMonthlyPayment = () => {
    props.setUpMonthlyOffer(true);
    const amount =
      props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment;
    const paymentListPatient = {
      patientId: showOfferDEtails.data.Patient_id
    };
    const offerDetailsdata = {
      offerId: props.tableData.createOffer.data.OfferId
    };
    props.getPaymentDetailsList(paymentListPatient);
    props.getOfferDetailsPatient(offerDetailsdata);
  };

  const sendEmailSuccess = () => {
    props.state();
    props.history.push("/admin/Offers");
    // setSendEmailModal(false);
  };

  const handleResetOffer = () => {
    const data = {
      amount: props.tableData.defaultValueOffer.amount,
      terms: props.tableData.defaultValueOffer.terms
    };
    if (props.tableData.defaultValueOffer.offerId) {
      data.offerId = props.tableData.defaultValueOffer.offerId;
    }
    props.genrateOfferHyperLink(data);
  };

  const handleBack = () => {
    const data = {
      commonText: "",
      ClientId: props.app.sendClientIdApi.toString(),
    };
    props.patientSearch(data);
    props.state();
    props.history.push("/admin/offerDetails");
  };

  const handlePaymentOffer = (type, preValue) => {
    let data = {};
    if (type === 1 || type === 2) {
      setPageTypePrint(false);
      if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
        data = {
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr:
            type === 2
              ? Number(
                  props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
                    "%",
                    ""
                  )
                )
              : Number(
                  props.tableData.genrateOfferData.data[0].Discounted[0].discount
                    .toString()
                    .replace("%", "")
                ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            type === 2
              ? props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate
              : 0,
          termsMonth:
            type === 2
              ? props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth
              : 0,
          perEMIAmt:
            type === 2
              ? Number(
                  props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
                    .toString()
                    .replace("$", "")
                )
              : 0,
          offerStatus: 1,
          downPayment:
            type === 2
              ? Number(values.downPayment.toString().replace("$", ""))
              : 0,
          proposalIds: props.tableData.preposalDataIds,
          offerType: type
        };
      } else if (
        props.tableData.genrateOfferData.data[0].Discounted[0].offerId
      ) {
        data = {
          offerId:
            props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr:
            type === 2
              ? Number(
                  props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
                    "%",
                    ""
                  )
                )
              : Number(
                  props.tableData.genrateOfferData.data[0].Discounted[0].discount
                    .toString()
                    .replace("%", "")
                ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            type === 2
              ? props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate
              : 0,
          termsMonth:
            type === 2
              ? props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth
              : 0,
          perEMIAmt:
            type === 2
              ? Number(
                  props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
                    .toString()
                    .replace("$", "")
                )
              : 0,
          offerStatus: 1,
          downPayment:
            type === 2
              ? Number(values.downPayment.toString().replace("$", ""))
              : 0,
          proposalIds: props.tableData.preposalDataIds,
          offerType: type
        };
      }
    } else if (type === 0 && !props.tableData.paymentMakeDetails) {
      if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
        setPageTypePrint(true);
        data = {
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
              "%",
              ""
            )
          ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate,
          termsMonth:
            props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth,
          perEMIAmt: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
              .toString()
              .replace("$", "")
          ),
          offerStatus: 3,
          downPayment: Number(values.downPayment.toString().replace("$", "")),
          proposalIds: props.tableData.preposalDataIds,
          offerType: 2
        };
        props.createOfferCall(data);
      } else if (
        props.tableData.genrateOfferData.data[0].Discounted[0].offerId
      ) {
        setPageTypePrint(true);
        data = {
          offerId:
            props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
              "%",
              ""
            )
          ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate,
          termsMonth:
            props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth,
          perEMIAmt: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
              .toString()
              .replace("$", "")
          ),
          offerStatus: 3,
          downPayment: Number(values.downPayment.toString().replace("$", "")),
          proposalIds: props.tableData.preposalDataIds,
          offerType: 2
        };
        props.createOfferCall(data);
      }
    } else if (type === 4) {
      setEmailType(true);
      if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
        data = {
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
              "%",
              ""
            )
          ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate,
          termsMonth:
            props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth,
          perEMIAmt: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
              .toString()
              .replace("$", "")
          ),
          offerStatus: 2,
          downPayment: Number(values.downPayment.toString().replace("$", "")),
          proposalIds: props.tableData.preposalDataIds,
          offerType: 2
        };
      } else if (
        props.tableData.genrateOfferData.data[0].Discounted[0].offerId
      ) {
        data = {
          offerId:
            props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
          clientId: showOfferDEtails.data.proposalList.filter(
            (key) => key._id === props.tableData.preposalDataIds[0]
          )[0].Client_ID,
          patientId: showOfferDEtails.data.Patient_id,
          totalEstimate: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          copayDeductables: Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
              .toString()
              .replace("$", "")
          ),
          apr: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].APR.toString().replace(
              "%",
              ""
            )
          ),
          startDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].startDate,
          endDate:
            props.tableData.genrateOfferData.data[0].Monthly[0].maturityDate,
          termsMonth:
            props.tableData.genrateOfferData.data[0].Monthly[0].termsMonth,
          perEMIAmt: Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].monthlyPayment
              .toString()
              .replace("$", "")
          ),
          offerStatus: 3,
          downPayment: Number(values.downPayment.toString().replace("$", "")),
          proposalIds: props.tableData.preposalDataIds,
          offerType: 2
        };
      }
    }

    if (
      type === 0 &&
      props.tableData.paymentMakeDetails &&
      props.tableData.paymentMakeDetails.status &&
      props.tableData.createOffer.data.payType === 2
    ) {
      const printRequest = {
        patientId: preValue
          ? preValue.data.Patient_id
          : showOfferDEtails.data.Patient_id,
        offerId: props.tableData.createOffer.data.OfferId
      };
      props.printOfferCall(printRequest);
    }
    if (type !== 0) {
      props.createOfferCall(data);
    }
  };

  // ================================= NEW CODE ===========================
  const selectedPatient = JSON.parse(
    localStorage.getItem("selectedPatient") || "{}"
  );

  const [preOfferState, setPresOfferState] = useState([]);
  const generateOfferByAxios = async () => {
    const amount = selectedPatient?.data?.proposalList[0]?.Self_Pay;
    console.log(`called generateOfferByAxios for${amount}`);
    const url = `/patients/generate-offer?amount=${amount}`;
    await getAxiosAPI(url).then((res) => {
      if (res.error) {
        console.log(res.error);
      } else {
        setPresOfferState(res.data);
      }
    });
  };
  React.useEffect(() => {
    if (Object.keys(selectedPatient).length) {
      setShowOfferDEtails(selectedPatient);
      // generateOfferByAxios();
    }
  }, [props]);

  const [state, setState] = useState({
    right: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Grid style={{ margin: "50px" }}>
      <Typography
        onClick={() => setState({ ...state, right: false })}
        className={classes.backBtn}
      >
        <KeyboardBackspaceRoundedIcon style={{ paddingTop: "10px" }} /> Back
      </Typography>
      <Typography className={classes.offersPara}>Email</Typography>
      <TextField
        className={classes.drawerInput}
        label="Email"
        variant="outlined"
      />
      <Typography className={classes.offersPara2}>Or</Typography>
      <Typography className={classes.offersPara}>Mobile (Optional)</Typography>
      <TextField
        className={classes.drawerInput}
        label="Mobile"
        variant="outlined"
      />
      <Grid className={classes.btnPairs}>
        <Button variant="contained" className={classes.drawerBtns}>
          Send
        </Button>
        <Button
          onClick={() => setState({ ...state, right: false })}
          variant="contained"
          className={classes.drawerBtns2}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <Card>
      <Grid container style={{ padding: "1%", paddingTop: "2%" }}>
        <Grid spacing={2} container xs={9} sm={9} item>
          <Grid
            xs={12}
            sm={12}
            lg={6}
            md={12}
            style={{
              alignSelf: "center"
            }}
            item
          >
            <div className={classes.optionAvailableText}>
              Patient Name: &nbsp;&nbsp;
              {`${showOfferDEtails?.data?.Patient_Name}`}
            </div>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            lg={4}
            md={12}
            style={{
              display: "inline-flex",
              flexWrap: "wrap",
              flexDirection: "column"
            }}
            item
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexWrap: "nowrap"
              }}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center"
          }}
        >
          <ButtonOriginal
            className={classes.clearButton}
            onClick={() => handleBack()}
          >
            Back
          </ButtonOriginal>
        </Grid>
        <div
          style={{
            marginLeft: "4%",
            marginRight: "4%",
            width: "-webkit-fill-available"
          }}
        >
          <Grid item xs={4} sm={4} className={classes.amountOwed}>
            <Typography style={{ color: "white", padding: "2%" }} variant="h5">
              {/* {`Amount Owed: $${props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced}`} */}
            </Typography>
          </Grid>
        </div>

        {/* ==========================================NEW UI ====================================== */}
        <Container>
          <Grid lg={12} container className={classes.cardMain}>
            <Grid xs={12} sm={6} className={classes.leftItem}>
              <Box className={classes.toplevelBox}>
                <Typography className={classes.tpoLevel}>
                  {Math.round(showOfferDEtails?.data?.tpoLevel) === 0
                    ? 0
                    : Math.round(showOfferDEtails?.data?.tpoLevel) === 1
                    ? 1
                    : Math.round(showOfferDEtails?.data?.tpoLevel) === 2
                    ? 2
                    : Math.round(showOfferDEtails?.data?.tpoLevel) === 3
                    ? 3
                    : Math.round(showOfferDEtails?.data?.tpoLevel) === 4
                    ? 4
                    : ""}
                </Typography>
              </Box>

              <Grid className={classes.leftText}>
                <Typography className={classes.mainHeading}>
                  TPO Level
                </Typography>
                <Typography className={classes.bottomPara}>
                  Level is calculated using The Patient Offer Algorithm
                </Typography>
              </Grid>
            </Grid>
            <Grid xs={12} sm={6} className={classes.rightItem}>
              <Typography className={classes.balance}>
                Current Balance
              </Typography>
              <Typography className={classes.balanceDigit}>
                {" "}
                ${" "}
                {/* {
                  props.tableData.genrateOfferData.data[0].Discounted[0]
                    .amountFinanced
                } */}
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.offersCard}>
            <Grid xs={4} sm={2}>
              <img
                className={classes.financerLogo}
                // key={loan.financerLogo}
                // src={loan.financerLogo}
                alt="TPO Logo"
                loading="eager"
              />
            </Grid>
            <Grid xs={4} sm={2}>
              <Typography className={classes.offersHeading}>
                ${" "}
                {/* {
                  props.tableData.genrateOfferData.data[0].Discounted[0]
                    .amountFinanced
                } */}
              </Typography>
              <Typography className={classes.offersPara}>
                Total Amount
              </Typography>
            </Grid>
            <Grid xs={4} sm={2}>
              <Typography className={classes.offersHeading}>
                ${" "}
                {/* {
                    props.tableData.genrateOfferData.data[0].Discounted[0]
                      .discountAmount
                  } */}
              </Typography>
              <Typography className={classes.offersPara}>
                Discount Offered
              </Typography>
            </Grid>
            <Grid xs={4} sm={2}>
              <Typography className={classes.offersHeading}>
                {/* {
                  props.tableData.genrateOfferData.data[0].Discounted[0]
                    .discount
                } */}
              </Typography>
              <Typography className={classes.offersPara}>
                Discount Rate
              </Typography>
            </Grid>
            <Grid xs={4} sm={2}>
              <Typography className={classes.offersHeading}>
                ${" "}
                {/* {
                  props.tableData.genrateOfferData.data[0].Discounted[0]
                    .finalPayAmount
                } */}
              </Typography>
              <Typography className={classes.offersPara}>Net Amount</Typography>
            </Grid>

            <Grid xs={4} sm={2}>
              <Button
                onClick={() => handlePaymentOffer(1)}
                variant="contained"
                className={classes.acceptButton}
              >
                Accept Discount
              </Button>
            </Grid>
          </Grid>

          {/* {loanOffers?.status ? ( */}

          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "20px 0"
            }}
          >
            <Typography className={classes.offersText}>
              The following payment plans are available to you:
            </Typography>
            <Typography className={classes.offersText2}>
              *Values are estimated
            </Typography>
          </Grid>
          {/* // ) : ( */}
          <Grid style={{ display: "flex", justifyContent: "center" }}>
            <Button
              disabled={loanOffers?.status}
              onClick={() => getLoanOfferList()}
              variant="contained"
              className={classes.showoffersBtn}
            >
              Show Offers
            </Button>
          </Grid>
          {/* // )} */}
          {/* {loanOffers?.data?.map((loan, i) => ( */}
          <Grid container className={classes.offersCard}>
            <Grid xs={4} sm={2}>
              <img
                className={classes.financerLogo}
                // key={loan.financerLogo}
                // src={loan.financerLogo}
                alt="Current Logo"
                loading="eager"
              />
            </Grid>
            <Grid xs={4} sm={2}>
              <Typography className={classes.offersHeading}>
                {/* {loan.APR} */}%
              </Typography>
              <Typography className={classes.offersPara}>APR*</Typography>
            </Grid>
            <Grid xs={4} sm={2}>
              <Typography className={classes.offersHeading}>
                month {/* {loan.monthlyPayment} */}
              </Typography>
              <Typography className={classes.offersPara}>
                Monthly Payment*
              </Typography>
            </Grid>
            <Grid xs={4} sm={2}>
              <Typography className={classes.offersHeading}>
                {/* {loan.termsMonth}  */}
                mos.
              </Typography>
              <Typography className={classes.offersPara}>Term*</Typography>
            </Grid>
            <Grid xs={4} sm={2}>
              <Typography className={classes.offersHeading}>
                dow{/* {loan.downPayment} */}
              </Typography>
              <Typography className={classes.offersPara}>
                Down Payment
              </Typography>
            </Grid>
            <Grid xs={4} sm={2}>
              <Button
                variant="contained"
                onClick={() => handlePaymentOffer(2)}
                className={classes.acceptButton}
              >
                Accept Offer
              </Button>
              <Typography
                style={{ padding: "5px 18px" }}
                className={classes.offersPara}
              >
                Offer details, rates & fees
              </Typography>
            </Grid>
          </Grid>
          {/* ))} */}

          {/* <Grid style={{ textAlign: "center", margin: "20px" }}>
            <Button variant="contained" className={classes.paymentBtn}>Return to Payment Options</Button>
          </Grid> */}

          <div>
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <Button
                  onClick={toggleDrawer(anchor, true)}
                  variant="contained"
                  className={classes.sendOffersBtn}
                >
                  Send Offers
                </Button>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </React.Fragment>
            ))}
          </div>
        </Container>
      </Grid>
      {/* <PaymentSection
        handleSendEmail={() => handlePaymentOffer(4)}
        handleSetupMonthlyPayment={() => handleSetupMonthlyPayment()}
        handleDownOnTerm={() => handleDownOnTerm()}
        handleDownOnMonth={() => handleDownOnMonth()}
        handleDownOnBlur={() => handleDownOnBlur()}
        setShowFinalOfferIn={() => handlePaymentOffer(0)}
        handleResetOffer={() => handleResetOffer()}
        {...props}
        ={}
        handleSetupMonthly={() => handleSetupMonthly()}
        handleOnchange={(v, k) => handleOnchange(v, k)}
        showOfferDEtails={showOfferDEtails}
        handlePaymentOffer={(data) => handlePaymentOffer(data)}
        onClick={() => {}}
      /> */}
      {/* <Modal open={sendEmailModal} onClose={() => setSendEmailModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div className={classes.cardDiv}>
              <img src={gif} width="50%" />
              <div className={classes.successText}>
                Offer Details are sent successfully to registered email id.
              </div>
              <br />
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="sm"
                onClick={() => sendEmailSuccess(false)}
              >
                Ok
              </Button>
            </div>
          </Card>
        </div>
      </Modal> */}
    </Card>
  );
  // <Card><FinalGenrateOffer handleSendEmailSuccess={() => handleSendEmailSuccess()} ={} {...props} showOfferDEtails={showOfferDEtails} onClick={() => handlePruntBack()} /></Card>
};

NewOfferPayment.propTypes = {
  patientSearch: PropTypes.func,
  tableData: PropTypes.object,
  sendEmailApiCall: PropTypes.object,
  uploadOfferDocument: PropTypes.func,
  updatePatientDetailsOffer: PropTypes.func,
  getGenrateOfferData: PropTypes.func,
  createOfferCall: PropTypes.func,
  printOfferCall: PropTypes.func,
  genrateOfferHyperLink: PropTypes.func,
  deleteDocumentCall: PropTypes.func,
  patientPaymentDetailsCall: PropTypes.func,
  getPatientOfferData: PropTypes.func,
  emptyGraphData: PropTypes.func,
  cptCodeDataCall: PropTypes.func,
  editPatientCall: PropTypes.func,
  getAllActivePlanList: PropTypes.func,
  getPaymentDetailsList: PropTypes.func,
  patientDashboard: PropTypes.object,
  getOfferDetailsPatient: PropTypes.func,
  getAllDocumentList: PropTypes.func,
  offerDownloadFile: PropTypes.func,
  state: PropTypes.func,
  history: PropTypes.object,
  addSetUpMonthlyPayment: PropTypes.func,
  getAllCompletedPlanList: PropTypes.func,
  setUpMonthlyOffer: PropTypes.func,
  setLoadingFalse: PropTypes.func,
  errorReset: PropTypes.func,
  getPersonalOfferData: PropTypes.func,
  financeOfferListFetchCall: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  patientDashboard: app.patientDashboard,
  userProvider: app.users
});

export default connect(mapStateToProps, {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  getPersonalOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  getOfferDetailsPatient,
  getPatientOfferData,
  getPaymentDetailsList,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  financeOfferListFetchCall,
  errorReset
})(NewOfferPayment);
