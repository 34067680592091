import React, { useEffect, useState } from "react";
import { StockTags, openSpinner, closeSpinner } from "@paytheory/pay-theory-ui";
import * as api from "../api/index";

const partner = process.env.REACT_APP_PARTNER;
const stage = process.env.REACT_APP_STAGE;

const BarcodeResult = ({ barcode, amount }) => {
  const [barcodeDetails, setBarcodeDetails] = useState(false);
  const [buttonLabel, setButtonLabel] = useState("Simulate Payment");
  const [statusMessage, setStatusMessage] = useState(<div />);
  const resetStatusMessage = () => setStatusMessage(<div />);

  const [COMPLETE] = useState("SUCCESS");

  const simulatePayment = () => {
    openSpinner();
    const paymentBody = {
      ExternalBillerID: process.env.REACT_APP_BILLER_ID,
      CardNumber: barcode.BarcodeUid,
      amount: amount / 100,
      date: "2021-03-02T03:26:43.8167113Z",
      transactionId: "14141312312234",
      merchantId: "merchant123",
      storeID: "store3456",
      terminalID: "terminal1234",
      card: null
    };

    api
      .postPayment(paymentBody)
      .then((json) => {
        setButtonLabel(COMPLETE);
        closeSpinner();
      })
      .catch((error) => {
        setStatusMessage(
          StockTags.error(
            "Payment Did Not Post to Cash Service",
            resetStatusMessage
          )
        );
        closeSpinner();
      });
  };

  return (
    <div className={`barcode-completion ${barcode ? "" : "hidden"}`}>
      <div className="barcode-header">
        <h2>Barcode Generated</h2>
        <button
          name="complete-cash-button"
          onClick={simulatePayment}
          disabled={buttonLabel === COMPLETE}
        >
          {buttonLabel}
        </button>
      </div>
      <div className="content">
        <div className="barcode">
          <iframe
            title="barcode"
            src={barcode.barcodeUrl}
            className="barcode-iframe"
            frameBorder="0"
          />
          <a
            href={barcode.barcodeUrl}
            target="_blank"
            rel="noopener norefferer noreferrer"
            className="barcode-link"
          >
            Open Barcode in New Tab
          </a>
        </div>
        <div className="map">
          <iframe
            title="paymap"
            src={barcode.mapUrl}
            allow={`geolocation 'self' https://${partner}.react.example.${stage}.com`}
            className="map-iframe"
            frameBorder="0"
          />
          <a
            href={barcode.mapUrl}
            target="_blank"
            rel="noopener norefferer noreferrer"
            className="barcode-link"
          >
            Open Map in New Tab
          </a>
        </div>
      </div>
      <style jsx="true" global="true">
        {`
          .barcode-completion {
            margin: 16px;
            overflow: hidden;
            transition: opacity 0.3s ease;
            opactity: 1;
            display: flex;
            flex-direction: column;
          }
          .barcode-completion.hidden {
            width: 0px;
            height: 0px;
            margin: 0px;
            opacity: 0;
            transition: opacity 0.3s ease;
          }
          .barcode-iframe {
            height: 300px;
            width: 100%;
          }
          .map-iframe {
            width: 100%;
            height: 600px;
          }

          .barcode-completion .content {
            display: flex;
            flex-wrap: wrap;
          }

          .barcode-completion .barcode,
          .barcode-completion .map {
            padding: 0px 8px 16px 0px;
          }

          .barcode-completion .barcode-header {
            display: flex;
            align-items: bottom;
            padding: 0px 0px 16px 0px;
          }
          .barcode-completion .barcode-header h2 {
            padding: 0px 16px 0px 0px;
          }
        `}
      </style>
      {statusMessage}
    </div>
  );
};

export default BarcodeResult;
