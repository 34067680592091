import { get } from "./httpClient";
import { LOGIN_URL, LOGIN_OTP_URL, COUNTRY_CODE_URL } from "../Uintls/Urls";

export const loginAction =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(LOGIN_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getOtpAction =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(LOGIN_OTP_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const getCountryList =
  (successCallback, failureCallback) => async () => {
    try {
      const data = await get(COUNTRY_CODE_URL);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
