import { Box } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

import PropTypes from "prop-types";
import React from "react";

import noDataImage from "../../assets/img/no_data_found.jpg";

const TableBodyCustom = function (props) {
  const { children, data, headCells } = props;

  if (data.length) {
    return <TableBody>{children}</TableBody>;
  }
  if (!data.length) {
    return (
      <TableBody>
        <TableRow>
          <TableCell className="no-data" colSpan={headCells.length + 1}>
            <Box py={6}>
              <img className="no-data-found" src={noDataImage} alt="" />
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
    );
  }
  return null;
};

TableBodyCustom.propTypes = {
  children: PropTypes.element,
  data: PropTypes.array,
  headCells: PropTypes.array,
};

TableBodyCustom.defaultProps = {
  children: null,
  data: [],
  headCells: [],
};

export default TableBodyCustom;
