import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const app = (state = initialState.app, action) => {
  switch (action.type) {
  case types.LOGIN_API:
    return { ...state, loading: true, userDetails: false };
  case types.SET_LOGIN:
    return {
      ...state,
      loading: false,
      success: true,
      userDetails: action.data,
      loggedIn: true,
      token: action.data.token,
    };
  case types.SET_ERROR:
    return { ...state, error: true };
  case types.SET_LOUGOUT:
    return { ...state, userDetails: false };
  case types.SET_NAME_APPBAR:
    return { ...state, appBarName: action.data };
  case types.SET_LAYOUT_IN_APP:
    return { ...state, layout: action.data };
  case types.SET_CLIENT_THEME:
    return {
      ...state, appTheme: action.data.data, loading: false, mainAppLoading: false,
    };
  case types.CLIENT_THEME_CALL:
    return { ...state, loading: true, mainAppLoading: true };
  case types.CLIENT_THEME_ERROR:
    return {
      ...state, error: true, loading: false, mainAppLoading: false,
    };
  case types.DEFAULT_THEME_CALL:
    return { ...state, loading: true, mainAppLoading: true };
  case types.DEFAULT_THEME_SUCCESS:
    return {
      ...state, defaultTheme: action.data, loading: false, mainAppLoading: false,
    };
  case types.DEFAULT_THEME_ERROR:
    return { ...state, error: true, loading: false };
  case types.CLIENT_PORTAL_CONFIG_CALL:
    return { ...state, loading: true };
  case types.CLIENT_PORTAL_CONFIG_SUCCESS:
    return { ...state, portalConfig: action.data, loading: false };
  case types.CLIENT_PORTAL_CONFIG_ERROR:
    return { ...state, error: true, loading: false };
  case types.USER_LEFT_MENU_CALL:
    return { ...state, loading: true };
  case types.USER_LEFT_MENU_SUCCESS:
    return { ...state, leftMenu: action.data, loading: false };
  case types.USER_LEFT_MENU_ERROR:
    return { ...state, error: true, loading: false };
  case types.SET_CLIENT_API_LIST:
    return { ...state, sendClientIdApi: action.data };
  default:
    return state;
  }
};

export default app;
