import React from "react";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Menu from "@material-ui/core/Menu";
import { makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import isEmpty from "lodash/isEmpty";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  getRoleDetailsById,
  changeRoleFormMode,
  getLandingPageList,
} from "../../Redux/Actions/RoleRegistration";

const useStyles = makeStyles(styles);

function LongMenu(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [userOptions, setUserOptions] = React.useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (type) => {
    if (type === "edit") {
      props.getRoleDetailsById(props.details);
      props.getLandingPageList(props.details);
      props.changeRoleFormMode("Edit");
    }
    setAnchorEl(null);
  };

  const associateclientForm = () => {
    props.openassociateModel();
    props.setStatusPid();
    handleClose();
  };

  const openStatusForm = () => {
    props.openForm();
    props.setStatusPid();
    handleClose();
  };

  React.useEffect(() => {
    const menu = JSON.parse(localStorage.getItem("menu"));
    if (menu) {
      setUserOptions(
        menu.Role_Permission_Data.LeftMenu[2].Page_Menu[0].Options || []
      );
    }
  }, []);

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <MenuItem className={classes.dialogBoxDropDown} onClick={handleClose}>
          View
        </MenuItem>
        {props.editable && (
          <MenuItem
            className={classes.dialogBoxDropDown}
            onClick={() => handleClose("edit")}
          >
            Edit
          </MenuItem>
        )}
        <MenuItem
          disabled={isEmpty(userOptions) ? true : !userOptions[3].permission}
          className={classes.dialogBoxDropDown}
          onClick={openStatusForm}
        >
          Change Status
        </MenuItem>
        {localStorage.getItem("clientId") ===
          process.env.REACT_APP_ACCESS_SERVICE_PROVIDER_ID && (
          <MenuItem
            className={classes.dialogBoxDropDown}
            onClick={() => associateclientForm()}
          >
            Associated Users
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}

LongMenu.propTypes = {
  details: PropTypes.string,
  getRoleDetailsById: PropTypes.func,
  changeRoleFormMode: PropTypes.func,
  openForm: PropTypes.func,
  setStatusPid: PropTypes.func,
  getLandingPageList: PropTypes.func,
  editable: PropTypes.bool,
  openassociateModel: PropTypes.func,
};

const mapStateToProps = (app) => ({
  formData: app.roles,
});

export default connect(mapStateToProps, {
  getRoleDetailsById,
  changeRoleFormMode,
  getLandingPageList,
})(LongMenu);
