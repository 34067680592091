const offerDetailStyles = theme => ({
  optionHeader: {
    margin: "1%",
    display: "flex",
    flexDirection: "row",
    marginRight: "4%",
  },
  optionAvailableText: {
    display: "flex",
    flexDirection: "row",
    fontSize: "18px",
    whiteSpace: "nowrap",
    marginTop: "20px",
    marginBottom: "20px",
  },
  offerDetailsTopHeading: {
    marginTop: "20px",
    marginBottom: "20px",
    paddingLeft:"20px"
  },
  optionPatientName: {
    fontSize: "18px",
    cursor: "pointer",
  },
  tpoLevelMargin: {
    marginTop: "1%",
    marginLeft: "1%",
  },
  clearButton: {
    textTransform: "none",
    backgroundColor: "#868686",
    height: "30px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#868686",
    },
  },
  modalDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "stretch",
  },
  modalCard: {
    width: "100%",
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    display: "initial",
  },
  margin: {
    margin: "1%",
    marginTop: "0px",
    marginBottom: "0px",
  },
  modalCardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  cardDiv: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    marginBottom: "10%",
    marginTop: "10%",
    overflow: "auto",
    height: "70vh",
  },
  cardDiv1: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10%",
    marginTop: "10%",
    overflow: "auto",
    textAlign: "center",
  },
  cellTextHeader: {
    fontSize: "80%",
    fontFamily: "Source Sans Pro",
    color: theme.palette.primary.dark,
    fontWeight: "bold",
  },
  cellText: {
    fontSize: "80%",
    fontFamily: "Source Sans Pro",
    color: theme.palette.primary.dark,
  },

  totalScoreHeading: {
    fontSize: "0.875rem",
    fontWeight: "bold",
  },
  totalScoreText: {
    fontSize: "0.875rem",
    fontWeight: "bold",
    fontFamily: "Source Sans Pro",
    color: theme.palette.primary.dark,
  },
  tpoLevelfooter: {
    alignItems: "center",
    marginTop: "-9%",
  },
  scoreText: {
    color: "#000000",
    fontSize: "18px",
    fontWeight: "bold",
    padding: "5px",
    paddingRight: "9%",
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    height: "30px",
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  errorIconStyle: {
    fontSize: "60px",
    color: "#FF0000",
  },
  errorIconImage: {
    width: "50%",
  },
  successText: {
    color: "gray",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },

  activeTab: {
    border: "2px solid",
    borderColor: theme.palette.offerDetails.activeTabBorderColor,
    backgroundColor: theme.palette.offerDetails.activeTab,
    // fontWeight: "bold",
  },
  inActiveTab: {
    color: "black",
    backgroundColor: theme.palette.offerDetails.inActiveTab,
    border: "2px solid",
    borderColor: theme.palette.offerDetails.inActiveBorderColor,
  },
  tabHeader: {
    borderTopColor: "black",
    // borderTop: "2px solid rgb(13, 22, 25)",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
  },
  hrStyleOfferDetails: {
    position: "relative",
    margin: "0%",
    marginBottom: "40px",
    marginLeft: "20px",
    marginRight: "20px",
    border: "1px solid #efefef",
  },
  hrStyleOfferDetailsHorizontal: {
      borderLeft:" 1px solid  balck",
      height: "20px",
      position: "absolute",
      left: "190px",
      top: "10px"
  },
  
});

export default offerDetailStyles;
