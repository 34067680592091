import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const paymentReducer = (state = initialState.payment, action) => {
  switch (action.type) {
  case types.SET_CHECKOUT_DATA:
    return { ...state, data: action.data };
  default:
    return state;
  }
};

export default paymentReducer;
