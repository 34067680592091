import {
  defaultBoxShadow,
  transition,
} from "assets/jss/material-dashboard-react";

const setupStyles = (theme) => ({
  tittleColor: {
    backgroundColor: theme.palette.tableColor.header,
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    paddingTop: ".7%",
    height: "2rem",
  },
  tittleTextColor: {
    color: theme.palette.tableColor.cellFontColor,
    fontSize: "120%",
    fontStyle: "Source Sans Pro",
  },
  margin: {
    margin: "1%",
    marginTop: "0px",
    marginBottom: "0px",
  },
  textColor: {
    color: "#595c5c",
    fontSize: "90%",
    fontStyle: "Source Sans Pro",
  },
  modalDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "stretch",
  },
  input: {
    width: "80%",
  },
  cardWidth: {
    width: "100%",
  },
  iconBox: {
    backgroundColor: theme.palette.info.contrastText,
  },
  tncBox: {
    height: "40px",
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    borderColor: "black",
  },
  buttonBox: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alighItem: "center",
    margin: "1.1rem",
  },
  ownerHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "1%",
  },
  dialogBoxDropDown: {
    width: "auto",
    color: theme.palette.primary.dark,
    overflow: "hidden",
    marginRight: "2%",
    marginLeft: "2%",
    fontSize: ".7rem",
    boxSizing: "border-box",
    fontFamily: "Source Sans Pro",
    fontWeight: "200",
    lineHeight: "1.5",
    "&:hover": {
      backgroundColor: "#00aab4",
      color: "#fff",
    },
  },
  successText: {
    color: "gray",
    fontSize: "14px",
    whiteSpace: "nowrap",
  },
  statusContainer: {
    margin: "1%",
    justifyContent: "flex-end",
  },
  marginStatusItem: {
    marginRight: "2%",
  },
  ownerTittle: {
    marginTop: ".5rem",
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    height: "40px",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  clearButton: {
    textTransform: "none",
    backgroundColor: theme.palette.primary.dark,
    height: "40px",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  inputBox: {
    margin: "10px",
    marginTop: "40px",
    color: "#bfbdbd",
  },
  statusHeadingText: {
    textAlign: "center",
    width: "90%",
    fontSize: "16px",
  },
  offerPageMargin: {
    margin: "3%",
  },
  canvasBackgourd: {
    backgroundColor: "#dbdbdb",
  },
  statusModalHeader: {
    display: "flex",
    height: "50px",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#c2bfb8",
    padding: "1%",
  },
  activeInputLableColor: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro",
    fontStyle: "Source Sans Pro",
    "&.focused": {
      color: theme.palette.primary.main,
    },
  },
  root: {
    width: "80%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Source Sans Pro",
    },
  },
  rootSelectInput: {
    width: "80%",
    marginBottom: "10%",
    fontSize: "90%",
    fontStyle: "Source Sans Pro",
  },
  marginBox: {
    margin: "3%",
  },
  resize: {
    fontSize: "14px",
    fontStyle: "Source Sans Pro",
    color: theme.palette.primary.dark,
  },
  checkBox: {
    padding: "0px",
  },
  cardDiv: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    margin: "10% 10%",
  },
  modalCard: {
    width: "40%",
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  modalRoot: {
    width: "40%",
    marginTop: "10%",
    height: "auto",
    padding: "30px",
    paddingTop: "10px",
    alignItems: "center",
    justifyContent: "center",
  },
  fullInput: {
    width: "93.2%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Source Sans Pro",
    },
  },
  halfInput: {
    width: "87%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Source Sans Pro",
    },
  },
  halfInputSingle: {
    width: "87%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Source Sans Pro",
    },
  },
  withoutOption: {
    width: "26.8%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Source Sans Pro",
    },
  },
  quaterInput: {
    width: "73%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Source Sans Pro",
    },
  },
  addIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
  },
  deleteIcon: {
    color: theme.palette.primary.contrastText,
    backgroundColor: "#ff4747",
    "&:hover": {
      color: theme.palette.primary.contrastText,
      backgroundColor: "#ff4747",
    },
  },
  dialogWidth: {
    width: "100%",
  },
  calender: {
    zIndex: "10002",
    position: "absolute",
    width: "auto",
    ...defaultBoxShadow,
    ...transition,
  },
  rootDialog: {
    width: "100%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Source Sans Pro",
    },
  },
});

export default setupStyles;
