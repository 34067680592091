import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import SignatureCanvas from "react-signature-canvas";
import { Typography } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle";

const useStyles = makeStyles(styles);
const TncAccept2 = props => {
  const classes = useStyles();
  return (<div>
    <Box className={classes.margin}>
      <Grid container className={classes.margin}>
        <Grid item xs>
          <div>
            {`I ${props.preValues.patientName} do here by agree to pay ${props.preValues.copay} full payment from my outstanding`}
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.margin}>
        <Grid item xs className={classes.displayFlex}>
          <Typography className={classes.datetnc}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date:
          </Typography>
          <div>
            {" "}
            {props.preValues.dateOfProcedure}
            {" "}
          </div>
        </Grid>
      </Grid>
      <Grid container className={classes.margin}>
        <Grid item xs className={classes.displayFlex}>
          <Typography className={classes.datetnc}>
            E-Signature: &nbsp;
          </Typography>
          <SignatureCanvas
            penColor="black"
            canvasProps={{ width: 250, height: 70, className: classes.canvasBackgourd }} />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs>
          <Typography className={classes.thanksColor}>Thanks for the Payment</Typography>
        </Grid>
        <Grid item xs />
        <Grid item xs>
          <Button variant="contained" className={classes.buttonStyle} size="md">
            Confirm and Pay
          </Button>
          <Button variant="contained" className={classes.buttonStyle} size="md">
            Print
          </Button>
        </Grid>
      </Grid>
    </Box>
  </div>);
};

TncAccept2.propTypes = {
  preValues: PropTypes.any,
};

export default TncAccept2;
