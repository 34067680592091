// let isLoader;

export const StyleVariables = {
  // Colors
  lightNavy: '#354DD4',
  lightGrey: '#a1a0ae',
  mutedColor: '#888888',
  whiteColor: '#fff',
  appBackground: '#fff',
  grey: '#F4F6F9',
  defaultGrey: '#e0e0e0',
  warningColor: '#fff8cb',
  alertColor: '#fcd6d3',
  // Meserments
  fullHeight: '100%',
  fullWidth: '100%',
  // Alignment
  alignAuto: 'auto',
  alignLeft: 'left',
  alignRight: 'right',
  alignCenter: 'center',
  alignMiddle: 'middle',
  // Spacing
  spacingZero: '0',
  spacingHalf: '0.5rem',
  spacingOne: '1rem',
  spacingTwo: '2rem',
  // None value
  none: 'none',
  // positions
  absolute: 'absolute',
  relative: 'relative',
  fixed: 'fixed',
  static: 'static',
  // Display
  displayFlex: 'flex',
  displayInlineFlex: 'inline-flex',
  displayBlock: 'block',
  spaceBetween: 'space-between',
  flexDirectionColumn: 'column',
  flexDirectionRow: 'row',
  alignItemsStart: 'flex-start',
  alignItemsEnd: 'flex-end',

  // Cursor
  pointer: 'pointer',
  cursorMove: 'move',
  //Decoration
  underline: 'underline',
  // Header Shadow
  headerShadow:
    '0px 2px 5px -1px rgba(85, 108, 214, 0.2), ' +
    '0px 1px 15px 10px rgba(85, 108, 214, 0.14), ' +
    '0px 1px 10px 0px rgba(85, 108, 214, 0.12)',
  //Card Shadow
  cardShadow:
    '0px 2px 5px -1px rgba(85, 108, 214, 0.2), ' +
    '0px 1px 15px 5px rgba(85, 108, 214, 0.14), ' +
    '0px 1px 10px 0px rgba(85, 108, 214, 0.12)',
}
