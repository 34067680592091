import { post, put, get } from "./httpClient";
import {
  UPDATE_GROUP_DATA_URL,
  CREATE_NEW_GROUP_URL,
  QUESTION_REPORT_CHANGE_STATUS,
  QUESTION_REPORT_KYC_CHANGE_STATUS,
  KYC_STATUS_APPROVE_CALL,
  MPA_STATUS_APPROVE_CALL,
  MPA_ATTECHMENT_CHECKLIST_REVIEW_SAVE,
  KYC_ATTECHMENT_CHECKLIST_REVIEW_SAVE,
  CHECKLIST_COMMENT_SAVE,
  AUTO_GENERATED_REPO_CODE,
  PATIENT_FORGET_PASSWORD_URL,
  USER_REGISTRATION_URL,
  GET_USER_PROFILE_IMAGE,
} from "../Uintls/Urls";

export const createNewGroupApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(CREATE_NEW_GROUP_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updateGroupApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(UPDATE_GROUP_DATA_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusPreameter =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(QUESTION_REPORT_CHANGE_STATUS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusPreameterKyc =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(QUESTION_REPORT_KYC_CHANGE_STATUS, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusApproveMpa =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(MPA_STATUS_APPROVE_CALL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const statusApproveKyc =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(KYC_STATUS_APPROVE_CALL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const mpaChecklistComment =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(CHECKLIST_COMMENT_SAVE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const kycChecklistAttechment =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(KYC_ATTECHMENT_CHECKLIST_REVIEW_SAVE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const mpaChecklistAttechment =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(MPA_ATTECHMENT_CHECKLIST_REVIEW_SAVE, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const confirmForgetPassword =
  (payload, successCallback, failureCallback) => async () => {
    try {
      // const url = `${PATIENT_FORGET_PASSWORD_URL}?email=${payload.email}&countryCode=${payload.countryCode}`;
      const data = await get(PATIENT_FORGET_PASSWORD_URL, payload);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const autoGeneratedRepCode =
  (successCallback, failureCallback) => async () => {
    try {
      // const url = `${PATIENT_FORGET_PASSWORD_URL}?email=${payload.email}&countryCode=${payload.countryCode}`;
      const data = await get(AUTO_GENERATED_REPO_CODE);
      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const createNewUserApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(USER_REGISTRATION_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const updateUserApiCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(USER_REGISTRATION_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

  export const updateProfileImage =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await put(GET_USER_PROFILE_IMAGE, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };