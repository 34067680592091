/* eslint-disable camelcase */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_moonrisekingdom from "@amcharts/amcharts4/themes/moonrisekingdom";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
// import exportCss from '../../assets/css/amchart/export';

function CptDustribution(props) {
  am4core.useTheme(am4themes_moonrisekingdom);
  am4core.useTheme(am4themes_animated);
  useEffect(() => {
    if (document.getElementById("cptchart") !== null) {
      const chart = am4core.create("cptchart", am4charts.PieChart3D);
      chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
      chart.angle = 40;
      chart.depth = 45;

      const title = chart.titles.create();
      title.text = "\nCPT Distributions";
      title.fontSize = 13;
      title.fontFamily = "Source Sans Pro";
      title.paddingBottom = 10;
      title.align = "center";
      title.fontWeight = "bold";
      if (props.dashboardData.cptDistributionData && props.dashboardData.cptDistributionData.data) {
        // Add data
        chart.data = props.dashboardData.cptDistributionData.data;
      }

      const series = chart.series.push(new am4charts.PieSeries3D());
      series.dataFields.value = "count";
      series.dataFields.category = "cptCode";
      series.labels.template.disabled = true;
      series.tooltip.label.wrap = true;
      series.tooltip.label.width = 200;
      series.legendSettings.valueText = " ";
      let colorSet = new am4core.ColorSet();
      colorSet.list = ["#c6f700", "#f60102", "#c603f7", "#0262f7", "#03f766"].map(function(color) {
        return new am4core.color(color);
      });
      series.colors = colorSet;
      // series.slices.template.tooltipText = "{cptCode}";
      series.slices.template.tooltipHTML = `<table width="200px">
<tr>
<center style="font-size:12px;">{cptCode}</center>
<hr />
</tr>
<tr>
<center style=" white-space: nowrap; font-size: 14px; margin-right:10%"><b>{count} </b>({value.percent.formatNumber('#.0')}%)</center>
</tr>
</table>`;

      const titles = chart.titles.push(new am4core.Label());
      titles.textAlign = "top";
      titles.wrap = true;

      // chart.innerRadius = am4core.percent(40);
      // chart.depth = 30;

      chart.legend = new am4charts.Legend();
      chart.legend.fontWeight = 400;
      chart.legend.position = "right";
      chart.legend.markers.template.paddingLeft = "2";
      chart.legend.markers.template.paddingTop = "2";
      chart.legend.markers.template.paddingRight = "2";
      chart.legend.markers.template.paddingBottom = "2";

      chart.radius = "100%";
      chart.angle = 30;
      chart.responsive = new am4core.Responsive();
      chart.responsive.enabled = true;
      chart.logo.disabled = true;

      /* suresh's changes */
      chart.legend.fontSize = 10;

      const marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 2;
      marker.strokeOpacity = 1;

      const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;

      // series.tooltip.autoTextColor = false;
      // series.tooltip.label.fill = am4core.color("#000000");
      chart.tooltip.label.fontSize = 2;
      chart.tooltip.label.maxWidth = 150;
      chart.tooltip.label.wrap = true;
      // pieSeries.labels.template.fill = am4core.color("white");

      // chart.tooltip.fontWeight=100;

      /* ************************** */
    }
  }, [props.dashboardData.cptDistributionData]);
  return (
    <div id="cptchart" style={{ width: "100%", height: "20rem" }} />
  );
}

CptDustribution.propTypes = {
  dashboardData: PropTypes.object,
};

export default CptDustribution;
