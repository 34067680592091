import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import { connect } from "react-redux";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import { recent_patient_data } from "../../Storage/RecentPatient";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650
  },
  cellText: {
    fontSize: "70%",
    fontStyle: "Source Sans Pro",
    color: theme.palette.primary.dark,
    padding: "2.5%",
    borderBottom: "0px",
    whiteSpace: "nowrap"
  },
  more: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "5px",
    paddingRight: "10px",
    color: "#9a9a9a",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3)
  },
  noWrap: {
    whiteSpace: "nowrap",
    marginLeft: "10%",
    borderBottom: "0px"
  },
  avtar: {
    margin: "1%"
  },
  mailLinkColor: {
    color: "#9a9a9a",
    fontSize: "13px",
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  patientDetails: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  patientNameColor: {
    fontSize: "80%",
    fontStyle: "Source Sans Pro",
    color: theme.palette.primary.main
  },
  margin: {
    width: "100%"
  },
  searchContainer: {
    display: "flex",
    paddingLeft: "10px",
    flexDirection: "row",
    padding: "1%"
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main
  },
  searchWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end"
  },
  root: {
    width: "30%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontStyle: "Source Sans Pro"
    }
  },
  overLayBox: {
    position: "absolute",
    width: "35%",
    marginTop: "4%",
    padding: "1%",
    zIndex: 5,
    cursor: "pointer",
    backgroundColor: theme.palette.primary.contrastText,
    boxShadow: "1px 1px 2px white, 0 0 25px #d6d0d0, 0 0 5px #737373",
    marginRight: "5%"
  }
}));

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  }
}));

function createData(patientName) {
  return {
    patientName
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography>First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography>Previous</Typography>
        )}
      </IconButton>
      <IconButton onClick={handleFirstPageButtonClick} aria-label="first page">
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography>Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography>Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

function RecentPatients(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(9);

  if (props.dashboardData.recentContactData) {
    rows = [];
    props.dashboardData.recentContactData.data.forEach((v, i) => {
      const patientName = v.patientName;
      rows.push(createData(patientName));
    });
  }

  return (
    <>
      <TableContainer component={Paper} style={{ overflow: "hidden" }}>
        <Table aria-label="simple table">
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow>
                <TableCell className={classes.cellText}>
                  <div className={classes.patientDetails}>
                    <Avatar
                      alt="Remy Sharp"
                      src={require("assets/img/marc.jpg")}
                      className={classes.small}
                    />
                    <div className={classes.noWrap}>{row.patientName}</div>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.more}>
          <a className={classes.mailLinkColor} href="/admin/activePatient">
            More
            {">>"}
          </a>
        </div>
      </TableContainer>
    </>
  );
}

RecentPatients.propTypes = {
  dashboardData: PropTypes.object
};

const mapStateToProps = ({ app }) => ({
  userData: app.userDetails
});
export default connect(mapStateToProps, null)(RecentPatients);
