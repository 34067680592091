/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import isEmpty from "lodash/isEmpty";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import noDataImage from "../../assets/img/noDataImage.png";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { patientSearch } from "Redux/Actions/genrateOfferPatient";
import { connect, useSelector } from "react-redux";

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

const useStyles = makeStyles(tableStyle);

const StyledTableCell = withStyles((theme) => ({
  root: {
    padding: "12px"
  },
  body: {
    padding: "2px"
  }
}))(TableCell);

function createData(
  patientName,
  ssn,
  email,
  phone,
  dob,
  pendingProposal,
  proposalInprocess,
  tpoLevel,
  patientId,
  offerGenrateCount,
  activePlanCount,
  newDueCount,
  newDues,
  data
) {
  return {
    patientName,
    ssn,
    email,
    phone,
    dob,
    pendingProposal,
    proposalInprocess,
    tpoLevel,
    patientId,
    offerGenrateCount,
    activePlanCount,
    newDueCount,
    newDues,
    data
  };
}

const headCells = [
  {
    id: "tpoLevel",
    className: "tpoLevel",
    numeric: false,
    disablePadding: true,
    label: "TPO Level"
  },
  {
    id: "Patient_id",
    className: "Patient_id",
    numeric: false,
    disablePadding: true,
    label: "Patient Id"
  },
  {
    id: "Patient_Name",
    className: "Patient_Name",
    numeric: false,
    disablePadding: true,
    label: "Patient Name"
  },
  {
    id: "SSN",
    className: "SSN",
    numeric: false,
    disablePadding: true,
    label: "SSN Last 4"
  },
  {
    id: "Mobile_Number",
    className: "Mobile_Number",
    numeric: true,
    disablePadding: false,
    label: "Phone"
  },
  {
    id: "activePlans",
    className: "activePlans",
    numeric: false,
    disablePadding: true,
    label: "Active Plans"
  },
  {
    id: "offerGenratedPlans",
    className: "offerGenratedPlans",
    numeric: false,
    disablePadding: true,
    label: "Offer Generated"
  },
  {
    id: "newDuesCount",
    className: "newDuesCount",
    numeric: false,
    disablePadding: true,
    label: "New Dues"
  },
  {
    id: "newDues",
    className: "newDues",
    numeric: false,
    disablePadding: true,
    label: "New Dues ($)"
  }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography
            style={{
              fontFamily: "Source Sans Pro",
              fontSize: "13px",
              textTransform: "uppercase"
            }}
          >
            Last
          </Typography>
        ) : (
          <Typography
            style={{
              fontFamily: "Source Sans Pro",
              fontSize: "13px",
              textTransform: "uppercase"
            }}
          >
            First
          </Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography
            style={{
              fontFamily: "Source Sans Pro",
              fontSize: "13px",
              textTransform: "uppercase"
            }}
          >
            Previous
          </Typography>
        ) : (
          <Typography
            style={{
              fontFamily: "Source Sans Pro",
              fontSize: "13px",
              textTransform: "uppercase"
            }}
          >
            Previous
          </Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography
            style={{
              fontFamily: "Source Sans Pro",
              fontSize: "13px",
              textTransform: "uppercase"
            }}
          >
            Next
          </Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography
            style={{
              fontFamily: "Source Sans Pro",
              fontSize: "13px",
              textTransform: "uppercase"
            }}
          >
            Last
          </Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const PatientTable = (props) => {
  const classes = useStyles();
  const [preTableData, setPreTableData] = React.useState(
    props.tableData.data && props.tableData.data.data
  );
  const [page, setPage] = React.useState(1);
  const [initialCall, setInitialCall] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [order, setOrder] = React.useState("asc");
  const [pageNo, setPageNo] = React.useState(1);
  const [orderBy, setOrderBy] = React.useState("Patient_Name");
  const [searchVlaues, setSearchValues] = React.useState({
    ClientId: props.app.sendClientIdApi && props.app.sendClientIdApi.toString(),
    loginEmail: localStorage.getItem("email"),
    patientName: "",
    balance: "",
    balanceLess: "",
    offerFromDate: "",
    offerToDate: "",
    limit: rowsPerPage,
    sortBy: orderBy,
    orderBy: order,
    page: pageNo
  });

  React.useEffect(() => {
    if (preTableData !== props.tableData.data) {
      setPreTableData(props.tableData.data);
    }
  }, [props]);

  React.useEffect(() => {
    if (props.app.sendClientIdApi && props.app.sendClientIdApi) {
      const data = {
        pageNo: page,
        limit: rowsPerPage,
        sortBy: orderBy,
        orderBy: order,
        commonText: props.commonText,
        ClientId: props.app.sendClientIdApi.toString()
      };
      props.patientSearch(data);
    }
  }, [page, rowsPerPage, orderBy, order, props.app.sendClientIdApi]);

  React.useEffect(() => {
    if (props.tableData.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData]);

  const rows = [];
  if (preTableData) {
    props.tableData.data.data.forEach((v, i) => {
      const patientName = v.Patient_Name;
      const ssn = v.SSN;
      const email = v.Email;
      const phone = v.Mobile_Number;
      const dob = v.DOB;
      const pendingProposal = v.pendingProposal;
      const proposalInprocess = v.proposalInprocess;
      const tpoLevel = v.tpoLevel;
      const patientId = v.Patient_id;
      const offerGenrateCount = v.offerGenratedPlans;
      const activePlanCount = v.activePlans;
      const newDueCount = v.newDuesCount;
      const newDues = v.newDues;
      const data = v;
      rows.push(
        createData(
          patientName,
          ssn,
          email,
          phone,
          dob,
          pendingProposal,
          proposalInprocess,
          tpoLevel,
          patientId,
          offerGenrateCount,
          activePlanCount,
          newDueCount,
          newDues,
          data
        )
      );
    });
  }

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <>
      <TableContainer component={Paper} style={{ borderRadius: "12px" }}>
        <Table
          className={
            !props.showOfferDEtails ? classes.table : classes.patientTable
          }
          aria-label="simple table"
        >
          <ThemeProvider theme={themeFont}>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {!isEmpty(rows) ? (
                rows.map((row, index) => (
                  <TableRow
                    style={{
                      backgroundColor:
                        (index + 1) % 2 === 0 ? "#F6F9FF" : "white"
                    }}
                    key={Math.random()}
                  >
                    <StyledTableCell
                      className={classes.patientNameColor}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        localStorage.setItem(
                          "selectedPatient",
                          JSON.stringify(row)
                        );
                        props.setShowOfferDEtails(row);
                      }}
                      align="center"
                    >
                      {/* {(Math.round(row.tpoLevel) === 0 && (

                  )) ||
                    (Math.round(row.tpoLevel) === 1 && (
                      <img src={FiberManualRecordIcon} alt="" />
                    )) ||
                    (Math.round(row.tpoLevel) === 2 && (
                      <img src={FiberManualRecordIcon} alt="" />
                    )) ||
                    (Math.round(row.tpoLevel) === 3 && (
                      <img src={FiberManualRecordIcon} alt="" />
                    )) ||
                    (Math.round(row.tpoLevel) >= 4 && (
                      <img src={FiberManualRecordIcon} alt="" />
                    ))} */}
                      <div
                        style={{
                          position: "relative",
                          display: "inline-flex",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <FiberManualRecordIcon
                          fontSize="large"
                          style={{
                            color:
                              Math.round(row.tpoLevel) === 0
                                ? "#cfcfcf"
                                : Math.round(row.tpoLevel) === 1
                                ? "#FF0000"
                                : Math.round(row.tpoLevel) === 2
                                ? "#FFA500"
                                : Math.round(row.tpoLevel) === 3
                                ? "#FFFF00"
                                : Math.round(row.tpoLevel) === 4
                                ? "#00FF00"
                                : "#cfcfcf",
                            fontSize: "3rem"
                          }}
                        />
                        <Typography
                          style={{
                            position: "absolute",
                            display: "inline-flex",
                            justifyContent: "center",
                            alignItems: "center",
                            lineHeight: 2,
                            color: "#000",
                            textAlign: "center",
                            fontSize: "1.5em",
                            fontWeight: 700
                          }}
                        >
                          {Math.round(row.tpoLevel) === 0
                            ? 0
                            : Math.round(row.tpoLevel) === 1
                            ? 1
                            : Math.round(row.tpoLevel) === 2
                            ? 2
                            : Math.round(row.tpoLevel) === 3
                            ? 3
                            : Math.round(row.tpoLevel) === 4
                            ? 4
                            : ""}
                        </Typography>
                      </div>
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.cellText}
                      align="center"
                    >
                      {row.patientId}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.patientNameColor}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        localStorage.setItem(
                          "selectedPatient",
                          JSON.stringify(row)
                        );
                        props.setShowOfferDEtails(row);
                      }}
                      align="center"
                    >
                      {row.patientName}
                    </StyledTableCell>

                    <StyledTableCell
                      className={classes.cellText}
                      align="center"
                    >
                      {row.ssn}
                    </StyledTableCell>
                    {!props.showOfferDEtails && (
                      <>
                        <StyledTableCell
                          className={classes.cellText}
                          align="center"
                        >
                          {row.phone}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.cellText}
                          align="center"
                        >
                          {row.activePlanCount}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.cellText}
                          align="center"
                        >
                          {row.offerGenrateCount}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.cellText}
                          align="center"
                        >
                          {row.newDueCount}
                        </StyledTableCell>
                        <StyledTableCell
                          className={classes.cellText}
                          align="center"
                        >
                          {row.newDues}
                        </StyledTableCell>
                      </>
                    )}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <StyledTableCell className="no-data" colSpan={12}>
                    <img src={noDataImage} alt="" />
                  </StyledTableCell>
                </TableRow>
              )}
            </TableBody>
          </ThemeProvider>
          <TableFooter>
            <TablePagination
              style={{ overflow: "hidden" }}
              rowsPerPageOptions={[
                5,
                10,
                25,
                { label: "All", value: 99999999999 }
              ]}
              colSpan={12}
              count={props.tableData.data.totalDocs}
              rowsPerPage={rowsPerPage}
              page={page - 1}
              SelectProps={{
                inputProps: { placeholder: "Show" },
                native: true
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

PatientTable.propTypes = {
  tableData: PropTypes.any,
  patientSearch: PropTypes.func,
  setShowOfferDEtails: PropTypes.func,
  showOfferDEtails: PropTypes.any
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  userProvider: app.users,
  app: app.app
});

export default connect(mapStateToProps, {
  patientSearch
})(PatientTable);
