const patientDashboard = theme => ({
    mainRoot: {
      margin: "1%",
    },
    mainContainerGrid: {
      marginTop: "-5%",
      marginBottom: "-4%",
    },
    duesGrid: {
      borderRadius:"15px",
      background: `#3E4E9B`,
    },
    cardGrid: {
      padding: "14px !important",
    },
    cardHeader: {
      margin: "0px 5px !important",
    },
    cardBodyDocument: {
      backgroundColor: "#c1c0c0",
      marginRight: "32px",
      marginLeft: "32px",
      // marginTop:"12px",
      borderRadius: "12px",
    },
    cardBody: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      textAlign: "center"
  
    },
    typographyAmount: {
      paddingTop: "0%",
      color: "#ffffff",
      fontSize: "12px !important",
      textTransform: "uppercase !important"
    },
    typographyCount: {
      paddingBottom: "0%",
      marginTop: "10px",
      fontWeight: "600",
      fontSize: "48px !important",
    },
    viewDetail: {
      cursor: "pointer",
      fontSize: "14px",
      color: "#27337D",
      fontWeight: "600",
      borderBottom:"1px solid",
      marginLeft:"10px",
      marginBottom:"20px"
    },
    cardFooter: {
      marginTop: "0px",
      paddingLeft:"50px",
      paddingRight:"50px"
    },
    cardDocumentBody: {
      height: "64px",
    },
    cardIcon: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-around",
      float: "none !important",
      padding: "0px !important",
      marginRight: "0px !important",
      background: `linear-gradient(60deg, ${theme.palette.offerDetails.offerDetailsHeaderCardColor1}, ${theme.palette.offerDetails.offerDetailsHeaderCardColor2})`,
      boxShadow: "none",
    },
    cardTitle: {
      color: theme.palette.primary.contrastText,
      marginTop: "10px",
      minHeight: "auto",
      fontWeight: "400",
      fontSize: "24px",
      // fontFamily: "'Source', 'Sans', 'Pro'",
      marginBottom: "10px",
      textDecoration: "none",
      "& small": {
        color: theme.palette.primary.contrastText,
        fontWeight: "400",
        lineHeight: "1",
      },
    },
    AttachmentOutlinedIcon: {
      marginLeft: "-9%",
    },
    gridInnerDocument: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    typographyDate: {
      fontSize: "0.8rem",
      color: "#144b8e",
      // width: "20%",
    },
    typographyOffer: {
      fontSize: "0.8rem",
      color: "#144b8e",
      // width: "51%",
    },
    typographyStatus: {
      fontSize: "0.8rem",
      color: "#144b8e",
      // width: "20%",
    },
    downloadBtn: {
      color: "#ffffff",
      cursor: "pointer",
      width: "8%",
    },
    viewMore: {
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: "600",
      color: "#ffffff",
    },
    modalRoot: {
      width: "50%",
    },
    modalDiv: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
      justifyContent: "center",
      alignItems: "center",
      alignContent: "stretch",
    },
    modalCard: {
      width: "30%",
      marginTop: "10%",
      height: "auto",
      padding: "30px",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
    },
    cellTextHeader: {
      fontSize: "100%",
      fontFamily: "Source Sans Pro",
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    cellText: {
      fontSize: "80%",
      fontFamily: "Source Sans Pro",
      color: theme.palette.primary.dark,
    },
    hrModalGridContainer: {
      marginTop: "12px",
      marginBottom:"0px"
    },
    modalHeaderTitle: {
      display: "flex",
      flexDirection: "row",
      margin: "1%",
      fontSize: "20px",
      whiteSpace: "nowrap",
    },
    modalHeaderName: {
      fontSize: "16px",
      color: "#333333",
      fontWeight: "bold",
      marginTop: "1px",
    },
    modalTableContainer: {
      width: "90%",
      maxHeight: "20rem",
      borderTop: "2px solid #0d1619",
      margin: "2% 4%",
    },
    typographyAmmount: {
      // color: "#ffffff",
      fontSize:"16px",
    },
    backgroundColorDiv: {
      backgroundColor:"#EFF4FF",
      padding: "10px",
    },
  });
  
  export default patientDashboard;
  