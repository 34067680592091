import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import { Field, Form, Formik } from "formik";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  groupsListApi,
  groupsTypeListApi,
  groupsListSearchApi,
} from "../../actions/TableData";
const useStyles = makeStyles(styles);

function SimpleDialog(props) {
  const [values, setValue] = React.useState(props.searchValues);
  const classes = useStyles();
  const {
    onClose,
    searchValues,
    groupTypeList,
    setSearchValues,
    searchChipData,
  } = props;

  const handleClose = () => {
    onClose();
  };

  const onReset = () => {
    setSearchValues({
      groupName: "",
      groupType: "",
      apiType: "search",
      groupStatus: "",
    });
    onFormSubmit({
      groupName: "",
      groupType: "",
      apiType: "search",
      groupStatus: "",
    });
    props.close();
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValue(allState);
  };

  const onFormSubmit = () => {
    const valuesClientID = { ...values };
    valuesClientID.ClientId = props.appData.sendClientIdApi.toString();
    valuesClientID.LoginEmail = localStorage.getItem("email");
    // props.groupsListSearchApi(valuesClientID);
    props.searchChipData(values);
    props.close();
    setSearchValues(values)
    
  };

  return (
    <>
      <Formik
        onSubmit={onFormSubmit}
        enableReinitialize
        initialValues={searchValues}
      >
        {({ setFieldValue }) => (
          <Form>
            <Grid
              container
              className={classes.margin}
              style={{ marginBottom: "5%" }}
            >
              <Grid item xs>
                <TextField
                  // as={TextField}
                  className={classes.rootDialog}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      focused: "focused",
                      input: classes.resize,
                    },
                  }}
                  value={values.groupName}
                  autoComplete="off"
                  label="Group Name"
                  onChange={(e) => onChange(e.target.value, "groupName")}
                  // onChange={(event) => {
                  //   setFieldValue("groupName", event.target.value);
                  // }}
                  name="groupName"
                  id="groupName"
                  style={{ fontSize: "82%" }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ marginBottom: "5%" }}
            >
              <Grid item xs>
                <TextField
                  // as={TextField}
                  select
                  className={classes.rootDialog}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      focused: "focused",
                      input: classes.resize,
                    },
                  }}
                  value={values.groupType}
                  label="Group Type"
                  name="groupType"
                  onChange={(e) => onChange(e.target.value, "groupType")}
                  // onChange={(event) => {
                  //   setFieldValue("groupType", event.target.value);
                  //   setSearchValues({
                  //     ...searchValues,
                  //     groupType: event.target.value,
                  //   });
                  // }}
                  id="groupType"
                  style={{ fontSize: "82%" }}
                >
                  <MenuItem className={classes.dialogBoxDropDown} value="All">
                    All
                  </MenuItem>
                  {groupTypeList.map((type) => (
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value={type.groupTypeCode}
                      key={type._id}
                    >
                      {type.groupTypeName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ marginBottom: "5%" }}
            >
              <Grid item xs>
                <TextField
                  // as={TextField}
                  className={classes.halfInputSingle}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                  }}
                  SelectProps={{
                    MenuProps: { disablePortal: false },
                  }}
                  label="Group Status"
                  value={values.groupStatus}
                  onChange={(e) => onChange(e.target.value, "groupStatus")}
                  // onChange={(event) => {
                  //   setFieldValue("groupStatus", event.target.value);
                  //   setSearchValues({
                  //     ...searchValues,
                  //     groupStatus: event.target.value,
                  //   });
                  // }}
                  name="groupStatus"
                  id="groupStatus"
                  select
                  style={{ fontSize: "82%" }}
                >
                  <MenuItem className={classes.dialogBoxDropDown} value="All">
                    All
                  </MenuItem>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Active"
                  >
                    Active
                  </MenuItem>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="InActive"
                  >
                    InActive
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs className={classes.clearSubmitButton}>
                <br />
                <Button
                  cancel
                  variant="outlined"
                  style={{
                    height: "2.1em",
                    // marginTop: "5px",
                    textTransform: "none",
                  }}
                  size="md"
                  onClick={() => {
                    onReset();
                  }}
                >
                  Clear
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  variant=""
                  color="#ffffff"
                  style={{
                    backgroundColor: "#03A430",
                    textTransform: "none",
                    borderRadius: "5px",
                    height: "30px",
                    color: "#ffffff",
                    //  fontSize: "18px",
                    fontWeight: "600",
                  }}
                  className={classes.buttonStyle}
                  size="md"
                  onClick={() => onFormSubmit()}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  searchValues: PropTypes.object,
  setSearchValues: PropTypes.func,
  groupTypeList: PropTypes.array,
  searchChipData: PropTypes.func,
  groupsTypeListApi: PropTypes.func,
};

const mapStateToProps = (app) => ({
  formData: app.users,
  appData: app.app,
});

export default connect(mapStateToProps, {
  groupsTypeListApi,
})(SimpleDialog);
