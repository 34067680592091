/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Search from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Card from "components/Card/Card";
import TextField from "@material-ui/core/TextField";

import InputAdornment from "@material-ui/core/InputAdornment";
import CustomButton from "components/CustomButtons/Button";
import ErrorIcon from "@material-ui/icons/Error";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  emptyPatientSelect,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  getPatientOfferData,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  setSelectPatientOffer,
  cernerPatientSearch,
  setPatientSearchCommonText
} from "Redux/Actions/genrateOfferPatient";
import Modal from "@material-ui/core/Modal";
import { Typography } from "@material-ui/core";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import Radio from "@material-ui/core/Radio";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import isEmpty from "lodash/isEmpty";
import brandLogo from "../../assets/newImg/ThePatientOffer-72.png";
import OffersStyle from "./OffersStyle";
import PatientTable from "./PatientTable";
import gif from "../../assets/img/success.gif";
import AddPatientForm from "../Patient/AddPatient";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

const GreenRadio = withStyles((theme) => ({
  root: {
    "&$checked": {
      color: theme.palette.primary.main
    }
  },
  checked: {}
}))((props) => <Radio color="default" {...props} />);

const useStyles = makeStyles(OffersStyle);
const PaymentGenrateOffer = (props) => {
  const classes = useStyles();
  const [searchData, setSearchData] = React.useState(props.tableData.data);
  const [selectedSearch, setSelectedSearch] = React.useState("local");
  const [logoPath, setLogoPath] = React.useState("");
  const [searchError, setSearchError] = React.useState(false);
  const [preAddPatientData, setPreAddPatientData] = React.useState(
    props.tableData.addPatientData
  );
  const [addPatientModal, setAddPatientModal] = React.useState(false);
  const [patientModal, setPatiemtModal] = React.useState(false);
  const [showTaable, setShowTable] = React.useState(false);
  const [preCptCodeData, setPreCptCodeData] = React.useState(
    props.tableData.cptCodeData
  );
  const [commonText, setCommonText] = React.useState(
    !props.tableData.patientDocumentList == false
      ? props.tableData.patientSearchData
      : ""
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [back, setBack] = React.useState("");
  const [searchVlaues, setSearchValues] = React.useState({
    commonText: "",
    ClientId: props.app.sendClientIdApi.toString(),
    next: ""
  });
  React.useEffect(() => {
    if (
      props.tableData.activePlanPatientList &&
      props.tableData.completedPlanPatientList &&
      props.tableData.patientDocumentList
    ) {
      // props.state();
      props.history.push("/admin/offerDetails");
    }
    if (searchData !== props.tableData.data) {
      setSearchData(props.tableData.data);
      setShowTable(true);
    }
    if (preCptCodeData !== props.tableData.cptCodeData) {
      setPreCptCodeData(props.tableData.cptCodeData);
      props.history.push("/admin/addpatient");
    }
    if (preAddPatientData !== props.tableData.addPatientData) {
      if (props.tableData.addPatientData.status) {
        setAddPatientModal(true);
        setPatiemtModal(false);
      }
      setPreAddPatientData(props.tableData.addPatientData);
    }
  }, [props]);

  const handlePatiemtModal = () => {
    props.cptCodeDataCall();
  };

  const onClickHandle = () => {
    setBack("Back");
    // setCommonText(value)
    const radioSelected = selectedSearch;
    // const finalSearchData = {
    //   ...value,
    //   commonText: props.tableData.patientSearchData == "" ? "" : props.tableData.patientSearchData.trim(),
    // };
    const data = {
      commonText:
        props.tableData.patientSearchData == ""
          ? ""
          : props.tableData.patientSearchData.trim(),
      ClientId: props.app.sendClientIdApi.toString(),
      next: ""
    };
    if (radioSelected === "local") {
      props.patientSearch(data);
    }

    if (radioSelected === "global") {
      if (commonText === "") {
        setSearchError(true);
      } else {
        setSearchError(false);
        props.cernerPatientSearch(data);
      }
    }
  };

  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      onClickHandle();
    }
  };

  const handlePatientNameSelect = (data) => {
    props.setSelectPatientOffer(data);
    const requestData = {
      patientId: data.data.Patient_id
    };
    // props.getPatientOfferData(requestData);
    const request = {
      patientId: data.data.Patient_id,
      ClientId: props.app.sendClientIdApi.toString()
    };
    props.getAllActivePlanList(request);
    props.getAllDocumentList(request);
    props.getAllCompletedPlanList(request);
  };

  if (isEmpty(logoPath)) {
    const logo_path = new Image();
    if (props.app.portalConfig) {
      const x = props.app.portalConfig.data[0]?.Logo_Image;
      logo_path.src = `${x}?" + ${new Date().getTime()}`;
      logo_path.onload = function () {
        setLogoPath(logo_path.src);
      };
      logo_path.onerror = function () {
        setLogoPath(brandLogo);
      };
    }
  }

  const open = Boolean(anchorEl);

  const refetch = () => {
    setBack("");
    setShowTable(false);
  };

  const onChange = (value) => {
    setCommonText(value);
    props.setPatientSearchCommonText(value);
  };

  return (
    <Card>
      <>
        <Box className={classes.patientBox}>
          <Grid container>
            <Grid item sm={5} xs={5} lg={6}>
              {back === "Back" ? (
                <Grid className={classes.searchAddButtons}>
                  <Button
                    style={{ textTransform: "none" }}
                    color="primary"
                    onClick={() => refetch()}
                  >
                    <KeyboardBackspaceIcon /> &nbsp; Back to Search
                  </Button>
                </Grid>
              ) : (
                <Grid className={classes.searchAddButtons}>
                  {/* <Button
                style={{ textTransform: "none" }}
                onClick={() => refetch()}
              >
                 {back}
              </Button> */}
                </Grid>
              )}
            </Grid>
            <Grid item sm={7} xs={7} lg={6}>
              <Grid className={classes.backSearchAddButtons}>
                <Button
                  style={{ textTransform: "none", color: "#334E90" }}
                  onClick={() => handlePatiemtModal()}
                  startIcon={<PersonAddIcon />}
                >
                  Register A New Patient
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <hr className={classes.hrStyleOffer} />
          <Grid className={classes.patientSearchContainer} container>
            <Grid item sm={12} xs={12}>
              <CardMedia className={classes.brandlogo}>
                <img
                  style={{
                    width: "100%"
                  }}
                  src={"/images/tpo-logo-alt.png"}
                  alt="appName"
                />
              </CardMedia>
            </Grid>
            <Grid item sm={7} xs={10}>
              <TextField
                // type="search"
                variant="outlined"
                error={searchError}
                fullWidth
                style={{ marginBottom: "3.5%" }}
                size="small"
                value={commonText}
                color="secondary"
                helperText={`${
                  searchError ? "Please Enter Some value in Search" : ""
                }`}
                placeholder="Patient Name / SSN / Email/ Phone"
                // label="Patient Name / SSN / Email / Phone"
                onChange={(e) => onChange(e.target.value)}
                // onChange={(e) => setCommonText(e.target.value)}
                name="Search"
                id="Search"
                onKeyPress={handleKeypress}
              />
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                size="small"
                // className={classes.halfInput}
                value={selectedSearch}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused"
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused"
                  }
                }}
                name="Search"
                id="Search"
                style={{
                  height: "5.187em",
                  marginRight: "2px",
                  paddingRight: "12px"
                }}
                select
                onChange={(e) => setSelectedSearch(e.target.value)}
              >
                <MenuItem value="local">
                  <Grid
                    sm={2}
                    xs={12}
                    item
                    direction="row"
                    style={{ display: "flex" }}
                  >
                    <Typography style={{ marginTop: "3.5%" }}>
                      Local Search
                    </Typography>
                  </Grid>
                </MenuItem>
                <MenuItem value="global">
                  <Grid
                    sm={2}
                    xs={12}
                    item
                    direction="row"
                    style={{ display: "flex" }}
                  >
                    <Typography style={{ marginTop: "3.5%" }}>
                      Global Search
                    </Typography>
                  </Grid>
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item>
              <Button
                color="#03A430"
                style={{
                  backgroundColor: "#03A430",
                  textTransform: "none",
                  // borderRadius: "25px",
                  height: "40px"
                }}
                variant="contained"
                className={classes.serachButton}
                onClick={() => onClickHandle()}
                startIcon={<Search />}
              >
                Search Patient
              </Button>
            </Grid>
          </Grid>

          {showTaable && (
            <>
              {/* <Grid container>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  style={{
                    margin: "1%",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Chip
                    label={commonText}
                    onDelete={() => handleChipDelete()}
                  />
                </Grid>
              </Grid> */}
              <Grid className={classes.patientTable} container>
                <Grid item xs>
                  <PatientTable
                    tableData={searchData}
                    commonText={commonText}
                    setShowOfferDEtails={handlePatientNameSelect}
                  />
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </>

      <Modal open={addPatientModal} onClose={() => setAddPatientModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div className={classes.cardDiv}>
              {!preAddPatientData.status ? (
                <ErrorIcon style={{ fontSize: "60px", color: "#FF0000" }} />
              ) : (
                <img src={gif} width="50%" />
              )}
              <div className={classes.successText}>
                {preAddPatientData.message}
              </div>
              <br />
              <CustomButton
                variant="contained"
                className={classes.buttonStyle}
                color="#334E90"
                size="sm"
                onClick={() => setAddPatientModal(false)}
              >
                Ok
              </CustomButton>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal open={patientModal} onClose={() => setPatiemtModal(false)}>
        <AddPatientForm
          callResponse={props.tableData.addPatientData}
          setPatiemtModal={() => setPatiemtModal(false)}
        />
      </Modal>
    </Card>
    // <Card><FinalGenrateOffer handleSendEmailSuccess={() => handleSendEmailSuccess()} values={values} {...props} showOfferDEtails={showOfferDEtails} onClick={() => handlePruntBack()} /></Card>
  );
};

PaymentGenrateOffer.propTypes = {
  patientSearch: PropTypes.func,
  tableData: PropTypes.object,
  getPatientOfferData: PropTypes.func,
  cptCodeDataCall: PropTypes.func,
  getAllActivePlanList: PropTypes.func,
  getAllDocumentList: PropTypes.func,
  state: PropTypes.func,
  history: PropTypes.object,
  getAllCompletedPlanList: PropTypes.func,
  setSelectPatientOffer: PropTypes.func,
  app: PropTypes.object,
  cernerPatientSearch: PropTypes.func,
  setPatientSearchCommonText: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  userProvider: app.users,
  app: app.app
});

export default connect(mapStateToProps, {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  emptyPatientSelect,
  getPatientOfferData,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  setSelectPatientOffer,
  cernerPatientSearch,
  setPatientSearchCommonText
})(PaymentGenrateOffer);
