/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import styles from "assets/jss/material-dashboard-react/components/businessForm";

import FormControl from "@material-ui/core/FormControl";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const useStyles = makeStyles(styles);

const themeFont = createMuiTheme({
  overrides: {
    MuiInputLabel: {
      root: {
        fontFamily: "Source Sans Pro",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "14px",
      },
    },
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro",
      },
      head: {
        display: "none",
      },
    },
    MuiInputBase:{
      input:{
        font:"14px",
        fontFamily: "Source Sans Pro",
      }
    }
  },
});

export function SimpleDialog(props) {
  const [values, setValue] = React.useState(props.searchValue);
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;


  const handleClose = () => {
    if (
      values.cpt === "" &&
      values.funded === "" &&
      values.fundedLess === "" &&
      values.offerFromDate === "" &&
      values.offerToDate === ""
    ) {
      props.activePatient({
        cpt: "",
        funded: "",
        fundedLess:"",
        offerFromDate: "",
        offerToDate: "",
      });
    }
    setValue({
      cpt: "",
      funded: "",
      fundedLess:"",
      offerFromDate: "",
      offerToDate: "",
    });
    props.seacrhChipData({
      cpt: "",
      funded: "",
      fundedLess:"",
      offerFromDate: "",
      offerToDate: "",
    });
    props.close();
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValue(allState);
  };

  const onFormSubmit = () => {
    props.seacrhChipData(values);
    props.close();
  };

  return (
    <>
      <Grid container style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize,
              },
            }}
            label="CPT Code / Description"
            value={values.cpt}
            onChange={(e) => onChange(e.target.value, "cpt")}
            name="cpt"
            id="cpt"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      
      <Grid container style={{ marginBottom: "3%" }}>
      <Grid item xs={6} lg={6}>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Fund Greater than Equal ($)"
            value={values.funded}
            onChange={(e) => onChange(e.target.value, "funded")}
            name="funded"
            id="funded"
            style={{ fontSize: "82%" }}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <TextField
            className={classes.halfInputSingle}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Fund Less than Equal ($)"
            value={values.fundedLess}
            onChange={(e) => onChange(e.target.value, "fundedLess")}
            name="fundedLess"
            id="fundedLess"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "3%" }}>
      <Grid item xs={6} lg={6}>
          <ThemeProvider theme={themeFont}>
            
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  clearable
                  className={classes.halfInput}
                  disabled={props.disabled}
                  onChange={(e) => {
                    if (e === null) {
                      onChange(e, "offerFromDate");
                    } else {
                      onChange(moment(e).format("MM-DD-YYYY"), "offerFromDate");
                    }
                  }}
                  value={
                    values?.offerFromDate
                      ? moment(values.offerFromDate).format("MM-DD-YYYY")
                      : null
                  }
                  required
                  label="Offer Date (From)"
                  id="offerFromDate"
                  name="offerFromDate"
                  placeholder="MM-DD-YYYY"
                  maxDate={new Date()}
                  maxDateMessage="Deposit Date should not be a Future date."
                  helpter
                  format="MM-dd-yyyy"
                  keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                  KeyboardButtonProps={{
                    size: "small",
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                    endAdornment: (
                      <Tooltip title="cds_DateHelp" placement="top">
                        <IconButton edge="end" size="small">
                          <HelpOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    ),
                    classes: {
                      adornedEnd: classes.endAdornment,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            
          </ThemeProvider>
        </Grid>
        <Grid item xs={6} lg={6}>
          <ThemeProvider theme={themeFont}>
            
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  clearable
                  disabled={props.disabled}
                  className={classes.halfInputSingle}
                  onChange={(e) => {
                    if (e === null) {
                      onChange(e, "offerToDate");
                    } else {
                      onChange(moment(e).format("MM-DD-YYYY"), "offerToDate");
                    }
                  }}
                  value={
                    values?.offerToDate
                      ? moment(values.offerToDate).format("MM-DD-YYYY")
                      : null
                  }
                  required
                  label="Offer Date (To)"
                  id="offerToDate"
                  name="offerToDate"
                  placeholder="MM-DD-YYYY"
                  minDate={values?.offerFromDate}
                  maxDate={new Date()}
                  maxDateMessage="Deposit Date should not be a Future date."
                  minDateMessage="Date(To) should not be a Future date from Deposit Date(From)."
                  format="MM-dd-yyyy"
                  keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                  KeyboardButtonProps={{
                    size: "small",
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                    },
                    endAdornment: (
                      <Tooltip title="cds_DateHelp" placement="top">
                        <IconButton edge="end" size="small">
                          <HelpOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    ),
                    classes: {
                      adornedEnd: classes.endAdornment,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            
          </ThemeProvider>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs className={classes.clearSubmitButton}>
          <br />
          <Button
            cancel
            variant="outlined"
            size="md"
            style={{
              height: "2.1em",
              // marginTop: "5px",
              textTransform: "none",
            }}
            onClick={() => handleClose()} 
          >
            Clear
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            cancel
            variant=""
            color="#ffffff"
            style={{
              backgroundColor: "#03A430",
              textTransform: "none",
              borderRadius: "5px",
              height: "30px",
              color: "#ffffff",
              //  fontSize: "18px",
              fontWeight: "600",
            }}
            type="submit"
            className={classes.buttonStyle}
            size="md"
            onClick={() => onFormSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  close: PropTypes.func,
};
