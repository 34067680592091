/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import ClearIcon from "@material-ui/icons/Clear";
import CoustomButton from "components/CustomButtons/Button";
import Button from "@material-ui/core/Button";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Card from "components/Card/Card";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import ChipInput from "material-ui-chip-input";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import closeImage from "assets/img/Close_Image.png";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Modal from "@material-ui/core/Modal";
import { exportToCSV } from "../../Utils/commonUtils";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/Providers";
import LongMenu from "../../components/Menu/Index";
import {
  getProviderList,
  changeProviderFormMode,
  getProviderStatus,
  changeSingleStatus,
  getProviderByFilterData,
  getProviderDetailsById,
  addSearchData
} from "../../Redux/Actions/ProvideRegistration";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

const useStyles = makeStyles(tableStyle);

function createData(
  DBA_Name,
  E_mail,
  Ownership_Type,
  Contact_Name,
  Phone,
  Creation_Date,
  Updated_date,
  status,
  Id,
  Sub_MerchantID,
  Error_Code,
  Error_Description,
  Client_Id,
  Current_Status
) {
  return {
    DBA_Name,
    E_mail,
    Ownership_Type,
    Contact_Name,
    Phone,
    Creation_Date,
    Updated_date,
    status,
    Id,
    Sub_MerchantID,
    Error_Code,
    Error_Description,
    Client_Id,
    Current_Status
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();

  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        variant="body2"
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography variant="body2">First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography variant="body2">Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography variant="body2">{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography variant="body2">Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography variant="body2">Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const headCells = [
    {
      id: "Business_Name",
      numeric: false,
      disablePadding: true,
      label: "DBA Name",
      headClassName: classes.DBA_Name
    },
    {
      id: "Business_EMail",
      numeric: false,
      disablePadding: true,
      label: "E-Mail",
      headClassName: classes.E_mail
    },
    {
      id: "Type_Name",
      numeric: false,
      disablePadding: false,
      label: "Ownership Type",
      headClassName: classes.OwnershipType
    },
    {
      id: "Owner_Information",
      numeric: false,
      disablePadding: true,
      label: "Contact Name",
      headClassName: classes.Contact_Name
    },
    {
      id: "Business_Phone",
      numeric: true,
      disablePadding: false,
      label: "Phone",
      headClassName: classes.Phone
    },
    {
      id: "Created_date",
      numeric: true,
      disablePadding: false,
      label: "Creation Date",
      headClassName: classes.Creation_Date
    },
    {
      id: "Updated_date",
      numeric: true,
      disablePadding: false,
      label: "Update Date",
      headClassName: classes.Updated_date
    },
    {
      id: "Provider_Status",
      numeric: false,
      disablePadding: false,
      label: "Status",
      headClassName: classes.status
    },
    {
      id: "blank",
      numeric: false,
      disablePadding: false,
      label: "",
      headClassName: classes.status
    }
  ];

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const ProviderReport = (props) => {
  const [preSearchDetails, setPreSearchDetails] = React.useState(
    props.tableData.searchDetails
  );
  const [statusDetails, setStatusPid] = React.useState({
    pid: "",
    name: "",
    status: ""
  });
  const [values, setValues] = React.useState({
    status: "",
    id: ""
  });
  const classes = useStyles();
  const [page, setPage] = React.useState(props.tableData.searchDetails.pageNo);
  const [emptyData, setEmptyDataModel] = React.useState(false);
  const [preProviderDetailsById, setPreProviderDetailsById] = React.useState(
    props.tableData.providerDetailsById
  );
  const [order, setOrder] = React.useState(
    props.tableData.searchDetails.orderBy
  );
  const [orderBy, setOrderBy] = React.useState(
    props.tableData.searchDetails.sortBy
  );
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const [temp, setTemp] = React.useState(false);
  const [openModel, setOpenModel] = React.useState(false);
  const [openBoardingModel, setopenBoardingModel] = React.useState(false);
  const [userOptions, setUserOptions] = React.useState(
    props.mainData.roles.roleTab &&
      props.mainData.roles.roleTab.Roles_Data.Master_Permission[1].Page_Menu[0]
        .Options[3]
  );
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [preStatusUpdate, setPreStatusUpdate] = React.useState(
    props.tableData.statusUpdate
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [initialCall, setInitialCall] = React.useState(false);

  function providerregisterurl() {
    props.history.push(`/${props.userDetails.layout}/user`);
    props.changeProviderFormMode("Create");
  }

  React.useEffect(() => {
    if (!props.tableData.statusList) {
      props.getProviderStatus();
    }
  }, []);

  React.useEffect(() => {
    if (props.tableData.providerList?.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData]);

  React.useEffect(() => {
    if (preStatusUpdate !== props.tableData.statusUpdate) {
      setPreStatusUpdate(props.tableData.statusUpdate);
      const requestData = { ...props.tableData.searchDetails };
      requestData.ClientId = props.userDetails.sendClientIdApi.toString();
      props.getProviderList({ ...requestData });
      setOpenModel(false);
    }
    if (props.mainData.roles.roleTab && !userOptions) {
      setUserOptions(
        props.mainData.roles.roleTab.Roles_Data.Master_Permission[1]
          .Page_Menu[0].Options[3]
      );
    }
    if (preProviderDetailsById !== props.tableData.providerDetailsById) {
      setPreProviderDetailsById(props.tableData.providerDetailsById);
      if (
        props.tableData.providerDetailsById &&
        props.tableData.providerDetailsById.status
      ) {
        props.history.push(`/${props.userDetails.layout}/user`);
      }
    }
    setopenBoardingModel(false);
    if (preSearchDetails !== props.tableData.searchDetails) {
      // getProviderByFilterDataHandle(props.tableData.searchDetails);
      setPreSearchDetails(props.tableData.searchDetails);
    }
  }, [props]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
    const requestData = { ...props.tableData.searchDetails };
    requestData.pageNo = newPage + 1;
    requestData.ClientId = props.userDetails.sendClientIdApi.toString();
    props.getProviderByFilterData(requestData);
    props.addSearchData(requestData);
  };

  const saveStatus = () => {
    props.changeSingleStatus(values);
  };

  const exportListData = () => {
    let filterValue = "";
    if (chipValue.length) {
      for (const property in chipValue) {
        if (chipValue[property] !== "") {
          filterValue += `${chipValue[property]}  `;
        }
      }
    }
    const sheetModuleDetails = [
      ["Module:", "Merchants Details"],
      ["Export On:", moment.utc(new Date()).format()],
      [
        "Exported By:",
        `${props.mainData.users.userDetailsById.Users_Data[0].FName} ${props.mainData.users.userDetailsById.Users_Data[0].LName} <${props.mainData.users.userDetailsById.Users_Data[0].Email}>`
      ],
      ["Filters:", filterValue]
    ];
    if (!isEmpty(rows)) {
      const mappedLogs = rows.map((row) => ({
        "DBA Name": row.DBA_Name,
        Email: row.E_mail,
        "Ownership Type": row.Ownership_Type,
        "Contact Name": row.Contact_Name,
        Phone: row.Phone,
        "Creation Date": row.Creation_Date,
        "Update Date": row.Updated_date,
        Status: row.status
      }));
      exportToCSV(
        mappedLogs,
        `Merchants${moment().format("MMDDYYYY")}`,
        sheetModuleDetails
      );
    } else {
      setEmptyDataModel(true);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const requestData = { ...props.tableData.searchDetails };
    requestData.recordPerPage = parseInt(event.target.value, 10);
    requestData.pageNo = 1;
    requestData.ClientId = props.userDetails.sendClientIdApi.toString();
    props.getProviderByFilterData({ ...requestData });
    props.addSearchData(requestData);
  };

  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      setChipValue(chipValue.filter((v) => v !== chip));
      const requestData = { ...props.tableData.searchDetails };
      const fillterPreChip = chipValue.filter((v) => v !== chip);
      const filterRemoveChip = chipValue.filter((v) => v === chip);
      if (fillterPreChip.length !== 0) {
        fillterPreChip.forEach((v) => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "DBA Name") {
            requestData.searchDbaName = splitSearchName[1].trim();
          }
          if (splitSearchName[0] === "Status") {
            const filterStatusCode =
              props.tableData.statusList.Provider_Status_Data.filter(
                (item) =>
                  item.Provider_Status.trim() === splitSearchName[1].trim()
              );
            requestData.searchStatus =
              filterStatusCode.length > 0
                ? filterStatusCode[0].Status_Code
                : "";
          }
        });
      }
      if (filterRemoveChip.length !== 0) {
        filterRemoveChip.forEach((v) => {
          const splitSearchName = v.split(":");
          if (splitSearchName[0] === "DBA Name") {
            requestData.searchDbaName = "";
          }
          if (splitSearchName[0] === "Status") {
            requestData.searchStatus = "";
          }
        });
      }
      requestData.limit = 5;
      requestData.pageNo = 1;
      requestData.ClientId = props.userDetails.sendClientIdApi.toString();
      props.addSearchData(requestData);
      props.getProviderByFilterData({ ...requestData });
    } else {
      const requestData = { ...props.tableData.searchDetails };
      requestData.searchDbaName = "";
      requestData.searchStatus = "";
      requestData.limit = 5;
      requestData.pageNo = 1;
      requestData.ClientId = props.userDetails.sendClientIdApi.toString();
      props.addSearchData(requestData);
      props.getProviderByFilterData({ ...requestData });
      setChipValue(chipValue);
    }
    if (chipValue.length === 0) {
      const requestData = { ...props.tableData.searchDetails };
      requestData.searchDbaName = "";
      requestData.searchStatus = "";
      requestData.limit = 5;
      requestData.pageNo = 1;
      requestData.ClientId = props.userDetails.sendClientIdApi.toString();
      props.addSearchData(requestData);
      props.getProviderByFilterData({ ...requestData });
      setChipValue([]);
    }
    setTemp(temp);
  };

  React.useEffect(() => {
    if (props.userDetails.sendClientIdApi) {
      props.getProviderList({
        ...props.tableData.searchDetails,
        ClientId: props.userDetails.sendClientIdApi.toString()
      });
    }
  }, [props.userDetails.sendClientIdApi]);

  const getProviderByFilterDataHandle = (value) => {
    props.addSearchData(value);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (property === "searchDbaName" || property === "searchStatus") {
          chipArray.push(
            `${property === "searchDbaName" ? "DBA Name" : "Status"}: ${
              property === "searchDbaName"
                ? value[property]
                : props.tableData.statusList &&
                  props.tableData.statusList.Provider_Status_Data.filter(
                    (item) => item.Status_Code === value[property]
                  )[0].Provider_Status
            }`
          );
        }
      }
    }
    setChipValue(chipArray);
  };

  rows = [];

  if (props.tableData.providerList) {
    if (
      props.tableData.providerList &&
      props.tableData.providerList.Providers_Data
    ) {
      props.tableData.providerList.Providers_Data.forEach((v, i) => {
        const Id = v.Provider_id;
        const DBA_Name = v.Business_Name;
        const E_mail = v.Business_EMail;
        const Ownership_Type = v.Type_Name;
        const Contact_Name = v.Owner_Information.First_Name;
        const Phone = v.Business_Phone;
        const Creation_Date = moment(v.Created_date).format("MM/DD/YYYY");
        const Updated_date = moment(v.Updated_date).format("MM/DD/YYYY");
        const status = v.Provider_Status;
        const Sub_MerchantID = v.Guid;
        const Error_Code = v.Error_Code;
        const Error_Description = v.Error_Description;
        const Client_Id = v.Client_Id;
        const Current_Status = v.Current_Status;
        rows.push(
          createData(
            DBA_Name,
            E_mail,
            Ownership_Type,
            Contact_Name,
            Phone,
            Creation_Date,
            Updated_date,
            status,
            Id,
            Sub_MerchantID,
            Error_Code,
            Error_Description,
            Client_Id,
            Current_Status
          )
        );
      });
    }
  }

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    allState.id = statusDetails.id;
    setValues(allState);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const requestData = { ...props.tableData.searchDetails };
    requestData.sortBy = property;
    requestData.orderBy = isAsc ? "desc" : "asc";
    requestData.ClientId = props.userDetails.sendClientIdApi.toString();
    props.getProviderByFilterData(requestData);
    props.addSearchData(requestData);
  };

  const handleEdit = (type, providerId) => {
    if (type === "edit") {
      props.getProviderDetailsById(providerId);
      props.changeProviderFormMode("Edit");
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  // const isSelected = (name) => selected.indexOf(name) !== -1;
  const userType = localStorage.getItem("userType");

  return (
    <>
      <ThemeProvider theme={themeFont}>
        <Modal open={emptyData} onClose={() => setEmptyDataModel(false)}>
          <div className={classes.modalDiv}>
            <Card className={classes.modalPayOut}>
              <div
                className={classes.cardDiv1}
                style={{
                  display: "flex",
                  overflow: "hidden"
                }}
              >
                <img src={closeImage} width="75px" />

                <div
                  style={{
                    textAlign: "center",
                    color: "gray",
                    fontSize: "14px",
                    whiteSpace: "normal"
                  }}
                >
                  No Data present
                </div>
                <br />
                <Grid container alignItems="center" justifyContent="center">
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    onClick={() => setEmptyDataModel(false)}
                  >
                    ok
                  </Button>
                </Grid>
              </div>
            </Card>
          </div>
        </Modal>
        <TableContainer className={classes.TableContainer} component={Paper}>
          <Modal
            open={openBoardingModel}
            onClose={() => setopenBoardingModel(false)}
            align="center"
          >
            <div
              className={classes.modalDiv}
              style={{ width: "48%" }}
              align="center"
            >
              <Card
                className={
                  windowWidthSm ? classes.modalRoot : classes.modalRootXs
                }
              >
                <div>
                  <ClearIcon
                    onClick={() => setopenBoardingModel(false)}
                    style={{ float: "right", pointer: "cursor" }}
                  />
                  <CardHeader subheader="Activity Response" />
                </div>
                <div style={{ margin: "3%" }}>
                  <Grid container className={classes.margin}>
                    <Grid item xs={6} align="left">
                      <span style={{ fontWeight: "bold" }}>DBA Name: </span>{" "}
                      {`${statusDetails.name}`}
                    </Grid>
                    <Grid item xs={6} align="left">
                      <span style={{ fontWeight: "bold" }}>Email: </span>{" "}
                      {`${statusDetails.email}`}
                    </Grid>
                  </Grid>
                  <Grid container className={classes.margin}>
                    <Grid item xs={6} align="left">
                      <span style={{ fontWeight: "bold" }}>
                        Sub Marchent Id:{" "}
                      </span>{" "}
                      {`${statusDetails.submarchecntId}`}
                    </Grid>
                    <Grid item xs={6} align="left">
                      <span style={{ fontWeight: "bold" }}>Status: </span>{" "}
                      {`${statusDetails.status}`}
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <CardHeader subheader="Error Log" />
                </div>
                <div>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          className={classes.cellTextHeader}
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          Error Code
                        </TableCell>
                        <TableCell
                          className={classes.cellTextHeader}
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          Field Name
                        </TableCell>
                        <TableCell
                          className={classes.cellTextHeader}
                          align="center"
                          style={{ fontWeight: "bold" }}
                        >
                          Description
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell className={classes.cellText} />
                        <TableCell
                          className={classes.cellText}
                          align="center"
                        />
                        <TableCell
                          className={classes.cellText}
                          align="center"
                        />
                      </TableRow>
                    </TableBody>
                  </Table>
                </div>
              </Card>
            </div>
          </Modal>
          <Modal
            open={openModel}
            onClose={() => setOpenModel(false)}
            align="center"
          >
            <div className={classes.modalDiv} align="center">
              <Card
                className={
                  windowWidthSm ? classes.modalRoot : classes.modalRootXs
                }
              >
                <div className={classes.statusModalHeader}>
                  <div className={classes.statusHeadingText}>
                    Change Merchant Status
                  </div>
                  <ClearIcon
                    style={{ color: "#fff" }}
                    color="#fff"
                    onClick={() => setOpenModel(false)}
                  />
                </div>
                <Grid
                  container
                  className={classes.margin}
                  style={{ padding: "2%" }}
                >
                  <Grid item className={classes.searchRoot}>
                    <strong>{`DBA Name: ${statusDetails.name}`}</strong>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  className={classes.margin}
                  style={{ padding: "2%" }}
                >
                  <Grid item xs={12}>
                    <TextField
                      className={classes.statusRoot}
                      fullWidth
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Status"
                      value={values.status}
                      onChange={(e) => onChange(e.target.value, "status")}
                      name="status"
                      id="status"
                      select
                      align="left"
                    >
                      {props.tableData.statusList &&
                        props.tableData.statusList.Provider_Status_Data.map(
                          (key) => (
                            <MenuItem
                              className={classes.dialogBoxDropDown}
                              value={key.Status_Code}
                            >
                              {key.Provider_Status}
                            </MenuItem>
                          )
                        )}
                    </TextField>
                  </Grid>
                </Grid>
                <Grid
                  container
                  className={classes.marginTop}
                  style={{
                    padding: "2%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-end"
                  }}
                >
                  <Button
                    color="primary"
                    className={classes.buttonStyle}
                    variant="contained"
                    onClick={() => saveStatus()}
                    size="lg"
                  >
                    Update
                  </Button>
                </Grid>
              </Card>
            </div>
          </Modal>
          {windowWidth && (
            <Grid container className={classes.searchContainer}>
              <Grid item className={classes.margin}>
                <div className={classes.searchWrapper}>
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => handleClose()}
                  >
                    <Box style={{ width: "35%" }}>
                      <ChipInput
                        value={chipValue.length > 0 ? chipValue : []}
                        onClick={() => handleClickOpen()}
                        style={{ display: "flex" }}
                        classes={{ chipContainer: classes.chipContainer }}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused"
                          }
                        }}
                        label="Search"
                        onDelete={(chip, index) =>
                          handleDeleteChip(chip, index)
                        }
                      />
                      <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className={classes.overLayBox}
                      >
                        <SimpleDialog
                          close={() => handleClose()}
                          seacrhChipData={(data) =>
                            getProviderByFilterDataHandle(data)
                          }
                        />
                      </Collapse>
                    </Box>
                  </ClickAwayListener>
                  <CoustomButton
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    onClick={() => {}}
                  >
                    <Search />
                  </CoustomButton>
                  <Button
                    size="large"
                    type="submit"
                    style={{
                      backgroundColor: "#03A430",
                      textTransform: "none",
                      borderRadius: "25px",
                      height: "48px",
                      width: "151px",
                      color: "#ffffff",
                      marginLeft: "10px",
                      marginRight: "15px",
                      fontSize: "18px",
                      fontWeight: "600"
                    }}
                    onClick={exportListData}
                    variant=""
                    color="#ffffff"
                  >
                    Export Data
                  </Button>
                  {userType === "Internal" && (
                    <Button
                      size="large"
                      type="submit"
                      style={{
                        backgroundColor: "#03A430",
                        textTransform: "none",
                        borderRadius: "25px",
                        height: "48px",
                        width: "151px",
                        color: "#ffffff",
                        fontSize: "18px",
                        fontWeight: "600"
                      }}
                      onClick={() => providerregisterurl()}
                      variant=""
                      color="#ffffff"
                    >
                      <AddIcon />
                      New
                    </Button>
                  )}
                </div>
              </Grid>
            </Grid>
          )}
          {!windowWidth && (
            <Grid container className={classes.searchContainer}>
              <Grid item className={classes.margin} style={{ display: "flex" }}>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => handleClose()}
                >
                  <Box style={{ width: "85%" }}>
                    <ChipInput
                      defaultValue={chipValue.length > 0 ? chipValue : []}
                      classes={{ chipContainer: classes.chipContainer }}
                      onClick={() => handleClickOpen()}
                      style={{ display: "flex" }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Search"
                      onDelete={(chip, index) => handleDeleteChip(chip, index)}
                    />
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className={classes.overLayBox}
                    >
                      <SimpleDialog
                        close={() => handleClose()}
                        seacrhChipData={(data) =>
                          getProviderByFilterDataHandle(data)
                        }
                      />
                    </Collapse>
                  </Box>
                </ClickAwayListener>
                <CoustomButton
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  onClick={() => {}}
                >
                  <Search />
                </CoustomButton>
              </Grid>
              <Grid item className={classes.expirtItemGrid}>
                <CoustomButton
                  aria-controls="simple-menu"
                  aria-haspopup="true"
                  onClick={exportListData}
                  variant="contained"
                  color="primary"
                  className={classes.buttonStyle}
                >
                  Export
                </CoustomButton>
                {userType === "Internal" && (
                  <CoustomButton
                    aria-controls="simple-menu"
                    variant="contained"
                    color="primary"
                    className={classes.buttonStyle}
                    onClick={providerregisterurl}
                  >
                    <AddIcon />
                    New
                  </CoustomButton>
                )}
              </Grid>
            </Grid>
          )}
          {/* <Grid
          container
          className={classes.margin}
        >
          <Grid
            item
            xs
            style={{
              display: "flex", paddingBottom: "1%", flexDirection: "row-reverse", paddingRight: "5%",
            }}>
            {chipValue
              && chipValue.map(data => (
                <Chip
                  label={`${data.name === "searchStatus" ? "Status" : "DBA Name"}: ${data.name === "searchStatus" ? props.tableData.statusList.Provider_Status_Data.filter(item => item.Status_Code === data.value)[0].Provider_Status : data.value}`}
                  onDelete={() => handleDeleteChip(data)}
                />
              ))}
          </Grid>
        </Grid> */}
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {!isEmpty(rows) ? (
                rows.map((row, index) => (
                  <TableRow
                    style={{
                      backgroundColor:
                        (index + 1) % 2 === 0 ? "#F6F9FF" : "white"
                    }}
                    className={classes.cellHeadSign}
                  >
                    <>
                      <TableCell
                        className={classes.cellText}
                        onClick={() => handleEdit("edit", row.Id)}
                      >
                        {row.DBA_Name}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleEdit("edit", row.Id)}
                      >
                        {row.E_mail}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleEdit("edit", row.Id)}
                      >
                        {row.Ownership_Type}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleEdit("edit", row.Id)}
                      >
                        {row.Contact_Name}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleEdit("edit", row.Id)}
                      >
                        {row.Phone}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleEdit("edit", row.Id)}
                      >
                        {row.Creation_Date}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleEdit("edit", row.Id)}
                      >
                        {row.Updated_date}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleEdit("edit", row.Id)}
                      >
                        {row.status}
                      </TableCell>
                    </>
                    <TableCell className={classes.cellText}>
                      <LongMenu
                        details={row}
                        provider
                        userOptions={userOptions}
                        {...props}
                        setValues={setValues}
                        openBoardingModel={() => setopenBoardingModel(true)}
                        openForm={() => setOpenModel(true)}
                        setStatusPid={() =>
                          setStatusPid({
                            id: row.Id,
                            name: row.DBA_Name,
                            email: row.E_mail,
                            submarchecntId: row.Sub_MerchantID,
                            errorCode: row.Error_Code,
                            errorDescription: row.Error_Description,
                            status: row.status,
                            clientid: row.Client_Id
                          })
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={12}>
                    <img
                      src={noDataImage}
                      alt=""
                      className={classes.noDataImage}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>

            {rows.length ? (
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      {
                        label: "All",
                        value: props.tableData.providerList?.totalDocs
                      }
                    ]}
                    colSpan={12}
                    count={props.tableData.providerList?.totalDocs}
                    rowsPerPage={rowsPerPage}
                    page={page - 1}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page 1st" },
                      MenuProps: { classes: { paper: classes.selectDropdown } }
                    }}
                    classes={{ menuItem: classes.menuItem }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            ) : (
              ""
            )}
          </Table>
        </TableContainer>
      </ThemeProvider>
    </>
  );
};

ProviderReport.propTypes = {
  getProviderList: PropTypes.func,
  tableData: PropTypes.object,
  history: PropTypes.any,
  changeProviderFormMode: PropTypes.func,
  getProviderStatus: PropTypes.func,
  changeSingleStatus: PropTypes.func,
  userDetails: PropTypes.object,
  getProviderByFilterData: PropTypes.func,
  getProviderDetailsById: PropTypes.func,
  mainData: PropTypes.object,
  addSearchData: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.provideRegistration,
  userDetails: app.app,
  mainData: app
});
export default connect(mapStateToProps, {
  getProviderList,
  changeProviderFormMode,
  getProviderStatus,
  changeSingleStatus,
  getProviderByFilterData,
  getProviderDetailsById,
  addSearchData
})(ProviderReport);
