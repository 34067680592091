/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import ButtonOriginal from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import Box from "@material-ui/core/Box";
import Button from "components/CustomButtons/Button.js";
import CustomButton from "components/CustomButtons/Button.js";
import DeleteIcon from "@material-ui/icons/Delete";
import StepButton from "@material-ui/core/StepButton";
import Card from "components/Card/Card.js";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import SignatureCanvas from "react-signature-canvas";
import { Typography } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import Modal from "@material-ui/core/Modal";
import Step from "@material-ui/core/Step";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useSnackbar } from "notistack";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import Stepper from "@material-ui/core/Stepper";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Pdf from "Uintls/The_patient_offer.pdf";
import fullOrigin from "../../assets/img/imgpsh_fullsize_anim.png";
import { DOWNLOAD_PATIENT_OFFER_DOCUMENTS_URL } from "../../Uintls/Urls";
import gif from "../../assets/img/success.gif";

const useStyles = makeStyles(styles);

const FinalGenrateOffer = (props) => {
  function fullPaySteps() {
    return [
      "Print",
      "Upload Signed Document",
      props.tableData.paymentMakeDetails &&
      props.tableData.paymentMakeDetails.status &&
      props.tableData.createOffer.data.payType !== 1
        ? "Done"
        : "Pay Now"
    ];
  }
  const [activeStep, setActiveStep] = React.useState(
    props.tableData.printOfferData &&
      props.tableData.printOfferData.data[0].offerAcceptanceDocument
      ? 1
      : 0
  );
  const [documentUpload, setDpcumentUpload] = React.useState(
    props.tableData.printOfferData &&
      props.tableData.printOfferData.data[0].offerAcceptanceDocument
  );
  const [fileSizeError, setFileSizeError] = React.useState(false);
  const [fileName, setFileName] = React.useState("");
  const [preFileDelete, setPreFileDelete] = React.useState(
    props.tableData.deleteDocument
  );
  const componentRef = React.createRef();
  const { enqueueSnackbar } = useSnackbar();
  const [showUploadedButton, setShowUploadedButton] = React.useState(false);
  const [prePaymentDetails, setPrePaymentsDetails] = React.useState(
    props.tableData.paymentDetailsData
  );
  const [uploadFileModal, setUploadFileModal] = React.useState(false);
  const [showPayNowButton, setShowPayNowButton] = React.useState(false);
  const [uploadFile, setUploadFile] = React.useState(
    props.tableData.uploadDocument
  );
  const [uploadedFile, setUploadedFile] = React.useState(false);
  const classes = useStyles();

  const handleUploadOnChange = (files) => {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    if (
      Math.round(files[0].size / 1024) < 20 ||
      Math.round(files[0].size / 1024) > 500
    ) {
      setFileSizeError(true);
      setFileName(files[0].name);
    } else {
      setFileSizeError(false);
      reader.onload = function () {
        setUploadedFile(reader.result);
      };
      reader.onerror = function (error) {};
    }
  };

  React.useEffect(() => {
    if (uploadFile !== props.tableData.uploadDocument) {
      setUploadFile(props.tableData.uploadDocument);
      if (props.tableData.uploadDocument.status) {
        enqueueSnackbar("File Upload Successfully", {
          variant: "success"
        });
        setDpcumentUpload(true);
      }
    }
    if (preFileDelete !== props.tableData.deleteDocument) {
      setPreFileDelete(props.tableData.deleteDocument);
      if (props.tableData.deleteDocument.status) {
        setDpcumentUpload(false);
      }
    }
  }, [props]);

  const handleUploadButton = () => {
    const data = {
      patientId: props.showOfferDEtails.data.Patient_id,
      offerId:
        props.tableData.printOfferData &&
        props.tableData.printOfferData.data[0].Offer_Id,
      imagedata: uploadedFile
    };
    props.uploadOfferDocument(data);
    setShowPayNowButton(true);
    setUploadFileModal(false);
  };

  const handleDeleteFile = () => {
    const data = {
      offerId:
        props.tableData.printOfferData &&
        props.tableData.printOfferData.data[0].Offer_Id,
      imagedata:
        props.tableData.printOfferData &&
        props.tableData.printOfferData.data[0].offerAcceptanceDocument
    };
    props.deleteDocumentCall(data);
    setShowPayNowButton(false);
  };

  function CallSingularBillPayAPI() {
    const amount =
      props.tableData.createOffer.data.payType === 1
        ? Number(
            props.tableData.genrateOfferData.data[0].Discounted[0].finalPayAmount
              .toString()
              .replace("$", "")
          )
        : Number(
            props.tableData.genrateOfferData.data[0].Monthly[0].downPayment
              .toString()
              .replace("$", "")
          );
    const data = {
      patientId: props.showOfferDEtails.data.Patient_id,
      offerId: props.tableData.createOffer.data.OfferId,
      amount
    };
    props.patientPaymentDetailsCall(data);
  }

  const handleAfterPrint = () => {
    setShowUploadedButton(true);
    setActiveStep(1);
  };
  const disablePayNowDocumentUpload = documentUpload || showPayNowButton;

  return (
    <div style={{ margin: "15px" }}>
      <Grid container>
        <Grid item xs={2} />
        <Grid item xs={6}>
          <Stepper className={classes.stepRoot} activeStep={activeStep}>
            {fullPaySteps().map((label, index) => (
              <Step key={label}>
                <StepButton onClick={() => setActiveStep(index)}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        </Grid>
        <Grid item xs={6} />
      </Grid>
      <Grid container style={{ margin: "1%" }}>
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center"
          }}
        >
          <ButtonOriginal
            className={classes.clearButton}
            onClick={() => props.onClick()}
          >
            Back test
          </ButtonOriginal>
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
            paddingRight: "2%"
          }}
        >
          <Button
            onClick={useReactToPrint({
              content: () => componentRef.current,
              onAfterPrint: () => handleAfterPrint(),
              documentTitle:
                props.tableData.printOfferData &&
                props.tableData.printOfferData.data[0].Offer_Id
            })}
          >
            Print
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            disabled={!(documentUpload || showUploadedButton)}
            onClick={() => setUploadFileModal(true)}
          >
            {documentUpload && <CheckCircleIcon />}
            Upload Signed Document
          </Button>
          {/* {<IconButton onClick={() => setUploadFileModal(true)}>
            <CheckIcon />
            <div>
              Upload Singned Document
            </div>
          </IconButton>} */}
          {props.tableData.paymentMakeDetails &&
          props.tableData.paymentMakeDetails.status &&
          props.tableData.createOffer.data.payType !== 1 ? (
            <Button
              disabled={!disablePayNowDocumentUpload}
              size="sm"
              onClick={() => props.handleSendEmailSuccess()}
            >
              Done
            </Button>
          ) : (
            <Button
              disabled={!disablePayNowDocumentUpload}
              size="sm"
              onClick={() => CallSingularBillPayAPI()}
            >
              Pay{" "}
              {`$${props.tableData.genrateOfferData.data[0].Discounted[0].finalPayAmount}`}{" "}
              now
            </Button>
          )}
        </Grid>
      </Grid>
      <div id="print" ref={componentRef}>
        <Grid container style={{ margin: "1% 9%" }}>
          <Grid iten xs={2} align="left">
            <img src={fullOrigin} alt="" style={{ width: "30%" }} />
          </Grid>
          <Grid iten xs />
        </Grid>
        <Grid container>
          <Grid
            item
            xs
            style={{
              margin: "1% 9%",
              fontSize: "16px",
              color: "gray",
              fontFamily: "Source Sans Pro"
            }}
          >
            {props.showOfferDEtails.patientName}
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs
            style={{
              margin: "1% 1% 1% 9%",
              fontSize: "16px",
              color: "gray",
              fontFamily: "Source Sans Pro"
            }}
          >
            Congratulations! We're excited to let you know that our billing
            department has approved your application and we are ready to fund
            your account balance with terms defined below:
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <div
              style={{
                margin: "1% 9%",
                fontSize: "18px",
                color: "#00aab4",
                fontFamily: "Source Sans Pro"
              }}
            >
              Procedure Details
            </div>
            <Box
              style={{
                margin: "1% 9%",
                width: "60%",
                backgroundColor: "#f6f5f5",
                borderRadius: "10px",
                padding: "2%"
              }}
            >
              <Grid container>
                <Grid item xs={4}>
                  Hospital/Provider Name
                </Grid>
                <Grid item xs={2}>
                  :
                </Grid>
                <Grid item xs={4}>
                  {props.tableData.printOfferData &&
                    props.tableData.printOfferData.data[0].Procedure_Details
                      .Hospital}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs>
            <div
              style={{
                margin: "1% 9%",
                fontSize: "18px",
                color: "#00aab4",
                fontFamily: "Source Sans Pro"
              }}
            >
              Payment Details
            </div>
            <Box
              style={{
                margin: "1% 9%",
                width: "60%",
                backgroundColor: "#f6f5f5",
                borderRadius: "10px",
                padding: "2%"
              }}
            >
              <Grid container>
                <Grid item xs={4}>
                  Offer Date
                </Grid>
                <Grid item xs={2}>
                  :
                </Grid>
                <Grid item xs={3}>
                  {props.tableData.printOfferData &&
                    props.tableData.printOfferData.data[0].Loan_Details
                      .Offer_Date}
                </Grid>
                <Grid item xs={1} />
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  Offer Number
                </Grid>
                <Grid item xs={2}>
                  :
                </Grid>
                <Grid item xs={4}>
                  {props.tableData.printOfferData &&
                    props.tableData.printOfferData.data[0].offerNumber}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  Amount Financed
                </Grid>
                <Grid item xs={2}>
                  :
                </Grid>
                <Grid item xs={2}>
                  {props.tableData.printOfferData &&
                    props.tableData.printOfferData.data[0].Loan_Details
                      .Amount_Financed}
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  Down payment
                </Grid>
                <Grid item xs={2}>
                  :
                </Grid>
                <Grid item xs={2}>
                  {props.tableData.printOfferData &&
                    props.tableData.printOfferData.data[0].Loan_Details
                      .Down_Payment}
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  Monthly payment
                </Grid>
                <Grid item xs={2}>
                  :
                </Grid>
                <Grid item xs={2}>
                  {props.tableData.printOfferData &&
                    props.tableData.printOfferData.data[0].Loan_Details
                      .Monthly_Payment}
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  Term (Months)
                </Grid>
                <Grid item xs={2}>
                  :
                </Grid>
                <Grid item xs={2}>
                  {props.tableData.printOfferData &&
                    props.tableData.printOfferData.data[0].Loan_Details.Terms}
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  Start Date
                </Grid>
                <Grid item xs={2}>
                  :
                </Grid>
                <Grid item xs={2}>
                  {props.tableData.printOfferData &&
                    props.tableData.printOfferData.data[0].Loan_Details
                      .Start_Date}
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  Maturity Date
                </Grid>
                <Grid item xs={2}>
                  :
                </Grid>
                <Grid item xs={2}>
                  {props.tableData.printOfferData &&
                    props.tableData.printOfferData.data[0].Loan_Details
                      .Maturity_Date}
                </Grid>
                <Grid item xs={2} />
              </Grid>
              <Grid container>
                <Grid item xs={4}>
                  APR
                </Grid>
                <Grid item xs={2}>
                  :
                </Grid>
                <Grid item xs={2}>
                  {props.tableData.printOfferData &&
                    props.tableData.printOfferData.data[0].Loan_Details.APR}
                  %
                </Grid>
                <Grid item xs={2} />
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs
            style={{
              margin: "1% 9%",
              fontSize: "18px",
              color: "#00aab4",
              fontFamily: "Source Sans Pro"
            }}
          >
            <a
              href={Pdf}
              without
              style={{ color: "#00aab4" }}
              rel="noopener noreferrer"
              target="_blank"
            >
              Acceptance <PictureAsPdfIcon />
            </a>
          </Grid>
        </Grid>
        <Grid container>
          <Grid
            item
            xs
            style={{
              margin: "1% 1% 1% 9%",
              fontSize: "16px",
              color: "gray",
              fontFamily: "Source Sans Pro"
            }}
          >
            I have understood the offer and have read the terms and conditions
            of the offer. By signing below, I am giving acceptance of same.
          </Grid>
        </Grid>
        <br />
        <br />
        <Grid container>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              flexDirection: "row",
              margin: "1% 9%"
            }}
          >
            <Typography style={{ color: "#00aab4" }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date:
            </Typography>
            <div>
              {" "}
              &nbsp;&nbsp;
              {moment().format("MM/DD/YYYY")}{" "}
            </div>
          </Grid>
          <Grid
            item
            xs
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}
          >
            <Typography style={{ color: "#00aab4" }}>
              Signature: &nbsp;
            </Typography>
            <SignatureCanvas
              penColor="black"
              canvasProps={{
                width: 250,
                height: 70,
                className: classes.canvasBackgourd
              }}
            />
          </Grid>
        </Grid>
      </div>
      <Grid container style={{ margin: "1% 0% 0% 0%" }}>
        <Grid iten xs={2} />
        <Grid iten xs={8} align="center">
          <Button
            onClick={useReactToPrint({
              content: () => componentRef.current,
              onAfterPrint: () => setShowUploadedButton(true)
            })}
          >
            Print
          </Button>
        </Grid>
        <Grid iten xs={2} />
      </Grid>
      <Modal
        open={uploadFileModal}
        onClose={() => setUploadFileModal(false)}
        align="center"
      >
        <div className={classes.modalDiv} align="center">
          <Card className={classes.modalRootUpload} style={{ width: "35%" }}>
            <div className={classes.statusModalHeader}>
              <div className={classes.statusHeadingText}>
                Upload Signed Acceptance Document
              </div>
              <ClearIcon onClick={() => setUploadFileModal(false)} />
            </div>
            <Grid
              container
              className={classes.margin}
              style={{ padding: "2%" }}
            >
              <Grid item xs={5} />
              <Grid item className={classes.searchRoot} />
              <Grid item xs={5} />
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ padding: "2%" }}
            >
              <Grid item xs={8}>
                {!documentUpload ? (
                  <div>
                    <div style={{ color: "gray" }}>
                      (jpg, png, pdf format only)
                    </div>
                    <TextField
                      className={classes.statusRoot}
                      error
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      error={fileSizeError}
                      helperText="File Size Should be 20kb to 500kb"
                      type="file"
                      onChange={(e) => handleUploadOnChange(e.target.files)}
                      id="file"
                    />
                  </div>
                ) : (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        whiteSpace: "nowrap"
                      }}
                    >
                      <div style={{ fontSize: "18px" }}>Signed Document</div>:{" "}
                      {props.tableData.printOfferData &&
                        props.tableData.printOfferData.data[0]
                          .offerAcceptanceDocument}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "flex-end"
                      }}
                    >
                      <div style={{ marginRight: "6%" }}>
                        <a
                          href={`${DOWNLOAD_PATIENT_OFFER_DOCUMENTS_URL}?fileName=${
                            props.tableData.printOfferData &&
                            props.tableData.printOfferData.data[0]
                              .offerAcceptanceDocument
                          }`}
                          download
                        >
                          <CloudDownloadIcon style={{ color: "#639a78" }} />
                        </a>
                      </div>
                      <div>
                        <DeleteIcon
                          onClick={() => handleDeleteFile()}
                          style={{ color: "#ff4747" }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.marginTop}
              style={{
                padding: "2%",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end"
              }}
            >
              <CustomButton
                disable={fileSizeError || documentUpload}
                aria-controls="simple-menu"
                onClick={() => handleUploadButton()}
                color="primary"
              >
                Upload
              </CustomButton>
            </Grid>
          </Card>
        </div>
      </Modal>
    </div>
  );
};

FinalGenrateOffer.propTypes = {
  showOfferDEtails: PropTypes.object,
  onClick: PropTypes.func,
  uploadOfferDocument: PropTypes.func,
  tableData: PropTypes.object,
  deleteDocumentCall: PropTypes.func,
  patientPaymentDetailsCall: PropTypes.func,
  handleSendEmailSuccess: PropTypes.func
};

export default FinalGenrateOffer;
