import { Checkbox, IconButton, makeStyles } from "@material-ui/core";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import style from "./StyleNew";

const useStyles = makeStyles(style);

export default function TableHeader(props) {
  const classes = useStyles();
  const {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    selected,
    setSelected,
    data,
    headCells,
    dataParameter,
    isSelection,
    showEllipsis,
    isHelpicon,
  } = props;

  const handleRequestSort = (property) => {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => {
    handleRequestSort(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n[dataParameter]);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {isSelection ? (
          <TableCell padding="checkbox" className={classes.cellTextHeaderOffer}>
            <Checkbox
              indeterminate={
                selected.length > 0 && selected.length < data.length
              }
              checked={selected.length === data.length && selected.length !== 0}
              onChange={handleSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
              // color="primary"
              style={{ color: "white" }}
            />
          </TableCell>
        ) : null}
        {headCells.map((headCell) => {
          const sortBy = headCell.sortProperty
            ? headCell.sortProperty
            : headCell.id;
          const isSort = headCell.isSort ? headCell.isSort : false;
          return (
            <TableCell
              align={headCell.actionsCellStyle}
              key={headCell.id}
              sortDirection={orderBy === sortBy ? order : false}
              className={clsx(
                classes.cellTextHeaderOffer,
                isHelpicon && classes.isHelpicon
              )}
              width={headCell.width}
            >
              {isSort ? (
                <>
                  <TableSortLabel
                    className={classes.tableSortTitle}
                    active={orderBy === sortBy}
                    direction={orderBy === sortBy ? order : "asc"}
                    onClick={() => {
                      createSortHandler(sortBy);
                    }}
                    classes={{
                      icon: "sortIcon",
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                  >
                    <Tooltip title={headCell.label}>
                      <span className={clsx({ "ellipsis-row": showEllipsis })}>
                        {headCell.label}
                      </span>
                    </Tooltip>
                    {orderBy === sortBy ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                  {isHelpicon && (
                    <Tooltip title={headCell.helpText} placement="top">
                      <IconButton edge="end" size="small">
                        <HelpOutlineOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              ) : (
                <>
                  {headCell.label}
                  {isHelpicon && (
                    <Tooltip title={headCell.helpText} placement="top">
                      <IconButton edge="end" size="small">
                        <HelpOutlineOutlinedIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                </>
              )}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

TableHeader.defaultProps = {
  order: "",
  setOrder: () => {},
  orderBy: "",
  setOrderBy: () => {},
  selected: [],
  setSelected: () => {},
  data: [],
  headCells: [],
  dataParameter: "",
  isSelection: false,
  showEllipsis: false,
  isHelpicon: false,
};

TableHeader.propTypes = {
  order: PropTypes.string,
  setOrder: PropTypes.func,
  orderBy: PropTypes.string,
  setOrderBy: PropTypes.func,
  selected: PropTypes.array,
  setSelected: PropTypes.func,
  data: PropTypes.array,
  headCells: PropTypes.array,
  dataParameter: PropTypes.string,
  isSelection: PropTypes.bool,
  showEllipsis: PropTypes.bool,
  isHelpicon: PropTypes.bool,
};
