import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import OutlinedInput from "@material-ui/core/OutlinedInput";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  inputSelect: {
    fontSize: "90%",
    fontStyle: "Source Sans Pro",
    color: theme.palette.primary.dark
  },
  inputLableSelect: {
    fontSize: "90%",
    fontStyle: "Source Sans Pro",
    color: theme.palette.primary.dark
  },
  menuItem: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.contrastText,
    padding: "0px 16px"
  },

  listItemText: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.contrastText
  }
}));
const ITEM_HEIGHT = 68;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  style: {
    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    width: "100%"
  },
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
  //   getContentAnchorEl: null
};

function MultipleSelect(props) {
  const classes = useStyles();
  const [personName, setPersonName] = React.useState(
    props.values ? props.values : []
  );
  const [preValue, setPreValue] = React.useState(false);
  const names = !props.data.Options ? props.data : props.data.Options;
  if (
    !preValue &&
    !personName.length &&
    (!props.data.Options ? props.data : props.data.Options)
  ) {
    const value = [];
    if (props.data.Options) {
      props.data.Options.forEach((v) => {
        if (v.permission) {
          value.push(v.name);
        }
      });
      setPersonName(value);
      setPreValue(!preValue);
    } else if (props.lable && props.values) {
      setPersonName(props.values);
      setPreValue(!preValue);
    } else if (props.roles) {
      setPersonName(props.values);
    }
  }

  const handleChange = (event) => {
    setPersonName(event.target.value);
    props.onChangeSelect(event.target.value);
  };

  return (
    <FormControl size="small" fullWidth sx={{ m: 1, width: "100%" }}>
      <InputLabel
        //   className={classes.inputLableSelect}
        id="demo-mutiple-checkbox-label"
      >
        {/* {`${!props.data.Options ? "Providers Accessible" : "Status"}`} */}
      </InputLabel>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple
        value={personName}
        onChange={handleChange}
        input={<OutlinedInput className={classes.inputSelect} />}
        renderValue={(selected) => selected.join(", ")}
        MenuProps={MenuProps}
      >
        {props.data.Options &&
          names.map((v) => (
            <MenuItem
              value={v.name}
              className={classes.menuItem}
              style={{ background: "#fff" }}
            >
              <Checkbox
                style={{ padding: "5px" }}
                checked={personName.indexOf(v.name) > -1}
              />
              <ListItemText className={classes.listItemText} primary={v.name} />
            </MenuItem>
          ))}
        {!props.data.Options &&
          names.map((v) => (
            <MenuItem
              value={v.Porvider_Id}
              className={classes.menuItem}
              style={{ background: "#fff" }}
            >
              <Checkbox
                style={{ padding: "5px" }}
                checked={personName.indexOf(v.Porvider_Id) > -1}
                color="secondary"
              />
              <ListItemText
                className={classes.listItemText}
                primary={v.ProviderData}
              />
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

MultipleSelect.propTypes = {
  data: PropTypes.any,
  onChangeSelect: PropTypes.func,
  values: PropTypes.array,
  lable: PropTypes.bool,
  roles: PropTypes.bool
};

export default connect(null, null)(MultipleSelect);
