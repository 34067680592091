import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const patientDashboard = (state = initialState.patientDashboard, action) => {
  switch (action.type) {
  case types.OPEN_FUNDING_AMOUNT_CALL:
    return { ...state, loading: true };
  case types.OPEN_FUNDING_AMOUNT_SUCCESS:
    return { ...state, loading: false, openFundingData: action.data };
  case types.OPEN_FUNDING_AMOUNT_ERROR:
    return { ...state, error: true, loading: false };
  case types.ACTIVE_PLAN_PATIENT_LIST_CALL:
    return { ...state, loading: true };
  case types.ACTIVE_PLAN_PATIENT_LIST_SUCCESS:
    return { ...state, activePlanPatientList: action.data, loading: false };
  case types.ACTIVE_PLAN_PATIENT_LIST_ERROR:
    return { ...state, error: true, loading: false };
  case types.PENDING_OFFER_AMOUNT_CALL:
    return { ...state, loading: true };
  case types.PENDING_OFFER_AMOUNT_SUCCESS:
    return { ...state, patientOfferAmountData: action.data, loading: false };
  case types.PENDING_OFFER_AMOUNT_ERROR:
    return { ...state, error: true, loading: false };
  case types.PAYMENT_STATUS_DATA_CALL:
    return { ...state, loading: true };
  case types.PAYMENT_STATUS_DATA_SUCCESS:
    return { ...state, paymentStatusData: action.data, loading: false };
  case types.PAYMENT_STATUS_DATA_ERROR:
    return { ...state, error: true, loading: false };
  case types.OFFER_ACCOUNT_STATUS_CALL:
    return { ...state, loading: true };
  case types.OFFER_ACCOUNT_STATUS_SUCCESS:
    return { ...state, offerAccountStatusData: action.data, loading: false };
  case types.OFFER_ACCOUNT_STATUS_ERROR:
    return { ...state, error: true, loading: false };
  case types.ORIGINAL_LOAN_TERM_CALL:
    return { ...state, loading: true };
  case types.ORIGINAL_LOAN_TERM_SUCCESS:
    return { ...state, originalLoanTermData: action.data, loading: false };
  case types.ORIGINAL_LOAN_TERM_ERROR:
    return { ...state, error: true, loading: false };
  case types.REMAINING_FUND_GRAPH_CALL:
    return { ...state, loading: true };
  case types.REMAINING_FUND_GRAPH_SUCCESS:
    return { ...state, remainingFundingData: action.data, loading: false };
  case types.REMAINING_FUND_GRAPH_ERROR:
    return { ...state, error: true, loading: false };
  case types.PAYMENT_LIST_AS_PER_PATIENT_ID_CALL:
    return { ...state, loading: true };
  case types.PAYMENT_LIST_AS_PER_PATIENT_ID_SUCCESS:
    return { ...state, paymentList: action.data, loading: false };
  case types.PAYMENT_LIST_AS_PER_PATIENT_ID_ERROR:
    return { ...state, error: true, loading: false };
  case types.CURRENTLY_SETUP_MONTH_PATIENT_CALL:
    return { ...state, loading: true };
  case types.CURRENTLY_SETUP_MONTH_PATIENT_SUCCESS:
    return { ...state, offetDetailsPatient: action.data, loading: false };
  case types.CURRENTLY_SETUP_MONTH_PATIENT_ERROR:
    return { ...state, error: true, loading: false };
  case types.RESET_PAYMENT_LIST:
    return { ...state, paymentList: false };
  case types.RESET_ERROR_PAYMENT_LIST:
    return { ...state, error: false };
  default:
    return state;
  }
};

export default patientDashboard;
