import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import isEmpty from "lodash/isEmpty";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import ErrorIcon from "@material-ui/icons/Error";
import fullOrigin from "../../assets/img/imgpsh_fullsize_anim.png";

const useStyles = makeStyles(styles);
const PaymentErrorMessage = (props) => {
  const classes = useStyles();
  const [logoPath, setLogoPath] = React.useState("");
  const handleBackToSearch = () => {
    if (!props.patientDashboard.offerAccountStatusData) {
      props.history.push("/admin/Offers");
      // props.state();
      props.emptyGraphData();
    } else {
      props.history.push("/patient/Dashboard");
      // props.state();
    }
  };

  const handleRetryPayment = () => {
    props.setShowPaymentDetails();
    props.setHandleFail(false);
    props.setRetryPayment();
  };

  if (isEmpty(logoPath)) {
    const logoPathFind = new Image();
    if (props.app.portalConfig) {
      const x = props.app.portalConfig.data[0]?.Logo_Image;
      logoPathFind.src = `${x}?" + ${new Date().getTime()}`;
      logoPathFind.onload = function () {
        setLogoPath(logoPathFind.src);
      };
      logoPathFind.onerror = function () {
        setLogoPath(fullOrigin);
      };
    }
  }

  return (
    <>
      <Grid container style={{ margin: "1% 0% 0% 0%" }}>
        <Grid iten xs={2} />
        <Grid iten xs={8} align="center">
          <img src={logoPath} alt="" />
        </Grid>
        <Grid iten xs={2} />
      </Grid>
      <Grid container style={{ margin: "1% 0% 0% 0%" }}>
        <Grid iten xs={2} />
        <Grid iten xs={8} align="center">
          <ErrorIcon style={{ fontSize: "75px", color: "#FF0000" }} />
        </Grid>
        <Grid iten xs={2} />
      </Grid>
      <Grid container style={{ marginTop: "-8%" }}>
        <Grid
          item
          xs={12}
          style={{
            margin: "10% 0% 0% 0%",
            fontSize: "16px",
            color: "#3a57a7",
            fontFamily: "Source Sans Pro"
          }}
        >
          <div align="center">
            <div>
              Something went wrong in payment processing{" "}
              <span style={{ fontWeight: "bold", fontSize: "22px" }}>
                {props.formData.paymentDetailsData.data.amount}
              </span>{" "}
              against Payment No.
              <span style={{ fontWeight: "bold", fontSize: "22px" }}>
                {props.formData.paymentDetailsData.data.offerNumber}
              </span>
              .
            </div>
            <div align="center" style={{ marginLeft: "9.5%" }}>
              <span style={{ fontWeight: "bold", color: "red" }}>
                {props.formData.paymentMakeDetails.message}.
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: "2%", marginBottom: "10%" }}>
        <Grid iten xs={3} />
        <Grid item xs={4} align="center">
          <Button
            variant="contained"
            onClick={() => handleBackToSearch()}
            className={classes.clearButton}
            size="large"
          >
            Back to Patient Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

PaymentErrorMessage.propTypes = {
  formData: PropTypes.object,
  emptyGraphData: PropTypes.func,
  history: PropTypes.object,
  state: PropTypes.func,
  setShowPaymentDetails: PropTypes.func,
  setHandleFail: PropTypes.func,
  setRetryPayment: PropTypes.func,
  patientDashboard: PropTypes.object,
  app: PropTypes.object
};

export default PaymentErrorMessage;
