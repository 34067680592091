import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";

const status = {
  newEntry: 1,
  decline: 6,
  approve: 7,
  onBoarded: 8,
  configured: 10,
  sendforunderwriting: 11,
  live: 12,
  cancelled: 13,
  sendToProvider: 14
};

export const exportToCSV = (mappedLogs, fileName, headerDetails) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";
  const wsExtraHeader = XLSX.utils.aoa_to_sheet(headerDetails);
  const ws = XLSX.utils.sheet_add_json(wsExtraHeader, mappedLogs, {
    origin: "A6"
  });

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

// export const actionPermission = (roleData, catId, pageId, type) => {
//   const roles = get(roleData, "leftMenu.data", false);
//   if (isEmpty(roles) || !catId || !pageId || !type) {
//     return roles;
//   }
//   if (!isEmpty(roles.LeftMenu)) {
//     const mainCat = roles.LeftMenu.filter((cat) => cat.Cat_id === catId);
//     let permission = !!mainCat;
//     if (!permission) {
//       return false;
//     }
//     const pageDetails = mainCat[0].Page_Menu.filter(
//       (page) => page.Page_id === pageId
//     );
//     permission = !!pageDetails;
//     if (!permission) {
//       return false;
//     }
//     const permissionData = pageDetails[0].Options.find(
//       (per) => per.value === type
//     );
//     if (permissionData) {
//       return permissionData.permission;
//     }
//     return false;
//   }
// };

export const checkFormDisabled = (props, values) => {
  if (props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    let allowChangeStatus = false;
    if (!props.mainData.roles.roleTab) {
      if (
        preData.Current_Status == 6 ||
        preData.Current_Status == 7 ||
        preData.Current_Status == 8 ||
        preData.Current_Status == 10 ||
        preData.Current_Status == 12 ||
        preData.Current_Status == 13
      ) {
        allowChangeStatus = true;
      }
    }
    if (props.mainData.roles.roleTab) {
      const filterSetUp =
        props.mainData.roles.roleTab.Roles_Data.Master_Permission.filter(
          (key) => key.Category_Name == "Setup"
        );
      const filterPageMenu =
        filterSetUp.length > 0 &&
        filterSetUp[0].Page_Menu.filter((key) => key.Page_Name == "Providers");
      const filterOptionStatue =
        filterPageMenu &&
        filterPageMenu[0]?.Options?.filter(
          (key) => key?.name == "Change Status"
        );
      if (filterOptionStatue) {
        allowChangeStatus = !filterOptionStatue[0].permission;
        if (!filterOptionStatue[0].permission) {
          if (
            preData.Current_Status == status.newEntry ||
            preData.Current_Status == status.sendToProvider
          ) {
            allowChangeStatus = false;
          } else {
            allowChangeStatus = true;
          }
        } else if (filterOptionStatue[0].permission) {
          if (
            preData.Current_Status == status.newEntry ||
            preData.Current_Status == status.sendToProvider ||
            preData.Current_Status == status.sendforunderwriting
          ) {
            allowChangeStatus = false;
          } else {
            allowChangeStatus = true;
          }
        }
      }
    }
    return allowChangeStatus;
  }
};
