import { get } from "./httpClient";
import { MERCHANT_SIGNUP_RESEND_MAIL } from "../Uintls/Urls";

export const sendMailAgain =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await get(MERCHANT_SIGNUP_RESEND_MAIL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
