/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Box from "@material-ui/core/Box";
// import InputLabel from "@material-ui/core/InputLabel";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
// core components
import TextField from "@material-ui/core/TextField";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import CloseIcon from "@material-ui/icons/Close";
import Card from "components/Card/Card";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Radio from "@material-ui/core/Radio";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import RadioGroup from "@material-ui/core/RadioGroup";
import Divider from "@material-ui/core/Divider";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import FormLabel from "@material-ui/core/FormLabel";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Grid from "@material-ui/core/Grid";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import PersonIcon from "@material-ui/icons/Person";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import DateFnsUtils from "@date-io/date-fns";
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone";
import EventNoteIcon from "@material-ui/icons/EventNote";
import PriorityHighIcon from "@material-ui/icons/PriorityHigh";
import SwipeableViews from "react-swipeable-views";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import PublicIcon from "@material-ui/icons/Public";
import DescriptionIcon from "@material-ui/icons/Description";
import StorageRoundedIcon from "@material-ui/icons/StorageRounded";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import NearMeRoundedIcon from "@material-ui/icons/NearMeRounded";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import RoomIcon from "@material-ui/icons/Room";
import StarIcon from "@material-ui/icons/Star";
import Table from "@material-ui/core/Table";
import AddIcon from "@material-ui/icons/Add";
import Autocomplete from "@material-ui/lab/Autocomplete";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import closeImage from "assets/img/Close_Image.png";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import AttachMoneyRoundedIcon from "@material-ui/icons/AttachMoneyRounded";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import { CardActions } from "@material-ui/core";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Modal from "@material-ui/core/Modal";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import Pdf from "Uintls/The_patient_offer.pdf";
import zollPdf from "Uintls/TPO_Zoll_Data_MSA_v2.2.1209.31870751.pdf";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { locationCity } from "../../constant/locationConstant";
import OwnerInformation from "./OwnerInformation";
import {
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  mpaChecklistComment,
  kycChecklistAttechment,
  mpaChecklistAttechment
} from "../../actions/Operations";
import gif from "../../assets/img/success.gif";
import {
  provideRegistration,
  deleteIncroptration,
  deleteAddressProof,
  deleteIdentityProof,
  getMccList,
  getKycQuestionData,
  getCountryTypeList,
  accountTypeList,
  ownerTypeList,
  updateRegistration,
  getProviderStatus,
  getupdatestatus,
  getProviderActivityById,
  getProviderList,
  changeSingleStatus,
  saveQuestionCall,
  changeProviderFormMode,
  setResetWuestion,
  getProviderParameterCall
} from "../../Redux/Actions/ProvideRegistration";
import { ReactComponent as ActivityIcon } from "../../assets/svgIcons/activityHistoryIcon.svg";
import { ReactComponent as ConfigurationIcon } from "../../assets/svgIcons/configurationIcon.svg";
import { ReactComponent as KYCIcon } from "../../assets/svgIcons/KYCIcon.svg";
import { withStyles } from "@material-ui/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { checkFormDisabled } from "Utils/commonUtils";

const IconsTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000",
    fontSize: "12px"
  },
  tooltipPlacementTop: {
    margin: "4px 0"
  }
}))(Tooltip);

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

const useStyles = makeStyles(styles);

let kycCheckListFlag = false;
let editPermissionFlag = false;
let createPermissionFlag = false;
let livePermissionFlag = false;
let changeStatusPermissionFlag = false;
let allowToBoardFlag = false;

const mandFields = {
  businessName: "Business Name is required.",
  legalBusiness: "Legal Business name is required",
  businessEmail: "Business Email Address is required",
  Owner_First_Name: "First Name is required"
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: "20px" }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function TabPanelAttchment(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box style={{ padding: "20px" }}>{children}</Box>}
    </div>
  );
}

TabPanelAttchment.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

function BusinessForm(props) {
  const [check, setCheck] = React.useState(false);
  const [validationModal, setValidationModal] = React.useState(false);
  const [selectedQuestion, setSelectedQuestion] = React.useState(false);
  const [commentSection, setCommentSection] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [errorMessage, setErrorMessage] = React.useState(false);
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down("xs"));
  const [disabledValue, setDisabledValue] = React.useState(false);
  const [sumError, setSumError] = React.useState(false);
  const [stateInput, setStateInput] = React.useState(true);
  const [checkListModal, setCheckListModal] = React.useState(false);
  const [approveModal, setApproveModal] = React.useState(false);
  const [declineModal, setDeclineModal] = React.useState(false);
  const [textAreaText, setTextAreaText] = React.useState("");
  const [ownerSelect, setOwnerSelect] = React.useState({});
  const [value, setValue] = React.useState(0);
  const [changeStatusModal, setChangeStatusModal] = React.useState(false);
  const classes = useStyles();
  const [data, setData] = React.useState(false);
  const windowWidthLG = useMediaQuery(theme.breakpoints.up("lg"));
  const [mccList, setMccList] = React.useState([]);
  const [fileHandleAttechment, setFileHandleAttechment] = React.useState({
    files: []
  });
  const [submissionSuccess, setSubmissionSuccess] = React.useState(
    props.formData.data
  );
  const [accordian, handleAccordClick] = React.useState({
    index: []
  });
  const [statusUpdate, setStatusUpdate] = React.useState(
    props.formData.statusUpdate
  );
  const [preUpdateQuestion, setPreUpdateQuestion] = React.useState(
    props.formData.questionUpdate
  );
  const [kycListModal, setKycListModal] = React.useState(false);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [mpaCheckListStatus, setMpaCheckListStatus] = React.useState({
    pending: 0,
    progress: 0,
    completed: 0
  });
  const [kycCheckListStatus, setKycCheckListStatus] = React.useState({
    pending: 0,
    progress: 0,
    completed: 0
  });

  const pageCategoryIndex =
    props.mainData.roles.roleTab &&
    props.mainData.roles.roleTab.Roles_Data.Master_Permission.findIndex(
      (x) => x.Category_Name === "Setup"
    );

  const [userOptions, setUserOptions] = React.useState(
    props.mainData.roles.roleTab &&
      props.mainData.roles.roleTab.Roles_Data.Master_Permission[
        pageCategoryIndex
      ].Page_Menu[0] &&
      props.mainData.roles.roleTab.Roles_Data.Master_Permission[
        pageCategoryIndex
      ].Page_Menu[0].Options[3] &&
      props.mainData.roles.roleTab.Roles_Data.Master_Permission[
        pageCategoryIndex
      ].Page_Menu[0].Options[3]
  );
  React.useEffect(() => {
    if (props.formData.mcclist.MCC_Data) {
      const sortedData = props.formData.mcclist.MCC_Data.map((option) => ({
        MCC_Code: option.MCC_Code,
        MCC_Name: `[${option.MCC_Code}] - ${option.MCC_Name}`
      }));
      setMccList([...sortedData]);
    }
  }, [props.formData.mcclist]);
  const [values, handleInput] = React.useState({
    businessName: "",
    legalBusiness: "",
    businessStartDate: "",
    docAdd: false,
    businessDiscrition: "",
    ownerShipType: "",
    businessPhoneNumber: "",
    businessEmail: "",
    businessWebsite: "",
    businessTaxId: "",
    NPI: "",
    businessStock: "",
    businessAddress: "",
    businessSuite: "",
    businessCity: "",
    businessState: "",
    USID: "",
    businessPostalCode: "",
    businessInterNational: "",
    deleteCoperation: false,
    businessCountry: "US",
    accountNumber: "",
    routingNumber: "",

    accountType: "",
    cardPresent: "",
    ownerArray: {
      owners: [
        {
          Owner_First_Name: "",
          Owner_Last_Name: "",
          Owner_Last4_SSN: "",

          Owner_DOB: "",
          Owner_Ownership_Percentage: "",

          Owner_Phone: "",
          Owner_Street_Address: "",
          Owner_Suite: "",
          Owner_City: "",
          Owner_State_Code: "",
          Owner_Postal_Code: "",
          Owner_International_Province: "",
          Owner_Country_Code: "US",
          Owner_Timezone_Code: "",
          ownerAddressProof: "",
          ownerPhotoId: "",
          deleteOwnerPhotoId: false,
          deleteAddressProofId: false,
          ownerAddressProofDetails: {
            File_Name: "",
            File_Type: "",
            Upload_Date_Time: ""
          },
          ownerPhotoIdDetails: {
            File_Name: "",
            File_Type: "",
            Upload_Date_Time: ""
          }
        }
      ]
    },
    cardNotPresent: "",
    eCommers: "",
    cardVolumeMonth: "",
    averageTicketSize: "",
    highTicket: "",
    ussidTypeInput: "",
    eCheckVolumeMonth: "",
    incorportionDocument: "",
    incorportionDocumentDetails: {
      File_Name: "",
      File_Type: "",
      File_Path: "",
      Upload_Date_Time: ""
    },
    eCheckAverage: "",
    eCheckHigh: "",
    status: "",
    editable: false
  });
  const [validationError, setVlidationError] = React.useState({
    businessName: false,
    legalBusiness: false,
    businessStartDate: false,
    businessStartDateMessage: false,
    businessDiscrition: false,
    ownerShipType: false,
    businessPhoneNumber: false,
    businessEmail: false,
    businessWebsite: false,
    businessTaxId: false,
    NPI: false,
    businessStock: false,
    businessAddress: false,
    suite: false,
    businessSuite: false,
    businessCity: false,
    businessState: false,
    USID: false,
    incorportionDocument: false,

    businessPostalCode: false,
    businessInterNational: false,
    businessCountry: false,
    accountNumber: false,
    routingNumber: false,

    accountType: false,
    cardPresent: false,
    ownerArray: {
      owners: [
        {
          Owner_First_Name: false,
          Owner_Last_Name: false,
          Owner_Last4_SSN: false,
          Owner_DOB: false,
          Owner_Ownership_Percentage: false,

          Owner_Phone: false,
          Owner_Street_Address: false,
          Owner_Suite: false,
          Owner_City: false,
          Owner_State_Code: false,
          Owner_Postal_Code: false,
          Owner_International_Province: false,
          Owner_Country_Code: false,
          Owner_Timezone_Code: false,
          ownerAddressProof: false,
          ownerPhotoId: false,
          ownerAddressProofDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          },
          ownerPhotoIdDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          }
        }
      ]
    },
    cardNotPresent: false,
    eCommers: false,
    cardVolumeMonth: false,
    averageTicketSize: false,
    highTicket: false,
    ussidTypeInput: false,
    eCheckVolumeMonth: false,
    eCheckAverage: false,
    eCheckHigh: false,
    status: "",
    checkedB: false
  });
  const PROVIDER_STATUS_CODE =
    process.env.REACT_APP_PROVIDER_STATUS_CODE.split(",");
  const ProviderStatusCode = props.formData.providerDetailsById.Providers_Data
    ? props.formData.providerDetailsById.Providers_Data[0].Current_Status
    : "";
  const handleClose = () => {
    setValidationModal(false);
  };

  const checkValidation = (saveDraft = false, autoValidate = false) => {
    let validate = true;
    const error = { ...validationError };
    for (const key in values) {
      if (saveDraft) {
        if (
          values[key] === "" &&
          (key === "legalBusiness" || key === "businessEmail")
        ) {
          error[key] = true;
          validate = false;
        }
        if (key === "ownerArray") {
          values[key].owners.forEach((item, i) => {
            if (item.Owner_First_Name === "") {
              error[key].owners[i].Owner_First_Name = true;
              validate = false;
            }
          });
        }
        // if (key === "ownerArray") {
        //   values[key].owners.forEach((item, i) => {
        //     if (item.Owner_Last_Name === "") {
        //       error[key].owners[i].Owner_Last_Name = true;
        //       validate = false;
        //     }
        //   });
        // }
      }
      if (!saveDraft) {
        if (check) {
          error.checkedB = false;
        } else {
          error.checkedB = true;
        }
      }
      if (!saveDraft) {
        if (
          (values[key] === "" ||
            values[key] === null ||
            values[key] === undefined) &&
          (key === "ownerShipType" ||
            key === "businessPhoneNumber" ||
            key === "businessTaxId" ||
            key === "NPI" ||
            key === "businessType" ||
            key === "businessName" ||
            key === "legalBusiness" ||
            key === "businessAddress" ||
            key === "ownerAddressProof" ||
            key === "ownerPhotoId" ||
            // key === "cardVolumeMonth" ||
            // key === "averageTicketSize" ||
            // key === "highTicket" ||
            key === "businessState" ||
            key === "businessCity" ||
            key === "businessPostalCode" ||
            key === "USID" ||
            key === "routingNumber" ||
            key === "accountType" ||
            key === "accountNumber" ||
            key === "bankDocumentType" ||
            key === "titleTermsCondition" ||
            key === "businessStartDate" ||
            key === "Business_Start_Date" ||
            key === "businessEmail" ||
            key === "businessSuite" ||
            key === "cardPresent" ||
            key === "eCommers" ||
            key === "incorportionDocument" ||
            key === "checkedB" ||
            key === "cardNotPresent")
        ) {
          error[key] = true;
          validate = false;
        }
        if (key === "businessTaxId") {
          if (values[key] === "" || values[key].length !== 9) {
            error[key] = true;
            validate = false;
          }
        }

        if (key === "businessPostalCode") {
          if (values[key] === "" || values[key].length !== 5) {
            error[key] = true;
            validate = false;
          }
        }

        if (key === "businessPhoneNumber") {
          if (values[key] === "" || values[key].length !== 12) {
            error[key] = true;
            validate = false;
          }
        }
        if (key === "businessStartDate") {
          if (values[key] === "" || values[key] === null) {
            error[key] = true;
            validate = false;
          }
        }
        if (key === "ownerAddressProof") {
          if (values[key] === "" || values[key] === null) {
            error[key] = true;
            validate = false;
          }
        }
        if (key === "incorportionDocument") {
          if (values[key] === "" || values[key] === null) {
            error[key] = true;
            validate = false;
          }
        }
        // if (key === "checkedB") {
        //   if (values[key] === "" || values[key] === null) {
        //     error[key] = true;
        //     validate = false;
        //   }
        // }

        if (!check) {
          validate = false;
          error.checkedB = true;
        }
        // if (key === "averageTicketSize" || key === "highTicket") {
        //   if (
        //     parseInt(values.averageTicketSize) > parseInt(values.highTicket)
        //   ) {
        //     validate = false;
        //     error.averageTicketSize = true;
        //     error.highTicket = true;
        //     if (error.averageTicketSize || error.highTicket) {
        //       validate = false;
        //     }
        //   } else {
        //     error.averageTicketSize = false;
        //     error.highTicket = false;
        //   }
        // }
        // if (key === "highTicket" || key === "cardVolumeMonth") {
        //   if (parseInt(values.highTicket) > parseInt(values.cardVolumeMonth)) {
        //     validate = false;
        //     error.cardVolumeMonth = true;
        //     error.highTicket = true;
        //     if (error.cardVolumeMonth || error.highTicket) {
        //       validate = false;
        //     }
        //   }
        // }

        if (key === "ownerArray") {
          values[key].owners.forEach((item, i) => {
            if (item.Owner_First_Name === "") {
              error[key].owners[i].Owner_First_Name = true;
              validate = false;
            }
            if (item.Owner_Last_Name === "") {
              error[key].owners[i].Owner_Last_Name = true;
              validate = false;
            }

            if (
              item.Owner_Ownership_Percentage === "" ||
              item.Owner_Ownership_Percentage === null
            ) {
              error[key].owners[i].Owner_Ownership_Percentage = true;
              validate = false;
            }
            if (
              item.ownerAddressProofDetails.File_Name === "" ||
              item.ownerAddressProof === null
            ) {
              error[key].owners[i].ownerAddressProof = true;
              validate = false;
            }
            if (
              item.ownerPhotoIdDetails.File_Name === "" ||
              item.ownerPhotoId === ""
            ) {
              error[key].owners[i].ownerPhotoId = true;
              validate = false;
            }
            if (item.Owner_Last4_SSN === "") {
              error[key].owners[i].Owner_Last4_SSN = true;
              validate = false;
            }
            if (item.Owner_Street_Address === "") {
              error[key].owners[i].Owner_Street_Address = true;
              validate = false;
            }

            if (item.Owner_Suite === "") {
              error[key].owners[i].Owner_Suite = true;
              validate = false;
            }
            if (item.Owner_State_Code === "") {
              error[key].owners[i].Owner_State_Code = true;
              validate = false;
            }
            if (item.Owner_City === "") {
              error[key].owners[i].Owner_City = true;
              validate = false;
            }
            if (item.Owner_Postal_Code === "") {
              error[key].owners[i].Owner_Postal_Code = true;
              validate = false;
            }
            if (item.Owner_Phone === "") {
              error[key].owners[i].Owner_Phone = true;
              validate = false;
            }
            if (item.Owner_DOB === "") {
              error[key].owners[i].Owner_DOB = true;
              validate = false;
            }
            if (item.Owner_DOB === undefined) {
              error[key].owners[i].Owner_DOB = true;
              validate = false;
            }
            if (item.Owner_DOB === null) {
              error[key].owners[i].Owner_DOB = true;
              validate = false;
            }
            if (item.Owner_DOB !== "") {
              if (moment().diff(item.Owner_DOB, "days") < 0) {
                error[key].owners[i].Owner_DOB = true;
                validate = false;
              }
            }
          });
        }
      }
    }
    setVlidationError(error);
    if (!saveDraft) {
      if (!validate) {
        setValidationModal(true);
      }
    }
    if (saveDraft) {
      if (!validate) {
        setValidationModal(true);
      }
    }
    return validate;
  };

  React.useEffect(() => {
    if (!props.formData.mcclist) {
      props.getMccList();
    }
    if (!props.formData.accountTypeList) {
      props.accountTypeList();
    }
    if (!props.formData.ownerShipTypeList) {
      props.ownerTypeList();
    }
    if (!props.formData.countryTypeList) {
      props.getCountryTypeList();
    }
    if (!props.formData.questionData && props.formData.formMode === "Edit") {
      const data = {
        providerId:
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById.Providers_Data[0]._id
      };
    }
    if (!props.formData.kycQuestionData && props.formData.formMode === "Edit") {
      const data = {
        providerId:
          props.formData.providerDetailsById &&
          props.formData.providerDetailsById.Providers_Data[0]._id,
        ownerId: ownerSelect.Owner_ID
      };
      // if (ownerSelect.Owner_ID) {
      //   props.getKycQuestionData(data);
      // }
    }
  }, []);

  React.useEffect(() => {
    if (props.mainData.roles.roleTab && !userOptions) {
      setUserOptions(
        props.mainData.roles.roleTab.Roles_Data.Master_Permission[
          pageCategoryIndex
        ].Page_Menu[0].Options[3]
      );
    }
    if (submissionSuccess !== props.formData.data) {
      setValidationModal(false);
      if (props.formData.data && props.formData.data.status) {
        setSubmissionSuccess(props.formData.data);
        enqueueSnackbar("Provider Saved Successfully", {
          variant: "success"
        });
        handlelistBack();
      } else if (
        props.formData.data &&
        !props.formData.data.status &&
        props.formData.data.errors
      ) {
        setErrorMessage(props.formData.data.errors);
        const allValidation = { ...validationError };
        props.formData.data.errors.forEach((key) => {
          if (key.param === "Business_Name") {
            allValidation.businessName = true;
          }
          if (key.param === "Legal_Name") {
            allValidation.legalBusiness = true;
          }
          if (key.param === "Business_Start_Date") {
            allValidation.businessStartDate = true;
          }
          if (key.param === "MCC") {
            allValidation.USID = true;
          }
          if (key.param === "Business_Description") {
            allValidation.businessDiscrition = true;
          }
          if (key.param === "Ownership_Type") {
            allValidation.ownerShipType = true;
          }
          if (key.param === "Business_Phone") {
            allValidation.businessPhoneNumber = true;
          }
          if (key.param === "Business_EMail") {
            allValidation.businessEmail = true;
          }
          if (key.param === "Website") {
            allValidation.businessWebsite = true;
          }
          if (key.param === "NPI") {
            allValidation.NPI = true;
          }
          if (key.param === "Fedral_Tax_ID") {
            allValidation.businessTaxId = true;
          }
          if (key.param === "Stock_Symbol") {
            allValidation.businessStock = true;
          }
          if (key.param === "Business_Address") {
            allValidation.businessAddress = true;
          }
          if (key.param === "Suite") {
            allValidation.businessSuite = true;
          }
          if (key.param === "Country_Code") {
            allValidation.businessCountry = true;
          }
          if (key.param === "State_Code") {
            allValidation.businessState = true;
          }
          if (key.param === "City") {
            allValidation.businessCity = true;
          }
          if (key.param === "International_Province") {
            allValidation.businessInterNational = true;
          }
          if (key.param === "Postal_Code") {
            allValidation.businessPostalCode = true;
          }
          if (key.param === "Account_Number") {
            allValidation.accountNumber = true;
          }
          if (key.param === "Routing_Number") {
            allValidation.routingNumber = true;
          }
          if (key.param === "Name_On_Account") {
            allValidation.legalBusiness = true;
          }
          if (key.param === "Account_Type") {
            allValidation.accountType = true;
          }
          if (key.param === "Card_Swiped") {
            allValidation.cardPresent = true;
          }
          if (key.param === "Card_Not_Present") {
            allValidation.cardNotPresent = true;
          }
          if (key.param === "ECommerce") {
            allValidation.eCommers = true;
          }
          if (key.param === "checkedB") {
            allValidation.checkedB = true;
          }
          if (key.param === "Monthly_Card_Volume") {
            allValidation.cardVolumeMonth = true;
          }

          if (key.param === "Average_Ticket_Size") {
            allValidation.averageTicketSize = true;
          }
          if (key.param === "High_Ticket") {
            allValidation.highTicket = true;
          }
          if (key.param === "Monthly_ACH_Volume") {
            allValidation.eCheckVolumeMonth = true;
          }
          // if (key.param === "incorportionDocument") {
          //   allValidation.incorportionDocument = true;
          // }
          if (key.param === "Average_ACH") {
            allValidation.eCheckAverage = true;
          }
          if (key.param === "High_ACH") {
            allValidation.eCheckHigh = true;
          }
          if (key.param === "Owner_Information[0].Owner_Last4_SSN") {
            allValidation.ownerArray.owners[0].Owner_Last4_SSN = true;
          }
          if (key.param === "Owner_Information[0].Owner_Ownership_Percentage") {
            allValidation.ownerArray.owners[0].Owner_Ownership_Percentage = true;
          }

          if (key.param === "Owner_Information[0].Owner_Phone") {
            allValidation.ownerArray.owners[0].Owner_Phone = true;
          }
          if (key.param === "Owner_Information[0].Owner_Street_Address") {
            allValidation.ownerArray.owners[0].Owner_Street_Address = true;
          }
          if (key.param === "Owner_Suite[0].Owner_Suite") {
            allValidation.ownerArray.owners[0].Owner_Suite = true;
          }
          if (key.param === "Owner_Information[0].Owner_State_Code") {
            allValidation.ownerArray.owners[0].Owner_State_Code = true;
          }
          if (key.param === "Owner_Information[0].Owner_City") {
            allValidation.ownerArray.owners[0].Owner_City = true;
          }
          if (key.param === "Owner_Information[0].Owner_Postal_Code") {
            allValidation.ownerArray.owners[0].Owner_Postal_Code = true;
          }
          if (key.param === "Owner_Information[0].Owner_Postal_Code_Ext") {
            allValidation.ownerArray.owners[0].Owner_Postal_Code_Ext = true;
          }

          if (key.param === "Owner_Information[0].ownerPhotoId") {
            allValidation.ownerArray.owners[0].ownerPhotoId = true;
          }
          if (key.param === "Owner_Information[0].ownerAddressProof") {
            allValidation.ownerArray.owners[0].ownerAddressProof = true;
          }
        });
        setVlidationError(allValidation);
        setSubmissionSuccess(props.formData.data);
        setValidationModal(true);
      }
    }

    if (statusUpdate !== props.formData.statusUpdate) {
      if (props.formData.statusUpdate && props.formData.statusUpdate.status) {
        setStatusUpdate(props.formData.statusUpdate);
        enqueueSnackbar("Provider status is updated Successfully", {
          variant: "success"
        });
        handlelistBack();
      } else if (
        props.formData.statusUpdate &&
        !props.formData.statusUpdate.status &&
        props.formData.statusUpdate.message === "Provider status already exits"
      ) {
        enqueueSnackbar(props.formData.statusUpdate.message, {
          variant: "error"
        });
        setSubmissionSuccess(props.formData.data);
      }
    }
    if (props.formData.questionUpdate !== preUpdateQuestion) {
      setPreUpdateQuestion(props.formData.questionUpdate);
      if (
        props.formData.questionUpdate &&
        props.formData.questionUpdate.status
      ) {
        const data = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id
        };
        // if (ownerSelect.Owner_ID) {
        //   props.getKycQuestionData({ ...data, ownerId: ownerSelect.Owner_ID });
        // }

        enqueueSnackbar(props.formData.questionUpdate.message, {
          variant: "success"
        });
      }
    }
  }, [submissionSuccess, props]);

  // React.useEffect(() => {
  //   if (ownerSelect.Owner_ID) {
  //     const data = {
  //       providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
  //       ownerId: ownerSelect.Owner_ID
  //     };
  //     // props.getKycQuestionData(data);
  //     props.statusPreameterKyc(
  //       data,
  //       () => {},
  //       (err) => {
  //         enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
  //           variant: "error"
  //         });
  //       }
  //     );
  //   }
  // }, [ownerSelect]);

  const handleAddNew = () => {
    props.changeProviderFormMode("Create");
    handleInput({
      businessName: "",
      legalBusiness: "",
      businessStartDate: "",
      docAdd: false,
      businessDiscrition: "",
      ownerShipType: "",
      businessPhoneNumber: "",
      businessEmail: "",
      businessWebsite: "",
      businessTaxId: "",
      NPI: "",
      businessStock: "",
      businessAddress: "",
      suite: "",
      businessSuite: "",
      businessCity: "",
      businessState: "",
      USID: "",
      businessPostalCode: "",
      businessInterNational: "",
      deleteCoperation: false,
      businessCountry: "US",
      accountNumber: "",
      routingNumber: "",

      accountType: "",
      cardPresent: "",
      ownerArray: {
        owners: [
          {
            Owner_First_Name: "",
            Owner_Last_Name: "",
            Owner_Last4_SSN: "",

            Owner_DOB: "",
            Owner_Ownership_Percentage: "",

            Owner_Phone: "",
            Owner_Street_Address: "",
            Owner_Suite: "",
            Owner_City: "",
            Owner_State_Code: "",
            Owner_Postal_Code: "",
            Owner_International_Province: "",
            Owner_Country_Code: "US",
            Owner_Timezone_Code: "",
            ownerAddressProof: "",
            ownerPhotoId: "",
            deleteOwnerPhotoId: false,
            deleteAddressProofId: false,
            ownerAddressProofDetails: {
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: ""
            },
            ownerPhotoIdDetails: {
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: ""
            }
          }
        ]
      },
      cardNotPresent: "",
      eCommers: "",
      cardVolumeMonth: "",
      averageTicketSize: "",
      highTicket: "",
      ussidTypeInput: "",
      eCheckVolumeMonth: "",
      incorportionDocument: "",
      incorportionDocumentDetails: {
        File_Name: "",
        File_Type: "",
        File_Path: "",
        Upload_Date_Time: ""
      },
      eCheckAverage: "",
      eCheckHigh: "",
      status: "",
      checkedB: "",
      editable: false
    });
    setVlidationError({
      businessName: false,
      legalBusiness: false,
      businessStartDate: false,
      businessDiscrition: false,
      ownerShipType: false,
      businessPhoneNumber: false,
      businessEmail: false,
      businessWebsite: false,
      businessTaxId: false,
      NPI: false,
      businessStock: false,
      businessAddress: false,
      suite: false,
      businessSuite: false,
      businessCity: false,
      businessState: false,
      USID: false,
      incorportionDocument: false,
      incorportionDocumentDetails: {
        File_Name: false,
        File_Type: false,
        File_Path: false,
        Upload_Date_Time: false
      },
      businessPostalCode: false,
      businessInterNational: false,
      businessCountry: false,
      accountNumber: false,
      routingNumber: false,

      accountType: false,
      cardPresent: false,
      ownerArray: {
        owners: [
          {
            Owner_First_Name: false,
            Owner_Last_Name: false,
            Owner_Last4_SSN: false,
            Owner_DOB: false,
            Owner_Ownership_Percentage: false,

            Owner_Phone: false,
            Owner_Street_Address: false,
            Owner_Suite: false,
            Owner_City: false,
            Owner_State_Code: false,
            Owner_Postal_Code: false,
            Owner_International_Province: false,
            Owner_Country_Code: false,
            Owner_Timezone_Code: false,
            ownerAddressProof: false,
            ownerPhotoId: false,
            ownerAddressProofDetails: {
              File_Name: false,
              File_Type: false,
              Upload_Date_Time: false
            },
            ownerPhotoIdDetails: {
              File_Name: false,
              File_Type: false,
              Upload_Date_Time: false
            }
          }
        ]
      },
      cardNotPresent: false,
      eCommers: false,
      cardVolumeMonth: false,
      averageTicketSize: false,
      highTicket: false,
      ussidTypeInput: false,
      eCheckVolumeMonth: false,
      eCheckAverage: false,
      eCheckHigh: false,
      status: "",
      checkedB: true
    });
    setCheck(false);
  };

  const addOwner = () => {
    if (values.ownerArray.owners.length < 5) {
      const ownerData = { ...values };
      const validation = { ...validationError };
      values.ownerArray.owners.push({
        Owner_First_Name: "",
        Owner_Last_Name: "",
        Owner_Last4_SSN: "",
        Owner_DOB: "",
        Owner_Ownership_Percentage: "",

        Owner_Phone: "",
        Owner_Street_Address: "",
        Owner_Suite: "",
        Owner_City: "",
        Owner_State_Code: "",
        Owner_Postal_Code: "",
        Owner_International_Province: "",
        Owner_Country_Code: "USA",
        Owner_Timezone_Code: " ",
        ownerAddressProof: false,
        ownerPhotoId: false,
        ownerAddressProofDetails: {
          File_Name: "",
          File_Type: "",
          Upload_Date_Time: ""
        },
        ownerPhotoIdDetails: {
          File_Name: "",
          File_Type: "",
          Upload_Date_Time: ""
        }
      });
      validation.ownerArray.owners.push({
        Owner_First_Name: false,
        Owner_Last_Name: false,
        Owner_Last4_SSN: false,
        Owner_DOB: false,
        Owner_Ownership_Percentage: false,

        Owner_Phone: false,
        Owner_Street_Address: false,
        Owner_Suite: false,
        Owner_City: false,
        Owner_State_Code: false,
        Owner_Postal_Code: false,
        Owner_International_Province: false,
        Owner_Country_Code: false,
        Owner_Timezone_Code: false,
        ownerAddressProof: false,
        ownerPhotoId: false,
        ownerAddressProofDetails: {
          File_Name: false,
          File_Type: false,
          Upload_Date_Time: false
        },
        ownerPhotoIdDetails: {
          File_Name: false,
          File_Type: false,
          Upload_Date_Time: false
        }
      });
      setVlidationError(validation);
      handleInput(ownerData);
    }
  };

  function formatSocialSecurity(v) {
    var r = v.replace(/\D/g, "");
    if (r.length > 9) {
      r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    } else if (r.length > 4) {
      r = r.replace(/^(\d\d\d)(\d{2})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
    // // let val;
    // val = val.replace(/\D/g, "");
    // val = val.replace(/^(\d{3})/, "$1-");
    // val = val.replace(/-(\d{2})/, "-$1-");
    // val = val.replace(/(\d)-(\d{4}).*/, "$1-$2");
    // return val;
  }
  const handleAccordian = (i) => {
    const accordianValue = { ...accordian };
    if (accordianValue.index.length > 0) {
      const checkPreValue = accordianValue.index.filter((v) => v === i);
      if (checkPreValue.length > 0) {
        accordianValue.index = accordianValue.index.filter((v) => v !== i);
      } else {
        accordianValue.index.push(i);
      }
    } else {
      accordianValue.index.push(i);
    }
    handleAccordClick(accordianValue);
  };

  const handleCommentSection = (value, type) => {
    setSelectedQuestion(value);
    if (value.comment) {
      setTextAreaText(value.comment);
    } else {
      setTextAreaText("");
    }
    setCommentSection(true);
  };

  const ownerOnchange = (index, data, type, e) => {
    const error = { ...validationError };
    const ownerData = { ...values };
    if (type === "ownerPhotoIdType") {
      error.ownerArray.owners[index].ownerPhotoId = false;
      ownerData.ownerArray.owners[index][type] = data;
      ownerData.ownerArray.owners[index].ownerPhotoIdDetails.Doc_Type = data;
    } else if (type === "ownerAddressProofType") {
      error.ownerArray.owners[index].ownerPhotoId = false;
      ownerData.ownerArray.owners[index][type] = data;
      ownerData.ownerArray.owners[index].ownerAddressProofDetails.Doc_Type =
        data;
    }
    if (type === "Owner_Last4_SSN") {
      if (
        e.nativeEvent.inputType === "deleteContentBackward" ||
        e.nativeEvent.inputType === "deleteContentForward"
      ) {
        ownerData.ownerArray.owners[index][type] = "";
        error.ownerArray.owners[index][type] = true;
      } else if (ownerData.ownerArray.owners[index][type].length !== 3) {
        ownerData.ownerArray.owners[index][type] = data;
        error.ownerArray.owners[index][type] = true;
      } else {
        ownerData.ownerArray.owners[index][type] = data;
        error.ownerArray.owners[index][type] = false;
      }
    } else if (type == "Owner_Phone") {
      if (data.length != 12) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type == "Owner_Ownership_Percentage") {
      if (!/^(?=.*\d)\d{0,2}(?:)?$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type == "Owner_Street_Address") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{2,50}$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type == "Owner_Suite") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{2,50}$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type == "Owner_City") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{2,50}$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type == "Owner_First_Name") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{3,50}$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type == "Owner_Last_Name") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{3,50}$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type === "Owner_Postal_Code") {
      if (!/^[0-9]{5}$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type === "Owner_Postal_Code_Ext") {
      if (!/^[0-9]{4}$/.test(data)) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else if (type === "Owner_Email") {
      const res = data.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      );
      if (res === null) {
        error.ownerArray.owners[index][type] = true;
        ownerData.ownerArray.owners[index][type] = data;
      } else {
        error.ownerArray.owners[index][type] = false;
        ownerData.ownerArray.owners[index][type] = data;
      }
    } else {
      error.ownerArray.owners[index][type] = false;
      ownerData.ownerArray.owners[index][type] = data;
    }
    if (type === "Owner_DOB") {
      if (data) {
        var eighteenYearsAgo = moment().subtract(18, "years");
        var birthday = moment(data);
        if (moment(eighteenYearsAgo).isBefore(birthday)) {
          error.ownerArray.owners[index][type] = true;
          ownerData.ownerArray.owners[index][type] = data;
        }
        if (moment(eighteenYearsAgo).isAfter(birthday)) {
          error.ownerArray.owners[index][type] = false;
          ownerData.ownerArray.owners[index][type] = data;
        }
      }
    }
    if (data === "") {
      error.ownerArray.owners[index][type] = false;
    }
    handleInput(ownerData);
    setVlidationError(error);
  };

  const ProviderStatus =
    props.formData.formMode === "Create"
      ? "New Entry"
      : props.formData.statusList &&
        props.formData.statusList.Provider_Status_Data.filter(
          (key) =>
            key.Status_Code ===
            (props.formData.formMode === "Create"
              ? 1
              : props.formData.providerDetailsById &&
                props.formData.providerDetailsById?.Providers_Data[0]
                  ?.Current_Status)
        ).length > 0 &&
        props.formData.statusList.Provider_Status_Data.filter(
          (key) =>
            key.Status_Code ===
            (props.formData.formMode === "Create"
              ? 1
              : props.formData.providerDetailsById &&
                props.formData.providerDetailsById?.Providers_Data[0]
                  ?.Current_Status)
        )[0].Provider_Status;

  if (props.mainData.roles.roleTab) {
    const kycIndex = props.mainData.roles.roleTab.Roles_Data.Master_Permission[
      pageCategoryIndex
    ].Page_Menu[0].Options.findIndex((x) => x.name === "Allow to Checklist");

    const changeStatusIndex =
      props.mainData.roles.roleTab.Roles_Data.Master_Permission[
        pageCategoryIndex
      ].Page_Menu[0].Options.findIndex((x) => x.name === "Change Status");

    const editIndex = props.mainData.roles.roleTab.Roles_Data.Master_Permission[
      pageCategoryIndex
    ].Page_Menu[0].Options.findIndex((x) => x.name === "Edit Information");

    const liveIndex = props.mainData.roles.roleTab.Roles_Data.Master_Permission[
      pageCategoryIndex
    ].Page_Menu[0].Options.findIndex((x) => x.name === "Allow to live");

    const boardIndex =
      props.mainData.roles.roleTab.Roles_Data.Master_Permission[
        pageCategoryIndex
      ].Page_Menu[0].Options.findIndex((x) => x.name === "Allow to Board");

    const createIndex =
      props.mainData.roles.roleTab.Roles_Data.Master_Permission[
        pageCategoryIndex
      ].Page_Menu[0].Options.findIndex((x) => x.name === "Create");

    allowToBoardFlag =
      boardIndex !== -1
        ? props.mainData.roles.roleTab.Roles_Data.Master_Permission[
            pageCategoryIndex
          ].Page_Menu[0].Options[boardIndex].permission
        : false;

    kycCheckListFlag =
      kycIndex !== -1
        ? props.mainData.roles.roleTab.Roles_Data.Master_Permission[
            pageCategoryIndex
          ].Page_Menu[0].Options[kycIndex].permission
        : false;

    editPermissionFlag =
      editIndex !== -1
        ? props.mainData.roles.roleTab.Roles_Data.Master_Permission[
            pageCategoryIndex
          ].Page_Menu[0].Options[editIndex].permission
        : false;

    createPermissionFlag =
      createIndex !== -1
        ? props.mainData.roles.roleTab.Roles_Data.Master_Permission[
            pageCategoryIndex
          ].Page_Menu[0].Options[createIndex].permission
        : false;

    livePermissionFlag =
      liveIndex !== -1
        ? props.mainData.roles.roleTab.Roles_Data.Master_Permission[
            pageCategoryIndex
          ].Page_Menu[0].Options[liveIndex].permission
        : false;

    changeStatusPermissionFlag =
      changeStatusIndex !== -1
        ? props.mainData.roles.roleTab.Roles_Data.Master_Permission[
            pageCategoryIndex
          ].Page_Menu[0].Options[changeStatusIndex].permission
        : false;

    // if (permissionFor === "DELETE") {
    //   return (
    //     ProviderStatus === "New Entry" ||
    //     (kycCheckListFlag && editPermissionFlag && ProviderStatus !== "Live")
    //   );
    // } else if (permissionFor === "ACTIONS") {
    //   let actions =
    //     (!kycCheckListFlag && ProviderStatus !== "New Entry") ||
    //     ProviderStatus === "Live" ||
    //     (!editPermissionFlag && ProviderStatus !== "New Entry") ||
    //     (!createPermissionFlag && ProviderStatus === "New Entry");
    //   return !action;
    // } else if (permissionFor === "FIELDS") {
    //   let fields =
    //     (!kycCheckListFlag && ProviderStatus !== "New Entry") ||
    //     ProviderStatus === "Live" ||
    //     (!editPermissionFlag && ProviderStatus !== "New Entry") ||
    //     (!createPermissionFlag && ProviderStatus === "New Entry");
    //   return fields;
    // }
  }

  if (ProviderStatus && ProviderStatus !== "New Entry" && !check) {
    setCheck(true);
  }

  const deleteOwner = (index) => {
    const initialValue = {
      owners: []
    };
    const initialValidateValue = {
      owners: []
    };
    const value = { ...values };
    const ValidateErrorDelete = { ...validationError };
    if (value.ownerArray.owners.length > 1) {
      value.ownerArray.owners.forEach((v, i) => {
        if (i !== index) {
          initialValue.owners.push(v);
        }
      });
      if (ValidateErrorDelete.ownerArray.owners.length > 1) {
        ValidateErrorDelete.ownerArray.owners.forEach((v, i) => {
          if (i !== index) {
            initialValidateValue.owners.push(v);
          }
        });
        ValidateErrorDelete.ownerArray = initialValidateValue;
        value.ownerArray = initialValue;
      }
      handleInput(value);
      setVlidationError(ValidateErrorDelete);
    }
  };
  function formatPhoneNumber(v) {
    let r = v.replace(/\D/g, "");
    if (r.length > 11) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    }
    if (r.length > 6) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 3) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }
  const approveYesClick = () => {
    if (checkListModal) {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Approve"
      };
      props.statusApproveMpa(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success"
          });
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error"
          });
        }
      );
      setApproveModal(false);
    }
    if (kycListModal) {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Approve"
      };
      props.statusApproveKyc(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success"
          });
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error"
          });
        }
      );
      setDeclineModal(false);
    }
  };

  const declineYesClick = () => {
    if (checkListModal) {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Reject"
      };
      props.statusApproveMpa(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success"
          });
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error"
          });
        }
      );
      setApproveModal(false);
    }
    if (kycListModal) {
      const requestData = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: "Reject"
      };
      props.statusApproveKyc(
        requestData,
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success"
          });
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error"
          });
        }
      );
      setDeclineModal(false);
    }
  };
  const handlePopupModel = () => {
    if (values.status == 13) {
      setChangeStatusModal(true);
    }
    if (values.status != 13) {
      const data = {
        id: props.formData.providerDetailsById.Providers_Data[0]._id,
        status: values.status
      };
      props.changeSingleStatus(data);
    }
  };
  const handleCheckListModalClose = (type) => {
    if (type === "kyc") {
      setKycListModal(false);
      handleAccordClick({ index: [] });
    }
    if (type === "mpa") {
      setCheckListModal(false);
      handleAccordClick({ index: [] });
    }
  };

  const handleBack = () => {
    props.history.push(`/${props.mainData.app.layout}/Providers`);
    props.setResetWuestion();
  };

  function convertformat(date) {
    if (!!date) {
      let newDate = date.split("T")[0];

      newDate = newDate.split("-");
      let month = newDate.splice(1, 1);
      newDate.push(month);
      newDate = newDate.reverse("").join("/");
      return newDate;
    }
  }

  if (!data && props.formData.providerDetailsById) {
    const preData = props.formData.providerDetailsById.Providers_Data[0];
    const allState = { ...values };
    const statusId = {
      statusCode: preData.Current_Status
    };
    props.getProviderStatus(statusId);
    allState.status = preData.Current_Status;
    allState.editable = preData.Edit_Status;
    allState.businessName = preData.Business_Name;
    allState.legalBusiness = preData.Legal_Name;
    allState.businessStartDate =
      preData.Business_Start_Date &&
      preData.Business_Start_Date !== "Invalid date"
        ? convertformat(preData.Business_Start_Date)
        : null;
    allState.businessDiscrition = preData.Business_Description;
    allState.ownerShipType = preData.Ownership_Type;
    allState.businessPhoneNumber = preData.Business_Phone
      ? formatPhoneNumber(preData.Business_Phone)
      : "";
    allState.businessEmail = preData.Business_EMail;
    allState.businessWebsite = preData.Website;
    allState.businessTaxId = preData.Fedral_Tax_ID;
    allState.NPI = preData.NPI;
    allState.businessStock = preData.Stock_Symbol;
    allState.businessAddress = preData.Business_Address;
    allState.businessSuite = preData.Suite;
    allState.businessCity = preData.City;
    allState.businessState = preData.State_Code;
    allState.USID = preData.MCC;
    allState.businessPostalCode = preData.Postal_Code;
    allState.businessInterNational = preData.International_Province;
    allState.businessCountry = preData.Country_Code;
    allState.accountNumber = preData.Sattlement_Account?.Account_Number;
    allState.routingNumber = preData.Sattlement_Account?.Routing_Number;
    allState.accountName = preData.Sattlement_Account?.Name_On_Account;
    allState.accountType = preData.Sattlement_Account?.Account_Type;
    allState.cardPresent = preData.Processing_Method?.Card_Swiped;
    allState.cardNotPresent = preData.Processing_Method?.Card_Not_Present;
    allState.eCommers = preData.Processing_Method?.ECommerce;
    allState.cardVolumeMonth = preData.Processing_Method?.Monthly_Card_Volume;
    allState.averageTicketSize = preData.Processing_Method?.Average_Ticket_Size;
    allState.highTicket = preData.Processing_Method?.High_Ticket;
    allState.ussidTypeInput = "";
    allState.eCheckVolumeMonth = preData.Processing_Method?.Monthly_ACH_Volume;
    allState.eCheckAverage = preData.Processing_Method?.Average_ACH;
    allState.eCheckHigh = preData.Processing_Method?.High_ACH;
    allState.incorportionDocumentDetails =
      preData.incorportionDocument === ""
        ? {
            File_Name: "",
            File_Type: "",
            File_Path: "",
            Upload_Date_Time: ""
          }
        : preData.incorportionDocument;
    allState.docAdd = preData.incorportionDocument !== "";
    allState.incorportionDocument = preData.incorportionDocument
      ? preData.incorportionDocument.File_Name
      : "";
    const ownerData = preData.Owner_Information;
    const preDataArray = [];
    const validationAddToOwner = { ...validationError };
    ownerData.forEach((key, i) => {
      if (i > 0) {
        validationAddToOwner.ownerArray.owners.push({
          Owner_First_Name: false,
          Owner_Last_Name: false,
          Owner_Last4_SSN: false,
          Owner_DOB: false,
          Owner_Ownership_Percentage: false,

          Owner_Phone: false,
          Owner_Street_Address: false,
          Owner_Suite: false,
          Owner_City: false,
          Owner_State_Code: false,
          Owner_Postal_Code: false,
          Owner_International_Province: false,
          Owner_Country_Code: false,
          Owner_Timezone_Code: false,
          ownerAddressProof: false,
          ownerPhotoId: false,
          ownerAddressProofDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          },
          ownerPhotoIdDetails: {
            File_Name: false,
            File_Type: false,
            Upload_Date_Time: false
          }
        });
      }
      const obj = { ...key };
      obj.ownerAddressProofDetails =
        key.ownerAddressProof === ""
          ? {
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: ""
            }
          : {
              File_Type: key.ownerAddressProof.File_Type,
              File_Name: key.ownerAddressProof.File_Name,
              File_Path: key.ownerAddressProof.File_Path,
              Upload_Date_Time: key.ownerAddressProof.Upload_Date_Time
            };
      obj.ownerPhotoIdDetails =
        key.ownerPhotoId === ""
          ? {
              File_Name: "",
              File_Type: "",
              Upload_Date_Time: ""
            }
          : {
              File_Type: key.ownerPhotoId.File_Type,
              File_Name: key.ownerPhotoId.File_Name,
              File_Path: key.ownerPhotoId.File_Path,
              Upload_Date_Time: key.ownerPhotoId.Upload_Date_Time
            };
      obj.ownerAddressProof = key.ownerAddressProof.File_Path;
      obj.ownerPhotoId = key.ownerPhotoId.File_Path;
      obj.Owner_DOB = convertformat(key.Owner_DOB);
      preDataArray.push(obj);
    });
    allState.ownerArray.owners = preDataArray;
    setOwnerSelect(values.ownerArray.owners[0]);
    handleInput(allState);

    const providerName = {
      providerId:
        props.formData.providerDetailsById &&
        props.formData.providerDetailsById.Providers_Data[0]._id
    };
    props.statusPreameter(
      providerName,
      (data) => {
        data.status && setMpaCheckListStatus(data.data);
      },
      (err) => {
        enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
          variant: "error"
        });
      }
    );
    // props.statusPreameterKyc(
    //   {
    //     ...providerName,
    //     ownerId: ownerSelect.Owner_ID
    //       ? ownerSelect.Owner_ID
    //       : allState.ownerArray.owners[0].Owner_ID
    //   },
    //   (data) => {
    //     data.status && setKycCheckListStatus(data.data);
    //   },
    //   (err) => {
    //     enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
    //       variant: "error"
    //     });
    //   }
    // );
    setData(true);
  }

  const saveStatus = () => {
    setChangeStatusModal(false);
    const data = {
      id: props.formData.providerDetailsById.Providers_Data[0]._id,
      status: values.status
    };
    props.changeSingleStatus(data);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUploadOnChange = (files, type, ownerType, index) => {
    const allState = { ...values };
    if (type === "main") {
      if (files) {
        // let uploadDate = currentDate.getFullYear() + '-' + (currentDate.getMonth()+1) + '-' + currentDate.getDate() +' '+ currentDate.getHours()+':'+ currentDate.getMinutes()+':'+ currentDate.getSeconds();
        allState.incorportionDocumentDetails.File_Name = files.name;
        allState.incorportionDocumentDetails.File_Path = files.path;
        allState.incorportionDocumentDetails.File_Type =
          files.name.split(".")[files.name.split(".").length - 1];
        allState.incorportionDocumentDetails.Upload_Date_Time = `${new Date().getDate()}-${
          new Date().getMonth() + 1
        }-${new Date().getFullYear()} ${new Date().getHours()}:${new Date().getMinutes()}`;
        const reader = new FileReader();
        reader.readAsDataURL(files);
        if (files.size / 1024 / 1024 < 25) {
          reader.onload = function () {
            allState.incorportionDocument = reader.result;
            allState.docAdd = true;
            handleInput(allState);
          };
          reader.onerror = function (error) {};
        } else {
          enqueueSnackbar("Max file size can be upto 25 MB", {
            variant: "error"
          });
        }
      }
    }
    if (type === "owner") {
      if (files) {
        if (ownerType === "ownerAddressProof") {
          allState.ownerArray.owners[index].ownerAddressProofDetails.File_Name =
            files.name;
          allState.ownerArray.owners[index].ownerAddressProofDetails.File_Type =
            files.name.split(".")[files.name.split(".").length - 1];
          allState.ownerArray.owners[
            index
          ].ownerAddressProofDetails.Upload_Date_Time = `${new Date().getDate()}-${
            new Date().getMonth() + 1
          }-${new Date().getFullYear()} ${new Date().getHours()}:${new Date().getMinutes()}`;
        }
        if (ownerType === "ownerPhotoId") {
          allState.ownerArray.owners[index].ownerPhotoIdDetails.File_Name =
            files.name;
          allState.ownerArray.owners[index].ownerPhotoIdDetails.File_Type =
            files.name.split(".")[files.name.split(".").length - 1];
          allState.ownerArray.owners[
            index
          ].ownerPhotoIdDetails.Upload_Date_Time = `${new Date().getDate()}-${
            new Date().getMonth() + 1
          }-${new Date().getFullYear()} ${new Date().getHours()}:${new Date().getMinutes()}`;
        }
        const reader = new FileReader();
        reader.readAsDataURL(files);
        if (files.size / 1024 / 1024 < 25) {
          reader.onload = function () {
            allState.ownerArray.owners[index][ownerType] = reader.result;
            allState.docAdd = true;
            handleInput(allState);
          };
          reader.onerror = function (error) {};
        } else {
          enqueueSnackbar("Max file size can be upto 25 MB", {
            variant: "error"
          });
        }
      }
    }
  };

  const providerStatusbyId =
    props.formData.providerDetailsById &&
    props.formData.providerDetailsById.Providers_Data[0].Current_Status;

  const currentProviderStatus =
    providerStatusbyId &&
    props.formData.statusList &&
    props.formData.statusList.Provider_Status_Data.filter(
      (key) => key.Status_Code === providerStatusbyId
    ).length > 0 &&
    props.formData.statusList.Provider_Status_Data.filter(
      (key) => key.Status_Code === providerStatusbyId
    )[0].Provider_Status;

  const handleUploadAttechmentOnChange = (files) => {
    if (files) {
      // let uploadDate = currentDate.getFullYear() + '-' + (currentDate.getMonth()+1) + '-' + currentDate.getDate() +' '+ currentDate.getHours()+':'+ currentDate.getMinutes()+':'+ currentDate.getSeconds();
      const preFileValues = { ...fileHandleAttechment };
      const fileValues = {
        file: "",
        File_Name: "",
        File_Path: "",
        File_Type: "",
        Upload_Date_Time: ""
      };
      fileValues.File_Name = files.name;
      fileValues.File_Path = files.path;
      fileValues.File_Type =
        files.name.split(".")[files.name.split(".").length - 1];
      fileValues.Upload_Date_Time = `${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()} ${new Date().getHours()}:${new Date().getMinutes()}`;
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onload = function () {
        fileValues.file = reader.result;
        preFileValues.files.push(fileValues);
        setFileHandleAttechment(preFileValues);
        if (kycListModal) {
          const kycAttechmentData = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
            quesId: selectedQuestion._id,
            attachment: reader.result
          };
          props.kycChecklistAttechment(
            kycAttechmentData,
            (data) => {
              enqueueSnackbar(data.message, {
                variant: "success"
              });
            },
            (err) => {
              enqueueSnackbar(
                err.message ? err.message : "Failed to get Data",
                {
                  variant: "error"
                }
              );
            }
          );
        }
        if (checkListModal) {
          const kycAttechmentData = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
            quesId: selectedQuestion._id,
            attachment: reader.result
          };
          props.mpaChecklistAttechment(
            kycAttechmentData,
            (data) => {
              enqueueSnackbar(data.message, {
                variant: "success"
              });
            },
            (err) => {
              enqueueSnackbar(
                err.message ? err.message : "Failed to get Data",
                {
                  variant: "error"
                }
              );
            }
          );
        }
      };
      reader.onerror = function (error) {};
    }
  };

  const sendComment = () => {
    const requestData = {
      providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
      quesId: selectedQuestion._id,
      comment: textAreaText,
      checklistName: kycListModal ? "KYCChecklist" : "MPAChecklist"
    };
    props.mpaChecklistComment(
      requestData,
      (data) => {
        const dataQuestion = {
          providerId: props.formData.providerDetailsById.Providers_Data[0]._id
        };
        // props.getKycQuestionData({
        //   ...dataQuestion,
        //   ownerId: ownerSelect.Owner_ID
        // });
        enqueueSnackbar(data.message, {
          variant: "success"
        });
      },
      (err) => {
        enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
          variant: "error"
        });
      }
    );
  };

  const onFormPreviewSubmit = () => {
    handleClose();
    setValidationModal(false);
    if (checkValidation()) {
      if (props.formData.formMode === "Create") {
        const requestData = { ...values };
        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );

        const arrayNew = [];
        values.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
          arrayNew.push(obj);
        });
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = true;
        props.provideRegistration(requestData);
      }

      if (props.formData.formMode === "Edit") {
        const requestData = { ...values };
        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );
        const arrayNew = [];
        values.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          obj.Owner_Phone = key.Owner_Phone.replace(/-/g, "");
          arrayNew.push(obj);
        });
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = true;
        props.updateRegistration(
          requestData,
          props.formData.providerDetailsById.Providers_Data[0]._id
        );
      }
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
  };

  const activity = () => {
    const data = {
      providerId: props.formData.providerDetailsById.Providers_Data[0]._id
    };
    props.getProviderActivityById(data);
    props.history.push(`/${props.mainData.app.layout}/provideractivity`);
  };

  const onFormSubbmit = () => {
    handleClose();
    setValidationModal(false);
    if (checkValidation(true)) {
      if (props.formData.formMode === "Create") {
        const arrayNew = [];
        const requestData = { ...values };
        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );
        requestData.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          if (!obj.ownerAddressProof) {
            obj.ownerAddressProof = "";
          }
          if (!obj.ownerPhotoId) {
            obj.ownerPhotoId = "";
          }
          arrayNew.push(obj);
        });
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = false;
        props.provideRegistration(requestData);
      }
      if (props.formData.formMode === "Edit") {
        const arrayNew = [];
        const requestData = { ...values };
        requestData.businessPhoneNumber = values.businessPhoneNumber.replace(
          /-/g,
          ""
        );

        requestData.ownerArray.owners.forEach((key) => {
          const obj = { ...key };
          if (!obj.ownerAddressProof) {
            obj.ownerAddressProof = "";
          }
          if (!obj.ownerPhotoId) {
            obj.ownerPhotoId = "";
          }
          arrayNew.push(obj);
        });
        requestData.ownerArray = arrayNew;
        requestData.previewSubmit = false;
        props.updateRegistration(
          requestData,
          props.formData.providerDetailsById.Providers_Data[0]._id
        );
        if (requestData.incorportionDocument === "" && values.docAdd) {
          const data = {
            providerId:
              props.formData.providerDetailsById.Providers_Data[0]._id,
            document:
              props.formData.providerDetailsById.Providers_Data[0]
                .incorportionDocument
          };
          props.deleteIncroptration(data);
        }

        values.ownerArray.owners.forEach((key, i) => {
          if (key.ownerAddressProofDetails.File_Name === "" && values.docAdd) {
            const dataOwner = {
              providerId:
                props.formData.providerDetailsById.Providers_Data[0]._id,
              ownerId: key.Owner_ID,
              document:
                props.formData.providerDetailsById.Providers_Data[0]
                  .Owner_Information[i].ownerAddressProof
            };
            props.deleteAddressProof(dataOwner);
          }
          if (key.ownerPhotoIdDetails.File_Name === "" && values.docAdd) {
            const dataOwnerId = {
              providerId:
                props.formData.providerDetailsById.Providers_Data[0]._id,
              ownerId: key.Owner_ID,
              document:
                props.formData.providerDetailsById.Providers_Data[0]
                  .Owner_Information[i].ownerPhotoId
            };
            props.deleteIdentityProof(dataOwnerId);
          }
        });
      }
      setValidationModal(false);
    } else {
      setValidationModal(true);
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handlelistBack = () => {
    getProviderList();
    props.history.push(`/${props.mainData.app.layout}/Providers`);
  };

  const handleErrorMessage = () => {
    setValidationModal(false);
    setErrorMessage(false);
  };

  const handleQuestionStatus = (item, value, type, i) => {
    if (type === "MPA_Checklist") {
      const data = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        quesId: item._id,
        userId: localStorage.getItem("userId"),
        option: value,
        comment: "Testing option comment",
        checklistName: "MPAChecklist"
      };
      const providerName = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id
      };
      props.statusPreameter(
        providerName,
        (data) => {
          // eslint-disable-next-line no-unused-expressions
          data.status && setMpaCheckListStatus(data.data);
        },
        (err) => {
          enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
            variant: "error"
          });
        }
      );
      props.saveQuestionCall(data);
    }
    if (type === "KYC_Checklist") {
      const data = {
        OwnerId: ownerSelect.Owner_ID
          ? ownerSelect.Owner_ID
          : values.ownerArray.owners[0].Owner_ID,
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id,
        quesId: item._id,
        userId: localStorage.getItem("userId"),
        option: value,
        comment: "Testing option comment",
        checklistName: "KYCChecklist"
      };
      const providerName = {
        providerId: props.formData.providerDetailsById.Providers_Data[0]._id
      };
      // props.statusPreameterKyc(
      //   {
      //     ...providerName,
      //     ownerId: ownerSelect.Owner_ID
      //       ? ownerSelect.Owner_ID
      //       : values.ownerArray.owners[0].Owner_ID
      //   },
      //   (data) => {
      //     // eslint-disable-next-line no-unused-expressions
      //     data.status && setKycCheckListStatus(data.data);
      //   },
      //   (err) => {
      //     enqueueSnackbar(err.message ? err.message : "Failed to get Data", {
      //       variant: "error"
      //     });
      //   }
      // );
      props.saveQuestionCall(data);
    }
  };

  const handleDeleteUpload = (type, index, docType) => {
    const allState = { ...values };
    if (type === "main") {
      allState.incorportionDocument = "";
      allState.incorportionDocumentDetails = {
        File_Name: "",
        File_Type: "",
        File_Path: "",
        Upload_Date_Time: ""
      };
      allState.docAdd = true;
    }
    if (type === "owner") {
      allState.ownerArray.owners[index][docType] = "";
      allState.ownerArray.owners[index][`${docType}Details`] = {
        File_Name: "",
        File_Type: "",
        Upload_Date_Time: ""
      };
      allState.docAdd = true;
    }
    handleInput(allState);
  };
  let sumValue = 0;
  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    error[type] = false;
    allState[type] = value;
    sumValue =
      parseInt(allState.cardPresent) +
      parseInt(allState.cardNotPresent) +
      parseInt(allState.eCommers);
    if (sumValue > 100) {
      setSumError(true);
    } else {
      setSumError(false);
    }
    if (type === "businessCountry") {
      if (value === "US") {
        setStateInput(true);
        allState.businessState = "";
        error[type] = false;
        allState[type] = value;
      } else {
        setStateInput(false);
        allState.businessState = "";
        error[type] = false;
        allState[type] = value;
      }
    }
    // if (type === "businessStartDate") {
    //   const dateOne = new Date(value); // Year, Month, Date
    //   const dateTwo = new Date();
    //   if (dateOne > dateTwo) {
    //     error[type] = true;
    //     error.businessStartDateMessage = true;
    //   }
    //   if (dateOne <= dateTwo) {
    //     error[type] = false;
    //     error.businessStartDateMessage = false;
    //   }
    // }
    if (type === "businessWebsite") {
      const res = value.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      );
      if (res === null) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessPhoneNumber") {
      if (value.length != 12) {
        error[type] = true;
        allState[type] = formatPhoneNumber(value);
      } else {
        error[type] = false;
        allState[type] = formatPhoneNumber(value);
      }
    }
    if (type === "accountNumber") {
      if (!/^[0-9]{5,17}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessSuite") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{2,50}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessAddress") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{2,50}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessName") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{3,50}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "legalBusiness") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{3,50}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessCity") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{2,50}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (
      type === "cardPresent" ||
      type === "cardNotPresent" ||
      type === "eCommers"
    ) {
      if (!/\b(1[1-9]|[1-9][0-9]|100)\b/.test(value) || sumValue > 100) {
        error[type] = true;
        allState[type] = value;
      } else {
        if (sumValue < 100 || sumValue == 100) {
          error["cardPresent"] = false;
          error["cardNotPresent"] = false;
          error["eCommers"] = false;
        }
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessTaxId") {
      if (!/^[0-9]{9}$/.test(value)) {
        if (value.length !== 9) {
          error[type] = true;
          allState[type] = value;
        }
        if (value.length === 9) {
          error[type] = false;
          allState[type] = value;
        }
      }
    }
    if (type === "routingNumber") {
      if (!/^[0-9]{9}$/.test(value)) {
        if (value.length !== 9) {
          error[type] = true;
          allState[type] = value;
        }
        if (value.length === 9) {
          error[type] = false;
          allState[type] = value;
        }
      }
    }
    if (type === "NPI") {
      if (!/^[0-9]{10}$/.test(value)) {
        if (value.length !== 10) {
          error[type] = true;
          allState[type] = value;
        }
        if (value.length === 10) {
          error[type] = false;
          allState[type] = value;
        }
      }
    }
    if (type === "businessPostalCode") {
      if (!/^[0-9]{5}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (type === "businessEmail") {
      const res = value.match(
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
      );
      if (res === null) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    }
    if (value === "") {
      error[type] = false;
    }
    handleInput(allState);
    setVlidationError(error);
  };

  const handleProviderParameter = () => {
    const data = {
      providerId: props.formData.providerDetailsById.Providers_Data[0]._id
    };
    props.getProviderParameterCall(data);
    props.history.push(`/${props.mainData.app.layout}/providerParameter`);
  };

  function convertUTCDateToLocalDate(date) {
    return [
      String(101 + date.getMonth()).substring(1),
      String(100 + date.getDate()).substring(1),
      String(date.getFullYear())
    ].join("/");
  }

  function pathCheck() {
    const pdfPath = `${zollPdf}`;
    return pdfPath;
  }
  const userType = localStorage.getItem("userType");
  const profileType = localStorage.getItem("profileType");

  const disabled =
    props.formData.formMode === "Edit" &&
    checkFormDisabled(
      props,
      values,
      props.formData.statusList &&
        props.formData.statusList.Provider_Status_Data
    );

  return (
    <>
      <ThemeProvider theme={themeFont}>
        <Modal open={validationModal} onClose={() => setValidationModal(false)}>
          <div className={classes.modalDiv}>
            <Card
              style={{
                width: "50%",
                maxWidth: "40%",
                height: "auto",
                alignItems: "center",
                flexDirection: "column",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  minInlineSize: "-webkit-fill-available",
                  textAlign: "start",
                  height: "auto",
                  maxHeight: "70vh",
                  display: "flex",
                  overflow: "auto",
                  flexWrap: "nowrap",
                  marginTop: "10%",
                  alignItems: "center",
                  flexDirection: "column"
                }}
              >
                <img
                  src={closeImage}
                  style={{ width: "10%", marginBottom: "3%" }}
                />
                <div className={classes.successText}>
                  <Typography
                    style={{ textAlign: "center" }}
                    color="error"
                    variant="subtitle2"
                    gutterBottom
                  >
                    Fields marked with an asterisk (*) are mandatory
                  </Typography>
                </div>
                {/* <div className={classes.successText}>-Email ID should be unique</div> */}
                {errorMessage && (
                  <div
                    style={{ marginTop: "2%" }}
                    className={classes.successText}
                  >
                    {errorMessage}
                    {errorMessage &&
                      errorMessage.map((key) => (
                        <div className={classes.successText}>-{key.msg} </div>
                      ))}
                  </div>
                )}
                <div
                  style={{ marginTop: "2%" }}
                  className={classes.successText}
                ></div>
                <br />
              </div>

              <CardActions style={{ margin: "5%" }}>
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  size="sm"
                  onClick={() => handleErrorMessage()}
                >
                  Ok
                </Button>
              </CardActions>
            </Card>
          </div>
        </Modal>
      </ThemeProvider>

      <Card
        style={{ borderTopLeftRadius: "16px", borderTopRightRadius: "16px" }}
      >
        <Box
          style={{
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
            alignItems: "center",
            padding: "10px"
          }}
          className={classes.tittleColor}
          height={40}
        >
          <Grid
            style={{
              display: "flex",
              position: "relative",
              flexWrap: "nowrap",
              boxSizing: "border-box",
              flexDirection: "row",
              alignItems: "center"
            }}
            container
          >
            <Grid style={{ position: "absolute" }} item>
              <IconButton
                color="white"
                aria-label="Back"
                onClick={() => handleBack()}
                component="span"
                style={{ background: "white", padding: "6px" }}
              >
                <KeyboardBackspaceIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid style={{ margin: "auto" }} item>
              <Typography
                style={{ margin: "auto" }}
                className={classes.tittleTextColor}
              >
                Business Information
              </Typography>
            </Grid>
            {createPermissionFlag && userType === "Internal" && (
              <Grid style={{ display: "flex" }} item>
                <IconButton
                  color="white"
                  aria-label="Back"
                  onClick={() => handleAddNew()}
                  component="span"
                  style={{
                    background: "white",
                    padding: "6px",
                    margin: "10px"
                  }}
                >
                  <AddIcon fontSize="small" />
                </IconButton>
                <Typography
                  style={{ margin: "auto" }}
                  className={classes.tittleTextColor}
                >
                  Add New
                </Typography>
              </Grid>
            )}
          </Grid>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignContent: "center",
            justifyContent: "center"
            // padding: "4%",
          }}
        >
          <FormControl style={{ marginTop: "20px" }}>
            {/* {props.formData.formMode !== "Edit" && (
              <Grid container spacing={2} className={classes.statusContainer}>
                <Grid item lg={12} sm={12} xs={12}>
                  <Button
                    variant="contained"
                    className={classes.clearButton}
                    onClick={() => handleBack()}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item xs={10} />
              </Grid>
            )} */}
            {props.formData.formMode === "Edit" && (
              <Grid
                container
                style={{ padding: "0px 25px" }}
                className={classes.statusContainer}
                justify="space-between"
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center"
                  }}
                >
                  <div style={{ fontWeight: "bold", color: "#fcba03" }}>
                    {`Status: ${currentProviderStatus}`}
                  </div>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "20px",
                    justifyContent: "end"
                  }}
                >
                  <IconsTooltip title="Activity History" placement="top">
                    <div className={classes.appSvgIcons}>
                      <ActivityIcon onClick={() => activity()} />
                    </div>
                  </IconsTooltip>
                  {PROVIDER_STATUS_CODE.some(
                    (x) => x === ProviderStatusCode.toString()
                  ) && (
                    <IconsTooltip title="Configuration" placement="top">
                      <div className={classes.appSvgIcons}>
                        <ConfigurationIcon
                          onClick={() => handleProviderParameter()}
                        />
                      </div>
                    </IconsTooltip>
                  )}
                  {props.formData.statusList &&
                    editPermissionFlag &&
                    changeStatusPermissionFlag &&
                    props.formData.statusList &&
                    props.formData.statusList.Provider_Status_Data.length >
                      1 && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        <TextField
                          className={classes.root}
                          variant="outlined"
                          size="small"
                          disabled={
                            userOptions ? !userOptions.permission : true
                          }
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused"
                            }
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused"
                            }
                          }}
                          label="Status"
                          value={values.status}
                          onChange={(e) => onChange(e.target.value, "status")}
                          name="status"
                          style={{
                            textAlign: "initial",
                            minWidth: "fit-content"
                          }}
                          id="status"
                          select
                        >
                          {props.formData.statusList &&
                            props.formData.statusList.Provider_Status_Data.map(
                              (key) => {
                                if (
                                  (key.Status_Code === 4 &&
                                    !livePermissionFlag) ||
                                  (key.Status_Code === 2 && !allowToBoardFlag)
                                ) {
                                  return;
                                } else
                                  return (
                                    <MenuItem
                                      className={classes.dialogBoxDropDown}
                                      value={key.Status_Code}
                                    >
                                      {key.Provider_Status}
                                    </MenuItem>
                                  );
                              }
                            )}
                        </TextField>
                        <Button
                          variant="contained"
                          className={classes.buttonStyle}
                          disabled={
                            userOptions ? !userOptions.permission : true
                          }
                          color="#696969"
                          onClick={handlePopupModel}
                          style={{
                            minWidth: "fit-content",
                            marginLeft: "15px",
                            textTransform: "capitalize"
                          }}
                        >
                          Change Status
                        </Button>
                      </div>
                    )}
                </div>
              </Grid>
            )}
            <Box className={classes.marginBox}>
              <Grid container className={classes.margin}>
                <Grid item lg={4} sm={6} xs={12}>
                  <PersonIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    disabled={disabled}
                    required
                    error={validationError.businessName}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="Business Name"
                    value={values.businessName}
                    helperText={
                      validationError.businessName &&
                      "Business Name is required."
                    }
                    onChange={(e) => {
                      onChange(e.target.value, "businessName");
                    }}
                    name="businessName"
                    id="businessName"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <BusinessCenterIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    required
                    error={validationError.legalBusiness}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        focused: "focused",
                        input: classes.resize
                      },
                      required: true
                    }}
                    label="Legal Business Name"
                    value={values.legalBusiness}
                    helperText={
                      validationError.legalBusiness &&
                      "Legal Name of Business is required."
                    }
                    onChange={(e) => {
                      // (/^[A-Za-z0-9\d\.\-\/\&\,\ ]*$/g.test(e.target.value) ||
                      //   e.target.value == "") &&
                      onChange(e.target.value, "legalBusiness");
                    }}
                    name="legalBusiness"
                    id="legalBusiness"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <EventNoteIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <ThemeProvider theme={themeFont}>
                    <FormControl className={classes.root}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          clearable
                          disabled={disabled}
                          required
                          onChange={(e) => {
                            if (e === null) {
                              onChange(e, "businessStartDate");
                            } else {
                              onChange(
                                convertUTCDateToLocalDate(e),
                                "businessStartDate"
                              );
                            }
                          }}
                          value={
                            values.businessStartDate
                              ? values.businessStartDate
                              : null
                          }
                          label="Business Start Date"
                          id="businessStartDate"
                          name="businessStartDate"
                          placeholder="MM/DD/YYYY"
                          maxDate={new Date()}
                          error={
                            validationError.businessStartDate ||
                            validationError.businessStartDateMessage
                          }
                          maxDateMessage="Business Start Date should not be a Future date."
                          format="MM/dd/yyyy"
                          keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                          KeyboardButtonProps={{
                            size: "small"
                          }}
                          InputProps={{
                            readOnly: true,
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused"
                            },
                            required: true,
                            endAdornment: (
                              <Tooltip title="cds_DateHelp" placement="top">
                                <IconButton edge="end" size="small">
                                  <HelpOutlineOutlinedIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )
                          }}
                          helperText={
                            (validationError.businessStartDate &&
                              "Business Start Date is required.") ||
                            (validationError.businessStartDateMessage &&
                              "Future date is not allowed")
                          }
                        />
                      </MuiPickersUtilsProvider>
                    </FormControl>
                  </ThemeProvider>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <DescriptionIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    required
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="NPI"
                    value={values.NPI}
                    onChange={(e) => {
                      (/^[0-9]\d*$/g.test(e.target.value) ||
                        e.target.value == "") &&
                        onChange(e.target.value, "NPI");
                    }}
                    name="NPI"
                    id="NPI"
                    error={validationError.NPI}
                    helperText={
                      validationError.NPI && "10 Digit NPI is required."
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={4} sm={6} style={{ display: "flex" }}>
                  <AccountBalanceIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <ThemeProvider theme={themeFont}>
                    <Autocomplete
                      name="USID"
                      id="USID"
                      label="MCC"
                      disabled={disabled}
                      value={
                        (mccList || []).find(
                          (option) => option.MCC_Code === parseInt(values.USID)
                        ) || {}
                      }
                      autoHighlight
                      fullWidth
                      onChange={(_, value, action) => {
                        if (action === "clear") {
                          onChange("", "USID");
                        } else {
                          value && onChange(value.MCC_Code, "USID");
                        }
                      }}
                      style={{
                        fontSize: "14px",
                        fontFamily: "Source San Pro"
                      }}
                      options={mccList || []}
                      getOptionLabel={(option) => option.MCC_Name || ""}
                      renderInput={(params) => (
                        <TextField
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused"
                            }
                          }}
                          required
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused"
                            }
                          }}
                          style={{
                            fontSize: "14px",
                            fontFamily: "Source San Pro"
                          }}
                          {...params}
                          label="MCC"
                          error={validationError.USID}
                          helperText={
                            validationError.USID && "Business MCC is required."
                          }
                        />
                      )}
                      className={classes.root}
                      renderOption={(option, { inputValue }) => {
                        const matches = match(option.MCC_Name, inputValue, {
                          insideWords: true
                        });
                        const parts = parse(option.MCC_Name, matches);

                        return (
                          <div>
                            <ThemeProvider theme={themeFont}>
                              {parts.map((part, index) => (
                                <span
                                  key={index}
                                  style={{
                                    background: part.highlight
                                      ? "#f3ff0070"
                                      : "",
                                    fontWeight: part.highlight ? 400 : "",
                                    // fontSize: "14px",
                                    fontFamily: "Source San Pro"
                                  }}
                                >
                                  {part.text}
                                </span>
                              ))}
                            </ThemeProvider>
                          </div>
                        );
                      }}
                    />
                  </ThemeProvider>
                </Grid>
                {values.USID === "option" && (
                  <Grid item xs={12} lg={4} sm={6}>
                    <AccountBalanceIcon
                      className={classes.inputBox}
                      fontSize="small"
                    />
                    <TextField
                      disabled={
                        !(
                          (kycCheckListFlag ||
                            ProviderStatus === "New Entry") &&
                          ProviderStatus !== "Live" &&
                          (editPermissionFlag || createPermissionFlag)
                        )
                      }
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Enter"
                      value={values.ussidTypeInput}
                      onChange={(e) =>
                        onChange(e.target.value, "ussidTypeInput")
                      }
                      name="USID"
                      id="USID"
                    />
                  </Grid>
                )}
                <Grid item xs={12} lg={4} sm={6} style={{ display: "flex" }}>
                  <BusinessCenterIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Business Description"
                    value={values.businessDiscrition}
                    onChange={(e) =>
                      onChange(e.target.value, "businessDiscrition")
                    }
                    name="businessDiscrition"
                    id="businessDiscrition"
                  />
                </Grid>

                <Grid item lg={4} sm={6} xs={12}>
                  <PriorityHighIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    select
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    helperText={
                      validationError.ownerShipType &&
                      "Business Ownership Type is required."
                    }
                    required
                    label="Ownership Type"
                    error={validationError.ownerShipType}
                    value={values.ownerShipType}
                    onChange={(e) => onChange(e.target.value, "ownerShipType")}
                    name="ownerShipType"
                    id="ownerShipType"
                  >
                    {props.formData.ownerShipTypeList &&
                      props.formData.ownerShipTypeList.Ownership_Type_Data.sort(
                        (a, b) => a.Type_Name.localeCompare(b.Type_Name)
                      ).map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key.Type_Name}
                        >
                          {key.Type_Name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <StayCurrentPortraitIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    required
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      // inputProps: { min: 0, max: 12 },
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Business Phone Number"
                    value={formatPhoneNumber(values.businessPhoneNumber)}
                    onChange={(e) => {
                      onChange(
                        formatPhoneNumber(e.target.value),
                        "businessPhoneNumber"
                      );
                    }}
                    name="businessPhoneNumber"
                    id="businessPhoneNumber"
                    error={validationError.businessPhoneNumber}
                    helperText={
                      validationError.businessPhoneNumber &&
                      "Enter a Valid Phone Number with 10 Digits Only"
                    }
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <StayCurrentPortraitIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    required
                    error={validationError.businessEmail}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      },
                      required: true,
                      readOnly:
                        values.businessEmail && !disabledValue ? true : false,
                      className:
                        values.businessEmail && !disabledValue
                          ? "Mui-disabled"
                          : ""
                    }}
                    label="Email Address"
                    value={values.businessEmail}
                    onChange={(e) => {
                      setDisabledValue(true);
                      onChange(e.target.value, "businessEmail");
                    }}
                    name="businessEmail"
                    id="businessEmail"
                    helperText={
                      validationError.businessEmail &&
                      "Enter a Valid Email Address"
                    }
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <PublicIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    error={validationError.businessWebsite}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Website"
                    value={values.businessWebsite}
                    onChange={(e) =>
                      onChange(e.target.value, "businessWebsite")
                    }
                    name="businessWebsite"
                    id="businessWebsite"
                    helperText={
                      validationError.businessWebsite &&
                      "please enter a valid web url"
                    }
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <DescriptionIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    error={validationError.businessTaxId}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Federal Tax ID"
                    required
                    value={values.businessTaxId}
                    inputProps={{ maxLength: 9, minLength: 9 }}
                    onChange={(e) => {
                      (/^[0-9]\d*$/g.test(e.target.value) ||
                        e.target.value == "") &&
                        onChange(e.target.value, "businessTaxId");
                    }}
                    name="businessTaxId"
                    id="businessTaxId"
                    helperText={
                      validationError.businessTaxId &&
                      "9 Digit Federal Tax ID is required."
                    }
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <StorageRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Stock Symbol"
                    value={values.businessStock}
                    onChange={(e) => onChange(e.target.value, "businessStock")}
                    name="businessStock"
                    id="businessStock"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <HomeRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    required
                    label="Business Address (No P.O. Box)"
                    value={values.businessAddress}
                    onChange={(e) =>
                      onChange(e.target.value, "businessAddress")
                    }
                    error={validationError.businessAddress}
                    helperText={
                      validationError.businessAddress &&
                      "min 2 max 50 characters Business Address is required."
                    }
                    name="businessAddress"
                    id="businessAddress"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <MoreHorizRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    error={validationError.businessSuite}
                    helperText={
                      validationError.businessSuite &&
                      "min 2 max 50 characters Business Suite is required."
                    }
                    label="Suite"
                    required
                    value={values.businessSuite}
                    onChange={(e) => onChange(e.target.value, "businessSuite")}
                    name="businessSuite"
                    id="businessSuite"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <AddIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    required
                    label="Country"
                    value={values.businessCountry}
                    onChange={(e) =>
                      onChange(e.target.value, "businessCountry")
                    }
                    name="businessCountry"
                    id="businessCountry"
                    select
                  >
                    {props.formData.countryTypeList &&
                      props.formData.countryTypeList.Country_Data.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key.Country_Code}
                        >
                          {key.Country_Name}
                        </MenuItem>
                      ))}
                  </TextField>
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <NearMeRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  {values.businessCountry === "US" ? (
                    <TextField
                      disabled={disabled}
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      error={validationError.businessState}
                      helperText={
                        validationError.businessState &&
                        "Business State is required."
                      }
                      label="State"
                      required
                      value={values.businessState}
                      onChange={(e) =>
                        onChange(e.target.value, "businessState")
                      }
                      name="businessState"
                      id="businessState"
                      select={stateInput}
                    >
                      {stateInput &&
                        locationCity.map((key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key.value}
                          >
                            {key.name}
                          </MenuItem>
                        ))}
                    </TextField>
                  ) : (
                    <TextField
                      disabled={
                        !(
                          (kycCheckListFlag ||
                            ProviderStatus === "New Entry") &&
                          ProviderStatus !== "Live" &&
                          (editPermissionFlag || createPermissionFlag)
                        )
                      }
                      className={classes.root}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="State"
                      required
                      value={values.businessState}
                      onChange={(e) =>
                        onChange(e.target.value, "businessState")
                      }
                      name="businessState"
                      id="businessState"
                    />
                  )}
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <LocationCityRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="City"
                    required
                    value={values.businessCity}
                    error={validationError.businessCity}
                    onChange={(e) => onChange(e.target.value, "businessCity")}
                    helperText={
                      validationError.businessCity &&
                      "min 2 max 50 characters Business City is required."
                    }
                    name="businessCity"
                    id="businessCity"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <StarIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="International Province"
                    value={values.businessInterNational}
                    onChange={(e) =>
                      onChange(e.target.value, "businessInterNational")
                    }
                    name="businessInterNational"
                    id="businessInterNational"
                  />
                </Grid>

                <Grid item lg={4} sm={6} xs={12}>
                  <RoomIcon className={classes.inputBox} fontSize="small" />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      inputProps: { min: 0, max: 7 },
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      },
                      required: true
                    }}
                    label="Postal Code"
                    required
                    value={values.businessPostalCode}
                    error={validationError.businessPostalCode}
                    onChange={(e) =>
                      (/^[0-9]\d*$/g.test(e.target.value) ||
                        e.target.value == "") &&
                      onChange(e.target.value, "businessPostalCode")
                    }
                    name="businessPostalCode"
                    id="businessPostalCode"
                    helperText={
                      validationError.businessPostalCode &&
                      "Enter a valid postal code of 5 digits."
                    }
                  />
                </Grid>
              </Grid>
              {values.incorportionDocumentDetails.File_Name === "" ? (
                (kycCheckListFlag || ProviderStatus === "New Entry") &&
                ProviderStatus !== "Live" &&
                (editPermissionFlag || createPermissionFlag) && (
                  <Grid
                    container
                    style={{ flexDirection: windowWidth ? "column" : "row" }}
                    className={
                      !validationError.incorportionDocument
                        ? classes.uploadDocumentBox
                        : classes.uploadDocumentBox
                    }
                  >
                    <Grid item xs={8} style={{ margin: "10px" }}>
                      <Typography
                        style={{ marginLeft: "10px" }}
                        variant="subtitle1"
                      >
                        Incorporation documents * (required) (If you are a
                        business, you must provide incorporation documents in
                        order to get verified) Maximum file size: 25mb (PDF,
                        JPG, JPEG & PNG accepted)
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                      }}
                    >
                      <label htmlFor="upload-incropraton">
                        <input
                          style={{ display: "none" }}
                          id="upload-incropraton"
                          name="upload-incropraton"
                          type="file"
                          accept="image/*, application/pdf"
                          display="none"
                          onChange={(e) =>
                            handleUploadOnChange(e.target.files[0], "main")
                          }
                          disabled={disabled}
                        />

                        <Button
                          className={
                            !validationError.incorportionDocument
                              ? classes.buttonStyle
                              : classes.buttonStyleError
                          }
                          variant="contained"
                          component="span"
                          style={{
                            fontSize: "small"
                          }}
                          disabled={disabled}
                        >
                          Upload Incorporation Document
                        </Button>
                      </label>
                    </Grid>
                  </Grid>
                )
              ) : (
                <Table className={classes.table} aria-label="simple table">
                  <TableHead className={classes.tableHeader}>
                    <TableRow>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "5%" }}
                        align="center"
                      >
                        Document Type
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "5%" }}
                        align="center"
                      >
                        Name
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "10%" }}
                        align="center"
                      >
                        File Type
                      </TableCell>
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "10%" }}
                        align="center"
                      >
                        Upload Time (UTC)
                      </TableCell>
                      {(ProviderStatus === "New Entry" ||
                        (kycCheckListFlag &&
                          editPermissionFlag &&
                          ProviderStatus !== "Live")) && (
                        <TableCell
                          className={classes.cellTextHeaderOffer}
                          style={{ width: "5%" }}
                          align="center"
                        >
                          Action
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">Incorporation</TableCell>
                      <TableCell align="center">
                        <a
                          href={values.incorportionDocumentDetails.File_Path}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {" "}
                          {values.incorportionDocumentDetails.File_Name}
                        </a>
                      </TableCell>
                      <TableCell align="center">
                        {values.incorportionDocumentDetails.File_Type}
                      </TableCell>
                      <TableCell align="center">
                        {moment(
                          values.incorportionDocumentDetails.Upload_Date_Time
                        ).format("MM/DD/YYYY hh:mm:ss A")}
                      </TableCell>
                      {(ProviderStatus === "New Entry" ||
                        (kycCheckListFlag &&
                          editPermissionFlag &&
                          ProviderStatus !== "Live")) && (
                        <TableCell align="center">
                          <IconButton
                            onClick={() => handleDeleteUpload("main")}
                            color="secondary"
                            disabled={disabled}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Box>
            <Box
              style={{
                alignItems: "center",
                marginTop: "30px",
                marginBottom: "30px"
              }}
              className={classes.tittleColor}
              height={40}
            >
              <Typography className={classes.tittleTextColor}>
                Settlement Account Information
              </Typography>
            </Box>
            <Box className={classes.marginBox}>
              <Grid container className={classes.margin}>
                <Grid item lg={3} sm={3} xs={12}>
                  <AccountBalanceIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.quaterInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Account Number"
                    required
                    value={values.accountNumber}
                    error={validationError.accountNumber}
                    helperText={
                      validationError.accountNumber &&
                      "Max 17 Digit Account Number is required."
                    }
                    onChange={(e) =>
                      (/^[0-9]\d*$/g.test(e.target.value) ||
                        e.target.value == "") &&
                      onChange(e.target.value, "accountNumber")
                    }
                    name="accountNumber"
                    id="accountNumber"
                    type="text"
                  />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                  <AccountBalanceIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.quaterInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      inputProps: { min: 0 },
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Routing Number"
                    required
                    error={validationError.routingNumber}
                    value={values.routingNumber}
                    onChange={(e) =>
                      (/^[0-9]\d*$/g.test(e.target.value) ||
                        e.target.value == "") &&
                      onChange(e.target.value, "routingNumber")
                    }
                    helperText={
                      validationError.routingNumber &&
                      "(9 Digit Routing Number is Required."
                    }
                    name="routingNumber"
                    id="routingNumber"
                    type="text"
                  />
                </Grid>

                <Grid item lg={3} sm={3} xs={12}>
                  <AccountBalanceIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.quaterInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Name on Account"
                    required
                    value={values.legalBusiness}
                    onChange={(e) => onChange(e.target.value, "accountName")}
                    name="accountName"
                    id="accountName"
                  />
                </Grid>
                <Grid item lg={3} sm={3} xs={12}>
                  <AccountBalanceIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.quaterInput}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Account Type"
                    required
                    value={values.accountType}
                    onChange={(e) => onChange(e.target.value, "accountType")}
                    name="accountType"
                    id="accountType"
                    select
                    error={validationError.accountType}
                    helperText={
                      validationError.accountType && "Account Name is required."
                    }
                  >
                    {props.formData.accountTypeList &&
                      props.formData.accountTypeList.Account_Type_Data.map(
                        (key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key.Account_Type_Name}
                          >
                            {key.Account_Type_Name}
                          </MenuItem>
                        )
                      )}
                  </TextField>
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                alignItems: "center",
                marginTop: "30px",
                marginBottom: "30px"
              }}
              className={classes.tittleColor}
              height={40}
            >
              <Typography className={classes.tittleTextColor}>
                Processing Method and Volume
              </Typography>
            </Box>
            <Box className={classes.marginBox}>
              <Grid container className={classes.margin}>
                <Grid item lg={4} sm={6} xs={12}>
                  <StrikethroughSIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    error={validationError.cardPresent}
                    helperText={
                      validationError.cardPresent
                        ? sumError
                          ? "total value of Card Present/Swiped, Card Not Present/Keyed and E-Commerce should be <= 100"
                          : "Card Present value between 10 to 100 is required."
                        : ""
                    }
                    label="Card Present/Swiped"
                    required
                    value={values.cardPresent}
                    onChange={(e) => onChange(e.target.value, "cardPresent")}
                    name="cardPresent"
                    id="cardPresent"
                    type="number"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <StrikethroughSIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Card Not Present/Keyed"
                    required
                    value={values.cardNotPresent}
                    onChange={(e) => onChange(e.target.value, "cardNotPresent")}
                    error={validationError.cardNotPresent}
                    helperText={
                      validationError.cardNotPresent
                        ? sumError
                          ? "total value of Card Present/Swiped, Card Not Present/Keyed and E-Commerce should be <= 100"
                          : "Card Not Present value between 10 to 100 is required."
                        : ""
                    }
                    name="cardNotPresent"
                    id="cardNotPresent"
                    type="number"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <StrikethroughSIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="E-Commerce"
                    required
                    value={values.eCommers}
                    error={validationError.eCommers}
                    helperText={
                      validationError.eCommers
                        ? sumError
                          ? "total value of Card Present/Swiped, Card Not Present/Keyed and E-Commerce should be <= 100"
                          : "E-Commerce value between 10 to 100 is required."
                        : ""
                    }
                    onChange={(e) => onChange(e.target.value, "eCommers")}
                    name="eCommers"
                    id="eCommers"
                    type="number"
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.margin}>
                <Grid item lg={4} sm={6} xs={12}>
                  <AttachMoneyRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Monthly Card Volume"
                    value={values.cardVolumeMonth}
                    onChange={(e) =>
                      onChange(e.target.value, "cardVolumeMonth")
                    }
                    name="cardVolumeMonth"
                    id="cardVolumeMonth"
                    type="number"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <AttachMoneyRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Average Ticket Size"
                    value={values.averageTicketSize}
                    onChange={(e) =>
                      onChange(e.target.value, "averageTicketSize")
                    }
                    name="averageTicketSize"
                    id="averageTicketSize"
                    type="number"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <AttachMoneyRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="High Ticket"
                    value={values.highTicket}
                    onChange={(e) => onChange(e.target.value, "highTicket")}
                    name="highTicket"
                    id="highTicket"
                    type="number"
                  />
                </Grid>
              </Grid>
              <Grid container className={classes.margin}>
                <Grid item lg={4} sm={6} xs={12}>
                  <AttachMoneyRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Monthly ACH/eCheck Volume"
                    value={values.eCheckVolumeMonth}
                    onChange={(e) =>
                      onChange(e.target.value, "eCheckVolumeMonth")
                    }
                    name="eCheckVolumeMonth"
                    id="eCheckVolumeMonth"
                    type="number"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <AttachMoneyRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Average ACH/eCheck"
                    value={values.eCheckAverage}
                    onChange={(e) => onChange(e.target.value, "eCheckAverage")}
                    name="eCheckAverage"
                    id="eCheckAverage"
                    type="number"
                  />
                </Grid>
                <Grid item lg={4} sm={6} xs={12}>
                  <AttachMoneyRoundedIcon
                    className={classes.inputBox}
                    fontSize="small"
                  />
                  <TextField
                    disabled={disabled}
                    className={classes.root}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="High ACH/eCheck"
                    value={values.eCheckHigh}
                    onChange={(e) => onChange(e.target.value, "eCheckHigh")}
                    name="eCheckHigh"
                    id="eCheckHigh"
                    type="number"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              style={{
                alignItems: "center",
                marginTop: "30px"
              }}
              className={classes.tittleColor}
              height={40}
            >
              <Typography className={classes.tittleTextColor}>
                Owner Information
              </Typography>
            </Box>
            {values.ownerArray.owners &&
              values.ownerArray.owners.map((key, i) => (
                <OwnerInformation
                  handleOwnerChange={(data, type, e) =>
                    ownerOnchange(i, data, type, e)
                  }
                  handleDeleteUpload={(docType) =>
                    handleDeleteUpload("owner", i, docType)
                  }
                  handleUploadOnChange={(data, type) =>
                    handleUploadOnChange(data, "owner", type, i)
                  }
                  index={i}
                  validationError={validationError.ownerArray.owners[i]}
                  data={key}
                  ProviderStatus={ProviderStatus}
                  kycCheckListFlag={kycCheckListFlag}
                  editPermissionFlag={editPermissionFlag}
                  createPermissionFlag={createPermissionFlag}
                  add={() => addOwner()}
                  remove={() => deleteOwner(i)}
                  disabled={disabled}
                />
              ))}
            <Box style={{ marginTop: "10px" }} height={20}>
              <Typography
                style={{
                  marginLeft: "10px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                {/* Terms and Conditions */}
                {validationError.checkedB ? (
                  <spen style={{ color: "red" }}>Terms and Conditions</spen>
                ) : (
                  "Terms and Conditions"
                )}
              </Typography>
            </Box>
            <Grid container className={classes.margin}>
              <Grid item xs={11}>
                <Box className={classes.tncBox}>
                  <Typography
                    style={{ marginTop: "10px", marginBottom: "20px" }}
                  >
                    <Checkbox
                      className={classes.checkBox}
                      checked={check}
                      disabled={ProviderStatus !== "New Entry"}
                      onChange={() => {
                        const errorAll = { ...validationError };
                        errorAll.checkedB = check;
                        setCheck(!check);
                        setVlidationError(errorAll);
                      }}
                      name="checkedB"
                      size="small"
                      style={{ marginRight: "5px" }}
                      color="primary"
                      value={values.checkedB}
                    />
                    {`By clicking on '${
                      props.formData.formMode === "Create" ||
                      ProviderStatus === "New Entry"
                        ? "Send For Underwriting"
                        : "Update"
                    }' below I certify that I have read and agree to be bound by the `}
                    {
                      <a
                        href={pathCheck()}
                        without
                        style={{ color: "#00aab4" }}
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        Pay Theory Terms and Conditions
                      </a>
                    }{" "}
                    {`accessible via the hyperlink herein incorporated
              by reference, and that I am duly authorized to bind ${values.legalBusiness}, to such provisions.`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                justifyContent: "center",
                margin: "1.5rem 0",
                gap: "1rem"
              }}
              className={classes.margin}
            >
              <Button
                className={classes.clearButton}
                variant="contained"
                color="#696969"
                size="large"
                onClick={() => handleBack()}
              >
                Back
              </Button>
              {(kycCheckListFlag || ProviderStatus === "New Entry") &&
                ProviderStatus !== "Live" &&
                (editPermissionFlag || createPermissionFlag) && (
                  <>
                    {ProviderStatus === "New Entry" && (
                      <Button
                        variant="contained"
                        className={classes.buttonStyle}
                        color="#696969"
                        id="saveDraftBusinessForm"
                        onClick={() => onFormSubbmit()}
                        size="large"
                        disabled={disabled}
                      >
                        <Typography nowrap>
                          {props.formData.formMode === "Create"
                            ? "Save Draft"
                            : "Save Draft"}
                        </Typography>
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      color="#696969"
                      onClick={() => onFormPreviewSubmit()}
                      size="large"
                      disabled={disabled}
                    >
                      <Typography nowrap>
                        {props.formData.formMode === "Create" ||
                        ProviderStatus === "New Entry"
                          ? "Send For Underwriting"
                          : "Update"}
                      </Typography>
                    </Button>
                  </>
                )}
            </Grid>
          </FormControl>
        </div>
      </Card>
      {/* {props.formData.formMode === "Edit" &&
        values.status !== 1 &&
        kycCheckListFlag && (
          <Grid
            container
            className={classes.fab}
            spacing={2}
            justifyContent="center"
          >
            <Grid item>
              <Button
                fab
                variant="fab"
                color="primary"
                aria-label="add"
                className={classes.verticalButtonStyle}
                onClick={() => setCheckListModal(true)}
                size="large"
              >
                <Typography
                  style={{
                    width: "1em",
                    flexBasis: "min-content",
                    fontSize: "12px"
                  }}
                >
                  Checklist
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                fab
                variant="fab"
                color="primary"
                aria-label="add"
                className={classes.verticalButtonStyle}
                onClick={() => setKycListModal(true)}
                size="large"
              >
                <Typography
                  style={{
                    width: "1em",
                    flexBasis: "min-content",
                    fontSize: "12px"
                  }}
                >
                  KYC - Checklist
                </Typography>
              </Button>
            </Grid>
          </Grid>
        )} */}
      {kycListModal && (
        <Grid
          container
          style={{ flexWrap: "nowrap" }}
          className={classes.fabCheckList}
        >
          <Grid
            container
            style={{ flexWrap: "nowrap", alignItems: "center" }}
            className={classes.tittleColorCheck}
            justifyContent="space-between"
            height={50}
          >
            <Typography className={classes.tittleTextColorList}>
              KYC-Checklist Review
            </Typography>
            <IconButton
              edge="end"
              style={{ margin: "15px" }}
              size="small"
              onClick={() => handleCheckListModalClose("kyc")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
          {!commentSection ? (
            <>
              <Grid
                style={{
                  display: "flex",
                  marginBottom: "25px",
                  marginTop: "25px",
                  alignItems: "center",
                  justifyContent: "center"
                }}
                container
              >
                <Grid item xs={8}>
                  <Autocomplete
                    id="selectOwner"
                    options={[...values.ownerArray.owners]}
                    getOptionLabel={(option) => {
                      return option
                        ? `${option.Owner_First_Name} ${option.Owner_Last_Name}`
                        : "";
                    }}
                    fullWidth
                    value={
                      ownerSelect ? ownerSelect : values.ownerArray.owners[0]
                    }
                    onChange={(event, newValue) => {
                      setOwnerSelect(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Owner"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password" // disable autocomplete and autofill
                        }}
                      />
                    )}
                    helperText="Please select a Owner"
                  />
                </Grid>
              </Grid>
              <Grid
                container
                style={{ flexWrap: "nowrap" }}
                className={classes.margin}
              >
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#ff4747" }}>
                    Pending
                  </Typography>
                  <Typography align="center" style={{ color: "#ff4747" }}>
                    {kycCheckListStatus.pending}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#f5d16e" }}>
                    In Progress
                  </Typography>
                  <Typography align="center" style={{ color: "#f5d16e" }}>
                    {kycCheckListStatus.progress}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#0abf28" }}>
                    Completed
                  </Typography>
                  <Typography align="center" style={{ color: "#0abf28" }}>
                    {kycCheckListStatus.completed}
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <> </>
              </Grid>
              {props.formData.kycQuestionData &&
                props.formData.kycQuestionData.data &&
                props.formData.kycQuestionData.data.map((key, i) => (
                  <Grid
                    container
                    style={{ flexWrap: "nowrap", marginTop: "3%" }}
                  >
                    <Accordion
                      style={{ width: "inherit", margin: "5px" }}
                      elevation={1}
                      expanded={
                        accordian.index.length > 0
                          ? accordian.index.filter((v) => v === i).length > 0
                          : false
                      }
                      onClick={() => handleAccordian(i)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography color="primary">{key.header}</Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {key.list.map((item, index) => (
                          <>
                            <Grid container direction="column">
                              <Grid item>
                                {`${index + 1}. ${item.qusestion}`}
                              </Grid>
                              <Grid
                                item
                                justifyContent="space-between"
                                style={{
                                  display: "flex",
                                  paddingLeft: "20px"
                                }}
                              >
                                <RadioGroup
                                  row
                                  aria-label="position"
                                  name="position"
                                  defaultValue="top"
                                >
                                  {item.options.map((keyItem) => (
                                    <FormControlLabel
                                      value={keyItem.option}
                                      style={{
                                        color: "black",
                                        fontSize: "14px"
                                      }}
                                      control={
                                        <Radio
                                          color="primary"
                                          checked={keyItem.optionSelected}
                                        />
                                      }
                                      onClick={() =>
                                        handleQuestionStatus(
                                          item,
                                          keyItem._id,
                                          "KYC_Checklist",
                                          i
                                        )
                                      }
                                      label={keyItem.option}
                                    />
                                  ))}
                                </RadioGroup>
                                <IconButton
                                  onClick={() =>
                                    handleCommentSection(item, "KYCChecklist")
                                  }
                                >
                                  <ChatBubbleOutlineIcon fontSize="small" />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Divider />
                          </>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              <Grid
                container
                style={{ justifyContent: "space-evenly", marginTop: "20px" }}
                // className={classes.margin}
              >
                {props.formData.questionData &&
                  props.formData.questionData.data &&
                  props.formData.questionData.confirmButton !== "Approve" && (
                    <Grid item>
                      <Button
                        variant="contained"
                        className={classes.buttonStyle}
                        color="#696969"
                        onClick={() => setApproveModal(true)}
                        size="large"
                      >
                        <Typography nowrap>approve</Typography>
                      </Button>
                    </Grid>
                  )}

                {props.formData.questionData &&
                  props.formData.questionData.data &&
                  props.formData.questionData.confirmButton !== "Approve" && (
                    <Grid item>
                      <Button
                        variant="contained"
                        className={classes.clearButton}
                        color="#696969"
                        onClick={() => setDeclineModal(true)}
                        size="large"
                      >
                        <Typography nowrap>Decline</Typography>
                      </Button>
                    </Grid>
                  )}
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
                justifyContent="space-between"
                height={50}
              >
                <Typography className={classes.tittleTextColorAtteched}>
                  {`Checklist items: ${
                    selectedQuestion && selectedQuestion.qusestion
                  }`}
                </Typography>

                <IconButton onClick={() => setCommentSection(false)}>
                  <ArrowBackIcon fontSize="small" style={{ color: "#000" }} />
                </IconButton>
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Comments" {...a11yProps(0)} />
                    <Tab label="Attachments" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  style={{ width: "100%" }}
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <TextareaAutosize
                      onChange={(e) => setTextAreaText(e.target.value)}
                      value={textAreaText}
                      style={{ width: "100%" }}
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Add comment here"
                    />
                    <Grid container>
                      <Grid container item xs justify="flex-end">
                        <Button
                          variant="contained"
                          className={classes.buttonStyle}
                          color="#696969"
                          style={{ margin: "10px" }}
                          size="sm"
                          onClick={() => sendComment()}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanelAttchment
                    value={value}
                    index={1}
                    dir={theme.direction}
                  >
                    <Grid container>
                      <Grid container item xs justify="flex-end">
                        <label htmlFor="upload-photo">
                          <input
                            style={{ display: "none" }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            display="none"
                            onChange={(e) =>
                              handleUploadAttechmentOnChange(e.target.files[0])
                            }
                          />

                          <Button
                            color="primary"
                            variant="contained"
                            component="span"
                          >
                            New
                          </Button>
                        </label>
                      </Grid>
                    </Grid>
                    {fileHandleAttechment.files.length > 0 && (
                      <>
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "flex-start"
                          }}
                          justifyContent="flex-start"
                          height={50}
                        >
                          <Typography
                            className={classes.tittleTextColorAtteched}
                          >
                            Attechments
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "flex-start"
                          }}
                          justifyContent="flex-start"
                          height={50}
                        >
                          {fileHandleAttechment.files.map((item) => (
                            <Typography className={classes.attechmentSection}>
                              {item.File_Name}
                            </Typography>
                          ))}
                        </Grid>
                      </>
                    )}
                  </TabPanelAttchment>
                </SwipeableViews>
              </Grid>
            </>
          )}
        </Grid>
      )}
      {checkListModal && (
        <Grid
          container
          style={{ flexWrap: "nowrap" }}
          className={classes.fabCheckList}
        >
          <Grid
            container
            style={{ flexWrap: "nowrap", alignItems: "center" }}
            className={classes.tittleColorCheck}
            justifyContent="space-between"
            height={50}
          >
            <Typography className={classes.tittleTextColorList}>
              Checklist-MPA Review
            </Typography>
            <IconButton
              edge="end"
              style={{ margin: "15px" }}
              size="small"
              onClick={() => handleCheckListModalClose("mpa")}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Grid>
          {!commentSection ? (
            <>
              <Grid
                container
                style={{ flexWrap: "nowrap" }}
                className={classes.margin}
              >
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#ff4747" }}>
                    Pending
                  </Typography>
                  <Typography align="center" style={{ color: "#ff4747" }}>
                    {mpaCheckListStatus.pending}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#f5d16e" }}>
                    In Progress
                  </Typography>
                  <Typography align="center" style={{ color: "#f5d16e" }}>
                    {mpaCheckListStatus.progress}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  className={classes.marginCheckList}
                  style={{ border: "0.5px solid" }}
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography align="center" style={{ color: "#0abf28" }}>
                    Completed
                  </Typography>
                  <Typography align="center" style={{ color: "#0abf28" }}>
                    {mpaCheckListStatus.completed}
                  </Typography>
                </Grid>
              </Grid>
              {props.formData.questionData &&
                props.formData.questionData.data &&
                props.formData.questionData.data.map((key, i) => (
                  <Grid
                    container
                    style={{ flexWrap: "nowrap", marginTop: "3%" }}
                    className={classes.margin}
                  >
                    <Accordion
                      style={{ width: "inherit" }}
                      expanded={
                        accordian.index.length > 0
                          ? accordian.index.filter((v) => v === i).length > 0
                          : false
                      }
                      onClick={() => handleAccordian(i)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Grid
                          container
                          style={{ justifyContent: "space-between" }}
                        >
                          <Typography color="primary">{key.header}</Typography>
                          <Typography
                            className={
                              key.headerStatus === "Pending"
                                ? classes.checkListStatusPending
                                : key.headerStatus === "Completed"
                                ? classes.checkListStatusComplete
                                : classes.checkListStatusInProgress
                            }
                          >
                            {key.headerStatus}
                          </Typography>
                        </Grid>
                      </AccordionSummary>
                      <AccordionDetails
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {key.list.map((item, index) => (
                          <>
                            <Grid container direction="column">
                              <Grid item>
                                {`${index + 1}. ${item.qusestion}`}
                              </Grid>
                              <Grid
                                item
                                justifyContent="space-between"
                                style={{
                                  display: "flex",
                                  paddingLeft: "20px"
                                }}
                              >
                                <RadioGroup
                                  row
                                  aria-label="position"
                                  name="position"
                                  defaultValue="top"
                                >
                                  {item.options.map((keyItem) => (
                                    <FormControlLabel
                                      value={keyItem.option}
                                      style={{
                                        color: "black",
                                        fontSize: "14px"
                                      }}
                                      control={
                                        <Radio
                                          color="primary"
                                          checked={keyItem.optionSelected}
                                        />
                                      }
                                      onClick={() =>
                                        handleQuestionStatus(
                                          item,
                                          keyItem._id,
                                          "MPA_Checklist",
                                          i
                                        )
                                      }
                                      label={keyItem.option}
                                    />
                                  ))}
                                </RadioGroup>
                                <IconButton
                                  onClick={() =>
                                    handleCommentSection(item, "MPAChecklist")
                                  }
                                >
                                  <ChatBubbleOutlineIcon fontSize="small" />
                                </IconButton>
                              </Grid>
                            </Grid>
                            <Divider />
                          </>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                ))}
              <Grid
                container
                style={{ justifyContent: "flex-start", margin: "10px" }}
                className={classes.margin}
              >
                <Grid item sm={1} xs={10} />
                {props.formData.questionData &&
                  props.formData.questionData.data &&
                  props.formData.questionData.confirmButton === "Approve" && (
                    <Grid item sm={3} xs={10}>
                      <Button
                        variant="contained"
                        className={classes.buttonStyle}
                        color="#696969"
                        onClick={() => setApproveModal(true)}
                        size="large"
                      >
                        <Typography nowrap>approve</Typography>
                      </Button>
                    </Grid>
                  )}
                <Grid item sm={1} xs={10} />
                {props.formData.questionData &&
                  props.formData.questionData.data &&
                  props.formData.questionData.confirmButton !== "Approve" && (
                    <Grid item sm={3} xs={10}>
                      <Button
                        variant="contained"
                        className={classes.clearButton}
                        color="#696969"
                        onClick={() => setDeclineModal(true)}
                        size="large"
                      >
                        <Typography nowrap>Decline</Typography>
                      </Button>
                    </Grid>
                  )}
              </Grid>
            </>
          ) : (
            <>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
                justifyContent="space-between"
                height={50}
              >
                <Typography className={classes.tittleTextColorAtteched}>
                  {`Checklist items: ${
                    selectedQuestion && selectedQuestion.qusestion
                  }`}
                </Typography>

                <IconButton onClick={() => setCommentSection(false)}>
                  <ArrowBackIcon fontSize="small" style={{ color: "#000" }} />
                </IconButton>
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  flexDirection: "row"
                }}
              >
                <AppBar position="static" color="default">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                  >
                    <Tab label="Comments" {...a11yProps(0)} />
                    <Tab label="Attachments" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>
                <SwipeableViews
                  style={{ width: "100%" }}
                  axis={theme.direction === "rtl" ? "x-reverse" : "x"}
                  index={value}
                  onChangeIndex={handleChangeIndex}
                >
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <TextareaAutosize
                      onChange={(e) => setTextAreaText(e.target.value)}
                      value={textAreaText}
                      style={{ width: "100%" }}
                      aria-label="minimum height"
                      minRows={3}
                      placeholder="Add comment here"
                    />
                    <Grid container>
                      <Grid container item xs justify="flex-end">
                        <Button
                          variant="contained"
                          className={classes.buttonStyle}
                          color="#696969"
                          style={{ margin: "10px" }}
                          size="sm"
                          onClick={() => sendComment()}
                        >
                          Save
                        </Button>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanelAttchment
                    value={value}
                    index={1}
                    dir={theme.direction}
                  >
                    <Grid container>
                      <Grid container item xs justify="flex-end">
                        <label htmlFor="upload-photo">
                          <input
                            style={{ display: "none" }}
                            id="upload-photo"
                            name="upload-photo"
                            type="file"
                            display="none"
                            onChange={(e) =>
                              handleUploadAttechmentOnChange(e.target.files[0])
                            }
                          />

                          <Button
                            color="primary"
                            variant="contained"
                            component="span"
                          >
                            New
                          </Button>
                        </label>
                      </Grid>
                    </Grid>
                    {fileHandleAttechment.files.length > 0 && (
                      <>
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "flex-start"
                          }}
                          justifyContent="flex-start"
                          height={50}
                        >
                          <Typography
                            className={classes.tittleTextColorAtteched}
                          >
                            Attechments
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignContent: "center",
                            alignItems: "center",
                            justifyContent: "flex-start"
                          }}
                          justifyContent="flex-start"
                          height={50}
                        >
                          {fileHandleAttechment.files.map((item) => (
                            <Typography className={classes.attechmentSection}>
                              {item.File_Name}
                            </Typography>
                          ))}
                        </Grid>
                      </>
                    )}
                  </TabPanelAttchment>
                </SwipeableViews>
              </Grid>
            </>
          )}
        </Grid>
      )}
      <Modal open={approveModal} onClose={() => setApproveModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCardApprove}>
            <div className={classes.cardDivApprove}>
              <ErrorOutlineIcon style={{ fontSize: 60, fill: "#f5d16e" }} />
              <p>
                <strong style={{ fontSize: "14px", color: "primary" }}>
                  Are you sure you want to approve the checklist?
                </strong>
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  style={{ margin: "10px" }}
                  size="sm"
                  onClick={() => approveYesClick()}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  className={classes.clearButton}
                  color="#696969"
                  style={{ margin: "10px" }}
                  size="sm"
                  onClick={() => setApproveModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal open={declineModal} onClose={() => setDeclineModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCardApprove}>
            <div className={classes.cardDivApprove}>
              <ErrorOutlineIcon style={{ fontSize: 60, fill: "#f5d16e" }} />
              <p>
                <strong style={{ fontSize: "14px", color: "primary" }}>
                  Are you sure you want to decline the checklist?
                </strong>
              </p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  style={{ margin: "10px" }}
                  size="sm"
                  onClick={() => declineYesClick()}
                >
                  Yes
                </Button>
                <Button
                  variant="contained"
                  className={classes.clearButton}
                  color="#696969"
                  style={{ margin: "10px" }}
                  size="sm"
                  onClick={() => setDeclineModal(false)}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </Card>
        </div>
      </Modal>
      <Grid item>
        <Dialog
          open={changeStatusModal}
          onClose={() => setChangeStatusModal(false)}
        >
          <DialogTitle id="alert-dialog-title">
            {"Confirm cancellation?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Are you sure you want to cancel this application?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              className={classes.buttonStyle}
              color="#696969"
              size="large"
              onClick={() => setChangeStatusModal(false)}
            >
              No
            </Button>
            <Button
              variant="contained"
              className={classes.buttonStyle}
              color="#696969"
              size="large"
              onClick={() => saveStatus()}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </>
  );
}

BusinessForm.propTypes = {
  provideRegistration: PropTypes.func,
  formData: PropTypes.object,
  getMccList: PropTypes.func,
  getCountryTypeList: PropTypes.func,
  accountTypeList: PropTypes.func,
  ownerTypeList: PropTypes.func,
  updateRegistration: PropTypes.func,
  getProviderStatus: PropTypes.func,
  mainData: PropTypes.object,
  history: PropTypes.object,
  getProviderActivityById: PropTypes.func,
  changeSingleStatus: PropTypes.func,
  getProviderParameterCall: PropTypes.func,
  deleteIncroptration: PropTypes.func,
  deleteAddressProof: PropTypes.func,
  deleteIdentityProof: PropTypes.func,
  saveQuestionCall: PropTypes.func,
  getKycQuestionData: PropTypes.func,
  statusPreameter: PropTypes.func,
  statusPreameterKyc: PropTypes.func,
  statusApproveMpa: PropTypes.func,
  statusApproveKyc: PropTypes.func,
  setResetWuestion: PropTypes.func,
  mpaChecklistComment: PropTypes.func,
  kycChecklistAttechment: PropTypes.func,
  mpaChecklistAttechment: PropTypes.func,
  changeProviderFormMode: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
  mainData: app
});

export default connect(mapStateToProps, {
  provideRegistration,
  getMccList,
  getKycQuestionData,
  getCountryTypeList,
  accountTypeList,
  ownerTypeList,
  updateRegistration,
  getProviderStatus,
  getupdatestatus,
  getProviderActivityById,
  getProviderList,
  changeSingleStatus,
  getProviderParameterCall,
  deleteIncroptration,
  deleteAddressProof,
  deleteIdentityProof,
  statusPreameter,
  statusPreameterKyc,
  statusApproveMpa,
  statusApproveKyc,
  saveQuestionCall,
  setResetWuestion,
  mpaChecklistComment,
  kycChecklistAttechment,
  changeProviderFormMode,
  mpaChecklistAttechment
})(BusinessForm);
