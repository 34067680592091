/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-syntax */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme, lighten } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import ClearIcon from "@material-ui/icons/Clear";
import TablePagination from "@material-ui/core/TablePagination";
import isEmpty from "lodash/isEmpty";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CoustomButton from "components/CustomButtons/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import Card from "components/Card/Card";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import ChipInput from "material-ui-chip-input";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import closeImage from "assets/img/Close_Image.png";
import Modal from "@material-ui/core/Modal";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/Roles";
import LongMenu from "../../components/Menu/Role";
import { exportToCSV } from "../../Utils/commonUtils";
import {
  getRoleList,
  rolePermisstionRequest,
  changeRoleFormMode,
  getRoleByFilterData,
  roleStatusUpdate
} from "../../Redux/Actions/RoleRegistration";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

const useStyles = makeStyles(tableStyle);

function createData(
  RoleName,
  Profile_Name,
  Landing_Page,
  Creation_Date,
  Update_Date,
  Status,
  Id,
  editable,
  ProviderName_List
) {
  return {
    RoleName,
    Profile_Name,
    Landing_Page,
    Creation_Date,
    Update_Date,
    Status,
    Id,
    editable,
    ProviderName_List
  };
}

function descendingComparator(a, b, orderBy) {
  if (orderBy === "Created_Date" || orderBy === "Updated_Date") {
    return new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf();
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "RoleName",
    numeric: false,
    disablePadding: true,
    label: "Role Name"
  },
  {
    id: "Profile_Name",
    numeric: false,
    disablePadding: true,
    label: "Profile Type"
  },
  {
    id: "Landing_Page",
    numeric: false,
    disablePadding: false,
    label: "Landing Page"
  },
  {
    id: "Creation_Date",
    numeric: false,
    disablePadding: true,
    label: "Creation Date"
  },
  {
    id: "Update_Date",
    numeric: false,
    disablePadding: false,
    label: "Update Date"
  },
  {
    id: "Status",
    numeric: true,
    disablePadding: false,
    label: "Status"
  },
  {
    id: "actions",
    className: "actions",
    numeric: false,
    disablePadding: true,
    label: ""
  }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}

        <TableCell />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

function RoleDataList(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [initialCall, setInitialCall] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("Updated_Date");
  const theme = useTheme();
  const [emptyData, setEmptyDataModel] = React.useState(false);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [pageNo, setPageNo] = React.useState(1);
  const sendApiLink = useSelector((state) => state.app.sendClientIdApi);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [statusDetails, setStatusPid] = React.useState({
    id: "",
    name: "",
    Status: ""
  });
  const [values, setValues] = React.useState({
    Status: "",
    id: ""
  });

  const searchedValues = JSON.parse(
    localStorage.getItem("searchValue") || "{}"
  );
  const chipedArray = JSON.parse(localStorage.getItem("chipArray") || "[]");

  const [searchValues, setSearchValues] = React.useState({
    Name: "",
    Current_Status: "",
    limit: rowsPerPage,
    sortBy: orderBy,
    orderBy: order,
    pageNo: pageNo
  });

  const [openModel, setOpenModel] = React.useState(false);
  const [openassociateModel, setOpenassociateModel] = React.useState(false);
  const [chipValue, setChipValue] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [rolePermission, setRolePermission] = React.useState(
    props.tableData.roleDetailsById
  );
  const [selectedValue, setSelectedValue] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [preUpdateStatus, setPreUpdateStatus] = React.useState(
    props.tableData.updateRoleStatus
  );
  const [validationError, setVlidationError] = React.useState({
    Password: true,
    ConfirmPassword: true
  });

  useEffect(() => {
    if (chipedArray.length) {
      const valuesClientID = { ...searchedValues };
      valuesClientID.ClientId = sendApiLink;
      valuesClientID.LoginEmail = localStorage.getItem("email");
      props.getRoleByFilterData(valuesClientID);
    } else {
    }
  }, []);

  function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
      setPageNo(1);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
      setPageNo(pageNo - 1);
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
      setPageNo(pageNo + 1);
    };

    const handleLastPageButtonClick = (event) => {
      setPageNo(Math.max(0, Math.ceil(count / rowsPerPage)));

      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.rootPagination}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Last</Typography>
          ) : (
            <Typography>First</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Previous</Typography>
          ) : (
            <Typography>Previous</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleFirstPageButtonClick}
          aria-label="first page"
          className={classes.bottonPage}
        >
          <Typography>{page + 1}</Typography>
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <Typography>Next</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? (
            <FirstPageIcon />
          ) : (
            <Typography>Last</Typography>
          )}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  const checkValidation = () => {
    const error = { ...validationError };
    let validate = true;
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,250})"
    );
    if (values.Password.match(strongRegex)) {
      if (values.Password === values.ConfirmPassword) {
        for (const key in values) {
          if (
            values[key] === "" &&
            (key === "Password" || key === "ConfirmPassword")
          ) {
            error[key] = true;
            validate = false;
          } else {
            error[key] = false;
          }
        }
      } else {
        error.ConfirmPassword = true;
        validate = false;
      }
    } else {
      error.Password = true;
      validate = false;
    }

    setVlidationError(error);
    return validate;
  };

  const providerregisterurl = () => {
    props.changeRoleFormMode("Create");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const exportListData = () => {
    let filterValue = "";
    if (chipValue.length) {
      for (const property in chipValue) {
        if (chipValue[property] !== "") {
          filterValue += `${chipValue[property]}`;
          filterValue += " ";
        }
      }
    }
    const sheetModuleDetails = [
      ["Module:", "Role Details"],
      ["Export On:", moment.utc(new Date()).format()],
      [
        "Exported By:",
        `${props.userDetails.userDetailsById.Users_Data[0].FName} ${props.userDetails.userDetailsById.Users_Data[0].LName} <${props.userDetails.userDetailsById.Users_Data[0].Email}>`
      ],
      ["Filters:", filterValue]
    ];
    if (!isEmpty(rows)) {
      const mappedLogs = rows.map((row) => ({
        "Role Name": row.RoleName,
        "Profile Type": row.Profile_Name,
        "Landing Page": row.Landing_Page,
        "Creation Date": row.Creation_Date,
        "Update Date": row.Update_Date,
        Status: row.Status
      }));
      exportToCSV(
        mappedLogs,
        `Roles${moment().format("MMDDYYYY")}`,
        sheetModuleDetails
      );
    } else {
      setEmptyDataModel(true);
    }
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const requestData = { ...searchValues };
    requestData.sortBy = property;
    requestData.orderBy = isAsc ? "desc" : "asc";
    requestData.ClientId = props.userDetails.sendClientIdApi.toString();
    setSearchValues(requestData);
  };

  const saveStatus = () => {
    const statusReequest = {
      rid: values.id,
      Status: values.Status
    };
    props.roleStatusUpdate(statusReequest);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    const requestData = { ...searchValues };
    requestData.limit = parseInt(event.target.value, 10);
    requestData.pageNo = 1;
    requestData.ClientId = props.userDetails.sendClientIdApi.toString();
    setSearchValues(requestData);
  };

  React.useEffect(() => {
    if (rolePermission !== props.tableData.roleDetailsById) {
      if (props.tableData.roleDetailsById.Status) {
        setRolePermission(props.tableData.roleDetailsById);
        props.changeRoleFormMode("ManageRole");
      }
    }
  }, [props]);

  React.useEffect(() => {
    if (!props.tableData.roleList) {
      const data = {
        pageNo: page,
        limit: rowsPerPage,
        sortBy: orderBy,
        orderBy: order,
        ClientId: props.appData.sendClientIdApi.toString()
      };
      props.getRoleList(data);
    }
    if (preUpdateStatus !== props.tableData.preUpdateStatus) {
      setPreUpdateStatus(props.tableData.preUpdateStatus);
    }
  }, []);

  React.useEffect(() => {
    const data = {
      pageNo: page,
      limit: rowsPerPage,
      sortBy: orderBy,
      orderBy: order,
      ClientId: props.appData.sendClientIdApi.toString()
    };
    props.getRoleList(data);
  }, [page, rowsPerPage, orderBy, order, props.appData.sendClientIdApi]);

  const getProviderByFilterData = (value) => {
    const finalSearchData = {
      ...value,
      Name: value.Name == "" ? "" : value.Name.trim()
    };
    setSearchValues(finalSearchData);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (property === "Name") {
          chipArray.push(
            `${"Name"}:
                    ${value[property]}`
          );
        } else if (property === "Current_Status") {
          chipArray.push(
            `${"Status"}:
                    ${value[property]}`
          );
        }
      }
    }
    const valuesClientID = {
      ...value,
      Name: value.Name == "" ? "" : value.Name.trim()
    };
    valuesClientID.ClientId = sendApiLink;
    valuesClientID.LoginEmail = localStorage.getItem("email");
    props.getRoleByFilterData(valuesClientID);
    setChipValue(chipArray);
  };

  const handleNameRedirect = (value) => {
    const data = {
      role_id: value
    };
    props.rolePermisstionRequest(data);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    error[type] = false;
    allState[type] = value;
    allState.id = statusDetails.id;
    setValues(allState);
    setVlidationError(error);
  };

  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      const fillterChip = chipValue.filter((v) => v !== chip);
      setChipValue(chipValue.filter((v) => v !== chip));
      if (fillterChip.length !== 0) {
        chipValue.forEach((v) => {
          searchValues[v.name] = v.value;
        });
        setSearchValues(searchValues);
        localStorage.setItem("searchValue", JSON.stringify(searchValues));
        const valuesClientID = { ...searchValues };
        valuesClientID.ClientId = sendApiLink;
        valuesClientID.LoginEmail = localStorage.getItem("email");
        props.getRoleByFilterData(valuesClientID);
      } else {
        setSearchValues({
          Name: "",
          Current_Status: ""
        });
        const valuesClientID = {
          Name: "",
          Current_Status: ""
        };
        valuesClientID.ClientId = sendApiLink;
        valuesClientID.LoginEmail = localStorage.getItem("email");
        props.getRoleByFilterData(valuesClientID);
        localStorage.removeItem("searchValue");
      }
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const rows = [];
  if (props.tableData.roleList) {
    props.tableData.roleList.Roles_Data.forEach((v, i) => {
      const Id = v._id;
      const RoleName = v.RoleName;
      const Profile_Name = v.Profile_Name;
      const Landing_Page = v.Landing_Page;
      const Creation_Date = moment(v.Created_Date).format("MM/DD/YYYY");
      const Update_Date = moment(v.Updated_Date).format("MM/DD/YYYY");
      const Status = v.Status;
      const editable = v.RoleEdit;
      const associated = v.ProviderName_List;
      rows.push(
        createData(
          RoleName,
          Profile_Name,
          Landing_Page,
          Creation_Date,
          Update_Date,
          Status,
          Id,
          editable,
          associated
        )
      );
    });
  }

  React.useEffect(() => {
    if (props.tableData.roleList.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData.roleList]);

  return (
    <>
      <ThemeProvider theme={themeFont}>
        <Modal open={emptyData} onClose={() => setEmptyDataModel(false)}>
          <div className={classes.modalDiv}>
            <Card className={classes.modalPayOut}>
              <div
                className={classes.cardDiv1}
                style={{
                  display: "flex",
                  overflow: "hidden"
                }}
              >
                <img src={closeImage} width="75px" />

                <div
                  style={{
                    textAlign: "center",
                    color: "gray",
                    fontSize: "14px",
                    whiteSpace: "normal"
                  }}
                >
                  No Data present
                </div>
                <br />
                <Grid container alignItems="center" justifyContent="center">
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    onClick={() => setEmptyDataModel(false)}
                  >
                    ok
                  </Button>
                </Grid>
              </div>
            </Card>
          </div>
        </Modal>
        <Modal
          open={openModel}
          onClose={() => setOpenModel(false)}
          align="center"
        >
          <Card
            className={windowWidthSm ? classes.modalRoot : classes.modalRootXs}
          >
            <div className={classes.statusModalHeader}>
              <div className={classes.statusHeadingText}>
                Change Role Status
              </div>
              <ClearIcon
                style={{ color: "white" }}
                onClick={() => setOpenModel(false)}
              />
            </div>
            <Grid
              container
              className={classes.margin}
              style={{ padding: "2%" }}
            >
              <Grid item className={classes.searchRoot}>
                {`Role: ${statusDetails.name}`}
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ padding: "2%" }}
            >
              <Grid item xs={12}>
                <TextField
                  className={classes.statusRoot}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    }
                  }}
                  label="Status"
                  fullWidth
                  value={statusDetails.Status}
                  onChange={(e) => onChange(e.target.value, "Status")}
                  name="Status"
                  id="Status"
                  select
                >
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Active"
                  >
                    Active
                  </MenuItem>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Inactive"
                  >
                    Inactive
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.marginTop}
              style={{
                paddingTop: "10px",
                paddingRight: "20px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center"
              }}
            >
              <CoustomButton
                variant="contained"
                onClick={() => saveStatus()}
                align="center"
              >
                Update
              </CoustomButton>
            </Grid>
          </Card>
        </Modal>
        <Modal
          open={openassociateModel}
          onClose={() => setOpenassociateModel(false)}
          align="center"
        >
          <div className={classes.modalDiv} align="center">
            <Card
              className={
                windowWidthSm ? classes.modalRoot : classes.modalRootXs
              }
            >
              <div
                className={classes.statusModalHeader}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "98%"
                }}
              >
                <CardHeader
                  className={classes.statusHeadingText}
                  subheader="Associated Users"
                  align="center"
                  style={{ color: "white" }}
                />
                <ClearIcon
                  style={{ color: "white" }}
                  onClick={() => setOpenassociateModel(false)}
                />
              </div>
              <Grid
                container
                style={{ paddingTop: "15px" }}
                className={classes.margin}
              >
                <Grid item xs>
                  <div className={classes.demo}>
                    <span style={{ fontWeight: "bold" }}>Role: </span>
                    {`${statusDetails.name}`}
                  </div>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ marginTop: "20px" }}
                className={classes.margin}
              >
                <Grid item xs>
                  <div className={classes.demo}>
                    {statusDetails.associated && statusDetails.associated[0] ? (
                      <List dense={dense}>
                        {statusDetails.associated.map((key) => (
                          <ListItem xs={12}>
                            <ListItemText primary={key} />
                          </ListItem>
                        ))}
                      </List>
                    ) : (
                      <div className={classes.demo}>
                        <span>None </span>
                      </div>
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container className={classes.marginTop}>
                <Grid
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "20px"
                  }}
                  item
                  sm={12}
                >
                  <Button
                    cancel
                    className={classes.clearButton}
                    aria-controls="simple-menu"
                    onClick={() => setOpenassociateModel(false)}
                    align="center"
                  >
                    Close
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </div>
        </Modal>
        {windowWidth && (
          <Grid container className={classes.searchContainerSetup}>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => handleClose()}
                >
                  <Box style={{ width: "35%" }}>
                    <ChipInput
                      value={chipValue.length > 0 ? chipValue : []}
                      onClick={() => handleClickOpen()}
                      style={{ display: "flex" }}
                      classes={{ chipContainer: classes.chipContainer }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Search"
                      onDelete={(chip, index) => handleDeleteChip(chip, index)}
                    />
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className={classes.overLayBox}
                    >
                      <SimpleDialog
                        close={() => handleClose()}
                        searchValue={searchValues}
                        seacrhChipData={(data) => getProviderByFilterData(data)}
                      />
                    </Collapse>
                  </Box>
                </ClickAwayListener>
                <CoustomButton
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  onClick={() => {}}
                >
                  <Search />
                </CoustomButton>
                <Button
                  size="large"
                  type="submit"
                  style={{
                    backgroundColor: "#03A430",
                    textTransform: "none",
                    borderRadius: "25px",
                    height: "48px",
                    width: "151px",
                    color: "#ffffff",
                    marginLeft: "10px",
                    marginRight: "15px",
                    fontSize: "18px",
                    fontWeight: "600"
                  }}
                  onClick={exportListData}
                  variant=""
                  color="#ffffff"
                >
                  Export Data
                </Button>
                <Button
                  size="large"
                  type="submit"
                  style={{
                    backgroundColor: "#03A430",
                    textTransform: "none",
                    borderRadius: "25px",
                    height: "48px",
                    width: "151px",
                    color: "#ffffff",
                    fontSize: "18px",
                    fontWeight: "600"
                  }}
                  onClick={providerregisterurl}
                  // disabled={
                  //   !isEmpty(errors) ||
                  //   isEmpty(values.id) ||
                  //   (!dirty && isEmpty(values.id)) ||
                  //   action
                  // }
                  variant=""
                  color="#ffffff"
                  // className={classes.submit}
                >
                  <AddIcon />
                  New
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainerSetup}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "85%" }}>
                  <ChipInput
                    value={chipValue.length > 0 ? chipValue : []}
                    onClick={() => handleClickOpen()}
                    style={{ display: "flex" }}
                    classes={{ chipContainer: classes.chipContainer }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Search"
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog
                      close={() => handleClose()}
                      searchValue={searchValues}
                      seacrhChipData={(data) => getProviderByFilterData(data)}
                    />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <CoustomButton
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </CoustomButton>
            </Grid>
            <Grid item className={classes.expirtItemGrid}>
              <CoustomButton
                variant="contained"
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportListData}
                color="primary"
                className={classes.buttonStyle}
              >
                Export
              </CoustomButton>
              {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleCloseMenu}>As Pdf</MenuItem>
              <MenuItem onClick={handleCloseMenu}>As Excel</MenuItem>
            </Menu> */}
              <CoustomButton
                variant="contained"
                onClick={providerregisterurl}
                aria-controls="simple-menu"
                color="primary"
                className={classes.buttonStyle}
              >
                <AddIcon />
                New
              </CoustomButton>
            </Grid>
          </Grid>
        )}
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              rows.map((row, index) => (
                <TableRow
                  style={{
                    backgroundColor: (index + 1) % 2 === 0 ? "#F6F9FF" : "white"
                  }}
                >
                  <TableCell
                    style={{ cursor: "pointer", color: "primary" }}
                    className={classes.patientNameColor}
                    align="center"
                    onClick={() => row.editable && handleNameRedirect(row.Id)}
                  >
                    {row.RoleName}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.Profile_Name}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.Landing_Page}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.Creation_Date}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.Update_Date}
                  </TableCell>
                  <TableCell className={classes.cellText} align="center">
                    {row.Status}
                  </TableCell>
                  <TableCell>
                    <LongMenu
                      details={row.Id}
                      editable={row.editable}
                      {...props}
                      openForm={() => setOpenModel(true)}
                      openassociateModel={() => setOpenassociateModel(true)}
                      setStatusPid={() =>
                        setStatusPid({
                          id: row.Id,
                          name: row.RoleName,
                          Status: row.Status,
                          associated: row.ProviderName_List,
                          Status: row.Status,
                          clientId:
                            process.env.REACT_APP_ACCESS_SERVICE_PROVIDER_ID
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "All", value: props.tableData.roleList?.totalDocs }
                  ]}
                  colSpan={12}
                  count={props.tableData.roleList.totalDocs}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  // SelectProps={{
                  //   inputProps: { placeholder: "Show" },
                  //   native: true,
                  // }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </ThemeProvider>
    </>
  );
}

RoleDataList.propTypes = {
  getRoleList: PropTypes.func,
  tableData: PropTypes.object,
  history: PropTypes.any,
  changeRoleFormMode: PropTypes.func,
  rolePermisstionRequest: PropTypes.func,
  getRoleByFilterData: PropTypes.func,
  setRoleChips: PropTypes.func,
  chipValue: PropTypes.array,
  userDetails: PropTypes.object,
  roleStatusUpdate: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.roles,
  userDetails: app.users,
  appData: app.app
});
export default connect(mapStateToProps, {
  getRoleList,
  rolePermisstionRequest,
  changeRoleFormMode,
  getRoleByFilterData,
  roleStatusUpdate
})(RoleDataList);
