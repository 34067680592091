/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  getProviderByFilterData,
  addSearchData
} from "../../Redux/Actions/ProvideRegistration";

const useStyles = makeStyles(styles);

function SimpleDialog(props) {
  const classes = useStyles();

  const handleClose = () => {
    const allState = { ...props.tableData.searchDetails };
    allState.pageNo = 1;
    allState.searchDbaName = "";
    allState.searchStatus = "";
    props.getProviderByFilterData({
      ...allState,
      ClientId: props.userDetails.sendClientIdApi.toString()
    });
    props.addSearchData(allState);
  };

  const onChange = (value, type) => {
    const allState = { ...props.tableData.searchDetails };
    allState[type] = value;
    props.addSearchData(allState);
  };

  const onFormSubmit = () => {
    const finalSearchData = {
      ...props.tableData.searchDetails
    };
    finalSearchData.searchDbaName =
      finalSearchData.searchDbaName == ""
        ? ""
        : finalSearchData.searchDbaName.trim();
    finalSearchData.pageNo = 1;
    props.getProviderByFilterData({
      ...finalSearchData,
      ClientId: props.userDetails.sendClientIdApi.toString()
    });
    props.seacrhChipData(finalSearchData);
    props.close();
  };

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              }
            }}
            label="DBA Name / Business Email"
            value={props.tableData.searchDetails.searchDbaName}
            onChange={(e) => onChange(e.target.value, "searchDbaName")}
            name="DBA"
            id="DBA"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            placeholder="Select Status"
            label="Status"
            value={props.tableData.searchDetails.searchStatus}
            onChange={(e) => onChange(e.target.value, "searchStatus")}
            name="Current_Status"
            id="Current_Status"
            select
            style={{ fontSize: "82%" }}
          >
            {props.tableData.statusList.Provider_Status_Data.map(
              (status, i) => (
                <MenuItem
                  key={i}
                  className={classes.dialogBoxDropDown}
                  value={status.Status_Code}
                >
                  {status.Provider_Status}
                </MenuItem>
              )
            )}

            {/* <MenuItem className={classes.dialogBoxDropDown} value="1">
              New Entry
            </MenuItem>

            <MenuItem className={classes.dialogBoxDropDown} value="8">
              Onboarded
            </MenuItem> */}
          </TextField>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs className={classes.clearSubmitButton}>
          <br />
          <Button
            cancel
            variant="outlined"
            style={{
              height: "2.1em",
              // marginTop: "5px",
              textTransform: "none"
            }}
            disabled={
              props.tableData.searchDetails.searchDbaName === "" &&
              props.tableData.searchDetails.searchStatus === ""
            }
            size="md"
            onClick={() => handleClose()}
          >
            Clear
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            variant=""
            color="#ffffff"
            style={{
              backgroundColor: "#03A430",
              textTransform: "none",
              borderRadius: "5px",
              height: "30px",
              color: "#ffffff",
              //  fontSize: "18px",
              fontWeight: "600"
            }}
            className={classes.buttonStyle}
            disabled={
              props.tableData.searchDetails.searchDbaName === "" &&
              props.tableData.searchDetails.searchStatus === ""
            }
            size="md"
            onClick={() => onFormSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  close: PropTypes.func,
  tableData: PropTypes.object,
  getProviderByFilterData: PropTypes.func,
  seacrhChipData: PropTypes.func,
  addSearchData: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.getProviderByFilterData,
  tableData: app.provideRegistration,
  userDetails: app.app
});

export default connect(mapStateToProps, {
  getProviderByFilterData,
  addSearchData
})(SimpleDialog);
