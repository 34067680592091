/* eslint-disable react/jsx-indent */
/* eslint-disable eqeqeq */
/* eslint-disable radix */
/* eslint-disable react/no-unescaped-entities */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import isEmpty from "lodash/isEmpty";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  printOfferCall,
  emptyPatientSelect,
} from "Redux/Actions/genrateOfferPatient";
import fullOrigin from "../../assets/img/imgpsh_fullsize_anim.png";

// const useStyles = makeStyles(styles);
const sessionExpire = (props) => {
  // const classes = useStyles();
  //   const [logoPath, setLogoPath] = React.useState("");
  //   const handleBackToSearch = () => {
  //     if (!props.patientDashboard.offerAccountStatusData) {
  //       if (props.formData.emailState) {
  //         props.history.push("/");
  //       } else if (props.formData.createOffer.data.payType === 1) {
  //         props.state();
  //         props.emptyPatientSelect();
  //         props.history.push("/admin/Offers");
  //       } else {
  //         const printRequest = {
  //           patientId: props.formData.paymentDetailsData.data.patientId,
  //           offerId: props.formData.paymentDetailsData.data.offerId,
  //         };
  //         props.printOfferCall(printRequest);
  //         props.history.push("/admin/printoffer");
  //         props.state();
  //       }
  //     } else {
  //       props.history.push("/patient/Dashboard");
  //       props.state();
  //     }
  //   };

  //   let string = "";
  //   if (props.values.cardNumber !== "") {
  //     string = `${
  //       props.values.paymentType === "cc" ? "credit" : "debit"
  //     } card ending with ${
  //       props.values.cardNumber.split(" ")[
  //         props.values.cardNumber.split(" ").length - 1
  //       ]
  //     }`;
  //   } else {
  //     string = `Account ending with ${props.values.bankAccountNumber.substr(-4)}`;
  //   }

    const handleMonthlyPayment = () => {
        props.history.push("/sessionOut");
    };


  //   let monthlyPayment = !props.patientDashboard.offerAccountStatusData
  //     && (props.formData.emailState
  //       ? props.formData.showPatientDetailsViaMail.data[0].Loan_Details
  //         .Monthly_Payment
  //       : false);

  //   if (props.patientDashboard.offerAccountStatusData) {
  //     monthlyPayment = props.patientDashboard.offerAccountStatusData.data[0].monthlyPayment;
  //   }

  //   if (isEmpty(logoPath)) {
  //     const logoPathFind = new Image();
  //     if (props.app.portalConfig) {
  //       const x = props.app.portalConfig.data[0]?.Logo_Image;
  //       logoPathFind.src = `${x}?" + ${new Date().getTime()}`;
  //       logoPathFind.onload = function () {
  //         setLogoPath(logoPathFind.src);
  //       };
  //       logoPathFind.onerror = function () {
  //         setLogoPath(fullOrigin);
  //       };
  //     }
  //   }

  return (
    <>
      <Grid container style={{ margin: "1% 0% 0% 0%" }}>
        <Grid iten xs={2} />
        <Grid iten xs={8} align="center">
          <img
            src={"/images/tpo-logo-alt.png"}
            alt=""
            style={{ width: "50%" }}
          />
        </Grid>
        <Grid iten xs={2} />
      </Grid>
      <Grid container style={{ margin: "1% 0% 0% 0%" }}>
        <Grid iten xs={2} />
        <Grid iten xs={8} align="center">
          {/* <CheckCircleIcon style={{ fontSize: "75px", color: "#3aae3a" }} /> */}
        </Grid>
        <Grid iten xs={2} />
      </Grid>
      {/* <Grid container> */}
      {/* <Grid iten xs={1} /> */}
      {/* <Grid
          item
          xs={10}
          style={{
            fontSize: "16px",
            color: "#3a57a7",
            fontFamily: "Source Sans Pro",
          }}
        >
          <div align="left">
            <div align="left">{`Thank you ${props.values.firstName}`}</div>
            <br />
            {(props.formData.emailState
              || (!props.formData.setUpMonthltFromOffer
                && props.formData.createOffer
                && props.formData.createOffer.data.payType
                  == process.env.REACT_APP_MONTHLY_OFFER_TYPE)) && (
              <div>
                {`Your ${
                  props.paymentMode === "Downpay"
                    ? "Down payment of"
                    : "monthly payment"
                }`}
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.paymentMode === "Downpay"
                    ? props.formData.paymentDetailsData.data.amount
                    : `$${monthlyPayment}`}
                </b>
                {" "}
                against extended payment no.
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentDetailsData.data.offerNumber}
                </b>
                {" "}
                {`has been ${
                  props.paymentMode === "Downpay" ? "processed" : "setup"
                } successfully on`}
                {" "}
                {string}
                . Your Payment reference number is
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentMakeDetails.data.Confirmation}
                </b>
                .
                <br />
                <br />
                {props.paymentMode === "Downpay"
                  && !props.formData.setUpMonthltFromOffer
                  && !props.formData.emailState && (
                  <>
                    <b style={{ fontSize: "22px" }}>
                      Monthly payment of
                      {" "}
                      {`${monthlyPayment}`}
                      {" "}
                      is also setup
                      on this
                      {" "}
                      {props.values.cardNumber !== "" ? "card" : "account"}
                      .
                      If you want to set that up differently click on button
                      "Change Monthly Setup " . You can also do it anytime
                      later by logging on to your TPO Portal.
                    </b>
                  </>
                )}
              </div>
            )}
            {props.patientDashboard.offerAccountStatusData && (
              <div>
                {`Your ${
                  props.paymentMode === "Downpay"
                    ? "Down payment of"
                    : "monthly payment"
                }`}
                {" "}
                <b style={{ fontSize: "22px" }}>{`$${monthlyPayment}`}</b>
                {" "}
                against extended payment no.
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentDetailsData.data.offerNumber}
                </b>
                {" "}
                {`has been ${
                  props.paymentMode === "Downpay" ? "processed" : "setup"
                } successfully on`}
                {" "}
                {string}
                . Your Payment reference number is
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.result.receipt_number}
                </b>
                .
                <br />
                <br />
                {props.paymentMode === "Downpay"
                  && !props.formData.setUpMonthltFromOffer
                  && !props.formData.emailState && (
                  <>
                    <b style={{ fontSize: "22px" }}>
                      Monthly payment of
                      {" "}
                      {`${monthlyPayment}`}
                      {" "}
                      is also setup
                      on this
                      {" "}
                      {props.values.cardNumber !== "" ? "card" : "account"}
                      .
                      If you want to set that up differently click on button
                      "Change Monthly Setup " . You can also do it anytime
                      later by logging on to your TPO Portal.
                    </b>
                  </>
                )}
              </div>
            )}
            {props.formData.setUpMonthltFromOffer && (
              <div>
                {`Your ${
                  props.paymentMode === "Downpay"
                    ? "Down payment of"
                    : "monthly payment"
                }`}
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.paymentMode === "Downpay"
                    ? props.formData.paymentDetailsData.data.amount
                    : `$${monthlyPayment}`}
                </b>
                {" "}
                against extended payment no.
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentDetailsData.data.offerNumber}
                </b>
                {" "}
                {`has been ${
                  props.paymentMode === "Downpay" ? "processed" : "setup"
                }processed successfully on`}
                {" "}
                {string}
                . Your Payment reference number is
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.result.receipt_number}
                </b>
                .
              </div>
            )}
            {!props.formData.emailState
              && props.formData.createOffer
              && props.formData.createOffer.data.payType === parseInt(3) && (
              <div>
                {"Your monthly payment of"}
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.paymentMode === "Downpay"
                    ? props.formData.paymentDetailsData.data.amount
                    : monthlyPayment}
                </b>
                {" "}
                against extended payment no.
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentDetailsData.data.offerNumber}
                </b>
                {" "}
                has been setup successfully on
                {" "}
                {string}
                . If you want to change
                this monthly payment method, You can do it anytime later by
                logging on to your TPO Portal.
              </div>
            )}
            {!props.formData.emailState
              && props.formData.createOffer
              && props.formData.createOffer.data.payType
                == process.env.REACT_APP_DISCOUNT_OFFER_TYPE && (
              <div>
                {"Your full payment after discount of"}
                {" "}
                <b style={{ fontSize: "22px" }}>{props.values.amount}</b>
                {" "}
                  against extended payment no.
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.formData.paymentDetailsData.data.offerNumber}
                </b>
                {" "}
                  has been processed successfully . Your Payment reference
                  number is
                {" "}
                <b style={{ fontSize: "22px" }}>
                  {props.result.receipt_number}
                </b>
                  .
              </div>
            )}
          </div>
        </Grid> */}
      {/* <Grid iten xs={1} /> */}
      {/* </Grid> */}
      <Grid container style={{ marginTop: "3%", marginBottom: "10%" }}>
        <Grid iten xs={3} />
        <Grid item>
          Your session has been expired due to clicking on back/refresh. <br />
          Redirecting you to payment options page. If you are not redirected,
          <p  onClick={() => handleMonthlyPayment()}>please click here.</p>
        </Grid>

        {/* {!props.patientDashboard.offerAccountStatusData
            && !props.formData.emailState
            && props.formData.createOffer.data.payType !== 1 && (
            <Button
              variant="contained"
              onClick={() => handleMonthlyPayment()}
              size="large"
            >
              Change Monthly Setup
            </Button>
          )} */}
      </Grid>
    </>
  );
};

// sessionExpire.propTypes = {
//   formData: PropTypes.object,
//   history: PropTypes.object,
//   state: PropTypes.func,
//   values: PropTypes.object,
//   setConfirmation: PropTypes.func,
//   setShowPaymentDetails: PropTypes.func,
//   setPaymentStatus: PropTypes.func,
//   paymentMode: PropTypes.any,
//   printOfferCall: PropTypes.func,
//   emptyPatientSelect: PropTypes.func,
//   patientDashboard: PropTypes.object,
//   app: PropTypes.object,
//   result: PropTypes.object,
// };

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  userProvider: app.users,
});

export default sessionExpire;

// export default sessionExpire;
