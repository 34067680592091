import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Grid,
  Checkbox,
  Modal,
} from "@material-ui/core";
import {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  setDefaultOfferValue,
  getPatientOfferData,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  setSelectPatientOffer,
  setBackToSearchFromDetails,
  addPreposalidsArray,
  patientTpoScore,
  generatePatientTpoScore,
} from "Redux/Actions/genrateOfferPatient";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { getAxiosAPI, formatter } from "../../actions/AxiosAPIMethods";
import Card from "../../components/Card/Card";
import CoustomAppLoader from "../../components/CoustomAppLoader/Index";
import EditPatientInfo from "./EditPatientInfo";

function AcceptOffer(props) {
  const classes = useStyles();
  const selectedPatient = JSON.parse(
    localStorage.getItem("selectedPatient") || "{}"
  );
  const totalAmount = localStorage.getItem("totalAmount");

  const genrateOfferData = JSON.parse(
    localStorage.getItem("genrateOfferData") || "{}"
  );

  localStorage.setItem(
    "offerId",
    props.tableData.genrateOfferData &&
      props.tableData.genrateOfferData.data[0].offerId
  );
  const [patientInfo, setPatientInfo] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [preGreGenrateOfferLoading, setPreGreGenrateOfferLoading] =
    React.useState(props.tableData.genrateOfferLoading);

  const getPatientDetails = async () => {
    const patientId = selectedPatient?.patientId;
    await getAxiosAPI(`/patients/patient-info?patientId=${patientId}`).then(
      (res) => {
        if (res.error) {
          console.log(res.error);
        } else {
          setPatientInfo(res.data);
        }
      }
    );
  };

  useEffect(() => {
    getPatientDetails();
  }, []);

  useEffect(() => {
    if (
      props.tableData.genrateOfferData.status &&
      !props.tableData.genrateOfferLoading
    ) {
      props.state();
      props.history.push("/admin/offerpayment");
    } else {
      // props.setOfferId(false);
      // props.addPreposalidsArray(genrateOfferData.dueId);
      // props.setPropsalData(genrateOfferData.dueId);
    }
  }, [props]);

  const handleGenerateOffer = () => {
    // props.setOfferId(false);
    // props.addPreposalidsArray(genrateOfferData.dueId)
    // props.setPropsalData(genrateOfferData.dueId);
    props.setLoadingFalse(true);
    setTimeout(() => {
      props.setLoadingFalse(false);
    }, 3000);
    props.getGenrateOfferData(genrateOfferData);
    props.setDefaultOfferValue({ amount: totalAmount, terms: "24" });
  };

  return (
    <Container>
      <Grid className={classes.nameBox}>
        <Grid className={classes.patientNameBox}>
          <Typography>Patient Name:-</Typography>
          <Typography>{selectedPatient?.patientName}</Typography>
        </Grid>
        <Button
          style={{ textTransform: "none" }}
          color="primary"
          onClick={() => {
            props.state();
            props.history.push("/admin/offerDetails");
          }}
        >
          Back
        </Button>
      </Grid>

      {/* ===================================form starts=============================== */}
      <Grid className={classes.patientDetailsCard}>
        <Grid container className={classes.upperInfo}>
          <Grid>
            <Typography className={classes.info}>
              Demographic Information
            </Typography>
          </Grid>
          <Grid>
            <Typography className={classes.balance}>Current Balance</Typography>
            <Button className={classes.balanceDigit}>
              {formatter.format(totalAmount)}
            </Button>
          </Grid>
        </Grid>
        <EditPatientInfo />
      </Grid>

      <Grid className={classes.patientDetailsCard}>
        <Typography className={classes.mainH1}>
          {patientInfo?.TnC?.header}
        </Typography>
        <Typography className={classes.H2}>
          {patientInfo?.TnC?.description}
        </Typography>
        <Typography className={classes.mainH1}>
          {patientInfo?.TnC?.subSections?.header}
        </Typography>
        {patientInfo?.TnC?.subSections?.content?.map((value, index) => (
          <Grid>
            <Typography className={classes.H1}>
              {index + 1}) {value.header}
            </Typography>
            <Typography className={classes.H2}>{value.description}</Typography>
          </Grid>
        ))}

        <Grid>
          <Typography className={classes.H3}>
            {patientInfo?.TnC?.subSections?.disclaimer}
          </Typography>
        </Grid>
        <Grid>
          <Typography className={classes.H1}>
            {patientInfo?.TnC?.checkbox?.header}
          </Typography>
          <Typography className={classes.H2}>
            {patientInfo?.TnC?.checkbox?.disclaimer}
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grid className={classes.bottomRow}>
          <Checkbox
            checked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            style={{ color: "#144b8e" }}
          />
          <Typography className={classes.TNCtext}>
            Accept Terms & condition
          </Typography>
        </Grid>
        <Grid className={classes.bottomRow}>
          <Button
            variant="contained"
            className={classes.buttons}
            disabled={!isChecked}
            onClick={() => handleGenerateOffer()}
          >
            Generate Offer
          </Button>
        </Grid>
      </Grid>

      {/* generate offer modal  */}
      <Modal open={props.tableData.genrateOfferLoading} onClose={() => {}}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard} style={{ width: "30%" }}>
            <div className={classes.cardDiv1}>
              <CoustomAppLoader loader={props.tableData.genrateOfferLoading} />
            </div>
          </Card>
        </div>
      </Modal>
    </Container>
  );
}

AcceptOffer.propTypes = {
  tableData: PropTypes.object,
  getGenrateOfferData: PropTypes.func,
  state: PropTypes.func,
  history: PropTypes.object,
  setLoadingFalse: PropTypes.func,
  setDefaultOfferValue: PropTypes.func,
  addPreposalidsArray: PropTypes.func,
  setOfferId: PropTypes.func,
  setPropsalData: PropTypes.func,
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  userProvider: app.users,
});

export default connect(mapStateToProps, {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  setDefaultOfferValue,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  getPatientOfferData,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  setSelectPatientOffer,
  setBackToSearchFromDetails,
  addPreposalidsArray,
  patientTpoScore,
  generatePatientTpoScore,
})(AcceptOffer);

const useStyles = makeStyles({
  addressBox: {
    display: "flex",
    flexDirection: "column",
  },
  cardDiv1: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10%",
    marginTop: "10%",
    overflow: "auto",
    textAlign: "center",
  },
  modalDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "stretch",
  },
  modalCard: {
    width: "100%",
    height: "auto",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    display: "initial",
  },
  TNCtext: {
    padding: "10px 0",
    fontSize: "16px",
    color: "#144b8e",
  },
  bottomRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  editFields: {
    // margin: "0 2px"
    display: "flex",
    flexDirection: "column",
  },
  activeInputLableColor: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro",
    "&.focused": {
      color: "#144b8e",
    },
  },
  H1: {
    color: "#144b8e",
    fontSize: "16px",
    fontWeight: "600",
    padding: "5px",
  },
  H2: {
    fontSize: "16px",
    fontWeight: "400",
    padding: "0 20px",
  },
  innerInputGrid: {
    display: "flex",
    flexDirection: "row",
  },
  Cbalance: {
    display: "flex",
    justifyContent: "flex-end",
    textAlign: "end",
  },
  inputFileds: {
    border: "none",
    margin: "0 20px",
    outline: "none",
  },
  Ptext: {
    color: "#144b8e",
    fontSize: "18px",
    fontWeight: "400",
    padding: "5px 10px",
  },
  mainH1: {
    color: "#144b8e",
    fontSize: "20px",
    fontWeight: "600",
    padding: "10px 0",
  },
  Atext: {
    // color: "#144b8e",
    padding: "5px 10px",
    fontSize: "18px",
    fontWeight: "400",
  },
  buttons: {
    textTransform: "none",
    fontWeight: "16px",
    padding: "8px 20px",
    color: "white",
    backgroundColor: "#03A430",
    borderRadius: "8px",
    margin: "20px",
  },
  info: {
    color: "#144b8e",
    fontSize: "20px",
    margin: "0 10px",
    fontWeight: "600",
  },
  incomeInfo: {
    color: "#144b8e",
    fontSize: "20px",
    margin: "10px",
    fontWeight: "600",
  },
  balance: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "22px",
    color: "#27337D",
    padding: "0 15px",
  },
  balanceDigit: {
    fontSize: "48px",
    fontWeight: "600",
    lineHeight: "52px",
    color: "#27337D",
  },
  nameBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  patientNameBox: {
    display: "flex",
    flexDirection: "row",
  },
  patientDetailsCard: {
    borderRadius: "15px",
    margin: "20px 0",
    padding: "20px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    backgroundColor: "#FFFFFF",
  },
  upperInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
});
