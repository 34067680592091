/* eslint-disable no-inner-declarations */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useState, useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import PropTypes from "prop-types";
// import exportCss from '../../assets/css/amchart/export';
// eslint-disable-next-line camelcase
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import "../../views/Dashboard/dashboard.css";

import { makeStyles } from "@material-ui/core/styles";
// import PropTypes from "prop-types";
import { Divider, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardFooter from "components/Card/CardFooter";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
// import ArrowCircleUpIcon from '@material-ui/icons/ArrowCircleUp';

import currentBalance from "assets/jss/material-dashboard-react/views/currentBalance";
// import ArrowCircleDownIcon from '@material-ui/icons/ArrowCircleDown';

const options = ["None", "Atria", "Callisto", "Dione"];

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(currentBalance);

am4core.useTheme(am4themes_animated);

function ActivePatient(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();

  // if (props.dashboardData.dailyDepositeCount) {
  //   rows = [];
  //   props.dashboardData.dailyDepositeCount.data.forEach((v, i) => {
  //     const totCount = v.count;
  //     const monthYearDate = v.monthYear;
  //     const totAmount = v.totalAmount;
  //     rows.push(createData(totCount, monthYearDate, totAmount));
  //   });
  // }

  // useEffect(() => {
  //   if (document.getElementById("chartRef") !== null) {
  //     const chart = am4core.create("chartRef", am4charts.XYChart);
  //     chart.logo.disabled = true;
  //     chart.width = am4core.percent(100);
  //     chart.height = am4core.percent(100);
  //     const title = chart.titles.create();
  //     title.text = "Patients";

  //     title.fontSize = 13;
  //     title.fontFamily = "Source Sans Pro";
  //     title.paddingBottom = 10;
  //     title.align = "left";
  //     title.fontWeight = "bold";

  //     if (
  //       props.dashboardData.activePatientData &&
  //       props.dashboardData.activePatientData.data
  //     ) {
  //       // Add data
  //       chart.data = props.dashboardData.activePatientData.data;
  //     }

  //     // Create axes
  //     const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
  //     categoryAxis.dataFields.category = "monthYear";
  //     categoryAxis.renderer.minGridDistance = 0;
  //     categoryAxis.renderer.grid.template.location = 0;
  //     categoryAxis.renderer.grid.template.disabled = true;
  //     categoryAxis.renderer.labels.template.rotation = 315;
  //     categoryAxis.renderer.labels.template.verticalCenter = "middle";
  //     categoryAxis.renderer.labels.template.fontSize = am4core.percent(70);
  //     categoryAxis.renderer.labels.template.url = "/admin/activePatient";
  //     categoryAxis.renderer.labels.template.urlTarget = "_self";

  //     const valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
  //     valueAxis.min = 0;
  //     valueAxis.renderer.grid.template.location = 0;
  //     valueAxis.renderer.grid.template.disabled = true;
  //     valueAxis.renderer.labels.template.fontSize = am4core.percent(70);

  //     // Create series
  //     function createSeries(field, name, color) {
  //       const series = chart.series.push(new am4charts.ColumnSeries());
  //       series.name = name;
  //       series.fill = color;
  //       series.stroke = color;
  //       series.dataFields.valueY = field;
  //       series.dataFields.categoryX = "monthYear";
  //       series.sequencedInterpolation = true;
  //       series.stacked = true;
  //       series.columns.template.width = am4core.percent(40);
  //       series.dummyData = {
  //         description: name,
  //       };
  //       // series.columns.template.tooltipText = "[bold,font-size:11px]{name} :[/]\n-----------------\n[font-size:11px]{categoryX}: {valueY}";

  //       series.columns.template.tooltipHTML = `<table style="font-size:10px">
  // 	   <tr>
  // 	   <center style="font-size:10px"><strong>{name}</strong></center>
  // 	   <hr />
  // 	 </tr>

  // 	   <tr>
  // 		 <th align="left">{categoryX}:</th>
  // 		 <td>{valueY}</td>
  // 	   </tr>

  // 	   </table>`;

  //       const labelBullet = series.bullets.push(new am4charts.LabelBullet());
  //       labelBullet.label.text = "{valueY}";
  //       labelBullet.locationY = 0.5;
  //       labelBullet.label.color = am4core.color("white");
  //       labelBullet.label.fontSize = am4core.percent(70);
  //       labelBullet.label.hideOversized = true;

  //       return series;
  //     }

  //     createSeries("activePatient", "Regular Patient", "#6aaac8");
  //     createSeries("default", "Accounts in Default", "#94d18e");
  //     createSeries("Movetocollection", "Moved To Collection", "#cae6cc");
  //     createSeries("Closed", "Closed", "#e6ce93");
  //     chart.legend = new am4charts.Legend();
  //     chart.legend.position = "right";
  //     chart.legend.width = am4core.percent(22);
  //     chart.legend.useDefaultMarker = true;
  //     chart.legend.fontSize = 10;
  //     /*-----------------*/
  //     chart.legend.labels.template.maxWidth = 10;
  //     chart.legend.labels.template.truncate = true;
  //     chart.legend.labels.template.wrap = true;
  //     // chart.legend.itemContainers.template.tooltipText = "{dataContext.dummyData.description}";
  //     chart.legend.itemContainers.backgroundColor = am4core.color("#ffffff");
  //     chart.legend.itemContainers.template.tooltipHTML = `<table style="font-size:10px">
  // 	   <tr>
  // 	   <center style="font-size:10px">{dataContext.dummyData.description}</center>
  // 		 </tr>   </table>`;

  //     /*--------------------*/

  //     const marker = chart.legend.markers.template.children.getIndex(0);
  //     marker.cornerRadius(12, 12, 12, 12);
  //     marker.strokeWidth = 0;
  //     marker.strokeOpacity = 0;

  //     const markerTemplate = chart.legend.markers.template;
  //     markerTemplate.width = 10;
  //     markerTemplate.height = 10;
  //   }
  // }, [props.dashboardData.activePatientData]);
  return (
    <>
      <Grid
        item
        // className={classes.cardGrid}
      >
        <CardHeader
          className={classes.duesGrid}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "12px",
            paddingTop: "12px",
          }}
        >
          <Typography noWrap className={classes.cardTitle}>
            Deposits
          </Typography>
          <Typography noWrap className={classes.cardTitle}>
            {/* <IconButton
              style={{
                color: "#ffffff",
              }}
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                "aria-labelledby": "long-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: "20ch",
                },
              }}
            >
              {options.map((option) => (
                <MenuItem
                  key={option}
                  selected={option === "Pyxis"}
                  onClick={handleClose}
                >
                  {option}
                </MenuItem>
              ))}
            </Menu> */}
          </Typography>
        </CardHeader>
        
        {props.dashboardData.dailyDepositeCount &&
          props.dashboardData.dailyDepositeCount.data.map((row) => (
            <CardBody>
              <Typography className={classes.typographyAmmount}>
                {row.monthYear}
              </Typography>
              <Typography className={classes.typographyCount}>
                ${row.totalAmount}
              </Typography>
            </CardBody>
          ))}

        {/* <CardBody>
          <Typography className={classes.typographyAmmount}>
            May 16 - May 20, 2022
          </Typography>
          <Typography className={classes.typographyCount}>$7,500.25</Typography>
        </CardBody> */}
        <CardFooter stats>
          <div
            className={classes.viewDetail}
            align="left"
            // onClick={(event) => props.handleChange(event, 1)}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 9.3075L4.9425 8.25L9 4.1925L13.0575 8.25L12 9.3075L9.75 7.0575L9.75 13.5H8.25L8.25 7.0575L6 9.3075ZM0 9C0 4.035 4.035 0 9 0C13.965 0 18 4.035 18 9C18 13.965 13.965 18 9 18C4.035 18 0 13.965 0 9ZM1.5 9C1.5 13.1325 4.8675 16.5 9 16.5C13.1325 16.5 16.5 13.1325 16.5 9C16.5 4.8675 13.1325 1.5 9 1.5C4.8675 1.5 1.5 4.8675 1.5 9Z"
                fill="#008768"
              />
            </svg>
            3.25%
            {/* <ArrowCircleDownIcon /> */}
          </div>
          <div className={classes.stats} />
        </CardFooter>
      </Grid>
    </>
  );
}

ActivePatient.propTypes = {
  dashboardData: PropTypes.object,
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default ActivePatient;
