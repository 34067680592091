/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/button-has-type */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  TabMenu,
  TabPage,
  openSpinner,
  closeSpinner,
} from "@paytheory/pay-theory-ui";
import card4 from "assets/img/card4.jpeg";
import accountIcon from "assets/img/bank-account-icon.jpg";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Card from "components/Card/Card";
import Button from "components/CustomButtons/Button";
import TextField from "@material-ui/core/TextField";
import Modal from "@material-ui/core/Modal";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import AppLoader from "components/AppLoader/Index";
import isEmpty from "lodash/isEmpty";
import fullOrigin from "../../assets/img/imgpsh_fullsize_anim.png";
import PaymentErrorMessage from "./PaymentErrorMessage";
import BarcodeResult from "./BarcodeResult/index";
import TransactionResult from "./TransactionResult/index";
import {
  patientMakePayment,
  emptyGraphData,
  setUpMonthlyOffer,
  beforePaymentSendOTP,
  paymentTokenCheckout,
} from "../../Redux/Actions/genrateOfferPatient";
import PaymentConfirmation from "./PaymentConfirmation";
const math = require("mathjs");

const MENU_ITEMS = {
  CASH: {
    menu: "cash-menu",
    tab: "cash-tab",
  },
  CARD: {
    menu: "card-menu",
    tab: "card-tab",
  },
  ACH: {
    menu: "ach-menu",
    tab: "ach-tab",
  },
};

const apiKey = process.env.REACT_APP_PAYMENTHEORY_API_KEY;
const dummyPaymentKey = process.env.REACT_APP_PAYMENT_ERROR_DUMMY_TEST;

const RenderTime = ({ remainingTime, handleBackToSearch }) => {
  const currentTime = React.useRef(remainingTime);
  const prevTime = React.useRef(null);
  const isNewTimeFirstTick = React.useRef(false);
  const [, setOneLastRerender] = React.useState(0);

  if (currentTime.current !== remainingTime) {
    isNewTimeFirstTick.current = true;
    prevTime.current = currentTime.current;
    currentTime.current = remainingTime;
  } else {
    isNewTimeFirstTick.current = false;
  }

  // force one last re-render when the time is over to tirgger the last animation
  if (remainingTime === 0) {
    setTimeout(() => {
      setOneLastRerender((val) => val + 1);
    }, 20);
  }

  if (currentTime.current === 0) {
    handleBackToSearch();
  }

  const isTimeUp = isNewTimeFirstTick.current;
  // eslint-disable-next-line no-param-reassign
  function fmtMSS(s) {
    return (s - (s %= 60)) / 60 + (s > 9 ? ":" : ":0") + s;
  }

  return (
    <div className="time-wrapper">
      {prevTime.current !== null && (
        <div
          key={prevTime.current}
          className={`time ${!isTimeUp ? "down" : ""}`}
        >
          {fmtMSS(prevTime.current)}
        </div>
      )}
    </div>
  );
};

const useStyles = makeStyles(styles);

const CheckoutOptions = (props) => {
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();
  const [valid, isValid] = useState("");
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [paymentMethod, setPaymentMethod] = React.useState("cc");
  const [logoPath, setLogoPath] = React.useState("");
  const [payTheory, setPayTheory] = useState(false);
  const [showPaymentDetails, setShowPaymentDetails] = React.useState(true);
  const [error, setError] = useState(false);
  const [openCancelModal, setOpenCancelModal] = React.useState(false);
  const [result, setResult] = useState(false);
  const [transactionResult, setTransactionResult] = React.useState();
  const [payAmount, setAmount] = React.useState();
  const [barcode, setBarcode] = useState(false);
  const [handleConfirmation, setConfirmation] = React.useState(false);
  const [statusMessage, setStatusMessage] = useState(<div />);
  const [API_KEY] = useState(apiKey);
  const [DUMMY_PAYMENT_KEY] = useState(dummyPaymentKey);
  const [handleFail, setHandleFail] = React.useState(false);
  const [prePaymentDetails, setPrePaymentDetails] = React.useState(
    props.formData.paymentMakeDetails
  );
 
  const [loadValue, setLoadValue] = React.useState(props);
  const [paymentMode, setPaymentMode] = React.useState(
    !props.formData.setUpMonthltFromOffer &&
      !props.patientDashboard.offerAccountStatusData
      ? "Downpay"
      : "Setup"
  );

  useEffect(() => {
    if (
      !props.patientDashboard.offerAccountStatusData &&
      !props.formData.paymentDetailsData
      // !props.formData.emailState
    ) {
      // props.history.push("/admin/offerpayment");
    }
  }, []);

  const [values, handleInput] = React.useState({
    cardNumber: "",
    expiryDate: "",
    cvv: "",
    paymentType: 2,
    amount: props.patientDashboard.offerAccountStatusData
      ? props.patientDashboard.offerAccountStatusData.data[0].monthlyPayment
      : props.formData.paymentDetailsData &&
        props.formData.paymentDetailsData.data.amount,
    firstName:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.firstName,
    lastName:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.lastName,
    email:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.email,
    address1:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.address1,
    address2:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.address2,
    city:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.city,
    state:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.state,
    zipCode:
      props.formData.paymentDetailsData &&
      props.formData.paymentDetailsData.data.zip,
    bankRouting: "",
    bankAccountNumber: "",
    bankAccountType: "savings",
  });

  const [billingValidationError, setBillingValidationError] =
    React.useState(true);

  const [validationError, setValidationError] = React.useState({
    cardNumber: false,
    expiryDate: false,
    cvv: false,
    firstName: false,
    lastName: false,
    email: false,
    address1: false,
    city: false,
    state: false,
    bankRouting: false,
    bankAccountNumber: false,
    zipCode: false,
  });

  const setRetryPayment = () => {
    const allState = { ...values };
    allState.cardNumber = "";
    allState.cvv = "";
    allState.expiryDate = "";
    allState.bankRouting = "";
    allState.bankAccountNumber = "";
    allState.bankAccountType = "savings";
    // handleInput(allState);
    setBillingValidationError(true);
  };

  const selectTab = (selected) => {
    const tab = document.getElementById(selected.tab);
    tab.classList.remove("gone");
    tab.classList.add("tab-visible");
  };
  const clearUnselected = (unselected) => {
    unselected.forEach((item) => {
      const tab = document.getElementById(item.tab);
      tab.classList.remove("tab-visible");
      tab.classList.add("gone");
    });
  };
  const changeTab = (selected) => {
    /* istanbul ignore next */
    switch (selected) {
      case MENU_ITEMS.CASH:
        clearUnselected([MENU_ITEMS.CARD, MENU_ITEMS.ACH]);
        setPaymentMethod("cash");
        break;
      case MENU_ITEMS.ACH:
        clearUnselected([MENU_ITEMS.CARD, MENU_ITEMS.CASH]);
        setPaymentMethod("ach");
        break;
      default:
        clearUnselected([MENU_ITEMS.ACH, MENU_ITEMS.CASH]);
        setPaymentMethod("cc");
    }
    selectTab(selected);
  };

  if (isEmpty(logoPath)) {
    const logo_path = new Image();
    if (props.app.portalConfig) {
      const x = props.app.portalConfig.data[0]?.Logo_Image;
      logo_path.src = `${x}?" + ${new Date().getTime()}`;
      logo_path.onload = function () {
        setLogoPath(logo_path.src);
      };
      logo_path.onerror = function () {
        setLogoPath(fullOrigin);
      };
    }
  }

  const paymentMethodIdUUID = () => {
    return "tst_pmt_xxxxxy4xxxyyxxxyxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x2) | 0x4;
      return v.toString(16);
    });
  };

  const generateTokenUUID = () => {
    return "tst_tokn_xxxxy4xxxyyxxxyxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x2) | 0x4;
      return v.toString(16);
    });
  };

  const payoridUUID = () => {
    return "tst_paor_xxxxy4xxxyyxxxyxxxxxx".replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x2) | 0x4;
      return v.toString(16);
    });
  };

  // Generate a random UUID
  const paymentMethodId = paymentMethodIdUUID();
  const generateToken = generateTokenUUID();
  const payorid = payoridUUID();


  const initTransaction = (type) => () => {
    let getPayAmount = values.amount.toString().replace("$", "");
    let convertDecimalAmount = math.number(getPayAmount);
    let multiplyNumber = parseInt(100);
    let multiplyAmount = convertDecimalAmount * multiplyNumber;
    let AMOUNT = math.round(multiplyAmount);
    // const AMOUNT = parseInt(100);
    const TRANSACTING_PARAMETERS = {
      amount: AMOUNT,
    };
    if (DUMMY_PAYMENT_KEY == "NO") {
      window.paytheory.transact(TRANSACTING_PARAMETERS).then((result) => {
        if (result.type === "SUCCESS") {
          const financeNo = localStorage.getItem("finNo");
          const data = {
            patientId: props.formData.paymentDetailsData.data.patientId,
            offerId: props.formData.paymentDetailsData.data.offerId,
            generateToken: result.body.receipt_number,
            paymentMethodId: result.body.payment_method_id,
            amount:
              paymentMode === "Setup"
                ? 0.0
                : props.formData.paymentDetailsData.data.payType == 3
                ? 1
                : Number(
                    props.formData.paymentDetailsData.data.amount
                      .toString()
                      .replace("$", "")
                  ),
            payor_id: result.body.payor_id,
            paymentType: paymentMethod,
            cardLast4Digit:
              paymentMethod !== "ach"
                ? result.body.last_four
                : values.bankAccountNumber.slice(
                    values.bankAccountNumber.length - 4
                  ),
            email: values.email,
            bankRouting: values.bankRouting,
            firstName: values.firstName,
            lastName: values.lastName,
            address1: values.address1,
            address2: values.address2,
            cardType: result.body.brand,
            state: values.state,
            city: values.city,
            payType: props.formData.paymentDetailsData.data.payType,
            zipCode: values.zipCode,
            payStatus: paymentMode,
            paymentId: props.formData.paymentDetailsData.data.paymentId,
            financeNo: financeNo || 1,
          };
          props.patientMakePayment(data);
          // closeSpinner();
          setLoading(false);
        }
      });
    } else {
      const financeNo = localStorage.getItem("finNo");
      const data = {
        patientId: props.formData.paymentDetailsData.data.patientId,
        offerId: props.formData.paymentDetailsData.data.offerId,
        generateToken: generateToken,
        paymentMethodId: paymentMethodId,
        amount:
          paymentMode === "Setup"
            ? 0.0
            : props.formData.paymentDetailsData.data.payType == 3
            ? 1
            : Number(
                props.formData.paymentDetailsData.data.amount
                  .toString()
                  .replace("$", "")
              ),
        payor_id: payorid,
        paymentType: paymentMethod,
        cardLast4Digit: values.cardNumber.slice(values.cardNumber.length - 4),
        email: values.email,
        bankRouting: values.bankRouting,
        firstName: values.firstName,
        lastName: values.lastName,
        address1: values.address1,
        address2: values.address2,
        cardType: "VISA",
        state: values.state,
        city: values.city,
        payType: props.formData.paymentDetailsData.data.payType,
        zipCode: values.zipCode,
        payStatus: paymentMode,
        paymentId: props.formData.paymentDetailsData.data.paymentId,
        financeNo: 1,
        cardNumber: values.cardNumber,
        cvv: values.cvv,
        expiryDate: values.expiryDate,
        financeNo: financeNo || 1,
      };
      props.patientMakePayment(data);
    }
    // openSpinner();
    // setLoading(true);
  };

  const handleBackToSearch = () => {
    if (!props.patientDashboard.offerAccountStatusData) {
      if (props.formData.emailState) {
        props.history.push("/admin/email/offer");
      } else {
        setOpenCancelModal(false);
        // props.history.push("/admin/offerpayment");
        // props.state();
      }
    } else {
      props.history.push("/patient/Dashboard");
      // props.state();
    }
  };

  React.useEffect(() => {
    const STYLES = {
      default: {
        color: "black",
        fontSize: "14px",
        fontFamily: "Source Sans Pro",
        background: "transparent",
        // font: "inherit",
        width: "100%",
        // borderBottom: "1px solid gray",
        // height: "1.1876em",
        margin: "0",
        display: "block",
        padding: "2px 0 3px",
        minWidth: 0,
        boxSizing: "content-box",
        animationName: "mui-auto-fill-cancel",
        letterSpacing: "inherit",
        animationDuration: "10ms",
      },
      error: {
        color: "#d9534f",
        fontSize: "14px",
        // borderBottom:"1px solid gray",
        // marginBottom:"-10px"
      },
    };

    window.paytheory.payTheoryFields({
      apiKey: API_KEY,
      styles: STYLES,
    });

    setLoading(false);

    window.paytheory.validObserver((valid) => {
      console.log("validObserver", valid);
      if (valid === "card" || valid === "cash" || valid === "ach") {
        isValid(valid);
      } else {
        isValid([]);
      }
    });
    window.paytheory.transactedObserver((txResult) => {
      console.log("txResult", txResult);
    });

    // openSpinner();
  }, [API_KEY, paymentMethod]);

  React.useEffect(() => {
    if (prePaymentDetails !== props.formData.paymentMakeDetails) {
      setPrePaymentDetails(props.formData.paymentMakeDetails);
      if (props.formData.paymentMakeDetails.status) {
        setConfirmation(true);
        setLoading(false);
        setShowPaymentDetails(false);
      }
      if (!props.formData.paymentMakeDetails.status) {
        setHandleFail(true);
      }
    }
  }, [props]);

  const setPaymentStatus = () => {
    const allState = { ...values };
    allState.amount = props.formData.emailState
      ? props.formData.showPatientDetailsViaMail?.data[0]?.Loan_Details
          ?.Monthly_Payment
      : props.formData.paymentDetailsData.data.amount;
    allState.cardNumber = "";
    allState.cvv = "";
    allState.expiryDate = "";
    allState.bankRouting = "";
    allState.bankAccountNumber = "";
    allState.bankAccountType = "savings";
    handleInput(allState);
    setBillingValidationError(true);
    setPaymentMode("Setup");
  };

  useEffect(() => {
    if (props.history.action == "POP") {
      setOpenCancelModal(false);
      // props.history.push("/admin/offerpayment");
    }
  }, []);

  const formatCardNumber = (value) => {
    const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
    const onlyNumbers = value.replace(/[^\d]/g, "");

    return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter((group) => !!group).join(" ")
    );
  };

  const formatCardExpiry = (value) => {
    const regex = /^(\d{0,2})(\d{0,2})$/g;
    const onlyNumbers = value.replace(/[^\d]/g, "");

    return onlyNumbers.replace(regex, (regex, $1, $2) =>
      [$1, $2].filter((group) => !!group).join("/")
    );
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    if (type === "cardNumber") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{19,20}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    } else if (type === "cvv") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{2,3}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    } else if (type === "expiryDate") {
      if (!/^[-a-zA-Z0-9@:%_\+,. "';:~#&//=]{4,5}$/.test(value)) {
        error[type] = true;
        allState[type] = value;
      } else {
        error[type] = false;
        allState[type] = value;
      }
    } else {
      error[type] = false;
      allState[type] = value;
    }
    handleInput(allState);
    setBillingValidationError(true);
    setValidationError(error);
  };
  const checkValidation = (saveDraft = false, autoValidate = false) => {
    let validate = true;
    const error = { ...validationError };
    for (const key in values) {
      if (saveDraft) {
        if (
          values[key] === "" &&
          (key === "legalBusiness" || key === "businessEmail")
        ) {
          error[key] = true;
          validate = false;
        }
        if (key === "ownerArray") {
          values[key].owners.forEach((item, i) => {
            if (item.Owner_First_Name === "") {
              error[key].owners[i].Owner_First_Name = true;
              validate = false;
            }
          });
        }
      }
    }
    setValidationError(error);

    return validate;
  };
  return (
    <div style={{ margin: windowWidth ? "1% 15% 1% 15%" : "1%" }}>
      {/* <AppLoader loader={loading} /> */}
      <Modal open={openCancelModal} onClose={() => setOpenCancelModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "60%",
                height: "150px",
                paddingBottom: "5%",
              }}
            >
              <Grid container className={classes.margin}>
                <Grid
                  item
                  xs
                  style={{
                    margin: "10%",
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "-webkit-center",
                  }}
                >
                  Do You want to cancel the process of making payment?
                </Grid>
              </Grid>
              <Grid container className={classes.margin}>
                <Grid item xs={2} />
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    style={{ height: "30px" }}
                    color="#696969"
                    size="sm"
                    onClick={() => handleBackToSearch()}
                  >
                    Ok
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    className={classes.clearButton}
                    color="#696969"
                    size="sm"
                    onClick={() => setOpenCancelModal(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Card>
        </div>
      </Modal>
      {!windowWidth ? (
        <Grid container>
          <Grid item xs={windowWidth ? 4 : 12} align="center">
            <img
              src={
                props.app.defaultTheme &&
                props.app.defaultTheme.data[0].Logo_Image
              }
              width="30%"
              height="auto"
              alt=""
            />
          </Grid>
        </Grid>
      ) : (
        ""
      )}
      <Card>
        <div style={{ margin: "15px" }}>
          {!handleConfirmation && !handleFail && (
            <>
              <Grid container style={{ margin: "1%" }}>
                {windowWidth ? (
                  <>
                    <Grid item xs={windowWidth ? 4 : 12}>
                      <img src={logoPath} width="30%" height="auto" alt="" />
                    </Grid>
                    <Grid
                      item
                      xs={5}
                      align="center"
                      // style={{
                      //   fontSize: "30px", display: "flex", justifyContent: "center", alignItems: "center",
                      // }}
                      style={{
                        fontSize: "30px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {props.formData.paymentDetailsData &&
                      props.formData.paymentDetailsData.data.payType == 3
                        ? "$1"
                        : values.amount}
                      {!props.formData.emailState &&
                        props.formData.paymentDetailsData &&
                        props.formData.paymentDetailsData.data.payType != 1 && (
                          <>
                            {props.formData.paymentDetailsData &&
                              props.formData.paymentDetailsData.data.payType ==
                                3 && (
                                <div
                                  style={{
                                    fontSize: "14px",
                                    marginTop: "3%",
                                    color: "#3a57a7",
                                  }}
                                >
                                  This is authentication fee to setup the
                                  monthly payment. Monthly payable amount will
                                  be {values.amount}
                                </div>
                              )}
                            <div
                              style={{
                                fontSize: "14px",
                                marginTop: "3%",
                                color: "#3a57a7",
                              }}
                            >
                              {`(Next due on : ${props.formData.paymentDetailsData.data.nextDueDate})`}
                            </div>
                          </>
                        )}
                    </Grid>
                    <Grid
                      item
                      xs={3}
                      style={{
                        display: "flex",
                        gap: "1rem",
                      }}
                    >
                      <CountdownCircleTimer
                        isPlaying
                        duration={300}
                        size={80}
                        colors={[
                          ["#3a57a7", 0.33],
                          ["#64bc5f", 0.33],
                          ["#A30000"],
                        ]}
                      >
                        <RenderTime
                          handleBackToSearch={() => handleBackToSearch()}
                          {...props}
                        />
                      </CountdownCircleTimer>
                      <Button
                        className={classes.clearButton}
                        onClick={() => setOpenCancelModal(true)}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
              <div className={`fields ${result || barcode ? "hide" : ""}`}>
                <Grid container>
                  <Grid item xs={windowWidth ? 4 : 12}>
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => changeTab(MENU_ITEMS.CARD)}
                      className={classes.margin}
                      style={{
                        width: "75%",
                        color: `${
                          paymentMethod === "cc" ? "black" : "#A7C2B5"
                        }`,
                        background: `${
                          paymentMethod === "cc" ? "#EFF4FF" : "#fff"
                        }`,
                      }}
                    >
                      <img src={card4} style={{ width: "30%" }} alt="" />
                      &nbsp;&nbsp; Credit Card
                    </Button>
                    <Grid item style={{ marginTop: "3%" }} />
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => changeTab(MENU_ITEMS.ACH)}
                      className={classes.margin}
                      style={{
                        width: "75%",
                        color: `${
                          paymentMethod === "ach" ? "black" : "#A7C2B5"
                        }`,
                        background: `${
                          paymentMethod === "ach" ? "#EFF4FF" : "#fff"
                        }`,
                      }}
                    >
                      <img src={accountIcon} style={{ width: "25%" }} alt="" />
                      &nbsp;&nbsp; Bank Account
                    </Button>
                    <Grid item style={{ marginTop: "3%" }} />
                    <Button
                      variant="outlined"
                      size="large"
                      onClick={() => changeTab(MENU_ITEMS.CASH)}
                      className={classes.margin}
                      style={{
                        width: "75%",
                        paddingRight: "65px",
                        color: `${
                          paymentMethod === "cash" ? "black" : "#A7C2B5"
                        }`,
                        background: `${
                          paymentMethod === "cash" ? "#EFF4FF" : "#fff"
                        }`,
                      }}
                    >
                      <img src={card4} style={{ width: "42%" }} alt="" />
                      &nbsp;&nbsp; Cash
                    </Button>
                  </Grid>
                  <Grid item xs={windowWidth ? 8 : 12}>
                    <TabPage id={MENU_ITEMS.CARD.tab}>
                      {DUMMY_PAYMENT_KEY === "NO" ? (
                        <div>
                          <Grid container>
                            <Grid
                              m={0}
                              p={0}
                              xs={12}
                              style={{ margin: "0px", padding: "0px" }}
                            >
                              <div
                                id="pay-theory-credit-card-number"
                                style={{
                                  borderBottom: "1px solid grey",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              />
                            </Grid>
                            <Grid
                              m={0}
                              p={0}
                              xs={3}
                              style={{ margin: "0px", padding: "0px" }}
                            >
                              <div
                                id="pay-theory-credit-card-exp"
                                style={{
                                  borderBottom: "1px solid grey",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              />
                            </Grid>

                            <Grid
                              m={0}
                              p={0}
                              xs={3}
                              style={{ marginLeft: "56px" }}
                            >
                              <div
                                id="pay-theory-credit-card-cvv"
                                style={{
                                  borderBottom: "1px solid grey",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid m={0} p={0} xs={3}>
                            <div
                              id="pay-theory-credit-card-zip"
                              style={{
                                borderBottom: "1px solid grey",
                                margin: "0px",
                                padding: "0px",
                              }}
                            />
                          </Grid>
                        </div>
                      ) : (
                        <div>
                          <Grid container>
                            <Grid
                              m={0}
                              p={0}
                              xs={12}
                              style={{ margin: "0px", paddingRight: "174px" }}
                            >
                              <TextField
                                required
                                error={validationError.cardNumber}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Card Number"
                                inputProps={{ maxLength: 19, minLength: 19 }}
                                value={formatCardNumber(values.cardNumber)}
                                onChange={(e) =>
                                  onChange(e.target.value, "cardNumber")
                                }
                                name="cardNumber"
                                id="cardNumber"
                                helperText={
                                  validationError.cardNumber &&
                                  "Please Enter valid card number"
                                }
                              />
                            </Grid>
                            <Grid
                              m={0}
                              p={0}
                              xs={3}
                              style={{ margin: "0px", padding: "0px" }}
                            >
                              <TextField
                                required
                                error={validationError.expiryDate}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Expiry Date"
                                value={formatCardExpiry(values.expiryDate)}
                                onChange={(e) => {
                                  if (
                                    e.target.value.length == 2 &&
                                    parseInt(e.target.value) > 12
                                  ) {
                                    onChange("", "expiryDate");
                                  } else if (
                                    e.target.value.includes("/") &&
                                    parseInt(e.target.value.split("/")[0]) > 12
                                  ) {
                                    onChange("", "expiryDate");
                                  } else {
                                    onChange(e.target.value, "expiryDate");
                                  }
                                }}
                                // onChange={(e) =>
                                //   (/^\d{1,2}\/\d{2}$/g.test(e.target.value) ||
                                //     e.target.value == "") &&
                                //   onChange(e.target.value, "expiryDate")
                                // }
                                inputProps={{ maxLength: 5, minLength: 5 }}
                                name="expiryDate"
                                id="expiryDate"
                                helperText={`${
                                  validationError.expiryDate
                                    ? "Please Enter valid expiry date"
                                    : ""
                                }`}
                              />
                            </Grid>

                            <Grid
                              m={0}
                              p={0}
                              xs={3}
                              style={{ marginLeft: "56px" }}
                            >
                              <TextField
                                required
                                error={validationError.cvv}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="CVV"
                                value={values.cvv}
                                onChange={(e) =>
                                  (/^[0-9]\d*$/g.test(e.target.value) ||
                                    e.target.value == "") &&
                                  onChange(e.target.value, "cvv")
                                }
                                inputProps={{ maxLength: 3, minLength: 3 }}
                                name="cvv"
                                id="cvv"
                                helperText={`${
                                  validationError.cvv
                                    ? "Please Enter valid CVV"
                                    : ""
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid m={0} p={0} xs={3}>
                            <TextField
                              required
                              error={validationError.zipCode}
                              className={classes.halfInput}
                              InputLabelProps={{
                                classes: {
                                  root: classes.activeInputLableColor,
                                  focused: "focused",
                                },
                              }}
                              InputProps={{
                                color: "secondary",
                                classes: {
                                  root: classes.activeInputColor,
                                  focused: "focused",
                                  input: classes.resize,
                                },
                                required: true,
                              }}
                              label="Zip Code"
                              value={values.zipCode}
                              onChange={(e) =>
                                onChange(e.target.value, "zipCode")
                              }
                              name="zipCode"
                              id="zipCode"
                              helperText={`${
                                validationError.zipCode
                                  ? "Please Enter valid Zip Code"
                                  : ""
                              }`}
                            />
                          </Grid>
                        </div>
                      )}

                      <Grid container>
                        <Grid item xs>
                          <div
                            style={{
                              fontSize: "18px",
                              color: "#00aab4",
                              marginTop: "30px",
                              fontFamily: "Source Sans Pro",
                            }}
                          >
                            Billing Information:
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            required
                            error={validationError.firstName}
                            className={classes.halfInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label="First Name"
                            value={values.firstName}
                            onChange={(e) =>
                              onChange(e.target.value, "firstName")
                            }
                            name="firstName"
                            id="firstName"
                            helperText={`${
                              validationError.firstName
                                ? "Please Enter First Name"
                                : ""
                            }`}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            error={validationError.lastName}
                            className={classes.halfInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label="Last Name"
                            value={values.lastName}
                            onChange={(e) =>
                              onChange(e.target.value, "lastName")
                            }
                            name="lastName"
                            id="lastName"
                            helperText={`${
                              validationError.lastName
                                ? "Please Enter Last Name"
                                : ""
                            }`}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            required
                            error={validationError.email}
                            className={classes.fullInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label="Email"
                            value={values.email}
                            onChange={(e) => onChange(e.target.value, "email")}
                            name="email"
                            id="email"
                            helperText={`${
                              validationError.email ? "Please Enter Email" : ""
                            }`}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            required
                            error={validationError.address1}
                            className={classes.fullInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label="Address-Line 1"
                            value={values.address1}
                            onChange={(e) =>
                              onChange(e.target.value, "address1")
                            }
                            name="address1"
                            id="address1"
                            helperText={`${
                              validationError.address1
                                ? "Please Enter Address-Line 1"
                                : ""
                            }`}
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            className={classes.fullInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label="Address-Line 2"
                            value={values.address2}
                            onChange={(e) =>
                              onChange(e.target.value, "address2")
                            }
                            name="address2"
                            id="address2"
                          />
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            required
                            error={validationError.city}
                            className={classes.halfInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label="City"
                            value={values.city}
                            onChange={(e) => onChange(e.target.value, "city")}
                            name="City"
                            id="city"
                            helperText={`${
                              validationError.city ? "Please Enter City" : ""
                            }`}
                          />
                        </Grid>
                        <Grid item xs={6} />
                      </Grid>
                      <Grid container>
                        <Grid item xs={6}>
                          <TextField
                            required
                            error={validationError.state}
                            className={classes.halfInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label="State Code"
                            value={values.state}
                            onChange={(e) => onChange(e.target.value, "state")}
                            name="state"
                            id="state"
                            helperText={`${
                              validationError.state ? "Please Enter State" : ""
                            }`}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            required
                            error={validationError.zipCode}
                            className={classes.halfInput}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused",
                              },
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                focused: "focused",
                                input: classes.resize,
                              },
                              required: true,
                            }}
                            label="Zip Code"
                            value={values.zipCode}
                            onChange={(e) =>
                              onChange(e.target.value, "zipCode")
                            }
                            name="zipCode"
                            id="zipCode"
                            helperText={`${
                              validationError.zipCode
                                ? "Please Enter Zip Code"
                                : ""
                            }`}
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        style={{ marginTop: "5%", justifyContent: "center" }}
                      >
                        <button
                          name="card-button"
                          className={classes.buttonStyle}
                          onClick={initTransaction("card")}
                          // disabled={!valid.includes("card")}
                        >
                          {`${
                            paymentMode === "Downpay"
                              ? "Pay Now"
                              : "Setup Monthly"
                          }`}{" "}
                          {/* {`${
                              paymentMode === "Downpay"
                                ? props.formData.paymentDetailsData &&
                                  props.formData.paymentDetailsData.data.amount
                                : values.amount
                            }`} */}
                        </button>
                      </Grid>
                    </TabPage>
                    <TabPage id={MENU_ITEMS.ACH.tab} visibility="gone">
                      {paymentMethod == "ach" ? (
                        <div>
                          <Grid container>
                            <Grid
                              m={0}
                              p={0}
                              xs={6}
                              style={{ margin: "0px", padding: "0px" }}
                            >
                              <div
                                id="pay-theory-ach-account-name"
                                style={{
                                  borderBottom: "1px solid grey",
                                  margin: "0px",
                                  padding: "0px",
                                }}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            m={0}
                            p={0}
                            xs={6}
                            style={{ margin: "0px", padding: "0px" }}
                          >
                            {" "}
                            <div
                              id="pay-theory-ach-account-number"
                              style={{
                                borderBottom: "1px solid grey",
                                margin: "0px",
                                padding: "0px",
                              }}
                            />
                          </Grid>
                          <Grid
                            m={0}
                            p={0}
                            xs={6}
                            style={{ margin: "0px", padding: "0px" }}
                          >
                            {" "}
                            <div
                              id="pay-theory-ach-routing-number"
                              style={{
                                borderBottom: "1px solid grey",
                                margin: "0px",
                                padding: "0px",
                              }}
                            />
                          </Grid>
                          <Grid
                            m={0}
                            p={0}
                            xs={7}
                            style={{ margin: "0px", padding: "0px" }}
                          >
                            <div id="pay-theory-ach-account-type" />
                          </Grid>

                          <Grid container>
                            <Grid item xs>
                              <div
                                style={{
                                  fontSize: "18px",
                                  color: "#00aab4",
                                  fontFamily: "Source Sans Pro",
                                }}
                              >
                                Billing Information:
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={validationError.firstName}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="First Name"
                                value={values.firstName}
                                onChange={(e) =>
                                  onChange(e.target.value, "firstName")
                                }
                                name="firstName"
                                id="firstName"
                                helperText={`${
                                  validationError.firstName
                                    ? "Please Enter First Name"
                                    : ""
                                }`}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={validationError.lastName}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Last Name"
                                value={values.lastName}
                                onChange={(e) =>
                                  onChange(e.target.value, "lastName")
                                }
                                name="lastName"
                                id="lastName"
                                helperText={`${
                                  validationError.lastName
                                    ? "Please Enter Last Name"
                                    : ""
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <TextField
                                required
                                error={validationError.email}
                                className={classes.fullInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Email"
                                value={values.email}
                                onChange={(e) =>
                                  onChange(e.target.value, "email")
                                }
                                name="email"
                                id="email"
                                helperText={`${
                                  validationError.email
                                    ? "Please Enter Email"
                                    : ""
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <TextField
                                required
                                error={validationError.address1}
                                className={classes.fullInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Address-Line 1"
                                value={values.address1}
                                onChange={(e) =>
                                  onChange(e.target.value, "address1")
                                }
                                name="address1"
                                id="address1"
                                helperText={`${
                                  validationError.address1
                                    ? "Please Enter Address-Line 1"
                                    : ""
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <TextField
                                className={classes.fullInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Address-Line 2"
                                value={values.address2}
                                onChange={(e) =>
                                  onChange(e.target.value, "address2")
                                }
                                name="address2"
                                id="address2"
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={validationError.city}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="City"
                                value={values.city}
                                onChange={(e) =>
                                  onChange(e.target.value, "city")
                                }
                                name="City"
                                id="city"
                                helperText={`${
                                  validationError.city
                                    ? "Please Enter City"
                                    : ""
                                }`}
                              />
                            </Grid>
                            <Grid item xs={6} />
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={validationError.state}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="State Code"
                                value={values.state}
                                onChange={(e) =>
                                  onChange(e.target.value, "state")
                                }
                                name="state"
                                id="state"
                                helperText={`${
                                  validationError.state
                                    ? "Please Enter State"
                                    : ""
                                }`}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={validationError.zipCode}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Zip Code"
                                value={values.zipCode}
                                onChange={(e) =>
                                  onChange(e.target.value, "zipCode")
                                }
                                name="zipCode"
                                id="zipCode"
                                helperText={`${
                                  validationError.zipCode
                                    ? "Please Enter Zip Code"
                                    : ""
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            style={{
                              marginTop: "5%",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              name="card-button"
                              style={{ fontSize: "18px" }}
                              variant="contained"
                              // disabled={!valid.includes("ach")}
                              className={classes.buttonStyle}
                              color="#696969"
                              size="large"
                              onClick={() => initTransaction("ach")}
                            >
                              {`${
                                paymentMode === "Downpay"
                                  ? "Pay Now"
                                  : "Setup Monthly"
                              }`}{" "}
                              {/* {`${
                            paymentMode === "Downpay"
                              ? props.formData.paymentDetailsData &&
                                props.formData.paymentDetailsData.data.amount
                              : values.amount
                          }`} */}
                            </Button>
                          </Grid>
                        </div>
                      ) : (
                        ""
                      )}
                    </TabPage>
                    <TabPage id={MENU_ITEMS.CASH.tab} visibility="gone">
                      {paymentMethod == "cash" ? (
                        <div>
                          <Grid
                            m={0}
                            p={0}
                            xs={6}
                            style={{ margin: "0px", padding: "0px" }}
                          >
                            <div
                              id="pay-theory-cash-name"
                              style={{
                                borderBottom: "1px solid grey",
                                margin: "0px",
                                padding: "0px",
                              }}
                            />
                          </Grid>
                          <Grid
                            m={0}
                            p={0}
                            xs={6}
                            style={{ margin: "0px", padding: "0px" }}
                          >
                            <div
                              id="pay-theory-cash-contact"
                              style={{
                                borderBottom: "1px solid grey",
                                margin: "0px",
                                padding: "0px",
                              }}
                            />
                          </Grid>

                          <Grid container>
                            <Grid item xs>
                              <div
                                style={{
                                  fontSize: "18px",
                                  color: "#00aab4",
                                  marginTop: "30px",
                                  fontFamily: "Source Sans Pro",
                                }}
                              >
                                Billing Information:
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={validationError.firstName}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="First Name"
                                value={values.firstName}
                                onChange={(e) =>
                                  onChange(e.target.value, "firstName")
                                }
                                name="firstName"
                                id="firstName"
                                helperText={`${
                                  validationError.firstName
                                    ? "Please Enter First Name"
                                    : ""
                                }`}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={validationError.lastName}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Last Name"
                                value={values.lastName}
                                onChange={(e) =>
                                  onChange(e.target.value, "lastName")
                                }
                                name="lastName"
                                id="lastName"
                                helperText={`${
                                  validationError.lastName
                                    ? "Please Enter Last Name"
                                    : ""
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <TextField
                                required
                                error={validationError.email}
                                className={classes.fullInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Email"
                                value={values.email}
                                onChange={(e) =>
                                  onChange(e.target.value, "email")
                                }
                                name="email"
                                id="email"
                                helperText={`${
                                  validationError.email
                                    ? "Please Enter Email"
                                    : ""
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <TextField
                                required
                                error={validationError.address1}
                                className={classes.fullInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Address-Line 1"
                                value={values.address1}
                                onChange={(e) =>
                                  onChange(e.target.value, "address1")
                                }
                                name="address1"
                                id="address1"
                                helperText={`${
                                  validationError.address1
                                    ? "Please Enter Address-Line 1"
                                    : ""
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={12}>
                              <TextField
                                className={classes.fullInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Address-Line 2"
                                value={values.address2}
                                onChange={(e) =>
                                  onChange(e.target.value, "address2")
                                }
                                name="address2"
                                id="address2"
                              />
                            </Grid>
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={validationError.city}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="City"
                                value={values.city}
                                onChange={(e) =>
                                  onChange(e.target.value, "city")
                                }
                                name="City"
                                id="city"
                                helperText={`${
                                  validationError.city
                                    ? "Please Enter City"
                                    : ""
                                }`}
                              />
                            </Grid>
                            <Grid item xs={6} />
                          </Grid>
                          <Grid container>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={validationError.state}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="State Code"
                                value={values.state}
                                onChange={(e) =>
                                  onChange(e.target.value, "state")
                                }
                                name="state"
                                id="state"
                                helperText={`${
                                  validationError.state
                                    ? "Please Enter State"
                                    : ""
                                }`}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                required
                                error={validationError.zipCode}
                                className={classes.halfInput}
                                InputLabelProps={{
                                  classes: {
                                    root: classes.activeInputLableColor,
                                    focused: "focused",
                                  },
                                }}
                                InputProps={{
                                  color: "secondary",
                                  classes: {
                                    root: classes.activeInputColor,
                                    focused: "focused",
                                    input: classes.resize,
                                  },
                                  required: true,
                                }}
                                label="Zip Code"
                                value={values.zipCode}
                                onChange={(e) =>
                                  onChange(e.target.value, "zipCode")
                                }
                                name="zipCode"
                                id="zipCode"
                                helperText={`${
                                  validationError.zipCode
                                    ? "Please Enter Zip Code"
                                    : ""
                                }`}
                              />
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            style={{
                              marginTop: "5%",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              style={{ fontSize: "18px" }}
                              variant="contained"
                              disabled={!valid.includes("ach")}
                              className={classes.buttonStyle}
                              color="#696969"
                              size="large"
                              onClick={() => initTransaction("ach")}
                            >
                              {`${
                                paymentMode === "Downpay"
                                  ? "Pay Now"
                                  : "Setup Monthly"
                              }`}{" "}
                              {/* {`${
                              paymentMode === "Downpay"
                                ? props.formData.paymentDetailsData &&
                                  props.formData.paymentDetailsData.data.amount
                                : values.amount
                            }`} */}
                            </Button>
                          </Grid>
                          <div
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                          >
                            <button
                              name="cash-button"
                              onClick={initTransaction("cash")}
                              disabled={!valid.includes("cash")}
                            >
                              Generate Barcode
                            </button>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </TabPage>
                  </Grid>
                </Grid>
              </div>
              <BarcodeResult barcode={barcode} amount={values.amount} />
              {statusMessage}
              <style jsx="true" global="true">
                {`
                  .checkout-options {
                    display: flex;
                  }
                  .fields {
                    margin: 0px 8px;
                    transition: all 0.5s;
                    overflow: hidden;
                    opacity: 1;
                  }

                  .fields.hide {
                    margin: 0px;
                    width: 0px;
                    opacity: 0;
                    transition: all 0.5s;
                  }
                  .input-field {
                    border: 1px solid var(--grey-1);
                    border-radius: 8px;
                    height: 46px;
                    padding: 0px 0px 0px 16px;
                    margin: 8px 0px;
                  }

                  #pay-theory-credit-card-number {
                    fontsize: 14px;
                    height: 46px;
                    padding: 0px 0px 0px 0px;
                    margin: 0px 0px 0px 0px;
                    borderbottom: 1px solid black;
                  }
                  #pay-theory-credit-card-exp {
                    height: 46px;
                    padding: 0px 0px 0px 0px;
                    margin: 0px 0px 0px 0px;
                    width: 100% !important;
                  }
                  #pay-theory-credit-card-cvv {
                    height: 46px;
                    padding: 0px 0px 0px 0px;
                    margin: 0px 0px 0px 0px;
                    width: 100%;
                  }
                  #pay-theory-credit-card-zip {
                    height: 46px;
                    margin: 0px;
                    width: 100%;
                  }
                  #card-exp-hosted-field {
                    height: 46px;
                    margin: 0px 0px;
                  }
                  .pt-hosted-field {
                    width: 100%;
                  }
                  #pay-theory-ach-account-name {
                    height: 46px;
                    margin: 0px 0px;
                    width: 87%;
                  }
                  #pay-theory-ach-account-number {
                    height: 46px;
                    margin: 0px 0px;
                    width: 87%;
                  }
                  #pay-theory-ach-routing-number {
                    height: 46px;
                    margin: 0px 0px;
                    width: 87%;
                  }
                  #pay-theory-ach-account-type {
                    height: 46px;
                    margin: 0px 0px;
                    width: 87%;
                  }
                  #pay-theory-cash-name {
                    height: 46px;
                    margin: 0px 0px;
                    width: 87%;
                  }
                  #pay-theory-cash-contact {
                    height: 46px;
                    margin: 0px 0px;
                    width: 87%;
                  }
                `}
              </style>
            </>
          )}
          {handleConfirmation && (
            <PaymentConfirmation
              paymentMode={paymentMode}
              values={values}
              result={result}
              setPaymentStatus={() => setPaymentStatus()}
              setShowPaymentDetails={() => setShowPaymentDetails(true)}
              setConfirmation={() => setConfirmation(false)}
              {...props}
            />
          )}
          {handleFail && (
            <PaymentErrorMessage
              values={values}
              setRetryPayment={() => setRetryPayment()}
              setShowPaymentDetails={() => setShowPaymentDetails(true)}
              setHandleFail={() => setHandleFail(false)}
              {...props}
            />
          )}
        </div>
      </Card>
    </div>
  );
};

CheckoutOptions.propTypes = {
  patientMakePayment: PropTypes.func,
  beforePaymentSendOTP: PropTypes.func,
  formData: PropTypes.object,
  history: PropTypes.object,
  state: PropTypes.func,
  emptyGraphData: PropTypes.func,
  setUpMonthlyOffer: PropTypes.func,
  patientDashboard: PropTypes.object,
  paymentTokenCheckout: PropTypes.func,
  app: PropTypes.object,
};

const mapStateToProps = (app) => ({
  formData: app.genrateOfferPatient,
  patientDashboard: app.patientDashboard,
  app: app.app,
});

export default connect(mapStateToProps, {
  patientMakePayment,
  beforePaymentSendOTP,
  emptyGraphData,
  setUpMonthlyOffer,
  paymentTokenCheckout,
})(CheckoutOptions);
