import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { Link } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import Paper from "@material-ui/core/Paper";
import Icon from "@material-ui/core/Icon";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import TableContainer from "@material-ui/core/TableContainer";
import Divider from "@material-ui/core/Divider";
import {
  genrateTokenAltpay,
  createInvoiceAltPay,
} from "../../actions/altPay.action";
import Style from "./style";
import { setCheckoutDataPayment } from "../../Redux/Actions/PaymentAction";
import "./cart.css";
import LenovoTabletImage from "../../assets/tempImages/lenovo-tablet.jpg";

import GooglePixelImage from "../../assets/tempImages/google-pixel.jpg";
import WillfulSmartWatchImage from "../../assets/tempImages/willful-smartwatch.jpg";
import AmazfitSmartWatchImage from "../../assets/tempImages/amazfit-smartwatch.jpg";
import BitcoinSvg from "../../assets/cryptoSvg/bitcoin-btc-logo.svg";
import LitecoinSvg from "../../assets/cryptoSvg/litecoin-ltc-logo.svg";
import DogecoinSvg from "../../assets/cryptoSvg/dogecoin-doge-logo.svg";
import EthereumSvg from "../../assets/cryptoSvg/ethereum-eth-logo.svg";
import BitcoinCashSvg from "../../assets/cryptoSvg/bitcoin-cash-bch-logo.svg";

const useStyles = makeStyles(Style);
const dummyProducts = [
  {
    qa: 10,
    totalRating: 67932,
    id: "123452",
    image: WillfulSmartWatchImage,
    title:
      "Willful Smart Watch for Android Phones and iOS Phones Compatible iPhone Samsung, IP68 Swimming Waterproof Smartwatch Fitness Tracker Fitness Watch Heart Rate Monitor Smart Watches for Men Women Black",
    rating: 4,
    price: 35.99,
    productURL: "#",
    shippingLocation: "United States of America",
  },
  {
    qa: 15,
    totalRating: 24533,
    id: "123454",
    image: GooglePixelImage,
    title:
      "Google Pixel 4a - New Unlocked Android Smartphone - 128 GB of Storage - Up to 24 Hour Battery - Just Black",
    rating: 4,
    price: 53.0,
    productURL: "#",
    shippingLocation: "United States of America",
  },
  {
    qa: 12,
    totalRating: 200,
    id: "123456",
    image: LenovoTabletImage,
    title:
      'Lenovo Tab M8 Tablet, 8" HD Android Tablet, Quad-Core Processor, 2GHz, 16GB Storage, Full Metal Cover, Long Battery Life, Android 9 Pie, ZA5G0102US, Slate Black',
    rating: 4,
    price: 89.99,
    productURL: "#",
    shippingLocation: "United States of America",
  },
  {
    qa: 29,
    totalRating: 57563,
    id: "123457",
    image: AmazfitSmartWatchImage,
    title:
      'Amazfit Bip U Pro Smart Watch with Built-in GPS, 9-Day Battery Life, Fitness Tracker, Blood Oxygen, Heart Rate, Sleep, Stress Monitor, 60+ Sports Modes, 1.43" Large HD Display',
    rating: 4,
    price: 69.99,
    productURL: "#",
    shippingLocation: "United States of America",
  },
];

function OrderCart(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPayButton, setSelectedPayButton] = React.useState(false);

  const formatRemainingTime = (time) => {
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;

    return `${minutes}:${seconds}`;
  };

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">Too late...</div>;
    }

    return (
      <div className="timer">
        {/* <div className="text">Remaining</div> */}
        <div className="value">{formatRemainingTime(remainingTime)}</div>
        {/* <div className="text">seconds</div> */}
      </div>
    );
  };

  function amountTotal() {
    let total = 0;
    dummyProducts.map((x) => {
      total += x.price;
    });
    return (Math.round(total * 100) / 100).toFixed(2); // return sum of elements in array
  }

  React.useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://apps.coinsuites.com/widget/coinsuites.js?v4";
    script.async = true;

    document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);

  const handlePayNowButton = () => {
    props.genrateTokenAltpay(
      "",
      (data) => {
        if (data.status) {
          let currency = "";
          if (selectedPayButton === 1) {
            currency = "btc";
          }
          if (selectedPayButton === 2) {
            currency = "eth";
          }
          if (selectedPayButton === 3) {
            currency = "doge";
          }
          if (selectedPayButton === 4) {
            currency = "ltc";
          }
          if (selectedPayButton === 5) {
            currency = "bch";
          }
          const payload = {
            header: {
              token: data.data,
            },
            body: {
              email: "john@gmail.com",
              firstName: "John",
              lastName: "kristy",
              company: "xyz inc.",
              address: "South Street Seaport, 256 Fulton St",
              city: "New York",
              prov_state: "NY",
              country: "USA",
              postal_zip: "10038",
              phone: "8475965821",
              total_amount: "4.00",
              Ref_id: "454545sds",
              url: "https://onboardingdev.coinsuites.com/api/webhook",
              currency: "USD",
              AssetName: currency,
            },
          };
          props.createInvoiceAltPay(
            payload,
            (data) => {
              props.setCheckoutDataPayment(data);
              if (data.status === "success") {
                props.history.push("/admin/Payments");
              } else {
                enqueueSnackbar(`${data.message}`, {
                  variant: "error",
                });
              }
            },
            (err) => {
              enqueueSnackbar(`${err.message}`, {
                variant: "error",
              });
            }
          );
        } else {
          enqueueSnackbar(`${data.message}`, {
            variant: "error",
          });
        }
      },
      (err) => {
        enqueueSnackbar(`${err.message}`, {
          variant: "error",
        });
      }
    );
  };

  React.useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://apps.coinsuites.com/widget/coinsuites.js?v4";
    script.async = true;

    document.body.appendChild(script);
  }, []);

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        <div className={classes.checkoutMain}>
          <div style={{ width: "50%" }}>
            <div className="cart-item">
              <div className="cart-item-header">
                <div className={classes.logoHeading}>
                  <h3 style={{ marginLeft: "15px" }}>TradingView</h3>
                </div>
                <div className="cart-item-info">
                  <button
                    type="button"
                    className="cart-item-btn"
                    // onClick={handleRemoveAllFromCart}
                  >
                    Deselect all items
                  </button>
                  <div className="cart-price">Price</div>
                </div>
              </div>
              <div>
                {dummyProducts.map((item) => (
                  <div className="cart-product">
                    <div>
                      <Link className="cart-product-link" to="/">
                        <img
                          // className="cart-product-img"
                          style={{
                            maxHeight: "150px",
                            maxWidth: "150px",
                            backgroundSize: "contain",
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                          src={item.image}
                          alt=""
                        />
                      </Link>
                      <div className="cart-product-details">
                        <div className="title-wrap">
                          <p>
                            <Link style={{ fontSize: "14px" }} to="#">
                              {item.title}
                            </Link>
                          </p>
                          <strong className="cart-product-price">
                            {`$${item.price}`}
                          </strong>
                        </div>
                        <>
                          <div className="stock-status">In stock</div>
                          <div className="cart-product-btn-wrap">
                            <select id="itemNumber" name="itemNumber">
                              <option value={1}>1</option>
                              <option value={2}>2</option>
                              <option value={3}>3</option>
                              <option value={4}>4</option>
                              <option value={5}>5</option>
                            </select>
                            <button type="button">Delete</button>
                            <button type="button">Save for Later</button>
                          </div>
                        </>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="cart-subtotal">
                <p>
                  {`Subtotal (${dummyProducts.length} `}
                  {dummyProducts.length >= 2 ? "items" : "item"}
                  ): <strong>{`$${amountTotal()}`}</strong>
                </p>
              </div>
            </div>
          </div>
          <div style={{ width: "50%" }}>
            <div className="cart-item">
              <Paper style={{ marginTop: "4%" }} variant="outlined">
                <Grid style={{ margin: "4%" }} container>
                  <Grid
                    xs={11}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                    item
                    container
                  >
                    <Grid xs={9} item>
                      <Typography
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        Payment Method
                      </Typography>
                    </Grid>
                    <Grid
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                      item
                    >
                      <div>
                        <div className="timer-wrapper">
                          <CountdownCircleTimer
                            isPlaying
                            size={75}
                            strokeWidth={5}
                            trailStrokeWidth={5}
                            duration={900}
                            colors={[
                              ["#00acc1", 0.33],
                              ["#F7B801", 0.33],
                              ["#A30000"],
                            ]}
                            onComplete={() => [false]}
                          >
                            {renderTime}
                          </CountdownCircleTimer>
                        </div>
                      </div>
                    </Grid>
                  </Grid>

                  <Grid
                    xs={12}
                    style={{
                      display: "flex",
                      marginLeft: "-4%",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginTop: "4%",
                      marginBottom: "4%",
                    }}
                    item
                    container
                  >
                    <Grid xs={12} item>
                      <Divider />
                    </Grid>
                  </Grid>

                  <Grid
                    xs={11}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "1%",
                    }}
                    item
                    container
                  >
                    <Grid xs={9} item>
                      <Typography
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                          fontWeight: "bold",
                        }}
                      >
                        Total
                      </Typography>
                    </Grid>
                    <Grid
                      xs={3}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                      item
                    >
                      <div>
                        <div className="timer-wrapper">
                          <Typography>
                            <strong>{`$${amountTotal()}`}</strong>
                          </Typography>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={10}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "2%",
                    }}
                    item
                    container
                  >
                    <Grid xs={12} item>
                      <Typography
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                      >
                        Express checkout
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={10}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "3%",
                      marginLeft: "3%",
                    }}
                    item
                    container
                  >
                    <Grid xs={12} item>
                      <Typography
                        style={{
                          display: "flex",
                          flexWrap: "nowrap",
                        }}
                      >
                        select a cryptocurrency to pay with
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={10}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      marginBottom: "2%",
                      marginLeft: "3%",
                    }}
                    item
                    spacing={1}
                    rowSpacing={1}
                    container
                  >
                    <Grid item>
                      <Button
                        variant={
                          selectedPayButton === 1 ? "contained" : "outlined"
                        }
                        onClick={() => setSelectedPayButton(1)}
                        fullWidth
                        style={{ textTransform: "capitalize" }}
                        endIcon={
                          <Typography
                            style={{ color: "#888888", fontSize: "inherit" }}
                          >
                            BTC
                          </Typography>
                        }
                        startIcon={
                          <Icon>
                            <img
                              style={{ display: "flex" }}
                              alt="edit"
                              src={BitcoinSvg}
                            />
                          </Icon>
                        }
                      >
                        Bitcoin
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={
                          selectedPayButton === 2 ? "contained" : "outlined"
                        }
                        onClick={() => setSelectedPayButton(2)}
                        fullWidth
                        style={{ textTransform: "capitalize" }}
                        endIcon={
                          <Typography
                            style={{ color: "#888888", fontSize: "inherit" }}
                          >
                            ETH
                          </Typography>
                        }
                        startIcon={
                          <Icon>
                            <img
                              style={{ display: "flex", width: "12px" }}
                              alt="edit"
                              src={EthereumSvg}
                            />
                          </Icon>
                        }
                      >
                        Ethereum
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={
                          selectedPayButton === 3 ? "contained" : "outlined"
                        }
                        onClick={() => setSelectedPayButton(3)}
                        fullWidth
                        style={{ textTransform: "capitalize" }}
                        endIcon={
                          <Typography
                            style={{ color: "#888888", fontSize: "inherit" }}
                          >
                            DOGE
                          </Typography>
                        }
                        startIcon={
                          <Icon>
                            <img
                              style={{ display: "flex" }}
                              alt="edit"
                              src={DogecoinSvg}
                            />
                          </Icon>
                        }
                      >
                        Dogecoin
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={
                          selectedPayButton === 4 ? "contained" : "outlined"
                        }
                        onClick={() => setSelectedPayButton(4)}
                        fullWidth
                        style={{ textTransform: "capitalize" }}
                        endIcon={
                          <Typography
                            style={{ color: "#888888", fontSize: "inherit" }}
                          >
                            LTC
                          </Typography>
                        }
                        startIcon={
                          <Icon>
                            <img
                              style={{ display: "flex" }}
                              alt="edit"
                              src={LitecoinSvg}
                            />
                          </Icon>
                        }
                      >
                        Litecoin
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant={
                          selectedPayButton === 5 ? "contained" : "outlined"
                        }
                        onClick={() => setSelectedPayButton(5)}
                        fullWidth
                        style={{ textTransform: "capitalize" }}
                        endIcon={
                          <Typography
                            style={{ color: "#888888", fontSize: "inherit" }}
                          >
                            BCH
                          </Typography>
                        }
                        startIcon={
                          <Icon>
                            <img
                              style={{ display: "flex" }}
                              alt="edit"
                              src={BitcoinCashSvg}
                            />
                          </Icon>
                        }
                      >
                        Bitcoin Cash
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    xs={11}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignContent: "center",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                    item
                    container
                  >
                    <div
                      coinsuites="Paywidget"
                      coinsuites_id="4a007183-4737-4aaf-9617-e60515f31f3e"
                      amount="36.00"
                      currency="USD"
                      ref_id="SB83347"
                      className="paydevpaybtn"
                    />

                    {/* <Button
                      style={{ fontSize: "14px" }}
                      variant={!selectedPayButton ? "outlined" : "contained"}
                      disabled={!selectedPayButton}
                      onClick={() => handlePayNowButton("hello")}
                    >
                      Pay Now
                    </Button> */}
                  </Grid>
                </Grid>
              </Paper>
            </div>
          </div>
        </div>
      </TableContainer>
    </>
  );
}

OrderCart.propTypes = {
  genrateTokenAltpay: PropTypes.func,
  createInvoiceAltPay: PropTypes.func,
  setCheckoutDataPayment: PropTypes.func,
  history: PropTypes.object,
};

const mapStateToProps = (app) => ({
  tableData: app.users,
  userDetails: app.app,
});
export default connect(mapStateToProps, {
  genrateTokenAltpay,
  createInvoiceAltPay,
  setCheckoutDataPayment,
})(OrderCart);
