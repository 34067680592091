/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import MultipleSelect from "../MultiSelect/Index";

const PurpleSwitch = withStyles((theme) => ({
  switchBase: {
    color: "#fff",
    "&$checked": {
      color: "#fff",
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
}))(Switch);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RoleMenuSub(props) {
  const [switchValue, setSwitch] = React.useState(props.data.Permission);

  const handleSwicth = (value) => {
    const values = { ...value };
    values.Permission = !switchValue;
    values.Options.forEach((v, i) => {
      values.Options[i].permission = !switchValue;
    });
    setSwitch(!switchValue);
    props.onChangeSelect(values);
  };

  const onChangeSelectValue = (data) => {
    const updateArray = props.data;
    updateArray.Options.forEach((v) => {
      if (data.length) {
        if (!data.filter((i) => i === v.name).length) {
          v.permission = false;
        } else {
          v.permission = true;
        }
      }
    });
    props.onChangeSelect(updateArray);
  };

  return (
    <>
      <Grid
        item
        xs={4}
        sm={2}
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
        }}
      >
        <div>{props.data.name}</div>
        <PurpleSwitch
          checked={switchValue}
          onChange={() => handleSwicth(props.data)}
          name="checkedA"
        />
      </Grid>
      {switchValue && (
        <Grid item xs={4}>
          <MultipleSelect
            data={props.data}
            onChangeSelect={(item) => onChangeSelectValue(item)}
          />
        </Grid>
      )}
    </>
  );
}

RoleMenuSub.propTypes = {
  data: PropTypes.any,
  onChangeSelect: PropTypes.func,
};

export default connect(null, null)(RoleMenuSub);
