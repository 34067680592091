/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Collapse from "@material-ui/core/Collapse";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import isEmpty from "lodash/isEmpty";
import ListItemText from "@material-ui/core/ListItemText";

import styles from "assets/jss/material-dashboard-react/components/sidebarStyle";
import { iconArray } from "../../constant/IconArray/Isons";
import logoBgWhite from "../../assets/newImg/tpo_white_new.png";

const useStyles = makeStyles(styles);

export default function Sidebar(props) {
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [collebleState, setCollebleState] = React.useState("");
  const [logoPath, setLogoPath] = React.useState("");
  const classes = useStyles();
  const profileTypeCheck =
    props.userRole.userList &&
    props.userRole.userList.Role_Permission_Data &&
    props.userRole.userList.Role_Permission_Data.Profile_Code;

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: "rgba(35, 49, 86, 0.8)"
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  let name = "";
  if (window.location.pathname.includes("admin")) {
    name =
      props.userRole.userList &&
      props.userRole.userList.Role_Permission_Data &&
      props.userRole.userList.Role_Permission_Data.UserName;
  } else if (window.location.pathname.includes("client")) {
    name = "Client";
  } else if (window.location.pathname.includes("cfo")) {
    name = "CFO";
  } else if (window.location.pathname.includes("frontDesk")) {
    name = "Front Desk";
  } else if (window.location.pathname.includes("patient")) {
    name =
      props.userRole.userList &&
      props.userRole.userList.Role_Permission_Data &&
      props.userRole.userList.Role_Permission_Data.UserName;
  }

  const handleOnClick = (item) => {
    if (collebleState === item) {
      setCollebleState("");
    } else {
      setCollebleState(item);
    }
  };

  const userOfferPath = window.location.pathname
    .split("/")
    .splice(2, 3)
    .join("/");
  const changeReportRoute = (name, displayName, Category_Name = "") => {
    props.setState();
    if (name.includes("Users")) {
      props.changeUserFormMode("init");
      props.changeRoleFormMode("init");
    }
    const barName = Category_Name
      ? `${Category_Name} / ${displayName}`
      : displayName;
    props.setAppBarName(barName);
    props.emptyPatientSelect();
    props.history.push(
      `/${props.userDetails.layout}/${name.replace(/\s/g, "")}`
    );
    if (!windowWidth) {
      props.setopen();
    }
  };
  const { color, image } = props;

  let listItemClasses;
  listItemClasses = classNames({
    [` ${classes[color]}`]: true
  });
  const whiteFontClasses = classNames({
    [` ${classes.whiteFont}`]: true
  });

  const links = (
    <List className={classes.list}>
      {props.userRole.userList &&
        props.userRole.userList.Role_Permission_Data &&
        props.userRole.userList.Role_Permission_Data.LeftMenu.map((v, i) => {
          if (v.Permission) {
            const icon = iconArray.filter(
              (iconItem) => iconItem.name === v.Menu_Display_Name
            );
            const IconShow = icon && icon[0].icon;
            return (
              <>
                <ListItem
                  key={i}
                  button
                  className={`${
                    props.userDetails.appBarName === v.Menu_Display_Name
                      ? classes.itemLink + listItemClasses
                      : classes.itemLink
                  }`}
                  onClick={() =>
                    v.Page_Menu
                      ? handleOnClick(i)
                      : changeReportRoute(
                          v.Menu_Display_Name,
                          v.Menu_Display_Name
                        )
                  }
                >
                  <IconShow
                    className={classNames(classes.itemIcon, whiteFontClasses)}
                  />
                  {/* <img style={{ color :"white",}} src={require("../../assets/img/reports.png")}/> */}
                  {(props.open || props.hoverDrawer) && (
                    <ListItemText
                      className={classNames(classes.itemText, whiteFontClasses)}
                      primary={v.Menu_Display_Name}
                      disableTypography
                    />
                  )}
                  {v.Page_Menu &&
                    (props.open || props.hoverDrawer) &&
                    (collebleState === i ? (
                      <ArrowDropUpIcon style={{ color: "#ffff" }} />
                    ) : (
                      <ArrowDropDownIcon style={{ color: "#ffff" }} />
                    ))}
                </ListItem>
                <Collapse
                  className={
                    props.hoverDrawer
                      ? classes.drawerClasses
                      : classes.hideDrawerClasses
                  }
                  in={collebleState === i}
                  timeout="auto"
                  unmountOnExit
                >
                  {v.IsPageMenu &&
                    v.Page_Menu.map((item, j) => {
                      if (item.Permission) {
                        return (
                          <ListItem
                            key={j}
                            button
                            style={{ height: "auto" }}
                            className={`${
                              userOfferPath ===
                              item.Menu_Display_Name.split(" ").join("")
                                ? classes.sideBar
                                : classes.itemLinkDrawer
                            }`}
                            onClick={() =>
                              changeReportRoute(
                                item.Page_Name,
                                item.Menu_Display_Name,
                                v.Category_Name
                              )
                            }
                          >
                            {(props.open || props.hoverDrawer) && (
                              <ListItemText
                                // style={{ paddingLeft: "0px" }}
                                className={classNames(
                                  classes.itemTextSubMessage,
                                  whiteFontClasses
                                )}
                                // style={{ float: "left" }}
                                primary={item.Menu_Display_Name}
                                disableTypography
                              />
                            )}
                          </ListItem>
                        );
                      }
                    })}
                </Collapse>
              </>
            );
          }
        })}
    </List>
  );
  const openDrawerOnHover = () => {
    setTimeout(() => {
      if (!props.hoverDrawer) {
        props.setOpenDrawerHover(true);
      }
    }, 200);
  };
  const closeDrawerOnHover = () => {
    if (props.hoverDrawer) {
      props.setOpenDrawerHover(false);
    }
  };
  if (isEmpty(logoPath)) {
    const logo_path = new Image();
    if (props.app.app.portalConfig) {
      const x = props.app.app.portalConfig.data[0]?.Logo_Image;
      logo_path.src = `${x}?" + ${new Date().getTime()}`;
      logo_path.onload = function () {
        setLogoPath(logo_path.src);
      };
      logo_path.onerror = function () {
        const localImage = require("assets/newImg/TPO_logo_temp.png");
        setLogoPath(localImage);
      };
    }
  }

  const brand = (
    <div className={classes.logo}>
      <img
        src={"/images/tpo-logo-white.png"}
        // src={logoPath || "assets/newImg/TPO_logo_temp.png"}
        className={classes.logoWidth}
      />
    </div>
  );
  const openBrand = (
    <div className={classes.smallLogo}>
      {/* <img
        src={logoBgWhite}
        // src={logoPath || "assets/newImg/TPO_logo_temp.png"}
        style={{ objectFit: "contain", maxHeight: "65px", maxWidth: "65px" }}
        className={classes.logoWidth}
      /> */}
    </div>
  );

  return (
    <div
      onMouseEnter={() => (windowWidth ? openDrawerOnHover() : {})}
      onMouseLeave={() => (windowWidth ? closeDrawerOnHover() : {})}
    >
      <Drawer
        PaperProps={{
          sx: { width: "90%" }
        }}
        variant={windowWidth ? "permanent" : "presentation"}
        onClose={() => props.setopen()}
        open={props.open || props.hoverDrawer}
        classes={{
          paper: classNames(
            props.open || props.hoverDrawer
              ? windowWidth
                ? classes.drawerPaperOpen
                : ""
              : classes.drawerPaper
          )
        }}
      >
        {props.open || props.hoverDrawer ? brand : openBrand}
        {/* <hr className={classes.hrStyle} />
        <div className={classes.profileName}>
          <Avatar className={classes.avtarColor} />
          {(props.open || props.hoverDrawer) && (
            <div className={classes.userName}>{name}</div>
          )}
        </div>
        <hr className={classes.hrStyle} /> */}
        <Scrollbars
          thumbSize={0}
          hideTracksWhenNotNeeded
          style={{ overflow: "hidden", margin: "0px" }}
          autoHide
        >
          <div>{links}</div>
        </Scrollbars>
      </Drawer>
    </div>
  );
}

Sidebar.propTypes = {
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  setOpenDrawerHover: PropTypes.func,
  hoverDrawer: PropTypes.bool,
  open: PropTypes.bool,
  setState: PropTypes.func,
  emptyPatientSelect: PropTypes.func,
  setopen: PropTypes.func,
  userRole: PropTypes.object
};
