/* eslint-disable no-debugger */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Card from "components/Card/Card";
import { Typography } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import noDataImage from "../../assets/img/noDataImage.png";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import {
  getAxiosAPI,
  SortData,
  formatter
} from "../../actions/AxiosAPIMethods";
const useStyles = makeStyles(tableStyle);

function createData(
  insurnaceProvider,
  providerId,
  offerId,
  startDate,
  amountFonded,
  discountAmountFonded,
  discount,
  offerType,
  status,
  offerNumber
) {
  return {
    insurnaceProvider,
    providerId,
    offerId,
    startDate,
    amountFonded,
    discountAmountFonded,
    discount,
    offerType,
    status,
    offerNumber,
  };
}

const headCells = [
  {
    id: "calories",
    numeric: true,
    disablePadding: true,
    label: "Offer ID",
  },
  {
    id: "type",
    numeric: true,
    disablePadding: true,
    label: "Offer Type",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Insurance Carrier",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Provider",
  },
  {
    id: "start",
    numeric: false,
    disablePadding: true,
    label: "Start Date",
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Amount Due",
  },
  {
    id: "amt",
    numeric: true,
    disablePadding: false,
    label: "Amount Funded",
  },
  {
    id: "dis",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const { classes } = props;

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map(headCell => (
          <TableCell
            key={Math.random()}
            className={classes.cellTextHeaderOffer}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
};

function CompletedPlans(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");

  const rows = [];
  props.tableData.completedPlanPatientList.data.forEach(key => {
    const offerId = key.offerId;
    const insurnaceProvider = key.insuranceProvider;
    const providerId = key.providerId;
    const startDate = key.transactiontDate;
    const amountFonded = key.totalAmountfunded;
    const discountAmountFonded = key.fullPaymentfunded;
    const discount = key.apr;
    const offerType = key.offerType;
    const status = key.status;
    const offerNumber = key.offerNumber;
    rows.push(
      createData(
        insurnaceProvider,
        providerId,
        offerId,
        startDate,
        amountFonded,
        discountAmountFonded,
        discount,
        offerType,
        status,
        offerNumber
      )
    );
  });

  return (
    <>
      <Grid container style={{ marginTop: "1%", marginBottom: "1%" }}>
        {/* <Grid item xs style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <Typography>
            Completed Plans (
            {props.tableData.completedPlanPatientList.data.length}
            ):
          </Typography>
        </Grid> */}
        <Grid item xs />
        <Grid item xs />
        <Grid item xs />
        <Grid item xs />
      </Grid>
      <Card
        style={{
          marginTop: "0px",
          // borderTop: "2px solid #0d1619",
        }}
      >
        <TableContainer style={{ maxHeight: "34rem" }}>
          <Table
            className={classes.patientTable}
            aria-labelledby="tableTitle"
            aria-label="enhanced table"
            // stickyHeader
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={0}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={() => {}}
              onRequestSort={() => {}}
              rowCount={rows.length}
              disableAction={props.disableAction}
            />
            <TableBody>
              {!isEmpty(rows) ? (
                rows.map((row, index) => (
                  <TableRow hover role="checkbox" key={Math.random()}>
                    <TableCell
                      className={classes.patientNameColor}
                      style={{ cursor: "pointer" }}
                      align="left"
                    >
                      {row.offerNumber}
                    </TableCell>
                    <TableCell className={classes.cellText} align="left">
                      {row.offerType}
                      {" "}
                      (
                      {row.discount}
                      %)
                    </TableCell>
                    <TableCell className={classes.cellText} align="left">
                      {row.insurnaceProvider}
                    </TableCell>
                    <TableCell className={classes.cellText} align="left">
                      {row.providerId}
                    </TableCell>
                    <TableCell className={classes.cellText} align="left">
                      {row.startDate}
                    </TableCell>
                    <TableCell className={classes.cellText} align="left">
                      {/* {row.amountFonded} */}
                      {formatter.format(row.amountFonded)}
                    </TableCell>
                    <TableCell className={classes.cellText} align="left">
                      {formatter.format(row.discountAmountFonded)}
                    </TableCell>
                    <TableCell className={classes.cellText} align="left">
                      {row.status}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={headCells.length + 1}>
                    <img src={noDataImage} alt="" className={classes.noDataImage} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
    </>
  );
}

CompletedPlans.propTypes = {
  disableAction: PropTypes.bool,
  tableData: PropTypes.object,
};

export default CompletedPlans;
