import { makeStyles } from "@material-ui/core/styles";
import { StyleVariables } from "./StyleVariable";

/**
 * Material UI framework styling object
 * @type {StylesHook<Styles<Theme, {}, string>>}
 */
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
    maxWidth: "100%",
    height: "100vh",
  },
  rootNew: {
    height: "100vh",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: theme.spacing(0),
    background: "#1d2136",
    maxWidth: "100%",
  },
  paperNew: {
    marginTop: theme.spacing(0),
    flexDirection: "row",
    alignItems: "center",
    // height: "100%",
    width: "100%",
  },

  paperNewM: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "auto",
    width: "85%",
    borderRadius: "12px",
  },
  paper: {
    marginTop: theme.spacing(0),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: StyleVariables.fullHeight,
  },
  form: {
    width: StyleVariables.fullWidth, // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  linkItem: {
    color: StyleVariables.mutedColor,
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  spaceTop: {
    marginTop: theme.spacing(1),
  },
  spaceTop2: {
    marginTop: theme.spacing(2),
  },
  spaceTop3: {
    marginTop: theme.spacing(3),
  },
  spaceBottom: {
    marginBottom: theme.spacing(1),
  },
  spaceBottom2: {
    marginBottom: theme.spacing(2),
  },
  loginFont: {
    fontFamily: "Source Sans Pro",
  },

  loginBackButton: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },

  brandLogo: {
    maxWidth: "200px",
    alignItems: "center",
    justifyContent: "center",
    width: StyleVariables.fullWidth,
    display: "flex",
    color: StyleVariables.whiteColor,
  },

  brandTag: {
    alignItems: "center",
    justifyContent: "center",
    width: StyleVariables.fullWidth,
    color: StyleVariables.whiteColor,
    "& h6": {
      fontWeight: "300",
    },
  },
  panelWidth: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },

  forgotButton: {
    marginBottom: "4%",
  },
  authInnerForm: {
    // textAlign: StyleVariables.alignCenter,
    margin: StyleVariables.alignAuto,
    width: "90%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      width: "90%",
    },
    [theme.breakpoints.up("xl")]: {
      width: "80%",
    },
  },
  adminCard: {
    height: "30vh",
  },
  tilesCard: {
    height: "100%",
    borderRadius: "4px",
    position: StyleVariables.relative,
    zIndex: "1",
    "&:after": {
      position: StyleVariables.absolute,
      height: StyleVariables.fullHeight,
      width: StyleVariables.fullWidth,
      top: StyleVariables.spacingZero,
      bottom: StyleVariables.spacingZero,
      left: StyleVariables.spacingZero,
      right: StyleVariables.spacingZero,
      zIndex: "-1",
    },
  },
  patientCard: {
    height: "30vh",
  },
  backgroundImg: {
    // backgroundImage: `url(${"/images/login_bg.png"})`,
    // backgroundColor: "#3b4b98",
    background:  `no-repeat center center fixed linear-gradient( rgba(62, 78, 155, 0.9), rgba(62, 78, 155, 0.9)), url("/images/login_bg.png")`,
    minHeight: "100vh",
    position: "relative",
    webkitBackgroundSize: "cover",
  mozBackgroundSize: "cover",
  oBackgroundSize: "cover",
  backgroundSize: "cover",
  },
}));


export default useStyles;
