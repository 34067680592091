import React, { useState, useEffect } from "react";
import { Container, Typography, Grid, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import { postAxiosAPI, getAxiosAPI } from "../../actions/AxiosAPIMethods";
import { useParams } from "react-router-dom";
import axios from "axios";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { createOfferCall } from "Redux/Actions/genrateOfferPatient";
import {
  getGenrateOfferData,
  patientSearch,
  patientPaymentDetailsCall,
  setEmailOffer,
} from "Redux/Actions/genrateOfferPatient";
import { Link } from "react-router-dom";

const TPO = (props) => {
  const classes = useStyles();
  const params = useParams();
  const [showAllOffers, setShowAllOffers] = React.useState(false);
  const [loader, setLoader] = useState(true);
  const [posts, setPosts] = useState([]);
  const [paymentDone, setPaymentDone] = useState();
  const [patientEmail, setPatientEmail] = React.useState(false);
  const [pageTypePrint, setPageTypePrint] = React.useState(false);
  const [showOfferDEtails, setShowOfferDEtails] = React.useState(
    props.tableData.data
  );

  const [values, setValues] = React.useState(
    props?.tableData?.genrateOfferData
      ? {
          downPayment:
            props.tableData.genrateOfferData.data &&
            props.tableData.genrateOfferData.data[0]?.Recourse[0]?.offers[0]
              .downPayment,
          monthlyPayment:
            props.tableData.genrateOfferData.data &&
            props.tableData.genrateOfferData.data[0]?.Recourse[0]?.offers[0]
              .monthlyPayment,
          terms:
            props.tableData.genrateOfferData.data &&
            props.tableData.genrateOfferData.data[0]?.Recourse[0]?.offers[0]
              .termsMonth,
          startDate:
            props.tableData.genrateOfferData.data &&
            props.tableData.genrateOfferData.data[0]?.Recourse[0]?.offers[0]
              .startDate,
          maturityDate:
            props.tableData.genrateOfferData.data &&
            props.tableData.genrateOfferData.data[0]?.Recourse[0]?.offers[0]
              .maturityDate,
          apr:
            props.tableData.genrateOfferData.data &&
            props.tableData.genrateOfferData.data[0]?.Recourse[0]?.offers[0]
              .APR,
          dailyIntrest:
            props.tableData.genrateOfferData.data &&
            props.tableData.genrateOfferData.data[0]?.Recourse[0]?.offers[0]
              .dailyInterestCharge,
        }
      : {
          downPayment: "",
          monthlyPayment: "",
          terms: "",
          startDate: "",
          maturityDate: "",
          apr: "",
          dailyIntrest: "",
        }
  );
  const [preError, setPreError] = React.useState(false);
  const clientIdData =
    props.tableData.genrateOfferData.data &&
    props.tableData.genrateOfferData.data[0].offerNumber.split("-");
  const userOfferPath = window.location.pathname.split("/");

  const loginUser = async () => {
    // localStorage.clear();
    const url = "/create-token?offerId=" + userOfferPath[3];
    await getAxiosAPI(url).then((res) => {
      if (res.error) {
      } else {
        localStorage.setItem("patientEmail", `${res.data.patientEmail}`);
        setPatientEmail(res.data);
        localStorage.setItem("token", `bearer ${res.data.token}`);
        props.setEmailOffer();
        localStorage.setItem("offerType", `email`);
        getAllOffersApiCall();
      }
    });
  };

  const getAllOffersApiCall = async (token) => {
    setLoader(true);
    const reqData = {
      offerId: offerId,
    };
    props.getGenrateOfferData(reqData);

    setLoader(false);
  };
  const paymentData =
    props.tableData.genrateOfferData &&
    props.tableData.genrateOfferData.Offer_Status;
  const generateOffers = async () => {
    setShowAllOffers(true);
  };
  const patientEmailStorage = localStorage.getItem("patientEmail");

  const clientData = () => {
    const reqData = {
      ClientId: patientEmail.clientId,
      commonText: patientEmail.patientEmail,
    };
    props.patientSearch(reqData);
  };

  useEffect(() => {
    loginUser();
  }, [params.id]);

  useEffect(() => {
    if (patientEmail) {
      clientData();
    }
  }, [patientEmail]);

  const offerId = userOfferPath[3];

  useEffect(() => {
    if (props.tableData.paymentDetailsData && preError) {
      props.history.push("/tpo/payments");
    }
    // if (preError) {
    //   setPreError(false);
    // }
  }, [props.tableData.paymentDetailsData]);

  const handlePaymentOffer = (type, preValue) => {
    let data;
    let amount;

    const monthlyAmount =
      props.tableData.genrateOfferData.data[0].Recourse[0].offers &&
      props.tableData.genrateOfferData.data[0].Recourse[0].offers.filter(
        (i) => i?.financerNo == localStorage.getItem("finNo")
      );

    if (props.tableData.genrateOfferData) {
      amount =
        type === 1
          ? Number(
              props.tableData.genrateOfferData.data &&
                props.tableData.genrateOfferData?.data[0]?.Discounted[0].finalPayAmount
                  .toString()
                  .replace("$", "")
            )
          : Number(
              props.tableData.genrateOfferData.data &&
                props.tableData.genrateOfferData?.data[0]?.Recourse[0]?.offers[0].downPayment
                  .toString()
                  .replace("$", "")
            );

      data = {
        patientId: props.tableData.data.data[0].Patient_id,
        offerId: props.tableData.genrateOfferData?.data[0].offerId,
        amount,
        paymentId: "",
        payType: type,
        monthlyPayment: monthlyAmount
          ? monthlyAmount[0]?.monthlyPayment.toString().replace("$", "")
          : 0,
      };
    } else {
      data = {
        patientId: props.tableData.data.data[0].Patient_id,
        offerId: props.tableData.genrateOfferData?.data[0].offerId,
        amount: 0,
        paymentId: "",
        payType: type,
        monthlyPayment: monthlyAmount
          ? monthlyAmount[0].monthlyPayment.toString().replace("$", "")
          : 0,
      };
    }
    props.patientPaymentDetailsCall(data);
    setPreError(true);
  };
  const lowest =
    props.tableData.genrateOfferData &&
    !props.tableData.genrateOfferData.Offer_Status &&
    props.tableData.genrateOfferData?.data[0]?.Recourse[0]?.offers.reduce(
      (previous, current) => {
        return current.APR < previous.APR ? current : previous;
      }
    );

  return (
    <div>
      <Container>
        {!loader ? (
          <>
            <Typography style={{ fontSize: "18px", margin: "0 20px" }}>
              Patient Name:-{" "}
              <b>
                {props.tableData.data.data &&
                  props.tableData.data?.data[0].Patient_Name}
              </b>
            </Typography>
            <Grid lg={12} container className={classes.cardMain}>
              <Grid xs={12} sm={6} className={classes.leftItem}>
                <Box className={classes.toplevelBox}>
                  <Typography className={classes.tpoLevel}>
                    {Math.round(
                      props.tableData.data.data &&
                        props.tableData.data?.data[0].tpoLevel
                    )}
                  </Typography>
                </Box>

                <Grid className={classes.leftText}>
                  <Typography className={classes.mainHeading}>
                    TPO Level
                  </Typography>
                  <Typography className={classes.bottomPara}>
                    Level is calculated using The Patient <br />
                    Offer Algorithm 2.0
                  </Typography>
                </Grid>
              </Grid>
              <Grid xs={12} sm={6} className={classes.rightItem}>
                <Typography className={classes.balance}>
                  Current Balance
                </Typography>
                <Typography className={classes.balanceDigit}>
                  ${" "}
                  {props.tableData.genrateOfferData.data &&
                    props.tableData.genrateOfferData.data[0]?.Discounted[0]
                      .amountFinanced}
                </Typography>
              </Grid>
            </Grid>
            {props.tableData.genrateOfferData &&
            props.tableData.genrateOfferData.Offer_Status == 8 ? (
              <div
                style={{
                  textAlign: "center",
                  display:"flex",
                  justifyContent:"center",
                  marginLeft:"40px",
                  fontSize: "50",
                  color: "black",
                  background: "white",
                }}
              >
                {"Payment for this offer has already been made."}
              </div>
            ) : (
              <>
                <Typography
                  style={{
                    margin: "20px",
                    fontSize: "22px",
                    fontWight: 600,
                    lineHeight: "28px",
                  }}
                >
                  The following payment options are available to you:
                </Typography>
                {showAllOffers ? (
                  <>
                    <div style={{ marginLeft: "20px" }}>
                      *Values are estimated
                    </div>
                    {props.tableData.genrateOfferData.data[0].Recourse[0].offers
                      .length &&
                      props.tableData.genrateOfferData?.data[0]?.Recourse[0].offers.map(
                        (loan) => (
                          <Grid container className={classes.offersCard}>
                            <Grid xs={6} sm={4} lg={2}>
                              <img
                                className={classes.financerLogo}
                                key={loan.financerLogo}
                                src={loan.financerLogo}
                                alt="Current Logo"
                                loading="eager"
                              />
                            </Grid>
                            <Grid xs={6} sm={4} lg={2}>
                              <Typography className={classes.offersPara}>
                                APR*
                              </Typography>
                              <Typography className={classes.offersHeading}>
                                {loan.APR}%
                              </Typography>
                            </Grid>
                            <Grid xs={6} sm={4} lg={2}>
                              <Typography className={classes.offersPara}>
                                Down Payment
                              </Typography>
                              <Typography className={classes.offersHeading}>
                                {loan.downPayment}
                              </Typography>
                            </Grid>
                            <Grid xs={6} sm={4} lg={3}>
                              <Typography className={classes.offersPara}>
                                Term*
                              </Typography>
                              <Typography className={classes.offersHeading}>
                                {loan.termsMonth} mos.
                              </Typography>
                            </Grid>
                            {/* <Grid xs={6} sm={4} lg={2}>
                        <Typography className={classes.offersHeading}>
                          {loan.downPayment}
                        </Typography>
                        <Typography className={classes.offersPara}>
                          Down Payment
                        </Typography>
                      </Grid> */}
                            <Grid
                              xs={6}
                              sm={4}
                              lg={2}
                              style={{
                                paddingTop: "12px",
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: "#03A430",
                                  borderRadius: "25px",
                                  textTransform: "none",
                                  height: "45px",
                                  color: "#ffffff",
                                  width: "190px",
                                }}
                                size="large"
                                variant="contained"
                                color="#ffffff"
                                type="submit"
                                onClick={() => {
                                  localStorage.setItem(
                                    "finNo",
                                    loan.financerNo
                                  );
                                  handlePaymentOffer(2);
                                }}
                                className={classes.acceptButton}
                              >
                                Accept Offer
                              </Button>
                              <Typography
                                style={{ padding: "5px 24px", width: "100%" }}
                                className={classes.offersPara}
                              >
                                <Link
                                  className={classes.offersPara}
                                  style={{ borderBottom: "1px solid grey" }}
                                >
                                  Offer details, rates & fees
                                </Link>
                              </Typography>
                            </Grid>
                          </Grid>
                        )
                      )}

                    {props.tableData.genrateOfferData.data[0].NonRecourse[0]
                      .offers
                      ? props.tableData.genrateOfferData.data[0].NonRecourse[0].offers.map(
                          (loan) => (
                            <Grid container className={classes.offersCard}>
                              <Grid xs={6} sm={4} lg={2}>
                                <img
                                  className={classes.financerLogo}
                                  key={
                                    props.tableData.genrateOfferData.data[0]
                                      .NonRecourse[0].financerLogo
                                  }
                                  src={
                                    props.tableData.genrateOfferData.data[0]
                                      .NonRecourse[0].financerLogo
                                  }
                                  alt="Recourse Logo"
                                  loading="eager"
                                />
                              </Grid>
                              <Grid xs={6} sm={4} lg={2}>
                                <Typography className={classes.offersHeading}>
                                  {loan?.APR}%
                                </Typography>
                                <Typography className={classes.offersPara}>
                                  APR*
                                </Typography>
                              </Grid>
                              <Grid xs={6} sm={4} lg={2}>
                                <Typography className={classes.offersHeading}>
                                  {loan?.monthlyPayment}
                                </Typography>
                                <Typography className={classes.offersPara}>
                                  Monthly Payment*
                                </Typography>
                              </Grid>
                              <Grid xs={6} sm={4} lg={2}>
                                <Typography className={classes.offersHeading}>
                                  {loan?.termsMonth} mos.
                                </Typography>
                                <Typography className={classes.offersPara}>
                                  Term*
                                </Typography>
                              </Grid>
                              <Grid xs={6} sm={4} lg={2}>
                                <Typography className={classes.offersHeading}>
                                  {loan?.downPayment}
                                </Typography>
                                <Typography className={classes.offersPara}>
                                  Down Payment
                                </Typography>
                              </Grid>
                              <Grid xs={6} sm={4} lg={2}>
                                <Button
                                  variant="contained"
                                  onClick={() => handlePaymentOffer(2)}
                                  className={classes.acceptButton}
                                >
                                  Accept Offer
                                </Button>
                                <Typography
                                  style={{ padding: "5px 18px" }}
                                  className={classes.offersPara}
                                >
                                  Offer details, rates & fees
                                </Typography>
                              </Grid>
                            </Grid>
                          )
                        )
                      : null}
                    <Grid style={{ textAlign: "center", margin: "20px" }}>
                      <Button
                        style={{
                          borderRadius: "25px",
                          textTransform: "none",
                          height: "45px",
                        }}
                        size="large"
                        variant="contained"
                        color="#ffffff"
                        type="submit"
                        onClick={() => setShowAllOffers(false)}
                        className={classes.paymentBtn}
                      >
                        Return to Payment Options
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid container className={classes.screen1Cards}>
                    <Grid
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ paddingBottom: "40px" }}
                    >
                      <Grid className={classes.primaryCards}>
                        <Grid className={classes.discountFlex}>
                          <Typography className={classes.cardH7}>
                            {props.tableData.genrateOfferData &&
                              props.tableData.genrateOfferData.data[0]
                                .Discounted[0].discount}
                          </Typography>
                          <Typography className={classes.cardH8}>
                            {" "}
                            Discount
                            {/* {props.tableData.genrateOfferData &&
                      props.tableData.genrateOfferData.data[0].Discounted[0]
                        .discountAmount}{" "} */}
                          </Typography>
                        </Grid>
                        <Grid container className={classes.cardSecond}>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography
                              className={classes.cardH1}
                              style={{ paddingLeft: "0px", paddingTop: "10px" }}
                            >
                              Single Payment
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Single Pay Discount
                            </Typography>
                            <Typography className={classes.cardH1}>
                              $
                              {props.tableData.genrateOfferData &&
                                props.tableData.genrateOfferData.data[0]
                                  .Discounted[0].finalPayAmount}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Total Payment
                            </Typography>
                            <Typography
                              className={classes.cardH1}
                              style={{ color: "#03A430" }}
                            >
                              ${" "}
                              {props.tableData.genrateOfferData &&
                                props.tableData.genrateOfferData.data[0]
                                  .Discounted[0]?.amountFinanced}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Button
                              style={{
                                backgroundColor: "#03A430",
                                borderRadius: "25px",
                                textTransform: "none",
                                height: "45px",
                                color: "#ffffff",
                                width: "190px",
                              }}
                              size="large"
                              variant="contained"
                              color="#ffffff"
                              type="submit"
                              onClick={() => handlePaymentOffer(1)}
                              // disabled={!isEmpty(formik.errors) || !formik.dirty}
                              // className={classes.submit}
                            >
                              Make Payment
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ paddingBottom: "40px" }}
                    >
                      <Grid className={classes.primaryCards}>
                        <Grid
                          className={classes.discountFlex}
                          style={{
                            backgroundColor: "#3E4E9B",
                            color: "#ffffff",
                            width: "10%",
                          }}
                        >
                          <Typography className={classes.cardH8}>
                            TPO Sponsored
                          </Typography>
                        </Grid>
                        <Grid container className={classes.cardSecond}>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography
                              className={classes.cardH1}
                              style={{ paddingLeft: "0px", paddingTop: "10px" }}
                            >
                              Payment Plans
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Lowest APR
                            </Typography>
                            <Typography className={classes.cardH1}>
                              $ {lowest.APR}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Lowest Monthly Payment
                            </Typography>
                            <Typography className={classes.cardH1}>
                              {lowest.monthlyPayment}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Button
                              onClick={() => generateOffers()}
                              style={{
                                backgroundColor: "#3E4E9B",
                                borderRadius: "25px",
                                textTransform: "none",
                                height: "45px",
                                color: "#ffffff",
                                width: "190px",
                              }}
                              size="large"
                              variant="contained"
                              color="#ffffff"
                              type="submit"

                              // disabled={!isEmpty(formik.errors) || !formik.dirty}
                              // className={classes.submit}
                            >
                              View Plans
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    {props.tableData.genrateOfferData &&
                    props.tableData.genrateOfferData?.data[0]?.NonRecourse[0]
                      .offers
                      ? props.tableData.genrateOfferData?.data[0]?.NonRecourse[0].offers.map(
                          (loan) => (
                            <Grid
                              xs={12}
                              md={12}
                              lg={12}
                              style={{ paddingBottom: "40px" }}
                            >
                              <Grid className={classes.primaryCards}>
                                <Grid
                                  className={classes.discountFlex}
                                  style={{
                                    backgroundColor: "#0079BD",
                                    color: "#ffffff",
                                    width: "11%",
                                  }}
                                >
                                  <Typography className={classes.cardH8}>
                                    Third-Party Plan
                                  </Typography>
                                </Grid>
                                <Grid container className={classes.cardSecond}>
                                  <Grid item lg={3} md={3} xs={12}>
                                    <Typography
                                      className={classes.cardH1}
                                      style={{
                                        paddingLeft: "0px",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      Payment Plans
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3} md={3} xs={12}>
                                    <Typography
                                      className={classes.typographyAmmount}
                                    >
                                      Lowest APR
                                    </Typography>
                                    <Typography className={classes.cardH1}>
                                      $
                                      {/* {offersDataNew?.data[0].Discounted[0].discountAmount} */}
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3} md={3} xs={12}>
                                    <Typography
                                      className={classes.typographyAmmount}
                                    >
                                      Lowest Monthly Payment
                                    </Typography>
                                    <Typography className={classes.cardH1}>
                                      ${" "}
                                      {/* {offersDataNew.data[0].Discounted[0].finalPayAmount} */}
                                    </Typography>
                                  </Grid>
                                  <Grid item lg={3} md={3} xs={12}>
                                    <Button
                                      onClick={() => generateOffers()}
                                      style={{
                                        backgroundColor: "#0079BD",
                                        borderRadius: "25px",
                                        textTransform: "none",
                                        height: "45px",
                                        color: "#ffffff",
                                        width: "190px",
                                      }}
                                      size="large"
                                      variant="contained"
                                      color="#ffffff"
                                      type="submit"

                                      // disabled={!isEmpty(formik.errors) || !formik.dirty}
                                      // className={classes.submit}
                                    >
                                      View Plans
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          )
                        )
                      : null}
                    {/* <Typography className={classes.cardH1}>Single Payment</Typography>
              <Grid style={{ margin: "20px 0" }}>
                <Typography className={classes.cardH2}>
                  Sub-total ${" "} */}
                    {/* {offersDataNew?.data[0]?.Discounted[0]?.amountFinanced} */}
                    {/* </Typography>
                <Typography className={classes.cardH2}>
                  Discount: -${" "} */}
                    {/* {offersDataNew?.data[0].Discounted[0].discountAmount} */}{" "}
                    {/* </Typography>
              </Grid> */}
                    {/* <Typography className={classes.cardH3}>TOTAL AMOUNT</Typography>
              <Divider className={classes.dividerCss} />
              <Typography className={classes.cardH4}> */}
                    {/* ${offersDataNew.data[0].Discounted[0].finalPayAmount} */}
                    {/* </Typography> */}
                    {/* <Button variant="contained" className={classes.paymentBtnPrimary}>
                Make Single Payment
              </Button> */}
                    {/* <Grid xs={12} md={12} lg={12}>
                <Grid className={classes.primaryCards}>
                  <Typography className={classes.cardH1}>
                    Payment Plan
                  </Typography>
                  <Grid style={{ margin: "20px 0" }}>
                    <Typography className={classes.cardH2}>
                      Pay in equal monthly installments.
                    </Typography>
                  </Grid>
                  <Grid className={classes.card2middle}>
                    <Typography className={classes.cardH3}>
                      Lowest Rate
                    </Typography>
                    <Typography className={classes.cardH3}>
                      View details, rates & fees
                    </Typography>
                  </Grid>
                  <Divider className={classes.dividerCss} />
                  <Grid className={classes.card2middle}>
                    <Grid> */}
                    {/* <Typography className={classes.cardH5}> */}
                    {/* {offersDataNew?.data[0]?.Recourse[0].offers[0]?.monthlyPayment} */}
                    {/* /mo.
                      </Typography>
                      <Typography className={classes.cardH6}>
                        Estimated Payment
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography className={classes.cardH5}> */}{" "}
                    {/* {offersDataNew?.data[0]?.Recourse[0].offers[0]?.termsMonth} */}{" "}
                    {/* mos.
                      </Typography>
                      <Typography className={classes.cardH6}>
                        Payment Length
                      </Typography>
                    </Grid>
                  </Grid>
                  <Button
                    onClick={() => generateOffers()}
                    variant="contained"
                    className={classes.paymentBtnPrimary2}
                  >
                    See All Payment Plans
                  </Button>
                </Grid>
              </Grid> */}
                  </Grid>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <Typography className={classes.cardH4}>Loading...</Typography>
          </>
        )}
      </Container>
    </div>
  );
};

TPO.propTypes = {
  createOfferCall: PropTypes.func,
  getGenrateOfferData: PropTypes.func,
  patientPaymentDetailsCall: PropTypes.func,
  patientSearch: PropTypes.func,
  setEmailOffer: PropTypes.func,
  app: PropTypes.object,
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  userProvider: app.users,
  app: app.app,
});

export default connect(mapStateToProps, {
  createOfferCall,
  getGenrateOfferData,
  patientPaymentDetailsCall,
  setEmailOffer,
  patientSearch,
})(TPO);

const useStyles = makeStyles({
  financerLogo: { objectFit: "contain", height: "80px", width: "100px" },
  root: {
    padding: "2px 15px",
    display: "flex",
    alignItems: "center",
    width: 300,
    borderRadius: "24px",
    color: "#555555",
    backgroundColor: "#EFF4FF",
  },
  discountFlex: {
    // position: "absolute",
    // top: "-14px",
    width: "9%",
    borderRadius: "7px",
    marginTop: "-43px",
    backgroundColor: "#FFD568",
    padding: "10px 15px",
    display: "flex",
    alignItems: "center",
    // borderRadius: "0px 0px 15px 15px",
  },
  card2middle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardH1: {
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "35px",
  },
  cardH2: {
    fontWeight: 400,
    padding: "2px 0",
    fontSize: "18px",
    lineHeight: "24px",
  },
  cardH7: {
    fontWeight: 600,
    color: "#333333",
    fontSize: "14px",
    lineHeight: "10px",
    paddingRight: "5px",
    textTransform: "none",
  },
  cardH8: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "10px",
    textTransform: "none",
  },
  cardH4: {
    fontWeight: 600,
    padding: "2px 0",
    fontSize: "48px",
    paddingBottom: "30px",
    paddingTop: "5px",
    lineHeight: "52px",
  },
  cardH5: {
    fontWeight: 600,
    padding: "5px 0",
    fontSize: "32px",
    lineHeight: "34px",
  },
  topNameGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardH6: {
    fontWeight: 400,
    paddingTop: "5px",
    paddingBottom: "45px",
    fontSize: "16px",
    lineHeight: "18px",
  },
  cardH3: {
    fontWeight: 600,
    color: "#27337D",
    padding: "5px 0",
    fontSize: "18px",
    lineHeight: "22px",
  },
  primaryCards: {
    backgroundColor: "white !important",
    padding: "30px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    borderRadius: "15px",
    height: "50px",
  },
  cardMain: {
    borderRadius: "15px",
    margin: "40px 20px 20px 20px",
    padding: "20px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    backgroundColor: "#FFFFFF",
  },
  screen1Cards: {
    margin: "20px",
  },
  dividerCss: {
    height: "2px !important",
    color: "#DBE3F3 !important",
  },
  input: {
    // marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  iconButtonBell: {
    padding: "10px",
    margin: "0 10px",
    backgroundColor: "#3E4E9B",
  },
  divider: {
    height: 28,
    margin: 4,
  },

  offersCard: {
    borderRadius: "15px",
    margin: "20px",
    padding: "24px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    backgroundColor: "#FFFFFF",
  },
  tpoLevel: {
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "56px",
  },
  cardSecond: {
    paddingTop: "10px",
  },
  toplevelBox: {
    backgroundColor: "#FFD568",
    paddingTop: "3px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "55px",
    height: "55px",
    borderRadius: "10px",
  },
  leftItem: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: 'space-between',
    margin: "auto 0px",
  },
  rightItem: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: 'right',
    alignItems: "flex-end",
    margin: "auto 0px",
  },
  leftText: {
    margin: "auto 20px",
  },
  mainHeading: {
    fontSize: "26px",
    fontWeight: "600",
    lineHeight: "30px",
  },
  bottomPara: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "22px",
  },
  balance: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "22px",
    color: "#27337D",
    padding: "0 15px",
  },
  balanceDigit: {
    fontSize: "48px",
    fontWeight: "600",
    lineHeight: "52px",
    // color: '#27337D'
  },
  offersHeading: {
    fontSize: "28px",
    fontWeight: "600",
    lineHeight: "30px",
  },
  offersPara: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    color: "#777777",
    paddingTop: "5px",
  },
  offersPara2: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    color: "#777777",
    paddingTop: "5px",
    textAlign: "center",
    margin: "10px 0px",
  },
  acceptButton: {
    backgroundColor: "#008768",
    borderRadius: "5px",
    // width: "auto",
    textTransform: "none",
    color: "#fff",
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: "400",
    margin: "0 10px",
    padding: "10px 30px !important",
  },
  paymentBtn: {
    backgroundColor: "#0079BD",
    borderRadius: "5px",
    padding: "10px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
  },
  paymentBtnPrimary: {
    backgroundColor: "#008768",
    width: "100%",
    borderRadius: "5px",
    padding: "25px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    marginTop: "30px",
  },
  paymentBtnPrimary2: {
    backgroundColor: "#0079BD",
    width: "100%",
    borderRadius: "5px",
    padding: "25px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    marginTop: "30px",
  },
  drawerBtns: {
    backgroundColor: "#008768",
    borderRadius: "5px",
    padding: "12px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    margin: "10px 0px",
  },
  drawerBtns2: {
    // backgroundColor: '#008768',
    borderRadius: "5px",
    padding: "12px 50px",
    textTransform: "none",
    // color: '#fff',
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    margin: "10px 0px",
  },
  sendOffersBtn: {
    // height: "auto",
    // minWidth: "40px",
    backgroundColor: "#0079BD",
    borderRadius: "5px",
    padding: "15px 20px",
    position: "fixed",
    right: "-62px",
    top: "50%",
    bottom: "50%",
    transform: "rotate(-90deg)",
    textTransform: "none",
    // width: "0.6em !important",
    color: "#fff",
    fontWeight: "400",
    fontSize: "22px",
    lineHeight: "24px",
  },
  drawerInput: {
    width: "350px",
    margin: "10px 0px",
    // padding: '10px 0px',
    height: "40px",
  },
  backBtn: {
    color: "#27337D",
    marginBottom: "50px",
    cursor: "pointer",
  },
  btnPairs: {
    margin: "80px 0px",
    display: "flex",
    flexDirection: "column",
  },
  topHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "20px",
  },
  headerContent: {
    display: "flex",
    flexDirection: "row",
  },
  useName: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    padding: "0 20px",
  },
});
