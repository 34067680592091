import { post, get } from "./httpClient";
import {
  ADD_PATIENT_DETAILS_OFFER_URL,
  PERSONAL_LOAN_OFFER_URL,
} from "../Uintls/Urls";

export const addNewPatientCall =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await post(ADD_PATIENT_DETAILS_OFFER_URL, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const financeOfferListFetchCall =
  (body, successCallback, failureCallback) => async () => {
    try {
      const data = await get(`${PERSONAL_LOAN_OFFER_URL}`, body);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
