/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
import React from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import classNames from "classnames";
import Radio from "@material-ui/core/Radio";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";
import AchForm from "./AchForm";
import CardForm from "./CardForm";

const GreenRadio = withStyles(theme => ({
  root: {
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  checked: {},
}))(props => <Radio color="default" {...props} />);

const useStyles = makeStyles(styles);
const PaymentInformation = props => {
  const classes = useStyles();
  const [selectedValue, setSelectedValue] = React.useState("");
  const [selectedPayment, setSelectedPayment] = React.useState("a");
  const [showPayment, setShowPaymet] = React.useState(true);
  const [showCard, setShowCard] = React.useState(true);
  const [values, setValues] = React.useState({
    email: "justinJones19@hotmail.com",
    mobile: "(372) 587-2335",
  });

  const [value, setValue] = React.useState({
    cardValue: "",
    mobile: "",
  });

  const handleChange = event => {
    if (event.target.value === "b") {
      setSelectedValue(event.target.value);
      setShowPaymet(true);
    }
    if (event.target.value === "a") {
      setSelectedValue(event.target.value);
      setShowPaymet(false);
    }
    if (event.target.value === "c") {
      setSelectedValue(event.target.value);
      setShowPaymet(false);
    }
  };
  const handlePayment = event => {
    if (event.target.value === "b") {
      setSelectedPayment(event.target.value);
      setShowCard(false);
    }
    if (event.target.value === "a") {
      setSelectedPayment(event.target.value);
      setShowCard(true);
    }
  };
  const onChange = (e, type) => {
    const value = { ...values };
    value[type] = e;
    setValues(value);
  };
  const handleNextStep = () => {
    if (selectedValue === "b") {
      props.setShowshowTnc();
      props.nextStep();
    } else {
      props.nextStep();
    }
  };
  return (<>
    <Box height={40} className={classes.radioBoxUnique}>
      <div className={classes.radioBoxRoot}>
        <GreenRadio
          className={classes.checkBox}
          checked={selectedValue === "b"}
          onChange={handleChange}
          value="b"
          name="radio-button-demo"
          inputProps={{ "aria-label": "B" }}
        />
        <Typography className={classes.textColorRadio}>Pay in full</Typography>
        <div className={classes.pointTextRupe}>
          {props.preValues.copay}
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      </div>
      <div className={classes.radioBoxRoot}>
        <GreenRadio
          checked={selectedValue === "a"}
          onChange={handleChange}
          className={classes.checkBox}
          value="a"
          name="radio-button-demo"
          inputProps={{ "aria-label": "A" }}
        />
        <Box>
          <div className={classes.displayFlex}>
            <Typography className={classes.textColorRadio}>Down Payment</Typography>
            {" "}
            <div className={classes.pointTextRupe}>
              {props.preValues.downPayment}
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </div>
          </div>
          <div className={classes.displayFlex}>
            <div className={classes.textDetails}>Monthly Payment:</div>
            <div>
              {" "}
              {props.preValues.perEmiAmt}
            </div>
          </div>
          <div className={classes.displayFlex}>
            <div className={classes.textDetails}>Tenure:</div>
            <div className={classes.textDetails}>{`${props.preValues.totalEmi}  ${props.preValues.start_date} - ${props.preValues.end_date}`}</div>
          </div>
          <div className={classes.displayFlex}>
            <div className={classes.textDetails}>APR:</div>
            <div>
              {" "}
              {props.preValues.apr}
              %
            </div>
          </div>
        </Box>
      </div>
      <div className={classes.radioBoxRoot}>
        <GreenRadio
          className={classes.checkBox}
          checked={selectedValue === "c"}
          onChange={handleChange}
          value="c"
          name="radio-button-demo"
          inputProps={{ "aria-label": "B" }}
        />
        <Typography className={classes.textColorRadio}>Send link to patient</Typography>
      </div>
    </Box>
    <div />
    {selectedValue === "c"
          && <Grid container className={classes.marginSendLink}>
            <Grid item xs>
              <TextField
                className={classes.emailRoot}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                }}
                label="Email Address"
                value={values.email}
                onChange={e => onChange(e.target.value, "email")}
                name="businessEmail"
                id="businessEmail"
              />
            </Grid>
            <Grid item xs>
              <TextField
                className={classes.emailRoot}
                InputLabelProps={{
                  classes: {
                    root: classes.activeInputLableColor,
                    focused: "focused",
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.activeInputColor,
                    input: classes.resize,
                    focused: "focused",
                  },
                }}
                label="Mobile Number"
                value={values.mobile}
                onChange={e => onChange(e.target.value, "mobile")}
                name="businessEmail"
                id="businessEmail"
              />
            </Grid>
            <Grid item xs>
              <Button variant="contained" className={classes.buttonStyle} color="#696969" size="large" onClick={() => props.setShowLinkMessage(true)}>
                Send Link to patient
              </Button>
            </Grid>
          </Grid>}
    {(selectedValue === "a" || selectedValue === "b")
          && <Box>
            <hr className={classes.lineBarPayment} />
            <Grid container className={classes.margin}>
              <Grid item xs>
                <Box height={40} className={classes.radioBox}>
                  <Typography className={classes.textColorRadioCard}>
                    {`${selectedValue === "a" ? "Down" : "Full"}`}
                    {" "}
                    Payment via
                  </Typography>
                  <div className={classes.radioBoxRoot}>
                    <GreenRadio
                      className={classes.checkBox}
                      checked={selectedPayment === "a"}
                      onChange={handlePayment}
                      value="a"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "A" }}
                    />
                    <Typography className={classes.textColorRadioCard}>Card</Typography>
                  </div>
                  <div className={classes.radioBoxRoot}>
                    <GreenRadio
                      checked={selectedPayment === "b"}
                      onChange={handlePayment}
                      className={classes.checkBox}
                      value="b"
                      name="radio-button-demo"
                      inputProps={{ "aria-label": "B" }}
                    />
                    <Typography className={classes.textColorRadioCard}>ACH</Typography>
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Grid container className={classes.margin}>
              <Grid item xs={6}>
                <TextField
                  className={classes.fullInput}
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused",
                    },
                  }}
                  InputProps={{
                    readOnly: true,
                    classes: {
                      root: classes.activeInputColor,
                      input: classNames({
                        [classes.readOnlyInput]: true,
                        [classes.pointTextRupe]: true,
                      }),
                      focused: "focused",
                    },
                  }}
                  disabled
                  value={`${selectedValue === "b" ? props.preValues.copay : props.preValues.downPayment}`}
                  onChange={e => onChange(e.target.value, "cardValue")}
                  name="card"
                  id="card"
                />
              </Grid>
            </Grid>

            {showCard ? <CardForm {...props} /> : <AchForm {...props} />}
            <Grid container className={classes.margin}>
              <Box className={classes.buttonBox}>
                <Button variant="contained" className={classes.buttonStyle} color="#696969" size="large" onClick={() => handleNextStep()}>
                  Save and Confirm
                </Button>
              </Box>
            </Grid>
          </Box>}
  </>);
};

PaymentInformation.propTypes = {
  preValues: PropTypes.object,
  nextStep: PropTypes.any,
  setShowLinkMessage: PropTypes.any,
  setShowshowTnc: PropTypes.any,
};

export default PaymentInformation;
