/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CoustomButton from "components/CustomButtons/Button.js";
import isEmpty from "lodash/isEmpty";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import SetUp from "views/Setup/Index";
import { Typography } from "@material-ui/core";
import noDataImage from "../../assets/img/noDataImage.png";
import LongMenu from "../../components/Menu/RoleParameter";
import {
  getProviderList,
  changeProviderFormMode,
  getProviderStatus,
  changeSingleStatus,
  saveProviderParameter,
  changeProviderParameterFormMode,
  getProviderParameterById
} from "../../Redux/Actions/ProvideRegistration";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

const useStyles = makeStyles(tableStyle);

function createData(
  clientId,
  Provider_id,
  Business_Name,
  Down_Payment,
  Maximum_Monthly_Payment,
  Minimum_Down_Payment,
  Minimum_Monthly_Payment,
  Minimum_Interest_Rate,
  Maximum_Interest_Rate,
  Allow_Overriding_Monthly_Payment_Date,
  Review_Offers_above,
  discountRate,
  interestRateTier1,
  interestRateTier2,
  interestRateTier3,
  interestRateTier4,
  Start_Date,
  End_Date,
  Created_By,
  Created_On,
  Id
) {
  return {
    clientId,
    Provider_id,
    Business_Name,
    Down_Payment,
    Maximum_Monthly_Payment,
    Minimum_Down_Payment,
    Minimum_Monthly_Payment,
    Minimum_Interest_Rate,
    Maximum_Interest_Rate,
    Allow_Overriding_Monthly_Payment_Date,
    Review_Offers_above,
    discountRate,
    interestRateTier1,
    interestRateTier2,
    interestRateTier3,
    interestRateTier4,
    Start_Date,
    End_Date,
    Created_By,
    Created_On,
    Id
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography>First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography>Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography>Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography>Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "DBA_Name",
    numeric: false,
    disablePadding: true,
    label: "Start Date"
  },
  {
    id: "E_mail",
    numeric: false,
    disablePadding: true,
    label: "End Date"
  },
  {
    id: "Ownership_Type",
    numeric: false,
    disablePadding: false,
    label: "DP"
  },
  {
    id: "Contact_Name",
    numeric: false,
    disablePadding: true,
    label: "Min DP"
  },
  {
    id: "Phone",
    numeric: true,
    disablePadding: false,
    label: "Month Pay(min)"
  },
  {
    id: "Creation_Date",
    numeric: true,
    disablePadding: false,
    label: "Month Pay(max)"
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Review Offer Above"
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Created By"
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Created On"
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Action"
  }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="left"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired
};

const ProviderParameter = (props) => {
  const classes = useStyles();
  const ref = React.useRef(null);
  const [page, setPage] = React.useState(0);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [preStatusUpdate, setPreStatusUpdate] = React.useState(
    props.tableData.statusUpdate
  );
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [showSetupForm, setShowSetUp] = React.useState(false);
  const [formData, setFornData] = React.useState({});

  React.useEffect(() => {
    if (!props.tableData.statusList) {
      props.getProviderStatus();
    }
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleConfigurationById = (data, row) => {
    props.getProviderParameterById(data);
    props.changeProviderParameterFormMode("View");
    handleSetup("View", row);
  };

  const handleBackButton = () => {
    props.history.push(`/${props.userDetails.layout}/Providers`);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  rows = [];

  if (props.tableData.providerParameterData) {
    props.tableData.providerParameterData.Providers_Data.forEach((v, i) => {
      const { Down_Payment } = v;
      const { Minimum_Down_Payment } = v;
      const { Minimum_Monthly_Payment } = v;
      const { Maximum_Monthly_Payment } = v;
      const { Minimum_Interest_Rate } = v;
      const { Maximum_Interest_Rate } = v;
      const { Allow_Overriding_Monthly_Payment_Date } = v;
      const { Review_Offers_above } = v;
      const { discountRate } = v;
      const { interestRateTier1 } = v;
      const { interestRateTier2 } = v;
      const { interestRateTier3 } = v;
      const { interestRateTier4 } = v;
      const { Start_Date } = v;
      const { End_Date } = v;
      const { Created_By } = v;
      const { Created_On } = v;
      const { Id } = v;
      const { Business_Name } = v;
      const { Provider_id } = v;
      const { clientId } = v;
      rows.push(
        createData(
          clientId,
          Provider_id,
          Business_Name,
          Down_Payment,
          Maximum_Monthly_Payment,
          Minimum_Down_Payment,
          Minimum_Monthly_Payment,
          Minimum_Interest_Rate,
          Maximum_Interest_Rate,
          Allow_Overriding_Monthly_Payment_Date,
          Review_Offers_above,
          discountRate,
          interestRateTier1,
          interestRateTier2,
          interestRateTier3,
          interestRateTier4,
          Start_Date,
          End_Date,
          Created_By,
          Created_On,
          Id
        )
      );
    });
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSetup = (type, value) => {
    const data = {
      value,
      name: props.tableData.providerParameterData.dbName,
      id: props.tableData.providerParameterData.provider_id,
      clientId: props.tableData.providerParameterData.clientId
    };
    setFornData(data);
    if (type === undefined) {
      props.changeProviderParameterFormMode("Create");
    }
    setShowSetUp(!showSetupForm);
  };

  const closeView = () => {
    setShowSetUp(false);
  };

  const profileType = localStorage.getItem("profileType");

  if (!showSetupForm) {
    return (
      <TableContainer className={classes.TableContainer} component={Paper}>
        <Grid container style={{ padding: "1%", alignItems: "center" }}>
          <Grid item xs={4} sm={4} md={4}>
            <Button
              className={classes.clearButton}
              style={{
                marginLeft: "2%"
              }}
              onClick={() => handleBackButton()}
            >
              Back
            </Button>
          </Grid>
          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            align="center"
            style={{ fontSize: "18px" }}
          >
            <Typography>
              {`Provider (DBA Name): ${
                props.tableData.providerParameterData &&
                props.tableData.providerParameterData.dbName
              }`}
            </Typography>
          </Grid>
          <Grid item xs={4} sm={4} md={4} align="right">
            {profileType == "Admin" && (
              <CoustomButton
                onClick={() => handleSetup(true)}
                aria-controls="simple-menu"
                color="#00acc1"
                style={{
                  marginRight: "2%"
                }}
              >
                <AddIcon />
                New
              </CoustomButton>
            )}
          </Grid>
        </Grid>
        <Table className={classes.table} aria-label="simple table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    style={{
                      backgroundColor:
                        (index + 1) % 2 === 0 ? "#F6F9FF" : "white",
                      cursor: "pointer"
                    }}
                  >
                    <ThemeProvider theme={themeFont}>
                      <TableCell
                        className={classes.cellText}
                        onClick={() => handleConfigurationById(row.Id, row)}
                      >
                        {row.Start_Date}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleConfigurationById(row.Id, row)}
                      >
                        {row.End_Date}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleConfigurationById(row.Id, row)}
                      >
                        {row.Down_Payment}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleConfigurationById(row.Id, row)}
                      >
                        {row.Minimum_Down_Payment}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleConfigurationById(row.Id, row)}
                      >
                        {row.Minimum_Monthly_Payment}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleConfigurationById(row.Id, row)}
                      >
                        {row.Maximum_Monthly_Payment}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleConfigurationById(row.Id, row)}
                      >
                        {row.Review_Offers_above}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleConfigurationById(row.Id, row)}
                      >
                        {row.Created_By}
                      </TableCell>
                      <TableCell
                        className={classes.cellText}
                        align="left"
                        onClick={() => handleConfigurationById(row.Id, row)}
                      >
                        {row.Created_On}
                      </TableCell>
                    </ThemeProvider>
                    <TableCell className={classes.cellText} align="left">
                      <LongMenu
                        details={row.Id}
                        {...props}
                        setShowSetUp={(type) => handleSetup(type, row)}
                      />
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img src={noDataImage} alt="" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                  colSpan={12}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  // SelectProps={{
                  //   inputProps: { placeholder: "Show" },
                  //   native: true,
                  // }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    );
  }
  return <SetUp {...props} data={formData} setShowSetUp={() => closeView()} />;
};

ProviderParameter.propTypes = {
  getProviderList: PropTypes.func,
  tableData: PropTypes.object,
  getProviderStatus: PropTypes.func,
  changeSingleStatus: PropTypes.func,
  userDetails: PropTypes.object,
  history: PropTypes.object,
  saveProviderParameter: PropTypes.func,
  changeProviderParameterFormMode: PropTypes.func,
  getProviderParameterById: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.provideRegistration,
  userDetails: app.app
});
export default connect(mapStateToProps, {
  getProviderList,
  changeProviderFormMode,
  getProviderStatus,
  changeSingleStatus,
  saveProviderParameter,
  changeProviderParameterFormMode,
  getProviderParameterById
})(ProviderParameter);
