import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const userRole = (state = initialState.roles, action) => {
  switch (action.type) {
  case types.SET_USER_ROLE:
    return { ...state, userList: action.data };
  case types.REST_REDUCER_STATE:
    return { ...state, userList: action.data };
  default:
    return state;
  }
};

export default userRole;
