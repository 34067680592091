/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-syntax */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { makeStyles, useTheme, lighten } from "@material-ui/core/styles";
import ChipInput from "material-ui-chip-input";

import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteIcon from "@material-ui/icons/Delete";
import isEmpty from "lodash/isEmpty";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import EditIcon from "@material-ui/icons/Edit";
import CoustomButton from "components/CustomButtons/Button";
import Chip from "@material-ui/core/Chip";
import { Grid, Modal, Card } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Button } from "@material-ui/core";
import closeImage from "assets/img/Close_Image.png";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";

import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import AddEditGroup from "../RoleManager/AddEditGroup";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/Groups";
import { exportToCSV } from "../../Utils/commonUtils";
import {
  groupsListApi,
  groupsTypeListApi,
  groupsListSearchApi
} from "../../actions/TableData";
import { getGroupListData } from "../../Redux/Actions/UserRegistration";
import GroupMenu from "../../components/Menu/Groups";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

function createData(
  GName,
  GType,
  totalUser,
  totalMID,
  Created_date,
  Updated_date,
  status
) {
  return {
    GName,
    GType,
    totalUser,
    totalMID,
    Created_date,
    Updated_date,
    status
  };
}

const useStyles = makeStyles(tableStyle);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "groupName",
    className: "groupName",
    numeric: false,
    disablePadding: true,
    label: "Group Name"
  },
  {
    id: "groupStatus",
    className: "status",
    numeric: false,
    disablePadding: true,
    label: "Status"
  },
  {
    id: "groupType",
    className: "groupType",
    numeric: false,
    disablePadding: true,
    label: "Group Type"
  },
  {
    id: "groupUser",
    className: "numberOfUsers",
    numeric: false,
    disablePadding: true,
    label: "Number Of Users"
  },
  {
    id: "totalMid",
    className: "numberOfMid",
    numeric: false,
    disablePadding: true,
    label: "Number Of MID's"
  },
  {
    id: "createdDate",
    className: "createdAt",
    numeric: true,
    disablePadding: true,
    label: "Created Date"
  },
  {
    id: "createdDate",
    className: "updatedAt",
    numeric: false,
    disablePadding: true,
    label: "Updated Date"
  },
  {
    id: "actions",
    className: "actions",
    numeric: false,
    disablePadding: true,
    label: ""
  }
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={classes.tableHeader}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className={classes.cellTextHeaderOffer}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

function GroupDataList(props) {
  const classes = useStyles();

  const [order, setOrder] = React.useState("desc");
  const [initialCall, setInitialCall] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("createdDate");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);

  const [pageNo, setPageNo] = React.useState(1);
  const [groupTypeList, setGroupTypeList] = React.useState([]);
  const [emptyData, setEmptyDataModel] = React.useState(false);
  const [addEditForm, setAddEditForm] = React.useState(false);
  const [actionsMenu, setActionsMenu] = React.useState(null);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [groupList, setGroupList] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const windowWidthSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [details, setDetails] = React.useState({});
  const [rolePermission, setRolePermission] = React.useState(
    props.tableData.roleDetailsById
  );
  const [searchValues, setSearchValues] = React.useState({
    groupName: "",
    groupType: "",
    apiType: "search",
    groupStatus: "",
    pageNo: pageNo,
    limit: rowsPerPage,
    sortBy: orderBy,
    orderBy: order,
    ClientId: props.userDetails.sendClientIdApi.toString()
  });
  function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      onChangePage(event, 0);
      setPageNo(1);
    };

    const handleBackButtonClick = (event) => {
      onChangePage(event, page - 1);
      setPageNo(pageNo - 1);
    };

    const handleNextButtonClick = (event) => {
      onChangePage(event, page + 1);
      setPageNo(pageNo + 1);
    };

    const handleLastPageButtonClick = (event) => {
      setPageNo(Math.max(0, Math.ceil(count / rowsPerPage)));
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.rootPagination}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Last</Typography>
          ) : (
            <Typography>First</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Previous</Typography>
          ) : (
            <Typography>Previous</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleFirstPageButtonClick}
          aria-label="first page"
          className={classes.bottonPage}
        >
          <Typography>{page + 1}</Typography>
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <Typography>Next</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? (
            <FirstPageIcon />
          ) : (
            <Typography>Last</Typography>
          )}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };
  React.useEffect(() => {
    if (!props.tableData.groupListData) {
      let data = {
        ...searchValues,
        pageNo: pageNo,
        limit: rowsPerPage,
        sortBy: orderBy,
        orderBy: order,
        ClientId: props.userDetails.sendClientIdApi.toString()
      };
      props.getGroupListData(data);
      callGroupTypeListApi();
    }
  }, []);

  React.useEffect(() => {
    let data = {
      ...searchValues,
      pageNo: pageNo,
      limit: rowsPerPage,
      sortBy: orderBy,
      orderBy: order,
      ClientId: props.userDetails.sendClientIdApi.toString()
    };
    props.getGroupListData(data);
  }, [pageNo, rowsPerPage, orderBy, order, props.userDetails.sendClientIdApi]);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const closeAddEditForm = () => {
    setAddEditForm(false);
    setDetails({});
    getGroupListData();
    callGroupTypeListApi();
  };

  const onClickEdit = (row) => {
    setDetails(row);
    setAddEditForm(true);
  };

  const handleEdit = (type, row) => {
    if (type === "edit") {
      onClickEdit(row);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const callGroupTypeListApi = () => {
    props.groupsTypeListApi((data) => {
      setGroupTypeList(data.State_Data);
    });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const requestData = { ...searchValues };
    requestData.sortBy = property;
    requestData.orderBy = isAsc ? "desc" : "asc";
    setSearchValues(requestData);
  };

  const changeChipsValue = (values) => {
    const temp = [];

    if (values.groupName) {
      temp.push({
        key: "groupName",
        name: "Group Name",
        value: values.groupName
      });
    }

    if (values.groupStatus) {
      temp.push({
        key: "groupStatus",
        name: "Status",
        value: values.groupStatus
      });
    }

    if (values.groupType) {
      temp.push({
        key: "groupType",
        name: "Group Type",
        value: values.groupType
      });
    }

    setChipValue(temp);
  };

  const getProviderByFilterData = (value) => {
    const finalSearchData = {
      ...value,
      groupName: value.groupName == "" ? "" : value.groupName.trim()
    };
    setSearchValues(finalSearchData);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (property === "groupName") {
          chipArray.push(
            `${"Group Name"}:
                    ${value[property]}`
          );
        } else if (property === "groupType") {
          chipArray.push(
            `${"Group Type"}:
              ${value[property]}`
          );
        } else if (property === "groupStatus") {
          chipArray.push(
            `${"Group Status"}:
                    ${value[property]}`
          );
        }
      }
    }
    setChipValue(chipArray);
    props.groupsListSearchApi(finalSearchData);
  };

  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      const chipName = chip.split(":")[0];
      const newChipValues = [...chipValue];
      newChipValues.splice(index, 1);
      setChipValue([...newChipValues]);
      const newSearchValue = { ...searchValues };
      if (chipName === "Group Name") {
        newSearchValue.groupName = "";
      }
      if (chipName === "Group Type") {
        newSearchValue.groupType = "";
      }
      if (chipName === "Group Status") {
        newSearchValue.groupStatus = "";
      }

      setSearchValues({ ...newSearchValue });
      const valuesClientID = { ...newSearchValue };
      // valuesClientID.ClientId = sendApiLink;
      // valuesClientID.LoginEmail = localStorage.getItem("email");
      props.groupsListSearchApi(valuesClientID);
    }
  };

  const exportListData = () => {
    let filterValue = "";
    if (chipValue.length) {
      chipValue.forEach((key) => {
        if (key.name !== "") {
          filterValue += `${key.name}: ${key.value}`;
          filterValue += " ";
        }
      });
    }
    const sheetModuleDetails = [
      ["Module:", "Group Details"],
      ["Export On:", moment.utc(new Date()).format()],
      [
        "Exported By:",
        `${props.tableData.userDetailsById.Users_Data[0].FName} ${props.tableData.userDetailsById.Users_Data[0].LName} <${props.tableData.userDetailsById.Users_Data[0].Email}>`
      ],
      ["Filters:", filterValue]
    ];
    if (!isEmpty(rows)) {
      const mappedLogs = rows.map((row) => ({
        "Group name": row.GName,
        Status: row.status,
        "Group Type": row.GType,
        "Number of Users": row.totalUser,
        "Total Mid": row.totalMID,
        "Created Date": row.Created_date
      }));
      exportToCSV(
        mappedLogs,
        `Merchants${moment().format("MMDDYYYY")}`,
        sheetModuleDetails
      );
    } else {
      setEmptyDataModel(true);
    }
  };

  React.useEffect(() => {
    if (rolePermission !== props.tableData.roleDetailsById) {
      if (props.tableData.roleDetailsById.status) {
        setRolePermission(props.tableData.roleDetailsById);
      }
    }
  }, [props]);

  const rows = [];

  if (props.tableData.groupListData) {
    props.tableData.groupListData.data.forEach((v, i) => {
      const GName = v.groupName;
      const GType = v.groupType;
      const totalUser = v.groupUser.length;
      const totalMID = v.totalMid;
      const Created_date = moment(v.createdDate).format("MM/DD/YYYY");
      const Updated_date = moment(v.createdDate).format("MM/DD/YYYY");
      const status = v.groupStatus;
      rows.push(
        createData(
          GName,
          GType,
          totalUser,
          totalMID,
          Created_date,
          Updated_date,
          status
        )
      );
    });
  }

  return (
    <>
      <ThemeProvider theme={themeFont}>
        <Modal open={emptyData} onClose={() => setEmptyDataModel(false)}>
          <div className={classes.modalDiv}>
            <Card className={classes.modalPayOut}>
              <div
                className={classes.cardDiv1}
                style={{
                  display: "flex",
                  overflow: "hidden"
                }}
              >
                <img src={closeImage} width="75px" />

                <div
                  style={{
                    textAlign: "center",
                    color: "gray",
                    fontSize: "14px",
                    whiteSpace: "normal"
                  }}
                >
                  No Data present
                </div>
                <br />
                <Grid container alignItems="center" justifyContent="center">
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    onClick={() => setEmptyDataModel(false)}
                  >
                    ok
                  </Button>
                </Grid>
              </div>
            </Card>
          </div>
        </Modal>
        {addEditForm && (
          <AddEditGroup
            getGroupListData={getGroupListData}
            details={details}
            addEditForm={addEditForm}
            goBack={closeAddEditForm}
          />
        )}
        {!addEditForm && (
          <>
            {windowWidth && (
              <Grid container className={classes.searchContainerSetup}>
                <Grid item className={classes.margin}>
                  <div className={classes.searchWrapper}>
                    <ClickAwayListener
                      mouseEvent="onMouseDown"
                      touchEvent="onTouchStart"
                      onClickAway={() => handleClose()}
                    >
                      <Box style={{ minWidth: "35%" }}>
                        <ChipInput
                          value={chipValue.length > 0 ? chipValue : []}
                          onClick={() => handleClickOpen()}
                          style={{ display: "flex" }}
                          classes={{ chipContainer: classes.chipContainer }}
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused"
                            }
                          }}
                          InputProps={{
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused"
                            }
                          }}
                          label="Search"
                          onDelete={(chip, index) =>
                            handleDeleteChip(chip, index)
                          }
                        />
                        <Collapse
                          in={open}
                          timeout="auto"
                          unmountOnExit
                          className={classes.overLayBox}
                        >
                          <SimpleDialog
                            close={() => handleClose()}
                            groupTypeList={groupTypeList}
                            searchValues={searchValues}
                            setSearchValues={setSearchValues}
                            searchChipData={(data) =>
                              getProviderByFilterData(data)
                            }
                          />
                        </Collapse>
                      </Box>
                    </ClickAwayListener>
                    <CoustomButton
                      color="white"
                      aria-label="edit"
                      justIcon
                      round
                      onClick={() => {}}
                    >
                      <Search />
                    </CoustomButton>

                    <Button
                      size="large"
                      type="submit"
                      style={{
                        backgroundColor: "#03A430",
                        textTransform: "none",
                        borderRadius: "25px",
                        height: "48px",
                        width: "151px",
                        color: "#ffffff",
                        marginLeft: "10px",
                        marginRight: "15px",
                        fontSize: "18px",
                        fontWeight: "600"
                      }}
                      onClick={exportListData}
                      // disabled={
                      //   !isEmpty(errors) ||
                      //   isEmpty(values.id) ||
                      //   (!dirty && isEmpty(values.id)) ||
                      //   action
                      // }
                      variant=""
                      color="#ffffff"
                      // className={classes.submit}
                    >
                      Export Data
                    </Button>
                    <Button
                      size="large"
                      type="submit"
                      style={{
                        backgroundColor: "#03A430",
                        textTransform: "none",
                        borderRadius: "25px",
                        height: "48px",
                        width: "151px",
                        color: "#ffffff",
                        fontSize: "18px",
                        fontWeight: "600"
                      }}
                      onClick={() => {
                        setAddEditForm(true);
                      }}
                      // disabled={
                      //   !isEmpty(errors) ||
                      //   isEmpty(values.id) ||
                      //   (!dirty && isEmpty(values.id)) ||
                      //   action
                      // }
                      variant=""
                      color="#ffffff"
                      // className={classes.submit}
                    >
                      <AddIcon />
                      New
                    </Button>
                  </div>
                </Grid>
              </Grid>
            )}

            {!windowWidth && (
              <Grid container className={classes.searchContainerSetup}>
                <Grid
                  item
                  className={classes.margin}
                  style={{ display: "flex" }}
                >
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => handleClose()}
                  >
                    <Box style={{ width: "85%" }}>
                      <ChipInput
                        value={chipValue.length > 0 ? chipValue : []}
                        onClick={() => handleClickOpen()}
                        style={{ display: "flex" }}
                        classes={{ chipContainer: classes.chipContainer }}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused"
                          }
                        }}
                        label="Search"
                        onDelete={(chip, index) =>
                          handleDeleteChip(chip, index)
                        }
                      />
                      <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className={classes.overLayBox}
                      >
                        <SimpleDialog
                          close={() => handleClose()}
                          groupTypeList={groupTypeList}
                          searchValues={searchValues}
                          setSearchValues={setSearchValues}
                          searchChipData={(data) =>
                            getProviderByFilterData(data)
                          }
                        />
                      </Collapse>
                    </Box>
                  </ClickAwayListener>
                  <CoustomButton
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    onClick={() => {}}
                  >
                    <Search />
                  </CoustomButton>
                </Grid>
                <Grid item className={classes.expirtItemGrid}>
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={exportListData}
                    color="primary"
                  >
                    Export
                  </Button>
                  {/* <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleCloseMenu}>As Pdf</MenuItem>
              <MenuItem onClick={handleCloseMenu}>As Excel</MenuItem>
            </Menu> */}
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    onClick={() => {
                      setAddEditForm(true);
                    }}
                    aria-controls="simple-menu"
                    color="primary"
                  >
                    <AddIcon />
                    New
                  </Button>
                </Grid>
              </Grid>
            )}

            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {!isEmpty(rows) ? (
                  rows.map((row, index) => (
                    <TableRow
                      key={row._id}
                      hover
                      style={{
                        backgroundColor:
                          (index + 1) % 2 === 0 ? "#F6F9FF" : "white"
                      }}
                      className={classes.cellHeadSign}
                    >
                      <TableCell
                        className={classes.cellText}
                        align="center"
                        onClick={() => handleEdit("edit", row)}
                      >
                        <b>{row.GName}</b>
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {row.status}
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {row.GType}
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {row.totalUser}
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {row.totalMID}
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {row.Created_date}
                      </TableCell>
                      <TableCell
                        onClick={() => handleEdit("edit", row)}
                        className={classes.cellText}
                        align="center"
                      >
                        {row.Updated_date}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          color="primary"
                          aria-label="edit group"
                          component="span"
                          onClick={() => onClickEdit(row)}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell className="no-data" colSpan={12}>
                      <img
                        src={noDataImage}
                        alt=""
                        className={classes.noDataImage}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              {rows.length ? (
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        {
                          label: "All",
                          value: props.tableData.groupListData?.totalDocs
                        }
                      ]}
                      colSpan={7}
                      count={props.tableData.groupListData.totalDocs}
                      rowsPerPage={rowsPerPage}
                      page={page - 1}
                      onChangePage={handleChangePage}
                      onChangeRowsPerPage={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              ) : (
                ""
              )}
            </Table>
          </>
        )}
      </ThemeProvider>
    </>
  );
}

GroupDataList.propTypes = {
  groupsListApi: PropTypes.func,
  tableData: PropTypes.object,
  groupsTypeListApi: PropTypes.func,
  groupsListSearchApi: PropTypes.func,
  getGroupListData: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.users,
  userDetails: app.app
});
export default connect(mapStateToProps, {
  groupsListApi,
  groupsTypeListApi,
  groupsListSearchApi,
  getGroupListData
})(GroupDataList);
