/* eslint-disable no-nested-ternary */
import React, { useMemo } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Box } from "@material-ui/core";
import qs from "qs";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch
} from "react-router-dom";
import ListIcon from "@material-ui/icons/List";
import Search from "@material-ui/icons/Search";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "components/CustomButtons/Button";
import { useTheme } from "@material-ui/core/styles";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { setBackToSearchFromDetails } from "Redux/Actions/genrateOfferPatient";
import SearchIcon from "@material-ui/icons/Search";

import styles from "assets/jss/material-dashboard-react/components/headerStyle";
import MultipleSelect from "../MultiSelect/Index";
import AdminNavbarLinks from "./AdminNavbarLinks";
import { logout, setSendClientIdInApi } from "../../Redux/Actions/LoginActions";

const useStyles = makeStyles(styles);

function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(
    () => ({
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...qs.parse(location.search), // Convert string to object
        ...params
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history
    }),
    [params, match, location, history]
  );
}

function Header(props) {
  const history = useHistory();
  const theme = useTheme();
  const router = useRouter();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [pathName, prePathName] = React.useState(window.location.pathname);
  const [temp, setTemp] = React.useState(false);
  const classes = useStyles();
  const { color } = props;
  const appBarClasses = classNames({
    [` ${classes[color]}`]: color
  });

  const clientIdSelect = () => {
    window.location.reload();
  };

  const includeHistory = "";

  const profileTypeCheck =
    props.userRole.userList &&
    props.userRole.userList.Role_Permission_Data &&
    props.userRole.userList.Role_Permission_Data.Profile_Code;

  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar
        className={classes.container}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row"
        }}
      >
        {profileTypeCheck !== "Patient" && props.userProvider.userProvider && (
          <Box className={classes.newSearchDiv} style={{ maxWidth: "35%" }}>
            <MultipleSelect
              className={classes.hideSearch}
              lable
              clientId={props.menuData.userList.ClientID}
              values={props.appData.sendClientIdApi}
              data={
                props.userProvider.userProvider &&
                props.userProvider.userProvider.data
              }
              onChangeSelect={(item) => props.setSendClientIdInApi(item)}
            />
          </Box>
        )}
        <div style={{ marginLeft: "auto" }}>
          <AdminNavbarLinks {...props} />
        </div>
      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  userProvider: PropTypes.object,
  userRole: PropTypes.object,
  handleDrawerToggle: PropTypes.func,
  setopen: PropTypes.func,
  openDrawer: PropTypes.any,
  setState: PropTypes.func,
  menuData: PropTypes.object,
  logout: PropTypes.func,
  displayName: PropTypes.string,
  app: PropTypes.object,
  setBackToSearchFromDetails: PropTypes.func,
  setCallRolePermission: PropTypes.func,
  setOpenDrawerHover: PropTypes.func,
  setSendClientIdInApi: PropTypes.func,
  appData: PropTypes.object
};

const mapStateToProps = ({ userRole, app }) => ({
  menuData: userRole,
  app: app.appBarName,
  appData: app
});

export default connect(mapStateToProps, {
  logout,
  setBackToSearchFromDetails,
  setSendClientIdInApi
})(Header);
