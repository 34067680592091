import { Button } from "@material-ui/core";
import React from "react";
import { ThemeProvider, makeStyles } from "@material-ui/core/styles";
import PaymentModalData from "./components";

const useStyles = makeStyles((theme) => ({
  payNow: {
    fontFamily: "Poppins",
    background: `#1A4155`,
    borderRadius: "20px",
    padding: "12px 45px",
    color: "white",
    fontWeight: 700,
    fontSize: "16px",
    letterSpacing: "0.15em",
    "&:hover": {
      background: `#1A4155`,
      color: "white"
    }
  }
}));

const PayButton = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);

  const openPaymentModal = () => {
    setIsOpen(true);
  };
  const closePaymentModal = (event, reason) => {
    if (reason !== "backdropClick") {
      setIsOpen(false);
    }
  };

  return (
    <div style={{ margin: "2rem" }}>
      <Button onClick={openPaymentModal} className={classes.payNow}>
        Pay Now
      </Button>
      <PaymentModalData closePaymentModal={closePaymentModal} isOpen={isOpen} />
    </div>
  );
};

export default PayButton;
