/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Card } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import ExpiringApplication from "components/PatientDetailsSection/ExpiringApplication";
import AcceptPatient from "components/PatientDetailsSection/index";
import RecentPatients from "components/PatientDetailsSection/RecentPatient";
import RippleLoading from "components/RippleCard/Index";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import styles from "../../assets/jss/material-dashboard-react/components/DashBoard";

import ActivePatient from "../../components/Graph/activePatient";
import {
  getRecentContactList,
  getRecentAcceptOffer,
  getExpireApplicationList,
  getActivePatientDashboardData,
  getDefaultData,
  getMoveToCollection,
  getBadDebatsData,
  getTotalOutStandingData,
  getTotalOutStandingCount,
  getDailyDepositeData,
  getDailyDepositeCount,
  getCptCodeData
} from "../../Redux/Actions/Dashboard";
import TotalOutstanding from "../../components/Graph/totalOutStanding";
import BadDebts from "../../components/Graph/badDebts";
import Daliydeposits from "../../components/Graph/dailyDeposites";
import Defaults from "../../components/Graph/defaults";
import MoveToCollection from "../../components/Graph/moveToCollection";
import CptDustribution from "../../components/Graph/cptDistribution";
import MemberRegistrations from "../../components/Graph/memberRegistreation";

const useStyles = makeStyles(styles);

function Dashboard(props) {
  const sendApiLink = useSelector((state) => state.app.sendClientIdApi);
  const [changeClientIds, setChangeClientIds] = React.useState(sendApiLink);
  const classes = useStyles();
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  React.useEffect(() => {
    const dataClientIds = {
      ClientId: props.app.sendClientIdApi.toString()
    };
    props.getRecentContactList(dataClientIds);
    props.getRecentAcceptOffer(dataClientIds);
    props.getExpireApplicationList(dataClientIds);
    const data = {
      fromdate: "2020-01-01",
      todate: "2020-12-30",
      ClientId: props.app.sendClientIdApi.toString()
    };
    const moveCollectionData = {
      ClientId: props.app.sendClientIdApi.toString(),
      fromdate: "2020-01-01",
      todate: "2020-12-30"
    };

    props.getActivePatientDashboardData(data);
    props.getDefaultData(dataClientIds);
    props.getMoveToCollection(moveCollectionData);
    props.getBadDebatsData(dataClientIds);
    props.getTotalOutStandingData(dataClientIds);
    props.getTotalOutStandingCount(dataClientIds);
    props.getDailyDepositeCount(dataClientIds);
    props.getDailyDepositeData(dataClientIds);
    props.getCptCodeData(data);
  }, [sendApiLink, changeClientIds]);
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item lg={8} md={8} xs={12}>
          <Grid container className={classes.gridContainer} spacing={3} mb={15}>
            <Grid
              item
              xs
              className={classes.margin}
              style={{
                paddingBottom: "0px"
              }}
            >
              <Card
                className={classes.cardBox}
                style={{
                  marginBottom: "0px !important"
                }}
              >
                {props.dashboardData.loading ? (
                  <RippleLoading />
                ) : (
                  <ActivePatient {...props} />
                )}
              </Card>
            </Grid>
            <Grid item xs className={classes.margin}>
              <Card
                className={classes.cardBox}
                style={{
                  paddingBottom: "0px"
                }}
              >
                {props.dashboardData.loading ? (
                  <RippleLoading />
                ) : (
                  <TotalOutstanding {...props} />
                )}
              </Card>
            </Grid>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            style={{
              marginBottom: "27px"
            }}
          >
            <Card className={classes.cardBox}>
              {props.dashboardData.loading ? (
                <RippleLoading />
              ) : (
                <Daliydeposits {...props} />
              )}
            </Card>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Card className={classes.cardBox}>
              {props.dashboardData.loading ? (
                <RippleLoading />
              ) : (
                <MemberRegistrations {...props} />
              )}
            </Card>
          </Grid>
        </Grid>
        <Grid item lg={4} md={4} xs={12}>
          <Grid
            item
            xs
            className={classes.margin}
            style={{
              marginBottom: "27px"
            }}
          >
            <Card className={classes.cardBox}>
              {props.dashboardData.loading ? (
                <RippleLoading />
              ) : (
                <AcceptPatient {...props} />
              )}
            </Card>
          </Grid>
          <Grid
            item
            xs={windowWidth ? 5 : 12}
            sm={12}
            className={classes.margin}
          >
            <Card className={classes.cardBox}>
              {props.dashboardData.loading ? (
                <RippleLoading />
              ) : (
                <ExpiringApplication {...props} />
              )}
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {/* <Grid container className={classes.gridContainer} spacing={2}> */}
      {/* <Grid item xs={windowWidth ? true : 12} sm={12} className={classes.margin}>
          <Card>
            {props.dashboardData.loading ? <RippleLoading /> : <BadDebts {...props} />}
          </Card>
        </Grid> */}
      {/* </Grid> */}
      {/* <Grid container className={classes.gridContainer} spacing={2}> */}
      {/* <Grid item xs={windowWidth ? 2 : 12} sm={12} className={classes.cardFontSize}>
          <Card>
            <div className={classes.patientDetailsHeading}>Recent Contacts</div>
            <hr className={classes.hrStyle} />
            {props.dashboardData.loading ? <RippleLoading /> : <RecentPatients {...props} />}
          </Card>
        </Grid> */}
      {/* <Grid item xs={windowWidth ? 5 : 12} sm={12} className={classes.margin}>
        <Card>
            {props.dashboardData.loading ? <RippleLoading /> : <Defaults {...props} />}
          </Card>
        </Grid> */}

      {/* </Grid> */}
      {/* <Grid container className={classes.gridContainer} spacing={2}>
        <Grid item xs={windowWidth ? true : 12} sm={12} className={classes.margin}>
          <Card>
            {props.dashboardData.loading ? <RippleLoading /> : <CptDustribution {...props} />}
          </Card>
        </Grid>
        
      </Grid> */}
      {/* <Grid container className={classes.gridContainer} spacing={2}>
          <Grid item xs className={classes.margin}>
            <Card>
              <MonthlyDeposites />
            </Card>
          </Grid>
          <Grid item xs className={classes.margin}>
            <Card>
              <AmountfondedYtd />
            </Card>
          </Grid>
        </Grid> */}
    </div>
  );
}

Dashboard.propTypes = {
  getRecentContactList: PropTypes.func,
  dashboardData: PropTypes.object,
  getRecentAcceptOffer: PropTypes.func,
  getExpireApplicationList: PropTypes.func,
  getActivePatientDashboardData: PropTypes.func,
  getDefaultData: PropTypes.func,
  getMoveToCollection: PropTypes.func,
  getBadDebatsData: PropTypes.func,
  getTotalOutStandingData: PropTypes.func,
  getTotalOutStandingCount: PropTypes.func,
  getDailyDepositeData: PropTypes.func,
  getDailyDepositeCount: PropTypes.func,
  getCptCodeData: PropTypes.func,
  app: PropTypes.object
};

const mapStateToProps = (app) => ({
  dashboardData: app.dashboard,
  app: app.app
});
export default connect(mapStateToProps, {
  getRecentContactList,
  getRecentAcceptOffer,
  getExpireApplicationList,
  getActivePatientDashboardData,
  getDefaultData,
  getMoveToCollection,
  getBadDebatsData,
  getTotalOutStandingData,
  getTotalOutStandingCount,
  getDailyDepositeCount,
  getDailyDepositeData,
  getCptCodeData
})(Dashboard);
