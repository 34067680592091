import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import styles from "assets/jss/material-dashboard-react/components/cardMessageStyle";
import fullOrigin from "../../assets/img/imgpsh_fullsize_anim.png";

const useStyles = makeStyles(styles);

const CancelledOfferMessage = props => {
  const classes = useStyles();

  const handleClose = () => {
    props.history.push("/");
  };

  return (
    <>
      <Grid container style={{ margin: "10% 0% 0% 0%" }}>
        <Grid iten xs={2} />
        <Grid iten xs={8} align="center">
          <img src={fullOrigin} width="10%" height="auto" alt="" />
        </Grid>
        <Grid iten xs={2} />
      </Grid>
      <Grid container>
        <Grid iten xs={2} />
        <Grid
          item
          xs={8}
          style={{
            fontSize: "16px",
            color: "gray",
            fontFamily: "Source Sans Pro",
            marginTop: "2%",
          }}>
          <div align="center">
            <div>
              {props.messageDetails}
            </div>
          </div>
        </Grid>
        <Grid iten xs={2} />
      </Grid>
      <Grid container style={{ marginTop: "2%", marginBottom: "10%" }}>
        <Grid item xs={12} align="center" textAlign="center">
          <Button variant="contained" onClick={() => handleClose()} className={classes.clearButton} size="large">
            Close
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

CancelledOfferMessage.propTypes = {
  messageDetails: PropTypes.string,
  history: PropTypes.object,
};

export default CancelledOfferMessage;
