import React, { useState, useEffect } from "react";
import { Container, Typography, Grid, Box } from "@material-ui/core";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import KeyboardBackspaceRoundedIcon from "@material-ui/icons/KeyboardBackspaceRounded";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  getPatientOfferData,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  getPersonalOfferData,
  deleteGenrateOfferData,
  getNewDues,
} from "Redux/Actions/genrateOfferPatient";
import {
  getPaymentDetailsList,
  getOfferDetailsPatient,
  errorReset,
} from "Redux/Actions/PatientDashboard";
import { financeOfferListFetchCall } from "../../actions/Patient.action";
import { postAxiosAPI, formatter } from "../../actions/AxiosAPIMethods";
import { getAxiosAPI } from "../../actions/AxiosAPIMethods";
import { Link } from "react-router-dom";
import isEmpty from "lodash/isEmpty";

const TPO = (props) => {
  const classes = useStyles();
  const clientIdData =
    props.tableData.genrateOfferData &&
    !props.tableData.genrateOfferData.Offer_Status &&
    props.tableData.genrateOfferData.data[0].offerNumber.split("-");

  const [showAllOffersNonRecourse, setShowAllOffersNonRecourse] =
    React.useState(false);
  const [showAllOffers, setShowAllOffers] = React.useState(false);
  const [loader, setLoader] = useState(false);
  const [offersDataNew, setOffersDataNew] = useState({});
  const [pageTypePrint, setPageTypePrint] = React.useState(false);
  const [showOfferDEtails, setShowOfferDEtails] = React.useState(
    props.tableData.selectPatient
  );

  const [preSendEmailResult, setPreSendEmailResult] = React.useState(
    props.tableData.createOffer
  );
  // {isEmpty(values.profileVisibility) ? (

  // ) : isEmpty(values.groupId) &&
  //   values.profileVisibility === "3" ? (

  // ) : (
  //   ""
  // )}
  const [lowestNonRecourseOffers, setlowestNonRecourseOffers] = React.useState(
    []
  );
  const [lowestNonRecourse, setlowestNonRecourse] = React.useState(false);
  const checkVlaues = () => {
    if (
      Object.keys(
        props.tableData.genrateOfferData &&
          !props.tableData.genrateOfferData.Offer_Status &&
          props.tableData.genrateOfferData.data[0].NonRecourse[0]
      ).length !== 0
    ) {
      if (
        props.tableData.genrateOfferData &&
        props.tableData.genrateOfferData.data[0].NonRecourse[0].offers
          .length !== 0
      ) {
        setlowestNonRecourse(true);
        let nonValue =
          props.tableData.genrateOfferData &&
          props.tableData.genrateOfferData.data[0].NonRecourse[0].offers.reduce(
            (previous, current) => {
              return current.APR < previous.APR ? current : previous;
            }
          );
        setlowestNonRecourseOffers(nonValue);
      }
    }
  };

  const [emailType, setEmailType] = React.useState(false);
  const [generatedOffers, setGeneratedOffers] = useState([]);
  const sendApiLink = useSelector((state) => state.app.sendClientIdApi);
  // data[0].Recourse[0].offers[0]
  const [values, setValues] = React.useState(
    props?.tableData?.genrateOfferData &&
      !props.tableData.genrateOfferData.Offer_Status
      ? {
          downPayment:
            props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
              .downPayment,
          monthlyPayment:
            props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
              .monthlyPayment,
          terms:
            props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
              .termsMonth,
          startDate:
            props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
              .startDate,
          maturityDate:
            props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
              .maturityDate,
          apr: props?.tableData?.genrateOfferData?.data[0]?.Recourse[0]
            .offers[0].APR,
          dailyIntrest:
            props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
              .dailyInterestCharge,
        }
      : {
          downPayment: "",
          monthlyPayment: "",
          terms: "",
          startDate: "",
          maturityDate: "",
          apr: "",
          dailyIntrest: "",
        }
  );

  const [preError, setPreError] = React.useState(props.patientDashboard.error);
  const [paymentType, setPaymentType] = React.useState(1);

  const [paymentTypeOffer, setPaymentTypeOffer] = React.useState(false);
  const [prePaymentDetails, setPrePaymentsDetails] = React.useState(
    props.tableData.paymentDetailsData
  );

  const selectedPatient = JSON.parse(
    localStorage.getItem("selectedPatient") || "{}"
  );
  const duesId = JSON.parse(localStorage.getItem("duesId") || "[]");
  const totalAmount = localStorage.getItem("totalAmount") || "";
  const [drawerInput, setDrawerInput] = useState(
    selectedPatient ? selectedPatient?.email : ""
  );
  const [sendOffer, setSendOffer] = useState({
    loader: false,
    text: "",
  });
  const sendOfferMail = async () => {
    setSendOffer({
      loader: true,
      text: "",
    });
    const url = "/send-offers-new";
    const data = {
      offerId: props.tableData.genrateOfferData.data[0].offerId,
      userEmail: drawerInput.trim().split(","),
      userName: selectedPatient?.patientName,
      amount: totalAmount,
    };
    await postAxiosAPI(url, data).then((res) => {
      if (res.error) {
        setSendOffer({
          loader: false,
          text: "Something went wrong please try again later! ",
        });
      } else {
        setSendOffer({
          loader: false,
          text: "Mail sent successfully! ",
        });
      }
    });
  };

  const [patientActivePlans, setPatientActivePlans] = React.useState([]);

  // const patientCall = (props) => {

  //     props.patientSearch();
  // };

  const offerIdData = localStorage.getItem("offerId")
    ? localStorage.getItem("offerId")
    : localStorage.getItem("selectOfferId");
  useEffect(() => {
    const reqData = {
      offerId: offerIdData,
      payType: paymentType,
    };
    props.getGenrateOfferData(reqData);
    checkVlaues();
  }, []);

  useEffect(() => {
    getNewDues();
    checkVlaues();
    // patientCall();
  }, []);

  const getNewDues = async () => {
    const ID = selectedPatient?.patientId;
    const url = `/patients/dues-list?patientId=${ID}&ClientId=${props.app.sendClientIdApi.toString()}`;
    await getAxiosAPI(url).then((res) => {
      if (res.error) {
      } else {
        setPatientActivePlans(res.data.data);
      }
    });
  };

  const payTypeValues = {
    type: {
      fullPayment: 1,
      downPayment: 2,
      nonRecourse: 4,
    },
  };

  const handlePaymentOffer = (type, preValue) => {
    if (props.patientDashboard.paymentList.data.length === 0) {
      props.history.push("/tpo/payments");
    } else {
      localStorage.setItem("payType", type);
      let data = {};

      if (type === 1 || type === 2) {
        setPageTypePrint(false);
        if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
          data = {
            clientId: clientIdData[0],
            patientId: showOfferDEtails
              ? showOfferDEtails.data.Patient_id
              : selectedPatient.patientId,
            totalEstimate: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            copayDeductables: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            apr:
              type === 2
                ? Number(
                    props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].APR.toString().replace(
                      "%",
                      ""
                    )
                  )
                : Number(
                    props.tableData.genrateOfferData.data[0].Discounted[0].discount
                      .toString()
                      .replace("%", "")
                  ),
            startDate:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .startDate,
            endDate:
              type === 2
                ? props?.tableData?.genrateOfferData?.data[0]?.Recourse[0]
                    .offers[0].maturityDate
                : 0,
            termsMonth:
              type === 2
                ? props?.tableData?.genrateOfferData?.data[0]?.Recourse[0]
                    .offers[0].termsMonth
                : 0,
            perEMIAmt:
              type === 2
                ? Number(
                    props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].monthlyPayment
                      .toString()
                      .replace("$", "")
                  )
                : 0,
            offerStatus: 1,
            downPayment:
              type === 2
                ? Number(values.downPayment.toString().replace("$", ""))
                : 0,
            proposalIds: props.tableData.preposalDataIds,
            offerType: type,
          };
        } else if (
          props.tableData.genrateOfferData.data[0].Discounted[0].offerId
        ) {
          data = {
            offerId:
              props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
            clientId: showOfferDEtails.data.proposalList.filter(
              (key) => key._id === props.tableData.preposalDataIds[0]
            )[0].Client_ID,
            patientId: showOfferDEtails
              ? showOfferDEtails.data.Patient_id
              : selectedPatient.patientId,
            totalEstimate: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            copayDeductables: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            apr:
              type === 2
                ? Number(
                    props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].APR.toString().replace(
                      "%",
                      ""
                    )
                  )
                : Number(
                    props.tableData.genrateOfferData.data[0].Discounted[0].discount
                      .toString()
                      .replace("%", "")
                  ),
            startDate:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .startDate,
            endDate:
              type === 2
                ? props?.tableData?.genrateOfferData?.data[0]?.Recourse[0]
                    .offers[0].maturityDate
                : 0,
            termsMonth:
              type === 2
                ? props?.tableData?.genrateOfferData?.data[0]?.Recourse[0]
                    .offers[0].termsMonth
                : 0,
            perEMIAmt:
              type === 2
                ? Number(
                    props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].monthlyPayment
                      .toString()
                      .replace("$", "")
                  )
                : 0,
            offerStatus: 1,
            downPayment:
              type === 2
                ? Number(values.downPayment.toString().replace("$", ""))
                : 0,
            proposalIds: props.tableData.preposalDataIds,
            offerType: type,
          };
        }
      } else if (type === 0 && !props.tableData.paymentMakeDetails) {
        if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
          setPageTypePrint(true);

          data = {
            clientId: showOfferDEtails.data.proposalList.filter(
              (key) => key._id === props.tableData.preposalDataIds[0]
            )[0].Client_ID,
            patientId: showOfferDEtails
              ? showOfferDEtails.data.Patient_id
              : selectedPatient.patientId,
            totalEstimate: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            copayDeductables: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            apr: Number(
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].APR.toString().replace(
                "%",
                ""
              )
            ),
            startDate:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .startDate,
            endDate:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .maturityDate,
            termsMonth:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .termsMonth,
            perEMIAmt: Number(
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].monthlyPayment
                .toString()
                .replace("$", "")
            ),
            offerStatus: 3,
            downPayment: Number(values.downPayment.toString().replace("$", "")),
            proposalIds: props.tableData.preposalDataIds,
            offerType: 2,
          };

          // props.createOfferCall(data);
        } else if (
          props.tableData.genrateOfferData.data[0].Discounted[0].offerId
        ) {
          setPageTypePrint(true);
          data = {
            offerId:
              props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
            clientId: showOfferDEtails.data.proposalList.filter(
              (key) => key._id === props.tableData.preposalDataIds[0]
            )[0].Client_ID,
            patientId: showOfferDEtails
              ? showOfferDEtails.data.Patient_id
              : selectedPatient.patientId,
            totalEstimate: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            copayDeductables: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            apr: Number(
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].APR.toString().replace(
                "%",
                ""
              )
            ),
            startDate:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .startDate,
            endDate:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .maturityDate,
            termsMonth:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .termsMonth,
            perEMIAmt: Number(
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].monthlyPayment
                .toString()
                .replace("$", "")
            ),
            offerStatus: 3,
            downPayment: Number(values.downPayment.toString().replace("$", "")),
            proposalIds: props.tableData.preposalDataIds,
            offerType: 2,
          };

          // props.createOfferCall(data);
        }
      } else if (type === 4) {
        setEmailType(true);
        if (!props.tableData.genrateOfferData.data[0].Discounted[0].offerId) {
          data = {
            clientId: showOfferDEtails.data.proposalList.filter(
              (key) => key._id === props.tableData.preposalDataIds[0]
            )[0].Client_ID,
            patientId: showOfferDEtails
              ? showOfferDEtails.data.Patient_id
              : selectedPatient.patientId,
            totalEstimate: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            copayDeductables: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            apr: Number(
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].APR.toString().replace(
                "%",
                ""
              )
            ),
            startDate:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .startDate,
            endDate:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .maturityDate,
            termsMonth:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .termsMonth,
            perEMIAmt: Number(
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].monthlyPayment
                .toString()
                .replace("$", "")
            ),
            offerStatus: 2,
            downPayment: Number(values.downPayment.toString().replace("$", "")),
            proposalIds: props.tableData.preposalDataIds,
            offerType: 2,
          };
        } else if (
          props.tableData.genrateOfferData.data[0].Discounted[0].offerId
        ) {
          data = {
            offerId:
              props.tableData.genrateOfferData.data[0].Discounted[0].offerId,
            clientId: showOfferDEtails.data.proposalList.filter(
              (key) => key._id === props.tableData.preposalDataIds[0]
            )[0].Client_ID,
            patientId: showOfferDEtails
              ? showOfferDEtails.data.Patient_id
              : selectedPatient.patientId,
            totalEstimate: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            copayDeductables: Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].amountFinanced
                .toString()
                .replace("$", "")
            ),
            apr: Number(
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].APR.toString().replace(
                "%",
                ""
              )
            ),
            startDate:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .startDate,
            endDate:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .maturityDate,
            termsMonth:
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0]
                .termsMonth,
            perEMIAmt: Number(
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].monthlyPayment
                .toString()
                .replace("$", "")
            ),
            offerStatus: 3,
            downPayment: Number(values.downPayment.toString().replace("$", "")),
            proposalIds: props.tableData.preposalDataIds,
            offerType: 2,
          };
        }
      }

      if (
        type === 0 &&
        props.tableData.paymentMakeDetails &&
        props.tableData.paymentMakeDetails.status &&
        payTypeValues.type.downPayment === 2
      ) {
        const printRequest = {
          patientId: preValue
            ? preValue.data.Patient_id
            : showOfferDEtails.data.Patient_id,
          offerId: props.tableData.genrateOfferData.data[0].offerId,
        };

        props.printOfferCall(printRequest);
      }

      setPaymentType(type);
      setPaymentTypeOffer(type);
    }
  };

  // data[0].Recourse[0].APR

  // const getAllOffersApiCall = async () => {
  //   // setLoader(fa);
  //   const url = "/patients/get-patient-offers";
  //   const reqData = {
  //     dueId: duesId,
  //     offerId: ""
  //   };
  //   await postAxiosAPI(url, reqData).then((res) => {
  //     if (res.error) {
  //       setLoader(false);
  //     } else {
  //       setOffersDataNew(res.data);
  //       setLoader(false);
  //     }
  //   });
  // };
  useEffect(() => {
    if (paymentTypeOffer != false) {
      props.history.push("/patient/patientPaymentDetails");
    }
  }, [paymentTypeOffer]);

  useEffect(() => {
    getNewDues();
    const paymentListPatient = {
      patientId: showOfferDEtails
        ? showOfferDEtails.data.Patient_id
        : selectedPatient.patientId,
    };
    props.getPaymentDetailsList(paymentListPatient);
    // if(window.location.reload){
    //   // getAllOffersApiCall()

    // }
    checkVlaues();
  }, []);

  useEffect(() => {
    if (
      props.patientDashboard.paymentList &&
      props.patientDashboard.paymentList.status &&
      props.patientDashboard.offetDetailsPatient &&
      props.patientDashboard.offetDetailsPatient.status
    ) {
      props.history.push("/patient/patientPaymentDetails");
    }
    if (preError !== props.patientDashboard.error) {
      setPreError(props.patientDashboard.error);
      const amount =
        paymentType === 1
          ? Number(
              props.tableData.genrateOfferData.data[0].Discounted[0].finalPayAmount
                .toString()
                .replace("$", "")
            )
          : Number(
              props?.tableData?.genrateOfferData?.data[0]?.Recourse[0].offers[0].downPayment
                .toString()
                .replace("$", "")
            );
      const monthlyAmount =
        props.tableData.genrateOfferData.data[0].Recourse[0].offers &&
        props.tableData.genrateOfferData.data[0].Recourse[0].offers.filter(
          (i) => i?.financerNo == localStorage.getItem("finNo")
        );
      const data = {
        patientId: showOfferDEtails
          ? showOfferDEtails.data.Patient_id
          : selectedPatient.patientId,
        offerId: props.tableData.genrateOfferData.data[0].offerId,
        amount,
        paymentId: "",
        monthlyPayment: monthlyAmount
          ? monthlyAmount[0].monthlyPayment.toString().replace("$", "")
          : 0,
        payType: paymentType,
      };
      props.patientPaymentDetailsCall(data);
    }
    if (preSendEmailResult !== props.tableData.createOffer) {
      setPreSendEmailResult(props.tableData.createOffer);
      if (props.tableData.createOffer.status) {
        if (emailType) {
          const printRequest = {
            offerId: props.tableData.genrateOfferData.data[0].offerId,
          };
          props.sendEmailApiCall(printRequest);
          setEmailType(false);
        }
        if (
          payTypeValues.type.fullPayment === 1 ||
          payTypeValues.type.downPayment === 2
        ) {
          if (!pageTypePrint && !emailType) {
            const paymentListPatient = {
              patientId: showOfferDEtails
                ? showOfferDEtails.data.Patient_id
                : selectedPatient.patientId,
            };
            const offerDetailsdata = {
              offerId: props.tableData.genrateOfferData.data[0].offerId,
            };
            props.getPaymentDetailsList(paymentListPatient);
            props.getOfferDetailsPatient(offerDetailsdata);
          } else if (!emailType) {
            const printRequest = {
              patientId: showOfferDEtails
                ? showOfferDEtails.data.Patient_id
                : selectedPatient.patientId,
              offerId: props.tableData.genrateOfferData.data[0].offerId,
            };
            props.printOfferCall(printRequest);
          }
        }
      }
    }
    if (
      prePaymentDetails !== props.tableData.paymentDetailsData &&
      !props.tableData.printOfferData
    ) {
      setPrePaymentsDetails(props.tableData.paymentDetailsData);
      if (props.tableData.paymentDetailsData) {
        // props.state();
        props.history.push("/tpo/payments");
      }
    }
    if (
      prePaymentDetails !== props.tableData.paymentDetailsData &&
      props.tableData.printOfferData &&
      !emailType
    ) {
      setPrePaymentsDetails(props.tableData.paymentDetailsData);
      if (props.tableData.paymentDetailsData) {
        // props.state();
        props.history.push("/tpo/payments");
      }
    }
    checkVlaues();
  }, [props]);

  const generateOffers = async () => {
    setShowAllOffers(true);
  };

  const generateOffersNonRecourse = () => {
    setShowAllOffersNonRecourse(true);
  };

  const [state, setState] = useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = () => (
    <Grid style={{ margin: "50px" }}>
      <Typography
        onClick={() => setState({ ...state, right: false })}
        className={classes.backBtn}
      >
        <KeyboardBackspaceRoundedIcon /> Back
      </Typography>
      <Typography className={classes.offersPara}>Email</Typography>
      <TextField
        value={drawerInput}
        onChange={(e) => setDrawerInput(e.target.value)}
        className={classes.drawerInput}
        label="Email"
        variant="outlined"
      />
      <Typography className={classes.offersPara2}>Or</Typography>
      <Typography className={classes.offersPara}>Mobile (Optional)</Typography>
      <TextField
        className={classes.drawerInput}
        label="Mobile"
        variant="outlined"
      />
      <Grid className={classes.btnPairs}>
        <Button
          variant="contained"
          disabled={sendOffer.text}
          onClick={() => sendOfferMail()}
          className={classes.drawerBtns}
        >
          Send
        </Button>
        <Button
          onClick={() => setState({ ...state, right: false })}
          variant="contained"
          className={classes.drawerBtns2}
        >
          Cancel
        </Button>
      </Grid>
      <Typography
        style={{
          display: "flex",
          fontWeight: "bold",
          justifyContent: "center",
        }}
      >
        {sendOffer.text}
      </Typography>
    </Grid>
  );

  const handleBack = () => {
    const data = {
      commonText: "",
      ClientId: props.app.sendClientIdApi.toString(),
    };
    props.patientSearch(data);
    if (showAllOffers) {
      setShowAllOffers(false);
    } else {
      props.deleteGenrateOfferData("deleting Offer");
      props.history.push("/admin/offerDetails");
    }
  };

  const lowest =
    props.tableData.genrateOfferData &&
    !props.tableData.genrateOfferData.Offer_Status &&
    props.tableData.genrateOfferData.data[0].Recourse[0].offers.reduce(
      (previous, current) => {
        return current.APR < previous.APR ? current : previous;
      }
    );

  return (
    <Container>
      <>
        <Grid lg={12} container className={classes.cardMain}>
          <Grid xs={12} sm={6} className={classes.leftItem}>
            <Box className={classes.toplevelBox}>
              <Typography className={classes.tpoLevel}>
                {Math.round(selectedPatient?.tpoLevel)}
              </Typography>
            </Box>

            <Grid className={classes.leftText}>
              <Typography className={classes.mainHeading}>TPO Level</Typography>
              <Typography className={classes.bottomPara}>
                Level is calculated using The Patient <br />
                Offer Algorithm 2.0
              </Typography>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} className={classes.rightItem}>
            <Typography className={classes.balance}>Current Balance</Typography>
            <Typography className={classes.balanceDigit}>
              {props.tableData.genrateOfferData &&
              !props.tableData.genrateOfferData.Offer_Status &&
              props.tableData.genrateOfferData.data[0].Discounted[0]
                ?.amountFinanced
                ? formatter.format(
                    props.tableData.genrateOfferData.data[0].Discounted[0]
                      ?.amountFinanced
                  )
                : "$0"}
            </Typography>
          </Grid>
        </Grid>

        {props.tableData.genrateOfferData &&
        props.tableData.genrateOfferData.Offer_Status == 8 ? (
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              marginLeft: "40px",
              fontSize: "50",
              color: "black",
              background: "white",
            }}
          >
            {"Payment for this offer has already been made."}
          </div>
        ) : (
          <>
            <Typography
              style={{
                margin: "20px",
                paddingTop: "15px",
                paddingBottom: "15px",
                fontSize: "22px",
                fontWight: 600,
                lineHeight: "28px",
              }}
            >
              The following payment options are available to you:
            </Typography>
            <div>
              {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <Button
                    color="#03A430"
                    style={{
                      backgroundColor: "#03A430",
                      textTransform: "none",
                      height: "40px",
                    }}
                    onClick={toggleDrawer(anchor, true)}
                    variant="contained"
                    className={classes.sendOffersBtn}
                  >
                    Send Offers
                  </Button>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={() => {
                      toggleDrawer(anchor, false);
                      setSendOffer({ loader: false, text: "" });
                    }}
                  >
                    {list(anchor)}
                  </Drawer>
                </React.Fragment>
              ))}
            </div>
            <div style={{ marginLeft: "20px" }}>*Values are estimated</div>
            {showAllOffers ? (
              <>
                {props.tableData.genrateOfferData.data[0].Recourse[0].offers &&
                  props.tableData.genrateOfferData.data[0].Recourse[0].offers.map(
                    (loan) => (
                      <Grid
                        container
                        className={classes.offersCard}
                        key={loan?.financerNo}
                      >
                        <Grid xs={6} sm={4} lg={2}>
                          <img
                            className={classes.financerLogo}
                            key={loan.financerLogo}
                            src={loan.financerLogo}
                            alt="Recourse Logo"
                            loading="eager"
                          />
                        </Grid>
                        <Grid xs={6} sm={4} lg={2}>
                          <Typography className={classes.offersPara}>
                            APR*
                          </Typography>
                          <Typography className={classes.offersHeading}>
                            {loan.APR}%
                          </Typography>
                        </Grid>
                        <Grid xs={6} sm={4} lg={2}>
                          <Typography className={classes.offersPara}>
                            Down Payment
                          </Typography>
                          <Typography className={classes.offersHeading}>
                            {loan.downPayment}
                          </Typography>
                        </Grid>
                        <Grid xs={6} sm={4} lg={3}>
                          <Typography className={classes.offersPara}>
                            Term*
                          </Typography>
                          <Typography className={classes.offersHeading}>
                            {loan.termsMonth} mos.
                          </Typography>
                        </Grid>
                        <Grid
                          xs={6}
                          sm={4}
                          lg={2}
                          style={{
                            paddingTop: "12px",
                          }}
                        >
                          <Button
                            style={{
                              backgroundColor: "#03A430",
                              borderRadius: "25px",
                              textTransform: "none",
                              height: "45px",
                              color: "#ffffff",
                              width: "190px",
                            }}
                            size="large"
                            variant="contained"
                            color="#ffffff"
                            type="submit"
                            onClick={() => {
                              handlePaymentOffer(2);
                              localStorage.setItem("finNo", loan.financerNo);
                            }}
                            // className={classes.acceptButton}
                          >
                            Accept Offer
                          </Button>
                          <Typography
                            style={{ padding: "5px 24px", width: "100%" }}
                            className={classes.offersPara}
                          >
                            <Link
                              className={classes.offersPara}
                              style={{ borderBottom: "1px solid grey" }}
                            >
                              Offer details, rates & fees
                            </Link>
                          </Typography>
                        </Grid>
                      </Grid>
                    )
                  )}
                <Grid style={{ textAlign: "center", margin: "20px" }}>
                  <Button
                    style={{
                      borderRadius: "25px",
                      textTransform: "none",
                      height: "45px",
                    }}
                    size="large"
                    variant="contained"
                    color="#ffffff"
                    type="submit"
                    onClick={() => setShowAllOffers(false)}
                    className={classes.paymentBtn}
                  >
                    Return to Payment Options
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                {showAllOffers ? (
                  <Grid container className={classes.screen1Cards}>
                    <Grid
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ paddingBottom: "40px" }}
                    >
                      <Grid className={classes.primaryCards}>
                        <Grid className={classes.discountFlex}>
                          <Typography className={classes.cardH7}>
                            {props.tableData.genrateOfferData &&
                              props.tableData.genrateOfferData.data[0]
                                .Discounted[0].discount}
                          </Typography>
                          <Typography className={classes.cardH8}>
                            {" "}
                            Discount
                            {/* {props.tableData.genrateOfferData &&
                      props.tableData.genrateOfferData.data[0].Discounted[0]
                        .discountAmount}{" "} */}
                          </Typography>
                        </Grid>
                        <Grid container className={classes.cardSecond}>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography
                              className={classes.cardH1}
                              style={{ paddingLeft: "0px", paddingTop: "10px" }}
                            >
                              Single Payment
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Single Pay Discount
                            </Typography>
                            <Typography className={classes.cardH1}>
                              $
                              {props.tableData.genrateOfferData &&
                                props.tableData.genrateOfferData.data[0]
                                  .Discounted[0].finalPayAmount}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Total Payment
                            </Typography>
                            <Typography
                              className={classes.cardH1}
                              style={{ color: "#03A430" }}
                            >
                              ${" "}
                              {props.tableData.genrateOfferData &&
                                props.tableData.genrateOfferData.data[0]
                                  .Discounted[0]?.amountFinanced}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Button
                              style={{
                                backgroundColor: "#03A430",
                                borderRadius: "25px",
                                textTransform: "none",
                                height: "45px",
                                color: "#ffffff",
                                width: "190px",
                              }}
                              size="large"
                              variant="contained"
                              color="#ffffff"
                              type="submit"
                              onClick={() => handlePaymentOffer(1)}
                              // disabled={!isEmpty(formik.errors) || !formik.dirty}
                              // className={classes.submit}
                            >
                              Make Payment
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ paddingBottom: "40px" }}
                    >
                      <Grid className={classes.primaryCards}>
                        <Grid
                          className={classes.discountFlex}
                          style={{
                            backgroundColor: "#3E4E9B",
                            color: "#ffffff",
                            width: "10%",
                          }}
                        >
                          <Typography className={classes.cardH8}>
                            TPO Sponsored
                          </Typography>
                        </Grid>
                        <Grid container className={classes.cardSecond}>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography
                              className={classes.cardH1}
                              style={{ paddingLeft: "0px", paddingTop: "10px" }}
                            >
                              Payment Plans
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Lowest APR
                            </Typography>
                            <Typography className={classes.cardH1}>
                              $ {lowest.APR}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Lowest Monthly Payment
                            </Typography>
                            <Typography className={classes.cardH1}>
                              {lowest.monthlyPayment}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Button
                              onClick={() => generateOffers()}
                              style={{
                                backgroundColor: "#3E4E9B",
                                borderRadius: "25px",
                                textTransform: "none",
                                height: "45px",
                                color: "#ffffff",
                                width: "190px",
                              }}
                              size="large"
                              variant="contained"
                              color="#ffffff"
                              type="submit"

                              // disabled={!isEmpty(formik.errors) || !formik.dirty}
                              // className={classes.submit}
                            >
                              View Plans
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ paddingBottom: "40px" }}
                    >
                      <Grid className={classes.primaryCards}>
                        <Grid
                          className={classes.discountFlex}
                          style={{
                            backgroundColor: "#0079BD",
                            color: "#ffffff",
                            width: "11%",
                          }}
                        >
                          <Typography className={classes.cardH8}>
                            Third-Party Plan
                          </Typography>
                        </Grid>
                        <Grid container className={classes.cardSecond}>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography
                              className={classes.cardH1}
                              style={{ paddingLeft: "0px", paddingTop: "10px" }}
                            >
                              Payment Plans
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Lowest APR
                            </Typography>
                            <Typography className={classes.cardH1}>
                              $ {lowestNonRecourseOffers.APR}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Lowest Monthly Payment
                            </Typography>
                            <Typography className={classes.cardH1}>
                              {lowestNonRecourseOffers.monthlyPayment}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Button
                              onClick={() => generateOffersNonRecourse()}
                              style={{
                                backgroundColor: "#0079BD",
                                borderRadius: "25px",
                                textTransform: "none",
                                height: "45px",
                                color: "#ffffff",
                                width: "190px",
                              }}
                              size="large"
                              variant="contained"
                              color="#ffffff"
                              type="submit"

                              // disabled={!isEmpty(formik.errors) || !formik.dirty}
                              // className={classes.submit}
                            >
                              View Plans
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </>
            )}
            {showAllOffersNonRecourse ? (
              <>
                {props.tableData.genrateOfferData.data[0].NonRecourse[0].offers
                  ? props.tableData.genrateOfferData.data[0].NonRecourse[0].offers.map(
                      (loan) => (
                        <Grid container className={classes.offersCard}>
                          <Grid xs={6} sm={4} lg={2}>
                            <img
                              className={classes.financerLogo}
                              key={
                                props.tableData.genrateOfferData.data[0]
                                  .NonRecourse[0].financerLogo
                              }
                              src={
                                props.tableData.genrateOfferData.data[0]
                                  .NonRecourse[0].financerLogo
                              }
                              alt="Recourse Logo"
                              loading="eager"
                            />
                          </Grid>
                          <Grid xs={6} sm={4} lg={2}>
                            <Typography className={classes.typographyAmmount}>
                              Lowest APR
                            </Typography>
                            <Typography className={classes.cardH1}>
                              $ {loan.APR}
                            </Typography>
                          </Grid>
                          <Grid xs={6} sm={4} lg={2}>
                            <Typography className={classes.offersPara}>
                              Down Payment
                            </Typography>
                            <Typography className={classes.offersHeading}>
                              {loan?.downPayment}
                            </Typography>
                          </Grid>
                          <Grid xs={6} sm={4} lg={3}>
                            <Typography className={classes.offersPara}>
                              Term*
                            </Typography>
                            <Typography className={classes.offersHeading}>
                              {loan?.termsMonth} mos.
                            </Typography>
                          </Grid>

                          <Grid xs={6} sm={4} lg={2}>
                            <Button
                              style={{
                                backgroundColor: "#03A430",
                                borderRadius: "25px",
                                textTransform: "none",
                                height: "45px",
                                color: "#ffffff",
                                width: "190px",
                              }}
                              size="large"
                              variant="contained"
                              color="#ffffff"
                              type="submit"
                              onClick={() => {
                                handlePaymentOffer(2);
                                localStorage.setItem("finNo", loan.financerNo);
                              }}
                            >
                              Accept Offer
                            </Button>
                            <Typography
                              style={{ padding: "5px 24px", width: "100%" }}
                              className={classes.offersPara}
                            >
                              <Link
                                className={classes.offersPara}
                                style={{ borderBottom: "1px solid grey" }}
                              >
                                Offer details, rates & fees
                              </Link>
                            </Typography>
                          </Grid>
                        </Grid>
                      )
                    )
                  : null}
                <Grid style={{ textAlign: "center", margin: "20px" }}>
                  <Button
                    style={{
                      borderRadius: "25px",
                      textTransform: "none",
                      height: "45px",
                    }}
                    size="large"
                    variant="contained"
                    color="#ffffff"
                    type="submit"
                    onClick={() => setShowAllOffersNonRecourse(false)}
                    className={classes.paymentBtn}
                  >
                    Return to Payment Options
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                {!showAllOffers ? (
                  <Grid container className={classes.screen1Cards}>
                    <Grid
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ paddingBottom: "40px" }}
                    >
                      <Grid className={classes.primaryCards}>
                        <Grid className={classes.discountFlex}>
                          <Typography className={classes.cardH7}>
                            {props.tableData.genrateOfferData &&
                              props.tableData.genrateOfferData.data[0]
                                .Discounted[0].discount}
                          </Typography>
                          <Typography className={classes.cardH8}>
                            {" "}
                            Discount
                            {/* {props.tableData.genrateOfferData &&
                      props.tableData.genrateOfferData.data[0].Discounted[0]
                        .discountAmount}{" "} */}
                          </Typography>
                        </Grid>
                        <Grid container className={classes.cardSecond}>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography
                              className={classes.cardH1}
                              style={{ paddingLeft: "0px", paddingTop: "10px" }}
                            >
                              Single Payment
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Single Pay Discount
                            </Typography>
                            <Typography className={classes.cardH1}>
                              $
                              {props.tableData.genrateOfferData &&
                                props.tableData.genrateOfferData.data[0]
                                  .Discounted[0].finalPayAmount}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Total Payment
                            </Typography>
                            <Typography
                              className={classes.cardH1}
                              style={{ color: "#03A430" }}
                            >
                              ${" "}
                              {props.tableData.genrateOfferData &&
                                props.tableData.genrateOfferData.data[0]
                                  .Discounted[0]?.amountFinanced}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Button
                              style={{
                                backgroundColor: "#03A430",
                                borderRadius: "25px",
                                textTransform: "none",
                                height: "45px",
                                color: "#ffffff",
                                width: "190px",
                              }}
                              size="large"
                              variant="contained"
                              color="#ffffff"
                              type="submit"
                              onClick={() => handlePaymentOffer(1)}
                              // disabled={!isEmpty(formik.errors) || !formik.dirty}
                              // className={classes.submit}
                            >
                              Make Payment
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ paddingBottom: "40px" }}
                    >
                      <Grid className={classes.primaryCards}>
                        <Grid
                          className={classes.discountFlex}
                          style={{
                            backgroundColor: "#3E4E9B",
                            color: "#ffffff",
                            width: "10%",
                          }}
                        >
                          <Typography className={classes.cardH8}>
                            TPO Sponsored
                          </Typography>
                        </Grid>
                        <Grid container className={classes.cardSecond}>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography
                              className={classes.cardH1}
                              style={{ paddingLeft: "0px", paddingTop: "10px" }}
                            >
                              Payment Plans
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Lowest APR
                            </Typography>
                            <Typography className={classes.cardH1}>
                              $ {lowest.APR}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Typography className={classes.typographyAmmount}>
                              Lowest Monthly Payment
                            </Typography>
                            <Typography className={classes.cardH1}>
                              {lowest.monthlyPayment}
                            </Typography>
                          </Grid>
                          <Grid item lg={3} md={3} xs={12}>
                            <Button
                              onClick={() => generateOffers()}
                              style={{
                                backgroundColor: "#3E4E9B",
                                borderRadius: "25px",
                                textTransform: "none",
                                height: "45px",
                                color: "#ffffff",
                                width: "190px",
                              }}
                              size="large"
                              variant="contained"
                              color="#ffffff"
                              type="submit"

                              // disabled={!isEmpty(formik.errors) || !formik.dirty}
                              // className={classes.submit}
                            >
                              View Plans
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      xs={12}
                      md={12}
                      lg={12}
                      style={{ paddingBottom: "40px" }}
                    >
                      {lowestNonRecourse == true ? (
                        <Grid className={classes.primaryCards}>
                          <Grid
                            className={classes.discountFlex}
                            style={{
                              backgroundColor: "#0079BD",
                              color: "#ffffff",
                              width: "11%",
                            }}
                          >
                            <Typography className={classes.cardH8}>
                              Third-Party Plan
                            </Typography>
                          </Grid>
                          <Grid container className={classes.cardSecond}>
                            <Grid item lg={3} md={3} xs={12}>
                              <Typography
                                className={classes.cardH1}
                                style={{
                                  paddingLeft: "0px",
                                  paddingTop: "10px",
                                }}
                              >
                                Payment Plans
                              </Typography>
                            </Grid>
                            <Grid item lg={3} md={3} xs={12}>
                              <Typography className={classes.typographyAmmount}>
                                Lowest APR
                              </Typography>
                              <Typography className={classes.cardH1}>
                                $ {lowestNonRecourseOffers.APR}
                              </Typography>
                            </Grid>
                            <Grid item lg={3} md={3} xs={12}>
                              <Typography className={classes.typographyAmmount}>
                                Lowest Monthly Payment
                              </Typography>
                              <Typography className={classes.cardH1}>
                                {lowestNonRecourseOffers.monthlyPayment}
                              </Typography>
                            </Grid>
                            <Grid item lg={3} md={3} xs={12}>
                              <Button
                                onClick={() => generateOffersNonRecourse()}
                                style={{
                                  backgroundColor: "#0079BD",
                                  borderRadius: "25px",
                                  textTransform: "none",
                                  height: "45px",
                                  color: "#ffffff",
                                  width: "190px",
                                }}
                                size="large"
                                variant="contained"
                                color="#ffffff"
                                type="submit"

                                // disabled={!isEmpty(formik.errors) || !formik.dirty}
                                // className={classes.submit}
                              >
                                View Plans
                              </Button>
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                ) : (
                  ""
                )}
              </>
            )}
          </>
        )}
      </>
    </Container>
  );
};

TPO.propTypes = {
  patientSearch: PropTypes.func,
  tableData: PropTypes.object,
  sendEmailApiCall: PropTypes.object,
  uploadOfferDocument: PropTypes.func,
  updatePatientDetailsOffer: PropTypes.func,
  getGenrateOfferData: PropTypes.func,
  deleteGenrateOfferData: PropTypes.func,
  getNewDues: PropTypes.func,
  createOfferCall: PropTypes.func,
  printOfferCall: PropTypes.func,
  genrateOfferHyperLink: PropTypes.func,
  deleteDocumentCall: PropTypes.func,
  patientPaymentDetailsCall: PropTypes.func,
  getPatientOfferData: PropTypes.func,
  emptyGraphData: PropTypes.func,
  cptCodeDataCall: PropTypes.func,
  editPatientCall: PropTypes.func,
  getAllActivePlanList: PropTypes.func,
  getPaymentDetailsList: PropTypes.func,
  patientDashboard: PropTypes.object,
  getOfferDetailsPatient: PropTypes.func,
  getAllDocumentList: PropTypes.func,
  offerDownloadFile: PropTypes.func,
  state: PropTypes.func,
  history: PropTypes.object,
  addSetUpMonthlyPayment: PropTypes.func,
  getAllCompletedPlanList: PropTypes.func,
  setUpMonthlyOffer: PropTypes.func,
  setLoadingFalse: PropTypes.func,
  errorReset: PropTypes.func,
  getPersonalOfferData: PropTypes.func,
  financeOfferListFetchCall: PropTypes.func,
  app: PropTypes.object,
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  patientDashboard: app.patientDashboard,
  userProvider: app.users,
  app: app.app,
});

export default connect(mapStateToProps, {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  getPersonalOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  getOfferDetailsPatient,
  getPatientOfferData,
  getPaymentDetailsList,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  financeOfferListFetchCall,
  errorReset,
  deleteGenrateOfferData,
  getNewDues,
})(TPO);

const useStyles = makeStyles({
  financerLogo: { objectFit: "contain", height: "80px", width: "100px" },
  root: {
    padding: "2px 15px",
    display: "flex",
    alignItems: "center",
    width: 300,
    borderRadius: "24px",
    color: "#555555",
    backgroundColor: "#EFF4FF",
  },
  discountFlex: {
    // position: "absolute",
    // top: "-14px",
    width: "9%",
    borderRadius: "7px",
    marginTop: "-43px",
    backgroundColor: "#FFD568",
    padding: "10px 15px",
    display: "flex",
    alignItems: "center",
    // borderRadius: "0px 0px 15px 15px",
  },
  card2middle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardH1: {
    fontWeight: 600,
    fontSize: "26px",
    lineHeight: "35px",
  },
  cardH2: {
    fontWeight: 400,
    padding: "2px 0",
    fontSize: "18px",
    lineHeight: "24px",
  },
  cardH7: {
    fontWeight: 600,
    color: "#333333",
    fontSize: "14px",
    lineHeight: "10px",
    paddingRight: "5px",
    textTransform: "none",
  },
  cardH8: {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "10px",
    textTransform: "none",
  },
  cardH4: {
    fontWeight: 600,
    padding: "2px 0",
    fontSize: "48px",
    paddingBottom: "30px",
    paddingTop: "5px",
    lineHeight: "52px",
  },
  cardH5: {
    fontWeight: 600,
    padding: "5px 0",
    fontSize: "32px",
    lineHeight: "34px",
  },
  topNameGrid: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  cardH6: {
    fontWeight: 400,
    paddingTop: "5px",
    paddingBottom: "45px",
    fontSize: "16px",
    lineHeight: "18px",
  },
  cardH3: {
    fontWeight: 600,
    color: "#27337D",
    padding: "5px 0",
    fontSize: "18px",
    lineHeight: "22px",
  },
  primaryCards: {
    backgroundColor: "white !important",
    padding: "30px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    borderRadius: "15px",
    height: "50px",
  },
  cardMain: {
    borderRadius: "15px",
    margin: "40px 20px 20px 20px",
    padding: "20px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    backgroundColor: "#FFFFFF",
  },
  screen1Cards: {
    margin: "20px",
  },
  dividerCss: {
    height: "2px !important",
    color: "#DBE3F3 !important",
  },
  input: {
    // marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  iconButtonBell: {
    padding: "10px",
    margin: "0 10px",
    backgroundColor: "#3E4E9B",
  },
  divider: {
    height: 28,
    margin: 4,
  },

  offersCard: {
    borderRadius: "15px",
    margin: "20px",
    padding: "24px",
    boxShadow: "4px 4px 18px rgba(221, 227, 241, 0.65)",
    backgroundColor: "#FFFFFF",
  },
  tpoLevel: {
    fontSize: "32px",
    fontWeight: "600",
    lineHeight: "56px",
  },
  cardSecond: {
    paddingTop: "10px",
  },
  toplevelBox: {
    backgroundColor: "#FFD568",
    paddingTop: "3px",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "55px",
    height: "55px",
    borderRadius: "10px",
  },
  leftItem: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: 'space-between',
    margin: "auto 0px",
  },
  rightItem: {
    display: "flex",
    flexDirection: "column",
    // justifyContent: 'right',
    alignItems: "flex-end",
    margin: "auto 0px",
  },
  leftText: {
    margin: "auto 20px",
  },
  mainHeading: {
    fontSize: "26px",
    fontWeight: "600",
    lineHeight: "30px",
  },
  bottomPara: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "22px",
  },
  balance: {
    fontSize: "18px",
    fontWeight: "600",
    lineHeight: "22px",
    color: "#27337D",
    padding: "0 15px",
  },
  balanceDigit: {
    fontSize: "48px",
    fontWeight: "600",
    lineHeight: "52px",
    // color: '#27337D'
  },
  offersHeading: {
    fontSize: "28px",
    fontWeight: "600",
    lineHeight: "30px",
  },
  offersPara: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    color: "#777777",
    paddingTop: "5px",
  },
  offersPara2: {
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px",
    color: "#777777",
    paddingTop: "5px",
    textAlign: "center",
    margin: "10px 0px",
  },
  acceptButton: {
    backgroundColor: "#008768",
    borderRadius: "5px",
    // width: "auto",
    textTransform: "none",
    color: "#fff",
    fontSize: "18px",
    lineHeight: "22px",
    fontWeight: "400",
    margin: "0 10px",
    padding: "10px 30px !important",
  },
  paymentBtn: {
    backgroundColor: "#0079BD",
    borderRadius: "5px",
    padding: "10px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
  },
  paymentBtnPrimary: {
    backgroundColor: "#008768",
    width: "100%",
    borderRadius: "5px",
    padding: "25px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    marginTop: "30px",
  },
  paymentBtnPrimary2: {
    backgroundColor: "#0079BD",
    width: "100%",
    borderRadius: "5px",
    padding: "25px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "24px",
    marginTop: "30px",
  },
  drawerBtns: {
    backgroundColor: "#008768",
    borderRadius: "5px",
    padding: "12px 50px",
    textTransform: "none",
    color: "#fff",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    margin: "10px 0px",
  },
  drawerBtns2: {
    // backgroundColor: '#008768',
    borderRadius: "5px",
    padding: "12px 50px",
    textTransform: "none",
    // color: '#fff',
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    margin: "10px 0px",
  },
  sendOffersBtn: {
    // height: "auto",
    // minWidth: "40px",
    backgroundColor: "#0079BD",
    borderRadius: "5px",
    padding: "15px 20px",
    position: "fixed",
    right: "-62px",
    top: "50%",
    bottom: "50%",
    transform: "rotate(-90deg)",
    textTransform: "none",
    // width: "0.6em !important",
    color: "#fff",
    fontWeight: "400",
    fontSize: "22px",
    lineHeight: "24px",
  },
  drawerInput: {
    width: "350px",
    margin: "10px 0px",
    // padding: '10px 0px',
    height: "40px",
  },
  backBtn: {
    color: "#27337D",
    marginBottom: "50px",
    cursor: "pointer",
  },
  btnPairs: {
    margin: "50px 0px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "column",
  },
  topHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "20px",
  },
  headerContent: {
    display: "flex",
    flexDirection: "row",
  },
  useName: {
    fontWeight: 600,
    fontSize: "18px",
    lineHeight: "24px",
    padding: "0 20px",
  },
});
