/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Card, Modal } from "@material-ui/core";
import closeImage from "assets/img/Close_Image.png";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import isEmpty from "lodash/isEmpty";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import noDataImage from "../../assets/img/noDataImage.png";
import { dailyDeposite, activePatient } from "../../Redux/Actions/TableAction";
import { SimpleDialog } from "../../components/DialogBox/DailyDeposits";
import { exportToCSV } from "../../Utils/commonUtils";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ChipInput from "material-ui-chip-input";

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

const useStyles = makeStyles(tableStyle);

function createData(
  patientName,
  providerId,
  ehrId,
  paymentDate,
  transactioId,
  paymentAmount
) {
  return {
    patientName,
    providerId,
    ehrId,
    paymentDate,
    transactioId,
    paymentAmount
  };
}

const DailyDeposite = (props) => {
  const classes = useStyles();

  const [initialCall, setInitialCall] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const [emptyData, setEmptyDataModel] = React.useState(false);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("Payment_Date");
  const [page, setPage] = React.useState(0);
  const [chipValue, setChipValue] = React.useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selected, setSelected] = React.useState([]);
  const [pageNo, setPageNo] = React.useState(1);
  const [searchVlaues, setSearchValues] = React.useState({
    ClientId:
      props.appData.sendClientIdApi && props.appData.sendClientIdApi.toString(),
    loginEmail: localStorage.getItem("email"),
    patientName: "",
    balance: "",
    balanceLess: "",
    offerFromDate: "",
    offerToDate: "",
    limit: rowsPerPage,
    sortBy: orderBy,
    orderBy: order,
    pageNo: pageNo
  });

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const requestData = { ...searchVlaues };
    requestData.sortBy = property;
    requestData.orderBy = isAsc ? "desc" : "asc";
    requestData.ClientId = props.appData.sendClientIdApi.toString();

    setSearchValues(requestData);
  };

  React.useEffect(() => {
    const data = {
      ...searchVlaues,
      pageNo: pageNo,
      limit: rowsPerPage,
      sortBy: orderBy,
      orderBy: order,
      ClientId: props.appData.sendClientIdApi.toString()
    };
    props.dailyDeposite(data);
  }, [page, rowsPerPage, orderBy, order, props.appData.sendClientIdApi]);

  React.useEffect(() => {
    if (props.tableData.dailyDeposite?.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData]);

  const rows = [];
  if (
    props.tableData.dailyDeposite &&
    props.tableData.dailyDeposite.Daily_deposite_Data
  ) {
    props.tableData.dailyDeposite.Daily_deposite_Data.forEach((v, i) => {
      const providerId = v.Provider_Id;
      const ehrId = v.EHR_ID;
      const patientName = v.Patient_Name;
      const paymentDate = moment(v.Payment_Date).format("MM/DD/YYYY");
      const paymentAmount = v.Payment_amt;
      const transactioId = v.Transaction_id;
      rows.push(
        createData(
          patientName,
          providerId,
          ehrId,
          paymentDate,
          transactioId,
          paymentAmount
        )
      );
    });
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const requestData = { ...searchVlaues };
    requestData.limit = parseInt(event.target.value, 10);
    requestData.pageNo = 1;
    requestData.ClientId = props.appData.sendClientIdApi.toString();
    setSearchValues(requestData);
    setPage(0);
  };

  const onClickTransactionHandle = (mid) => {
    const data = {
      loginEmail: localStorage.getItem("email"),
      mid
    };
    props.activePatient(data);
    props.history.push("/admin/Transactions");
  };

  const exportListData = () => {
    const filterValue = "";
    // if (chipValue.length) {
    //   for (const property in chipValue) {
    //     if (chipValue[property] !== "") {
    //       filterValue += `${chipValue[property]}`;
    //     }
    //   }
    // }
    const sheetModuleDetails = [
      ["Module:", "Daily Deposite Details"],
      ["Export On:", moment.utc(new Date()).format()],
      [
        "Exported By:",
        `${props.userDetails.userDetailsById.Users_Data[0].FName} ${props.userDetails.userDetailsById.Users_Data[0].LName} <${props.userDetails.userDetailsById.Users_Data[0].Email}>`
      ],
      ["Filters:", filterValue]
    ];
    if (!isEmpty(rows)) {
      const mappedLogs = rows.map((row) => ({
        Patient: row.patientName,
        Provider: row.providerId,
        "EHR ID": row.ehrId,
        "Payment Date": row.paymentDate,
        "Transaction ID": row.transactioId,
        "Amount ($)": row.paymentAmount
      }));
      exportToCSV(
        mappedLogs,
        `Daily Deposite${moment().format("MMDDYYYY")}`,
        sheetModuleDetails
      );
    } else {
      setEmptyDataModel(true);
    }
  };

  const headCells = [
    {
      id: "Patient_Name",
      className: "Patient_Name",
      numeric: false,
      disablePadding: true,
      label: "Patient"
    },
    {
      id: "Provider_Id",
      className: "Provider_Id",
      numeric: false,
      disablePadding: true,
      label: "Provider"
    },
    {
      id: "EHR_ID",
      className: "EHR_ID",
      numeric: false,
      disablePadding: true,
      label: " EHR ID"
    },
    {
      id: "Payment_Date",
      className: "Payment_Date",
      numeric: false,
      disablePadding: true,
      label: "Payment Date"
    },
    {
      id: "Transaction_id",
      className: "Transaction_id",
      numeric: true,
      disablePadding: false,
      label: "Transaction ID"
    },
    {
      id: "Payment_amt",
      className: "Payment_amt",
      numeric: false,
      disablePadding: true,
      label: "Amount"
    }
  ];

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              className={classes.cellTextHeaderOffer}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell />
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };
  function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      setPageNo(1);
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      setPageNo(pageNo - 1);
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      setPageNo(pageNo + 1);
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      setPageNo(Math.max(0, Math.ceil(count / rowsPerPage)));
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.rootPagination}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Last</Typography>
          ) : (
            <Typography>First</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Previous</Typography>
          ) : (
            <Typography>Previous</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleFirstPageButtonClick}
          aria-label="first page"
          style={{
            backgroundColor: "#00acc1"
          }}
          className={classes.bottonPage}
        >
          <Typography>{page + 1}</Typography>
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <Typography>Next</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? (
            <FirstPageIcon />
          ) : (
            <Typography>Last</Typography>
          )}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };
  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      const chipName = chip.split(":")[0];
      const newChipValues = [...chipValue];
      newChipValues.splice(index, 1);
      setChipValue([...newChipValues]);
      const newSearchValue = { ...searchVlaues };
      if (chipName === "Patient Name") {
        newSearchValue.patientName = "";
      }
      if (chipName === "Amount more than Equal ($)") {
        newSearchValue.balance = "";
      }
      if (chipName === "Amount less than Equal ($)") {
        newSearchValue.balanceLess = "";
      }
      if (chipName === "Offer Date From") {
        newSearchValue.offerFromDate = "";
      }
      if (chipName === "Offer Date To") {
        newSearchValue.offerToDate = "";
      }
      setSearchValues({ ...newSearchValue });
      const valuesClientID = { ...newSearchValue };
      // valuesClientID.ClientId = sendApiLink;
      // valuesClientID.LoginEmail = localStorage.getItem("email");
      props.dailyDeposite(valuesClientID);
      console.log("newSearchValue", newSearchValue);
      console.log("chipName", chipName);
    }
  };

  const getDailyDepositFilterDataHandle = (value) => {
    const finalSearchData = {
      ...value,
      patientName: value.patientName == "" ? "" : value.patientName.trim(),
      balance: value.balance == "" ? "" : value.balance.trim(),
      balanceLess: value.balanceLess == "" ? "" : value.balanceLess.trim(),
      orderBy: order,
      sortBy: orderBy,
      limit: rowsPerPage,
      pageNo: pageNo,
      ClientId:
        props.appData.sendClientIdApi &&
        props.appData.sendClientIdApi.toString(),
      loginEmail: localStorage.getItem("email")
    };
    setSearchValues(finalSearchData);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (property === "patientName") {
          chipArray.push(
            `${"Patient Name"}:
                    ${value[property]}`
          );
        } else if (property === "balance") {
          chipArray.push(
            `${"Amount more than Equal ($)"}:
              ${value[property]}`
          );
        } else if (property === "balanceLess") {
          chipArray.push(
            `${"Amount less than Equal ($)"}:
                    ${value[property]}`
          );
        } else if (property === "offerFromDate") {
          chipArray.push(
            `${"Offer Date From"}:
                ${value[property]}`
          );
        } else if (property === "offerToDate") {
          chipArray.push(
            `${"Offer Date To"}:
                  ${value[property]}`
          );
        }
      }
    }

    props.dailyDeposite(finalSearchData);
    setChipValue(chipArray);
  };

  return (
    <>
      <ThemeProvider theme={themeFont}>
        <Modal open={emptyData} onClose={() => setEmptyDataModel(false)}>
          <div className={classes.modalDiv}>
            <Card className={classes.modalPayOut}>
              <div
                className={classes.cardDiv1}
                style={{
                  display: "flex",
                  overflow: "hidden"
                }}
              >
                <img src={closeImage} width="75px" />

                <div
                  style={{
                    textAlign: "center",
                    color: "gray",
                    fontSize: "14px",
                    whiteSpace: "normal"
                  }}
                >
                  No Data present
                </div>
                <br />
                <Grid container alignItems="center" justifyContent="center">
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    onClick={() => setEmptyDataModel(false)}
                  >
                    ok
                  </Button>
                </Grid>
              </div>
            </Card>
          </div>
        </Modal>
        <TableContainer
          className={classes.TableContainer}
          component={Paper}
          elevation={0}
        >
          {windowWidth && (
            <Grid container className={classes.searchContainer}>
              <Grid>
                <Typography className={classes.upperMiniHeadings}>
                  REPORTS
                </Typography>
                <Typography className={classes.uppserBoldHeading}>
                  Daily Deposits
                </Typography>
              </Grid>
              <Grid container className={classes.searchContainer}>
                <Grid item className={classes.margin}>
                  <Box style={{ width: "25%", marginLeft: "-10px" }}>
                    Deposited Amount:{" "}
                    <span style={{ color: "#008768" }}>
                      $
                      {props.tableData.dailyDeposite &&
                        props.tableData.dailyDeposite.paymentAmount}
                    </span>
                  </Box>

                  <div className={classes.searchWrapper}>
                    <ClickAwayListener
                      mouseEvent="onMouseDown"
                      touchEvent="onTouchStart"
                      onClickAway={() => handleClose()}
                    >
                      <Box
                        // className={classes.newSearchDiv}
                        style={{
                          minWidth: "50%",
                          display: "block",
                          backgroundColor: "#ffffff",
                          borderRadius: "20px",
                          marginRight: "10px"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: "5px",
                            marginBottom: "5px"
                          }}
                        >
                          <Box style={{ width: "100%" }}>
                            <ChipInput
                              disableUnderline
                              value={chipValue.length > 0 ? chipValue : []}
                              onClick={() => handleClickOpen()}
                              style={{ display: "flex", marginLeft: "10px" }}
                              classes={{ chipContainer: classes.chipContainer }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.activeInputLableColor,
                                  focused: "focused"
                                }
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.activeInputColor,
                                  input: classes.resize,
                                  focused: "focused"
                                }
                              }}
                              placeholder="Search Report"
                              onDelete={(chip, index) =>
                                handleDeleteChip(chip, index)
                              }
                            />
                          </Box>
                          <Box
                            style={{ paddingTop: "10px", paddingRight: "10px" }}
                          >
                            <SearchIcon />
                          </Box>
                        </div>

                        <Collapse
                          in={open}
                          timeout="auto"
                          unmountOnExit
                          className={classes.overLayBox}
                        >
                          <SimpleDialog
                            close={() => handleClose()}
                            searchValue={searchVlaues}
                            seacrhChipData={(data) =>
                              getDailyDepositFilterDataHandle(data)
                            }
                          />
                        </Collapse>
                      </Box>
                    </ClickAwayListener>
                    <Button
                      size="large"
                      type="submit"
                      className={classes.exportButton}
                      onClick={exportListData}
                      variant=""
                      color="#ffffff"
                    >
                      Export Data
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!windowWidth && (
            <Grid container className={classes.searchContainer}>
              <Grid item className={classes.margin} style={{ display: "flex" }}>
                <div className={classes.searchWrapper}>
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => handleClose()}
                  >
                    <Box style={{ width: "85%" }}>
                      <TextField
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused"
                          }
                        }}
                        inputProps={{
                          autoComplete: "off"
                        }}
                        fullWidth
                        label="Search"
                        onClick={() => handleClickOpen()}
                        name="Search"
                        id="Search"
                      />
                      <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className={classes.overLayBox}
                      >
                        <SimpleDialog
                          close={() => handleClose()}
                          searchValue={searchVlaues}
                          seacrhChipData={(data) =>
                            getDailyDepositFilterDataHandle(data)
                          }
                        />
                      </Collapse>
                    </Box>
                  </ClickAwayListener>
                  <Button
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    onClick={() => {}}
                  >
                    <Search />
                  </Button>
                  <Grid item className={classes.expirtItemGrid}>
                    <Button
                      size="large"
                      type="submit"
                      style={{
                        backgroundColor: "#03A430",
                        textTransform: "none",
                        borderRadius: "25px",
                        height: "48px",
                        color: "#ffffff",
                        fontSize: "18px",
                        fontWeight: "600"
                      }}
                      onClick={exportListData}
                      variant=""
                      color="#ffffff"
                    >
                      Export Data
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          )}
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {!isEmpty(rows) ? (
                rows.map((row, index) => (
                  <TableRow
                    style={{
                      backgroundColor:
                        (index + 1) % 2 === 0 ? "#F6F9FF" : "white"
                    }}
                  >
                    <TableCell
                      className={classes.cellTextPatient}
                      onClick={() => onClickTransactionHandle(row.mid)}
                      style={{ cursor: "pointer" }}
                    >
                      {row.patientName}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      {row.providerId}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      {row.ehrId}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      {row.paymentDate}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      {row.transactioId}
                    </TableCell>
                    <TableCell
                      className={classes.cellTextNew}
                      align="center"
                      style={{ color: "#008768" }}
                    >
                      ${row.paymentAmount}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={12}>
                    <img
                      src={noDataImage}
                      alt=""
                      className={classes.noDataImage}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value: props.tableData.dailyDeposite.totalDocs
                    }
                  ]}
                  colSpan={12}
                  count={props.tableData.dailyDeposite.totalDocs}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </>
  );
};

DailyDeposite.propTypes = {
  dailyDeposite: PropTypes.func,
  activePatient: PropTypes.func,
  tableData: PropTypes.any,
  userDetails: PropTypes.any
};

const mapStateToProps = (table) => ({
  tableData: table.table,
  userDetails: table.users,
  appData: table.app
});
export default connect(mapStateToProps, {
  dailyDeposite,
  activePatient
})(DailyDeposite);
