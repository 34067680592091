import qs from "qs";
import * as types from "./ActionTypes";
import { post, get, put, modifiedPost } from "../Helper/index";
import * as urls from "../../Uintls/Urls";

export const provideRegistration = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDE_REGISTRATION_CALL });
  post({
    url: urls.PROVIDE_REGISTRATION_URL,
    success: types.PROVIDE_REGISTRATION_SUCCESS,
    failure: types.PROVIDE_REGISTRATION_ERROR,
    dispatch,
    body: {
      UserId: localStorage.getItem("userId"),
      guId: "",
      Business_Name: data.businessName,
      Legal_Name: data.legalBusiness,
      Business_Start_Date: data.businessStartDate,
      MCC: data.USID,
      Business_Description: data.businessDiscrition,
      Ownership_Type: data.ownerShipType,
      Business_Phone: data.businessPhoneNumber,
      Business_EMail: data.businessEmail,
      Website: data.businessWebsite,
      Fedral_Tax_ID: data.businessTaxId,
      NPI: data.NPI,
      Stock_Symbol: data.businessStock,
      Business_Address: data.businessAddress,
      Suite: data.businessSuite,
      Country_Code: data.businessCountry,
      State_Code: data.businessState,
      City: data.businessCity,
      International_Province: data.businessInterNational,
      Postal_Code: data.businessPostalCode,
      Account_Number: data.accountNumber,
      Routing_Number: data.routingNumber,
      Name_On_Account: data.legalBusiness,
      Account_Type: data.accountType,
      Card_Swiped: data.cardPresent,
      Card_Not_Present: data.cardNotPresent,
      ECommerce: data.eCommers,
      Monthly_Card_Volume: data.cardVolumeMonth,
      Average_Ticket_Size: data.averageTicketSize,
      High_Ticket: data.highTicket,
      Monthly_ACH_Volume: data.eCheckVolumeMonth,
      Average_ACH: data.eCheckAverage,
      incorportionDocument: data.incorportionDocument,
      incorporationName: data.incorportionDocumentDetails.File_Name,
      High_ACH: data.eCheckHigh,
      Owner_Information: data.ownerArray,
      timeZoneID: 1,
      Created_By: "Admin",
      previewSubmit: data.previewSubmit
    }
  });
};

export const updateRegistration = (data, id) => (dispatch) => {
  dispatch({ type: types.PROVIDE_REGISTRATION_CALL });
  put({
    url: urls.PROVIDE_REGISTRATION_URL,
    success: types.PROVIDE_REGISTRATION_SUCCESS,
    failure: types.PROVIDE_REGISTRATION_ERROR,
    dispatch,
    body: {
      UserId: localStorage.getItem("userId"),
      pid: id,
      Business_Name: data.businessName,
      Legal_Name: data.legalBusiness,
      Business_Start_Date: data.businessStartDate,
      MCC: data.USID,
      Business_Description: data.businessDiscrition,
      Ownership_Type: data.ownerShipType,
      Business_Phone: data.businessPhoneNumber,
      incorporationName: data.incorportionDocumentDetails.File_Name,
      Business_EMail: data.businessEmail,
      Website: data.businessWebsite,
      Fedral_Tax_ID: data.businessTaxId,
      NPI: data.NPI,
      Stock_Symbol: data.businessStock,
      Business_Address: data.businessAddress,
      Suite: data.businessSuite,
      Country_Code: data.businessCountry,
      State_Code: data.businessState,
      City: data.businessCity,
      International_Province: data.businessInterNational,
      Postal_Code: data.businessPostalCode,
      Account_Number: data.accountNumber,
      Routing_Number: data.routingNumber,
      Name_On_Account: data.legalBusiness,
      Account_Type: data.accountType,
      Card_Swiped: data.cardPresent,
      Card_Not_Present: data.cardNotPresent,
      ECommerce: data.eCommers,
      Monthly_Card_Volume: data.cardVolumeMonth,
      Average_Ticket_Size: data.averageTicketSize,
      High_Ticket: data.highTicket,
      Monthly_ACH_Volume: data.eCheckVolumeMonth,
      Average_ACH: data.eCheckAverage,
      High_ACH: data.eCheckHigh,
      Owner_Information: data.ownerArray,
      incorportionDocument: data.incorportionDocument,
      timeZoneID: 1,
      Created_By: "Admin",
      Current_Status: data.status,
      previewSubmit: data.previewSubmit
    }
  });
};

export const getMccList = () => (dispatch) => {
  dispatch({ type: types.MCC_LIST_CALL });
  get({
    url: urls.MCC_LIST_URL,
    success: types.MCC_LIST_SUCCESS,
    failure: types.MCC_LIST_ERROR,
    dispatch
  });
};

export const getCountryTypeList = () => (dispatch) => {
  dispatch({ type: types.COUNTEY_TYPE_LIST_CALL });
  get({
    url: urls.COMMON_COUNTRY_TBALE_URL,
    success: types.COUNTEY_TYPE_LIST_SUCCESS,
    failure: types.COUNTEY_TYPE_LIST_ERROR,
    dispatch
  });
};

export const accountTypeList = () => (dispatch) => {
  dispatch({ type: types.ACCOUNT_TYPE_LIST_CALL });
  get({
    url: urls.ACCOUNT_TYPE_LIST_URL,
    success: types.ACCOUNT_TYPE_LIST_SUCCESS,
    failure: types.ACCOUNT_TYPE_LIST_ERROR,
    dispatch
  });
};

export const ownerTypeList = () => (dispatch) => {
  dispatch({ type: types.OWNER_TYPE_LIST_CALL });
  get({
    url: urls.OWNER_SHIP_LIST_URL,
    success: types.OWNER_TYPE_LIST_SUCCESS,
    failure: types.OWNER_TYPE_LIST_ERROR,
    dispatch
  });
};

export const getProviderList = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_LIST_CALL });
  get({
    url: urls.PROVIDER_LIST_URL,
    success: types.PROVIDER_LIST_SUCCESS,
    failure: types.PROVIDER_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getProviderByFilterData = (data) => (dispatch) => {
  const finalData = { ...data };
  finalData.apiType = "search";
  dispatch({ type: types.PROVIDER_LIST_BY_FILTERDATA_CALL });
  get({
    url: `${urls.PROVIDER_LIST_BY_SEARCH_URL}`,
    success: types.PROVIDER_LIST_BY_FILTERDATA_SUCCESS,
    failure: types.PROVIDER_LIST_BY_FILTERDATA_ERROR,
    dispatch,
    body: finalData
  });
};

export const getProviderDetailsById = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_LIST_BY_ID_CALL });
  get({
    url: `${urls.GET_PROVIDER_LIST_BY_ID}/${data}`,
    success: types.PROVIDER_LIST_BY_ID_SUCCESS,
    failure: types.PROVIDER_LIST_BY_ID_ERROR,
    dispatch
  });
};

export const getProviderStatus = (data) => (dispatch) => {
  dispatch({ type: types.GET_PROVIDER_STATUS_CALL });
  if (data === undefined) {
    get({
      url: urls.GET_PROVIDER_STATUS_URL,
      success: types.GET_PROVIDER_STATUS_SUCCESS,
      failure: types.GET_PROVIDER_STATUS_ERROR,
      dispatch,
      body: ""
    });
  } else {
    get({
      url: urls.GET_PROVIDER_STATUS_URL,
      success: types.GET_PROVIDER_STATUS_SUCCESS,
      failure: types.GET_PROVIDER_STATUS_ERROR,
      dispatch,
      body: data
    });
  }
};

export const changeProviderFormMode = (data) => ({
  type: types.PROVIDER_FORM_MODE,
  data
});

export const changeSingleStatus = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_STATUS_CHANGE_SINGLE_CALL });
  put({
    url: urls.PROVIDER_STATUS_CHANGE_URL,
    success: types.PROVIDER_STATUS_CHANGE_SINGLE_SUCCESS,
    failure: types.PROVIDER_STATUS_CHANGE_SINGLE_ERROR,
    dispatch,
    body: {
      UserId: localStorage.getItem("userId"),
      pid: data.id,
      Current_Status: data.status
    }
  });
};

export const providerSearch = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_STATUS_CHANGE_SINGLE_CALL });
  put({
    url: urls.PROVIDER_SEARCH_URL,
    success: types.PROVIDER_STATUS_CHANGE_SINGLE_SUCCESS,
    failure: types.PROVIDER_STATUS_CHANGE_SINGLE_ERROR,
    dispatch,
    body: {
      pid: data.id,
      Current_Status: data.status
    }
  });
};

export const getupdatestatus = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_STATUS_CHANGE_SINGLE_CALL });
  put({
    url: urls.PROVIDER_SEARCH_URL,
    success: types.PROVIDER_STATUS_CHANGE_SINGLE_SUCCESS,
    failure: types.PROVIDER_STATUS_CHANGE_SINGLE_ERROR,
    dispatch,
    body: {
      pid: data.id,
      Current_Status: data.status
    }
  });
};

export const getProviderActivityById = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_ACTIVITY_BY_ID_CALL });
  get({
    url: urls.PROVIDER_ACTIVITY_BY_ID_URL,
    success: types.PROVIDER_ACTIVITY_BY_ID_SUCCESS,
    failure: types.PROVIDER_ACTIVITY_BY_ID_ERROR,
    dispatch,
    body: data
  });
};

export const getProviderParameterCall = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_PARAMETER_CALL });
  get({
    url: urls.PROVIDER_PARAMETER_URL,
    success: types.PROVIDER_PARAMETER_SUCCESS,
    failure: types.PROVIDER_PARAMETER_ERROR,
    dispatch,
    body: data
  });
};

export const saveProviderParameter = (data) => (dispatch) => {
  dispatch({ type: types.SAVE_PROVIDER_PARAMETER_CALL });
  post({
    url: urls.PROVIDER_PARAMETER_URL,
    success: types.SAVE_PROVIDER_PARAMETER_SUCCESS,
    failure: types.SAVE_PROVIDER_PARAMETER_ERROR,
    dispatch,
    body: data
  });
};

export const changeProviderParameterFormMode = (data) => ({
  type: types.PROVIDER_PARAMETER_FORM_MODE,
  data
});

export const getProviderParameterById = (data) => (dispatch) => {
  dispatch({ type: types.PROVIDER_PARAMETER_BY_ID_CALL });
  get({
    url: `${urls.PROVIDER_PARAMETER_URL}/${data}`,
    success: types.PROVIDER_PARAMETER_BY_ID_SUCCESS,
    failure: types.PROVIDER_PARAMETER_BY_ID_ERROR,
    dispatch,
    body: data
  });
};

export const deleteIncroptration = (data) => (dispatch) => {
  dispatch({ type: types.DELETE_INCROPRATION_DOCUMENT_CALL });
  get({
    url: `${urls.DELETE_INCROPRATION_DOCUMENT_URL}`,
    success: types.DELETE_INCROPRATION_DOCUMENT_SUCCESS,
    failure: types.DELETE_INCROPRATION_DOCUMENT_ERROR,
    dispatch,
    body: data
  });
};

export const deleteAddressProof = (data) => (dispatch) => {
  dispatch({ type: types.DELETE_ADDRESS_DOCUMENT_CALL });
  get({
    url: `${urls.DELETE_ADDRESS_DOCUMENT_URL}`,
    success: types.DELETE_ADDRESS_DOCUMENT_SUCCESS,
    failure: types.DELETE_ADDRESS_DOCUMENT_ERROR,
    dispatch,
    body: data
  });
};

export const deleteIdentityProof = (data) => (dispatch) => {
  dispatch({ type: types.DELETE_IDENTITY_DOCUMENT_CALL });
  get({
    url: `${urls.DELETE_IDENTITY_DOCUMENT_URL}`,
    success: types.DELETE_IDENTITY_DOCUMENT_SUCCESS,
    failure: types.DELETE_IDENTITY_DOCUMENT_ERROR,
    dispatch,
    body: data
  });
};

export const getQuestionData = (data) => (dispatch) => {
  dispatch({ type: types.GET_QUESTION_DATA_CALL });
  get({
    url: `${urls.GET_QUESTION_DATA_URL}`,
    success: types.GET_QUESTION_DATA_SUCCESS,
    failure: types.GET_QUESTION_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const getKycQuestionData = (data) => (dispatch) => {
  dispatch({ type: types.GET_KYC_QUESTIONS_CALL });
  get({
    url: `${urls.GET_KYC_QUESTIONS_URL}`,
    success: types.GET_KYC_QUESTIONS_SUCCESS,
    failure: types.GET_KYC_QUESTIONS_ERROR,
    dispatch,
    body: data
  });
};

export const saveQuestionCall = (data) => (dispatch) => {
  dispatch({ type: types.SAVE_QUESTION_DATA_CALL });
  post({
    url: urls.SAVE_QUESTION_DATA_URL,
    success: types.SAVE_QUESTION_DATA_SUCCESS,
    failure: types.SAVE_QUESTION_DATA_ERROR,
    dispatch,
    body: data
  });
};

export const setResetWuestion = () => ({
  type: types.SET_ALL_EMPTY_Question
});

export const addSearchData = (data) => ({
  type: types.ADD_MERCHANT_SEARCH_DATA,
  data
});

export const removeSearchData = () => ({
  type: types.EMPTY_SEARCH_DATA
});
