/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import ButtonOriginal from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import Box from "@material-ui/core/Box";
import Button from "components/CustomButtons/Button.js";
import CustomButton from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import SignatureCanvas from "react-signature-canvas";
import { Typography } from "@material-ui/core";
import {
  patientPaymentDetailsCall,
  setEmailRedirect,
} from "Redux/Actions/genrateOfferPatient";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import Pdf from "Uintls/The_patient_offer.pdf";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import isEmpty from "lodash/isEmpty";
import CancelledOfferMessage from "./CancelledOfferMessage";
import fullOrigin from "../../assets/img/imgpsh_fullsize_anim.png";

const useStyles = makeStyles(styles);
const EmailGenrateOffer = (props) => {
  const componentRef = React.createRef();
  const classes = useStyles();
  const [logoPath, setLogoPath] = React.useState("");
  const [prePaymentDetails, setPrePaymentDetails] = React.useState(
    props.tableData.paymentDetailsData
  );
  const details =
    props.tableData.showPatientDetailsViaMail &&
    props.tableData.showPatientDetailsViaMail.data[0];

  React.useEffect(() => {
    if (prePaymentDetails !== props.tableData.paymentDetailsData) {
      setPrePaymentDetails(props.tableData.paymentDetailsData);
      props.setEmailRedirect();
      props.history.push("/tpo/payments");
    }
  }, [props]);

  const handlePayNow = () => {
    const data = {
      patientId: details.Patient_Id,
      offerId: details.Offer_Id,
      amount: Number(
        details.Loan_Details.Down_Payment.toString().replace("$", "")
      ),
    };
    props.patientPaymentDetailsCall(data);
  };

  const handleClose = () => {
    props.history.push("/");
  };

  if (isEmpty(logoPath)) {
    const logo_path = new Image();
    if (props.app.portalConfig) {
      const x = props.app.portalConfig.data[0]?.Logo_Image;
       logo_path.src = `${x}?" + ${new Date().getTime()}`;
      logo_path.onload = function () {
        setLogoPath(logo_path.src);
      };
      logo_path.onerror = function () {
        setLogoPath(fullOrigin);
      };
    }
  }

  return (
    <Card>
      {props.tableData.showPatientDetailsViaMail.status !== false ? (
        <>
          <div style={{ margin: "15px" }}>
            <Grid container style={{ margin: "1%" }}>
              <Grid item xs={4}>
                <ButtonOriginal
                  className={classes.clearButton}
                  onClick={() => handleClose()}
                >
                  Close
                </ButtonOriginal>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  paddingRight: "2%",
                }}
              >
                <Button
                  style={{ fontSize: "18px" }}
                  onClick={() => handlePayNow()}
                >
                  Pay Now
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button
                  onClick={useReactToPrint({
                    content: () => componentRef.current,
                    onAfterPrint: () => {},
                    documentTitle: details.Offer_Id,
                  })}
                >
                  Print
                </Button>
              </Grid>
              <Grid item xs={3} />
            </Grid>
            <div id="print" ref={componentRef}>
              <Grid container style={{ margin: "1% 9%" }}>
                <Grid iten xs={2} align="left">
                  <img src={logoPath} alt="" style={{ width: "30%" }} />
                </Grid>
                <Grid iten xs />
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs
                  style={{
                    margin: "1% 9%",
                    fontSize: "16px",
                    color: "gray",
                    fontFamily: "Source Sans Pro",
                  }}
                >
                  {details && details.Patient_Name}
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs
                  style={{
                    margin: "1% 1% 1% 9%",
                    fontSize: "16px",
                    color: "gray",
                    fontFamily: "Source Sans Pro",
                  }}
                >
                  Congratulations! We're excited to let you know that our
                  billing department has approved your application and we are
                  ready to fund your account balance with terms defined below:
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs>
                  <div
                    style={{
                      margin: "1% 9%",
                      fontSize: "18px",
                      color: "#00aab4",
                      fontFamily: "Source Sans Pro",
                    }}
                  >
                    Procedure Details
                  </div>
                  <Box
                    style={{
                      margin: "1% 9%",
                      width: "60%",
                      backgroundColor: "#f6f5f5",
                      borderRadius: "10px",
                      padding: "2%",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={4}>
                        Hospital/Provider Name
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={4}>
                        {details && details.Procedure_Details.Hospital}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs>
                  <div
                    style={{
                      margin: "1% 9%",
                      fontSize: "18px",
                      color: "#00aab4",
                      fontFamily: "Source Sans Pro",
                    }}
                  >
                    Payment Details
                  </div>
                  <Box
                    style={{
                      margin: "1% 9%",
                      width: "60%",
                      backgroundColor: "#f6f5f5",
                      borderRadius: "10px",
                      padding: "2%",
                    }}
                  >
                    <Grid container>
                      <Grid item xs={4}>
                        Offer Date
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={3}>
                        {details && details.Loan_Details.Offer_Date}
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        Offer Number
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={4}>
                        {details && details.offerNumber}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        Amount Financed
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={2}>
                        {details && details.Loan_Details.Amount_Financed}
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        Down payment
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={2}>
                        {details && details.Loan_Details.Down_Payment}
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        Monthly payment
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={2}>
                        {details && details.Loan_Details.Monthly_Payment}
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        Term (Months)
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={2}>
                        {details && details.Loan_Details.Terms}
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        Start Date
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={2}>
                        {details && details.Loan_Details.Start_Date}
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        Maturity Date
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={2}>
                        {details && details.Loan_Details.Maturity_Date}
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                    <Grid container>
                      <Grid item xs={4}>
                        APR
                      </Grid>
                      <Grid item xs={2}>
                        :
                      </Grid>
                      <Grid item xs={2}>
                        {details && details.Loan_Details.APR}%
                      </Grid>
                      <Grid item xs={2} />
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs
                  style={{
                    margin: "1% 9%",
                    fontSize: "18px",
                    color: "#00aab4",
                    fontFamily: "Source Sans Pro",
                  }}
                >
                  <a
                    href={Pdf}
                    without
                    style={{ color: "#00aab4" }}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Acceptance <PictureAsPdfIcon />
                  </a>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  item
                  xs
                  style={{
                    margin: "1% 1% 1% 9%",
                    fontSize: "16px",
                    color: "gray",
                    fontFamily: "Source Sans Pro",
                  }}
                >
                  I have understood the offer and have read the terms and
                  conditions of the offer. By signing below, I am giving
                  acceptance of same.
                </Grid>
              </Grid>
              <br />
              <br />
              <Grid container>
                <Grid
                  item
                  xs={4}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    margin: "1% 9%",
                  }}
                >
                  <Typography style={{ color: "#00aab4" }}>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Date:
                  </Typography>
                  <div>
                    {" "}
                    &nbsp;&nbsp;
                    {moment().format("MM/DD/YYYY")}{" "}
                  </div>
                </Grid>
                <Grid
                  item
                  xs
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography style={{ color: "#00aab4" }}>
                    Signature: &nbsp;
                  </Typography>
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 250,
                      height: 70,
                      className: classes.canvasBackgourd,
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <Grid container style={{ margin: "1% 0% 0% 0%" }}>
              <Grid item xs={2} />
              <Grid item xs={8} align="center">
                <ButtonOriginal
                  className={classes.clearButton}
                  onClick={() => window.open("", "_parent", "").close()}
                >
                  Close
                </ButtonOriginal>
                &nbsp;&nbsp;&nbsp;
                <Button style={{ fontSize: "18px" }} onClick={() => {}}>
                  Pay Now
                </Button>
              </Grid>
              <Grid iten xs={2} />
            </Grid>
          </div>
        </>
      ) : (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <CancelledOfferMessage
          messageDetails={props.tableData.showPatientDetailsViaMail.message}
          {...props}
        />
      )}
    </Card>
  );
};

EmailGenrateOffer.propTypes = {
  tableData: PropTypes.object,
  history: PropTypes.func,
  patientPaymentDetailsCall: PropTypes.func,
  setEmailRedirect: PropTypes.func,
  app: PropTypes.object,
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  app: app.app,
});

export default connect(mapStateToProps, {
  patientPaymentDetailsCall,
  setEmailRedirect,
})(EmailGenrateOffer);
