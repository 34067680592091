const ProviderStyle = (theme) => ({
  brandlogo: {
    height: "120px",
    width: "200px",
    backgroundSize: "contain",
    marginLeft: "auto",
    marginRight: "auto",
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 35,
    },
  },
  ActionButtons: {
    marginTop: "8%",
    textAlign: "center",
    marginBottom: "2%",
    justifyContent: "center",
  },
  MainContainer: {
    paddingTop: "2%",
    justifyContent: "center",
  },
  LogoContainer: {
    paddingTop: "2%",
    paddingBottom: "2%",
    justifyContent: "center",
  },
  patientTable: {
    padding: "1%",
  },
  clearButton: {
    backgroundColor: "#868686",
    height: "30px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#868686",
    },
  },
  backButton: {
    paddingTop: "2%",
    paddingLeft: "2%",
  },
  ActionButtonsMain: {
    alignItems: "baseline",
    textAlign: "center",
    justifyContent: "center",
    marginBottom: "2%",
  },
  logo: {
    objectFit: "contain",
    maxHeight: "250px",
    maxWidth: "250px",
  },
});

export default ProviderStyle;
