const displayRow = {
  display: "flex",
  flexDirection: "row",
};

const PatientStyle = theme => ({
  headerCardIcon: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
  },
  displayFlexRow: {
    display: "flex",
    flexDirection: "row",
  },
  displayFlexColumn: {
    display: "flex",
    flexDirection: "column",
  },
  mainTopComtainer: {
    ...displayRow,
    margin: "5%",
    marginBottom: "10px",
  },
  clearButton: {
    backgroundColor: "#868686",
    height: "35px",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#868686",
    },
  },
  avatarIcon: {
    position: "absolute",
    paddingTop: "1%",
  },
  buttonBox: {
    display: "flex",
    // width: "100%",
    justifyContent: "center",
    alighItem: "center",
    // margin: "2rem",
    paddingBottom: "3%",
  },
  addPatientBackButton: {
    paddingTop: "2%",
    paddingLeft: "2%",
  },
  addPatientForm: {
    padding: "2%",
    paddingTop: "3%",
    justifyContent: "center",
  },
  loginFormPaper: {
    margin: "2%",
    borderRadius: "8px",
  },
  cardMargin: {
    marginBottom: "20px",
  },
});


export default PatientStyle;
