/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import isEmpty from "lodash/isEmpty";
import { SimpleDialog } from "../../components/DialogBox/Index";
import { accountStatementList } from "../../Redux/Actions/TableAction";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import noDataImage from "../../assets/img/noDataImage.png";

const useStyles = makeStyles(tableStyle);

function TablePaginationActions(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.rootPagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography>First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography>Previous</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleFirstPageButtonClick}
        aria-label="first page"
        className={classes.bottonPage}
      >
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography>Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography>Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};

const AccountStatement = (props) => {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [rows, setRows] = React.useState([]);
  const [selectedValue, setSelectedValue] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const redirected = new URLSearchParams(window.location.search).get(
    "redirected"
  );

  React.useEffect(() => {
    if (!props.tableData.accountStatementList) {
      props.accountStatementList();
    }
  }, []);

  if (props.tableData.accountStatementList && isEmpty(rows)) {
    if (
      props.tableData.accountStatementList.message &&
      !isEmpty(props.tableData.accountStatementList.data)
    ) {
      const data = props.tableData.accountStatementList.data;
      data.forEach((v) => {
        v.endDate = moment(v.endDate).format("MM/DD/YYYY");
        v.startDate = moment(v.startDate).format("MM/DD/YYYY");
      });
      setRows(data);
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <TableContainer className={classes.TableContainer} component={Paper}>
        <Grid
          container
          className={classes.searchContainer}
          style={{ justifyContent: "end" }}
        >
          <Grid item className={classes.margin}>
            <div className={classes.searchWrapper}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "35%" }}>
                  <TextField
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    inputProps={{
                      autoComplete: "off"
                    }}
                    fullWidth
                    label="Search"
                    onClick={() => handleClickOpen()}
                    name="Search"
                    id="Search"
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog close={() => handleClose()} />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <Button
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </Button>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                className={classes.ExportButtonStyle}
              >
                Export
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
              >
                <MenuItem onClick={handleCloseMenu}>As Pdf</MenuItem>
                <MenuItem onClick={handleCloseMenu}>My account</MenuItem>
              </Menu>
              {redirected === "Dashboard" && (
                <Button
                  onClick={() => {
                    props.history.push("/patient/Dashboard");
                  }}
                >
                  Go Back
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        <Table className={classes.table} aria-label="simple table">
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Offer Number
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Amount Financed
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Funding Date
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Down Payment ($)
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Monthly Payment ($)
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Terms
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Start Date
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                End Date
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                APR (%)
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Last Payment On
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Term Left
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Principal Balance
              </TableCell>
              <TableCell className={classes.cellTextHeaderOffer} align="center">
                Pay Off
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow>
                <TableCell className={classes.cellText}>
                  {row.offerNumber}
                </TableCell>
                <TableCell className={classes.cellText}>
                  {row.amountFinanced}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.fundingDate}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.downPayment}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.monthlyPayment}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.terms}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.startDate}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.endDate}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.apr}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.lastPaymentOn}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.termLeft}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.principalBalance}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.payOff}
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell className="no-data" colSpan={6}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
              colSpan={12}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: { placeholder: "Show" },
                native: true
              }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
};

AccountStatement.propTypes = {
  accountStatementList: PropTypes.func,
  tableData: PropTypes.any
};

const mapStateToProps = (table) => ({
  tableData: table.table
});
export default connect(mapStateToProps, {
  accountStatementList
})(AccountStatement);
