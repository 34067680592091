import * as types from "./ActionTypes";
import * as urls from "../../Uintls/Urls";
import { get } from "../Helper/index";

export const resetReducer = () => ({
  type: types.REST_REDUCER_STATE,
});

export const defaultThemeCall = () => dispatch => {
  dispatch({ type: types.DEFAULT_THEME_CALL });
  get({
    url: `${urls.DEFAULT_THEME_CALL}`,
    success: types.DEFAULT_THEME_SUCCESS,
    failure: types.DEFAULT_THEME_ERROR,
    dispatch,
    body: "default",
  });
};

export const handleInitialData = () => async dispatch => {
  dispatch(defaultThemeCall());
};
