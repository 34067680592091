const style = (theme) => ({
  cardBox: {
    borderRadius: "10px"
  },
  barBox: {
    width: "30%",
  },
  gridContainer: {
    marginBottom: "15px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexWrap: "wrap",
    },
    [theme.breakpoints.up("sm")]: {
      flexWrap: "nowrap",
    },
  },
  patientDetailsHeading: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    paddingBottom: "0px",
    justifyContent: "flex-start",
    fontSize: "13px",
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
  },
  margin: {
    margin: ".2%",
    marginTop: "0px",
  },
  hrStyle: {
    position: "relative",
    zIndex: "4",
    width: "80%",
    border: "1px solid #efefef",
  },
  cardFontSize: {
    fontSize: "unset",
    margin: ".2%",
    marginTop: "-4%",
  },
  gridItem: {
    flexBasis: "unset",
  },
  resize: {
    padding: "10px 14px",
  },
});

export default style;
