import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
// import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export default function CommonDialog({ message, description, open, onClose }) {
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{message}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {description}
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions> */}
        <div style={{ flexGrow: 1, textAlign: "center" }}>
          <Grid container spacing={12}>
            <Grid item xs>
              <Button
                style={{
                  alignSelf: "center",
                  textAlign: "center",
                  bottom: "20px",
                }}
                size="medium"
                variant="contained"
                onClick={onClose}
                color="primary"
              >
                Okay
              </Button>
            </Grid>
          </Grid>
        </div>
        {/* </DialogActions> */}
      </Dialog>
    </div>
  );
}

CommonDialog.propTypes = {
  message: PropTypes.string,
  description: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
CommonDialog.defaultProps = {
  message: "",
  description: "",
  open: false,
  onClose: () => {},
};
