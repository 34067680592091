import React, {useState} from "react";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";


const useStyles = makeStyles(styles);
const PaymentLink = () => {
    const classes = useStyles();
    return <div>
        <Box className={ classNames({
                        [classes.margin]: false,
                        [classes.linkBackgroudColor]: false
                      })} >
        <Grid container className={classes.linkBackgroudColor}>
            <Grid item xs className={classes.contentCenter}>
                <Typography className={classes.textDetails}>A Payment link has been sent on the email: justinJones19@hotmail.com and mobile: (372) 587-2335. Link will be active for 48 hours</Typography>
              </Grid>
          </Grid>
        </Box>
        </div>;
};

export default PaymentLink;
