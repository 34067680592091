import "../../assets/css/CountryCode.scss";
import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import { Select } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
// import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import InputAdornment from "@material-ui/core/InputAdornment";
import Radio from "@material-ui/core/Radio";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import { Divider } from "@material-ui/core";
import styles from "assets/jss/material-dashboard-react/components/userForm";
import isEmpty from "lodash/isEmpty";
// import useMediaQuery from "@material-ui/core/useMediaQuery";
// import closeImage from "assets/img/Close_Image.png";
// import gif from "../../assets/img/success.gif";
// import Card from "components/Card/Card";
import MultipleSelect from "../../components/MultiSelect/indexUserProfile";
import Validation from "../../Utils/Validations";
import { userListApi } from "../../actions/TableData";
import { locationCity } from "../../constant/locationConstant";
import {
  getCountryTypeList,
  getProfileTypeList,
  getRoleList,
  changeUserFormMode,
  getTimeZoneList,
  getUserList,
  getUserListProvider,
  getGroupListData,
  getProviderRoleList
} from "../../Redux/Actions/UserRegistration";
import {
  updateUserApiCall,
  createNewUserApiCall,
  autoGeneratedRepCode,
  updateProfileImage
} from "../../actions/Operations";
import {
  Container,
  CssBaseline,
  Paper,
  Card,
  CardContent
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import EditIcon from "@material-ui/icons/Edit";
import AvatarUpdate from "./AvatarUpdate";
import { setSendClientIdInApi } from "Redux/Actions/LoginActions";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(styles);

const GreenRadio = withStyles((theme) => ({
  root: {
    "&$checked": {
      color: theme.palette.primary.main
    }
  },
  checked: {}
}))((props) => <Radio color="default" {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

// const useStyles = makeStyles(theme => ({
//   root: {
//     backgroundColor: theme.palette.background.paper,
//     width: '100%',
//   },
// }));
function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update state to force render
  // An function that increment 👆🏻 the previous state like here
  // is better than directly setting `value + 1`
}

function UserProfile(props) {
  const classes = useStyles();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();
  const formikRef = React.useRef(null);
  const [filterDataRole, setFilterDataRole] = React.useState(false);
  const [userList, setUserList] = React.useState([]);
  const [personName, setPersonName] = React.useState(
    props.values ? props.values : []
  );
  const [stateInput, setStateInput] = React.useState(true);

  const [dataRole, setDataRole] = React.useState(false);
  const [imageBass64, setImageBass64] = React.useState("");
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [selectedSearch, setSelectedSearch] = React.useState("local");
  const [preUserListData, setPreUserListData] = React.useState(
    props.formData.groupListData
  );
  // const [image, setImage] = useState({ preview: "", raw: "" });
  const names = "";
  const [initialValues, setInitialValues] = React.useState({
    Profile_Code: "",
    First_Name: "",
    Middle_Name: "",
    Last_Name: "",
    Email: "",
    Password: "",
    Role_ID: "",
    Address1: "",
    Address2: "",
    City: "",
    Mobile: "",
    Phone_No: "",
    Country_Code: "US",
    State_Code: "",
    Zip: "",
    Time_Zone_Code: "",
    profileImage: "",
    Accessible_Provider: "",
    User_Profile_Image: {
      File_Name: "",
      File_Path: "",
      File_Type: ""
    },
    reportsTo: "",
    profileVisibility: "1",
    confirmPassword: "",
    groupId: [],
    repoCode: "",
    CreateByClientId: localStorage.getItem("userId"),
    ClientID: props.userRole.userList.ClientID,
    Accessible_Provider: [props.userRole.userList.ClientID],
    Created_By: localStorage.getItem("userId"),
    dialCode: 1
  });
  const [validationError, setVlidationError] = React.useState({
    Profile_Code: props.formData.formMode === "Create",
    FName: props.formData.formMode === "Create",
    LName: props.formData.formMode === "Create",
    Email: props.formData.formMode === "Create",
    Role: props.formData.formMode === "Create",
    city: props.formData.formMode === "Create",
    mobile: props.formData.formMode === "Create",
    country: props.formData.formMode === "Create",
    state: props.formData.formMode === "Create"
  });
  const longText = (
    <ul>
      <li>Uppercase Letters</li>
      <li>Lowercase Letters</li>
      <li>Numbers</li>
      <li>Special Character</li>
    </ul>
  );

  const callUserListApi = () => {
    props.userListApi(
      {
        ClientId: localStorage.getItem("clientId"),
        LoginEmail: localStorage.getItem("email"),
        formType: "reportsTo"
      },
      (data) => {
        setUserList(data.Users_Data);
      }
    );
  };

  const autoGeneratedRepCodeCall = () => {
    props.autoGeneratedRepCode(
      (data) => {
        if (data.status) {
          formikRef.current.setFieldValue("repoCode", data.data.newRepoCode);
        }
      },
      (err) => {
        enqueueSnackbar("Failed to auto generate.", {
          variant: "error"
        });
      }
    );
  };

  const handleVisability = (event) => {
    if (event.target.value === "2") {
      formikRef.current.setFieldValue("profileVisibility", "3");
      props.getGroupListData();
    } else {
      formikRef.current.setFieldValue("profileVisibility", event.target.value);
    }
  };

  const handleUserType = (e) => {
    const valueRole = props.roles.providerroleList.Roles_Data.filter(
      (v) => v.Profile_Code === e
    );
    setFilterDataRole(valueRole);
    formikRef.current.setFieldValue("Profile_Code", e);
    formikRef.current.setFieldValue("Role_ID", "");
  };

  const handleBack = () => {
    props.getUserList();
    props.changeUserFormMode("init");
  };

  if (
    !dataRole &&
    props.roles.providerroleList &&
    props.roles.providerroleList.Roles_Data &&
    props.formData.userDetailsById
  ) {
    const preData = props.formData.userDetailsById.Users_Data[0];
    const valueRole = props.roles.providerroleList.Roles_Data.filter(
      (v) => v.Profile_Code === preData.Profile_Code
    );
    var updatedData = {
      Profile_Code: preData.Profile_Code,
      First_Name: preData.FName,
      Middle_Name: preData.MName,
      Last_Name: preData.LName,
      Email: preData.Email,
      Role_ID: preData.RoleID,
      Address1: preData.Address1,
      Address2: preData.Address2,
      City: preData.City,
      Mobile: preData.Mobile,
      Phone_No: preData.Phone,
      Country_Code: preData.Country_Code,
      State_Code: preData.State_Code,
      Zip: preData.Zip,
      Time_Zone_Code: preData.TimeZone_Code,
      Accessible_Provider:
        preData.Accessible_Provider && preData.Accessible_Provider[0],
      profileImage: preData.User_Profile_Image,
      User_Profile_Image: {
        ...preData.User_Profile_Image
      },
      reportsTo: preData.reportsTo,
      profileVisibility: preData.profileVisibility,
      confirmPassword: "",
      groupId: preData.groupId,
      repoCode: preData.repoCode || "",
      ClientID: preData.ClientID,
      dialCode: preData.dialCode,
      UserID: preData.UserID
    };

    setInitialValues({ ...updatedData });
    setFilterDataRole(valueRole);
    setDataRole(true);
  }

  const handleChange = (e) => {
    const preData =
      props.formData.userDetailsById &&
      props.formData.userDetailsById.Users_Data[0];
    const data = {
      userId: preData && preData.UserID,
      profileImage: imageBass64
    };
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0]
      });
    }

    const imageUrl = e.target.files[0];
    var reader = new FileReader();
    reader.onloadend = function () {
      setImageBass64(reader.result);
    };
    reader.readAsDataURL(imageUrl);
  };

  const onFormSubmit = (values) => {
    if (props.formData.formMode === "Create") {
      props.createNewUserApiCall(
        { ...values, profileImage: imageBass64 },
        (data) => {
          if (data.status) {
            enqueueSnackbar(data.message, {
              variant: "success"
            });
            handleBack();
          } else {
            enqueueSnackbar(data.message, {
              variant: "error"
            });
          }
        },
        (err) => {
          console.log(err);
          enqueueSnackbar(err.message, {
            variant: "error"
          });
        }
      );
      const preData =
        props.formData.userDetailsById &&
        props.formData.userDetailsById.Users_Data[0];
      const data = {
        userId: preData && preData.UserID
      };
    }
    if (props.formData.formMode) {
      props.updateUserApiCall(
        { ...values, profileImage: imageBass64 },
        (data) => {
          enqueueSnackbar(data.message, {
            variant: "success"
          });
          handleBack();
        },
        (err) => {
          enqueueSnackbar(err.message, {
            variant: "success"
          });
        }
      );
      const preData =
        props.formData.userDetailsById &&
        props.formData.userDetailsById.Users_Data[0];
      const data = {
        userId: preData && preData.UserID,
        profileImage: imageBass64
      };
    }
  };

  const onChange = (value, type) => {
    const allState = { ...initialValues };
    const error = { ...validationError };
    if (type === "Profile_Code") {
      const valueRole = props.roles.providerroleList.Roles_Data.filter(
        (v) => v.Profile_Code === value
      );
      setFilterDataRole(valueRole);
    }
    if (type === "ClientID") {
      props.getProviderRoleList(value);
      setFilterDataRole(false);
      if (props.userRole.userList.ClientID !== value) {
        allState.Accessible_Provider = value;
      } else if (props.userRole.userList.ClientID === value) {
        allState.Accessible_Provider = [value];
      }
    }
    if (type === "businessCountry") {
      if (value === "USA") {
        setStateInput(true);
        allState.businessState = "";
      } else {
        setStateInput(false);
        allState.businessState = "";
      }
    } else {
      error[type] = false;
    }
    allState[type] = value;
    setInitialValues(allState);
    setVlidationError(error);
  };

  React.useEffect(() => {
    callUserListApi();
    if (!props.formData.countryTypeList) {
      props.getCountryTypeList();
    }
    if (!props.formData.profileTypeList) {
      props.getProfileTypeList();
    }
    if (!props.formData.roleList) {
      props.getRoleList();
    }
    if (!props.formData.timeZoneList) {
      props.getTimeZoneList();
    }
    if (!props.formData.providerroleList) {
      props.getProviderRoleList();
    }
    if (!props.formData.userProvider) {
      const data = {
        clientId: props.userRole.userList.ClientID
      };
      props.getUserListProvider(data);
    }
  }, []);

  const forceUpdate = useForceUpdate();

  React.useEffect(() => {
    if (preUserListData !== props.formData.groupListData) {
      setPreUserListData(props.formData.groupListData);
    }
  }, [props]);

  return (
    <>
      <Card>
        <Box className={classes.marginBox}>
          <Formik
            innerRef={(p) => {
              formikRef.current = p;
            }}
            onSubmit={onFormSubmit}
            enableReinitialize
            initialValues={initialValues}
            validationSchema={
              props.formData.formMode === "Create"
                ? Validation.createUser
                : Validation.updateUser
            }
          >
            {({ errors, setFieldValue, values, touched, handleSubmit }) => (
              <Form>
                <Grid
                  container
                  spacing={2}
                  style={{ flexDirection: smallDevice ? "column-reverse" : "" }}
                >
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginTop: "2%",
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Created for{" "}
                      <p style={{ color: "red", margin: "0" }}> *</p>
                    </Typography>
                    <Field
                      as={TextField}
                      required
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={touched.ClientID && errors.ClientID}
                      // onChange={(e) => setPreUserListData(e.target.value)}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        required: true
                      }}
                      disabled={props.formData.formMode === "Edit"}
                      value={values.ClientID}
                      onChange={(e) => onChange(e.target.value, "ClientID")}
                      name="ClientID"
                      id="ClientID"
                      select
                      style={{ height: "4.187em" }}
                      helperText={
                        touched.ClientID && errors.ClientID
                          ? errors.ClientID
                          : ""
                      }
                    >
                      {props.formData.userProvider &&
                        props.formData.userProvider.data &&
                        props.formData.userProvider.data.map((key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key.Porvider_Id}
                          >
                            {key.ProviderData}
                          </MenuItem>
                        ))}
                    </Field>
                    <Grid item lg={12} md={12} sm={6} xs={12}>
                      <Typography
                        style={{
                          marginTop: "2%",
                          marginBottom: "4px",
                          fontWeight: "600",
                          display: "flex",
                          alignItems: "center"
                        }}
                      >
                        Providers Accessible{" "}
                        <p style={{ color: "red", margin: "0" }}> *</p>
                      </Typography>
                      {props.userRole.userList.ClientID !==
                      initialValues.ClientID ? (
                        <Field
                          as={TextField}
                          required
                          size="small"
                          fullWidth
                          variant="outlined"
                          error={
                            touched.Accessible_Provider &&
                            errors.Accessible_Provider
                          }
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused"
                            }
                          }}
                          InputProps={{
                            color: "secondary",
                            classes: {
                              root: classes.activeInputColor,
                              input: classes.resize,
                              focused: "focused"
                            }
                          }}
                          disabled={
                            props.formData.formMode === "Edit" ||
                            props.userRole.userList.ClientID !==
                              values.Accessible_Provider
                          }
                          onChange={(e) =>
                            onChange(e.target.value, "Accessible_Provider")
                          }
                          value={initialValues.Accessible_Provider}
                          name="Accessible_Provider"
                          id="Accessible_Provider"
                          select
                          style={{ height: "4.187em" }}
                          helperText={
                            touched.Accessible_Provider &&
                            errors.Accessible_Provider
                              ? errors.Accessible_Provider
                              : ""
                          }
                        >
                          {props.formData.userProvider &&
                            props.formData.userProvider.data &&
                            props.formData.userProvider.data.map((key) => (
                              <MenuItem
                                className={classes.dialogBoxDropDown}
                                value={key.Porvider_Id}
                              >
                                {key.ProviderData}
                              </MenuItem>
                            ))}
                        </Field>
                      ) : (
                        props.formData.userProvider && (
                          <MultipleSelect
                            clientId={props.userRole.userList.ClientID}
                            values={initialValues.Accessible_Provider}
                            data={
                              props.formData.userProvider &&
                              props.formData.userProvider.data
                            }
                            onChangeSelect={(item) =>
                              onChange(item, "Accessible_Provider")
                            }
                          />
                        )
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    lg={6}
                    md={6}
                    sm={6}
                    xs={12}
                    style={{ display: "flex", justifyContent: "end" }}
                  >
                    <div>
                      <label htmlFor="upload-button">
                        {image.preview ? (
                          <>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                position: "relative"
                              }}
                            >
                              <Button component="span">
                                <Box
                                  style={{
                                    display: "flex",
                                    position: "absolute",
                                    zIndex: "100",
                                    bottom: "0",
                                    left: "0"
                                  }}
                                >
                                  <EditIcon
                                    style={{
                                      color: "#ffffff",
                                      backgroundColor: "#03A430",
                                      padding: "8px",
                                      borderRadius: "50%"
                                    }}
                                  ></EditIcon>
                                </Box>
                                <Avatar
                                  style={{
                                    height: "120px",
                                    width: "120px"
                                  }}
                                  alt="User porfile"
                                  src={image.preview}
                                ></Avatar>
                              </Button>
                            </Grid>
                          </>
                        ) : (
                          <Grid
                            container
                            direction="column"
                            alignItems="center"
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              position: "relative"
                            }}
                          >
                            <Button component="span">
                              <Box
                                style={{
                                  display: "flex",
                                  position: "absolute",
                                  zIndex: "100",
                                  bottom: "0",
                                  left: "0"
                                }}
                              >
                                <EditIcon
                                  style={{
                                    color: "#ffffff",
                                    backgroundColor: "#03A430",
                                    padding: "8px",
                                    borderRadius: "50%"
                                  }}
                                ></EditIcon>
                              </Box>
                              <Avatar
                                style={{
                                  height: "120px",
                                  width: "120px"
                                }}
                                alt="User porfile"
                                src={`${process.env.REACT_APP_AWS_PATH}/profile_photo/${initialValues.User_Profile_Image.File_Name}`}
                              ></Avatar>
                            </Button>
                          </Grid>
                        )}
                      </label>
                      <input
                        type="file"
                        id="upload-button"
                        style={{ display: "none" }}
                        onChange={handleChange}
                      />
                      <br />
                    </div>
                  </Grid>
                  <Grid item xs />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Choose User Type{" "}
                      <p style={{ color: "red", margin: "0" }}> *</p>
                    </Typography>
                    <Field
                      as={TextField}
                      required
                      disabled
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={touched.Profile_Code && errors.Profile_Code}
                      onChange={(e) => handleUserType(e.target.value)}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      // disabled={props.formData.formMode !== "Create"}
                      // label="Choose User Type"
                      name="Profile_Code"
                      id="Profile_Code"
                      select
                      style={{ height: "4.187em" }}
                      helperText={
                        touched.Profile_Code && errors.Profile_Code
                          ? errors.Profile_Code
                          : ""
                      }
                    >
                      {props.formData.profileTypeList &&
                        props.formData?.profileTypeList?.Profile_Data.map(
                          (key) => {
                            if (
                              key.ProfileName !== "Company" &&
                              JSON.parse(localStorage.getItem("menu"))
                                .Role_Permission_Data.Profile_Code ===
                                "SuperAdmin"
                                ? true
                                : key.ProfileName !== "SuperAdmin"
                            ) {
                              return (
                                <MenuItem
                                  className={classes.dialogBoxDropDown}
                                  value={key.Profile_Code}
                                >
                                  {key.ProfileName}
                                </MenuItem>
                              );
                            }
                          }
                        )}
                    </Field>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      User Code <p style={{ color: "red", margin: "0" }}> *</p>
                    </Typography>
                    <Field
                      as={TextField}
                      required
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.repoCode && touched.repoCode}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Button
                              style={{ marginBottom: "4px" }}
                              size="small"
                              fullWidth
                              disabled={props.formData.formMode !== "Create"}
                              aria-label="toggle password visibility"
                              onClick={() => autoGeneratedRepCodeCall()}
                            >
                              Auto Generate
                            </Button>
                            <Divider
                              variant="middle"
                              style={{ height: 18, margin: 4 }}
                              orientation="vertical"
                            />
                            <Tooltip
                              style={{ marginBottom: "4px" }}
                              title="Can be Entered manually Or You can auto-Generated"
                              classes={{
                                tooltip: classes.customTooltip
                              }}
                            >
                              <ErrorOutlineIcon />
                            </Tooltip>
                          </InputAdornment>
                        ),
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      disabled={props.formData.formMode !== "Create"}
                      // label="User Code"
                      onChange={(e) => {
                        setFieldValue("repoCode", e.target.value.trim());
                      }}
                      name="repoCode"
                      id="repoCode"
                      style={{ height: "4.187em" }}
                      helperText={
                        errors.repoCode && touched.repoCode
                          ? errors.repoCode
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs />
                </Grid>
                <Grid container spacing={2}>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      First Name <p style={{ color: "red", margin: "0" }}> *</p>
                    </Typography>
                    <Field
                      as={TextField}
                      required
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.First_Name && touched.First_Name}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize
                        },
                        required: true
                      }}
                      // label="First Name"
                      name="First_Name"
                      helperText={
                        errors.First_Name && touched.First_Name
                          ? errors.First_Name
                          : ""
                      }
                      id="First_Name"
                      style={{ height: "4.187em" }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Middle Name{" "}
                      <p style={{ color: "red", margin: "0" }}> &nbsp; </p>
                    </Typography>
                    <Field
                      as={TextField}
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.Middle_Name && touched.Middle_Name}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize
                        }
                        // required: true,
                      }}
                      // label="Middle Name"
                      name="Middle_Name"
                      id="Middle_Name"
                      style={{ height: "4.187em" }}
                      helperText={
                        errors.Middle_Name && touched.Middle_Name
                          ? errors.Middle_Name
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Last Name <p style={{ color: "red", margin: "0" }}> *</p>
                    </Typography>
                    <Field
                      as={TextField}
                      required
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.Last_Name && touched.Last_Name}
                      InputLabelProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize
                        },
                        required: true
                      }}
                      // label="Last Name"
                      name="Last_Name"
                      id="Last_Name"
                      style={{ height: "4.187em" }}
                      helperText={
                        errors.Last_Name && touched.Last_Name
                          ? errors.Last_Name
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Email Address{" "}
                      <p style={{ color: "red", margin: "0" }}> *</p>
                    </Typography>
                    <Field
                      as={TextField}
                      required
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.Email && touched.Email}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize
                        },
                        required: true
                      }}
                      disabled={props.formData.formMode !== "Create"}
                      // label="Email"
                      name="Email"
                      id="Email"
                      style={{ height: "4.187em" }}
                      helperText={
                        errors.Email && touched.Email ? errors.Email : ""
                      }
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Mobile number{" "}
                      <p style={{ color: "red", margin: "0" }}> *</p>
                    </Typography>
                    <Field
                      as={TextField}
                      required
                      size="small"
                      fullWidth
                      variant="outlined"
                      // label="Mobile"
                      name="Mobile"
                      id="Mobile"
                      style={{ height: "4.187em" }}
                      error={errors.Mobile && touched.Mobile}
                      helperText={
                        errors.Mobile && touched.Mobile ? errors.Mobile : ""
                      }
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",

                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize
                        },
                        required: true,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Select
                              id="country-menu"
                              value={values.dialCode}
                              onChange={(event) => {
                                setFieldValue("dialCode", event.target.value);
                              }}
                              classes={{ root: "country-list" }}
                              className="no-underline"
                              inputProps={{
                                "aria-label": "Without label"
                              }}
                            >
                              {props.formData.countryTypeList &&
                                props.formData.countryTypeList.Country_Data.map(
                                  (country) => (
                                    <MenuItem
                                      value={country.Dial_Code}
                                      option={
                                        props.formData.countryTypeList
                                          ?.Country_Data
                                      }
                                      key={country.Country_Code}
                                      className="country"
                                    >
                                      <span
                                        className={`country-flag ${country.Country_Code.toLowerCase().substring(
                                          0,
                                          2
                                        )}`}
                                      />
                                      &nbsp;
                                      <span className="dial-code">{`+${country.Dial_Code}`}</span>
                                      &nbsp;
                                      <span className="country-name">
                                        ({country.Country_Name})
                                      </span>
                                    </MenuItem>
                                  )
                                )}
                            </Select>
                          </InputAdornment>
                        )
                      }}
                    />
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Phone number{" "}
                      <p style={{ color: "red", margin: "0" }}> &nbsp; </p>
                    </Typography>
                    <Field
                      as={TextField}
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.Phone_No && touched.Phone_No}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      // label="Alternative number"
                      name="Phone_No"
                      id="Phone_No"
                      style={{ height: "4.187em" }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize
                        }

                        // required: true
                      }}
                      helperText={
                        errors.Phone_No && touched.Phone_No
                          ? errors.Phone_No
                          : " "
                      }
                    />
                  </Grid>
                </Grid>
                <Divider style={{ marginTop: "20px" }} />
                <Typography
                  style={{
                    margin: "2.5% 0",
                    marginBottom: "30px",
                    fontWeight: "600",
                    color: "#3E4E9B",
                    fontSize: "18px"
                  }}
                >
                  Roles/Permissions
                </Typography>
                <Grid container spacing={2}>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Role <p style={{ color: "red", margin: "0" }}> * </p>
                    </Typography>
                    <Field
                      as={TextField}
                      required
                      disabled
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.Role_ID && touched.Role_ID}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      // label="Role"
                      name="Role_ID"
                      id="Role_ID"
                      select
                      helperText={
                        errors.Role_ID && touched.Role_ID ? errors.Role_ID : ""
                      }
                      style={{ height: "4.187em" }}
                    >
                      {!filterDataRole &&
                        props.roles.providerroleList &&
                        props.roles.providerroleList.Roles_Data &&
                        props.roles.providerroleList.Roles_Data.map((key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key._id}
                          >
                            {key.RoleName}
                          </MenuItem>
                        ))}
                      {filterDataRole &&
                        filterDataRole.map((key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key._id}
                          >
                            {key.RoleName}
                          </MenuItem>
                        ))}
                    </Field>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Reports To{" "}
                      <p style={{ color: "red", margin: "0" }}> &nbsp; </p>
                    </Typography>
                    <Field
                      as={TextField}
                      // required
                      disabled
                      size="small"
                      fullWidth
                      variant="outlined"
                      // error={errors.reportsTo && touched.reportsTo}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      // disabled={props.formData.formMode !== "Create"}
                      // label="Reports To"
                      name="reportsTo"
                      id="reportsTo"
                      select
                      style={{ height: "4.187em" }}
                      // helperText={
                      //   errors.reportsTo && touched.reportsTo
                      //     ? errors.reportsTo
                      //     : ""
                      // }
                    >
                      {userList.map((key) => (
                        <MenuItem
                          className={classes.dialogBoxDropDown}
                          value={key.UserID}
                        >
                          {`${key.FName} ${key.LName}`}
                        </MenuItem>
                      ))}
                    </Field>
                  </Grid>
                  <Grid item lg={4} sm={6} xs={12}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      TimeZone <p style={{ color: "red", margin: "0" }}> * </p>
                    </Typography>
                    <Field
                      as={TextField}
                      required
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.Time_Zone_Code && touched.Time_Zone_Code}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      // label="TimeZone"
                      name="Time_Zone_Code"
                      id="Time_Zone_Code"
                      select
                      style={{ height: "4.187em" }}
                      helperText={
                        errors.Time_Zone_Code && touched.Time_Zone_Code
                          ? errors.Time_Zone_Code
                          : ""
                      }
                    >
                      <MenuItem
                        className={classes.dialogBoxDropDown}
                        value="option"
                      >
                        Choose your timezone
                      </MenuItem>
                      {props.formData.timeZoneList &&
                        props.formData.timeZoneList.Timezone_Data.map((key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key.Timezone_Code}
                          >
                            {key.Timezone_Name}
                          </MenuItem>
                        ))}
                    </Field>
                  </Grid>
                </Grid>

                <Divider style={{ marginTop: "20px" }} />
                <Typography
                  style={{
                    margin: "2.5% 0",
                    marginBottom: "30px",
                    fontWeight: "600",
                    color: "#3E4E9B",
                    fontSize: "18px"
                  }}
                >
                  Mailing Address
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={4} sm={6}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Address Line 1{" "}
                      <p style={{ color: "red", margin: "0" }}> * </p>
                    </Typography>
                    <Field
                      as={TextField}
                      required
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.Address1 && touched.Address1}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize
                        },
                        required: true
                      }}
                      // label="Address Line 1"
                      name="Address1"
                      id="Address1"
                      style={{ height: "4.187em" }}
                      helperText={
                        errors.Address1 && touched.Address1
                          ? errors.Address1
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} sm={6}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600"
                      }}
                    >
                      Address Line 2
                    </Typography>
                    <Field
                      as={TextField}
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.Address2 && touched.Address2}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize
                        }
                        // required: true
                      }}
                      // label="Address Line 2"
                      name="Address2"
                      id="Address2"
                      style={{ height: "4.187em" }}
                      helperText={
                        errors.Address2 && touched.Address2
                          ? errors.Address2
                          : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} sm={6}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Country <p style={{ color: "red", margin: "0" }}> * </p>
                    </Typography>
                    <Field
                      as={TextField}
                      required
                      size="small"
                      fullWidth
                      variant="outlined"
                      error={errors.Country_Code && touched.Country_Code}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      // label="Country"
                      name="Country_Code"
                      id="Country_Code"
                      select
                      style={{ height: "4.187em" }}
                      helperText={
                        errors.Country_Code && touched.Country_Code
                          ? errors.Country_Code
                          : ""
                      }
                    >
                      {props.formData.countryTypeList &&
                        props.formData.countryTypeList.Country_Data.map(
                          (key) => (
                            <MenuItem
                              className={classes.dialogBoxDropDown}
                              value={key.Country_Code}
                            >
                              {key.Country_Name}
                            </MenuItem>
                          )
                        )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} lg={4} sm={6}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      City <p style={{ color: "red", margin: "0" }}> * </p>
                    </Typography>
                    <Field
                      as={TextField}
                      error={errors.City && touched.City}
                      required
                      size="small"
                      fullWidth
                      variant="outlined"
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          focused: "focused",
                          input: classes.resize
                        },
                        required: true
                      }}
                      // label="City"
                      name="City"
                      id="City"
                      style={{ height: "4.187em" }}
                      helperText={
                        errors.City && touched.City ? errors.City : ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} lg={4} sm={6}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      State <p style={{ color: "red", margin: "0" }}> * </p>
                    </Typography>
                    {values.Country_Code === "US" ? (
                      <Field
                        as={TextField}
                        required
                        size="small"
                        fullWidth
                        variant="outlined"
                        error={errors.State_Code && touched.State_Code}
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        InputProps={{
                          color: "secondary",
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused"
                          }
                        }}
                        // label="State"
                        name="State_Code"
                        id="State_Code"
                        select
                        style={{ height: "4.187em" }}
                        helperText={
                          errors.State_Code && touched.State_Code
                            ? errors.State_Code
                            : " "
                        }
                      >
                        {locationCity.map((key) => (
                          <MenuItem
                            className={classes.dialogBoxDropDown}
                            value={key.value}
                          >
                            {key.name}
                          </MenuItem>
                        ))}
                      </Field>
                    ) : (
                      <>
                        <Grid item>
                          <Field
                            as={TextField}
                            required
                            size="small"
                            fullWidth
                            variant="outlined"
                            error={errors.State_Code && touched.State_Code}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              color: "secondary",
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused"
                              }
                            }}
                            // label="State"
                            name="State_Code"
                            id="State_Code"
                            style={{ height: "4.187em" }}
                            helperText={
                              errors.State_Code && touched.State_Code
                                ? errors.State_Code
                                : " "
                            }
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} lg={4} sm={6}>
                    <Typography
                      style={{
                        marginBottom: "4px",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center"
                      }}
                    >
                      Zip <p style={{ color: "red", margin: "0" }}> * </p>
                    </Typography>
                    <Field
                      as={TextField}
                      size="small"
                      fullWidth
                      variant="outlined"
                      required
                      error={errors.Zip && touched.Zip}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        color: "secondary",
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      // label="Zip"
                      name="Zip"
                      id="Zip"
                      style={{ height: "4.187em" }}
                      helperText={errors.Zip && touched.Zip ? errors.Zip : ""}
                    />
                  </Grid>
                </Grid>

                <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

                {window.location.pathname.match("admin") ? (
                  <>
                    <Grid container spacing={2}>
                      <Grid item xs>
                        <Box
                          height={40}
                          className={classes.radioBox}
                          style={{ marginTop: "0px" }}
                        >
                          <div
                            style={{ marginRight: "2%" }}
                            className={classes.radioBoxRoot}
                          >
                            <Typography className={classes.textColorRadioCard}>
                              Profile Visibility :
                            </Typography>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <GreenRadio
                              className={classes.checkBox}
                              checked={values.profileVisibility == "1"}
                              onChange={handleVisability}
                              value="1"
                              name="radio-button-demo"
                              inputProps={{ "aria-label": "1" }}
                              style={{ marginRight: "10px" }}
                            />
                            <Typography className={classes.textColorRadioCard}>
                              Own
                            </Typography>
                          </div>
                          <div
                            style={{ marginRight: "2%" }}
                            className={classes.radioBoxRoot}
                          >
                            <GreenRadio
                              checked={values.profileVisibility == "2"}
                              onChange={handleVisability}
                              className={classes.checkBox}
                              value="2"
                              style={{ marginRight: "10px" }}
                              inputProps={{ "aria-label": "2" }}
                            />
                            <Typography className={classes.textColorRadioCard}>
                              Own and Persons Directly reporting to me
                            </Typography>
                          </div>
                          {/* <div
                      style={{ marginRight: "2%" }}
                      className={classes.radioBoxRoot}
                    >
                      <GreenRadio
                        checked={values.profileVisibility === "3"}
                        onChange={handleVisability}
                        className={classes.checkBox}
                        value="3"
                        style={{ marginRight: "10px" }}
                        inputProps={{ "aria-label": "3" }}
                      />
                      <Typography className={classes.textColorRadioCard}>
                        Groups
                      </Typography>
                    </div> */}
                        </Box>
                        <div
                          style={{
                            textAlign: "center",
                            textAlignLast: "center"
                          }}
                        >
                          {isEmpty(values.profileVisibility) ? (
                            <Typography
                              component="span"
                              className="error"
                              variant="body2"
                            >
                              {errors.profileVisibility}
                            </Typography>
                          ) : isEmpty(values.groupId) &&
                            values.profileVisibility === "3" ? (
                            <Typography
                              component="span"
                              className="error"
                              variant="body2"
                            >
                              Please select at least 1 group to continue.
                            </Typography>
                          ) : (
                            ""
                          )}
                        </div>
                      </Grid>
                    </Grid>
                    {values.profileVisibility === "3" && (
                      <Grid container spacing={2}>
                        <Grid item xs textAlign="right" sx={{ border: 1 }}>
                          <MultipleSelect
                            {...props}
                            onChange={(event) => {
                              setFieldValue("groupId", event);
                            }}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </>
                ) : (
                  ""
                )}
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={0}
                    style={{
                      textAlign: "center"
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#03A430",
                        borderRadius: "25px",
                        textTransform: "none",
                        height: "40px",
                        width: "20%",
                        color: "#ffffff"
                      }}
                      size="large"
                      variant="contained"
                      color="#ffffff"
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                        forceUpdate();
                        // windowLoad()
                        // window.location.reload();
                      }}
                      disabled={
                        values.profileVisibility === "3" &&
                        isEmpty(values.groupId)
                      }
                      className={classes.submit}
                    >
                      {props.formData.formMode === "Create"
                        ? "Submit"
                        : "Update"}
                    </Button>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item lg={9} md={9} sm={8} xs={0} />
                  <Grid item lg={3} md={3} sm={4} xs={12} textAlign="right">
                    <Box className={classes.buttonBox} textAlign="right">
                      {/* <Button
                      variant="contained"
                      className={classes.clearButton}
                      onClick={() => handleBack()}
                    >
                      Back  
                    </Button>
                    &nbsp;&nbsp;&nbsp; */}
                      {/* <Button
                      style={{
                        backgroundColor: "#03A430",
                        borderRadius: "25px",
                        textTransform: "none",
                        height: "40px",
                        color: "#ffffff",
                      }}
                      size="large"
                      variant="contained"
                      color="#ffffff"
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={
                        values.profileVisibility === "3" &&
                        isEmpty(values.groupId)
                      }
                      // className={classes.submit}
                    >
                      {props.formData.formMode === "Create"
                        ? "Submit"
                        : "Update"}
                    </Button> */}
                      {/* <Button
                      variant="contained"
                      disabled={
                        values.profileVisibility === "3" &&
                        isEmpty(values.groupId)
                      }
                      className={classes.buttonStyle}
                      onClick={() => {
                        handleSubmit();
                      }}
                      style={{ alignItems: "right" }}
                    >
                      {props.formData.formMode === "Create"
                        ? "Submit"
                        : "Update"}
                    </Button> */}
                    </Box>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Box>
      </Card>
    </>
  );
}

UserProfile.propTypes = {
  userProvider: PropTypes.object,
  formData: PropTypes.object,
  getCountryTypeList: PropTypes.func,
  getProfileTypeList: PropTypes.func,
  getRoleList: PropTypes.func,
  getTimeZoneList: PropTypes.func,
  changeUserFormMode: PropTypes.func,
  getUserList: PropTypes.func,
  getProviderRoleList: PropTypes.func,
  userRole: PropTypes.object,
  getUserListProvider: PropTypes.func,
  roles: PropTypes.object,
  userListApi: PropTypes.func,
  getGroupListData: PropTypes.func,
  autoGeneratedRepCode: PropTypes.func,
  updateUserApiCall: PropTypes.func,
  createNewUserApiCall: PropTypes.func,
  setSendClientIdInApi: PropTypes.func,
  updateProfileImage: PropTypes.func
};

const mapStateToProps = (app) => ({
  formData: app.users,
  userRole: app.userRole,
  roles: app.roles
});
export default connect(mapStateToProps, {
  updateUserApiCall,
  createNewUserApiCall,
  getCountryTypeList,
  getProfileTypeList,
  getRoleList,
  autoGeneratedRepCode,
  userListApi,
  changeUserFormMode,
  getTimeZoneList,
  getUserList,
  getUserListProvider,
  getProviderRoleList,
  getGroupListData,
  updateProfileImage
})(UserProfile);
