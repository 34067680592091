import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor
} from "assets/jss/material-dashboard-react";

const headerStyle = (theme) => ({
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
    borderBottom: "0",
    marginBottom: "60px",
    position: "absolute",
    // width: "99%",
    paddingTop: "10px",
    color: grayColor[7],
    border: "0",
    // borderRadius: "3px",
    padding: "10px 0",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block"
  },
  hideSearch: {
    [theme.breakpoints.up("md")]: {
      width: "90%",
      display: "flex",
      flexDirection: "row",
      alignItem: "center",
      justifyContent: "center"
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    },
    width: "100%"
  },
  newSearchDiv: {
    borderRadius: "24px",
    backgroundColor: "#EFF4FF",
    marginLeft: "20px",
    fontSize: "16px",
    lineHeight: "18px",
    fontWeight: "400",
    padding: "10px 20px",
    display: "flex"
  },
  container: {
    ...container,
    minHeight: "50px",
    paddingLeft: "0px"
  },
  flex: {
    flex: 1
  },
  hideHeader: {
    [theme.breakpoints.up("md")]: {
      width: "30%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "auto"
    }
  },
  title: {
    ...defaultFont,
    letterSpacing: "unset",
    lineHeight: "30px",
    marginLeft: "-10px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent"
    }
  },
  appResponsive: {
    top: "8px"
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  }
});

export default headerStyle;
