/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Search from "@material-ui/icons/Search";
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card";
import { Typography } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import ClearIcon from "@material-ui/icons/Clear";
import { Box, Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableFooter from "@material-ui/core/TableFooter";
import TextField from "@material-ui/core/TextField";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import isEmpty from "lodash/isEmpty";
import ErrorIcon from "@material-ui/icons/Error";
import Divider from "@material-ui/core/Divider";
// import styles from "assets/jss/material-dashboard-react/components/businessForm";
import {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  setDefaultOfferValue,
  getPatientOfferData,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  setSelectPatientOffer,
  setBackToSearchFromDetails,
  addPreposalidsArray,
  patientTpoScore,
  generatePatientTpoScore
} from "Redux/Actions/genrateOfferPatient";
import Modal from "@material-ui/core/Modal";
import CoustomAppLoader from "components/CoustomAppLoader/Index";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import SwipeableViews from "react-swipeable-views";
import ProposalTable from "./PreposalList";
import PatientDetailsSection from "../Offerpayment/EditPatientInfo";
import gif from "../../assets/img/success.gif";
import AddPatientForm from "../Patient/AddPatient";
import ActivePlans from "./ActivePlan";
import HeaderOffer from "./HeaderOffer";
import CompletedPlans from "./CompletedPlan";
// import tpoLavel0 from "../../assets/img/TPO_Level-0.jpeg";
// import tpoLavel1 from "../../assets/img/TPO_Level-1.jpg";
// import tpoLavel2 from "../../assets/img/TPO_Level-2.jpg";
// import tpoLavel3 from "../../assets/img/TPO_Level-3.jpg";
// import tpoLavel4 from "../../assets/img/TPO_Level-4.jpg";
import offerDetailStyles from "../../assets/jss/material-dashboard-react/components/offerDetails";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`
  };
}

const useStyles = makeStyles(offerDetailStyles);
const OfferDetails = (props) => {
  const sendApiLink = useSelector((state) => state.app.sendClientIdApi);
  const [preActivePlanList, setPreActivePlanList] = React.useState(
    props.tableData.activePlanPatientList
  );
  const classes = useStyles();
  const [searchData, setSearchData] = React.useState(props.tableData.data);
  const [preAddPatientData, setPreAddPatientData] = React.useState(
    props.tableData.addPatientData
  );
  const [clickLink, setClickLink] = React.useState(false);
  const [addPatientModal, setAddPatientModal] = React.useState(false);
  const [patientModal, setPatiemtModal] = React.useState(false);
  const [showOfferDEtails, setShowOfferDEtails] = React.useState(
    props.tableData.selectPatient
  );
  const [hideTable, setHideTable] = React.useState(false);
  const [disableAction, setDisableAction] = React.useState(false);
  const [tpoScoreModal, setTpoScoreModal] = React.useState(false);
  const [offerId, setOfferId] = React.useState(
    props.tableData.createOffer && props.tableData.createOffer.data.OfferId
  );
  const [preposalData, setPropsalData] = React.useState([]);
  const [setUpMonthlyModal, setSetUpMonthlyModal] = React.useState(false);
  const [preCptCodeData, setPreCptCodeData] = React.useState(
    props.tableData.cptCodeData
  );
  const [preGenrateOfferData, setPreGenrateOfferData] = React.useState(
    props.tableData.genrateOfferData
  );
  const [tpoScoreData, setTpoScoreData] = React.useState(
    props.tableData.patientTPOScoreList &&
      props.tableData.patientTPOScoreList.data
  );
  const [preGreGenrateOfferLoading, setPreGreGenrateOfferLoading] =
    React.useState(props.tableData.genrateOfferLoading);
  const commonText = props.tableData.patientSearchData;
  // const [searchVlaues, setSearchValues] = React.useState({
  //   commonText: "",
  //   ClientId: props.app.sendClientIdApi.toString(),
  //   next: "",
  // });
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleScrollActivePlan = () => {
    if (!isEmpty(titleActivePlan.current)) {
      titleActivePlan.current.scrollIntoView({
        top: "0",
        behavior: "smooth",
        block: "start"
      });
    }
  };

  const handleScrollNewDues = () => {
    if (!isEmpty(titleRef.current)) {
      titleRef.current.scrollIntoView({
        top: "0",
        behavior: "smooth",
        block: "start"
      });
    }
  };

  React.useEffect(() => {
    if (value === 1) {
      handleScrollActivePlan();
    }
    if (value === 0) {
      handleScrollNewDues();
    }
  }, [value]);

  React.useEffect(() => {
    if (preCptCodeData !== props.tableData.cptCodeData) {
      setPreCptCodeData(props.tableData.cptCodeData);
      if (!showOfferDEtails) {
        setPatiemtModal(true);
      }
    }
    if (preActivePlanList !== props.tableData.activePlanPatientList) {
      setPreActivePlanList(props.tableData.activePlanPatientList);
    }
    if (searchData !== props.tableData.data) {
      setSearchData(props.tableData.data);
      if (
        props.tableData.data &&
        props.tableData.data.data &&
        props.tableData.addProposalData &&
        props.tableData.addProposalData.status
      ) {
        const filterPreposalDetails = props.tableData.data.data.filter(
          (key) =>
            key.Patient_id === props.tableData.selectPatient.data.Patient_id
        );
        if (filterPreposalDetails.length > 0) {
          filterPreposalDetails[0].data = filterPreposalDetails[0];
          props.setSelectPatientOffer(filterPreposalDetails[0]);
          setShowOfferDEtails(filterPreposalDetails[0]);
        }
      }
    }
    if (preGenrateOfferData !== props.tableData.genrateOfferData) {
      setPreGenrateOfferData(props.tableData.genrateOfferData);
    }
    if (
      props.tableData.patientTPOScoreList &&
      props.tableData.patientTPOScoreList.data
    ) {
      if (tpoScoreData !== props.tableData.patientTPOScoreList.data) {
        setTpoScoreData(props.tableData.patientTPOScoreList.data);
      }
    }
    if (preGreGenrateOfferLoading !== props.tableData.genrateOfferLoading) {
      setPreGreGenrateOfferLoading(props.tableData.genrateOfferLoading);
      if (
        props.tableData.genrateOfferData.status &&
        !props.tableData.genrateOfferLoading
      ) {
        props.state();
        props.history.push("/admin/offerpayment");
      }
    }
    if (clickLink && props.tableData.genrateOfferData.status) {
      props.state();
      props.history.push("/admin/offerpayment");
    }
  }, [props]);

  const handlePrePosalReset = () => {
    setHideTable(false);
    setDisableAction(false);
    setOfferId(false);
  };

  const onClickBackSearchHandle = () => {
    props.setBackToSearchFromDetails();
    const data = {
      commonText,
      ClientId: props.app.sendClientIdApi.toString()
    };
    props.patientSearch(data);
    props.state();
    props.history.push("/admin/Offers");
  };
  const tpoScoreLevelModel = () => {
    const data = {
      patientId: showOfferDEtails.data.Patient_id
    };
    if (
      props.userProvider.userDetailsById.Users_Data[0].Profile_Code === "SA"
    ) {
      props.patientTpoScore(data);
      setTpoScoreModal(true);
    }
  };
  const calculateScoreNext = () => {
    const data = {
      patientId: showOfferDEtails.data.Patient_id
    };
    props.generatePatientTpoScore(data);

    const dataScore = {
      patientId: showOfferDEtails.data.Patient_id
    };
    props.patientTpoScore(dataScore);
    // setTpoScoreModal(false);
  };

  const titleRef = React.useRef();
  const titleActivePlan = React.useRef();

  return (
    <Card
      style={{
        height: !hideTable && !showOfferDEtails ? "330px" : "auto"
        // marginTop: "-2%",
      }}
    >
      <Grid container spacing={0} align="center">
        <Grid
          item
          xs={12}
          md={2}
          style={{
            paddingLeft: "20px",
            marginTop: "10px",
            marginBottom: "10px"
          }}
        >
          <Button
            style={{ textTransform: "none" }}
            color="primary"
            onClick={() => onClickBackSearchHandle()}
          >
            <KeyboardBackspaceIcon /> &nbsp;&nbsp; Back to Search
          </Button>
          <hr className={classes.hrStyleOfferDetailsHorizontal}></hr>
        </Grid>

        <Grid
          item
          xs={12}
          md={3}
          onClick={() => tpoScoreLevelModel()}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "35px"
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            Options Available For: &nbsp;
          </div>
          <div>{`${showOfferDEtails.data.Patient_Name} -`}</div>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          style={{
            position: "relative",
            display: "inline-flex",
            justifyContent: "left",
            alignItems: "center",
            fontSize: "20px",
            fontWeight: "bold",
            paddingLeft: "35px"
          }}
        >
          TPO Level
          <div
            style={{
              position: "relative",
              display: "inline-flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <FiberManualRecordIcon
              fontSize="large"
              style={{
                color:
                  Math.round(showOfferDEtails.data.tpoLevel) === 0
                    ? "#cfcfcf"
                    : Math.round(showOfferDEtails.data.tpoLevel) === 1
                    ? "#FF0000"
                    : Math.round(showOfferDEtails.data.tpoLevel) === 2
                    ? "#FFA500"
                    : Math.round(showOfferDEtails.data.tpoLevel) === 3
                    ? "#FFFF00"
                    : Math.round(showOfferDEtails.data.tpoLevel) === 4
                    ? "#00FF00"
                    : "#cfcfcf",
                fontSize: "3rem"
              }}
            />
            <Typography
              align="center"
              style={{
                position: "absolute",
                lineHeight: 1,
                color: "#000000",
                textAlign: "center",
                width: "36px",
                fontSize: "1.5rem",
                fontWeight: 700
              }}
            >
              {Math.round(showOfferDEtails.data.tpoLevel) === 0
                ? 0
                : Math.round(showOfferDEtails.data.tpoLevel) === 1
                ? 1
                : Math.round(showOfferDEtails.data.tpoLevel) === 2
                ? 2
                : Math.round(showOfferDEtails.data.tpoLevel) === 3
                ? 3
                : Math.round(showOfferDEtails.data.tpoLevel) === 4
                ? 4
                : 0}
            </Typography>
          </div>
        </Grid>
      </Grid>

      <hr className={classes.hrStyleOfferDetails}></hr>
      <Grid item>
        <Modal open={tpoScoreModal} onClose={() => setTpoScoreModal(false)}>
          <div className={classes.modalDiv}>
            <Card className={classes.modalCard} style={{ width: "50%" }}>
              <Grid constainer className={classes.margin}>
                <div className={classes.modalCardHeader}>
                  <CardHeader
                    subheader="TPO Score"
                    align="center"
                    width="95%"
                  />
                  <ClearIcon
                    onClick={() => setTpoScoreModal(false)}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <Grid item style={{ alignItems: "center" }}>
                  <div className={classes.cardDiv} style={{ marginTop: "0px" }}>
                    <TableContainer>
                      <Table
                        className={classes.patientTable}
                        aria-labelledby="tableTitle"
                        aria-label="enhanced table"
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              key={Math.random()}
                              className={classes.cellTextHeader}
                            >
                              ID
                            </TableCell>
                            <TableCell
                              key={Math.random()}
                              className={classes.cellTextHeader}
                            >
                              Parameter Name
                            </TableCell>
                            <TableCell
                              key={Math.random()}
                              className={classes.cellTextHeader}
                            >
                              Weightage
                            </TableCell>
                            <TableCell
                              key={Math.random()}
                              className={classes.cellTextHeader}
                            >
                              Input Value
                            </TableCell>
                            <TableCell
                              key={Math.random()}
                              className={classes.cellTextHeader}
                            >
                              Score
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {tpoScoreData &&
                            tpoScoreData.map((key) => (
                              <TableRow
                                hover
                                role="checkbox"
                                key={Math.random()}
                              >
                                <TableCell
                                  className={classes.cellText}
                                  align="left"
                                >
                                  {key.scoreId}
                                </TableCell>
                                <TableCell
                                  className={classes.cellText}
                                  align="left"
                                >
                                  {key.parameterName}
                                </TableCell>
                                <TableCell
                                  className={classes.cellText}
                                  align="left"
                                >
                                  {key.weightage}
                                </TableCell>
                                <TableCell
                                  className={classes.cellText}
                                  align="left"
                                >
                                  {key.inputValue}
                                </TableCell>
                                <TableCell
                                  className={classes.cellText}
                                  align="left"
                                >
                                  {key.score}
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                        <TableFooter>
                          <TableRow hover role="checkbox" key={Math.random()}>
                            <TableCell
                              colSpan={4}
                              align="right"
                              className={classes.totalScoreHeading}
                            >
                              Total Score
                            </TableCell>
                            <TableCell
                              className={classes.totalScoreText}
                              align="left"
                            >
                              {props.tableData.patientTPOScoreList &&
                                props.tableData.patientTPOScoreList.totalScore}
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </TableContainer>
                  </div>
                </Grid>
                <Grid item align="center" className={classes.tpoLevelfooter}>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            align="right"
                            className={classes.scoreText}
                          >
                            TPO Level:{" "}
                            {props.tableData.patientTPOScoreList &&
                              props.tableData.patientTPOScoreList.tpoLevel}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid
                  item
                  align="center"
                  style={{
                    alignItems: "center",
                    paddingBottom: "10px",
                    paddingTop: "10px"
                  }}
                >
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    size="large"
                    onClick={() => calculateScoreNext()}
                  >
                    Next Score
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </div>
        </Modal>
      </Grid>
      <HeaderOffer
        {...props}
        handleScrollActivePlan={handleScrollActivePlan}
        handleScrollNewDues={handleScrollNewDues}
        handleChange={handleChange}
        showOfferDEtails={showOfferDEtails}
        tableData={props.tableData.patientDocumentList}
        titleRef={titleRef}
        titleActivePlan={titleActivePlan}
      />

      <Grid
        container
        style={{ padding: showOfferDEtails ? "3% 2% 0px 2%" : "0px" }}
      >
        <Grid item xs={12} sm={12}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            spacing={2}
            style={{ textTransform: "none" }}
          >
            <Tab
              style={{ textTransform: "none" }}
              label="Patient Detail"
              {...a11yProps(2)}
            />
            <Tab
              style={{ textTransform: "none" }}
              label={`New Dues (${props.tableData.selectPatient.newDueCount})`}
              {...a11yProps(0)}
            />
            <Tab
              style={{ textTransform: "none" }}
              label={`Active Plans (${props.tableData.activePlanPatientList.data.length})`}
              {...a11yProps(1)}
            />
            <Tab
              style={{ textTransform: "none" }}
              label={`Completed Plans (${props.tableData.completedPlanPatientList.data.length})`}
              {...a11yProps(2)}
            />
          </Tabs>
          <hr style={{ border: "1px solid #efefef", margin: "0px" }}></hr>

          <SwipeableViews
            axis="x"
            index={value}
            // onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0}>
              <Grid container style={{ marginTop: "20px" }}>
                <PatientDetailsSection />
              </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ProposalTable
                setOfferId={(data) => setOfferId(data)}
                offerId={offerId}
                preposalData={preposalData}
                handlePrePosalReset={() => handlePrePosalReset()}
                disableAction={disableAction}
                setPropsalData={(data) => props.addPreposalidsArray(data)}
                {...props}
                setHideTable={() => setHideTable(!hideTable)}
                setClickLink={() => setClickLink(true)}
                showOfferDEtails={showOfferDEtails}
                titleRef={titleRef}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <ActivePlans
                setOfferId={(data) => setOfferId(data)}
                preposalData={preposalData}
                offerId={offerId}
                handlePrePosalReset={() => handlePrePosalReset()}
                disableAction={disableAction}
                setPropsalData={(data) => {}}
                {...props}
                setHideTable={() => setHideTable(!hideTable)}
                showOfferDEtails={showOfferDEtails}
                titleActivePlan={titleActivePlan}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <CompletedPlans
                setOfferId={(data) => setOfferId(data)}
                offerId={offerId}
                preposalData={preposalData}
                handlePrePosalReset={() => handlePrePosalReset()}
                disableAction={disableAction}
                setPropsalData={(data) => {}}
                {...props}
                setHideTable={() => setHideTable(!hideTable)}
                showOfferDEtails={showOfferDEtails}
              />
            </TabPanel>
          </SwipeableViews>
        </Grid>
      </Grid>
      <Modal open={addPatientModal} onClose={() => setAddPatientModal(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div className={classes.cardDiv}>
              {!preAddPatientData.status ? (
                <ErrorIcon className={classes.errorIconStyle} />
              ) : (
                <img src={gif} className={classes.errorIconImage} />
              )}
              <div className={classes.successText}>
                {preAddPatientData.message}
              </div>
              <br />
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="sm"
                onClick={() => setAddPatientModal(false)}
              >
                Ok
              </Button>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal open={patientModal} onClose={() => setPatiemtModal(false)}>
        <AddPatientForm setPatiemtModal={() => setPatiemtModal(false)} />
      </Modal>
      <Modal
        open={setUpMonthlyModal}
        onClose={() => setSetUpMonthlyModal(false)}
      >
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard}>
            <div className={classes.cardDiv}>
              {!props.tableData.setUpMonthlyPayment.status ? (
                <ErrorIcon className={classes.errorIconStyle} />
              ) : (
                <img src={gif} className={classes.errorIconImage} />
              )}
              <div className={classes.successText}>
                {props.tableData.setUpMonthlyPayment.message}
              </div>
              <br />
              <Button
                variant="contained"
                className={classes.buttonStyle}
                color="#696969"
                size="sm"
                onClick={() => setSetUpMonthlyModal(false)}
              >
                Ok
              </Button>
            </div>
          </Card>
        </div>
      </Modal>
      <Modal
        open={patientModal}
        disablePortal
        onClose={() => setPatiemtModal(false)}
      >
        <AddPatientForm setPatiemtModal={() => setPatiemtModal(false)} />
      </Modal>
      <Modal open={props.tableData.genrateOfferLoading} onClose={() => {}}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalCard} style={{ width: "30%" }}>
            <div className={classes.cardDiv1}>
              <CoustomAppLoader loader={props.tableData.genrateOfferLoading} />
            </div>
          </Card>
        </div>
      </Modal>
    </Card>
  );
  // <Card><FinalGenrateOffer handleSendEmailSuccess={() => handleSendEmailSuccess()} values={values} {...props} showOfferDEtails={showOfferDEtails} onClick={() => handlePruntBack()} /></Card>
};

OfferDetails.propTypes = {
  patientSearch: PropTypes.func,
  tableData: PropTypes.object,
  sendEmailApiCall: PropTypes.object,
  uploadOfferDocument: PropTypes.func,
  updatePatientDetailsOffer: PropTypes.func,
  getGenrateOfferData: PropTypes.func,
  createOfferCall: PropTypes.func,
  printOfferCall: PropTypes.func,
  genrateOfferHyperLink: PropTypes.func,
  deleteDocumentCall: PropTypes.func,
  patientPaymentDetailsCall: PropTypes.func,
  getPatientOfferData: PropTypes.func,
  emptyGraphData: PropTypes.func,
  cptCodeDataCall: PropTypes.func,
  editPatientCall: PropTypes.func,
  getAllActivePlanList: PropTypes.func,
  getAllDocumentList: PropTypes.func,
  offerDownloadFile: PropTypes.func,
  state: PropTypes.func,
  history: PropTypes.object,
  addSetUpMonthlyPayment: PropTypes.func,
  getAllCompletedPlanList: PropTypes.func,
  setUpMonthlyOffer: PropTypes.func,
  setLoadingFalse: PropTypes.func,
  setSelectPatientOffer: PropTypes.func,
  setBackToSearchFromDetails: PropTypes.func,
  setDefaultOfferValue: PropTypes.func,
  addPreposalidsArray: PropTypes.func,
  patientTpoScore: PropTypes.func,
  generatePatientTpoScore: PropTypes.func,
  app: PropTypes.object
};

const mapStateToProps = (app) => ({
  tableData: app.genrateOfferPatient,
  userProvider: app.users,
  app: app.app
});

export default connect(mapStateToProps, {
  patientSearch,
  sendEmailApiCall,
  uploadOfferDocument,
  updatePatientDetailsOffer,
  getGenrateOfferData,
  setDefaultOfferValue,
  createOfferCall,
  printOfferCall,
  genrateOfferHyperLink,
  deleteDocumentCall,
  patientPaymentDetailsCall,
  getPatientOfferData,
  emptyGraphData,
  cptCodeDataCall,
  editPatientCall,
  getAllActivePlanList,
  getAllDocumentList,
  offerDownloadFile,
  addSetUpMonthlyPayment,
  getAllCompletedPlanList,
  setUpMonthlyOffer,
  setLoadingFalse,
  setSelectPatientOffer,
  setBackToSearchFromDetails,
  addPreposalidsArray,
  patientTpoScore,
  generatePatientTpoScore
})(OfferDetails);
