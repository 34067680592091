/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-useless-path-segments */
import * as types from "./ActionTypes";
import { post, get, put } from "../Helper/index";
import * as urls from "../../Uintls/Urls";

export const getAllActivePlanList = data => dispatch => {
  dispatch({ type: types.ACTIVE_PLAN_PATIENT_LIST_CALL });
  get({
    url: `${urls.ACTIVE_PLAN_PATIENT_LISTS_URL}`,
    success: types.ACTIVE_PLAN_PATIENT_LIST_SUCCESS,
    failure: types.ACTIVE_PLAN_PATIENT_LIST_ERROR,
    dispatch,
    body: data,
  });
};

export const getOpenFundingAmount = data => dispatch => {
  dispatch({ type: types.OPEN_FUNDING_AMOUNT_CALL });
  get({
    url: `${urls.OPEN_FUNDING_AMOUNT_URL}`,
    success: types.OPEN_FUNDING_AMOUNT_SUCCESS,
    failure: types.OPEN_FUNDING_AMOUNT_ERROR,
    dispatch,
    body: data,
  });
};

export const getPendingOfferAmount = data => dispatch => {
  dispatch({ type: types.PENDING_OFFER_AMOUNT_CALL });
  get({
    url: `${urls.PENDING_OFFER_AMOUNT_URL}`,
    success: types.PENDING_OFFER_AMOUNT_SUCCESS,
    failure: types.PENDING_OFFER_AMOUNT_ERROR,
    dispatch,
    body: data,
  });
};

export const getPaymentStatus = data => dispatch => {
  dispatch({ type: types.PAYMENT_STATUS_DATA_CALL });
  get({
    url: `${urls.PAYMENT_STATUS_URL}`,
    success: types.PAYMENT_STATUS_DATA_SUCCESS,
    failure: types.PAYMENT_STATUS_DATA_ERROR,
    dispatch,
    body: data,
  });
};

export const getOfferAccountStatus = data => dispatch => {
  dispatch({ type: types.OFFER_ACCOUNT_STATUS_CALL });
  get({
    url: `${urls.OFFER_ACCOUNT_STATUS_URL}`,
    success: types.OFFER_ACCOUNT_STATUS_SUCCESS,
    failure: types.OFFER_ACCOUNT_STATUS_ERROR,
    dispatch,
    body: data,
  });
};

export const getOriginalLoanTerm = data => dispatch => {
  dispatch({ type: types.ORIGINAL_LOAN_TERM_CALL });
  get({
    url: `${urls.ORIGINAL_LOAN_TERM_URL}`,
    success: types.ORIGINAL_LOAN_TERM_SUCCESS,
    failure: types.ORIGINAL_LOAN_TERM_ERROR,
    dispatch,
    body: data,
  });
};

export const getRemainingFundingGraph = data => dispatch => {
  dispatch({ type: types.REMAINING_FUND_GRAPH_CALL });
  get({
    url: `${urls.REMAINING_FUND_GRAPH_URL}`,
    success: types.REMAINING_FUND_GRAPH_SUCCESS,
    failure: types.REMAINING_FUND_GRAPH_ERROR,
    dispatch,
    body: data,
  });
};

export const getPaymentDetailsList = data => dispatch => {
  dispatch({ type: types.PAYMENT_LIST_AS_PER_PATIENT_ID_CALL });
  get({
    url: `${urls.PAYMENT_LIST_AS_PER_PATIENT_ID_URL}`,
    success: types.PAYMENT_LIST_AS_PER_PATIENT_ID_SUCCESS,
    failure: types.PAYMENT_LIST_AS_PER_PATIENT_ID_ERROR,
    dispatch,
    body: data,
  });
};

export const getOfferDetailsPatient = data => dispatch => {
  dispatch({ type: types.CURRENTLY_SETUP_MONTH_PATIENT_CALL });
  get({
    url: `${urls.CURRENTLY_SETUP_MONTH_PATIENT_URL}`,
    success: types.CURRENTLY_SETUP_MONTH_PATIENT_SUCCESS,
    failure: types.CURRENTLY_SETUP_MONTH_PATIENT_ERROR,
    dispatch,
    body: data,
  });
};

export const resetPaymentList = () => ({
  type: types.RESET_PAYMENT_LIST,
});

export const errorReset = () => ({
  type: types.RESET_ERROR_PAYMENT_LIST,
});
