import * as types from "./ActionTypes";
import { get } from "../Helper/index";
import * as urls from "../../Uintls/Urls";

export const getRecentContactList = data => dispatch => {
  dispatch({ type: types.RECENT_CONTACT_LISTS_CALL });
  get({
    url: `${urls.RECENT_CONTACT_LISTS_URL}`,
    success: types.RECENT_CONTACT_LISTS_SUCCESS,
    failure: types.RECENT_CONTACT_LISTS_ERROR,
    dispatch,
    body: data,
  });
};

export const getRecentAcceptOffer = data => dispatch => {
  dispatch({ type: types.ACCEPT_OFFER_LIST_CALL });
  get({
    url: `${urls.ACCEPT_OFFER_LIST_URL}`,
    success: types.ACCEPT_OFFER_LIST_SUCCESS,
    failure: types.ACCEPT_OFFER_LIST_ERROR,
    dispatch,
    body: data,
  });
};

export const getExpireApplicationList = data => dispatch => {
  dispatch({ type: types.EXPIRYING_APPLICATION_DATA_CALL });
  get({
    url: `${urls.EXPIRYING_APPLICATION_DATA_URL}`,
    success: types.EXPIRYING_APPLICATION_DATA_SUCCESS,
    failure: types.EXPIRYING_APPLICATION_DATA_ERROR,
    dispatch,
    body: data,
  });
};

export const getActivePatientDashboardData = data => dispatch => {
  dispatch({ type: types.NEW_ACTIVE_PATIENT_DASHBOARD_DATA_CALL });
  get({
    url: `${urls.NEW_ACTIVE_PATIENT_DASHBOARD_DATA_URL}`,
    success: types.NEW_ACTIVE_PATIENT_DASHBOARD_DATA_SUCCESS,
    failure: types.NEW_ACTIVE_PATIENT_DASHBOARD_DATA_ERROR,
    dispatch,
    body: data,
  });
};

export const getDefaultData = data => dispatch => {
  dispatch({ type: types.DEFAULT_DASHBOARD_DATA_CALL });
  get({
    url: `${urls.DEFAULT_DASHBOARD_DATA_URL}`,
    success: types.DEFAULT_DASHBOARD_DATA_SUCCESS,
    failure: types.DEFAULT_DASHBOARD_DATA_ERROR,
    dispatch,
    body: data,
  });
};

export const getMoveToCollection = data => dispatch => {
  dispatch({ type: types.MOVE_TO_COLLECTION_DASHBOARD_CALL });
  get({
    url: `${urls.MOVE_TO_COLLECTION_DASHBOARD_URL}`,
    success: types.MOVE_TO_COLLECTION_DASHBOARD_SUCCESS,
    failure: types.MOVE_TO_COLLECTION_DASHBOARD_ERROR,
    dispatch,
    body: data,
  });
};

export const getCptCodeData = data => dispatch => {
  dispatch({ type: types.CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_CALL });
  get({
    url: `${urls.CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_URL}`,
    success: types.CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_SUCCESS,
    failure: types.CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_ERROR,
    dispatch,
    body: data,
  });
};

export const getBadDebatsData = data => dispatch => {
  dispatch({ type: types.BAD_DEBATES_DASHBOARD_DATA_CALL });
  get({
    url: `${urls.BAD_DEBATES_DASHBOARD_DATA_URL}`,
    success: types.BAD_DEBATES_DASHBOARD_DATA_SUCCESS,
    failure: types.BAD_DEBATES_DASHBOARD_DATA_ERROR,
    dispatch,
    body: data,
  });
};

export const getTotalOutStandingData = data => dispatch => {
  dispatch({ type: types.TOTAL_OUT_SDANDING_DASHBOARD_DATA_CALL });
  get({
    url: `${urls.TOTAL_OUT_SDANDING_DASHBOARD_DATA_URL}`,
    success: types.TOTAL_OUT_SDANDING_DASHBOARD_DATA_SUCCESS,
    failure: types.TOTAL_OUT_SDANDING_DASHBOARD_DATA_ERROR,
    dispatch,
    body: data,
  });
};

export const getTotalOutStandingCount= data => dispatch => {
  dispatch({ type: types.TOTAL_OUT_SDANDING_DASHBOARD_COUNT_CALL });
  get({
    url: `${urls.TOTAL_OUT_SDANDING_DASHBOARD_COUNT_URL}`,
    success: types.TOTAL_OUT_SDANDING_DASHBOARD_COUNT_SUCCESS,
    failure: types.TOTAL_OUT_SDANDING_DASHBOARD_COUNT_ERROR,
    dispatch,
    body: data,
  });
};

export const getDailyDepositeData = data => dispatch => {
  dispatch({ type: types.DAILY_DEPOSITE_DASHBOARD_DATA_CALL });
  get({
    url: `${urls.DAILY_DEPOSITE_DASHBOARD_DATA_URL}`,
    success: types.DAILY_DEPOSITE_DASHBOARD_DATA_SUCCESS,
    failure: types.DAILY_DEPOSITE_DASHBOARD_DATA_ERROR,
    dispatch,
    body: data,
  });
};

export const getDailyDepositeCount = data => dispatch => {
  dispatch({ type: types.DAILY_DEPOSITE_DASHBOARD_COUNT_CALL });
  get({
    url: `${urls.DAILY_DEPOSITE_DASHBOARD_COUNT_URL}`,
    success: types.DAILY_DEPOSITE_DASHBOARD_COUNT_SUCCESS,
    failure: types.DAILY_DEPOSITE_DASHBOARD_COUNT_ERROR,
    dispatch,
    body: data,
  });
};