/* eslint-disable import/extensions */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import moment from "moment";
import SearchIcon from "@material-ui/icons/Search";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "components/CustomButtons/Button";
import Table from "@material-ui/core/Table";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import isEmpty from "lodash/isEmpty";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import noDataImage from "../../assets/img/noDataImage.png";
import { cptCodeData } from "../../Storage/cptCode";
import { SimpleDialog } from "../../components/DialogBox/cptcode";
import { exportToCSV } from "../../Utils/commonUtils";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { getCptData } from "../../Redux/Actions/TableAction";
import ChipInput from "material-ui-chip-input";

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

const useStyles = makeStyles(tableStyle);

function createData(date, cptCode, cptDiscription, loanId, totalFound) {
  return {
    date,
    cptCode,
    cptDiscription,
    loanId,
    totalFound
  };
}

function CptCode(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("cpt");
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selected, setSelected] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [pageNo, setPageNo] = React.useState(1);
  const [selectedValue, setSelectedValue] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [chipValue, setChipValue] = React.useState([]);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [searchVlaues, setSearchValues] = React.useState({
    ClientId:
      props.appData.sendClientIdApi && props.appData.sendClientIdApi.toString(),
    loginEmail: localStorage.getItem("email"),
    cpt: "",
    funded: "",
    fundedLess: "",
    offerFromDate: "",
    offerToDate: "",
    limit: rowsPerPage,
    sortBy: orderBy,
    orderBy: order,
    pageNo: pageNo
  });

  const rows = [];

  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCells = [
      {
        id: "Offer_Date",
        className: "Offer_Date",
        numeric: false,
        disablePadding: true,
        label: "Offer Date"
      },
      {
        id: "CPT_Code",
        className: "CPT_Code",
        numeric: false,
        disablePadding: true,
        label: "CPT Code"
      },
      {
        id: "CPT_Description",
        className: "CPT_Description",
        numeric: false,
        disablePadding: true,
        label: "CPT Description"
      },
      {
        id: "total",
        className: "total",
        numeric: false,
        disablePadding: true,
        label: "Total Found"
      }
    ];

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              className={classes.cellTextHeaderOffer}
              align="center"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell />
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };
  function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      setPageNo(1);
      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      setPageNo(pageNo - 1);
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      setPageNo(pageNo + 1);
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      setPageNo(Math.max(0, Math.ceil(count / rowsPerPage)));
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.rootPagination}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Last</Typography>
          ) : (
            <Typography>First</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Previous</Typography>
          ) : (
            <Typography>Previous</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleFirstPageButtonClick}
          aria-label="first page"
          className={classes.bottonPage}
        >
          <Typography>{page + 1}</Typography>
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <Typography>Next</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? (
            <FirstPageIcon />
          ) : (
            <Typography>Last</Typography>
          )}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  React.useEffect(() => {
    const data = {
      ...searchVlaues,
      pageNo: page,
      limit: rowsPerPage,
      sortBy: orderBy,
      orderBy: order,
      ClientId: props.appData.sendClientIdApi.toString()
    };
    props.getCptData(data);
  }, [page, rowsPerPage, orderBy, order, props.appData.sendClientIdApi]);

  if (props.tableData.cptData && props.tableData.cptData.CPT_Code_Data) {
    props.tableData.cptData.CPT_Code_Data.forEach((v, i) => {
      const cptCode = v.CPT_Code;
      const cptDiscription = v.CPT_Description;
      const date = v.Date_offered;
      const loanId = v.Loan_id;
      const totalFound = v.Total_funded;
      rows.push(createData(date, cptCode, cptDiscription, loanId, totalFound));
    });
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const requestData = { ...searchVlaues };
    requestData.sortBy = property;
    requestData.orderBy = isAsc ? "desc" : "asc";
    requestData.ClientId = props.appData.sendClientIdApi.toString();
    setSearchValues(requestData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const requestData = { ...searchVlaues };
    requestData.limit = parseInt(event.target.value, 10);
    requestData.pageNo = 1;
    requestData.ClientId = props.appData.sendClientIdApi.toString();
    setSearchValues(requestData);
    setPage(1);
  };

  const exportListData = () => {
    const filterValue = "";
    // if (chipValue.length) {
    //   for (const property in chipValue) {
    //     if (chipValue[property] !== "") {
    //       filterValue += `${chipValue[property]}`;
    //     }
    //   }
    // }
    const sheetModuleDetails = [
      ["Module:", "CPT Codes Details"],
      ["Export On:", moment.utc(new Date()).format()],
      [
        "Exported By:",
        `${props.userDetails.userDetailsById.Users_Data[0].FName} ${props.userDetails.userDetailsById.Users_Data[0].LName} <${props.userDetails.userDetailsById.Users_Data[0].Email}>`
      ],
      ["Filters:", filterValue]
    ];
    if (!isEmpty(rows)) {
      const mappedLogs = rows.map((row) => ({
        "Offer Date": row.date,
        "CPT Code": row.cptCode,
        "CPT Discription": row.cptDiscription,
        "Total Found": row.totalFound
      }));
      exportToCSV(
        mappedLogs,
        `CPT Codes${moment().format("MMDDYYYY")}`,
        sheetModuleDetails
      );
    }
  };
  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      const chipName = chip.split(":")[0];
      const newChipValues = [...chipValue];
      newChipValues.splice(index, 1);
      setChipValue([...newChipValues]);
      const newSearchValue = { ...searchVlaues };
      if (chipName === "CPT Code") {
        newSearchValue.cpt = "";
      }
      if (chipName === "Fund Greater than Equal ($)") {
        newSearchValue.funded = "";
      }
      if (chipName === "Fund Less than Equal ($)") {
        newSearchValue.fundedLess = "";
      }
      if (chipName === "Offer Date From") {
        newSearchValue.offerFromDate = "";
      }
      if (chipName === "Offer Date To") {
        newSearchValue.offerToDate = "";
      }
      setSearchValues({ ...newSearchValue });
      const valuesClientID = { ...newSearchValue };
      // valuesClientID.ClientId = sendApiLink;
      // valuesClientID.LoginEmail = localStorage.getItem("email");
      props.getCptData(valuesClientID);
    }
  };

  const getCptCodeFilterDataHandle = (value) => {
    const finalSearchData = {
      ...value,
      cpt: value.cpt == "" ? "" : value.cpt.trim(),
      funded: value.funded == "" ? "" : value.funded.trim(),
      fundedLess: value.fundedLess == "" ? "" : value.fundedLess.trim(),
      orderBy: order,
      sortBy: orderBy,
      limit: rowsPerPage,
      page: pageNo,
      ClientId:
        props.appData.sendClientIdApi &&
        props.appData.sendClientIdApi.toString(),
      loginEmail: localStorage.getItem("email")
    };
    setSearchValues(finalSearchData);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (property === "cpt") {
          chipArray.push(
            `${"CPT Code"}:
                    ${value[property]}`
          );
        } else if (property === "funded") {
          chipArray.push(
            `${"Fund Greater than Equal ($)"}:
              ${value[property]}`
          );
        } else if (property === "fundedLess") {
          chipArray.push(
            `${"Fund Less than Equal ($)"}:
                    ${value[property]}`
          );
        } else if (property === "offerFromDate") {
          chipArray.push(
            `${"Offer Date From"}:
                ${value[property]}`
          );
        } else if (property === "offerToDate") {
          chipArray.push(
            `${"Offer Date To"}:
                  ${value[property]}`
          );
        }
      }
    }

    props.getCptData(finalSearchData);
    setChipValue(chipArray);
  };

  return (
    <>
      <ThemeProvider theme={themeFont}>
        <TableContainer
          className={classes.TableContainer}
          component={Paper}
          elevation={0}
        >
          {windowWidth && (
            <Grid container className={classes.searchContainer}>
              <Grid>
                <Typography className={classes.upperMiniHeadings}>
                  REPORTS
                </Typography>
                <Typography className={classes.uppserBoldHeading}>
                  CPT Codes
                </Typography>
              </Grid>
              <Grid container className={classes.searchContainer}>
                <Grid item className={classes.margin}>
                  <div className={classes.searchWrapper}>
                    <ClickAwayListener
                      mouseEvent="onMouseDown"
                      touchEvent="onTouchStart"
                      onClickAway={() => handleClose()}
                    >
                      <Box
                        style={{
                          minWidth: "40%",
                          display: "block",
                          backgroundColor: "#ffffff",
                          borderRadius: "20px",
                          marginRight: "10px"
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            marginTop: "5px",
                            marginBottom: "5px"
                          }}
                        >
                          <Box style={{ width: "100%" }}>
                            <ChipInput
                              disableUnderline
                              value={chipValue.length > 0 ? chipValue : []}
                              onClick={() => handleClickOpen()}
                              style={{ display: "flex", marginLeft: "10px" }}
                              classes={{ chipContainer: classes.chipContainer }}
                              InputLabelProps={{
                                classes: {
                                  root: classes.activeInputLableColor,
                                  focused: "focused"
                                }
                              }}
                              InputProps={{
                                classes: {
                                  root: classes.activeInputColor,
                                  input: classes.resize,
                                  focused: "focused"
                                }
                              }}
                              placeholder="Search Report"
                              onDelete={(chip, index) =>
                                handleDeleteChip(chip, index)
                              }
                            />
                          </Box>
                          <Box
                            style={{ paddingTop: "10px", paddingRight: "10px" }}
                          >
                            <SearchIcon />
                          </Box>
                        </div>
                        <Collapse
                          in={open}
                          timeout="auto"
                          unmountOnExit
                          className={classes.overLayBox}
                        >
                          <SimpleDialog
                            close={() => handleClose()}
                            searchValue={searchVlaues}
                            seacrhChipData={(data) =>
                              getCptCodeFilterDataHandle(data)
                            }
                          />
                        </Collapse>
                      </Box>
                    </ClickAwayListener>
                    <Button
                      size="large"
                      type="submit"
                      className={classes.exportButton}
                      onClick={exportListData}
                      variant=""
                      color="#ffffff"
                    >
                      Export Data
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}
          {!windowWidth && (
            <Grid container className={classes.searchContainer}>
              <Grid item className={classes.margin} style={{ display: "flex" }}>
                <div className={classes.searchWrapper}>
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => handleClose()}
                  >
                    <Box style={{ width: "85%" }}>
                      <TextField
                        InputLabelProps={{
                          classes: {
                            root: classes.activeInputLableColor,
                            focused: "focused"
                          }
                        }}
                        InputProps={{
                          classes: {
                            root: classes.activeInputColor,
                            input: classes.resize,
                            focused: "focused"
                          }
                        }}
                        inputProps={{
                          autoComplete: "off"
                        }}
                        fullWidth
                        label="Search"
                        onClick={() => handleClickOpen()}
                        name="Search"
                        id="Search"
                      />
                      <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className={classes.overLayBox}
                      >
                        <SimpleDialog
                          close={() => handleClose()}
                          searchValue={searchVlaues}
                          seacrhChipData={(data) =>
                            getCptCodeFilterDataHandle(data)
                          }
                        />
                      </Collapse>
                    </Box>
                  </ClickAwayListener>
                  <Button
                    color="white"
                    aria-label="edit"
                    justIcon
                    round
                    onClick={() => {}}
                  >
                    <Search />
                  </Button>

                  <Grid item className={classes.expirtItemGrid}>
                    <Button
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={exportListData}
                      variant="contained"
                      color="primary"
                      className={classes.buttonStyle}
                    >
                      Export
                    </Button>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          )}
          <Table className={classes.table} aria-label="simple table">
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {!isEmpty(rows) ? (
                rows.map((row, index) => (
                  <TableRow
                    style={{
                      backgroundColor:
                        (index + 1) % 2 === 0 ? "#F6F9FF" : "white"
                    }}
                  >
                    <TableCell className={classes.cellTextNew}>
                      {row?.date}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      {row?.cptCode}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="left">
                      {row?.cptDiscription}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      {row?.totalFound}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={12}>
                    <img
                      src={noDataImage}
                      alt=""
                      className={classes.noDataImage}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "All", value: props.tableData.cptData?.totalDocs }
                  ]}
                  colSpan={12}
                  count={props.tableData.cptData?.totalDocs}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { placeholder: "Show" },
                    native: true
                  }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </ThemeProvider>
    </>
  );
}

CptCode.propTypes = {
  userDetails: PropTypes.any,
  getCptData: PropTypes.func
};

const mapStateToProps = (table) => ({
  tableData: table.table,
  userDetails: table.users,
  appData: table.app
});
export default connect(mapStateToProps, { getCptData })(CptCode);
