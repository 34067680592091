/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-duplicate-props */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import { Grid, Card, Modal } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Collapse from "@material-ui/core/Collapse";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import closeImage from "assets/img/Close_Image.png";
import TableFooter from "@material-ui/core/TableFooter";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import isEmpty from "lodash/isEmpty";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import SearchIcon from "@material-ui/icons/Search";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { exportToCSV } from "../../Utils/commonUtils";
import noDataImage from "../../assets/img/noDataImage.png";
import {
  activePatient,
  getActivePatientByFilterData
} from "../../Redux/Actions/TableAction";
// import { SimpleDialog } from "../../components/DialogBox/Index";
import SimpleDialog from "../../components/DialogBox/ActivePatientDialog";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import EditIcon from "@material-ui/icons/Edit";
import { TablePageData } from "Utils/constant";
import CustomTable from "components/CustomTable/CustomTableNew";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import ChipInput from "material-ui-chip-input";

const useStyles = makeStyles(tableStyle);

function createData(
  date,
  providerId,
  ehrId,
  patientName,
  cptCode,
  totalFound,
  initailAmount,
  monthlyAmount,
  startDate,
  endDate,
  reciptDate,
  balance,
  status,
  cptDiscription
) {
  return {
    date,
    providerId,
    ehrId,
    patientName,
    cptCode,
    totalFound,
    initailAmount,
    monthlyAmount,
    startDate,
    endDate,
    reciptDate,
    balance,
    status,
    cptDiscription
  };
}

const ActivePatient = (props) => {
  const classes = useStyles();
  const dataParameter = "PS_ID";
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [totalCount, setTotalCount] = React.useState("");
  const [emptyData, setEmptyDataModel] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState();
  const theme = useTheme();
  const [preData, setPreData] = React.useState(props.tableData.activePatient);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Patient_Name");
  const [pageNo, setPageNo] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [pricingScheduleList, setPricingScheduleList] = React.useState([]);
  const [pageDetails, setPageDetails] = React.useState({ ...TablePageData });
  const [initialCall, setInitialCall] = React.useState(false);
  const [selected, setSelected] = React.useState([]);
  const [chipValue, setChipValue] = React.useState([]);
  const [temp, setTemp] = React.useState(false);
  const [preStatusUpdate, setPreStatusUpdate] = React.useState(
    props.tableData.status
  );
  const [clientId, setClientId] = React.useState();

  const [searchVlaues, setSearchValues] = React.useState({
    ClientId:
      props.appData.sendClientIdApi && props.appData.sendClientIdApi.toString(),
    loginEmail: localStorage.getItem("email"),
    patientName: "",
    balance: "",
    balanceLess: "",
    offerFromDate: "",
    offerToDate: "",
    limit: rowsPerPage,
    sortBy: orderBy,
    orderBy: order,
    pageNo: pageNo
  });

  console.log("sendClientIdApi", props.appData.sendClientIdApi);

  React.useEffect(() => {
    if (props.appData.sendClientIdApi) {
      const data = {
        ...searchVlaues,
        ClientId: props.appData.sendClientIdApi.toString(),
        loginEmail: localStorage.getItem("email"),
        limit: rowsPerPage,
        sortBy: orderBy,
        orderBy: order,
        pageNo: pageNo
      };
      props.activePatient(data);
    }
  }, [pageNo, rowsPerPage, orderBy, order, props.appData.sendClientIdApi]);

  React.useEffect(() => {
    if (props.tableData.activePatient?.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData]);

  const exportListData = () => {
    const filterValue = "";
    // if (chipValue.length) {
    //   for (const property in chipValue) {
    //     if (chipValue[property] !== "") {
    //       filterValue += `${chipValue[property]}`;
    //     }
    //   }
    // }
    const sheetModuleDetails = [
      ["Module:", "Active Patients"],
      ["Export On:", moment.utc(new Date()).format()],
      [
        "Exported By:",
        `${props.userDetails.userDetailsById.Users_Data[0].FName} ${props.userDetails.userDetailsById.Users_Data[0].LName} <${props.userDetails.userDetailsById.Users_Data[0].Email}>`
      ],
      ["Filters:", filterValue]
    ];
    if (!isEmpty(rows)) {
      const mappedLogs = rows.map((row) => ({
        Patient: row.patientName,
        "Offfer Date": row.date,
        Provider: row.providerId,
        "EHR ID": row.ehrId,
        Funded: row.totalFound,
        "Initial Amount": row.initailAmount,
        "Monthly Amount": row.monthlyAmount,
        Balance: row.balance,
        Status: row.status
      }));
      exportToCSV(
        mappedLogs,
        `Active Patients${moment().format("MMDDYYYY")}`,
        sheetModuleDetails
      );
    } else {
      setEmptyDataModel(true);
    }
  };

  let activePatientList = [];

  let rows = [];
  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    const headCells = [
      {
        id: "Patient_Name",
        numeric: false,
        disablePadding: true,
        label: "Patient",
        className: "Patient_Name"
      },
      {
        id: "offer_date",
        numeric: false,
        disablePadding: true,
        label: "Offer Date"
        // headClassName: classes.date,
      },
      {
        id: "Provider_Id",
        numeric: false,
        disablePadding: false,
        label: "Provider"
        // headClassName: classes.providerId,
      },
      {
        id: "EHR_ID",
        numeric: false,
        disablePadding: false,
        label: "EHR ID"
        // headClassName: classes.ehrId,
      },
      {
        id: "Total_Funded",
        numeric: false,
        disablePadding: true,
        label: "Funded"
        // headClassName: classes.Total_Funded,
      },
      {
        id: "Inital_Amt",
        numeric: true,
        disablePadding: false,
        label: "Initial Amount"
        // headClassName: classes.Phone,
      },
      {
        id: "Monthly_Amt",
        numeric: true,
        disablePadding: false,
        label: "Monthly Amount"
        // headClassName: classes.Creation_Date,
      },
      {
        id: "Balance",
        numeric: true,
        disablePadding: false,
        label: "Balance"
        // headClassName: classes.Update_Date,
      },
      {
        id: "Status",
        numeric: false,
        disablePadding: false,
        label: "Status"
        // headClassName: classes.status,
      }
      // {
      //   id: "blank",
      //   numeric: false,
      //   disablePadding: false,
      //   label: "",
      //   headClassName: classes.status,
      // },
    ];

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              className={classes.cellTextHeaderOffer}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {/* {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </span>
                ) : null} */}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired
  };
  const themeFont = createMuiTheme({
    overrides: {
      MuiTableCell: {
        // Name of the component ⚛️ / style sheet
        root: {
          fontFamily: "Source Sans Pro"
        },
        head: {
          display: "none"
        }
      },
      MuiTableSortLabel: {
        // Name of the component ⚛️ / style sheet
        root: {
          color: "white",
          fontFamily: "Source Sans Pro",
          "&:hover": {
            color: "#ffffff"
          },
          "&$active": {
            color: "white"
          }
        },
        active: {},
        icon: {
          color: "inherit !important"
        }
      }
    }
  });
  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      const chipName = chip.split(":")[0];
      const newChipValues = [...chipValue];
      newChipValues.splice(index, 1);
      setChipValue([...newChipValues]);
      const newSearchValue = { ...searchVlaues };
      if (chipName === "Patient Name") {
        newSearchValue.patientName = "";
      }
      if (chipName === "Amount more than Equal ($)") {
        newSearchValue.balance = "";
      }
      if (chipName === "Amount less than Equal ($)") {
        newSearchValue.balanceLess = "";
      }
      if (chipName === "Offer Date From") {
        newSearchValue.offerFromDate = "";
      }
      if (chipName === "Offer Date To") {
        newSearchValue.offerToDate = "";
      }
      setSearchValues({ ...newSearchValue });
      const valuesClientID = { ...newSearchValue };
      // valuesClientID.ClientId = sendApiLink;
      // valuesClientID.LoginEmail = localStorage.getItem("email");
      props.activePatient(valuesClientID);
    }
  };

  const getActivePatientFilterDataHandle = (value) => {
    const finalSearchData = {
      ...value,
      patientName: value.patientName == "" ? "" : value.patientName.trim(),
      balance: value.balance == "" ? "" : value.balance.trim(),
      balanceLess: value.balanceLess == "" ? "" : value.balanceLess.trim(),
      orderBy: order,
      sortBy: orderBy,
      limit: rowsPerPage,
      pageNo: pageNo,
      ClientId:
        props.appData.sendClientIdApi &&
        props.appData.sendClientIdApi.toString(),
      loginEmail: localStorage.getItem("email")
    };
    setSearchValues(finalSearchData);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (property === "patientName") {
          chipArray.push(
            `${"Patient Name"}:
                    ${value[property]}`
          );
        } else if (property === "balance") {
          chipArray.push(
            `${"Amount more than Equal ($)"}:
              ${value[property]}`
          );
        } else if (property === "balanceLess") {
          chipArray.push(
            `${"Amount less than Equal ($)"}:
                    ${value[property]}`
          );
        } else if (property === "offerFromDate") {
          chipArray.push(
            `${"Offer Date From"}:
                ${value[property]}`
          );
        } else if (property === "offerToDate") {
          chipArray.push(
            `${"Offer Date To"}:
                  ${value[property]}`
          );
        }
      }
    }
    props.activePatient(finalSearchData);
    setChipValue(chipArray);
  };

  function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      setPageNo(1);
      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      setPageNo(pageNo - 1);
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      setPageNo(pageNo + 1);
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      setPageNo(Math.max(0, Math.ceil(count / rowsPerPage)));
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.rootPagination}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Last</Typography>
          ) : (
            <Typography>First</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Previous</Typography>
          ) : (
            <Typography>Previous</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleFirstPageButtonClick}
          aria-label="first page"
          className={classes.bottonPage}
        >
          <Typography>{page + 1}</Typography>
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <Typography>Next</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? (
            <FirstPageIcon />
          ) : (
            <Typography>Last</Typography>
          )}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  if (props.tableData.activePatient) {
    if (
      props.tableData.activePatient &&
      props.tableData.activePatient.Active_Patients_Data
    ) {
      activePatientList = props.tableData.activePatient.Active_Patients_Data;
      activePatientList.forEach((v, i) => {
        const date = moment(v.offer_date).format("MM/DD/YYYY");
        const providerId = v.Provider_Id;
        const ehrId = v.EHR_ID;
        const patientName = v.Patient_Name;
        const cptCode = v.CPT_Code;
        const totalFound = v.Total_Funded;
        const initalAmount = v.Inital_Amt;
        const monthlyAmount = v.Monthly_Amt;
        const startDate = v.Start_Date;
        const endDate = v.End_Date;
        const status = v.Status;
        const balance = v.Balance;
        const reciptDate = v.Receipts_till_date;
        const cptDiscription = v.CPT_Description;
        rows.push(
          createData(
            date,
            providerId,
            ehrId,
            patientName,
            cptCode,
            totalFound,
            initalAmount,
            monthlyAmount,
            startDate,
            endDate,
            reciptDate,
            balance,
            status,
            cptDiscription
          )
        );
      });
    }
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // const requestData = { ...searchVlaues };
    // requestData.page = newPage + 1;
    // requestData.ClientId = props.appData.sendClientIdApi.toString();
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const requestData = { ...searchVlaues };
    requestData.sortBy = property;
    requestData.orderBy = isAsc ? "desc" : "asc";
    requestData.ClientId = props.appData.sendClientIdApi.toString();

    setSearchValues(requestData);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    const requestData = { ...searchVlaues };
    requestData.limit = parseInt(event.target.value, 10);
    requestData.pageNo = 1;
    requestData.ClientId = props.appData.sendClientIdApi.toString();
    setSearchValues(requestData);
    setPage(0);
  };
  const activePatientData =
    props.tableData.activePatient &&
    props.tableData.activePatient.totalDocs;
  // const activeDataLenght = Array.length();

  return (
    <>
      <Modal open={emptyData} onClose={() => setEmptyDataModel(false)}>
        <div className={classes.modalDiv}>
          <Card className={classes.modalPayOut}>
            <div
              className={classes.cardDiv1}
              style={{
                display: "flex",
                overflow: "hidden"
              }}
            >
              <img src={closeImage} width="75px" />
              <div
                style={{
                  display: "flex",
                  overflow: "hidden"
                }}
              >
                <img src={closeImage} width="75px" />
                <div
                  style={{
                    textAlign: "center",
                    color: "gray",
                    fontSize: "14px",
                    whiteSpace: "normal"
                  }}
                >
                  No Data present
                </div>
                <br />
                <Grid container alignItems="center" justifyContent="center">
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    onClick={() => setEmptyDataModel(false)}
                  >
                    ok
                  </Button>
                </Grid>
              </div>
              <br />
              <Grid container alignItems="center" justifyContent="center">
                <Button
                  variant="contained"
                  className={classes.buttonStyle}
                  color="#696969"
                  onClick={() => setEmptyDataModel(false)}
                >
                  ok
                </Button>
              </Grid>
            </div>
          </Card>
        </div>
      </Modal>
      <TableContainer
        className={classes.TableContainer}
        component={Paper}
        elevation={0}
      >
        {windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid>
              <Typography className={classes.upperMiniHeadings}>
                REPORTS
              </Typography>
              <Typography className={classes.uppserBoldHeading}>
                Active Patient
              </Typography>
            </Grid>
            <Grid container className={classes.searchContainer}>
              <Grid item className={classes.margin}>
                <Box style={{ width: "25%", marginLeft: "-10px" }}>
                  {" "}
                  Total Active Patients: {activePatientData}
                </Box>
                <div className={classes.searchWrapper}>
                  <ClickAwayListener
                    mouseEvent="onMouseDown"
                    touchEvent="onTouchStart"
                    onClickAway={() => handleClose()}
                  >
                    <Box
                      // className={classes.newSearchDiv}
                      style={{
                        minWidth: "50%",
                        display: "block",
                        backgroundColor: "#ffffff",
                        borderRadius: "20px",
                        marginRight: "10px"
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          marginTop: "5px",
                          marginBottom: "5px"
                        }}
                      >
                        <Box style={{ width: "100%" }}>
                          <ChipInput
                            disableUnderline
                            value={chipValue.length > 0 ? chipValue : []}
                            onClick={() => handleClickOpen()}
                            style={{ display: "flex", marginLeft: "10px" }}
                            classes={{ chipContainer: classes.chipContainer }}
                            InputLabelProps={{
                              classes: {
                                root: classes.activeInputLableColor,
                                focused: "focused"
                              }
                            }}
                            InputProps={{
                              classes: {
                                root: classes.activeInputColor,
                                input: classes.resize,
                                focused: "focused"
                              }
                            }}
                            placeholder="Search Report"
                            onDelete={(chip, index) =>
                              handleDeleteChip(chip, index)
                            }
                          />
                        </Box>
                        <Box
                          style={{ paddingTop: "10px", paddingRight: "10px" }}
                        >
                          <SearchIcon />
                        </Box>
                      </div>
                      <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        className={classes.overLayBox}
                      >
                        <SimpleDialog
                          close={() => handleClose()}
                          searchValue={searchVlaues}
                          seacrhChipData={(data) =>
                            getActivePatientFilterDataHandle(data)
                          }
                        />
                      </Collapse>
                    </Box>
                  </ClickAwayListener>
                  <Button
                    size="large"
                    type="submit"
                    className={classes.exportButton}
                    onClick={exportListData}
                    variant=""
                    color="#ffffff"
                  >
                    Export Data
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}

        {!windowWidth && (
          <Grid container className={classes.searchContainer}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              Total Active Patients:{" "}
              <div className={classes.searchWrapper}>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => handleClose()}
                >
                  <Box style={{ width: "85%" }}>
                    <ChipInput
                      disableUnderline
                      value={chipValue.length > 0 ? chipValue : []}
                      onClick={() => handleClickOpen()}
                      style={{ display: "flex", marginLeft: "10px" }}
                      classes={{ chipContainer: classes.chipContainer }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      placeholder="Search Report"
                      onDelete={(chip, index) => handleDeleteChip(chip, index)}
                    />
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className={classes.overLayBox}
                    >
                      <SimpleDialog
                        close={() => handleClose()}
                        searchValue={searchVlaues}
                        seacrhChipData={(data) =>
                          getActivePatientFilterDataHandle(data)
                        }
                      />
                    </Collapse>
                  </Box>
                </ClickAwayListener>
                <Button
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  onClick={() => {}}
                >
                  <Search />
                </Button>
                <Grid item className={classes.expirtItemGrid}>
                  <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    variant="contained"
                    className={classes.buttonStyle}
                    onClick={exportListData}
                    color="primary"
                  >
                    Export Data
                  </Button>
                </Grid>
              </div>
            </Grid>
          </Grid>
        )}
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
          style={{ marginBottom: rowsPerPage > 25 ? "55px" : "" }}
        >
          <ThemeProvider theme={themeFont}>
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {!isEmpty(rows) ? (
                rows.map((row, index) => (
                  <TableRow
                    style={{
                      backgroundColor:
                        (index + 1) % 2 === 0 ? "#F6F9FF" : "white"
                    }}
                  >
                    <TableCell className={classes.cellTextPatient}>
                      {row.patientName}
                    </TableCell>
                    <TableCell className={classes.cellTextNew}>
                      {row.date}
                    </TableCell>
                    <TableCell className={classes.cellTextNew}>
                      {row.providerId}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      {row.ehrId}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      ${row.totalFound.toFixed(2)}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      {row.initailAmount}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      ${row.monthlyAmount.toFixed(2)}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      ${row.balance.toFixed(2)}
                    </TableCell>
                    <TableCell className={classes.cellTextNew} align="center">
                      <Button
                        variant="contain"
                        className={classes.statusButton}
                      >
                        {row.status}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell className="no-data" colSpan={12}>
                    <img
                      src={noDataImage}
                      alt=""
                      className={classes.noDataImage}
                    />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </ThemeProvider>
          {rows.length ? (
            <TableFooter className={rowsPerPage > 25 && classes.stickyFooter}>
              <TableRow
              // style={{
              //   display: rowsPerPage > 25 ? "flex" : "",
              //   justifyContent: rowsPerPage > 25 ? "end" : "",
              // }}
              >
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    {
                      label: "All",
                      value:
                        props.tableData.activePatient &&
                        props.tableData.activePatient?.totalDocs
                    }
                  ]}
                  colSpan={12}
                  count={
                    props.tableData.activePatient &&
                    props.tableData.activePatient.totalDocs
                  }
                  rowsPerPage={rowsPerPage}
                  page={page}
                  // SelectProps={{
                  //   inputProps: { "aria-label": "rows per page" },
                  //   MenuProps: { classes: { paper: classes.selectDropdown } },
                  // }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </TableContainer>
    </>
  );
};

ActivePatient.propTypes = {
  activePatient: PropTypes.func,
  tableData: PropTypes.any,
  userDetails: PropTypes.any,
  getActivePatientByFilterData: PropTypes.any
};

const mapStateToProps = (table) => ({
  tableData: table.table,
  userDetails: table.users,
  appData: table.app
});
export default connect(mapStateToProps, {
  activePatient,
  getActivePatientByFilterData
})(ActivePatient);
