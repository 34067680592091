import React from "react";
import {
  Box,
  Button,
  Grid,
  Typography,
  MenuItem,
  Select
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Card from "components/Card/Card";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useTheme } from "@material-ui/core/styles";
import CustomButton from "components/CustomButtons/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import Divider from "@material-ui/core/Divider";
import { useLocation } from "react-router-dom";
import {
  ThemeListFetchCall,
  UpdatePanelTheme,
  FetchPortalThemeCall,
  timeZoneListFetchCall,
  providerPanelInfoCall
} from "../../actions/ProviderPanel.action";
import adminStyle from "./ProviderStyle";

const useStyles = makeStyles(adminStyle);
const ProvidersSettings = (props) => {
  const classes = useStyles();
  const location = useLocation();
  const [counter, setCounter] = React.useState(0);
  const [fileName, setFileName] = React.useState("");
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(true);
  const [themeList, setThemeList] = React.useState([]);
  const [timeZoneList, setTimeZoneList] = React.useState([]);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  // const [currentTheme, setCurrentTheme] = React.useState({});
  const [portalInfo, setPortalInfo] = React.useState({});
  const [initialData, setInitialData] = React.useState({
    themeId: "",
    timeZoneId: "",
    uploadLogo: ""
  });
  const [oldLogoPath, setOldLogoPath] = React.useState(false);

  const setCounterfx = () => {
    setTimeout(() => {
      setCounter(counter + 1);
    }, 2000);
  };

  const onSubmit = (values) => {
    const updatedPayload = {
      themeId: values.themeId,
      timeZoneId: values.timeZoneId,
      uploadLogo: values.uploadLogo
    };
    props.UpdatePanelTheme(
      updatedPayload,
      () => {
        fetchPanelInfo();
        enqueueSnackbar("Portal Configuration Updated Successfully.", {
          variant: "success"
        });
        setCounterfx();
      },
      (err) => {
        enqueueSnackbar("Failed to Update Portal Configuration.", {
          variant: "error"
        });
      }
    );
  };

  // const fetchCurrentTheme = () => {
  //   props.FetchPortalThemeCall((data) => {
  //     setCurrentTheme(data.data[0] || {});
  //   });
  // };

  const fetchPanelInfo = () => {
    props.providerPanelInfoCall(location.state.details.Client_Id, (data) => {
      if (!isEmpty(data.data[0])) {
        setPortalInfo(data.data[0]);
      } else {
        setPortalInfo({ Theme_ID: "", TimeZone_ID: "", Logo_Image: "" });
      }
    });
  };

  React.useEffect(() => {
    if (!isEmpty(portalInfo)) {
      setInitialData({
        themeId: portalInfo.Theme_ID,
        timeZoneId: portalInfo.TimeZone_ID,
        uploadLogo: ""
      });
    }
    let clientLogo = "";
    try {
      clientLogo = portalInfo.Logo_Image;
      if (clientLogo) {
        setOldLogoPath(true);
      }
    } catch {
      setOldLogoPath(false);
    }
  }, [portalInfo]);

  React.useEffect(() => {
    let clientLogo = false;
    clientLogo = portalInfo.Logo_Image;
    if (clientLogo) {
      setOldLogoPath(true);
    } else {
      setOldLogoPath(false);
    }
  }, [initialData]);

  React.useEffect(() => {
    if (isEmpty(themeList)) {
      props.ThemeListFetchCall((data) => {
        setThemeList(data.data);
      });

      // fetchCurrentTheme();
    }
    if (isEmpty(timeZoneList)) {
      props.timeZoneListFetchCall((data) => {
        setTimeZoneList(data.Timezone_Data);
      });

      fetchPanelInfo();
    }
  }, []);

  return (
    <Card>
      <>
        <Box className={classes.box}>
          <Grid
            container
            sm={12}
            xs={12}
            alignItems="center"
            justify="flex-start"
            className={classes.backButton}
          >
            <Grid item>
              <Button
                variant="contained"
                size="small"
                className={classes.clearButton}
                onClick={() => props.history.goBack()}
              >
                <Typography component="p">Back</Typography>
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            sm={12}
            xs={12}
            alignItems="center"
            justify="flex-start"
            className={classes.backButton}
          >
            <Grid item>
              <Typography component="p">
                <b>Provider Name: </b>
                {`${location.state.details.DBA_Name} (${location.state.details.Client_Id})`}
              </Typography>
            </Grid>
          </Grid>
          <Formik
            onSubmit={onSubmit}
            enableReinitialize
            initialValues={initialData}
          >
            {({ setFieldValue, handleSubmit, values, resetForm }) => (
              <Form>
                <>
                  <Grid spacing={1} className={classes.MainContainer} container>
                    <Grid
                      item
                      style={{ paddingTop: "2%" }}
                      container
                      sm={10}
                      xs={10}
                    >
                      <Grid item sm={3} xs={10}>
                        <Typography varient="body1">
                          <b> Panel Theme :</b>
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={10}>
                        <Field
                          as={Select}
                          fullWidth
                          size="small"
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused"
                            }
                          }}
                          label="Select Panel Theme"
                          name="themeId"
                          id="themeId"
                        >
                          {themeList.map((key) => (
                            <MenuItem
                              className={classes.dialogBoxDropDown}
                              value={key._id}
                            >
                              {key.themeName}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      // style={{ paddingTop: "5%" }}
                      container
                      sm={10}
                      xs={10}
                    >
                      <Grid item sm={3} xs={10}>
                        <Typography varient="body1">
                          <b> Application Time Zone :</b>
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={10}>
                        <Field
                          as={Select}
                          fullWidth
                          size="small"
                          InputLabelProps={{
                            classes: {
                              root: classes.activeInputLableColor,
                              focused: "focused"
                            }
                          }}
                          label="Select Panel Theme"
                          name="timeZoneId"
                          id="timeZoneId"
                        >
                          {timeZoneList.map((key) => (
                            <MenuItem
                              className={classes.dialogBoxDropDown}
                              value={key.timeZoneId}
                            >
                              {key.Timezone_Name}
                            </MenuItem>
                          ))}
                        </Field>
                      </Grid>
                    </Grid>

                    {/* <Divider
                    variant="fullWidth"
                    flexItem
                    orientation="vertical"
                  />

                  <Grid item container sm={5} xs={10}>
                    asdasd
                  </Grid> */}
                  </Grid>

                  {/* <Grid
                    spacing={1}
                    className={classes.ActionButtonsMain}
                    item
                    container
                  >
                    <Grid sm={4} xs={11} item>
                      <Grid className={classes.ActionButtons} item container>
                        <Grid md={4} sm={6} xs={12} item>
                          <Button
                            variant="outlined"
                            size="large"
                            style={{ height: "2.1em", marginTop: "5px" }}
                            onClick={() => {
                              resetForm();
                            }}
                          >
                            Reset
                          </Button>
                        </Grid>
                        <Grid md={4} sm={6} xs={12} item>
                          <CustomButton
                            variant="contained"
                            disabled={
                              isEmpty(values.themeId) || !values.timeZoneId
                            }
                            className={classes.buttonStyle}
                            size="medium"
                            onClick={() => handleSubmit()}
                            type="submit"
                            color="secondary"
                          >
                            Update
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid> */}

                  <Grid container>
                    <Grid
                      justifyContent="center"
                      variant="middle"
                      sm={12}
                      xs={12}
                      item
                    >
                      <Divider />
                    </Grid>
                  </Grid>
                  <Grid spacing={1} className={classes.LogoContainer} container>
                    <Grid
                      item
                      style={{
                        paddingTop: "1%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column"
                      }}
                      container
                      sm={10}
                      justifyContent="center"
                      xs={10}
                    >
                      {!isEmpty(portalInfo.Logo_Image) && oldLogoPath ? (
                        <>
                          <Typography varient="body1">Current Logo</Typography>
                          <svg
                            version="1.1"
                            id="L9"
                            style={{
                              width: "100px",
                              height: "100px",
                              display: loading ? "block" : "none"
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            x="0px"
                            y="0px"
                            viewBox="0 0 100 100"
                          >
                            <path
                              fill="#000"
                              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                            >
                              <animateTransform
                                attributeName="transform"
                                attributeType="XML"
                                type="rotate"
                                dur="1s"
                                from="0 50 50"
                                to="360 50 50"
                                repeatCount="indefinite"
                              />
                            </path>
                          </svg>
                          <img
                            className={classes.logo}
                            style={{ display: loading ? "none" : "block" }}
                            key={`${portalInfo.Logo_Image}?${new Date()}`}
                            src={`${portalInfo.Logo_Image}?${new Date()}`}
                            alt="Current Logo"
                            loading="eager"
                            onLoad={() => {
                              setLoading(false);
                            }}
                          />
                        </>
                      ) : (
                        <Typography>No Logo Uploaded</Typography>
                      )}
                    </Grid>
                    <Grid
                      item
                      style={{
                        paddingTop: "1%",
                        display: "flex",
                        flexWrap: "wrap",
                        flexDirection: "column",
                        alignContent: "space-around",
                        justifyContent: "center",
                        alignItems: "center"
                      }}
                      container
                      justifyContent="center"
                      sm={4}
                      xs={10}
                    >
                      <label
                        htmlFor="uploadLogo"
                        style={{
                          position: "relative",
                          marginBottom: "5px",
                          display: "flex"
                        }}
                        className="uploadButton"
                      >
                        <Button
                          disableElevation
                          component="span"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                        >
                          Upload New Logo
                        </Button>
                        <ErrorMessage name="uploadLogo">
                          {(msg) => <span className="error">{msg}</span>}
                        </ErrorMessage>
                        <input
                          onChange={(event) => {
                            const file = event.target.files[0];
                            if (file) {
                              if (file.type !== "image/png") {
                                enqueueSnackbar(
                                  "Please select a valid file, file type must be PNG",
                                  {
                                    variant: "error",
                                    anchorOrigin: {
                                      vertical: "top",
                                      horizontal: "center"
                                    }
                                  }
                                );
                              }
                              if (file.size > 50000) {
                                enqueueSnackbar(
                                  "File size is to large, maximum file size allowed is 500Kb",
                                  {
                                    variant: "error",
                                    anchorOrigin: {
                                      vertical: "top",
                                      horizontal: "center"
                                    }
                                  }
                                );
                              } else if (file.type === "image/png") {
                                const fileReader = new FileReader();
                                fileReader.readAsDataURL(file);
                                fileReader.onload = () => {
                                  setFieldValue(
                                    "uploadLogo",
                                    fileReader.result
                                  );
                                };
                                setFileName(file.name);
                                setFieldValue(
                                  "logoUrl",
                                  URL.createObjectURL(file)
                                );
                              }
                            }
                            event.target.value = null;
                            // const reader = new FileReader();
                            // reader.addEventListener("load", (event) => {
                            //   const result = event.target.result;

                            // });
                            // reader.readAsText(file);
                          }}
                          id="uploadLogo"
                          name="uploadLogo"
                          type="file"
                          accept="image/*"
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            zIndex: -1,
                            width: "100%",
                            display: "flex",
                            padding: "8px"
                          }}
                          className="inputFile"
                        />
                      </label>
                      <Typography component="span" variant="body2">
                        {fileName ? fileName || "" : ""}
                      </Typography>
                    </Grid>
                    {values.logoUrl && (
                      <>
                        {windowWidth && (
                          <Grid justifyContent="center" item>
                            <Divider orientation="vertical" variant="middle" />
                          </Grid>
                        )}

                        <Grid
                          item
                          container
                          justifyContent="center"
                          sm={4}
                          xs={8}
                        >
                          <img
                            className={classes.logo}
                            src={values.logoUrl}
                            alt="uploadImage"
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                  <Grid item container className={classes.ActionButtonsMain}>
                    <Grid style={{ display: "inline-grid" }}>
                      <Typography variant="caption">
                        <FiberManualRecordIcon
                          style={{ color: "#a1a1a1", fontSize: "0.4rem" }}
                        />
                        &nbsp;Logo Type must be PNG.
                      </Typography>

                      <Typography variant="caption">
                        <FiberManualRecordIcon
                          style={{ color: "#a1a1a1", fontSize: "0.4rem" }}
                        />
                        &nbsp;Maximum allowed size is 500Kb and minimum is 1Kb.
                      </Typography>
                      <Typography variant="caption">
                        <FiberManualRecordIcon
                          style={{ color: "#a1a1a1", fontSize: "0.4rem" }}
                        />
                        &nbsp;For a better expericence maintain the logo ratio
                        5:1 ( width : height).
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    spacing={1}
                    className={classes.ActionButtonsMain}
                    item
                    container
                  >
                    <Grid sm={4} xs={11} item>
                      <Grid className={classes.ActionButtons} item container>
                        <Grid md={4} sm={6} xs={12} item>
                          <Button
                            variant="outlined"
                            size="large"
                            style={{ height: "2.1em", marginTop: "5px" }}
                            onClick={() => {
                              resetForm();
                              setFileName("");
                            }}
                          >
                            Reset
                          </Button>
                        </Grid>
                        <Grid md={4} sm={6} xs={12} item>
                          <CustomButton
                            variant="contained"
                            disabled={
                              isEmpty(values.themeId) || !values.timeZoneId
                            }
                            className={classes.buttonStyle}
                            size="medium"
                            onClick={() => handleSubmit()}
                            type="submit"
                            color="secondary"
                          >
                            Update
                          </CustomButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              </Form>
            )}
          </Formik>
        </Box>
      </>
    </Card>
  );
};

ProvidersSettings.propTypes = {
  ThemeListFetchCall: PropTypes.func,
  history: PropTypes.any,
  UpdatePanelTheme: PropTypes.func,
  state: PropTypes.any,
  timeZoneListFetchCall: PropTypes.func,
  providerPanelInfoCall: PropTypes.func
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps, {
  ThemeListFetchCall,
  timeZoneListFetchCall,
  UpdatePanelTheme,
  FetchPortalThemeCall,
  providerPanelInfoCall
})(ProvidersSettings);
