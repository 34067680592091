import axios from "axios";

// export const axiosInstance();

export function getAxiosInstance() {
  // const router = useRouter()
  const token = localStorage.getItem("token");
  const config = process.env.REACT_APP_API_BASE_PATH;

  const instance = axios.create({
    baseURL: config,
    headers: {
      "Content-Type": "application/json",
      authorization: token,
    },
  });
  instance.interceptors.response.use(response => response,
    err => {
      const error = err.response;
      // if error is 401

      if (error.status === 401 && error.config && !error.config.__isRetryRequest) {
        sessionStorage.setItem("sessionExpired", "1");
      }
      return Promise.reject(error);
    });

  return instance;
}

export async function getAxiosAPI(url) {
  try {
    const response = await getAxiosInstance().get(url);
    return ({ error: false, data: response.data });
  } catch (error) {
    const err = error;
    console.log(err.data);
    return ({ error: true, data: err.data });
  }
}

export async function deleteAxiosAPI(url) {
  try {
    const response = await getAxiosInstance().delete(url);
    return ({ error: false, data: response.data });
  } catch (error) {
    const err = error;
    console.log(err.data);
    return ({ error: true, data: err.data });
  }
}
export async function postAxiosAPI(url, data) {
  try {
    const response = await getAxiosInstance().post(url, data);
    return ({ error: false, data: response.data });
  } catch (error) {
    const err = error;
    console.log(err.data);
    return ({ error: true, data: err.data });
  }
}
export async function patchAxiosAPI(url, data) {
  try {
    const response = await getAxiosInstance().patch(url, data);
    return ({ error: false, data: response.data });
  } catch (error) {
    const err = error;
    console.log(err.data);
    return ({ error: true, data: err.data });
  }
}
export async function putAxiosAPI(url, data) {
  try {
    const response = await getAxiosInstance().put(url, data);
    return ({ error: false, data: response.data });
  } catch (error) {
    const err = error;
    console.log(err.data);
    return ({ error: true, data: err.data });
  }
}

export function getErrorMessage(obj) {
  const err = obj;
  let str = "";
  // eslint-disable-next-line no-restricted-syntax
  for (const k in err) {
    // eslint-disable-next-line no-prototype-builtins
    if (err.hasOwnProperty(k)) {
      str += err[k];
    }
  }
  return str;
}

export const SortData = (a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0);

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",

  // These options are needed to round to whole numbers if that's what you want.
  // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
