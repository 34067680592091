/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { getRoleByFilterData } from "../../Redux/Actions/RoleRegistration";

const useStyles = makeStyles(styles);

function SimpleDialog(props) {
  const [values, setValue] = React.useState(props.searchValue);
  const sendApiLink = useSelector((state) => state.app.sendClientIdApi);
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const [filter, setFilter] = React.useState("");

  const handleClose = () => {
    onClose(selectedValue);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValue(allState);
  };

  const onFormSubmit = () => {
    const valuesClientID = { ...values };
    valuesClientID.ClientId = sendApiLink;
    valuesClientID.LoginEmail = localStorage.getItem("email");
    props.getRoleByFilterData(valuesClientID);
    props.seacrhChipData(values);
    props.close();
  };

  const clearButton = () => {
    setValue({
      Name: "",
      Current_Status: ""
    });
    const valuesClientID = {
      Name: "",
      Current_Status: ""
    };
    valuesClientID.ClientId = sendApiLink;
    valuesClientID.LoginEmail = localStorage.getItem("email");
    props.getRoleByFilterData(valuesClientID);
    props.seacrhChipData({
      Name: "",
      Current_Status: ""
    });
    props.close();
  };

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              }
            }}
            autoComplete="off"
            label="Roll Name"
            value={values.Name}
            onChange={(e) => onChange(e.target.value, "Name")}
            name="Name"
            id="Name"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>

      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.halfInputSingle}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Status"
            value={values.Current_Status}
            onChange={(e) => onChange(e.target.value, "Current_Status")}
            name="Current_Status"
            id="Current_Status"
            select
            style={{ fontSize: "82%" }}
          >
            <MenuItem className={classes.dialogBoxDropDown} value="All">
              All
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Active">
              Active
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Inactive">
              Inactive
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs className={classes.clearSubmitButton}>
          <br />
          <Button
            disabled={values.Current_Status === "" && values.Name === ""}
            cancel
            variant="outlined"
            style={{
              height: "2.1em",
              // marginTop: "5px",
              textTransform: "none",
            }}
            size="md"
            onClick={() => clearButton()}
          >
            Clear
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
            disabled={values.Current_Status === "" && values.Name === ""}
            variant=""
            color="#ffffff"
            style={{
              backgroundColor: "#03A430",
              textTransform: "none",
              borderRadius: "5px",
              height: "30px",
              color: "#ffffff",
              //  fontSize: "18px",
              fontWeight: "600",
            }}
            className={classes.buttonStyle}
            size="md"
            onClick={() => onFormSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  close: PropTypes.func,
  getRoleByFilterData: PropTypes.func,
  seacrhChipData: PropTypes.func,
  searchValue: PropTypes.object
};

const mapStateToProps = (app) => ({
  formData: app.roles
});

export default connect(mapStateToProps, {
  getRoleByFilterData
})(SimpleDialog);
