/* eslint-disable import/extensions */
import React from "react";
import Box from "@material-ui/core/Box";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import InputLabel from "@material-ui/core/InputLabel";
// core components
import TextField from "@material-ui/core/TextField";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DateFnsUtils from "@date-io/date-fns";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { useTheme } from "@material-ui/core/styles";
import { Typography, Divider } from "@material-ui/core";
import MenuBookRoundedIcon from "@material-ui/icons/MenuBookRounded";
import DateRangeRoundedIcon from "@material-ui/icons/DateRangeRounded";
import styles from "assets/jss/material-dashboard-react/components/businessForm.js";
import PersonIcon from "@material-ui/icons/Person";
import StayCurrentPortraitIcon from "@material-ui/icons/StayCurrentPortrait";
import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import MoreHorizRoundedIcon from "@material-ui/icons/MoreHorizRounded";
import LocationCityRoundedIcon from "@material-ui/icons/LocationCityRounded";
import NearMeRoundedIcon from "@material-ui/icons/NearMeRounded";
import RoomIcon from "@material-ui/icons/Room";
import Table from "@material-ui/core/Table";
import IconButton from "@material-ui/core/IconButton";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import DeleteIcon from "@material-ui/icons/Delete";
import TableCell from "@material-ui/core/TableCell";
import StarIcon from "@material-ui/icons/Star";
import AddIcon from "@material-ui/icons/Add";
import StrikethroughSIcon from "@material-ui/icons/StrikethroughS";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import Tooltip from "@material-ui/core/Tooltip";
import { locationCity, countryList } from "../../constant/locationConstant";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

const useStyles = makeStyles(styles);

const OwnerInformation = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const openDob = React.useRef(null);
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const [photoIdType, setPhotoIdType] = React.useState("");
  const [photoIdTypeAdd, setPhotoIdTypeAdd] = React.useState("");

  const [stateInput, setStateInput] = React.useState(true);

  const handleSSNFocus = (e) => {
    e.preventDefault();
    e.target.select();
  };

  function formatPhoneNumber(v) {
    let r = v.replace(/\D/g, "");
    if (r.length > 11) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
      return r;
    }
    if (r.length > 6) {
      r = r.replace(/^(\d\d\d)(\d{3})(\d{0,4}).*/, "$1-$2-$3");
    } else if (r.length > 3) {
      r = r.replace(/^(\d\d\d)(\d{0,3})/, "$1-$2");
    } else {
      r = r.replace(/^(\d*)/, "$1");
    }
    return r;
  }

  const form = (
    <div>
      <Grid container className={classes.margin}>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            required
            error={props.validationError.Owner_First_Name}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            label="First Name"
            value={props.data.Owner_First_Name}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_First_Name")
            }
            name="Owner_First_Name"
            id="Owner_First_Name"
            helperText={
              props.validationError.Owner_First_Name &&
              "min 3 max 50 characters Owner First Name is Required."
            }
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <PersonIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.Owner_Last_Name}
            label="Last Name"
            required
            value={props.data.Owner_Last_Name}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Last_Name")
            }
            helperText={
              props.validationError.Owner_Last_Name &&
              "min 3 max 50 characters Owner Last Name is Required."
            }
            name="Owner_Last_Name"
            id="Owner_Last_Name"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <MenuBookRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            inputProps={{
              autoComplete: "off",
              maxLength: 4
            }}
            label="Last 4 SSN"
            required
            error={props.validationError.Owner_Last4_SSN}
            value={props.data.Owner_Last4_SSN}
            onChange={(e) =>
              (/^[0-9]\d*$/g.test(e.target.value) || e.target.value == "") &&
              props.handleOwnerChange(e.target.value, "Owner_Last4_SSN", e)
            }
            helperText={
              props.validationError.Owner_Last4_SSN && "Owner SSN is Required."
            }
            onFocus={(e) => handleSSNFocus(e)}
            name="Owner_Last4_SSN"
            id="Owner_Last4_SSN"
            type="text"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <DateRangeRoundedIcon className={classes.inputBox} fontSize="small" />
          <ThemeProvider theme={themeFont}>
            <FormControl className={classes.root}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  clearable
                  disabled={props.disabled}
                  inputVariant="standard"
                  onChange={(e) => {
                    if (e === null) {
                      props.handleOwnerChange(e, "Owner_DOB");
                    } else if (e > new Date()) {
                      props.handleOwnerChange(e, "Owner_DOB");
                    } else {
                      props.handleOwnerChange(
                        moment(e).format("MM/DD/YYYY"),
                        "Owner_DOB"
                      );
                    }
                  }}
                  value={props.data.Owner_DOB ? props.data.Owner_DOB : null}
                  helperText={
                    props.validationError.Owner_DOB &&
                    "Enter a valid date. Owner's age must be 18+ years."
                  }
                  error={props.validationError.Owner_DOB}
                  label="Date Of Birth"
                  required
                  id="Owner_DOB"
                  name="Owner_DOB"
                  maxDate={moment().subtract(18, "years")._d}
                  maxDateMessage="Birth Date should not be a Future date."
                  format="MM/dd/yyyy"
                  placeholder="MM/DD/YYYY"
                  keyboardIcon={<EventOutlinedIcon fontSize="small" />}
                  KeyboardButtonProps={{
                    size: "small"
                  }}
                  InputProps={{
                    readOnly: true,
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused",
                      readOnly: true
                    },

                    endAdornment: (
                      <Tooltip title="cds_DateHelp" placement="top">
                        <IconButton edge="end" size="small">
                          <HelpOutlineOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    )
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </ThemeProvider>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <StrikethroughSIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            helperText={
              props.validationError.Owner_Ownership_Percentage &&
              "Please Enter Valid Owner Percentage(%) of Ownership is Required."
            }
            label="Ownership Percentage"
            required
            error={props.validationError.Owner_Ownership_Percentage}
            value={props.data.Owner_Ownership_Percentage}
            onChange={(e) =>
              props.handleOwnerChange(
                e.target.value,
                "Owner_Ownership_Percentage"
              )
            }
            name="Owner_Ownership_Percentage"
            id="Owner_Ownership_Percentage"
            type="text"
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <StayCurrentPortraitIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              inputProps: { min: 0, max: 10 },
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Phone"
            required
            error={props.validationError.Owner_Phone}
            value={formatPhoneNumber(props.data.Owner_Phone)}
            helperText={
              props.validationError.Owner_Phone &&
              "Enter a Valid Phone Number with 10 Digits Only"
            }
            onChange={(e) => {
              props.handleOwnerChange(
                formatPhoneNumber(e.target.value),
                "Owner_Phone"
              );
            }}
            name="Owner_Phone"
            id="Owner_Phone"
            type="text"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <HomeRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Street Address (No P.O. Box)"
            required
            value={props.data.Owner_Street_Address}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Street_Address")
            }
            error={props.validationError.Owner_Street_Address}
            helperText={
              props.validationError.Owner_Street_Address &&
              "min 2 max 50 characters Street Address is required."
            }
            name="Owner_Street_Address"
            id="Owner_Street_Address"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <MoreHorizRoundedIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            error={props.validationError.Owner_Suite}
            helperText={
              props.validationError.Owner_Suite &&
              "min 2 max 50 characters Owner Suite is required."
            }
            label="Suite"
            required
            value={props.data.Owner_Suite}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Suite")
            }
            name="Owner_Suite"
            id="Owner_Suite"
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <AddIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Country"
            required
            value={props.data.Owner_Country_Code}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_Country_Code")
            }
            name="Owner_Country_Code"
            id="Owner_Country_Code"
            select
          >
            {props.formData.countryTypeList &&
              props.formData.countryTypeList.Country_Data.map((key) => (
                <MenuItem
                  className={classes.dialogBoxDropDown}
                  value={key.Country_Code}
                >
                  {key.Country_Name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <NearMeRoundedIcon className={classes.inputBox} fontSize="small" />
          {props.data.Owner_Country_Code === "US" ? (
            <TextField
              disabled={props.disabled}
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              label="State"
              required
              value={props.data.Owner_State_Code}
              onChange={(e) =>
                props.handleOwnerChange(e.target.value, "Owner_State_Code")
              }
              error={props.validationError.Owner_State_Code}
              helperText={
                props.validationError.Owner_State_Code && "State is Required."
              }
              name="Owner_State_Code"
              id="Owner_State_Code"
              select={stateInput}
            >
              {stateInput &&
                locationCity.map((key) => (
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value={key.value}
                  >
                    {key.name}
                  </MenuItem>
                ))}
            </TextField>
          ) : (
            <TextField
              disabled={props.disabled}
              className={classes.root}
              InputLabelProps={{
                classes: {
                  root: classes.activeInputLableColor,
                  focused: "focused"
                }
              }}
              InputProps={{
                classes: {
                  root: classes.activeInputColor,
                  input: classes.resize,
                  focused: "focused"
                }
              }}
              label="State"
              required
              value={props.data.Owner_State_Code}
              onChange={(e) =>
                props.handleOwnerChange(e.target.value, "Owner_State_Code")
              }
              name="Owner_State_Code"
              id="Owner_State_Code"
            />
          )}
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <LocationCityRoundedIcon
            className={classes.inputBox}
            fontSize="small"
          />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="City"
            required
            value={props.data.Owner_City}
            onChange={(e) =>
              props.handleOwnerChange(e.target.value, "Owner_City")
            }
            error={props.validationError.Owner_City}
            helperText={
              props.validationError.Owner_City &&
              "min 2 max 50 characters Business City is required."
            }
            name="Owner_City"
            id="Owner_City"
          />
        </Grid>

        <Grid item lg={4} sm={6} xs={12}>
          <StarIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="International Province"
            value={props.data.Owner_International_Province}
            onChange={(e) =>
              props.handleOwnerChange(
                e.target.value,
                "Owner_International_Province"
              )
            }
            name="Owner_International_Province"
            id="Owner_International_Province"
          />
        </Grid>
        <Grid item lg={4} sm={6} xs={12}>
          <RoomIcon className={classes.inputBox} fontSize="small" />
          <TextField
            disabled={props.disabled}
            className={classes.root}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              inputProps: { min: 0 },
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              },
              required: true
            }}
            label="Postal Code"
            required
            error={props.validationError.Owner_Postal_Code}
            value={props.data.Owner_Postal_Code}
            onChange={(e) =>
              /^[0-9]\d*$/g.test(e.target.value) &&
              props.handleOwnerChange(e.target.value, "Owner_Postal_Code")
            }
            helperText={
              props.validationError.Owner_Postal_Code &&
              "Enter a valid postal code of 5 digits.."
            }
            name="Owner_Postal_Code"
            id="Owner_Postal_Code"
            type="text"
          />
        </Grid>
      </Grid>
      <>
        {(props.data.ownerAddressProofDetails.File_Name !== "" ||
          props.data.ownerPhotoIdDetails.File_Name !== "") && (
          <Grid item xs={12}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    style={{ width: "5%" }}
                    align="center"
                  >
                    Document Type
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    style={{ width: "5%" }}
                    align="center"
                  >
                    Name
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    style={{ width: "10%" }}
                    align="center"
                  >
                    File Type
                  </TableCell>
                  <TableCell
                    className={classes.cellTextHeaderOffer}
                    style={{ width: "10%" }}
                    align="center"
                  >
                    Upload Time (UTC)
                  </TableCell>
                  {(props.kycCheckListFlag ||
                    props.ProviderStatus === "New Entry") &&
                    props.ProviderStatus !== "Live" &&
                    (props.editPermissionFlag ||
                      props.createPermissionFlag) && (
                      <TableCell
                        className={classes.cellTextHeaderOffer}
                        style={{ width: "5%" }}
                        align="center"
                      >
                        Action
                      </TableCell>
                    )}
                </TableRow>
              </TableHead>
              <TableBody>
                {props.data.ownerPhotoIdDetails.File_Name !== "" && (
                  <TableRow>
                    <TableCell align="center">identity Proof</TableCell>
                    <TableCell align="center">
                      <a
                        href={props.data.ownerPhotoIdDetails.File_Path}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {props.data.ownerPhotoIdDetails.File_Name}
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      {props.data.ownerPhotoIdDetails.File_Type}
                    </TableCell>
                    <TableCell align="center">
                      {moment(
                        props.data.ownerPhotoIdDetails.Upload_Date_Time
                      ).format("MM/DD/YYYY hh:mm:ss A")}
                    </TableCell>
                    {(props.kycCheckListFlag ||
                      props.ProviderStatus === "New Entry") &&
                      props.ProviderStatus !== "Live" &&
                      (props.editPermissionFlag ||
                        props.createPermissionFlag) && (
                        <TableCell align="center">
                          <IconButton
                            onClick={() =>
                              props.handleDeleteUpload("ownerPhotoId")
                            }
                            color="secondary"
                            disabled={props.disabled}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                  </TableRow>
                )}
                {props.data.ownerAddressProofDetails.File_Name !== "" && (
                  <TableRow>
                    <TableCell align="center">Address Proof</TableCell>
                    <TableCell align="center">
                      <a
                        href={props.data.ownerAddressProofDetails.File_Path}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {props.data.ownerAddressProofDetails.File_Name}
                      </a>
                    </TableCell>
                    <TableCell align="center">
                      {props.data.ownerAddressProofDetails.File_Type}
                    </TableCell>
                    <TableCell align="center">
                      {moment(
                        props.data.ownerAddressProofDetails.Upload_Date_Time
                      ).format("MM/DD/YYYY hh:mm:ss A")}
                    </TableCell>
                    {(props.kycCheckListFlag ||
                      props.ProviderStatus === "New Entry") &&
                      props.ProviderStatus !== "Live" &&
                      (props.editPermissionFlag ||
                        props.createPermissionFlag) && (
                        <TableCell align="center">
                          <IconButton
                            onClick={() =>
                              props.handleDeleteUpload("ownerAddressProof")
                            }
                            color="secondary"
                            disabled={props.disabled}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      )}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Grid>
        )}
        {props.data.ownerAddressProofDetails.File_Name === "" &&
          (props.kycCheckListFlag || props.ProviderStatus === "New Entry") &&
          props.ProviderStatus !== "Live" &&
          (props.editPermissionFlag || props.createPermissionFlag) && (
            <Grid
              container
              style={{ flexDirection: windowWidth ? "row" : "column" }}
              className={classes.uploadDocumentBox}
            >
              <Grid item xs={7}>
                <Typography style={{ marginLeft: "10px" }} variant="subtitle1">
                  P.O.A (Proof of address) * (required) (You must provide a
                  PERSONAL proof of address in the form of a utility bill, cell
                  phone bill, government bill or credit card bill. It must be
                  dated within 3 months of todays date, with your current
                  address, full name and date visible in order to get verified)
                  Maximum file size: 25mb (PDF, JPG, JPEG & PNG accepted)
                </Typography>
              </Grid>
              <Grid
                item
                xs={windowWidth ? 5 : 12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: windowWidth ? "row" : "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%"
                  }}
                >
                  <TextField
                    className={classes.rootDocumentSelect}
                    disabled={props.disabled}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    required
                    helperText={
                      props.validationError.ownerAddressProof === ""
                        ? "Select Document Type"
                        : ""
                    }
                    label="Document Type"
                    value={props.data.ownerAddressProof}
                    error={props.validationError.ownerAddressProof}
                    onChange={(e) => {
                      setPhotoIdTypeAdd(true);
                      props.handleOwnerChange(
                        e.target.value,
                        "ownerAddressProof"
                      );
                    }}
                    name="ownerAddressProof"
                    id="ownerAddressProof"
                    select
                    SelectProps={{ MenuProps: { disableScrollLock: true } }}
                  >
                    <MenuItem className={classes.dialogBoxDropDown} value="ub">
                      Utility bill
                    </MenuItem>
                    <MenuItem className={classes.dialogBoxDropDown} value="cpb">
                      Cell phone bill
                    </MenuItem>
                    <MenuItem className={classes.dialogBoxDropDown} value="gb">
                      Government bill
                    </MenuItem>
                    <MenuItem className={classes.dialogBoxDropDown} value="ccd">
                      Credit card bill
                    </MenuItem>
                  </TextField>
                  <label
                    htmlFor="upload-photoProof"
                    style={{
                      margin: windowWidth ? "0px" : "10px",
                      minWidth: "fit-content"
                    }}
                  >
                    <input
                      style={{ display: "none" }}
                      id="upload-photoProof"
                      name="upload-photoProof"
                      accept="image/*, application/pdf"
                      type={photoIdTypeAdd ? "file" : "button"}
                      display="none"
                      onChange={(e) => {
                        if (props.data.ownerAddressProof) {
                          props.handleUploadOnChange(
                            e.target.files[0],
                            "ownerAddressProof"
                          );
                        }
                      }}
                    />
                    <Button
                      component="span"
                      className={
                        !props.validationError.ownerAddressProof
                          ? classes.buttonStyle
                          : classes.buttonStyleError
                      }
                      variant="contained"
                      disabled={!photoIdTypeAdd || props.disabled}
                      style={{
                        fontSize: "small"
                      }}
                    >
                      Upload Adddress Proof
                    </Button>
                  </label>
                </div>
              </Grid>
            </Grid>
          )}
        {props.data.ownerPhotoIdDetails.File_Name === "" &&
          !props.disabled &&
          (props.kycCheckListFlag || props.ProviderStatus === "New Entry") &&
          props.ProviderStatus !== "Live" &&
          (props.editPermissionFlag || props.createPermissionFlag) && (
            <Grid
              container
              style={{ flexDirection: windowWidth ? "row" : "column" }}
              className={classes.uploadDocumentBox}
            >
              <Grid item xs={7}>
                <Typography variant="subtitle1" style={{ marginLeft: "10px" }}>
                  Photo ID (Drivers License, Passport, Gov. Issued ID) *
                  (required) Maximum file size: 25mb (PDF, JPG, JPEG & PNG
                  accepted)
                </Typography>
              </Grid>
              <Grid
                item
                xs={windowWidth ? 5 : 12}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: windowWidth ? "row" : "column",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    width: "100%"
                  }}
                >
                  <TextField
                    className={classes.rootDocumentSelect}
                    disabled={props.disabled}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    required
                    label="Document Type"
                    value={props.data.ownerPhotoId}
                    // error={!photoIdType && photoIdType !== ""}
                    error={props.validationError.ownerPhotoId}
                    onChange={(e) => {
                      setPhotoIdType(true);
                      props.handleOwnerChange(e.target.value, "ownerPhotoId");
                    }}
                    name="ownerPhotoId"
                    id="ownerPhotoId"
                    select
                    helperText={
                      props.validationError.ownerPhotoId === ""
                        ? "Select Document Type"
                        : ""
                    }
                    SelectProps={{
                      MenuProps: { disableScrollLock: true }
                    }}
                  >
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value="Driver License"
                    >
                      Driver License
                    </MenuItem>
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value="Govt. issued ID"
                    >
                      Govt. Issued ID
                    </MenuItem>
                    <MenuItem
                      className={classes.dialogBoxDropDown}
                      value="Passport"
                    >
                      Passport
                    </MenuItem>
                  </TextField>
                  <label
                    htmlFor="upload-photoId"
                    style={{
                      margin: windowWidth ? "0px" : "10px",
                      minWidth: "fit-content"
                    }}
                  >
                    <input
                      style={{ display: "none" }}
                      id="upload-photoId"
                      accept="image/*, application/pdf"
                      name="upload-photoId"
                      required
                      disabled={props.disabled}
                      type={photoIdType ? "file" : "button"}
                      onClick={() => {
                        if (props.data.ownerPhotoIdDetails) {
                          if (!photoIdType) {
                            setPhotoIdType(true);
                          }
                        } else {
                          setPhotoIdType(false);
                        }
                      }}
                      display="none"
                      onChange={(e) => {
                        if (props.data.ownerPhotoId) {
                          props.handleUploadOnChange(
                            e.target.files[0],
                            "ownerPhotoId"
                          );
                        }
                      }}
                    />

                    <Button
                      component="span"
                      variant="contained"
                      className={
                        !props.validationError.ownerPhotoId
                          ? classes.buttonStyle
                          : classes.buttonStyleError
                      }
                      disabled={!photoIdType || props.disabled}
                      style={{
                        fontSize: "small"
                      }}
                    >
                      Upload Photo ID
                    </Button>
                  </label>
                </div>
              </Grid>
            </Grid>
          )}
      </>
      <Divider style={{ marginTop: "20px", marginBottom: "10px" }} />
    </div>
  );

  return (
    <div>
      <Box
        style={{
          display: "flex",
          flexDirection: "row",
          padding: "20px"
        }}
        className={classes.ownerHeader}
      >
        <div style={{ margin: "auto" }}>
          <b>{`Owner ${props.index + 1}`}</b>
        </div>

        <div>
          {(props.kycCheckListFlag || props.ProviderStatus === "New Entry") &&
            props.ProviderStatus !== "Live" &&
            (props.editPermissionFlag || props.createPermissionFlag) && (
              <Tooltip title="Add Owner" aria-label="add">
                <Button
                  className={classes.addIcon}
                  size="sm"
                  aria-label="open drawer"
                  onClick={() => props.add()}
                >
                  <AddIcon />
                </Button>
              </Tooltip>
            )}
          {props.index !== 0 && (
            <Tooltip
              style={{ marginLeft: "10px" }}
              title="Remove"
              aria-label="Remove"
            >
              <Button
                className={classes.deleteIcon}
                aria-label="open drawer"
                onClick={() => props.remove()}
              >
                <DeleteOutlineRoundedIcon />
              </Button>
            </Tooltip>
          )}
        </div>
      </Box>
      <Box>{form}</Box>
    </div>
  );
};

OwnerInformation.propTypes = {
  index: PropTypes.any,
  data: PropTypes.object,
  add: PropTypes.func,
  remove: PropTypes.func,
  handleOwnerChange: PropTypes.func,
  validationError: PropTypes.object,
  formData: PropTypes.object,
  handleUploadOnChange: PropTypes.func,
  handleDeleteUpload: PropTypes.func,
  ProviderStatus: PropTypes.bool,
  kycCheckListFlag: PropTypes.bool,
  createPermissionFlag: PropTypes.bool,
  editPermissionFlag: PropTypes.bool
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration
});
export default connect(mapStateToProps, null)(OwnerInformation);
