export default {
  app: {
    token: "",
    sessionToken: "",
    loggedIn: false,
    mainAppLoading: false,
    loading: false,
    role: false,
    error: false,
    success: false,
    sendClientIdApi: false,
    userDetails: false,
    appBarName: "",
    leftMenu: false,
    layout: false,
    defaultTheme: false,
    appTheme: false,
    portalConfig: false
  },
  menu: {
    data: false
  },
  onBoarding: {
    data: false,
    error: false,
    loading: false
  },
  provideRegistration: {
    formMode: "Create",
    questionData: false,
    kycQuestionData: false,
    questionUpdate: false,
    data: false,
    mcclist: false,
    accountTypeList: false,
    ownerShipTypeList: false,
    statusList: false,
    countryTypeList: false,
    providerList: false,
    search: false,
    providerDetailsById: false,
    statusUpdate: false,
    changeSingleStatus: false,
    providerActivityById: false,
    providerParameterData: false,
    saveProviderParameter: false,
    deleteIncropration: false,
    deleteAddress: false,
    deleteIdProof: false,
    loading: false,
    error: false,
    searchDetails: {
      searchDbaName: "",
      searchStatus: "",
      apiType: "search",
      limit: 5,
      pageNo: 1,
      sortBy: "Updated_date",
      orderBy: "desc"
    }
  },
  table: {
    activePatient: false,
    loading: false,
    error: false,
    initialCall: false,
    dailyDeposite: false,
    cptData: false,
    totalOutStanding: false,
    defaults: false,
    moveToCollection: false,
    newMemberRegister: false,
    accountStatementList: false,
    pendingOffersList: false,
    openFundingList: false,
    badDebts: false
  },
  forms: {
    master: false
  },
  genrateOfferPatient: {
    data: false,
    selectPatient: false,
    success: false,
    emailState: false,
    setEmailOffer: false,
    forgetPassword: false,
    authTokenCheck: false,
    changePassword: false,
    patientId: false,
    updatePatientDetails: false,
    activePlanPatientList: false,
    error: false,
    loading: false,
    deleteDocument: false,
    paymentDetailsData: false,
    paymentCheckoutData: false,
    cptCodeData: false,
    addPatientData: false,
    paymentMakeDetails: false,
    patientGraphData: false,
    printOfferData: false,
    uploadDocument: false,
    genrateOfferData: false,
    sendEmailOffer: false,
    createOffer: false,
    showPatientDetailsViaMail: false,
    updatePatientDetailsOffer: false,
    showPatientInfoViaMail: false,
    patientRegisterData: false,
    patientDocumentList: false,
    offerDownloadFile: false,
    addProposalData: false,
    setUpMonthlyPayment: false,
    completedPlanPatientList: false,
    setUpMonthltFromOffer: false,
    genrateOfferLoading: false,
    defaultValueOffer: false,
    preposalDataIds: false,
    flowType: false,
    sentOTPData: false,
    verifyOTPData: false,
    userIdForgetPassword: false,
    patientTPOScoreList: false,
    generatePatientTPOScore: false,
    cernerPatientSearch: false,
    getNewDues: false,
    patientSearchData: ""
  },
  roles: {
    formMode: "init",
    data: false,
    roleList: false,
    userList: false,
    roleDetailsById: false,
    updateRolePermission: false,
    profileTypeList: false,
    landingpageList: false,
    timeZoneList: false,
    updateRoleStatus: false,
    loading: false,
    roleTab: false,
    error: false,
    searchRolesData: {
      Name: "",
      Current_Status: "",
      limit: 5,
      sortBy: "asc",
      orderBy: "Updated_Date",
      page: 1
    }
  },
  users: {
    formMode: "init",
    data: false,
    userList: false,
    userDetailsById: false,
    userDetailsByIdForm: false,
    profileImage: false,
    roleList: false,
    countryTypeList: false,
    profileTypeList: false,
    statusUpdate: false,
    passwordUpdate: false,
    loading: false,
    userProvider: false,
    timeZoneList: false,
    groupListData: false,
    error: false,
    searchUsersData: {
      Name: "",
      Role: "",
      Current_Status: "",
      limit: 5,
      sortBy: "asc",
      orderBy: "Created_date",
      page: 1
    },
    searchGroupsData: {
      groupName: "",
      groupType: "",
      apiType: "search",
      groupStatus: "",
      limit: 5,
      sortBy: "asc",
      orderBy: "createdDate",
      page: 1
    }
  },
  patientDashboard: {
    data: false,
    success: false,
    openFundingData: false,
    activePlanPatientList: false,
    patientOfferAmountData: false,
    paymentStatusData: false,
    offerAccountStatusData: false,
    originalLoanTermData: false,
    remainingFundingData: false,
    error: false,
    loading: false,
    paymentList: false,
    offetDetailsPatient: false
  },
  dashboard: {
    data: false,
    success: false,
    recentContactData: false,
    error: false,
    loading: false,
    recentAcceptOffer: false,
    expireApplicationData: false,
    activePatientData: false,
    defaultData: false,
    moveToCollectionData: false,
    cptDistributionData: false,
    badDebatesData: false,
    totalOutStandingData: false,
    totalOutStandingCount: false,
    dailyDepositeData: false,
    dailyDepositeCount: false
  },
  payment: {
    data: false
  }
};
