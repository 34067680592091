/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-useless-path-segments */
import * as types from "./ActionTypes";
import { post, get, put } from "../Helper/index";
import * as urls from "../../Uintls/Urls";

export const patientSearch = (data) => (dispatch) => {
  dispatch({ type: types.GENRATE_OFFER_PATIENT_SEARCH_CALL });
  get({
    url: `${urls.GENRATE_OFFER_PATIENT_SEARCH_URL}`,
    success: types.GENRATE_OFFER_PATIENT_SEARCH_SUCCESS,
    failure: types.GENRATE_OFFER_PATIENT_SEARCH_ERROR,
    dispatch,
    body: data
  });
};

export const uploadOfferDocument = (data) => (dispatch) => {
  dispatch({ type: types.UPLOAD_DOCUMNET_FINAL_OFFER_CALL });
  post({
    url: `${urls.UPLOAD_DOCUMNET_FINAL_OFFER}`,
    success: types.UPLOAD_DOCUMNET_FINAL_OFFER_SUCCESS,
    failure: types.UPLOAD_DOCUMNET_FINAL_OFFER_ERROR,
    dispatch,
    body: data,
    auth: true
  });
};

export const sendEmailApiCall = (data) => (dispatch) => {
  dispatch({ type: types.SEND_OFFER_EMAIL_CALL });
  get({
    url: `${urls.SEND_OFFER_EMAIL_ID_URL}`,
    success: types.SEND_OFFER_EMAIL_SUCCESS,
    failure: types.SEND_OFFER_EMAIL_ERROR,
    dispatch,
    body: data
  });
};

export const getPatientDetailsEmail = (data) => (dispatch) => {
  dispatch({ type: types.SHOW_EMAIL_PATIENT_DETAILS_CALL });
  get({
    url: `${urls.SHOW_EMAIL_PATIENT_DETAILS_URL}`,
    success: types.SHOW_EMAIL_PATIENT_DETAILS_SUCCESS,
    failure: types.SHOW_EMAIL_PATIENT_DETAILS_ERROR,
    dispatch,
    body: data
  });
};

export const updatePatientDetailsOffer = (data) => (dispatch) => {
  dispatch({ type: types.UPDATE_PATIENT_DETAILS_OFFER_CALL });
  put({
    url: `${urls.UPDATE_PATIENT_DETAILS_OFFER_URL}`,
    success: types.UPDATE_PATIENT_DETAILS_OFFER_SUCCESS,
    failure: types.UPDATE_PATIENT_DETAILS_OFFER_ERROR,
    dispatch,
    body: data
  });
};

export const getGenrateOfferData = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_GENRATE_OFFER_CALL });
  post({
    url: `${urls.PATIENT_GENRATE_OFFER_URL}`,
    success: types.PATIENT_GENRATE_OFFER_SUCCESS,
    failure: types.PATIENT_GENRATE_OFFER_ERROR,
    dispatch,
    body: data,
    auth: true
  });
};

export const deleteGenrateOfferData = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_GENRATE_OFFER_DELETE });
};

export const getPersonalOfferData = (data) => (dispatch) => {
  dispatch({ type: types.PERSONAL_LOAN_OFFER_CALL });
  get({
    url: `${urls.PERSONAL_LOAN_OFFER_URL}`,
    success: types.PERSONAL_LOAN_OFFER_SUCCESS,
    failure: types.PERSONAL_LOAN_OFFER_ERROR,
    dispatch,
    body: data
  });
};

export const createOfferCall = (data) => (dispatch) => {
  dispatch({ type: types.CREATE_OFFER_PATIENT_CALL });
  post({
    url: `${urls.CREATE_OFFER_PATIENT_URL}`,
    success: types.CREATE_OFFER_PATIENT_SUCCESS,
    failure: types.CREATE_OFFER_PATIENT_ERROR,
    dispatch,
    body: data,
    auth: true
  });
};

export const printOfferCall = (data) => (dispatch) => {
  dispatch({ type: types.PRINT_OFFER_PATIENT_CALL });
  get({
    url: `${urls.PRINT_OFFER_PATIENT_URL}`,
    success: types.PRINT_OFFER_PATIENT_SUCCESS,
    failure: types.PRINT_OFFER_PATIENT_ERROR,
    dispatch,
    body: data
  });
};

export const genrateOfferHyperLink = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_HYPER_LINK_GENRATE_OFFER_CALL });
  post({
    url: `${urls.PATIENT_GENRATE_OFFER_URL}`,
    success: types.PATIENT_HYPER_LINK_GENRATE_OFFER_SUCCESS,
    failure: types.PATIENT_HYPER_LINK_GENRATE_OFFER_ERROR,
    dispatch,
    body: data,
    auth: true
  });
};

export const deleteDocumentCall = (data) => (dispatch) => {
  dispatch({ type: types.DELETE_PATIENT_OFFER_DOCUMENTS_CALL });
  get({
    url: `${urls.DELETE_PATIENT_OFFER_DOCUMENTS_URL}`,
    success: types.DELETE_PATIENT_OFFER_DOCUMENTS_SUCCESS,
    failure: types.DELETE_PATIENT_OFFER_DOCUMENTS_ERROR,
    dispatch,
    body: data
  });
};

export const patientPaymentDetailsCall = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_INFO_BILLING_DETAILS_CALL });
  get({
    url: `${urls.PATIENT_INFO_BILLING_DETAILS_URL}`,
    success: types.PATIENT_INFO_BILLING_DETAILS_SUCCESS,
    failure: types.PATIENT_INFO_BILLING_DETAILS_ERROR,
    dispatch,
    body: data
  });
};

export const patientMakePayment = (data) => (dispatch) => {
  dispatch({ type: types.MAKE_PATIENT_PAYMENT_CALL });
  post({
    url: `${urls.MAKE_PATIENT_PAYMENT_URL}`,
    success: types.MAKE_PATIENT_PAYMENT_SUCCESS,
    failure: types.MAKE_PATIENT_PAYMENT_ERROR,
    dispatch,
    body: data
  });
};

export const getPatientInfoEmail = (data) => (dispatch) => {
  dispatch({ type: types.SHOW_EMAIL_PATIENT_INFO_CALL, data });
  get({
    url: `${urls.SHOW_EMAIL_PATIENT_INFO_URL}`,
    success: types.SHOW_EMAIL_PATIENT_INFO_SUCCESS,
    failure: types.SHOW_EMAIL_PATIENT_INFO_ERROR,
    dispatch,
    body: data
  });
};

export const patientRegister = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_REGISTER_CALL });
  get({
    url: `${urls.PATIENT_REGISTER_URL}`,
    success: types.PATIENT_REGISTER_SUCCESS,
    failure: types.PATIENT_REGISTER_ERROR,
    dispatch,
    body: data
  });
};

export const getPatientOfferData = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_OFFER_GRAPH_CALL });
  get({
    url: `${urls.PATIENT_OFFER_GRAPH_URL}`,
    success: types.PATIENT_OFFER_GRAPH_SUCCESS,
    failure: types.PATIENT_OFFER_GRAPH_ERROR,
    dispatch,
    body: data
  });
};

export const emptyGraphData = () => ({
  type: types.PATIENT_OFFER_GRAPH_DATA_EMPTY
});

export const setEmailRedirect = () => ({
  type: types.PATIENT_OFFER_EMAIL_SET
});

export const setEmailOffer = () => ({
  type: types.OFFER_EMAIL_SET
});

export const confirmForgetPassword = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_FORGET_PASSWORD_CALL });
  get({
    url: `${urls.PATIENT_FORGET_PASSWORD_URL}`,
    success: types.PATIENT_FORGET_PASSWORD_SUCCESS,
    failure: types.PATIENT_FORGET_PASSWORD_ERROR,
    dispatch,
    body: data
  });
};

export const checkResetPasswordAuthCall = (data) => (dispatch) => {
  dispatch({ type: types.CHECK_RESET_PASSWORD_AUTH_CHECK_CALL });
  get({
    url: `${urls.CHECK_RESET_PASSWORD_AUTH_CHECK_URL}`,
    success: types.CHECK_RESET_PASSWORD_AUTH_CHECK_SUCCESS,
    failure: types.CHECK_RESET_PASSWORD_AUTH_CHECK_ERROR,
    dispatch,
    body: data
  });
};

export const changePasswordCall = (data) => (dispatch) => {
  dispatch({ type: types.CHANGE_PATIENT_PASSWORD_CALL });
  get({
    url: `${urls.CHANGE_PATIENT_PASSWORD_URL}`,
    success: types.CHANGE_PATIENT_PASSWORD_SUCCESS,
    failure: types.CHANGE_PATIENT_PASSWORD_ERROR,
    dispatch,
    body: data
  });
};

export const cptCodeDataCall = () => (dispatch) => {
  dispatch({ type: types.SERVICES_CPT_CODE_PATIENT_CALL });
  get({
    url: `${urls.SERVICES_CPT_CODE_PATIENT_URL}`,
    success: types.SERVICES_CPT_CODE_PATIENT_SUCCESS,
    failure: types.SERVICES_CPT_CODE_PATIENT_ERROR,
    dispatch,
    body: ""
  });
};

export const addPatientCall = (data) => (dispatch) => {
  dispatch({ type: types.ADD_PATIENT_DETAILS_OFFER_CALL });
  post({
    url: `${urls.ADD_PATIENT_DETAILS_OFFER_URL}`,
    success: types.ADD_PATIENT_DETAILS_OFFER_SUCCESS,
    failure: types.ADD_PATIENT_DETAILS_OFFER_ERROR,
    dispatch,
    body: data
  });
};

export const editPatientCall = (data) => (dispatch) => {
  dispatch({ type: types.UPDATE_PATIENT_DETAILS_CALL });
  put({
    url: `${urls.UPDATE_PATIENT_DETAILS_URL}`,
    success: types.UPDATE_PATIENT_DETAILS_SUCCESS,
    failure: types.UPDATE_PATIENT_DETAILS_ERROR,
    dispatch,
    body: data
  });
};

export const getAllActivePlanList = (data) => (dispatch) => {
  dispatch({ type: types.ACTIVE_PLAN_PATIENT_LIST_CALL });
  get({
    url: `${urls.ACTIVE_PLAN_PATIENT_LIST_URL}`,
    success: types.ACTIVE_PLAN_PATIENT_LIST_SUCCESS,
    failure: types.ACTIVE_PLAN_PATIENT_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getAllDocumentList = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_DOCUMENT_LIST_CALL });
  get({
    url: `${urls.PATIENT_DOCUMENT_LIST_URL}`,
    success: types.PATIENT_DOCUMENT_LIST_SUCCESS,
    failure: types.PATIENT_DOCUMENT_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const offerDownloadFile = (data) => (dispatch) => {
  dispatch({ type: types.OFFER_DOWNLOAD_FILE_CALL });
  get({
    url: `${urls.OFFER_DOWNLOAD_FILE_URL}`,
    success: types.OFFER_DOWNLOAD_FILE_SUCCESS,
    failure: types.OFFER_DOWNLOAD_FILE_ERROR,
    dispatch,
    body: data
  });
};

export const addProposalCall = (data) => (dispatch) => {
  dispatch({ type: types.ADD_PROPOSAL_DETAILS_CALL });
  post({
    url: `${urls.ADD_PROPOSAL_DETAILS_URL}`,
    success: types.ADD_PROPOSAL_DETAILS_SUCCESS,
    failure: types.ADD_PROPOSAL_DETAILS_ERROR,
    dispatch,
    body: data
  });
};

export const addSetUpMonthlyPayment = (data) => (dispatch) => {
  dispatch({ type: types.SETUP_MONTHLY_PAYMENT_CALL });
  put({
    url: `${urls.SETUP_MONTHLY_PAYMENT_URL}`,
    success: types.SETUP_MONTHLY_PAYMENT_SUCCESS,
    failure: types.SETUP_MONTHLY_PAYMENT_ERROR,
    dispatch,
    body: data
  });
};

export const getAllCompletedPlanList = (data) => (dispatch) => {
  dispatch({ type: types.COMPLETED_PLAN_PATIENT_LIST_CALL });
  get({
    url: `${urls.COMPLETED_PLAN_PATIENT_LIST_URL}`,
    success: types.COMPLETED_PLAN_PATIENT_LIST_SUCCESS,
    failure: types.COMPLETED_PLAN_PATIENT_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const setUpMonthlyOffer = (data) => ({
  type: types.SETUP_MONTHLY_FROM_OFFER,
  data
});

export const setLoadingFalse = (data) => ({
  type: types.GENRATE_OFFER_LOADING_FALSE,
  data
});

export const setDefaultOfferValue = (data) => ({
  type: types.SET_DEFAULT_VALUE_OFFER,
  data
});

export const setSelectPatientOffer = (data) => ({
  type: types.SET_PATIENT_OFFER_SELECT_PATINET,
  data
});

export const setBackToSearchFromDetails = () => ({
  type: types.SET_BACK_TO_SEARCH_FROM_OFFER
});

export const addPreposalidsArray = (data) => ({
  type: types.SET_PREPOSAL_IDS_ARRAY,
  data
});

export const emptyPatientSelect = () => ({
  type: types.EMPTY_OFFER_SELECT_PATIENT
});

export const beforePaymentSendOTP = (data) => (dispatch) => {
  dispatch({ type: types.BEFORE_PAYMENT_SEND_OTP_CALL });
  get({
    url: `${urls.BEFORE_PAYMENT_SEND_OTP_URL}`,
    success: types.BEFORE_PAYMENT_SEND_OTP_SUCCESS,
    failure: types.BEFORE_PAYMENT_SEND_OTP_ERROR,
    dispatch,
    body: data
  });
};

export const verifyPaymentOTP = (data) => (dispatch) => {
  dispatch({ type: types.VERIFY_PAYMENT_OTP_CALL });
  get({
    url: `${urls.VERIFY_PAYMENT_OTP_URL}`,
    success: types.VERIFY_PAYMENT_OTP_SUCCESS,
    failure: types.VERIFY_PAYMENT_OTP_ERROR,
    dispatch,
    body: data
  });
};

export const setUserIdResetPaassword = (data) => ({
  type: types.SET_USER_ID_FORGET_PASSWORD,
  data
});

export const patientTpoScore = (data) => (dispatch) => {
  dispatch({ type: types.PATIENT_TPO_SCORE_CALL });
  get({
    url: `${urls.PATIENT_TPO_SCORE_URL}`,
    success: types.PATIENT_TPO_SCORE_SUCCESS,
    failure: types.PATIENT_TPO_SCORE_ERROR,
    dispatch,
    body: data
  });
};

export const generatePatientTpoScore = (data) => (dispatch) => {
  dispatch({ type: types.GENERATE_PATIENT_TPO_SCORE_CALL });
  get({
    url: `${urls.GENERATE_PATIENT_TPO_SCORE_URL}`,
    success: types.GENERATE_PATIENT_TPO_SCORE_SUCCESS,
    failure: types.GENERATE_PATIENT_TPO_SCORE_ERROR,
    dispatch,
    body: data
  });
};

export const paymentTokenCheckout = (data) => (dispatch) => {
  dispatch({ type: types.PAYMENT_CHECKOUT_CALL });
  post({
    url: `${urls.PAYMENT_CHECKOUT_URL}`,
    success: types.PAYMENT_CHECKOUT_SUCCESS,
    failure: types.PAYMENT_CHECKOUT_ERROR,
    dispatch,
    body: data,
    auth: false,
    payment: true
  });
};

export const cernerPatientSearch = (data) => (dispatch) => {
  dispatch({ type: types.GLOBAL_PATIENT_SEARCH_CALL });
  get({
    url: `${urls.GLOBAL_PATIENT_SEARCH_URL}`,
    success: types.GLOBAL_PATIENT_SEARCH_SUCCESS,
    failure: types.GLOBAL_PATIENT_SEARCH_ERROR,
    dispatch,
    body: data
  });
};

export const getNewDues = (data) => (dispatch) => {
  dispatch({ type: types.GET_PATIENTS_DUES_LIST_CALL });
  get({
    url: `${urls.GET_PATIENTS_DUES_LIST}`,
    success: types.GET_PATIENTS_DUES_LIST_SUCCESS,
    failure: types.GET_PATIENTS_DUES_LIST_ERROR,
    dispatch,
    body: data
  });
};

export const getPatientToken = (data) => (dispatch) => {
  dispatch({ type: types.SHOW_EMAIL_PATIENT_TOKEN_CALL, data });
  get({
    url: `${urls.SHOW_EMAIL_PATIENT_TOKEN_URL}`,
    success: types.SHOW_EMAIL_PATIENT_TOKEN_SUCCESS,
    failure: types.SHOW_EMAIL_PATIENT_TOKEN_ERROR,
    dispatch,
    body: data
  });
};

export const getAllOffersApiCall = (data) => (dispatch) => {
  dispatch({ type: types.SHOW_GENERATE_PATIENT_OFFER_CALL, data });
  get({
    url: `${urls.SHOW_GENERATE_PATIENT_OFFER_URL}`,
    success: types.SHOW_GENERATE_PATIENT_OFFER_SUCCESS,
    failure: types.SHOW_GENERATE_PATIENT_OFFER_ERROR,
    dispatch,
    body: data
  });
};

export const setPatientSearchCommonText = (data) => ({
  type: types.PATIENT_SEARCH_COMMONTEXT,
  data
});