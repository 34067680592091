const styles = (theme) => ({
  makeStylesCard246: {
    width: "50%",
  },
  cardWidth: {
    width: "50%",
  },
  cardDiv: {
    flexDirection: "column",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    margin: "10% 10%",
    width: "50%",
  },
  rootDialog: {
    width: "50%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro",
    },
  },
});

export default styles;
