import * as types from "../Actions/ActionTypes";
import initialState from "./initailState";

const table = (state = initialState.table, action) => {
  switch (action.type) {
    case types.ACTIVE_PATIENT_TABLE_DATA_CALL:
      return { ...state, loading: true };
    case types.ACTIVE_PATIENT_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        activePatient: action.data
      };
    case types.ACTIVE_PATIENT_TABLE_DATA_ERROR:
      return { ...state, error: true, loading: false };

    case types.OPEN_FUNDING_TABLE_DATA_CALL:
      return { ...state, loading: true };
    case types.OPEN_FUNDING_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        openFundingList: action.data
      };
    case types.OPEN_FUNDING_TABLE_DATA_ERROR:
      return { ...state, error: true, loading: false };

    case types.PENDING_OFFERS_TABLE_DATA_CALL:
      return { ...state, loading: true };
    case types.PENDING_OFFERS_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingOffersList: action.data
      };
    case types.PENDING_OFFERS_TABLE_DATA_ERROR:
      return { ...state, error: true, loading: false };

    case types.ACCOUNT_STATEMENT_TABLE_DATA_CALL:
      return { ...state, loading: true };
    case types.ACCOUNT_STATEMENT_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        accountStatementList: action.data
      };
    case types.ACCOUNT_STATEMENT_TABLE_DATA_ERROR:
      return { ...state, error: true, loading: false };

    case types.DAILY_DEPOSIT_TABLE_DATA_CALL:
      return { ...state, loading: true };
    case types.DAILY_DEPOSIT_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        dailyDeposite: action.data
      };
    case types.DAILY_DEPOSIT_TABLE_DATA_ERROR:
      return { ...state, error: true, loading: false };

    case types.CPT_DATA_TABLE_DATA_CALL:
      return { ...state, loading: true };
    case types.CPT_DATA_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        cptData: action.data
      };
    case types.CPT_DATA_TABLE_DATA_ERROR:
      return { ...state, error: true, loading: false };

    case types.TOTAL_OUTSTANDING_TABLE_DATA_CALL:
      return { ...state, loading: true };
    case types.TOTAL_OUTSTANDING_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        totalOutStanding: action.data
      };
    case types.TOTAL_OUTSTANDING_TABLE_DATA_ERROR:
      return { ...state, error: true, loading: false };
    case types.DEFAULTS_TABLE_DATA_CALL:
      return { ...state, loading: true };
    case types.DEFAULTS_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        defaults: action.data
      };
    case types.DEFAULTS_TABLE_DATA_ERROR:
      return { ...state, error: true, loading: false };
    case types.MOVE_TO_COLLECTION_TABLE_DATA_CALL:
      return { ...state, loading: true };
    case types.MOVE_TO_COLLECTION_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        moveToCollection: action.data
      };
    case types.MOVE_TO_COLLECTION_TABLE_DATA_ERROR:
      return { ...state, error: true, loading: false };
    case types.BED_DEBTS_TABLE_DATA_CALL:
      return { ...state, loading: true };
    case types.BED_DEBTS_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        badDebts: action.data
      };
    case types.BED_DEBTS_TABLE_DATA_ERROR:
      return { ...state, error: true, loading: false };
    case types.NEW_MEMBER_TABLE_DATA_CALL:
      return { ...state, loading: true };
    case types.NEW_MEMBER_TABLE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        newMemberRegister: action.data
      };
    case types.NEW_MEMBER_TABLE_DATA_ERROR:
      return { ...state, error: true, loading: false };
    default:
      return state;
  }
};

export default table;
