/* eslint-disable import/extensions */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import StepButton from "@material-ui/core/StepButton";
import PropTypes from "prop-types";
import Card from "components/Card/Card.js";
import FormControl from "@material-ui/core/FormControl";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import { Typography } from "@material-ui/core";
import ShowOffer from "./ShowOffer";
import PaymentInformation from "./PaymentInformation";
import MonthlyPayment from "./MonthlyPayment";
import TncAccept from "./tncAccept";
import TncAccept2 from "./tncAccept2";
import PaymentLink from "./paymentLinkMessage";

const useStyles = makeStyles(styles);
function getSteps() {
  return ["Generate Offer", "Payment Information", "Monthly Payment Information", "Accept & Confirm"];
}
function fullPaySteps() {
  return ["Generate Offer", "Payment Information", "Accept & Confirm"];
}
function linkSteps() {
  return ["Generate Offer", "Thanks!!"];
}

const patientArray = [
  {
    patientName: "Justin Jones",
    patientAddress: "P.O. Box 283 8562",
    mobileNumber: "(372) 587-2335",
    email: "justinJones19@hotmail.com",
    counrty: "USA",
    state: "AL",
    city: "Frederick Nebraska ",
    postalCode: "20620",
    insurancePlan: "Family Floater",
    dateOfProcedure: "20/1/2021",
    totalEstimate: "$18,000",
    searchName: "Justin",
    procedureCode: "M45.9",
    copay: "$5,100.00",
    downPayment: 510.00,
    totalEmi: 8,
    apr: 8.25,
    perEmiAmt: 591.64,
    start_date: "03/15/2021",
    end_date: "10/15/2021",
  },
  {
    patientName: "Talles Micky",
    procedureCode: "A-209",
    patientAddress: "606-3727 Ullamcorper",
    mobileNumber: "(786) 713-8616",
    email: "tallesmicky@yahoo.com",
    counrty: "USA",
    state: "AZ",
    city: "Roseville NH",
    postalCode: "11523",
    insurancePlan: "Family Floater",
    dateOfProcedure: "20/12/2020",
    copay: "$3,500.00",
    totalEstimate: "$15,000",
    searchName: "Talles",
    downPayment: 350.00,
    totalEmi: 15,
    apr: 10.20,
    perEmiAmt: 224.56,
    start_date: "03/27/2021",
    end_date: "05/27/2022",
  },
  {
    patientName: "Nicky Galler",
    patientAddress: "928-3313 Vel Av",
    mobileNumber: "(802) 668-8240",
    email: "nicky@galler.com",
    counrty: "USA",
    state: "CA",
    city: "Aliso Viejo",
    postalCode: "37232",
    insurancePlan: "Family Floater",
    dateOfProcedure: "2/1/2021",
    totalEstimate: "$20,000",
    searchName: "Nicky",
    procedureCode: "H44.139",
    copay: "$5,600.00",
    downPayment: 560.00,
    totalEmi: 8,
    apr: 7.50,
    perEmiAmt: 591.64,
    start_date: "04/03/2021",
    end_date: "11/03/2021",
  }];

const GenrateOffer = props => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [values, setValue] = React.useState({
    patientName: "",
    procedureCode: "",
    patientAddress: "",
    mobileNumber: "",
    email: "",
    counrty: "",
    state: "",
    city: "",
    postalCode: "",
    insurancePlan: "",
    dateOfProcedure: "",
    copay: "",
    totalEstimate: "",
    searchName: "",
  });
  const [showOffers, setShowOffers] = React.useState(false);
  const [showGenrateOffer, setGenrateOffer] = React.useState(false);
  const [completed, setCompleted] = React.useState({});
  const [nopatient, setNoPatient] = React.useState(false);
  const [showLinkMessage, setShowLinkMessage] = React.useState(false);
  const [showTnc, setShowshowTnc] = React.useState(false);
  const [paymentFull, setPaymentFull] = React.useState(false);

  const [stateInput, setStateInput] = React.useState(true);
  const onChange = (value, type) => {
    const allState = { ...values };
    if (type === "counrty") {
      if (value === "USA") {
        setStateInput(true);
        allState.state = "";
      } else {
        setStateInput(false);
        allState.state = "";
      }
    }
    allState[type] = value;
    setValue(allState);
  };
  const onChangeSearch = e => {
    const value = { ...values };
    value.searchName = e;
    setValue(value);
  };
  let steps = showLinkMessage ? linkSteps() : getSteps();

  if (showTnc) {
    steps = fullPaySteps();
  }

  const totalSteps = () => steps.length;

  const completedSteps = () => Object.keys(completed).length;

  const isLastStep = () => activeStep === totalSteps() - 1;

  const allStepsCompleted = () => completedSteps() === totalSteps();
  const handleStep = step => () => {
    setActiveStep(step);
    setShowLinkMessage(false);
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() && !allStepsCompleted()
      ? // It's the last step, but not all steps have been completed,
      // find the first step that has been completed
      steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;
    setActiveStep(newActiveStep);
    setShowOffers(false);
  };
  if (values.searchName === "" && showGenrateOffer) {
    const value = { ...values };
    value.patientName = "";
    setValue(value);
    setGenrateOffer(false);
    setShowOffers(false);
    setActiveStep(0);
    setNoPatient(false);
  }

  const showPatientName = () => {
    const hello = patientArray.filter(key => key.patientName.toLowerCase().match(values.searchName.toLowerCase()));
    if (hello.length) {
      setNoPatient(false);
      setGenrateOffer(true);
      setValue(hello[0]);
    }
    if (!hello.length) {
      setNoPatient(true);
    }
  };
  return (<div>
    <div>
      <Stepper className={classes.stepRoot} activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <Card>
        {activeStep !== 3 && <Grid container className={classes.margin}>
          <Grid item xs>
            <Box className={classes.HeaderBox}>
              <Typography className={classes.headerNameText}>{props.preValues.patientName}</Typography>
              <div className={classes.headerNameDetails}>
                {` -  Scheduled for [${props.preValues.procedureCode}] Maligant Neoplasm of unspecified part of left bronchus or lung on`}
              </div>
              <Typography className={classes.headerNameText}>
                {" "}
                {props.preValues.dateOfProcedure}
              </Typography>
            </Box>
          </Grid>
        </Grid>}
        <FormControl className={classes.margin}>
          {activeStep === 0
            && <div>
              <ShowOffer preValues={props.preValues} nextStep={() => handleNext()} />
            </div>}
          {showLinkMessage && <PaymentLink />}
          {activeStep === 1 && !showLinkMessage && <PaymentInformation preValues={props.preValues} nextStep={() => handleNext()} setShowLinkMessage={() => setShowLinkMessage(true)} setShowshowTnc={() => setShowshowTnc(true)} />}
          {activeStep === 2 && !showTnc && <MonthlyPayment preValues={props.preValues} nextStep={() => handleNext()} />}
          {activeStep === 2 && showTnc && <TncAccept2 activeStep={activeStep} {...props} nextStep={() => handleNext()} />}
          {activeStep === 3 && <TncAccept activeStep={activeStep} values={values} {...props} nextStep={() => handleNext()} />}
          <Box />
        </FormControl>
      </Card>
    </div>
  </div>);
};

GenrateOffer.propTypes = {
  preValues: PropTypes.object,
};

export default GenrateOffer;
