import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import LoaderImage from "../../assets/img/Animated_Logo.gif";

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "#d2d2d280",
  },
}));

function CoustomAppLoader(props) {
  const [showBottomHeader, setShowBottomHeader] = React.useState(false);
  const classes = useStyles();

  if (!showBottomHeader && props.loader) {
    setTimeout(() => { setShowBottomHeader(true); }, 1000);
  }

  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <img src={LoaderImage} alt="" style={{ width: "50%" }} color="inherit" />
      <div>&nbsp;</div>
      <div style={{ color: "green" }}>Running TPO algorithm ....</div>
      {!showBottomHeader && <div style={{ color: "green" }}>&nbsp;&nbsp;</div>}
      {showBottomHeader && <div style={{ color: "green" }}>Optimizing your offer... </div>}
    </div>
  );
}

CoustomAppLoader.propTypes = {
  loader: PropTypes.bool,
};

export default CoustomAppLoader;
