import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { ThemeProvider, createTheme } from "@material-ui/core";
import { getUserListProvider } from "../../Redux/Actions/UserRegistration";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  inputSelect: {
    fontSize: "80%",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark
  },
  inputLableSelect: {
    fontSize: "80%",
    fontStyle: "Helvetica, Arial,sans-serif",
    color: theme.palette.primary.dark
  },
  menuItem: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.contrastText,
    padding: "0px 1rem !important"
  },
  menuButtons: {
    padding: "8px !important",
    background: "#fff",
    display: "flex",
    // justifyContent: "space-between",
    justifyContent: "end",
    position: "sticky",
    top: "0",
    zIndex: "1000",
    boxShadow: `0 2px 3px -3px #000`
  },
  listItemText: {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.contrastText
  }
}));

const theme = createTheme({
  overrides: {
    MuiList: {
      padding: {
        width: "100% !important",
        padding: "0px !important"
      }
    },
    MuiListItem: {
      button: {
        "&:hover": {
          background: "white",
          backgroundColor: `white !important`
        }
      },
      root: {
        "&.Mui-selected": {
          background: "white",
          backgroundColor: `white !important`,
          "&:hover": {
            background: "white",
            backgroundColor: `white !important`
          }
        }
      }
    }
  }
});

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

function MultipleSelect(props) {
  const classes = useStyles();
  const APIClientData = JSON.parse(localStorage.getItem("sendApiClientId"));
  const [personName, setPersonName] = React.useState(
    props.values ? props.values : []
  );
  const [preValue, setPreValue] = React.useState(false);
  const [names, setNames] = React.useState(
    !props.data.Options ? props.data : props.data.Options
  );
  if (
    !preValue &&
    !personName.length &&
    (!props.data.Options ? props.data : props.data.Options)
  ) {
    const value = [];
    if (props.data.Options) {
      props.data.Options.forEach((v) => {
        if (v.permission) {
          value.push(v.name);
        }
      });
      setPersonName(value);
      setPreValue(!preValue);
    } else if (props.lable && props.values) {
      setPersonName(props.values);
      setPreValue(!preValue);
    } else if (props.roles) {
      setPersonName(props.values);
    }
  }

  const handleChange = (event) => {
    if (event.target.value.includes("reset")) {
      resetFields();
    } else {
      setPersonName(event.target.value);
      props.onChangeSelect(event.target.value);
    }
  };
  const resetFields = () => {
    props.onChangeSelect(APIClientData);
    setPersonName(APIClientData);
  };

  return (
    <div style={{ width: "100%" }}>
      <ThemeProvider theme={theme}>
        <FormControl
          className={classes.formControl}
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "1rem"
          }}
        >
          <span style={{ fontSize: "14px", minWidth: "fit-content" }}>
            {`${!props.data.Options ? "Providers Accessible" : "Status"}`}
          </span>
          <Select
            id="demo-mutiple-checkbox"
            style={{
              maxWidth: "67%",
              position: "relative"
            }}
            multiple
            value={personName}
            onChange={handleChange}
            input={<Input className={classes.inputSelect} />}
            renderValue={(selected) => selected.join(", ")}
            MenuProps={MenuProps}
          >
            <MenuItem className={classes.menuButtons} value="reset">
              <span
                style={{
                  cursor: "pointer",
                  background: "lightgrey",
                  padding: "6px 12px",
                  borderRadius: "12px"
                }}
              >
                Reset
              </span>
            </MenuItem>
            {props.data.Options &&
              names.map((v) => (
                <MenuItem
                  value={v.name}
                  className={classes.menuItem}
                  style={{ background: "#fff" }}
                >
                  <Checkbox checked={personName.indexOf(v.name) > -1} />
                  <ListItemText
                    className={classes.listItemText}
                    primary={v.name}
                  />
                </MenuItem>
              ))}
            {!props.data.Options &&
              names.map((v) => (
                <MenuItem
                  value={v.Porvider_Id}
                  className={classes.menuItem}
                  style={{ background: "#fff" }}
                >
                  <Checkbox checked={personName.indexOf(v.Porvider_Id) > -1} />
                  <ListItemText
                    className={classes.listItemText}
                    primary={v.ProviderData}
                  />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    </div>
  );
}

MultipleSelect.propTypes = {
  data: PropTypes.any,
  onChangeSelect: PropTypes.func,
  values: PropTypes.array,
  lable: PropTypes.bool,
  roles: PropTypes.bool,
  getUserListProvider: PropTypes.func
};

const mapStateToProps = (app) => ({
  appData: app.app
});

export default connect(mapStateToProps, {
  getUserListProvider
})(MultipleSelect);
