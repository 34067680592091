/* eslint-disable camelcase */
export const daily_deposit_colors_graph = {
  volume: "#6aaac8", // 3e86a8
  line: "#29586e",
  columnHover: "#8ec8d1",
};

export const total_out_colors_graph = {
  volume: "#6aaac8", // 3e86a8
  line: "#29586e",
  columnHover: "#8ec8d1",
};

export const defaults_colors_graph = {
  volume: "#6aaac8", // 3e86a8
  line: "#29586e",
  columnHover: "#8ec8d1",
};

export const collection_colors_graph = {
  volume: "#6aaac8", // 3e86a8
  line: "#29586e",
  columnHover: "#8ec8d1",
};

export const bad_debts_colors_graph = {
  volume: "#6aaac8", // 3e86a8
  line: "#29586e",
  columnHover: "#8ec8d1",
};
