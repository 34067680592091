import {
  defaultFont,
  dangerColor,
  whiteColor,
} from "assets/jss/material-dashboard-react";

const headerLinksStyle = (theme) => ({
  search: {
    "& > div": {
      marginTop: "0",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px 15px !important",
      float: "none !important",
      paddingTop: "1px",
      paddingBottom: "1px",
      padding: "0!important",
      width: "60%",
      marginTop: "40px",
      "& input": {
        color: whiteColor,
      },
    },
  },
  radioBox: {
    display: "flex",
    flexDirection: "row",
    alignItem: "center",
    justifyContent: "center",
  },
  radioBoxUnique: {
    display: "flex",
    height: "auto",
    flexDirection: "row",
    alignItem: "center",
    justifyContent: "center",
  },
  radioBoxRoot: {
    display: "flex",
    flexDirection: "row",
  },
  displayFlex: {
    display: "flex",
  },
  datetnc: {
    color: theme.palette.info.main,
  },
  textColorRadio: {
    color: theme.palette.primary.dark,
    fontFamily: "Source Sans Pro",
    marginTop: ".5rem",
  },
  textColorRadioCard: {
    color: theme.palette.primary.dark,
    fontFamily: "Source Sans Pro",
  },
  thanksColor: {
    color: theme.palette.error.main,
    fontFamily: "Source Sans Pro",
    fontWeight: 900,
    marginLeft: "100px",
  },
  lineBarPayment: {
    width: "62%",
    marginLeft: "220px",
    border: "1px solid #ededed",
  },
  height: {
    height: "100px",
  },
  textDetails: {
    color: theme.palette.primary.dark,
    fontSize: "90%",
    fontFamily: "Source Sans Pro",
  },
  pointText: {
    color: theme.palette.primary.contrastText,
  },
  contentCenter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  pointTextRupe: {
    color: theme.palette.info.main,
    marginTop: ".5rem",
    fontWeight: "bold",
    fontSize: "18px",
  },
  linkBackgroudColor: {
    backgroundColor: theme.palette.primary.contrastText,
    height: "60px",
    width: "80%",
    borderRadius: 10,
    marginBottom: "50px",
  },

  dialogBoxDropDown: {
    width: "auto",
    overflow: "hidden",
    marginRight: "2%",
    marginLeft: "2%",
    fontSize: "14px",
    boxSizing: "border-box",
    fontFamily: "Source Sans Pro",
    fontWeight: "200",
    lineHeight: "1.5",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
  HeaderBox: {
    display: "flex",
    marginLeft: "50px",
  },
  canvasBackgourd: {
    backgroundColor: theme.palette.primary.contrastText,
  },
  headerNameText: {
    color: theme.palette.info.light,
    fontFamily: "Source Sans Pro",
    fontSize: 14,
  },
  headerNameDetails: {
    color: theme.palette.info.main,
    fontFamily: "Source Sans Pro",
    fontSize: 14,
  },
  checkBox: {
    marginBottom: "100px",
    "&$checked": {
      color: theme.palette.primary.main,
    },
  },
  linkText: {
    zIndex: "4",
    ...defaultFont,
    fontSize: "14px",
    margin: "0px",
  },
  buttonLink: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "10px 15px 0",
      width: "-webkit-fill-available",
      "& svg": {
        width: "24px",
        height: "30px",
        marginRight: "15px",
        marginLeft: "-15px",
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        fontSize: "24px",
        lineHeight: "30px",
        width: "24px",
        height: "30px",
        marginRight: "15px",
        marginLeft: "-15px",
      },
      "& > span": {
        justifyContent: "flex-start",
        width: "100%",
      },
    },
  },
  searchButton: {
    [theme.breakpoints.down("sm")]: {
      top: "-50px !important",
      marginRight: "22px",
      float: "right",
    },
  },
  marginTop: {
    marginTop: ".7rem",
  },
  marginInput: {
    zIndex: "4",
    margin: "0",
  },
  searchIcon: {
    width: "17px",
    zIndex: "4",
  },
  notifications: {
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "2px",
      border: `1px solid ${whiteColor}`,
      right: "4px",
      fontSize: "9px",
      background: dangerColor[0],
      color: whiteColor,
      minWidth: "16px",
      height: "16px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "16px",
      verticalAlign: "middle",
      display: "block",
    },
    [theme.breakpoints.down("sm")]: {
      ...defaultFont,
      fontSize: "14px",
      marginRight: "8px",
    },
  },
  manager: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    display: "inline-block",
  },
  searchWrapper: {
    [theme.breakpoints.down("sm")]: {
      width: "-webkit-fill-available",
      margin: "10px 15px 0",
    },
    display: "inline-block",
  },
  tittleColor: {
    backgroundColor: theme.palette.primary.dark,
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    paddingTop: ".7%",
    height: "2rem",
  },
  tittleTextColor: {
    color: theme.palette.primary.contrastText,
    fontSize: "120%",
    fontFamily: "Source Sans Pro",
  },
  searchGrid: {
    display: "flex",
    flexDirection: "row",
  },
  autoFill: {
    width: "100%",
  },
  margin: {
    margin: "1%",
    marginTop: "2%",
  },
  marginSendLink: {
    margin: "1%",
    marginTop: "2%",
    marginLeft: "220px",
    width: "70%",
  },
  textColor: {
    color: theme.palette.primary.dark,
    fontSize: "90%",
    fontFamily: "Source Sans Pro",
  },
  input: {
    width: "80%",
  },
  cardWidth: {
    width: "100%",
  },
  iconBox: {
    backgroundColor: theme.palette.info.contrastText,
  },
  tncBox: {
    height: "40px",
    display: "flex",
    alighItem: "center",
    justifyContent: "center",
    borderColor: "black",
  },
  detailBox: {
    width: "50%",
    height: "70%",
    borderRadius: 10,
    marginLeft: "30%",
    backgroundColor: "#f7c9c6",
    padding: ".9%",
  },
  loanDetailBox: {
    width: "70%",
    height: "70%",
    borderRadius: 10,
    marginLeft: "20%",
    backgroundColor: "#f7c9c6",
    padding: ".9%",
  },
  textName: {
    color: "#fcf2fc",
  },
  loanOffrerBox: {
    width: "30%",
    height: "70%",
    borderRadius: 10,
    marginLeft: "5%",
    backgroundColor: "#f7c9c6",
    padding: ".1%",
  },
  detailsMain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItem: "center",
  },
  detailsMainLoan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItem: "center",
    width: "70%",
    marginLeft: "10%",
  },
  detailRoot: {
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
  },
  buttonBox: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-end",
    alighItem: "center",
    margin: "2rem",
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
  },
  inputBox: {
    marginTop: "4.5%",
    color: "#bfbdbd",
  },
  searchBox: {
    marginTop: "2.5%",
    color: "#bfbdbd",
  },
  gridBox: {
    display: "flex",
    flexDirection: "row",
  },
  activeInputLableColor: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro",
    fontStyle: "Source Sans Pro",
    "&.focused": {
      color: theme.palette.primary.main,
    },
  },
  root: {
    width: "30%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro",
    },
  },
  emailRoot: {
    width: "80%",
  },
  rootSelectInput: {
    width: "80%",
    marginBottom: "10%",
    fontSize: "90%",
    fontFamily: "Source Sans Pro",
  },
  resize: {
    fontSize: "14px",
    fontFamily: "Source Sans Pro",
    color: theme.palette.primary.dark,
  },
  fullInput: {
    width: "93.2%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro",
    },
  },
  halfInput: {
    width: "87%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro",
    },
  },
  halfInputSingle: {
    width: "500px",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro",
    },
  },
  quaterInput: {
    width: "73%",
    "&.focused": {
      color: theme.palette.primary.main,
      fontSize: "90%",
      fontFamily: "Source Sans Pro",
    },
  },
  stepRoot: {
    backgroundColor: "transparent",
  },
  stepBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "1%",
    width: "50%",
    backgroundColor: "black",
  },
  stepNumberBox: {
    backgroundColor: "#a1c5ff",
    width: "2%",
    height: "40%",
  },
  readOnlyInput: {
    backgroundColor: "#f5f5f5",
    fontFamily: "Source Sans Pro",
  },
  payLatterHeading: {
    color: "#a3a3a3",
  },
  payLatterCaption: {
    color: "#a3a3a3",
    marginTop: ".5rem",
    fontSize: 14,
  },
});

export default headerLinksStyle;
