import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ListItemText from "@material-ui/core/ListItemText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function RoleMultipleSelect(props) {
  const classes = useStyles();
  const [personName, setPersonName] = React.useState(props.values);
  const names = props.data;
  const handleChange = (event) => {
    setPersonName(event.target.value);
    props.onChangeSelect(event.target.value);
  };

  React.useEffect(() => {
    if (personName !== props.values) {
      setPersonName(props.values);
    }
  }, [props]);

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel
          color="secondary"
          style={{ fontSize: "90%" }}
          id="demo-mutiple-checkbox-label"
        >
          Associated Users
        </InputLabel>
        <Select
          labelId="demo-mutiple-checkbox-label"
          id="demo-mutiple-checkbox"
          multiple
          value={personName}
          onChange={handleChange}
          input={<Input style={{ fontSize: "90%", width: "87%" }} />}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
        >
          {names.map((v) => (
            <MenuItem value={v.Porvider_Id} style={{ fontSize: "90%" }}>
              <Checkbox
                checked={personName.indexOf(v.Porvider_Id) > -1}
                color="secondary"
              />
              <ListItemText
                style={{ fontSize: "90%" }}
                primary={v.ProviderData}
              />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

RoleMultipleSelect.propTypes = {
  data: PropTypes.any,
  onChangeSelect: PropTypes.func,
  values: PropTypes.array,
};

export default connect(null, null)(RoleMultipleSelect);
