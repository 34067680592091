/* eslint-disable global-require */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardFooter from "components/Card/CardFooter";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import currentBalance from "assets/jss/material-dashboard-react/views/currentBalance";
import { Button } from "@material-ui/core";
import CallIcon from "@material-ui/icons/Call";
import EmailIcon from "@material-ui/icons/Email";

const useStyles = makeStyles(
  currentBalance
  //   theme => ({
  //   table: {
  //     minWidth: 650,
  //   },
  //   more: {
  //     display: "flex",
  //     justifyContent: "flex-end",
  //     alignItems: "center",
  //     padding: "5px",
  //     paddingRight: "10px",
  //   },
  //   cellText: {
  //     fontSize: "70%",
  //     fontStyle: "Source Sans Pro",
  //     color: theme.palette.primary.dark,
  //     padding: "1%",
  //     borderBottom: "0px",
  //     whiteSpace: "nowrap",
  //   },
  //   small: {
  //     width: theme.spacing(3),
  //     height: theme.spacing(3),
  //   },
  //   smallIcon: {
  //     width: "1.23rem",
  //     height: "1.2rem",
  //   },
  //   noWrap: {
  //     whiteSpace: "nowrap",
  //     marginLeft: "10%",
  //     borderBottom: "0px",
  //   },
  //   patientDetails: {
  //     display: "flex",
  //     flexDirection: "row",
  //     justifyContent: "flex-start",
  //     alignItems: "center",
  //   },
  //   patientNameColor: {
  //     fontSize: "70%",
  //     whiteSpace: "nowrap",
  //     fontStyle: "Source Sans Pro",
  //     color: theme.palette.primary.main,
  //     borderBottom: "0px",
  //     padding: "1%",
  //   },
  //   mailLinkColor: {
  //     color: "#9a9a9a",
  //     fontSize: "13px",
  //     fontFamily: "Source Sans Pro",
  //     fontWeight: "bold",
  //     "&:hover": {
  //       color: theme.palette.primary.main,
  //     },
  //   },
  //   margin: {
  //     width: "100%",
  //   },
  //   searchContainer: {
  //     display: "flex",
  //     paddingLeft: "10px",
  //     flexDirection: "row",
  //     padding: "1%",
  //   },
  //   buttonStyle: {
  //     backgroundColor: theme.palette.primary.main,
  //   },
  //   searchWrapper: {
  //     display: "flex",
  //     width: "100%",
  //     justifyContent: "flex-end",
  //   },
  //   root: {
  //     width: "30%",
  //     "&.focused": {
  //       color: theme.palette.primary.main,
  //       fontSize: "90%",
  //       fontStyle: "Source Sans Pro",
  //     },
  //   },
  //   overLayBox: {
  //     position: "absolute",
  //     width: "35%",
  //     marginTop: "4%",
  //     padding: "1%",
  //     zIndex: 5,
  //     cursor: "pointer",
  //     backgroundColor: theme.palette.primary.contrastText,
  //     boxShadow: "1px 1px 2px white, 0 0 25px #d6d0d0, 0 0 5px #737373",
  //     marginRight: "5%",
  //   },
  // })
);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function createData(ehrId, patientName, expire, disclose, parofileImage) {
  return {
    ehrId,
    patientName,
    expire,
    disclose,
    parofileImage,
  };
}

let rows = [];

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Last</Typography>
        ) : (
          <Typography>First</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <Typography>Previous</Typography>
        ) : (
          <Typography>Previous</Typography>
        )}
      </IconButton>
      <IconButton onClick={handleFirstPageButtonClick} aria-label="first page">
        <Typography>{page + 1}</Typography>
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <Typography>Next</Typography>
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? (
          <FirstPageIcon />
        ) : (
          <Typography>Last</Typography>
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function ExpiringApplication(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  if (props.dashboardData.expireApplicationData) {
    rows = [];
    props.dashboardData.expireApplicationData.data.forEach((v, i) => {
      const ehrId = v.offerNumber;
      const patientName = v.patientName;
      const expire = v.Expiry;
      const disclose = v.Disclosed;
      rows.push(createData(ehrId, patientName, expire, disclose));
    });
  }

  return (
    <>
      <Grid
        item
        // className={classes.cardGrid}
      >
        <CardHeader className={classes.duesGrid}>
          <Typography
            className={classes.typographyAmount}
            style={{ textTransform: "uppercase !important" }}
          >
            Expiring
          </Typography>
          <Typography
            noWrap
            style={{ marginTop: "3px", marginBottom: "1px" }}
            className={classes.cardTitle}
          >
            Applications
          </Typography>
        </CardHeader>
        {(rowsPerPage > 0
          ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
          : rows
        ).map((row) => (
          <>
            <CardBody className={classes.AdminDashboardHoverRow}>
              <Grid>
                <Grid className={classes.cardBody}>
                  <Typography className={classes.modalHeaderName}>
                    {row.patientName}
                  </Typography>
                  <Typography className={classes.modalHeaderName}>
                    {row.expire}
                  </Typography>
                </Grid>
                <Grid className={classes.cardBody}>
                  <Typography className={classes.typographyAmmountList}>
                    Offer No: {row.ehrId}
                  </Typography>
                  <Typography className={classes.typographyAmmountList}>
                    Expiry
                  </Typography>
                </Grid>
              </Grid>
            </CardBody>
            <hr className={classes.hrModalGridContainer} />
          </>
        ))}

        <CardFooter
          className={classes.cardFooter}
         
        >
          <a href="/admin/activePatient">
            <Button
              size="large"
              fullWidth
              type="submit"
              variant=""
              color="#27337D"
              style={{
                textTransform: "none",
                borderRadius: "25px",
                border: "1px solid #3E4E9B",
                fontWeight: "600",
                width: "240px",
                marginBottom: "15px",
                marginTop: "15px",
                color: "#27337D",
              }}
            >
              View All Expiring Applications
            </Button>
          </a>
          <div className={classes.stats} />
        </CardFooter>
      </Grid>
      {/* <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.cellText} />
              <TableCell className={classes.cellText} align="center">
                Offer Number
              </TableCell>
              <TableCell className={classes.cellText} align="center">
                Expiry
              </TableCell>
              <TableCell className={classes.cellText} align="center">
                Disclose
              </TableCell>
              <TableCell className={classes.cellText} align="center">
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row) => (
              <TableRow>
                <TableCell className={classes.cellText}>
                  <div className={classes.patientDetails}>
                    <Avatar
                      alt="Remy Sharp"
                      src={require("assets/img/marc.jpg")}
                      className={classes.small}
                    />
                    <div className={classes.noWrap}>{row.patientName}</div>
                  </div>
                </TableCell>
                <TableCell className={classes.patientNameColor} align="center">
                  {row.ehrId}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.expire}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  {row.disclose}
                </TableCell>
                <TableCell className={classes.cellText} align="center">
                  <CallIcon className={classes.smallIcon} />
                  <a
                    className={classes.mailLinkColor}
                    href="mailto:someone@example.com"
                  >
                    <EmailIcon className={classes.smallIcon} />
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <div className={classes.more}>
          <a className={classes.mailLinkColor} href="/admin/activePatient">
            More
            {">>"}
          </a>
        </div>
      </TableContainer> */}
    </>
  );
}

ExpiringApplication.propTypes = {
  dashboardData: PropTypes.object,
};

export default connect(null, null)(ExpiringApplication);
