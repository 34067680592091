const OffersStyle = (theme) => ({
  brandlogo: {
    height: "100px",
    width: "400px",
    // marginTop:"10px",
    marginBottom:"10px",
    backgroundSize: "contain",
    marginLeft: "auto",
    marginRight: "auto",
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 35,
    },
  },
  searchAddButtons: {
    justifyContent: "center",
    marginTop: "3%",
    marginBottom: "3%",
    paddingLeft:"3%",
    paddingRight:"3%",
    paddingBottom:"0%",
    textAlign: 'start',
    textTransform: 'none'
    
  },
  backSearchAddButtons: {
    justifyContent: "center",
    marginTop: "3%",
    marginBottom: "0%",
    paddingBottom:"0%",
    paddingRight:"2%",
    textColor:"#334E90",
    textAlign: 'end',
  },
  patientSearchContainer: {
    justifyContent: "center",
    padding: "0%",
  },
  patientTable: {
    padding: "1%"
  },
  hrStyleOffer: {
    position: "relative",
    marginRight: "2%",
    marginLeft: "2%",
    marginTop:"0px",
    border: "1px solid #efefef",
  },
  serachButton: {
    background: "#70B754",
    borderRadius: "5px",
    textTransform: 'none',
    width: "160px",
    height: "38px",
    marginLeft: "0%",
    "&:hover": {
      backgroundColor: "#70B754",
    }
  },
 
});

export default OffersStyle;
