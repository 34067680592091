/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-syntax */
import React from "react";
import PropTypes from "prop-types";
import { connect, useSelector } from "react-redux";
import ChipInput from "material-ui-chip-input";
import moment from "moment";
import { makeStyles, useTheme, lighten } from "@material-ui/core/styles";
import CardHeader from "@material-ui/core/CardHeader";
import ClearIcon from "@material-ui/icons/Clear";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import TablePagination from "@material-ui/core/TablePagination";
import DeleteIcon from "@material-ui/icons/Delete";
import isEmpty from "lodash/isEmpty";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import CoustomButton from "components/CustomButtons/Button";
import MenuItem from "@material-ui/core/MenuItem";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import { Button } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TextField from "@material-ui/core/TextField";
import Collapse from "@material-ui/core/Collapse";
import Card from "components/Card/Card";
import Search from "@material-ui/icons/Search";
import TableHead from "@material-ui/core/TableHead";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import TableFooter from "@material-ui/core/TableFooter";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import { Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import AddIcon from "@material-ui/icons/Add";
import Modal from "@material-ui/core/Modal";
import closeImage from "assets/img/Close_Image.png";
import noDataImage from "../../assets/img/noDataImage.png";
import SimpleDialog from "../../components/DialogBox/Users";
import LongMenu from "../../components/Menu/User";
import { exportToCSV } from "../../Utils/commonUtils";
import {
  getUserList,
  changeUserFormMode,
  changeSingleStatus,
  changePassword,
  getUserByFilterData,
  getUserDetailsByForm
} from "../../Redux/Actions/UserRegistration";
import tableStyle from "../../assets/jss/material-dashboard-react/components/reportTables";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

const themeFont = createMuiTheme({
  overrides: {
    MuiTableCell: {
      // Name of the component ⚛️ / style sheet
      root: {
        fontFamily: "Source Sans Pro"
      },
      head: {
        display: "none"
      }
    },
    MuiTableSortLabel: {
      // Name of the component ⚛️ / style sheet
      root: {
        color: "white",
        fontFamily: "Source Sans Pro",
        "&:hover": {
          color: "#ffffff"
        },
        "&$active": {
          color: "white"
        }
      },
      active: {},
      icon: {
        color: "inherit !important"
      }
    }
  }
});

const useStyles = makeStyles(tableStyle);

function createData(
  FName,
  MName,
  LName,
  ClientID,
  Email,
  Phone,
  Role,
  ProfileMaster,
  Created_date,
  status,
  UserID,
  editable
) {
  return {
    FName,
    MName,
    LName,
    ClientID,
    Email,
    Phone,
    Role,
    ProfileMaster,
    Created_date,
    status,
    UserID,
    editable
  };
}

const longText = (
  <ul>
    <li>Uppercase Letters</li>
    <li>Lowercase Letters</li>
    <li>Numbers</li>
    <li>Special Character</li>
  </ul>
);

function descendingComparator(a, b, orderBy) {
  if (orderBy === "Created_date") {
    return new Date(b[orderBy]).valueOf() - new Date(a[orderBy]).valueOf();
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "FName",
    className: "FName",
    numeric: false,
    disablePadding: true,
    label: "Name"
  },
  {
    id: "ClientID",
    className: "ClientID",
    numeric: false,
    disablePadding: true,
    label: "Client Id"
  },
  {
    id: "Email",
    className: "Email",
    numeric: false,
    disablePadding: true,
    label: "Email"
  },
  {
    id: "Mobile",
    className: "Phone",
    numeric: true,
    disablePadding: false,
    label: "Phone"
  },
  {
    id: "Role",
    className: "Role",
    numeric: false,
    disablePadding: true,
    label: "Role"
  },
  {
    id: "Created_date",
    className: "Created_date",
    numeric: true,
    disablePadding: false,
    label: "Created On"
  },
  {
    id: "Status",
    className: "status",
    numeric: true,
    disablePadding: false,
    label: "Status"
  },
  {
    id: "actions",
    className: "actions",
    numeric: false,
    disablePadding: true,
    label: ""
  }
];

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  title: {
    flex: "1 1 100%"
  }
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Nutrition
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired
};

function UserDataList(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [initialCall, setInitialCall] = React.useState(false);
  const [orderBy, setOrderBy] = React.useState("Created_date");
  const [pageNo, setPageNo] = React.useState(1);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const theme = useTheme();
  const windowWidth = useMediaQuery(theme.breakpoints.up("md"));
  const windowWidthSm = useMediaQuery(theme.breakpoints.up("sm"));
  const [emptyData, setEmptyDataModel] = React.useState(false);
  const [statusDetails, setStatusPid] = React.useState({
    id: "",
    name: "",
    email: "",
    status: ""
  });
  const [values, setValues] = React.useState({
    status: "",
    id: ""
  });
  const [searchVlaues, setSearchValues] = React.useState({
    Name: "",
    Role: "",
    Current_Status: "",
    limit: rowsPerPage,
    sortBy: orderBy,
    orderBy: order,
    pageNo: pageNo
  });
  const [openModel, setOpenModel] = React.useState(false);
  const [openPasswordModel, setopenPasswordModel] = React.useState(false);
  const [preStatusUpdate, setPreStatusUpdate] = React.useState(
    props.tableData.statusUpdate
  );
  const [prePasswordUpdate, setPrePasswordUpdate] = React.useState(
    props.tableData.passwordUpdate
  );
  const [validationModal, setValidationModal] = React.useState(false);
  const sendApiLink = useSelector((state) => state.app.sendClientIdApi);
  const [chipValue, setChipValue] = React.useState([]);
  const [open, setOpen] = React.useState(false);
  const [rolePermission, setRolePermission] = React.useState(
    props.tableData.roleDetailsById
  );

  const [validationError, setVlidationError] = React.useState({
    Password: false,
    ConfirmPassword: false
  });

  function EnhancedTableHead(props) {
    const {
      classes,
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort
    } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead className={classes.tableHeader}>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              className={classes.cellTextHeaderOffer}
              align="left"
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell />
        </TableRow>
      </TableHead>
    );
  }

  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
  };
  function TablePaginationActions(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { count, page, rowsPerPage, onChangePage } = props;

    const handleFirstPageButtonClick = (event) => {
      setPageNo(1);
      onChangePage(event, 0);
    };

    const handleBackButtonClick = (event) => {
      setPageNo(pageNo - 1);
      onChangePage(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      setPageNo(pageNo + 1);
      onChangePage(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      setPageNo(Math.max(0, Math.ceil(count / rowsPerPage)));
      onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.rootPagination}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Last</Typography>
          ) : (
            <Typography>First</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <Typography>Previous</Typography>
          ) : (
            <Typography>Previous</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleFirstPageButtonClick}
          aria-label="first page"
          className={classes.bottonPage}
        >
          <Typography>{page + 1}</Typography>
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <Typography>Next</Typography>
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? (
            <FirstPageIcon />
          ) : (
            <Typography>Last</Typography>
          )}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onChangePage: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired
  };

  const checkValidation = () => {
    const error = { ...validationError };
    let validate = true;
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,250})"
    );
    if (values.Password.match(strongRegex)) {
      if (values.Password === values.ConfirmPassword) {
        for (const key in values) {
          if (
            values[key] === "" &&
            (key === "Password" || key === "ConfirmPassword")
          ) {
            error[key] = true;
            validate = false;
          } else {
            error[key] = false;
          }
        }
      } else {
        error.ConfirmPassword = true;
        validate = false;
      }
    } else {
      error.Password = true;
      validate = false;
    }

    setVlidationError(error);
    return validate;
  };

  const userregisterurl = () => {
    props.changeUserFormMode("Create");
  };

  React.useEffect(() => {
    if (preStatusUpdate !== props.tableData.statusUpdate) {
      setPreStatusUpdate(props.tableData.statusUpdate);
      setOpenModel(false);
    }

    if (prePasswordUpdate !== props.tableData.passwordUpdate) {
      setPrePasswordUpdate(props.tableData.passwordUpdate);
      setopenPasswordModel(false);
    }
  }, [props]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    const requestData = { ...searchVlaues };
    requestData.sortBy = property;
    requestData.orderBy = isAsc ? "desc" : "asc";
    requestData.ClientId = props.userDetails.sendClientIdApi.toString();
    setSearchValues(requestData);
  };

  const saveStatus = () => {
    props.changeSingleStatus(values);
  };

  const savePassword = () => {
    if (checkValidation()) {
      props.changePassword(values);
    } else {
      setValidationModal(true);
    }
  };

  const exportListData = () => {
    let filterValue = "";
    if (chipValue.length) {
      for (const property in chipValue) {
        if (chipValue[property] !== "") {
          filterValue += `${chipValue[property]}`;
          filterValue += " ";
        }
      }
    }
    const sheetModuleDetails = [
      ["Module:", "User Details"],
      ["Export On:", moment.utc(new Date()).format()],
      [
        "Exported By:",
        `${props.tableData.userDetailsById.Users_Data[0].FName} ${props.tableData.userDetailsById.Users_Data[0].LName} <${props.tableData.userDetailsById.Users_Data[0].Email}>`
      ],
      ["Filters:", filterValue]
    ];
    if (!isEmpty(rows)) {
      const mappedLogs = rows.map((row) => ({
        "First Name": row.FName,
        "Middle Name": row.MName,
        "Last Name": row.LName,
        "Client Id": row.ClientID,
        Email: row.Email,
        Phone: row.Phone,
        Role: `${row.Role} , Profile Type:
        ${row.ProfileMaster} `,
        "Creation Date": row.Created_date,
        Status: row.status
      }));
      exportToCSV(
        mappedLogs,
        `Users${moment().format("MMDDYYYY")}`,
        sheetModuleDetails
      );
    } else {
      setEmptyDataModel(true);
    }
  };

  React.useEffect(() => {
    if (rolePermission !== props.tableData.roleDetailsById) {
      if (props.tableData.roleDetailsById.status) {
        setRolePermission(props.tableData.roleDetailsById);
      }
    }
  }, [props]);

  React.useEffect(() => {
    if (!props.tableData.userList && props.userDetails.sendClientIdApi) {
      let data = {
        pageNo: pageNo,
        limit: rowsPerPage,
        sortBy: orderBy,
        orderBy: order,
        ClientId: props.userDetails.sendClientIdApi.toString()
      };
      props.changeUserFormMode("init");
      props.getUserList(data);
    }
  }, []);

  React.useEffect(() => {
    if (props.userDetails.sendClientIdApi) {
      const data = {
        ...searchVlaues,
        pageNo: pageNo,
        limit: rowsPerPage,
        sortBy: orderBy,
        orderBy: order,
        ClientId: props.userDetails.sendClientIdApi.toString()
      };
      props.getUserList(data);
    }
  }, [pageNo, rowsPerPage, orderBy, order, props.userDetails.sendClientIdApi]);

  const getProviderByFilterData = (value) => {
    const finalSearchData = {
      ...value,
      Name: value.Name == "" ? "" : value.Name.trim(),
      Role: value.Role == "" ? "" : value.Role.trim()
    };
    setSearchValues(finalSearchData);
    const chipArray = [];
    for (const property in value) {
      if (value[property] !== "") {
        if (property === "Name") {
          chipArray.push(
            `${"Name"}:
                    ${value[property]}`
          );
        } else if (property === "Role") {
          chipArray.push(
            `${"Role"}:
              ${value[property]}`
          );
        } else if (property === "Current_Status") {
          chipArray.push(
            `${"Status"}:
                    ${value[property]}`
          );
        }
      }
    }
    const valuesClientID = {
      ...value,
      Name: value.Name == "" ? "" : value.Name.trim(),
      Role: value.Role == "" ? "" : value.Role.trim()
    };
    valuesClientID.ClientId = sendApiLink;
    valuesClientID.LoginEmail = localStorage.getItem("email");
    props.getUserByFilterData(valuesClientID);
    setChipValue(chipArray);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    const error = { ...validationError };
    error[type] = false;
    allState[type] = value;
    allState.id = statusDetails.id;
    setValues(allState);
    setVlidationError(error);
  };

  const handleDeleteChip = (chip, index) => {
    if (chipValue.length) {
      const chipName = chip.split(":")[0];
      const newChipValues = [...chipValue];
      newChipValues.splice(index, 1);
      setChipValue([...newChipValues]);
      const newSearchValue = { ...searchVlaues };
      if (chipName === "Name") {
        newSearchValue.Name = "";
      }
      if (chipName === "Role") {
        newSearchValue.Role = "";
      }
      if (chipName === "Current_Status") {
        newSearchValue.Current_Status = "";
      }
      setSearchValues({ ...newSearchValue });
      const valuesClientID = { ...newSearchValue };
      valuesClientID.ClientId = sendApiLink;
      valuesClientID.LoginEmail = localStorage.getItem("email");
      props.getUserByFilterData(valuesClientID);
    }
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    const requestData = { ...searchVlaues };
    requestData.limit = parseInt(event.target.value, 10);
    requestData.pageNo = 1;
    requestData.ClientId = props.userDetails.sendClientIdApi.toString();
    setSearchValues(requestData);
  };

  const rows = [];

  if (props.tableData.userList) {
    props.tableData.userList.Users_Data.forEach((v, i) => {
      const UserID = v.UserID;
      const FName = v.FName;
      const MName = v.MName;
      const LName = v.LName;
      const ClientID = v.ClientID;
      const Email = v.Email;
      const Phone = v.Mobile;
      const Role = v.Role;
      const ProfileMaster = v.ProfileMaster;
      const Created_date = moment(v.Created_date).format("MM/DD/YYYY");
      const status = v.Status;
      const editable = v.UserEdit;
      rows.push(
        createData(
          FName,
          MName,
          LName,
          ClientID,
          Email,
          Phone,
          Role,
          ProfileMaster,
          Created_date,
          status,
          UserID,
          editable
        )
      );
    });
  }

  React.useEffect(() => {
    if (props.tableData.userList.initialCall) {
      setInitialCall(true);
    }
  }, [props.tableData.userList]);

  const editUserData = (id) => {
    props.changeUserFormMode("Edit");
    props.getUserDetailsByForm(id);
  };

  return (
    <>
      {" "}
      <ThemeProvider theme={themeFont}>
        <Modal open={emptyData} onClose={() => setEmptyDataModel(false)}>
          <div className={classes.modalDiv}>
            <Card className={classes.modalPayOut}>
              <div
                className={classes.cardDiv1}
                style={{
                  display: "flex",
                  overflow: "hidden"
                }}
              >
                <img src={closeImage} width="75px" />

                <div
                  style={{
                    textAlign: "center",
                    color: "gray",
                    fontSize: "14px",
                    whiteSpace: "normal"
                  }}
                >
                  No Data present
                </div>
                <br />
                <Grid container alignItems="center" justifyContent="center">
                  <Button
                    variant="contained"
                    className={classes.buttonStyle}
                    color="#696969"
                    onClick={() => setEmptyDataModel(false)}
                  >
                    ok
                  </Button>
                </Grid>
              </div>
            </Card>
          </div>
        </Modal>
        <Modal
          open={openPasswordModel}
          onClose={() => setopenPasswordModel(false)}
          align="center"
        >
          <div
            style={{
              width: "auto",
              height: "auto",
              maxHeight: "400px",
              maxWidth: "450px"
            }}
          >
            <Card className={classes.modalRoot}>
              <div className={classes.statusModalHeader}>
                <div className={classes.statusHeadingText}>Change Password</div>
                <ClearIcon
                  style={{ color: "white" }}
                  onClick={() => setopenPasswordModel(false)}
                />
              </div>
              <div style={{ padding: "3%" }}>
                <Grid
                  container
                  direction="column"
                  className={classes.margin}
                  style={{
                    flexDirection: "column",

                    alignItems: "flex-start"
                  }}
                >
                  <Grid
                    item
                    className={classes.searchRoot}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Typography variant="h1" style={{ padding: "10px" }}>
                      User:
                    </Typography>
                    {"    "}
                    <Typography
                      component="p"
                      variant="body1"
                      style={{ padding: "10px" }}
                    >
                      {statusDetails.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    className={classes.searchRoot}
                    style={{ display: "flex", flexDirection: "row" }}
                  >
                    <Typography variant="h1" style={{ padding: "10px" }}>
                      Email:
                    </Typography>
                    {"    "}
                    <Typography
                      component="p"
                      variant="body1"
                      style={{ padding: "10px" }}
                    >
                      {statusDetails.email}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container direction="column">
                  <Grid item md={12} lg={12} sm={12} xs={10}>
                    <TextField
                      required
                      error={validationError.Password}
                      className={classes.rootInput}
                      fullWidth
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        endAdornment: (
                          <Tooltip
                            title={longText}
                            classes={{
                              tooltip: classes.customTooltip
                            }}
                          >
                            <ErrorOutlineIcon />
                          </Tooltip>
                        ),
                        required: true
                      }}
                      label="Password"
                      value={values.Password}
                      onChange={(e) => onChange(e.target.value, "Password")}
                      name="Password"
                      id="Password"
                      type="password"
                    />
                  </Grid>
                  <Grid item md={12} lg={12} sm={12} xs={10}>
                    <TextField
                      required
                      error={validationError.ConfirmPassword}
                      className={classes.rootInput}
                      fullWidth
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        },
                        endAdornment: (
                          <Tooltip
                            title={longText}
                            classes={{
                              tooltip: classes.customTooltip
                            }}
                          >
                            <ErrorOutlineIcon />
                          </Tooltip>
                        ),
                        required: true
                      }}
                      // fullWidth
                      label="Confirm Password"
                      value={values.ConfirmPassword}
                      onChange={(e) =>
                        onChange(e.target.value, "ConfirmPassword")
                      }
                      name="ConfirmPassword"
                      id="ConfirmPassword"
                      type="password"
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: "3%" }}>
                  <Grid item xs={8} />
                  <Grid
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    item
                    xs={4}
                  >
                    <Button
                      variant="contained"
                      className={classes.buttonStyle}
                      aria-controls="simple-menu"
                      onClick={() => savePassword()}
                      color="primary"
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Card>
          </div>
        </Modal>
        <Modal
          open={openModel}
          onClose={() => setOpenModel(false)}
          align="center"
        >
          <Card
            className={windowWidthSm ? classes.modalRoot : classes.modalRootXs}
          >
            <div className={classes.statusModalHeader}>
              <div className={classes.statusHeadingText}>
                Change User Status
              </div>
              <ClearIcon
                style={{ color: "white" }}
                onClick={() => setOpenModel(false)}
              />
            </div>
            <Grid
              container
              direction="column"
              className={classes.margin}
              style={{
                flexDirection: "column",
                paddingLeft: "4%",
                alignItems: "flex-start"
              }}
            >
              <Grid
                item
                className={classes.searchRoot}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Typography variant="h1" style={{ padding: "10px" }}>
                  User:
                </Typography>
                {"    "}
                <Typography
                  component="p"
                  variant="body1"
                  style={{ padding: "10px" }}
                >
                  {statusDetails.name}
                </Typography>
              </Grid>
              <Grid
                item
                className={classes.searchRoot}
                style={{ display: "flex", flexDirection: "row" }}
              >
                <Typography variant="h1" style={{ padding: "10px" }}>
                  Email:
                </Typography>
                {"    "}
                <Typography
                  component="p"
                  variant="body1"
                  style={{ padding: "10px" }}
                >
                  {statusDetails.email}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              className={classes.margin}
              style={{ paddingTop: "2%" }}
            >
              <Grid item xs={11}>
                <TextField
                  InputLabelProps={{
                    classes: {
                      root: classes.activeInputLableColor,
                      focused: "focused"
                    }
                  }}
                  InputProps={{
                    classes: {
                      root: classes.activeInputColor,
                      input: classes.resize,
                      focused: "focused"
                    }
                  }}
                  fullWidth
                  label="Status"
                  value={values.status}
                  onChange={(e) => onChange(e.target.value, "status")}
                  name="status"
                  id="status"
                  select
                >
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Active"
                  >
                    Active
                  </MenuItem>
                  <MenuItem
                    className={classes.dialogBoxDropDown}
                    value="Inactive"
                  >
                    Inactive
                  </MenuItem>
                </TextField>
              </Grid>
            </Grid>
            <Grid
              className={classes.marginTop}
              container
              style={{
                paddingTop: "2%"
              }}
            >
              <Grid item xs={8} />
              <Grid item xs={4}>
                <CoustomButton
                  align="center"
                  variant="contained"
                  onClick={() => saveStatus()}
                >
                  Update
                </CoustomButton>
              </Grid>
            </Grid>
          </Card>
        </Modal>
        {windowWidth && (
          <Grid container className={classes.searchContainerSetup}>
            <Grid item className={classes.margin}>
              <div className={classes.searchWrapper}>
                <ClickAwayListener
                  mouseEvent="onMouseDown"
                  touchEvent="onTouchStart"
                  onClickAway={() => handleClose()}
                >
                  <Box style={{ minWidth: "35%" }}>
                    <ChipInput
                      value={chipValue.length > 0 ? chipValue : []}
                      onClick={() => handleClickOpen()}
                      style={{ display: "flex" }}
                      classes={{ chipContainer: classes.chipContainer }}
                      InputLabelProps={{
                        classes: {
                          root: classes.activeInputLableColor,
                          focused: "focused"
                        }
                      }}
                      InputProps={{
                        classes: {
                          root: classes.activeInputColor,
                          input: classes.resize,
                          focused: "focused"
                        }
                      }}
                      label="Search"
                      onDelete={(chip, index) => handleDeleteChip(chip, index)}
                    />
                    <Collapse
                      in={open}
                      timeout="auto"
                      unmountOnExit
                      className={classes.overLayBox}
                    >
                      <SimpleDialog
                        close={() => handleClose()}
                        searchValue={searchVlaues}
                        seacrhChipData={(data) => getProviderByFilterData(data)}
                      />
                    </Collapse>
                  </Box>
                </ClickAwayListener>
                <CoustomButton
                  color="white"
                  aria-label="edit"
                  justIcon
                  round
                  onClick={() => {}}
                >
                  <Search />
                </CoustomButton>
                <Button
                  size="large"
                  type="submit"
                  style={{
                    backgroundColor: "#03A430",
                    textTransform: "none",
                    borderRadius: "25px",
                    height: "48px",
                    width: "151px",
                    color: "#ffffff",
                    marginLeft: "10px",
                    marginRight: "15px",
                    fontSize: "18px",
                    fontWeight: "600"
                  }}
                  onClick={() => exportListData()}
                  variant=""
                  color="#ffffff"
                >
                  Export Data
                </Button>
                <Button
                  size="large"
                  type="submit"
                  style={{
                    backgroundColor: "#03A430",
                    textTransform: "none",
                    borderRadius: "25px",
                    height: "48px",
                    width: "151px",
                    color: "#ffffff",
                    fontSize: "18px",
                    fontWeight: "600"
                  }}
                  onClick={userregisterurl}
                  variant=""
                  color="#ffffff"
                >
                  <AddIcon />
                  New
                </Button>
              </div>
            </Grid>
          </Grid>
        )}
        {!windowWidth && (
          <Grid container className={classes.searchContainerSetup}>
            <Grid item className={classes.margin} style={{ display: "flex" }}>
              <ClickAwayListener
                mouseEvent="onMouseDown"
                touchEvent="onTouchStart"
                onClickAway={() => handleClose()}
              >
                <Box style={{ width: "85%" }}>
                  <ChipInput
                    value={chipValue.length > 0 ? chipValue : []}
                    onClick={() => handleClickOpen()}
                    style={{ display: "flex" }}
                    classes={{ chipContainer: classes.chipContainer }}
                    InputLabelProps={{
                      classes: {
                        root: classes.activeInputLableColor,
                        focused: "focused"
                      }
                    }}
                    InputProps={{
                      classes: {
                        root: classes.activeInputColor,
                        input: classes.resize,
                        focused: "focused"
                      }
                    }}
                    label="Search"
                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                  />
                  <Collapse
                    in={open}
                    timeout="auto"
                    unmountOnExit
                    className={classes.overLayBox}
                  >
                    <SimpleDialog
                      close={() => handleClose()}
                      searchValue={searchVlaues}
                      seacrhChipData={(data) => getProviderByFilterData(data)}
                    />
                  </Collapse>
                </Box>
              </ClickAwayListener>
              <CoustomButton
                color="white"
                aria-label="edit"
                justIcon
                round
                onClick={() => {}}
              >
                <Search />
              </CoustomButton>
            </Grid>
            <Grid item className={classes.expirtItemGrid}>
              <Button
                variant="contained"
                className={classes.buttonStyle}
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={exportListData}
                color="primary"
              >
                Export
              </Button>
              <Button
                variant="contained"
                className={classes.buttonStyle}
                onClick={userregisterurl}
                aria-controls="simple-menu"
                color="primary"
              >
                <AddIcon />
                New
              </Button>
            </Grid>
          </Grid>
        )}
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? "small" : "medium"}
          aria-label="enhanced table"
        >
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {!isEmpty(rows) ? (
              rows.map((row, index) => (
                <TableRow
                  style={{
                    backgroundColor:
                      (index + 1) % 2 === 0 ? "#F6F9FF" : "white",
                    cursor: "pointer"
                  }}
                >
                  <TableCell
                    className={classes.patientNameColor}
                    align="left"
                    onClick={() => editUserData(row.UserID)}
                  >
                    <b>
                      {row.FName} {row.MName} {row.LName}
                    </b>
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => editUserData(row.UserID)}
                  >
                    {row.ClientID}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => editUserData(row.UserID)}
                  >
                    {row.Email}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => editUserData(row.UserID)}
                  >
                    {row.Phone}
                  </TableCell>
                  <TableCell
                    className={classes.patientNameColor}
                    align="left"
                    onClick={() => editUserData(row.UserID)}
                  >
                    {row.Role}
                    <br />
                    Profile Type:
                    {row.ProfileMaster}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => editUserData(row.UserID)}
                  >
                    {row.Created_date}
                  </TableCell>
                  <TableCell
                    className={classes.cellText}
                    align="left"
                    onClick={() => editUserData(row.UserID)}
                  >
                    {row.status}
                  </TableCell>
                  <TableCell>
                    <LongMenu
                      details={row.UserID}
                      {...props}
                      editable={row.editable}
                      openForm={() => setOpenModel(true)}
                      openPasswordModel={() => setopenPasswordModel(true)}
                      setStatusPid={() =>
                        setStatusPid({
                          id: row.UserID,
                          name: `${row.FName} ${row.LName}`,
                          email: row.Email,
                          status: row.status
                        })
                      }
                    />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell className="no-data" colSpan={12}>
                  <img
                    src={noDataImage}
                    alt=""
                    className={classes.noDataImage}
                  />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
          {rows.length ? (
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "All", value: props.tableData.userList?.totalDocs }
                  ]}
                  colSpan={12}
                  count={props.tableData.userList?.totalDocs}
                  rowsPerPage={rowsPerPage}
                  page={page - 1}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          ) : (
            ""
          )}
        </Table>
      </ThemeProvider>
    </>
  );
}

UserDataList.propTypes = {
  getUserList: PropTypes.func,
  tableData: PropTypes.object,
  history: PropTypes.any,
  changeUserFormMode: PropTypes.func,
  changeSingleStatus: PropTypes.func,
  changePassword: PropTypes.func,
  getUserByFilterData: PropTypes.func,
  userDetails: PropTypes.object,
  getUserDetailsByForm: PropTypes.func
};

const mapStateToProps = (app) => ({
  tableData: app.users,
  userDetails: app.app
});
export default connect(mapStateToProps, {
  getUserList,
  changeUserFormMode,
  changeSingleStatus,
  changePassword,
  getUserByFilterData,
  getUserDetailsByForm
})(UserDataList);
