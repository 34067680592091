/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import styles from "assets/jss/material-dashboard-react/components/businessForm.js";
import RoleMenuSub from "../../components/RoleMenuSub/Index";

const PurpleSwitch = withStyles((theme) => ({
  switchBase: {
    color: "gray",
    "&$checked": {
      color: theme.palette.primary.main,
    },
    "&$checked + $track": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  checked: {},
  track: {},
}))(Switch);

const useStyles = makeStyles((theme) => ({
  ...styles,
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  modalDiv: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "stretch",
  },
}));

function EditRolePermission(props) {
  const classes = useStyles();
  const onChange = (value) => {
    props.onChangePermission(value);
  };
  return (
    <>
      <Grid
        container
        style={{
          margin: "1%",
          marginTop: "2%",
          borderBottomWidth: "4px",
          borderBottomColor: "gray",
        }}
      >
        <Grid item xs>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              style={{ backgroundColor: "rgba(0, 0, 0, .03)" }}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.heading}>
                {props.details.Category_Name}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              style={{ display: "flex", flexDirection: "column" }}
            >
              {props.details.Page_Menu.map((v, i) => (
                <>
                  <Grid container spacing={2} style={{ marginBottom: "1%" }}>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        paddingTop: "1%",
                        paddingLeft: "2%",
                      }}
                      xs="4"
                    >
                      <Typography>{v.Page_Name}</Typography>
                    </Grid>
                    <RoleMenuSub
                      data={v}
                      onChangeSelect={(item) => onChange(item)}
                    />
                  </Grid>
                  {props.details.Page_Menu.length !== i - 1 && <Divider />}
                </>
              ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </>
  );
}

EditRolePermission.propTypes = {
  details: PropTypes.object,
  onChangePermission: PropTypes.func,
};

const mapStateToProps = (app) => ({
  formData: app.provideRegistration,
});

export default connect(mapStateToProps, null)(EditRolePermission);
