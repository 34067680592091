// APP actionTypes
export const SET_LOADING = "SET_LOADING";

export const SET_ERROR = "SET_ERROR";

export const SET_TOKEN = "SET_TOKEN";

export const SET_SESSION_TOKEN = "SET_SESSION_TOKEN";

export const SET_SUCCESS = "SET_SUCCESS";

export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER";

// Loggin action types

export const LOGIN_API = "LOGIN_API";

export const SET_LOGIN = "SET_LOGIN";

// Menu Action types

export const GET_MENU_API = "GET_MENU_API";
export const SET_MENU = "SET_MENU";

// Active Patient types

export const ACTIVE_PATIENT_TABLE_DATA_CALL = "ACTIVE_PATIENT_TABLE_DATA_CALL";
export const ACTIVE_PATIENT_TABLE_DATA_SUCCESS =
  "ACTIVE_PATIENT_TABLE_DATA_SUCCESS";
export const ACTIVE_PATIENT_TABLE_DATA_ERROR =
  "ACTIVE_PATIENT_TABLE_DATA_ERROR";

// Open Funding types

export const OPEN_FUNDING_TABLE_DATA_CALL = "OPEN_FUNDING_TABLE_DATA_CALL";
export const OPEN_FUNDING_TABLE_DATA_SUCCESS =
  "OPEN_FUNDING_TABLE_DATA_SUCCESS";
export const OPEN_FUNDING_TABLE_DATA_ERROR = "OPEN_FUNDING_TABLE_DATA_ERROR";

// Pending Offers types

export const PENDING_OFFERS_TABLE_DATA_CALL = "PENDING_OFFERS_TABLE_DATA_CALL";
export const PENDING_OFFERS_TABLE_DATA_SUCCESS =
  "PENDING_OFFERS_TABLE_DATA_SUCCESS";
export const PENDING_OFFERS_TABLE_DATA_ERROR =
  "PENDING_OFFERS_TABLE_DATA_ERROR";

// Provide Registration types

export const PROVIDE_REGISTRATION_CALL = "PROVIDE_REGISTRATION_CALL";
export const PROVIDE_REGISTRATION_SUCCESS = "PROVIDE_REGISTRATION_SUCCESS";
export const PROVIDE_REGISTRATION_ERROR = "PROVIDE_REGISTRATION_ERROR";

// Provide Update types

export const PROVIDE_UPDATION_CALL = "PROVIDE_UPDATION_CALL";
export const PROVIDE_UPDATION_SUCCESS = "PROVIDE_UPDATION_SUCCESS";
export const PROVIDE_UPDATION_ERROR = "PROVIDE_UPDATION_ERROR";

// MCC List types

export const MCC_LIST_CALL = "MCC_LIST_CALL";
export const MCC_LIST_SUCCESS = "MCC_LIST_SUCCESS";
export const MCC_LIST_ERROR = "MCC_LIST_ERROR";

// Country type List types

export const COUNTEY_TYPE_LIST_CALL = "COUNTEY_TYPE_LIST_CALL";
export const COUNTEY_TYPE_LIST_SUCCESS = "COUNTEY_TYPE_LIST_SUCCESS";
export const COUNTEY_TYPE_LIST_ERROR = "COUNTEY_TYPE_LIST_ERROR";

// Account Type list types

export const ACCOUNT_TYPE_LIST_CALL = "ACCOUNT_TYPE_LIST_CALL";
export const ACCOUNT_TYPE_LIST_SUCCESS = "ACCOUNT_TYPE_LIST_SUCCESS";
export const ACCOUNT_TYPE_LIST_ERROR = "ACCOUNT_TYPE_LIST_ERROR";

// Owner Ship Type list types

export const OWNER_TYPE_LIST_CALL = "OWNER_TYPE_LIST_CALL";
export const OWNER_TYPE_LIST_SUCCESS = "OWNER_TYPE_LIST_SUCCESS";
export const OWNER_TYPE_LIST_ERROR = "OWNER_TYPE_LIST_ERROR";

// Provider List list types

export const PROVIDER_LIST_CALL = "PROVIDER_LIST_CALL";
export const PROVIDER_LIST_SUCCESS = "PROVIDER_LIST_SUCCESS";
export const PROVIDER_LIST_ERROR = "PROVIDER_LIST_ERROR";

// Provider List by id types

export const PROVIDER_LIST_BY_ID_CALL = "PROVIDER_LIST_BY_ID_CALL";
export const PROVIDER_LIST_BY_ID_SUCCESS = "PROVIDER_LIST_BY_ID_SUCCESS";
export const PROVIDER_LIST_BY_ID_ERROR = "PROVIDER_LIST_BY_ID_ERROR";

// Provider List by Filter types

export const PROVIDER_LIST_BY_FILTERDATA_CALL =
  "PROVIDER_LIST_BY_FILTERDATA_CALL";
export const PROVIDER_LIST_BY_FILTERDATA_SUCCESS =
  "PROVIDER_LIST_BY_FILTERDATA_SUCCESS";
export const PROVIDER_LIST_BY_FILTERDATA_ERROR =
  "PROVIDER_LIST_BY_FILTERDATA_ERROR";

// Provider status types

export const GET_PROVIDER_STATUS_CALL = "GET_PROVIDER_STATUS_CALL";
export const GET_PROVIDER_STATUS_SUCCESS = "GET_PROVIDER_STATUS_SUCCESS";
export const GET_PROVIDER_STATUS_ERROR = "GET_PROVIDER_STATUS_ERROR";

// Provider FormMode type

export const PROVIDER_FORM_MODE = "PROVIDER_FORM_MODE";

// Provider change Status via action

export const PROVIDER_STATUS_CHANGE_SINGLE_CALL =
  "PROVIDER_STATUS_CHANGE_SINGLE_CALL";
export const PROVIDER_STATUS_CHANGE_SINGLE_SUCCESS =
  "PROVIDER_STATUS_CHANGE_SINGLE_SUCCESS";
export const PROVIDER_STATUS_CHANGE_SINGLE_ERROR =
  "PROVIDER_STATUS_CHANGE_SINGLE_ERROR";

// Role change Status via action

export const ROLE_LIST_CALL = "ROLE_LIST_CALL";
export const ROLE_LIST_SUCCESS = "ROLE_LIST_SUCCESS";
export const ROLE_LIST_ERROR = "ROLE_LIST_ERROR";
// Set User Role type

export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_CLIENT_THEME = "SET_CLIENT_THEME";

// Set Role Permission type

export const SET_ROLL_PERMISSIONS_CALL = "SET_ROLL_PERMISSIONS_CALL";
export const SET_ROLL_PERMISSIONS_SUCCESS = "SET_ROLL_PERMISSIONS_SUCCESS";
export const SET_ROLL_PERMISSIONS_ERROR = "SET_ROLL_PERMISSIONS_ERROR";

// Set User Permission type

export const USER_LIST_CALL = "USER_LIST_CALL";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_ERROR = "USER_LIST_ERROR";

//set user image type
export const GET_USER_PROFILE_IMAGE_CALL = "GET_USER_PROFILE_IMAGE_CALL";
export const GET_USER_PROFILE_IMAGE_SUCCESS = "GET_USER_PROFILE_IMAGE_SUCCESS";
export const GET_USER_PROFILE_IMAGE_ERROR = "GET_USER_PROFILE_IMAGE_ERROR";

// Set Role Permission type

export const UPDATE_ROLL_PERMISSIONS_CALL = "UPDATE_ROLL_PERMISSIONS_CALL";
export const UPDATE_ROLL_PERMISSIONS_SUCCESS =
  "UPDATE_ROLL_PERMISSIONS_SUCCESS";
export const UPDATE_ROLL_PERMISSIONS_ERROR = "UPDATE_ROLL_PERMISSIONS_ERROR";
// Set User Registration type

export const USER_REGISTRATION_CALL = "USER_REGISTRATION_CALL";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_REGISTRATION_ERROR = "USER_REGISTRATION_ERROR";

// User data by id

export const USER_LIST_BY_ID_CALL = "USER_LIST_BY_ID_CALL";
export const USER_LIST_BY_ID_SUCCESS = "USER_LIST_BY_ID_SUCCESS";
export const USER_LIST_BY_ID_ERROR = "USER_LIST_BY_ID_ERROR";

// Set Master Profile

export const PROFILE_TYPE_LIST_CALL = "PROFILE_TYPE_LIST_CALL";
export const PROFILE_TYPE_LIST_SUCCESS = "PROFILE_TYPE_LIST_SUCCESS";
export const PROFILE_TYPE_LIST_ERROR = "PROFILE_TYPE_LIST_ERROR";

// User FormMode type

export const USER_FORM_MODE = "USER_FORM_MODE";

// User change Status via action

export const USER_STATUS_CHANGE_SINGLE_CALL = "USER_STATUS_CHANGE_SINGLE_CALL";
export const USER_STATUS_CHANGE_SINGLE_SUCCESS =
  "USER_STATUS_CHANGE_SINGLE_SUCCESS";
export const USER_STATUS_CHANGE_SINGLE_ERROR =
  "USER_STATUS_CHANGE_SINGLE_ERROR";

// User change password via action

export const USER_CHANGE_PASSWORD_CALL = "USER_CHANGE_PASSWORD_CALL";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_ERROR = "USER_CHANGE_PASSWORD_ERROR";

// Role Registration

export const ROLE_REGISTRATION_CALL = "ROLE_REGISTRATION_CALL";
export const ROLE_REGISTRATION_SUCCESS = "ROLE_REGISTRATION_SUCCESS";
export const ROLE_REGISTRATION_ERROR = "ROLE_REGISTRATION_ERROR";

// Lnading Page List

export const LANDING_PAGE_LIST_CALL = "LANDING_PAGE_LIST_CALL";
export const LANDING_PAGE_LIST_SUCCESS = "LANDING_PAGE_LIST_SUCCESS";
export const LANDING_PAGE_LIST_ERROR = "LANDING_PAGE_LIST_ERROR";

// Role FormMode type

export const ROLE_FORM_MODE = "ROLE_FORM_MODE";

// Role data by id

export const ROLE_LIST_BY_ID_CALL = "ROLE_LIST_BY_ID_CALL";
export const ROLE_LIST_BY_ID_SUCCESS = "ROLE_LIST_BY_ID_SUCCESS";
export const ROLE_LIST_BY_ID_ERROR = "ROLE_LIST_BY_ID_ERROR";

// Lougout Action Type

export const SET_LOUGOUT = "SET_LOUGOUT";

// User List by Filter types

export const USER_LIST_BY_FILTERDATA_CALL = "USER_LIST_BY_FILTERDATA_CALL";
export const USER_LIST_BY_FILTERDATA_SUCCESS =
  "USER_LIST_BY_FILTERDATA_SUCCESS";
export const USER_LIST_BY_FILTERDATA_ERROR = "USER_LIST_BY_FILTERDATA_ERROR";

// Role List by Filter types

export const ROLE_LIST_BY_FILTERDATA_CALL = "ROLE_LIST_BY_FILTERDATA_CALL";
export const ROLE_LIST_BY_FILTERDATA_SUCCESS =
  "ROLE_LIST_BY_FILTERDATA_SUCCESS";
export const ROLE_LIST_BY_FILTERDATA_ERROR = "ROLE_LIST_BY_FILTERDATA_ERROR";

export const SET_NAME_APPBAR = "SET_NAME_APPBAR";

// Timezone List types

export const TIMEZONE_LIST_CALL = "TIMEZONE_LIST_CALL";
export const TIMEZONE_LIST_SUCCESS = "TIMEZONE_LIST_SUCCESS";
export const TIMEZONE_LIST_ERROR = "TIMEZONE_LIST_ERROR";

// user Provider List Call

export const USER_PROVIDER_LIST_CALL = "USER_PROVIDER_LIST_CALL";
export const USER_PROVIDER_LIST_SUCCESS = "USER_PROVIDER_LIST_SUCCESS";
export const USER_PROVIDER_LIST_FAIL = "USER_PROVIDER_LIST_FAIL";

// user Provider List Call

export const PROVIDER_ROLE_LIST_CALL = "PROVIDER_ROLE_LIST_CALL";
export const PROVIDER_ROLE_LIST_SUCCESS = "PROVIDER_ROLE_LIST_SUCCESS";
export const PROVIDER_ROLE_LIST_ERROR = "PROVIDER_ROLE_LIST_ERROR";

// Move to Collection

export const MOVE_TO_COLLECTION_CALL = "kjbhjjhh";

// Daily Deposit types report

export const DAILY_DEPOSIT_TABLE_DATA_CALL = "DAILY_DEPOSIT_TABLE_DATA_CALL";
export const DAILY_DEPOSIT_TABLE_DATA_SUCCESS =
  "DAILY_DEPOSIT_TABLE_DATA_SUCCESS";
export const DAILY_DEPOSIT_TABLE_DATA_ERROR = "DAILY_DEPOSIT_TABLE_DATA_ERROR";

// Daily Deposit types report

export const CPT_DATA_TABLE_DATA_CALL = "CPT_DATA_TABLE_DATA_CALL";
export const CPT_DATA_TABLE_DATA_SUCCESS = "CPT_DATA_TABLE_DATA_SUCCESS";
export const CPT_DATA_TABLE_DATA_ERROR = "CPT_DATA_TABLE_DATA_ERROR";

// Total Outstanding types report

export const TOTAL_OUTSTANDING_TABLE_DATA_CALL =
  "TOTAL_OUTSTANDING_TABLE_DATA_CALL";
export const TOTAL_OUTSTANDING_TABLE_DATA_SUCCESS =
  "TOTAL_OUTSTANDING_TABLE_DATA_SUCCESS";
export const TOTAL_OUTSTANDING_TABLE_DATA_ERROR =
  "TOTAL_OUTSTANDING_TABLE_DATA_ERROR";

// Default types report

export const DEFAULTS_TABLE_DATA_CALL = "DEFAULTS_TABLE_DATA_CALL";
export const DEFAULTS_TABLE_DATA_SUCCESS = "DEFAULTS_TABLE_DATA_SUCCESS";
export const DEFAULTS_TABLE_DATA_ERROR = "DEFAULTS_TABLE_DATA_ERROR";

// move-to-collections types report

export const MOVE_TO_COLLECTION_TABLE_DATA_CALL =
  "MOVE_TO_COLLECTION_TABLE_DATA_CALL";
export const MOVE_TO_COLLECTION_TABLE_DATA_SUCCESS =
  "MOVE_TO_COLLECTION_TABLE_DATA_SUCCESS";
export const MOVE_TO_COLLECTION_TABLE_DATA_ERROR =
  "MOVE_TO_COLLECTION_TABLE_DATA_ERROR";

// bad debts types report

export const BED_DEBTS_TABLE_DATA_CALL = "BED_DEBTS_TABLE_DATA_CALL";
export const BED_DEBTS_TABLE_DATA_SUCCESS = "BED_DEBTS_TABLE_DATA_SUCCESS";
export const BED_DEBTS_TABLE_DATA_ERROR = "BED_DEBTS_TABLE_DATA_ERROR";

// new members register types report

export const NEW_MEMBER_TABLE_DATA_CALL = "NEW_MEMBER_TABLE_DATA_CALL";
export const NEW_MEMBER_TABLE_DATA_SUCCESS = "NEW_MEMBER_TABLE_DATA_SUCCESS";
export const NEW_MEMBER_TABLE_DATA_ERROR = "NEW_MEMBER_TABLE_DATA_ERROR";

// Set Layout in App

export const SET_LAYOUT_IN_APP = "SET_LAYOUT_IN_APP";

// Provider search action types

export const PROVIDER_SEARCH_CALL = "PROVIDER_SEARCH_CALL";

// Provider Activity action types

export const PROVIDER_ACTIVITY_BY_ID_CALL = "PROVIDER_ACTIVITY_BY_ID_CALL";
export const PROVIDER_ACTIVITY_BY_ID_SUCCESS =
  "PROVIDER_ACTIVITY_BY_ID_SUCCESS";
export const PROVIDER_ACTIVITY_BY_ID_ERROR = "PROVIDER_ACTIVITY_BY_ID_ERROR";

// empty Provider List Action type

export const PROVIDER_LIST_ACCESABLE_EMPTY = "PROVIDER_LIST_ACCESABLE_EMPTY";

// Set reducer to Initial State

export const REST_REDUCER_STATE = "REST_REDUCER_STATE";

// Provider Parameter Action type

export const PROVIDER_PARAMETER_CALL = "PROVIDER_PARAMETER_CALL";
export const PROVIDER_PARAMETER_SUCCESS = "PROVIDER_PARAMETER_SUCCESS";
export const PROVIDER_PARAMETER_ERROR = "PROVIDER_PARAMETER_ERROR";

// Savr Provider Parameter Action type

export const SAVE_PROVIDER_PARAMETER_CALL = "SAVE_PROVIDER_PARAMETER_CALL";
export const SAVE_PROVIDER_PARAMETER_SUCCESS =
  "SAVE_PROVIDER_PARAMETER_SUCCESS";
export const SAVE_PROVIDER_PARAMETER_ERROR = "SAVE_PROVIDER_PARAMETER_ERROR";

// Provider Parameter FormMode type

export const PROVIDER_PARAMETER_FORM_MODE = "PROVIDER_PARAMETER_FORM_MODE";

// Provider Parameter by id action types

export const PROVIDER_PARAMETER_BY_ID_CALL = "PROVIDER_PARAMETER_BY_ID_CALL";
export const PROVIDER_PARAMETER_BY_ID_SUCCESS =
  "PROVIDER_PARAMETER_BY_ID_SUCCESS";
export const PROVIDER_PARAMETER_BY_ID_ERROR = "PROVIDER_PARAMETER_BY_ID_ERROR";

// Role Status Update

export const ROLE_STATUS_UPDATE_CALL = "ROLE_STATUS_UPDATE_CALL";
export const ROLE_STATUS_UPDATE_SUCCESS = "ROLE_STATUS_UPDATE_SUCCESS";
export const ROLE_STATUS_UPDATE_ERROR = "ROLE_STATUS_UPDATE_ERROR";

// Patient Search Action types

export const GENRATE_OFFER_PATIENT_SEARCH_CALL =
  "GENRATE_OFFER_PATIENT_SEARCH_CALL";
export const GENRATE_OFFER_PATIENT_SEARCH_SUCCESS =
  "GENRATE_OFFER_PATIENT_SEARCH_SUCCESS";
export const GENRATE_OFFER_PATIENT_SEARCH_ERROR =
  "GENRATE_OFFER_PATIENT_SEARCH_ERROR";

// Upload Documnet Action types

export const UPLOAD_DOCUMNET_FINAL_OFFER_CALL =
  "UPLOAD_DOCUMNET_FINAL_OFFER_CALL";
export const UPLOAD_DOCUMNET_FINAL_OFFER_SUCCESS =
  "UPLOAD_DOCUMNET_FINAL_OFFER_SUCCESS";
export const UPLOAD_DOCUMNET_FINAL_OFFER_ERROR =
  "UPLOAD_DOCUMNET_FINAL_OFFER_ERROR";

// Upload Documnet Action types

export const SEND_OFFER_EMAIL_CALL = "SEND_OFFER_EMAIL_CALL";
export const SEND_OFFER_EMAIL_SUCCESS = "SEND_OFFER_EMAIL_SUCCESS";
export const SEND_OFFER_EMAIL_ERROR = "SEND_OFFER_EMAIL_ERROR";

// Email patient details action types

export const SHOW_EMAIL_PATIENT_DETAILS_CALL =
  "SHOW_EMAIL_PATIENT_DETAILS_CALL";
export const SHOW_EMAIL_PATIENT_DETAILS_SUCCESS =
  "SHOW_EMAIL_PATIENT_DETAILS_SUCCESS";
export const SHOW_EMAIL_PATIENT_DETAILS_ERROR =
  "SHOW_EMAIL_PATIENT_DETAILS_ERROR";

// Patient Details Update Offer
export const UPDATE_PATIENT_DETAILS_OFFER_CALL =
  "UPDATE_PATIENT_DETAILS_OFFER_CALL";
export const UPDATE_PATIENT_DETAILS_OFFER_SUCCESS =
  "UPDATE_PATIENT_DETAILS_OFFER_SUCCESS";
export const UPDATE_PATIENT_DETAILS_OFFER_ERROR =
  "UPDATE_PATIENT_DETAILS_OFFER_ERROR";

// Patient Genrate Offer
export const PATIENT_GENRATE_OFFER_CALL = "PATIENT_GENRATE_OFFER_CALL";
export const PATIENT_GENRATE_OFFER_SUCCESS = "PATIENT_GENRATE_OFFER_SUCCESS";
export const PATIENT_GENRATE_OFFER_ERROR = "PATIENT_GENRATE_OFFER_ERROR";
export const PATIENT_GENRATE_OFFER_DELETE = "PATIENT_GENRATE_OFFER_DELETE";

// LOAN Genrate Offer
export const PERSONAL_LOAN_OFFER_CALL = "PERSONAL_LOAN_OFFER_CALL";
export const PERSONAL_LOAN_OFFER_SUCCESS = "PERSONAL_LOAN_OFFER_SUCCESS";
export const PERSONAL_LOAN_OFFER_ERROR = "PERSONAL_LOAN_OFFER_ERROR";

// Patient Create Offer
export const CREATE_OFFER_PATIENT_CALL = "CREATE_OFFER_PATIENT_CALL";
export const CREATE_OFFER_PATIENT_SUCCESS = "CREATE_OFFER_PATIENT_SUCCESS";
export const CREATE_OFFER_PATIENT_ERROR = "CREATE_OFFER_PATIENT_ERROR";

// Patient Print Offer
export const PRINT_OFFER_PATIENT_CALL = "PRINT_OFFER_PATIENT_CALL";
export const PRINT_OFFER_PATIENT_SUCCESS = "PRINT_OFFER_PATIENT_SUCCESS";
export const PRINT_OFFER_PATIENT_ERROR = "PRINT_OFFER_PATIENT_ERROR";

// Patient Hyper Link Genrate Offer
export const PATIENT_HYPER_LINK_GENRATE_OFFER_CALL =
  "PATIENT_HYPER_LINK_GENRATE_OFFER_CALL";
export const PATIENT_HYPER_LINK_GENRATE_OFFER_SUCCESS =
  "PATIENT_HYPER_LINK_GENRATE_OFFER_SUCCESS";
export const PATIENT_HYPER_LINK_GENRATE_OFFER_ERROR =
  "PATIENT_HYPER_LINK_GENRATE_OFFER_ERROR";

// Patient Download Patient Document Offer
export const MAKE_PATIENT_PAYMENT_CALL = "MAKE_PATIENT_PAYMENT_CALL";
export const MAKE_PATIENT_PAYMENT_SUCCESS = "MAKE_PATIENT_PAYMENT_SUCCESS";
export const MAKE_PATIENT_PAYMENT_ERROR = "MAKE_PATIENT_PAYMENT_ERROR";

// Patient Delete Patient Document Offer
export const DELETE_PATIENT_OFFER_DOCUMENTS_CALL =
  "DELETE_PATIENT_OFFER_DOCUMENTS_CALL";
export const DELETE_PATIENT_OFFER_DOCUMENTS_SUCCESS =
  "DELETE_PATIENT_OFFER_DOCUMENTS_SUCCESS";
export const DELETE_PATIENT_OFFER_DOCUMENTS_ERROR =
  "DELETE_PATIENT_OFFER_DOCUMENTS_ERROR";

// Patient Payment Information Action types
export const PATIENT_INFO_BILLING_DETAILS_CALL =
  "PATIENT_INFO_BILLING_DETAILS_CALL";
export const PATIENT_INFO_BILLING_DETAILS_SUCCESS =
  "PATIENT_INFO_BILLING_DETAILS_SUCCESS";
export const PATIENT_INFO_BILLING_DETAILS_ERROR =
  "PATIENT_INFO_BILLING_DETAILS_ERROR";

// Email patient information action types

export const SHOW_EMAIL_PATIENT_INFO_CALL = "SHOW_EMAIL_PATIENT_INFO_CALL";
export const SHOW_EMAIL_PATIENT_INFO_SUCCESS =
  "SHOW_EMAIL_PATIENT_INFO_SUCCESS";
export const SHOW_EMAIL_PATIENT_INFO_ERROR = "SHOW_EMAIL_PATIENT_INFO_ERROR";

// Patient register action types

export const PATIENT_REGISTER_CALL = "PATIENT_REGISTER_CALL";
export const PATIENT_REGISTER_SUCCESS = "PATIENT_REGISTER_SUCCESS";
export const PATIENT_REGISTER_ERROR = "PATIENT_REGISTER_ERROR";

// Patient offer graph action types

export const PATIENT_OFFER_GRAPH_CALL = "PATIENT_OFFER_GRAPH_CALL";
export const PATIENT_OFFER_GRAPH_SUCCESS = "PATIENT_OFFER_GRAPH_SUCCESS";
export const PATIENT_OFFER_GRAPH_ERROR = "PATIENT_OFFER_GRAPH_ERROR";
export const PATIENT_OFFER_GRAPH_DATA_EMPTY = "PATIENT_OFFER_GRAPH_DATA_EMPTY";

// Set Redirect From Email

export const PATIENT_OFFER_EMAIL_SET = "PATIENT_OFFER_EMAIL_SET";
export const OFFER_EMAIL_SET = "OFFER_EMAIL_SET";

// Patient Forget Password

export const PATIENT_FORGET_PASSWORD_CALL = "PATIENT_FORGET_PASSWORD_CALL";
export const PATIENT_FORGET_PASSWORD_SUCCESS =
  "PATIENT_FORGET_PASSWORD_SUCCESS";
export const PATIENT_FORGET_PASSWORD_ERROR = "PATIENT_FORGET_PASSWORD_ERROR";

// Patient Reset Password Auth Check

export const CHECK_RESET_PASSWORD_AUTH_CHECK_CALL =
  "CHECK_RESET_PASSWORD_AUTH_CHECK_CALL";
export const CHECK_RESET_PASSWORD_AUTH_CHECK_SUCCESS =
  "CHECK_RESET_PASSWORD_AUTH_CHECK_SUCCESS";
export const CHECK_RESET_PASSWORD_AUTH_CHECK_ERROR =
  "CHECK_RESET_PASSWORD_AUTH_CHECK_ERROR";

// Reset Password Action types

export const CHANGE_PATIENT_PASSWORD_CALL = "CHANGE_PATIENT_PASSWORD_CALL";
export const CHANGE_PATIENT_PASSWORD_SUCCESS =
  "CHANGE_PATIENT_PASSWORD_SUCCESS";
export const CHANGE_PATIENT_PASSWORD_ERROR = "CHANGE_PATIENT_PASSWORD_ERROR";

// CPT Code Add Patinet Dropdown Data Action type

export const SERVICES_CPT_CODE_PATIENT_CALL = "SERVICES_CPT_CODE_PATIENT_CALL";
export const SERVICES_CPT_CODE_PATIENT_SUCCESS =
  "SERVICES_CPT_CODE_PATIENT_SUCCESS";
export const SERVICES_CPT_CODE_PATIENT_ERROR =
  "SERVICES_CPT_CODE_PATIENT_ERROR";

// Add Patient Details Offer

export const ADD_PATIENT_DETAILS_OFFER_CALL = "ADD_PATIENT_DETAILS_OFFER_CALL";
export const ADD_PATIENT_DETAILS_OFFER_SUCCESS =
  "ADD_PATIENT_DETAILS_OFFER_SUCCESS";
export const ADD_PATIENT_DETAILS_OFFER_ERROR =
  "ADD_PATIENT_DETAILS_OFFER_ERROR";

// Update Patient Details

export const UPDATE_PATIENT_DETAILS_CALL = "UPDATE_PATIENT_DETAILS_CALL";
export const UPDATE_PATIENT_DETAILS_SUCCESS = "UPDATE_PATIENT_DETAILS_SUCCESS";
export const UPDATE_PATIENT_DETAILS_ERROR = "UPDATE_PATIENT_DETAILS_ERROR";

// Active plan Patient List

export const ACTIVE_PLAN_PATIENT_LIST_CALL = "ACTIVE_PLAN_PATIENT_LIST_CALL";
export const ACTIVE_PLAN_PATIENT_LIST_SUCCESS =
  "ACTIVE_PLAN_PATIENT_LIST_SUCCESS";
export const ACTIVE_PLAN_PATIENT_LIST_ERROR = "ACTIVE_PLAN_PATIENT_LIST_ERROR";

// Patient Document List

export const PATIENT_DOCUMENT_LIST_CALL = "PATIENT_DOCUMENT_LIST_CALL";
export const PATIENT_DOCUMENT_LIST_SUCCESS = "PATIENT_DOCUMENT_LIST_SUCCESS";
export const PATIENT_DOCUMENT_LIST_ERROR = "PATIENT_DOCUMENT_LIST_ERROR";

// Offer Document File Dwonload

export const OFFER_DOWNLOAD_FILE_CALL = "OFFER_DOWNLOAD_FILE_CALL";
export const OFFER_DOWNLOAD_FILE_SUCCESS = "OFFER_DOWNLOAD_FILE_SUCCESS";
export const OFFER_DOWNLOAD_FILE_ERROR = "OFFER_DOWNLOAD_FILE_ERROR";

// Add Proposal Details

export const ADD_PROPOSAL_DETAILS_CALL = "ADD_PROPOSAL_DETAILS_CALL";
export const ADD_PROPOSAL_DETAILS_SUCCESS = "ADD_PROPOSAL_DETAILS_SUCCESS";
export const ADD_PROPOSAL_DETAILS_ERROR = "ADD_PROPOSAL_DETAILS_ERROR";

// Add Setup Monthly Payment

export const SETUP_MONTHLY_PAYMENT_CALL = "SETUP_MONTHLY_PAYMENT_CALL";
export const SETUP_MONTHLY_PAYMENT_SUCCESS = "SETUP_MONTHLY_PAYMENT_SUCCESS";
export const SETUP_MONTHLY_PAYMENT_ERROR = "SETUP_MONTHLY_PAYMENT_ERROR";

// Completed plan Patient List

export const COMPLETED_PLAN_PATIENT_LIST_CALL =
  "COMPLETED_PLAN_PATIENT_LIST_CALL";
export const COMPLETED_PLAN_PATIENT_LIST_SUCCESS =
  "COMPLETED_PLAN_PATIENT_LIST_SUCCESS";
export const COMPLETED_PLAN_PATIENT_LIST_ERROR =
  "COMPLETED_PLAN_PATIENT_LIST_ERROR";

// Set up Monthly from Offer

export const SETUP_MONTHLY_FROM_OFFER = "SETUP_MONTHLY_FROM_OFFER";

// Offer genrate Offer Loading Stop

export const GENRATE_OFFER_LOADING_FALSE = "GENRATE_OFFER_LOADING_FALSE";

// Open funded data from Patient Dashboard

export const SET_DEFAULT_VALUE_OFFER = "SET_DEFAULT_VALUE_OFFER";

// Select Patient From Table

export const SET_PATIENT_OFFER_SELECT_PATINET =
  "SET_PATIENT_OFFER_SELECT_PATINET";

// Back To Table search

export const SET_BACK_TO_SEARCH_FROM_OFFER = "SET_BACK_TO_SEARCH_FROM_OFFER";

// Add PreposalId Array

export const SET_PREPOSAL_IDS_ARRAY = "SET_PREPOSAL_IDS_ARRAY";
export const OPEN_FUNDING_AMOUNT_CALL = "OPEN_FUNDING_AMOUNT_CALL";
export const OPEN_FUNDING_AMOUNT_SUCCESS = "OPEN_FUNDING_AMOUNT_SUCCESS";
export const OPEN_FUNDING_AMOUNT_ERROR = "OPEN_FUNDING_AMOUNT_ERROR";

// Open funded data from Patient Dashboard

export const PENDING_OFFER_AMOUNT_CALL = "PENDING_OFFER_AMOUNT_CALL";
export const PENDING_OFFER_AMOUNT_SUCCESS = "PENDING_OFFER_AMOUNT_SUCCESS";
export const PENDING_OFFER_AMOUNT_ERROR = "PENDING_OFFER_AMOUNT_ERROR";

// payment status data from Patient Dashboard

export const PAYMENT_STATUS_DATA_CALL = "PAYMENT_STATUS_DATA_CALL";
export const PAYMENT_STATUS_DATA_SUCCESS = "PAYMENT_STATUS_DATA_SUCCESS";
export const PAYMENT_STATUS_DATA_ERROR = "PAYMENT_STATUS_DATA_ERROR";

// Offer account status data from Patient Dashboard

export const OFFER_ACCOUNT_STATUS_CALL = "OFFER_ACCOUNT_STATUS_CALL";
export const OFFER_ACCOUNT_STATUS_SUCCESS = "OFFER_ACCOUNT_STATUS_SUCCESS";
export const OFFER_ACCOUNT_STATUS_ERROR = "OFFER_ACCOUNT_STATUS_ERROR";

// Original loan terms data from Patient Dashboard

export const ORIGINAL_LOAN_TERM_CALL = "ORIGINAL_LOAN_TERM_CALL";
export const ORIGINAL_LOAN_TERM_SUCCESS = "ORIGINAL_LOAN_TERM_SUCCESS";
export const ORIGINAL_LOAN_TERM_ERROR = "ORIGINAL_LOAN_TERM_ERROR";

// Remaining funding data from Patient Dashboard

export const REMAINING_FUND_GRAPH_CALL = "REMAINING_FUND_GRAPH_CALL";
export const REMAINING_FUND_GRAPH_SUCCESS = "REMAINING_FUND_GRAPH_SUCCESS";
export const REMAINING_FUND_GRAPH_ERROR = "REMAINING_FUND_GRAPH_ERROR";

// Empty Offer Select

export const EMPTY_OFFER_SELECT_PATIENT = "EMPTY_OFFER_SELECT_PATIENT";

// Recent contact list from Main Dashboard

export const RECENT_CONTACT_LISTS_CALL = "RECENT_CONTACT_LISTS_CALL";
export const RECENT_CONTACT_LISTS_SUCCESS = "RECENT_CONTACT_LISTS_SUCCESS";
export const RECENT_CONTACT_LISTS_ERROR = "RECENT_CONTACT_LISTS_ERROR";

// Recent accept contect list

export const ACCEPT_OFFER_LIST_CALL = "ACCEPT_OFFER_LIST_CALL";
export const ACCEPT_OFFER_LIST_SUCCESS = "ACCEPT_OFFER_LIST_SUCCESS";
export const ACCEPT_OFFER_LIST_ERROR = "ACCEPT_OFFER_LIST_ERROR";

// Expiring Application List

export const EXPIRYING_APPLICATION_DATA_CALL =
  "EXPIRYING_APPLICATION_DATA_CALL";
export const EXPIRYING_APPLICATION_DATA_SUCCESS =
  "EXPIRYING_APPLICATION_DATA_SUCCESS";
export const EXPIRYING_APPLICATION_DATA_ERROR =
  "EXPIRYING_APPLICATION_DATA_ERROR";

// Active Patient Dashboard Data

export const NEW_ACTIVE_PATIENT_DASHBOARD_DATA_CALL =
  "NEW_ACTIVE_PATIENT_DASHBOARD_DATA_CALL";
export const NEW_ACTIVE_PATIENT_DASHBOARD_DATA_SUCCESS =
  "NEW_ACTIVE_PATIENT_DASHBOARD_DATA_SUCCESS";
export const NEW_ACTIVE_PATIENT_DASHBOARD_DATA_ERROR =
  "NEW_ACTIVE_PATIENT_DASHBOARD_DATA_ERROR";

// Default Dashboard Data

export const DEFAULT_DASHBOARD_DATA_CALL = "DEFAULT_DASHBOARD_DATA_CALL";
export const DEFAULT_DASHBOARD_DATA_SUCCESS = "DEFAULT_DASHBOARD_DATA_SUCCESS";
export const DEFAULT_DASHBOARD_DATA_ERROR = "DEFAULT_DASHBOARD_DATA_ERROR";

// Move to collection Dashboard Data

export const MOVE_TO_COLLECTION_DASHBOARD_CALL =
  "MOVE_TO_COLLECTION_DASHBOARD_CALL";
export const MOVE_TO_COLLECTION_DASHBOARD_SUCCESS =
  "MOVE_TO_COLLECTION_DASHBOARD_SUCCESS";
export const MOVE_TO_COLLECTION_DASHBOARD_ERROR =
  "MOVE_TO_COLLECTION_DASHBOARD_ERROR";

// CPT contribution Data

export const CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_CALL =
  "CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_CALL";
export const CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_SUCCESS =
  "CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_SUCCESS";
export const CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_ERROR =
  "CPT_CODE_DISTRIBUTED_DASHBOARD_DATA_ERROR";

// Bad debates Dashboard Data

export const BAD_DEBATES_DASHBOARD_DATA_CALL =
  "BAD_DEBATES_DASHBOARD_DATA_CALL";
export const BAD_DEBATES_DASHBOARD_DATA_SUCCESS =
  "BAD_DEBATES_DASHBOARD_DATA_SUCCESS";
export const BAD_DEBATES_DASHBOARD_DATA_ERROR =
  "BAD_DEBATES_DASHBOARD_DATA_ERROR";

// Total Out Standing Dashboard Data

export const TOTAL_OUT_SDANDING_DASHBOARD_DATA_CALL =
  "TOTAL_OUT_SDANDING_DASHBOARD_DATA_CALL";
export const TOTAL_OUT_SDANDING_DASHBOARD_DATA_SUCCESS =
  "TOTAL_OUT_SDANDING_DASHBOARD_DATA_SUCCESS";
export const TOTAL_OUT_SDANDING_DASHBOARD_DATA_ERROR =
  "TOTAL_OUT_SDANDING_DASHBOARD_DATA_ERROR";

// Total Out Standing Dashboard Count
export const TOTAL_OUT_SDANDING_DASHBOARD_COUNT_CALL =
  "TOTAL_OUT_SDANDING_DASHBOARD_COUNT_CALL";
export const TOTAL_OUT_SDANDING_DASHBOARD_COUNT_SUCCESS =
  "TOTAL_OUT_SDANDING_DASHBOARD_COUNT_SUCCESS";
export const TOTAL_OUT_SDANDING_DASHBOARD_COUNT_ERROR =
  "TOTAL_OUT_SDANDING_DASHBOARD_COUNT_ERROR";

// Daily Deposite Dashboard Data
export const DAILY_DEPOSITE_DASHBOARD_DATA_CALL =
  "DAILY_DEPOSITE_DASHBOARD_DATA_CALL";
export const DAILY_DEPOSITE_DASHBOARD_DATA_SUCCESS =
  "DAILY_DEPOSITE_DASHBOARD_DATA_SUCCESS";
export const DAILY_DEPOSITE_DASHBOARD_DATA_ERROR =
  "DAILY_DEPOSITE_DASHBOARD_DATA_ERROR";

// Daily Deposite Dashboard Data

export const DAILY_DEPOSITE_DASHBOARD_COUNT_CALL =
  "DAILY_DEPOSITE_DASHBOARD_COUNT_CALL";
export const DAILY_DEPOSITE_DASHBOARD_COUNT_SUCCESS =
  "DAILY_DEPOSITE_DASHBOARD_COUNT_SUCCESS";
export const DAILY_DEPOSITE_DASHBOARD_COUNT_ERROR =
  "DAILY_DEPOSITE_DASHBOARD_COUNT_ERROR";

// Patient Payment List Data
export const PAYMENT_LIST_AS_PER_PATIENT_ID_CALL =
  "PAYMENT_LIST_AS_PER_PATIENT_ID_CALL";
export const PAYMENT_LIST_AS_PER_PATIENT_ID_SUCCESS =
  "PAYMENT_LIST_AS_PER_PATIENT_ID_SUCCESS";
export const PAYMENT_LIST_AS_PER_PATIENT_ID_ERROR =
  "PAYMENT_LIST_AS_PER_PATIENT_ID_ERROR";

// Setup Monthly Details Patient

export const CURRENTLY_SETUP_MONTH_PATIENT_CALL =
  "CURRENTLY_SETUP_MONTH_PATIENT_CALL";
export const CURRENTLY_SETUP_MONTH_PATIENT_SUCCESS =
  "CURRENTLY_SETUP_MONTH_PATIENT_SUCCESS";
export const CURRENTLY_SETUP_MONTH_PATIENT_ERROR =
  "CURRENTLY_SETUP_MONTH_PATIENT_ERROR";

// Before OTP Send Payment

export const BEFORE_PAYMENT_SEND_OTP_CALL = "BEFORE_PAYMENT_SEND_OTP_CALL";
export const BEFORE_PAYMENT_SEND_OTP_SUCCESS =
  "BEFORE_PAYMENT_SEND_OTP_SUCCESS";
export const BEFORE_PAYMENT_SEND_OTP_ERROR = "BEFORE_PAYMENT_SEND_OTP_ERROR";

// Verify Payment OTP

export const VERIFY_PAYMENT_OTP_CALL = "VERIFY_PAYMENT_OTP_CALL";
export const VERIFY_PAYMENT_OTP_SUCCESS = "VERIFY_PAYMENT_OTP_SUCCESS";
export const VERIFY_PAYMENT_OTP_ERROR = "VERIFY_PAYMENT_OTP_ERROR";

// SET user id for forget password

export const SET_USER_ID_FORGET_PASSWORD = "SET_USER_ID_FORGET_PASSWORD";

// Reset Payment List

export const RESET_PAYMENT_LIST = "RESET_PAYMENT_LIST0";

// Reset Payment Error

export const RESET_ERROR_PAYMENT_LIST = "RESET_ERROR_PAYMENT_LIST";

// Patient TPO Score

export const PATIENT_TPO_SCORE_CALL = "PATIENT_TPO_SCORE_CALL";
export const PATIENT_TPO_SCORE_SUCCESS = "PATIENT_TPO_SCORE_SUCCESS";
export const PATIENT_TPO_SCORE_ERROR = "PATIENT_TPO_SCORE_ERROR";

// Generate Patient TPO Score

export const GENERATE_PATIENT_TPO_SCORE_CALL =
  "GENERATE_PATIENT_TPO_SCORE_CALL";
export const GENERATE_PATIENT_TPO_SCORE_SUCCESS =
  "GENERATE_PATIENT_TPO_SCORE_SUCCESS";
export const GENERATE_PATIENT_TPO_SCORE_ERROR =
  "GENERATE_PATIENT_TPO_SCORE_ERROR";

// Account Statement For Patient Portal

export const ACCOUNT_STATEMENT_TABLE_DATA_CALL =
  "ACCOUNT_STATEMENT_TABLE_DATA_CALL";
export const ACCOUNT_STATEMENT_TABLE_DATA_SUCCESS =
  "ACCOUNT_STATEMENT_TABLE_DATA_SUCCESS";
export const ACCOUNT_STATEMENT_TABLE_DATA_ERROR =
  "ACCOUNT_STATEMENT_TABLE_DATA_ERROR";

// Get Otp actions

export const GET_OTP_CALL = "GET_OTP_CALL";
export const GET_OTP_SUCCESS = "GET_OTP_SUCCESS";
export const GET_OTP_ERROR = "GET_OTP_ERROR";

// Payment Checkout Action Type
export const PAYMENT_CHECKOUT_CALL = "PAYMENT_CHECKOUT_CALL";
export const PAYMENT_CHECKOUT_SUCCESS = "PAYMENT_CHECKOUT_SUCCESS";
export const PAYMENT_CHECKOUT_ERROR = "PAYMENT_CHECKOUT_ERROR";

// Client Theme Action Type

export const CLIENT_THEME_CALL = "CLIENT_THEME_CALL";
export const CLIENT_THEME_SUCCESS = "CLIENT_THEME_SUCCESS";
export const CLIENT_THEME_ERROR = "CLIENT_THEME_ERROR";

// Client Theme Action Type

export const CLIENT_PORTAL_CONFIG_CALL = "CLIENT_PORTAL_CONFIG_CALL";
export const CLIENT_PORTAL_CONFIG_SUCCESS = "CLIENT_PORTAL_CONFIG_SUCCESS";
export const CLIENT_PORTAL_CONFIG_ERROR = "CLIENT_PORTAL_CONFIG_ERROR";

// Set Window Width

export const SET_WINDOW_WIDTH = "SET_WINDOW_WIDTH";

// Default Theme Call
export const DEFAULT_THEME_CALL = "DEFAULT_THEME_CALL";
export const DEFAULT_THEME_SUCCESS = "DEFAULT_THEME_SUCCESS";
export const DEFAULT_THEME_ERROR = "DEFAULT_THEME_ERROR";

// Cerner Patient Search Action types

export const GLOBAL_PATIENT_SEARCH_CALL = "GLOBAL_PATIENT_SEARCH_CALL";
export const GLOBAL_PATIENT_SEARCH_SUCCESS = "GLOBAL_PATIENT_SEARCH_SUCCESS";
export const GLOBAL_PATIENT_SEARCH_ERROR = "GLOBAL_PATIENT_SEARCH_ERROR";

// On Board Action Types

export const ONBORDING_PROVIDER_CALL = "ONBORDING_PROVIDER_CALL";
export const ONBORDING_PROVIDER_SUCCESS = "ONBORDING_PROVIDER_SUCCESS";
export const ONBORDING_PROVIDER_ERROR = "ONBORDING_PROVIDER_ERROR";

// Delete incropration document Action Types

export const DELETE_INCROPRATION_DOCUMENT_CALL =
  "DELETE_INCROPRATION_DOCUMENT_CALL";
export const DELETE_INCROPRATION_DOCUMENT_SUCCESS =
  "DELETE_INCROPRATION_DOCUMENT_SUCCESS";
export const DELETE_INCROPRATION_DOCUMENT_ERROR =
  "DELETE_INCROPRATION_DOCUMENT_ERROR";

// Delete Address document Action Types

export const DELETE_ADDRESS_DOCUMENT_CALL = "DELETE_ADDRESS_DOCUMENT_CALL";
export const DELETE_ADDRESS_DOCUMENT_SUCCESS =
  "DELETE_ADDRESS_DOCUMENT_SUCCESS";
export const DELETE_ADDRESS_DOCUMENT_ERROR = "DELETE_ADDRESS_DOCUMENT_ERROR";

// Delete PhotoId document Action Types

export const DELETE_IDENTITY_DOCUMENT_CALL = "DELETE_IDENTITY_DOCUMENT_CALL";
export const DELETE_IDENTITY_DOCUMENT_SUCCESS =
  "DELETE_IDENTITY_DOCUMENT_SUCCESS";
export const DELETE_IDENTITY_DOCUMENT_ERROR = "DELETE_IDENTITY_DOCUMENT_ERROR";

// Get Question Data

export const GET_QUESTION_DATA_CALL = "GET_QUESTION_DATA_CALL";
export const GET_QUESTION_DATA_SUCCESS = "GET_QUESTION_DATA_SUCCESS";
export const GET_QUESTION_DATA_ERROR = "GET_QUESTION_DATA_ERROR";

// Get KYC Question Data

export const GET_KYC_QUESTIONS_CALL = "GET_KYC_QUESTIONS_CALL";
export const GET_KYC_QUESTIONS_SUCCESS = "GET_KYC_QUESTIONS_SUCCESS";
export const GET_KYC_QUESTIONS_ERROR = "GET_KYC_QUESTIONS_ERROR";

// Set Role Details

export const SET_ROLE_DETAILS_DATA = "SET_ROLE_DETAILS_DATA";

// Call Group Data List

export const GROUP_LIST_DATA_CALL = "GROUP_LIST_DATA_CALL";
export const GROUP_LIST_DATA_SUCCESS = "GROUP_LIST_DATA_SUCCESS";
export const GROUP_LIST_DATA_ERROR = "GROUP_LIST_DATA_ERROR";

// Question Save issue

export const SAVE_QUESTION_DATA_CALL = "SAVE_QUESTION_DATA_CALL";
export const SAVE_QUESTION_DATA_SUCCESS = "SAVE_QUESTION_DATA_SUCCESS";
export const SAVE_QUESTION_DATA_ERROR = "SAVE_QUESTION_DATA_ERROR";

// Empty Question

export const SET_ALL_EMPTY_Question = "SET_ALL_EMPTY_Question";

// user Left Menu Cal

export const USER_LEFT_MENU_CALL = "USER_LEFT_MENU_CALL";
export const USER_LEFT_MENU_SUCCESS = "USER_LEFT_MENU_SUCCESS";
export const USER_LEFT_MENU_ERROR = "USER_LEFT_MENU_ERROR";

// checkout Set Data

export const SET_CHECKOUT_DATA = "SET_CHECKOUT_DATA";

// Set Send Api Client List

export const SET_CLIENT_API_LIST = "SET_CLIENT_API_LIST";

// Set Master Profile

// user Left Menu Cal

// patient dul list call
export const GET_PATIENTS_DUES_LIST_CALL = "GET_PATIENTS_DUES_LIST_CALL";
export const GET_PATIENTS_DUES_LIST_SUCCESS = "GET_PATIENTS_DUES_LIST_SUCCESS";
export const GET_PATIENTS_DUES_LIST_ERROR = "GET_PATIENTS_DUES_LIST_ERROR";

// Email patient token action types

export const SHOW_EMAIL_PATIENT_TOKEN_CALL = "SHOW_EMAIL_PATIENT_TOKEN_CALL";
export const SHOW_EMAIL_PATIENT_TOKEN_SUCCESS =
  "SHOW_EMAIL_PATIENT_TOKEN_SUCCESS";
export const SHOW_EMAIL_PATIENT_TOKEN_ERROR = "SHOW_EMAIL_PATIENT_TOKEN_ERROR";

// genrate patient api call action types

export const SHOW_GENERATE_PATIENT_OFFER_CALL =
  "SHOW_GENERATE_PATIENT_OFFER_CALL";
export const SHOW_GENERATE_PATIENT_OFFER_SUCCESS =
  "SHOW_GENERATE_PATIENT_OFFER_SUCCESS";
export const SHOW_GENERATE_PATIENT_OFFER_ERROR =
  "SHOW_GENERATE_PATIENT_OFFER_ERROR";

// User Details Form

export const USER_LIST_BY_ID_FORM_CALL = "USER_LIST_BY_ID_FORM_CALL";
export const USER_LIST_BY_ID_FORM_SUCCESS = "USER_LIST_BY_ID_FORM_SUCCESS";
export const USER_LIST_BY_ID_FORM_ERROR = "USER_LIST_BY_ID_FORM_CALL";

// patient search

export const PATIENT_SEARCH_COMMONTEXT = "PATIENT_SEARCH_COMMONTEXT";
// Aylo User Details Form
export const USER_ACCOUNT_DETAILS_CALL = "USER_ACCOUNT_DETAILS_CALL";
export const USER_ACCOUNT_DETAILS_SUCCESS = "USER_ACCOUNT_DETAILS_SUCCESS";
export const USER_ACCOUNT_DETAILS_ERROR = "USER_ACCOUNT_DETAILS_ERROR";

// Empty search data Provider
export const EMPTY_SEARCH_DATA = "EMPTY_SEARCH_DATA";

// add merchant search data Provider
export const ADD_MERCHANT_SEARCH_DATA = "ADD_MERCHANT_SEARCH_DATA";
