import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";
import { getUserByFilterData } from "../../Redux/Actions/UserRegistration";

const useStyles = makeStyles(styles);

function SimpleDialog(props) {
  const [values, setValue] = React.useState(props.searchValue);
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValue(allState);
  };

  const onFormSubmit = () => {
    props.seacrhChipData(values);
    props.close();
  };

  return (
    <>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              }
            }}
            autoComplete="off"
            label="Name / Email / Phone"
            value={values.Name}
            onChange={(e) => onChange(e.target.value, "Name")}
            name="Name"
            id="Name"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              }
            }}
            label="Role Name"
            value={values.Role}
            onChange={(e) => onChange(e.target.value, "Role")}
            name="Roll"
            id="Roll"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.margin} style={{ marginBottom: "5%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            SelectProps={{
              MenuProps: { disablePortal: false }
            }}
            label="Status"
            value={values.Current_Status}
            onChange={(e) => onChange(e.target.value, "Current_Status")}
            name="Current_Status"
            id="Current_Status"
            select
          >
            <MenuItem className={classes.dialogBoxDropDown} value="All">
              All
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Active">
              Active
            </MenuItem>
            <MenuItem className={classes.dialogBoxDropDown} value="Inactive">
              Inactive
            </MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs className={classes.clearSubmitButton}>
          <br />
          <Button
            cancel
            variant="outlined"
            style={{
              height: "2.1em",
              // marginTop: "5px",
              textTransform: "none",
            }}
            disabled={
              values.Current_Status === "" &&
              values.Role === "" &&
              values.Name === ""
            }
            size="md"
            onClick={() => handleClose()}
          >
            Clear
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
           variant=""
           color="#ffffff"
           style={{
             backgroundColor: "#03A430",
             textTransform: "none",
             borderRadius: "5px",
             height: "30px",
             color: "#ffffff",
             //  fontSize: "18px",
             fontWeight: "600",
           }}
            className={classes.buttonStyle}
            disabled={
              values.Current_Status === "" &&
              values.Role === "" &&
              values.Name === ""
            }
            size="md"
            onClick={() => onFormSubmit()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
  close: PropTypes.func,
  getUserByFilterData: PropTypes.func,
  seacrhChipData: PropTypes.func,
  searchValue: PropTypes.object
};

const mapStateToProps = (app) => ({
  formData: app.users
});

export default connect(mapStateToProps, {
  getUserByFilterData
})(SimpleDialog);
