import { get, getAltPay, postAltPay } from "./httpClient";
import { GENRATE_TOKEN_ALT_PAY, CREATE_INVOICE_ALT_PAY } from "../Uintls/Urls";

export const genrateTokenAltpay =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await getAltPay(GENRATE_TOKEN_ALT_PAY, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };

export const createInvoiceAltPay =
  (payload, successCallback, failureCallback) => async () => {
    try {
      const data = await postAltPay(CREATE_INVOICE_ALT_PAY, payload);

      if (typeof successCallback === "function") {
        successCallback(data);
      }
    } catch (err) {
      if (typeof failureCallback === "function") {
        failureCallback(err);
      }
    }
  };
