const style = (theme) => ({
  checkoutMain: {
    padding: "2%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignContent: "center",
    justifyContent: "center",
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    height: "30px",
    color: theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  logoHeading: { display: "inline-flex", alignItems: "center" },
});

export default style;
