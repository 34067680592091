import React, { useRef, useLayoutEffect, useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import ListIcon from "@material-ui/icons/List";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
//import exportCss from '../../assets/css/amchart/export';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { Divider, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardFooter from "components/Card/CardFooter";
import { Button } from "@material-ui/core";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import currentBalance from "assets/jss/material-dashboard-react/views/currentBalance";
import { makeStyles } from "@material-ui/core/styles";
import { styled, alpha } from "@material-ui/core/styles";
import EditIcon from "@material-ui/icons/Edit";
import ArchiveIcon from "@material-ui/icons/Archive";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const options = ["None", "Atria", "Callisto", "Dione"];

const ITEM_HEIGHT = 48;

const useStyles = makeStyles(currentBalance);

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function MemberRegistrations() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  // am4core.useTheme(am4themes_animated);
  useLayoutEffect(() => {
    let root = am5.Root.new("chartdive");

    // Set themes
    // https://www.amcharts.com/docs/v5/concepts/themes/
    root.setThemes([am5themes_Animated.new(root)]);

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/xy-chart/
    var chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
      })
    );

    // Add legend
    // https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
    var legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    var data = [
      {
        year: "Week 1",
        "level 1": 21000.25,
        "level 2": 20685.36,
        "level 3": 19948.68,
        "level 4": 20285.66,
        "level 5": 19558.98,
      },
      {
        year: "Week 2",
        "level 1": 21000.25,
        "level 2": 20685.36,
        "level 3": 19948.68,
        "level 4": 20285.66,
        "level 5": 19558.98,
      },
      {
        year: "Week 3",
        "level 1": 21000.25,
        "level 2": 20685.36,
        "level 3": 19948.68,
        "level 4": 20285.66,
        "level 5": 19558.98,
      },
      {
        year: "Week 4",
        "level 1": 21000.25,
        "level 2": 20685.36,
        "level 3": 19948.68,
        "level 4": 20285.66,
        "level 5": 19558.98,
      },
    ];

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/

    var xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 0,
      cellStartLocation: 0.1,
      cellEndLocation: 0.8,
    });
    xRenderer.labels.template.setAll({
      rotation: 0,
      centerY: am5.p50,
      centerX: 0,
      paddingLeft: -40,
    });
    xRenderer.grid.template.set("visible", false);

    var xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "year",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.data.setAll(data);

    var yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
    function makeSeries(name, fieldName, color) {
      var tooltip = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        getStrokeFromSprite: true,
        autoTextColor: false,
        getLabelFillFromSprite: true,
        stroke: am5.color(0x76b041),
        labelText: "[bold]{name}[/]\n{categoryX}: {valueY}",
      });

      tooltip.get("background").setAll({
        fill: am5.color(0xffffff),
        fillOpacity: 0.8,
      });

      var series = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: fieldName,
          categoryXField: "year",
          fill: color,
          tooltip: tooltip,
        })
      );

      series.columns.template.setAll({
        cornerRadiusTL: 15,
        cornerRadiusTR: 15,
        maxWidth: 30,
        strokeOpacity: 0,
        tooltipHTML:
          "<div style='display: flex;'><div style='width: 12px; height: 12px; border-radius: 50%; background: " +
          color +
          "; margin-top: 5px'></div>&nbsp;${valueY}</div>",
        width: am5.percent(90),
        tooltipY: 0,
      });

      series.data.setAll(data);

      // Make stuff animate on load
      // https://www.amcharts.com/docs/v5/concepts/animations/
      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            /* fill: root.interfaceColors.get("alternativeText"), */
            //centerY: 0,
            //centerX: am5.p50,
            populateText: true,
          }),
        });
      });

      legend.data.push(series);
    }

    makeSeries("TOP L1", "level 1", "#0057A4");
    makeSeries("TOP L2", "level 2", "#00D5A5");
    makeSeries("TPO L3", "level 3", "#D3507B");
    makeSeries("TPO L4", "level 4", "#FFB959");
    makeSeries("TPO L5", "level 5", "#FE7D66");

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);

  //   	var active_patients = '{"Data": [{ "Active_Patients": [{"month_year": "December-2019", "total_count": 24},{"month_year": "January-2020", "total_count": 6},{"month_year": "February-2020", "total_count": 9},{"month_year": "March-2020", "total_count": 12},{"month_year": "April-2020", "total_count": 15},{"month_year": "May-2020", "total_count": 22},{"month_year": "June-2020", "total_count": 27},{"month_year": "July-2020", "total_count": 31},{"month_year": "August-2020", "total_count": 31},{"month_year": "September-2020", "total_count": 30},{"month_year": "October-2020", "total_count": 31},{"month_year": "November-2020", "total_count": 28}]}]}';
  //   useEffect(() => {
  //     if (document.getElementById('memberchart') !== null) {
  // 		let chart = am4core.create("memberchart", am4charts.PieChart3D);
  // 		chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
  // 		chart.angle = 40;
  // 		chart.depth = 45;

  // 		let title = chart.titles.create();
  // 		title.text = "\nTPO Level Distributions";
  // 		title.fontSize = 13;
  // 		title.fontFamily="Source Sans Pro";
  // 		title.paddingBottom = 10;
  // 		title.align = "center";
  // 		title.fontWeight = "bold";

  // 		chart.data = [
  // 		{
  // 			tpoLevel: "Level-1",
  // 			count: 100
  // 		},
  // 		{
  // 			tpoLevel: "Level-2",
  // 			count: 80
  // 		},
  // 		{
  // 			tpoLevel: "Level-3",
  // 			count: 70
  // 		},
  // 		{
  // 			tpoLevel: "Level-4",
  // 			count: 65
  // 		},
  // 		{
  // 			tpoLevel: "Level-5",
  // 			count: 55
  // 		},
  // 		{
  // 			tpoLevel: "Level-6",
  // 			count: 50
  // 		},
  // 		{
  // 			tpoLevel: "Level-7",
  // 			count: 35
  // 		},
  // 		{
  // 			tpoLevel: "Level-8",
  // 			count: 25
  // 		},
  // 		{
  // 			tpoLevel: "Level-9",
  // 			count: 20
  // 		},
  // 		{
  // 			tpoLevel: "Level-10",
  // 			count: 10
  // 		}
  // 		];

  // 		let series = chart.series.push(new am4charts.PieSeries3D());
  // 		series.dataFields.value = "count";
  // 		series.dataFields.category = "tpoLevel";
  // 		series.labels.template.disabled = true;
  // 		series.marginLeft = 500;
  // 		let colorSet = new am4core.ColorSet();
  // 		colorSet.list = ["#c6f700", "#f60102", "#c603f7", "#0262f7", "#03f766"].map(function(color) {
  // 			return new am4core.color(color);
  // 		});
  // 		series.colors = colorSet;
  // 		series.slices.template.tooltipHTML = `<table style="font-size:10px;">
  // 		   	<tr">
  // 		   		<center style="font-size:12px; word-wrap: break-all;">{tpoLevel}</center>
  // 		   		<hr />
  // 		 	</tr>
  // 			<tr align="center">
  // 		   		<center style="text-align: center; width: 100% !important; font-size: 14px;"><b>{count}</b> ({value.percent.formatNumber('#.0')}%)</center>
  // 			</tr>

  // 		   </table>`;

  // 		let titles = chart.titles.push(new am4core.Label());
  // 		titles.textAlign = "middle";
  // 		titles.wrap = true;

  // 		//chart.innerRadius = am4core.percent(40);
  // 		//chart.depth = 30;

  // 		chart.legend = new am4charts.Legend();
  // 		chart.legend.fontWeight = 400;
  // 		chart.legend.position = "right";
  // 		chart.legend.marginRight = 100;
  // 		//chart.legend.marginLeft = 200;
  // 		chart.legend.markers.template.paddingLeft = "2";
  // 		chart.legend.markers.template.paddingTop = "2";
  // 		chart.legend.markers.template.paddingRight = "2";
  // 		chart.legend.markers.template.paddingBottom = "2";
  // 		series.legendSettings.valueText = " ";

  // 		chart.radius = "100%";
  // 		chart.angle = 30;
  // 		chart.responsive = new am4core.Responsive();
  // 		chart.responsive.enabled = true;
  // 		chart.logo.disabled = true;

  // 		/* suresh's changes */

  // 		chart.legend.fontSize=10;
  // 		chart.legend.useDefaultMarker = true;
  // 		var marker = chart.legend.markers.template.children.getIndex(0);
  // 		marker.cornerRadius(12, 12, 12, 12);
  // 		marker.strokeWidth = 2;
  // 		marker.strokeOpacity = 1;
  // 		marker.stroke = am4core.color("#ccc");
  // 		chart.tooltip.label.fill = am4core.color("#f00");
  // 		var markerTemplate = chart.legend.markers.template;
  // 		markerTemplate.width = 10;
  // 		markerTemplate.height = 10;

  // /* ************************** */

  //     }
  //   }, []);
  return (
    <>
      <Grid
        item
        // className={classes.cardGrid}
      >
        <CardHeader
          // className={classes.duesGrid}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "0px",
            paddingTop: "15px",
          }}
        >
          <Typography noWrap className={classes.cardTitle}>
            <Button
              style={{
                color: "#333333",
                border: "1px solid",
                borderRadius: "25px",
                textTransform: "none",
                fontWeight: "bold",
                fontSize: "20px",
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant=""
              // disableElevation
              // onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              TPO Level Distributions
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {/* <MenuItem onClick={handleClose} disableRipple>
                Last Month
              </MenuItem>
              <MenuItem onClick={handleClose} disableRipple>
                This Month
              </MenuItem>
              <Divider sx={{ my: 0.5 }} />
              <MenuItem onClick={handleClose} disableRipple>
                Year To Date
              </MenuItem> */}
              {/* <MenuItem onClick={handleClose} disableRipple>
                <MoreHorizIcon />
                More
              </MenuItem> */}
            </StyledMenu>
          </Typography>
          <Typography noWrap className={classes.cardTitle}>
            <Button
              style={{
                color: "#27337D",
                border: "1px solid",
                borderRadius: "25px",
                textTransform: "none",
                fontWeight: "600",
              }}
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant=""
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
            >
              Last Month
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              // color="#3E4E9B"
            >
              <MenuItem value={"This Month"} onClick={handleClose}>
                This Month
              </MenuItem>
              <MenuItem
                style={{
                  color: "#27337D",
                  border: "1px solid",
                  borderRadius: "25px",
                  textTransform: "none",
                  fontWeight: "600",
                }}
                onClick={handleClose}
              >
                Last Month
              </MenuItem>
              <MenuItem onClick={handleClose}>Year To Date</MenuItem>
              {/* <MenuItem onClick={handleClose} disableRipple>
                <MoreHorizIcon />
                More
              </MenuItem> */}
            </StyledMenu>
          </Typography>
        </CardHeader>
        <CardBody>
          <Typography className={classes.typographyAmmount}>
            Total Level Distributions May 1 - May 31
          </Typography>

          <Typography className={classes.typographyCountGraph}>
            $110,500.25
          </Typography>
        </CardBody>
      </Grid>
      <div id="chartdive" style={{ width: "100%", height: "20rem" }} />
    </>
  );
}
