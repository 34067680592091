/* eslint-disable import/extensions */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import styles from "assets/jss/material-dashboard-react/components/businessForm";

const useStyles = makeStyles(styles);
export function SimpleDialog(props) {
  const [values, setValue] = React.useState({
    Id: "",
    cpt: "",
    status: "",
    balance: "",
    balanceLess: "",
    offerDateFrom: "",
    offerDateTo: ""
  });
  const classes = useStyles();

  const onChange = (value, type) => {
    const allState = { ...values };
    allState[type] = value;
    setValue(allState);
  };

  return (
    <>
      <Grid container style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.rootDialog}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                focused: "focused",
                input: classes.resize
              }
            }}
            label="MID / DBA Name"
            value={values.id}
            onChange={(e) => onChange(e.target.value, "id")}
            name="EHR"
            id="EHR"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "3%" }}>
        <Grid item xs={6} lg={6}>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Amount more than Equal ($)"
            value={values.balance}
            onChange={(e) => onChange(e.target.value, "balance")}
            name="balance"
            id="balance"
            style={{ fontSize: "82%" }}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <TextField
            className={classes.halfInputSingle}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Amount less than Equal ($)"
            value={values.balanceLess}
            onChange={(e) => onChange(e.target.value, "balanceLess")}
            name="balanceLess"
            id="balanceLess"
            style={{ fontSize: "82%" }}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginBottom: "3%" }}>
        <Grid item xs={6} lg={6}>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
            label="Transaction Date (From)"
            type="date"
            value={values.offerDateFrom}
            onChange={(e) => onChange(e.target.value, "offerDateFrom")}
            name="offerDateFrom"
            id="offerDateFrom"
            style={{ fontSize: "82%" }}
          />
        </Grid>
        <Grid item xs={6} lg={6}>
          <TextField
            id="date"
            label="Transaction Date (To)"
            type="date"
            value={values.offerDateTo}
            onChange={(e) => onChange(e.target.value, "offerDateTo")}
            className={classes.halfInput}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused"
              }
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused"
              }
            }}
          />
        </Grid>
      </Grid>
      {/* <Grid container  style={{ marginBottom: "3%" }}>
        <Grid item xs>
          <TextField
            className={classes.halfInput}
            InputLabelProps={{
              classes: {
                root: classes.activeInputLableColor,
                focused: "focused",
              },
            }}
            InputProps={{
              classes: {
                root: classes.activeInputColor,
                input: classes.resize,
                focused: "focused",
              },
            }}
            label="Status"
            value={values.status}
            onChange={e => onChange(e.target.value, "status")}
            name="status"
            id="status"
            select
            style={{ fontSize: "82%" }}
          >
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Closed">Closed</MenuItem>
          </TextField>
        </Grid>
      </Grid> */}
      <Grid container>
        <Grid item xs>
          <br />
          <Button
            cancel
            variant="outlined"
            size="md"
            style={{
              height: "2.1em",
              // marginTop: "5px",
              textTransform: "none",
            }}
            onClick={() => props.close()}
          >
            Clear
          </Button>
          &nbsp;&nbsp;&nbsp;
          <Button
             cancel
             variant=""
             color="#ffffff"
             style={{
               backgroundColor: "#03A430",
               textTransform: "none",
               borderRadius: "5px",
               height: "30px",
               color: "#ffffff",
              //  fontSize: "18px",
               fontWeight: "600",
             }}
             type="submit"
            className={classes.buttonStyle}
            size="md"
            onClick={() => props.close()}
          >
            Search
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

SimpleDialog.propTypes = {
  close: PropTypes.func
};
