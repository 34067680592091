/* eslint-disable camelcase */
// eslint-disable-next-line no-unused-vars
import * as yup from "yup";
// eslint-disable-next-line no-unused-vars
import { mixed, object, string, date, number, boolean } from "yup";
let amountPattern = /^\d+(\.\d{0,2})?$/;
// /^\d*\.?\d*$/;
export default {
  checkUserDetails: object({
    accountNumber: number("Please enter a valid account number.")
      .typeError("Please enter a valid account number.")
      .required("Account Number is required."),
    dob: date("Please select a valid date of birth")
      .typeError("Enter a valid Date")
      .nullable()
      .required("Date of Birth is required.")
  }),
  payReview: object({
    amount: number("Please enter a valid account number.")
      .positive()
      .test(
        "is-decimal",
        "Amount required with maximum 2 decimal places",
        (val) => {
          if (val != undefined || val != "") {
            return amountPattern.test(val);
          }
          return true;
        }
      )
      .min(0, "Amount must be greater then 0")
      .required("Amount is required."),
    name: string("Please enter name.").required("Name is required."),
    address: string("Please enter address.").required("Address is required.")
  }),
  updateCardDetails: object({
    cardNumber: mixed("Please enter a valid 16 digits Card Number.")
      .test(
        "cardNumber",
        "Please enter a valid 16 digits Card Number",
        (val) => {
          if (val != undefined || val != "") {
            return /^[0-9 \-]+$/.test(val);
          }
          return true;
        }
      )
      .required("Please enter a valid 16 digits Card Number."),
    cvv: number("Please enter CVV.")
      .test("CVV", "Please enter a valid 3 digit CVV.", (val) => {
        if (val != undefined || val != "") {
          return /^[0-9]{3}$/.test(val);
        }
        return true;
      })
      .typeError("Please enter a valid 3 digit CVV.")
      .required("CVV is required."),
    expiry: date("Please select a valid date")
      .typeError("Enter a valid Date")
      .nullable()
      .required("Card expiry date is required."),
    zip: number("Please enter a zip.")
      .test("zip", "zip must be 5-7 digits", (val) => {
        if (val != undefined || val != "") {
          return /^[0-9]{5,7}$/.test(val);
        }
        return true;
      })
      .typeError("Please enter a valid zip code.")
      .required("Zip is required.")
  }),
  updateUserDetails: object({
    firstName: string("Please enter first name.").required(
      "First name is required."
    ),
    lastName: string("Please enter last name.").required(
      "Last name is required."
    ),
    phone: number("Please enter a phone.")
      .test("phone", "Please enter a valid phone number.", (val) => {
        if (val != undefined || val != "") {
          return /^[0-9]{10,12}$/.test(val);
        }
        return true;
      })
      .typeError("Please enter a valid phone number.")
      .required("Phone is required."),
    email: string("Please enter a valid email.")
      .required("Email is required.")
      .typeError("Please enter a valid email."),
    zip: number("Please enter a zip.")
      .test("zip", "Zip must be 5-7 digits", (val) => {
        if (val != undefined || val != "") {
          return /^[0-9]{5,7}$/.test(val);
        }
        return true;
      })
      .typeError("Please enter a valid zip code."),
    dob: date("Please select a valid date of birth")
      .typeError("Enter a valid Date")
      .nullable()
  })
};
